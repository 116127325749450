import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";

import useGetRegisterMember from "common/hooks/useGetRegisterMember";

import RegisterPatientForm from "./RegisterPatientForm";
import { isValidSalesforceId } from "../../helpers/components/Forms/FormHelpers";
import RegisterPatientModalStateEnum from "./enums/RegisterPatientModalStateEnum";
import RegisterPatientModal from "./RegisterPatientModal";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import ErrorComponent from "../../components/ErrorComponent";
import { RootState, useAppDispatch } from "common/redux";
import { AlertButtonType } from "common/redux/AlertSlice";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";

const RegisterPatientContainer = styled.div`
  max-width: none;
  margin: 2.5%;
`;

const CreatePatientCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e8eaf0;
  text-decoration: none;
  /* Shadow 2 - Card */

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;

  transition: all 0.66s ease-out;
  width: 500px;
`;

const RegisterPatient = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const salesforceLeadParam = searchParams.get("salesforceLead");
  const [salesforceLead, setSalesforceLead] = useState<string>(
    salesforceLeadParam ?? ""
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const {
    fetchData,
    isFetching,
    isLoaded,
    isError,
    error,
    memberName,
    registerMemberLeadsId,
    reset,
    registerMemberReqBody
  } = useGetRegisterMember({ registered_by: user?.user_id });

  useEffect(() => {
    const id = "errorRegisteringSalesforceLead";
    if (isError && error) {
      const buttons: AlertButtonType[] = [
        {
          text: "Done",
          style: "cancel",
          onPress: () => Alert_close({ dispatch, id })
        }
      ];
      Alert_show({
        dispatch,
        id,
        title: "Error Registering Salesforce Lead",
        content: <ErrorComponent error={error} />,
        type: "error",
        size: "small",
        buttons
      });
    }
  }, [isError, error]);

  useEffect(() => {
    const fetchRegister = async (salesforceLead) => {
      await fetchData(salesforceLead);
    };
    if (
      isValidSalesforceId(salesforceLead) &&
      !isFetching &&
      !isLoaded &&
      !isError
    ) {
      fetchRegister(salesforceLead);
    }
  }, [salesforceLead, isFetching, isLoaded, isError]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentModalState, setCurrentModalState] =
    useState<RegisterPatientModalStateEnum>(
      RegisterPatientModalStateEnum.STATE_1
    );

  useEffect(() => {
    if (isLoaded && registerMemberLeadsId) {
      setLeadsId(registerMemberLeadsId);
      setModalOpen(true);
    }
  }, [isLoaded, registerMemberLeadsId]);

  const [leadsId, setLeadsId] = useState<string>(null);

  return (
    <RegisterPatientContainer>
      <Typography
        variant="h1"
        color="text.primary"
        sx={{ marginBottom: "2.5%" }}
      >
        Register Member
      </Typography>
      <CreatePatientCard>
        {user && (
          <RegisterPatientForm
            salesforceInitialLead={salesforceLead}
            setSalesforceLead={setSalesforceLead}
            getSalesforceLead={fetchData}
            isFetching={isFetching}
            isLoaded={isLoaded}
          />
        )}
      </CreatePatientCard>
      <RegisterPatientModal
        modalOpen={modalOpen}
        currentModalState={currentModalState}
        setCurrentModalState={setCurrentModalState}
        setModalOpen={setModalOpen}
        setSalesforceLead={setSalesforceLead}
        patientName={memberName}
        leadsId={leadsId}
        setLeadsId={setLeadsId}
        reset={reset}
        registerPatientReqBody={registerMemberReqBody}
        registerPatientLoading={isFetching}
      />
    </RegisterPatientContainer>
  );
};

export default RegisterPatient;
