import { createSlice } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { Platform } from "react-native";

interface StateType {
  code: string;
  tabId: string;
  startDate: DateTime;
  endDate: DateTime;
}

export const CURRENT_TAB_ID = Platform.OS === "web" ? uuidv4() : "";

const initialState: StateType = {
  tabId: null,
  code: null,
  startDate: null,
  endDate: null
};

export const videoCallSlice = createSlice({
  name: "videoCall",
  initialState,
  reducers: {
    joinVideoCallRoom: (
      state,
      action: { payload: { code: string; tabId: string } }
    ) => {
      const { code, tabId } = action.payload;

      state.tabId = tabId;
      state.code = code;
      state.startDate = null;
      state.endDate = null;
    },
    startVideoCall: (state) => {
      state.startDate = DateTime.now();
      state.endDate = null;
    },
    endVideoCall: (state) => {
      state.code = null;
      state.tabId = null;
      state.endDate = DateTime.now();
    },
    resetStateVideoCall: (state) => {
      state.tabId = initialState.tabId;
      state.code = initialState.code;
      state.startDate = initialState.startDate;
      state.endDate = initialState.endDate;
    }
  }
});

export const {
  joinVideoCallRoom,
  startVideoCall,
  endVideoCall,
  resetStateVideoCall
} = videoCallSlice.actions;

export default videoCallSlice.reducer;
