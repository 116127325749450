import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { checkIdValid, isFalsy } from "../helpers/helpers";
import EncounterReasonsEnum from "../enums/EncounterReasonsEnum";
import RolesEnum, { hasProviderRole, hasTnRole } from "../enums/RolesEnum";
import { useGetEncountersQuery } from "../services/EncountersService";

const useGetLastEncounter = (
  patient_id: string,
  roleFilter: RolesEnum.TH_NURSE | RolesEnum.NURSE_PROVIDER,
  startDate?: DateTime,
  endDate?: DateTime
) => {
  const isValidId = checkIdValid(patient_id);

  const [isLoaded, setIsLoaded] = useState(false);

  const {
    data: encounterVisits,
    isError,
    error
  } = useGetEncountersQuery(
    {
      patient_id,
      startsAfter: startDate,
      startsBefore: endDate,
      submitterRoles: roleFilter
    },
    { skip: isValidId === false }
  );

  const lastEncounterVisit = useMemo(() => {
    // assign the most recent visit or encounter to this value
    // use these variables to keep track of the most recent visit or encounter
    let mostRecentVisitOrEncounter: any;
    let mostRecentDateTime: DateTime;

    encounterVisits?.forEach((item) => {
      if (roleFilter === RolesEnum.TH_NURSE) {
        if (
          // if we have not saved the most recent visit or encounter
          (isFalsy(mostRecentVisitOrEncounter) ||
            // or the current encounter is more recent
            DateTime.fromISO(item?.encounter?.starts_on) >
              mostRecentDateTime) &&
          // we want to show last TN encounter and last NP encounter based on the connected RPM encounter type
          // https://docs.google.com/document/d/1X0qSFSH9HiKni13nn7VoWA9iI8H9LPKRGgDSzHgtcdU/edit
          // we want to show last TN encounter based on RPM or Device Setup
          // https://copilotiq.atlassian.net/browse/ENG-5184?focusedCommentId=15788
          [
            EncounterReasonsEnum.REMOTE_PATIENT_MONITORING,
            EncounterReasonsEnum.INITIAL_VISIT,
            EncounterReasonsEnum.DEVICE_SETUP
            // @ts-ignore
          ].includes(item?.encounter?.reason) &&
          hasTnRole(item?.encounter?.submitter_roles)
        ) {
          // reassign these variables
          mostRecentVisitOrEncounter = item?.encounter;
          mostRecentDateTime = DateTime.fromISO(item?.encounter?.starts_on);
        }
        // provider logic
      } else if (roleFilter === RolesEnum.NURSE_PROVIDER) {
        if (
          // if we have not saved the most recent visit or encounter
          ((isFalsy(mostRecentVisitOrEncounter) && item?.encounter) ||
            // or the current visit is more recent
            DateTime.fromISO(item?.encounter?.starts_on) >
              mostRecentVisitOrEncounter) &&
          hasProviderRole(item?.encounter?.submitter_roles)
        ) {
          // reassign these variables
          mostRecentVisitOrEncounter = item?.encounter;
          mostRecentDateTime = DateTime.fromISO(item?.encounter?.starts_on);
        }
      }
    });

    setIsLoaded(true);

    // this will return undefined if there are no visits or encounters
    return (
      mostRecentVisitOrEncounter?.encounter_started_on ??
      mostRecentVisitOrEncounter?.starts_on
    );
  }, [encounterVisits]);

  useEffect(() => {
    if (isError) {
      setIsLoaded(true);
    }
  }, [isError]);

  return { lastEncounterVisit, isLoaded, error };
};

export default useGetLastEncounter;
