import { apiCalendar } from "./AxiosService";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import CalendarEventResponseType from "../types/Calendaring/CalendarEventResponseType";
import CalendarEventRequestType from "../types/Calendaring/CalendarEventRequestType";
import RecurrenceUpdateTypeEnum from "../enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";
import { DateTime } from "luxon";
import appointmentsService from "./AppointmentsService";
import { AxiosHeaders } from "axios";
import ManagerNursePtoResponseType from "../types/Calendaring/ManagerNursePtoResponseType";
import cloneDeep from "lodash.clonedeep";
import { getNameOrUsername } from "../helpers/helpers";
import CalendarRecurringEventInfoResponseType from "../types/Calendaring/CalendarRecurringEventInfoResponseType";
import { userService } from "./UserService";

export const DELAY_AFTER_REQUEST_COMPLETED = 3000;
export const DELAY_AFTER_UPDATE_SCHEDULE_REQUEST_COMPLETED = 2000;

interface ManagerPtoParams {
  manager_id: string;
}
interface TeamPtoParams {
  team_id: string;
  startdate: DateTime;
  enddate: DateTime;
}

interface CalendarEventParams {
  event_id: string;
  staff_id?: string;
  patient_id?: string;
}
interface CalendarEventsParams {
  staff_id?: string;
  patient_id?: string;
  startdate?: DateTime;
  enddate?: DateTime;
  xTraceId?: string;
  showDeleted?: boolean;
}

interface ReassignCalendarEventParams {
  staff_id: string;
  start_date?: string;
  end_date?: string;
}

const transformResponseEventResponseType = (
  response: CalendarEventResponseType[]
) => {
  return response?.map((item) => {
    const start = DateTime.fromISO(item.startdate);
    const end = DateTime.fromISO(item.enddate);
    const duration_diff = end.diff(start, "seconds").seconds;

    return {
      ...item,
      duration_diff
    };
  });
};

const calendarService = apiCalendar
  .enhanceEndpoints({
    addTagTypes: [
      ReduxTagEnum.Calendar,
      ReduxTagEnum.Availability,
      ReduxTagEnum.Visits,
      ReduxTagEnum.ManagerPto
    ]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getCalendarEvent: builder.query<
        CalendarEventResponseType,
        CalendarEventParams
      >({
        query: ({
          event_id
        }: {
          event_id: string;
          patient_id?: string;
          staff_id?: string;
        }) => {
          return { url: `/calendar/events/${event_id}`, method: "GET" };
        },
        providesTags: (result, error, arg) => {
          const arr = [];
          if (result && arg.staff_id) {
            arr.push({ type: ReduxTagEnum.Calendar, id: arg.staff_id });
          }
          if (result && arg.patient_id) {
            arr.push({ type: ReduxTagEnum.Calendar, id: arg.patient_id });
          }
          return arr;
        }
      }),
      getCalendarEvents: builder.query<
        CalendarEventResponseType[],
        CalendarEventsParams
      >({
        query: ({
          staff_id,
          patient_id,
          startdate,
          enddate
        }: {
          staff_id?: string;
          patient_id?: string;
          startdate: DateTime;
          enddate: DateTime;
        }) => {
          const params = QueryString_stringify({
            staff_id,
            patient_id,
            startdate: startdate.toISO(),
            enddate: enddate.toISO()
          });
          return { url: `/calendar/events?${params}`, method: "GET" };
        },
        providesTags: (result, error, arg) => {
          const arr = [];
          if (result && arg.staff_id) {
            arr.push({ type: ReduxTagEnum.Calendar, id: arg.staff_id });
          }
          if (result && arg.patient_id) {
            arr.push({ type: ReduxTagEnum.Calendar, id: arg.patient_id });
          }
          return arr;
        },
        transformResponse: transformResponseEventResponseType
      }),
      getUpcomingCalendarEvents: builder.query<
        CalendarEventResponseType[],
        CalendarEventsParams
      >({
        query: ({
          staff_id,
          patient_id,
          startdate,
          enddate
        }: {
          staff_id?: string;
          patient_id?: string;
          startdate: DateTime;
          enddate: DateTime;
        }) => {
          const params = QueryString_stringify({
            staff_id,
            patient_id,
            startdate: startdate.toISO(),
            enddate: enddate.toISO()
          });
          return { url: `/calendar/events/upcoming?${params}`, method: "GET" };
        },
        providesTags: (result, error, arg) => {
          const arr = [];
          if (result && arg.staff_id) {
            arr.push({ type: ReduxTagEnum.Calendar, id: arg.staff_id });
          }
          if (result && arg.patient_id) {
            arr.push({ type: ReduxTagEnum.Calendar, id: arg.patient_id });
          }
          return arr;
        },
        transformResponse: transformResponseEventResponseType
      }),
      getPatientCalendarEvents: builder.query<
        CalendarEventResponseType[],
        CalendarEventsParams
      >({
        query: ({
          patient_id,
          startdate,
          enddate
        }: {
          patient_id: string;
          startdate: DateTime;
          enddate: DateTime;
        }) => {
          const params = QueryString_stringify({
            startdate: startdate.toISO(),
            enddate: enddate.toISO()
          });
          return {
            url: `/calendar/patient/${patient_id}/events?${params}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => {
          const arr = [];
          if (result && arg.patient_id) {
            arr.push({ type: ReduxTagEnum.Calendar, id: arg.patient_id });
          }
          return arr;
        },
        transformResponse: transformResponseEventResponseType
      }),
      getStaffRecurringEvents: builder.query<
        CalendarRecurringEventInfoResponseType[],
        CalendarEventsParams
      >({
        query: ({
          staff_id,
          showDeleted
        }: {
          staff_id: string;
          showDeleted?: boolean;
        }) => {
          const params = QueryString_stringify({
            showDeleted
          });
          return {
            url: `/calendar/staff/${staff_id}/recurring?${params}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => {
          const arr = [];
          if (result && arg.staff_id) {
            arr.push({ type: ReduxTagEnum.Calendar, id: arg.staff_id });
          }
          return arr;
        }
      }),
      getManagerNursePto: builder.query<
        ManagerNursePtoResponseType[],
        ManagerPtoParams
      >({
        query: ({ manager_id }: { manager_id: string }) => {
          return {
            url: `/calendar/manager/${manager_id}/nurse_pto`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => {
          const arr = [];
          arr.push({ type: ReduxTagEnum.ManagerPto });
          if (result && arg.manager_id) {
            arr.push({ type: ReduxTagEnum.ManagerPto, id: arg?.manager_id });
          }
          return arr;
        },
        transformResponse: (response: ManagerNursePtoResponseType[]) => {
          const clone = cloneDeep(response);
          const finalResponse = [];
          clone.forEach((item) => {
            const fullname = getNameOrUsername(item?.staff);
            // flatten ooo_slots to only have one element
            const newitems = item?.ooo_slots?.map((slot) => {
              return {
                staff: {
                  ...item?.staff,
                  fullname
                },
                ooo_slots: [{ ...slot }]
              };
            });

            newitems.forEach((item) => {
              finalResponse.push(item);
            });
          });
          return finalResponse;
        }
      }),
      getTeamNursePto: builder.query<
        ManagerNursePtoResponseType[],
        TeamPtoParams
      >({
        query: ({
          team_id,
          startdate,
          enddate
        }: {
          team_id: string;
          startdate: DateTime;
          enddate: DateTime;
        }) => {
          const params = QueryString_stringify({
            startdate: startdate?.toISO(),
            enddate: enddate?.toISO()
          });
          return {
            url: `/calendar/team/${team_id}/nurse_pto?${params}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => {
          const arr = [];
          arr.push({ type: ReduxTagEnum.ManagerPto });
          if (result && arg.team_id) {
            arr.push({ type: ReduxTagEnum.ManagerPto, id: arg?.team_id });
          }
          return arr;
        },
        transformResponse: (response: ManagerNursePtoResponseType[]) => {
          const clone = cloneDeep(response);
          const finalResponse = [];
          clone.forEach((item) => {
            const fullname = getNameOrUsername(item?.staff);
            // flatten ooo_slots to only have one element
            const newitems = item?.ooo_slots?.map((slot) => {
              return {
                staff: {
                  ...item?.staff,
                  fullname
                },
                ooo_slots: [{ ...slot }]
              };
            });

            newitems.forEach((item) => {
              finalResponse.push(item);
            });
          });
          return finalResponse;
        }
      }),
      // Mutations
      createCalendarEvent: builder.mutation<
        CalendarEventRequestType,
        {
          body: CalendarEventRequestType;
          staffId: string;
          xTraceId: string;
          managerId?: string;
          patientId?: string;
        }
      >({
        query: ({ body, staffId, xTraceId }) => ({
          url: `/calendar/staff/${staffId}/events`,
          method: "POST",
          data: body,
          headers: new AxiosHeaders({
            "X-Trace-Id": xTraceId
          })
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            const tagsToInvalidate = [];

            tagsToInvalidate.push({
              type: ReduxTagEnum.Availability,
              id: props.staffId
            });
            tagsToInvalidate.push({
              type: ReduxTagEnum.Calendar,
              id: props.staffId
            });

            tagsToInvalidate.push({ type: ReduxTagEnum.ManagerPto });

            if (props?.managerId) {
              tagsToInvalidate.push({
                type: ReduxTagEnum.ManagerPto,
                id: props?.managerId
              });
            }

            if (props?.patientId) {
              tagsToInvalidate.push({
                type: ReduxTagEnum.Calendar,
                id: props?.patientId
              });
            }

            setTimeout(() => {
              dispatch(calendarService.util.invalidateTags(tagsToInvalidate));
            }, DELAY_AFTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      updateCalendarEvent: builder.mutation<
        Partial<CalendarEventRequestType>,
        {
          body: Partial<CalendarEventRequestType>;
          eventId: string;
          staffId?: string;
          patientId?: string;
        }
      >({
        query: ({ body, eventId }) => ({
          url: `/calendar/events/${eventId}`,
          method: "PUT",
          data: body
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            setTimeout(() => {
              dispatch(
                calendarService.util.invalidateTags([
                  { type: ReduxTagEnum.Calendar, id: props.patientId },
                  { type: ReduxTagEnum.Calendar, id: props.staffId },
                  { type: ReduxTagEnum.Availability, id: props.staffId },
                  { type: ReduxTagEnum.ManagerPto }
                ])
              );
              dispatch(
                appointmentsService.util.invalidateTags([
                  { type: ReduxTagEnum.Appointments, id: props?.patientId },
                  { type: ReduxTagEnum.Appointments, id: props?.staffId }
                ])
              );
            }, DELAY_AFTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      reassignCalendarEvent: builder.mutation<
        ReassignCalendarEventParams,
        {
          body: ReassignCalendarEventParams;
          eventId: string;
          staffId?: string;
          oldStaffId?: string;
        }
      >({
        query: ({ body, eventId }) => ({
          url: `/calendar/events/${eventId}/reassign`,
          method: "PUT",
          data: body
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            let calendarServiceTagsToInvalidate = [];

            calendarServiceTagsToInvalidate.push({
              type: ReduxTagEnum.ManagerPto
            });

            calendarServiceTagsToInvalidate.push({
              type: ReduxTagEnum.Calendar,
              id: props.staffId
            });
            calendarServiceTagsToInvalidate.push({
              type: ReduxTagEnum.Availability,
              id: props.staffId
            });

            if (props.oldStaffId) {
              calendarServiceTagsToInvalidate.push({
                type: ReduxTagEnum.Calendar,
                id: props.oldStaffId
              });
            }

            setTimeout(() => {
              dispatch(
                calendarService.util.invalidateTags(
                  calendarServiceTagsToInvalidate
                )
              );
              dispatch(
                appointmentsService.util.invalidateTags([
                  { type: ReduxTagEnum.Appointments, id: props?.staffId }
                ])
              );
            }, DELAY_AFTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      deleteCalendarEvent: builder.mutation<
        null,
        {
          event_id: string;
          staff_or_team_id?: string;
          patient_id?: string;
          delete_type: RecurrenceUpdateTypeEnum;
        }
      >({
        query: ({
          event_id,
          delete_type
        }: {
          event_id: string;
          delete_type: RecurrenceUpdateTypeEnum;
        }) => {
          const params = QueryString_stringify({
            delete_type
          });
          return {
            url: `/calendar/events/${event_id}?${params}`,
            method: "DELETE"
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            setTimeout(() => {
              dispatch(
                calendarService.util.invalidateTags([
                  { type: ReduxTagEnum.Calendar, id: props?.patient_id },
                  { type: ReduxTagEnum.Calendar, id: props?.staff_or_team_id },
                  {
                    type: ReduxTagEnum.Availability,
                    id: props?.staff_or_team_id
                  },
                  { type: ReduxTagEnum.ManagerPto },
                  { type: ReduxTagEnum.ManagerPto, id: props?.staff_or_team_id }
                ])
              );
              dispatch(
                appointmentsService.util.invalidateTags([
                  { type: ReduxTagEnum.Appointments, id: props?.patient_id },
                  {
                    type: ReduxTagEnum.Appointments,
                    id: props?.staff_or_team_id
                  }
                ])
              );
            }, DELAY_AFTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      deleteCalendarEvents: builder.mutation<
        null,
        {
          patient_id: string;
        }
      >({
        query: ({
          patient_id,
        }: {
          patient_id: string;
        }) => {
          const params = QueryString_stringify({
            patient_id
          });
          return {
            url: `/calendar/events?${params}`,
            method: "DELETE"
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            setTimeout(() => {
              dispatch(
                calendarService.util.invalidateTags([
                  { type: ReduxTagEnum.Calendar, id: props?.patient_id },
                  { type: ReduxTagEnum.ManagerPto },
                ])
              );
              dispatch(
                appointmentsService.util.invalidateTags([
                  { type: ReduxTagEnum.Appointments, id: props?.patient_id },
                ])
              );
            }, DELAY_AFTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      updateStaffScheduleType: builder.mutation<
        null,
        {
          staff_id: string;
          schedule_type: string;
        }
      >({
        query: ({
          staff_id,
          schedule_type
        }: {
          staff_id: string;
          schedule_type: string;
        }) => {
          return {
            url: `/calendar/staff/${staff_id}/schedule-type`,
            data: {
              schedule_type_id: schedule_type
            },
            method: "PUT"
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            setTimeout(() => {
              dispatch(
                userService.util.invalidateTags([
                  { type: ReduxTagEnum.User, id: props?.staff_id }
                ])
              );
            }, DELAY_AFTER_UPDATE_SCHEDULE_REQUEST_COMPLETED);
          } catch (error) {}
        }
      })
    })
  });

export const {
  useGetCalendarEventQuery,
  useGetCalendarEventsQuery,
  useGetUpcomingCalendarEventsQuery,
  useGetPatientCalendarEventsQuery,
  useGetStaffRecurringEventsQuery,
  useGetManagerNursePtoQuery,
  useGetTeamNursePtoQuery,
  useCreateCalendarEventMutation,
  useReassignCalendarEventMutation,
  useUpdateCalendarEventMutation,
  useDeleteCalendarEventMutation,
  useDeleteCalendarEventsMutation,
  useUpdateStaffScheduleTypeMutation
} = calendarService;
