import { useMemo } from "react";
import RolesEnum from "../enums/RolesEnum";
import { useGetUserWithQueryParamsQuery } from "../services/UserService";

const useGetSortedNursesList = () => {
  const { data, ...otherProps } = useGetUserWithQueryParamsQuery({
    roles: [RolesEnum.TH_NURSE, RolesEnum.THN_MANAGER]
  });

  const nursesList = useMemo(() => {
    if (data === undefined) return undefined;

    return data
      .map((nurse) => {
        let label = "";
        if (nurse?.first) {
          label += nurse.first;
        }
        if (nurse?.last) {
          label += ` ${nurse.last}`;
        }
        // fall back to username if first and last name are not defined
        if (label.length === 0) {
          label = nurse.username;
        }
        return {
          value: nurse?.user_id,
          label
        };
      })
      .sort((a, b) => {
        return a?.label?.toLowerCase()?.localeCompare(b?.label?.toLowerCase());
      });
  }, [data]);

  return { data: nursesList, ...otherProps };
};

export default useGetSortedNursesList;
