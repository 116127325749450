import { useCallback, useMemo, useState } from "react";
import { DateTime } from "luxon";

import { AssignNursePatientModal } from "../../helpers/components/Forms/AssignNursePatientModal/AssignNursePatientModal";
import {
  HeaderComponent,
  IHeaderProps,
  TableComponentContainer,
  CustomDatePicker
} from "../../styling/StyleComponents";
import { TableColumn } from "../../components/Table/TableTypes";
import Table from "../../components/Table/Table";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import ErrorComponent from "../../components/ErrorComponent";
import { AssignProviderPatientModal } from "../../helpers/components/Forms/AssignProviderPatientModal/AssignProviderPatientModal";
import {
  CarerType,
  useGetCarerMemberReportQuery
} from "common/services/CarerMembersService";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { isProviderRole } from "common/enums/RolesEnum";
import DebouncedInput from "../../components/Input/DebouncedInput";
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  styled
} from "@mui/material";
import { FilterAltOutlined, LoopOutlined, Search } from "@mui/icons-material";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import { firstLastUsernameSearch } from "../../helpers/helpers";
import {
  checkIdValid,
  formatName,
  isFalsy,
  trimText
} from "common/helpers/helpers";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import DateRangePickerModal from "../../components/Modal/DateRangePickerModal";

const TABLE_INPUT_WIDTH = "clamp(200px, 250%, 350px)";
const DATE_FORMAT = "MM/dd/yyyy";

const StyledInput = styled(DebouncedInput)`
  background: #f5fbfe;
  width: ${TABLE_INPUT_WIDTH};
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
`;

const Fill = styled("div")`
  display: flex;
  flex: 1;
`;

interface IProps extends IHeaderProps {
  tableHeader: string;
  tableColumns: TableColumn[];
  isNoActivityList: boolean;
  externalLink?: string;
  searchEnabled?: boolean;
}

const PATIENT_LIMIT = 300;

const ALLOWED_STATUSES = [
  MemberStatusEnum.ACTIVE,
  MemberStatusEnum.INACTIVE,
  MemberStatusEnum.PENDING,
  MemberStatusEnum.REEVALUATING_PATIENT,
  MemberStatusEnum.NEVER_ENGAGED,
  MemberStatusEnum.CANCELED
];

const SELECT_STATUSES = ["All", ...ALLOWED_STATUSES];

const SELECTED_STATUSES_INITIAL_STATE = [
  MemberStatusEnum.ACTIVE,
  MemberStatusEnum.PENDING
];

const CarerPatientList = ({
  tableHeader = "",
  tableColumns,
  componentHeader,
  ComponentTooltip,
  isNoActivityList,
  externalLink,
  searchEnabled = false
}: IProps) => {
  const { user: savedUser, currentRole } = useSelector(
    (state: RootState) => state.auth
  );

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [providerModalOpen, setProviderModalOpen] = useState<boolean>(false);
  const [selectedPatientId, setSelectedPatientId] = useState<string>();
  const [searchText, setSearchText] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<MemberStatusEnum[]>(
    SELECTED_STATUSES_INITIAL_STATE
  );

  const [dateRangePicking, setDateRangePicking] = useState<
    "last_reading_date" | "last_encounter_date"
  >();
  const [lastReadingRange, setLastReadingRange] =
    useState<DateTime[]>(undefined);
  const [lastEncounterRange, setLastEncounterRange] =
    useState<DateTime[]>(undefined);

  const selectedStatusNoEmpty =
    selectedStatus.length === 0 ? ALLOWED_STATUSES : selectedStatus;

  const setSelectedPatientIdCallback = useCallback(
    (val: string) => {
      setSelectedPatientId(val);
    },
    [setSelectedPatientId]
  );

  const params = useSanitizedParams();
  const { nurseId, staffId } = params;

  const userId = nurseId ?? staffId ?? savedUser?.user_id;
  const invalidUserId = !isFalsy(userId) && !checkIdValid(userId);

  const { data: user } = useGetUserWithUsernameQuery({ username: userId });

  const userRoles = user?.user?.roles;
  const isProvider = useMemo(() => {
    if (userRoles === undefined) return undefined;
    for (const key in userRoles) {
      const userRole = userRoles[key];
      const includes = isProviderRole(userRole);
      if (includes) return true;
    }
    return false;
  }, [userRoles]);

  const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setSearchText(newText);
  };

  const trimmedText = useMemo(() => {
    const trimmedText = trimText(searchText);

    return trimmedText;
  }, [searchText]);

  const {
    data: dataCarer,
    isLoading: isLoadingCarer,
    error: errorCarer
  } = useGetCarerMemberReportQuery(
    {
      carer_id: userId,
      ...(isProvider && { size: PATIENT_LIMIT }),
      // Need to determine if the nurseId is populated, is this is a nurse or provider
      carer_type: isProvider ? CarerType.PROVIDER : CarerType.NURSE
    },
    {
      skip: user === undefined || invalidUserId
    }
  );

  const finalDataCarer = useMemo(() => {
    let filteredDataCarer = dataCarer?.filter((item) => {
      return selectedStatusNoEmpty.includes(item.patient.status);
    });

    if (trimmedText.length > 0) {
      filteredDataCarer = firstLastUsernameSearch(
        trimmedText,
        filteredDataCarer,
        "patient.username",
        "patient.fullname"
      );
    }

    if (lastEncounterRange) {
      filteredDataCarer = filteredDataCarer.filter((item) => {
        const lastNurseEncounter = DateTime.fromISO(
          item?.encounters?.last_nurse_encounter_date
        );
        const lastProviderEncounter = DateTime.fromISO(
          item?.encounters?.last_provider_encounter_date
        );
        const lastEncounter =
          lastNurseEncounter > lastProviderEncounter
            ? lastNurseEncounter
            : lastProviderEncounter;

        return (
          lastEncounterRange[0] <= lastEncounter &&
          lastEncounter <= lastEncounterRange[1]
        );
      });
    }
    if (lastReadingRange) {
      filteredDataCarer = filteredDataCarer.filter((item) => {
        const lastReading = DateTime.fromISO(item?.readings?.last_reading_date);
        return (
          lastReadingRange[0] <= lastReading &&
          lastReading <= lastReadingRange[1]
        );
      });
    }

    if (filteredDataCarer && isNoActivityList) {
      return filteredDataCarer?.filter((item) => {
        const order = item?.most_recent_order?.shipping?.delivered;
        if (order === undefined) {
          return false;
        }
        if (item.readings === undefined || item.encounters === undefined)
          return true;
        const now = DateTime.now();
        const lastReading = DateTime.fromISO(item.readings.last_reading_date);

        const lastEncounterNurse = DateTime.fromISO(
          item.encounters.last_nurse_encounter_date
        );

        return (
          lastReading < now.minus({ days: 7 }) ||
          lastEncounterNurse < now.minus({ days: 14 })
        );
      });
    } else {
      return filteredDataCarer;
    }
  }, [
    dataCarer,
    isNoActivityList,
    selectedStatusNoEmpty,
    trimmedText,
    lastEncounterRange,
    lastReadingRange
  ]);

  const setModalStateCallback = (val: string) => {
    if (val === "open") {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  };
  const setProviderModalStateCallback = (val: string) => {
    if (val === "open") {
      setProviderModalOpen(true);
    } else {
      setProviderModalOpen(false);
    }
  };

  const resetDisabled = useMemo(() => {
    const sortFn = (a, b) => a.localeCompare(b);
    const resetStatusInitialStateSorted = [...SELECTED_STATUSES_INITIAL_STATE]
      .sort(sortFn)
      .toString();
    const selectedStatusSorted = [...selectedStatus].sort(sortFn).toString();

    return (
      resetStatusInitialStateSorted === selectedStatusSorted &&
      lastEncounterRange === undefined &&
      lastReadingRange === undefined &&
      searchText === ""
    );
  }, [selectedStatus, searchText, lastEncounterRange, lastReadingRange]);

  const handleReset = () => {
    setSelectedStatus(SELECTED_STATUSES_INITIAL_STATE);
    setSearchText("");
    setLastEncounterRange(undefined);
    setLastReadingRange(undefined);
  };

  return (
    <TableComponentContainer>
      <HeaderComponent
        componentHeader={componentHeader}
        ComponentTooltip={ComponentTooltip}
        key={componentHeader}
      />
      <Row gap={"20px"} flexWrap={"wrap"}>
        {searchEnabled && (
          <>
            <Row>
              <FilterAltOutlined color="primary" />
              <Typography variant="h6" color="primary">
                Filter by
              </Typography>
            </Row>
            <TextField
              select
              label={"Select Member Status"}
              sx={{ width: 200 }}
              id={"Select Member Status"}
              defaultValue={selectedStatus.join(",")}
              slotProps={{
                select: {
                  variant: "outlined",
                  multiple: true,
                  value: selectedStatus,
                  onChange: (event) => {
                    const value = event.target.value;
                    // @ts-ignore
                    if (value.includes("All")) {
                      setSelectedStatus(ALLOWED_STATUSES);
                    } else setSelectedStatus(value as MemberStatusEnum[]);
                  }
                }
              }}
            >
              {SELECT_STATUSES.map((value) => (
                <MenuItem key={value} value={value}>
                  <>
                    {formatName(value, false)}{" "}
                    {selectedStatus.includes(value as MemberStatusEnum)}
                  </>
                </MenuItem>
              ))}
            </TextField>

            {isProvider === false && (
              <>
                <CustomDatePicker
                  onClick={() => setDateRangePicking("last_encounter_date")}
                  label="Last Contact"
                  value={
                    lastEncounterRange?.length > 1
                      ? lastEncounterRange[0].toFormat(DATE_FORMAT) +
                        " - " +
                        lastEncounterRange[1].toFormat(DATE_FORMAT)
                      : null
                  }
                />
                <CustomDatePicker
                  onClick={() => setDateRangePicking("last_reading_date")}
                  label="Last Reading"
                  value={
                    lastReadingRange?.length > 1
                      ? lastReadingRange[0].toFormat(DATE_FORMAT) +
                        " - " +
                        lastReadingRange[1].toFormat(DATE_FORMAT)
                      : null
                  }
                />
              </>
            )}
            <Button
              startIcon={<LoopOutlined />}
              onClick={handleReset}
              disabled={resetDisabled}
            >
              Reset
            </Button>

            <Fill />
            <StyledInput
              fullWidth
              debounceTimeout={300}
              value={searchText}
              onChange={handleText}
              type="text"
              placeholder="Search members"
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ backgroundColor: "transparent" }}
                    >
                      <Search />
                    </InputAdornment>
                  )
                }
              }}
            />
          </>
        )}
      </Row>
      {isLoadingCarer && <LoadingFallback delay={500} count={10} />}
      {finalDataCarer && (
        <Table
          data={finalDataCarer}
          tableProps={{
            externalLink,
            setSelectedPatientIdCallback,
            currentRole
          }}
          tableColumns={tableColumns}
          tableHeader={tableHeader}
        />
      )}
      {errorCarer && <ErrorComponent error={errorCarer} />}
      {invalidUserId && (
        <Typography
          variant="body1"
          color="error"
        >{`Error: Invalid User ID ${userId}`}</Typography>
      )}
      <AssignNursePatientModal
        key={`assignnurse_${selectedPatientId}`}
        modalOpen={modalOpen}
        setModalStateCallback={setModalStateCallback}
        selectedPatientId={selectedPatientId}
      />
      <AssignProviderPatientModal
        key={`assignprovider_${selectedPatientId}`}
        modalOpen={providerModalOpen}
        setModalStateCallback={setProviderModalStateCallback}
        selectedPatientId={selectedPatientId}
      />

      <DateRangePickerModal
        isVisible={dateRangePicking !== undefined}
        onCancel={() => setDateRangePicking(undefined)}
        onConfirm={(start, end) => {
          if (dateRangePicking === "last_encounter_date") {
            setLastEncounterRange([start.startOf("day"), end.endOf("day")]);
          } else if (dateRangePicking === "last_reading_date") {
            setLastReadingRange([start.startOf("day"), end.endOf("day")]);
          }
          setDateRangePicking(undefined);
        }}
      />
    </TableComponentContainer>
  );
};

export default CarerPatientList;
