import { createSlice } from "@reduxjs/toolkit";
import React from "react";
import { Alert_close } from "../helpers/AlertHelper";
import LocalizedStrings from "../localizations/LocalizedStrings";
import { store } from ".";

// Copied AlertButton and AlertOptions types from react-native
export interface AlertButtonType {
  text?: string | undefined;
  onPress?: () => void;
  style?: "default" | "cancel" | "destructive" | undefined;
  hasLoadingState?: boolean;
  loading?: boolean;
}

interface AlertOptions {
  cancelable?: boolean;
  userInterfaceStyle?: "unspecified" | "light" | "dark";
  onDismiss?: () => void;
}

interface ModalExplicitSize {
  width: string;
  height: string;
}

export type ModalSize = "small" | "medium" | "large" | ModalExplicitSize;
export interface AlertProps {
  title?: string;
  id?: string;
  // It is okay to use simple components that don't make any calculations for the "content" prop.
  // MUI components like "Box" and our "ErrorComponent" are fine for now.
  // More advanced components that perform calculations may cause an error.
  content?: React.ReactNode | React.ReactNode[];
  buttons?: AlertButtonType[];
  options?: AlertOptions;
  type?: "warning" | "error" | "info" | "success" | "default";
  row?: boolean;
  loading?: boolean;
  size?: ModalSize;
  hideCloseIcon?: boolean;
}

interface StateType extends AlertProps {
  visible: boolean;
}

const initialModalState: StateType = {
  visible: false,
  title: "",
  content: undefined,
  buttons: [],
  options: {},
  type: undefined,
  row: true,
  loading: false,
  id: undefined
};

const initialState = {
  visibleModals: []
};

function getDefaultButtons(id, title) {
  return [
    {
      text: LocalizedStrings.close,
      onPress: () => Alert_close({ dispatch: store.dispatch, id, title })
    }
  ];
}

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    alertShow: (state, action: { payload: AlertProps }) => {
      const {
        title,
        content,
        type,
        options,
        row = false,
        id,
        size,
        hideCloseIcon
      } = action.payload;

      const buttons = action.payload.buttons ?? getDefaultButtons(id, title);

      const modal = {
        title,
        content,
        type,
        buttons,
        options,
        row,
        // default to title if id doesn't exist
        id: id ?? title,
        visible: true,
        loading: false,
        size,
        hideCloseIcon
      };

      state.visibleModals.push(modal);
    },
    alertClose: (state, action: { payload: AlertProps }) => {
      const { id, title } = action.payload;

      const visibleModalIndex = state.visibleModals.findIndex(
        (i) => i.id === id || i.title === title
      );
      if (visibleModalIndex > -1) {
        state.visibleModals.splice(visibleModalIndex, 1);
      }
    },
    alertCloseAll: (state) => {
      state.visibleModals = [];
    },
    alertLoading: (state, action: { payload: AlertProps }) => {
      const { id, title } = action.payload;

      const visibleModalIndex = state.visibleModals.findIndex(
        (i) => i.id === id || i.title === title
      );
      if (visibleModalIndex > -1) {
        state.visibleModals[visibleModalIndex].loading = true;
      }
    }
  }
});

export const { alertShow, alertClose, alertCloseAll, alertLoading } =
  alertSlice.actions;

export default alertSlice.reducer;
