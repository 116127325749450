import { useEffect, useRef } from "react";

import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import { TurqoiseButton, WhiteButton } from "../../styling";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import ErrorComponent from "../../components/ErrorComponent";
import { Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import { useDeleteEncounterMutation } from "common/services/EncountersService";

// const DELAY = DELAY_AFTER_VISIT_REQUEST_COMPLETED + 500;
const DELAY = 500;

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  encounterId: string;
}

const DeleteEncounterConfirmationModal = ({
  isOpen,
  onRequestClose,
  encounterId
}: IProps) => {
  const dispatch = useAppDispatch();

  const [
    deleteEncounterMutation,
    {
      isSuccess: deleteEncounterSuccess,
      fulfilledTimeStamp: deleteEncounterFulfilledTimestamp,
      isLoading: deleteEncounterLoading,
      error: deleteEncounterError,
      reset: resetDeleteEncounter
    }
  ] = useDeleteEncounterMutation({});

  const isLoading = useGetDelayedLoadingBoolean(
    deleteEncounterLoading,
    deleteEncounterFulfilledTimestamp,
    deleteEncounterSuccess,
    DELAY
  );

  // const [
  //   deleteVisitMutation,
  //   {
  //     isSuccess: deleteVisitSuccess,
  //     fulfilledTimeStamp: deleteVisitFulfilledTimestamp,
  //     isLoading: deleteVisitLoading,
  //     error: deleteVisitError,
  //     reset: resetDeleteVisit
  //   }
  // ] = useDeleteVisitMutation({});

  // const isLoading = useGetDelayedLoadingBoolean(
  //   deleteVisitLoading,
  //   deleteVisitFulfilledTimestamp,
  //   deleteVisitSuccess,
  //   DELAY
  // );

  const closeModal = () => {
    onRequestClose();
    resetDeleteEncounter();
  };

  const onClick = () => {
    deleteEncounterMutation({
      // visit_id: encounterId
      encounterId
    });
  };

  const timerRef = useRef(null);

  useEffect(() => {
    if (deleteEncounterSuccess) {
      timerRef.current = setTimeout(() => {
        closeModal();
        resetDeleteEncounter();
      }, DELAY);

      return () => {
        if (timerRef.current) clearTimeout(timerRef.current);
      };
    }
  }, [deleteEncounterSuccess]);

  useEffect(() => {
    if (deleteEncounterError) {
      Alert_show({
        dispatch,
        id: "deleteEncounterError",
        title: "Something went wrong deleting the encounter.",
        content: <ErrorComponent error={deleteEncounterError} />,
        size: "small",
        type: "warning"
      });
      closeModal();
      resetDeleteEncounter();
    }
  }, [deleteEncounterError]);

  return (
    <StyledModal
      isOpen={isOpen}
      modalHeight="350px"
      modalWidth="300px"
      contentLabel="Delete Encounter Confirmation"
      onRequestClose={closeModal}
    >
      <ModalHeader onRequestClose={closeModal}>Delete Encounter</ModalHeader>
      <ModalBody>Are you sure you want to delete this encounter?</ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          <TurqoiseButton loading={isLoading} onClick={onClick} id="Yes">
            Yes
          </TurqoiseButton>
          <WhiteButton onClick={closeModal} id="Cancel">
            Cancel
          </WhiteButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default DeleteEncounterConfirmationModal;
