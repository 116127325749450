import { ChangeEvent } from "react";
import styled from "@emotion/styled";
import { Spinner } from "../../styling";
import DebouncedInput from "../Input/DebouncedInput";
import { InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { blue } from "../../styling/colors";

const TABLE_INPUT_WIDTH = "clamp(200px, 250%, 350px)";

export const StyledInput = styled(DebouncedInput)`
  background: ${blue[50]};
  width: ${TABLE_INPUT_WIDTH};
`;

const StyledSpinner = styled(Spinner)`
  left: unset;
  right: 40px;
  top: 12px;
`;

interface TableSearchInputFieldProps {
  searchText: string;
  handleText: (e: ChangeEvent<HTMLInputElement>) => void;
  isFetching: boolean;
}

function TableSearchInputField({
  searchText,
  handleText,
  isFetching
}: TableSearchInputFieldProps) {
  return (
    <StyledInput
      fullWidth
      debounceTimeout={300}
      value={searchText}
      onChange={handleText}
      type="text"
      placeholder="Search members"
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment
              position="end"
              sx={{ backgroundColor: "transparent" }}
            >
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ backgroundColor: "transparent" }}
            >
              <StyledSpinner loading={isFetching.toString()} />
            </InputAdornment>
          )
        }
      }}
    />
  );
}

export default TableSearchInputField;
