import RolesEnum from "common/enums/RolesEnum";
import {
  MEMBER_CHART,
  COMMON_ROUTES,
  PROVIDER_ROUTES,
  VISITS
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_MY_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  SIDEBAR_SUPPORT,
  PROVIDER_SCHEDULES_SIDEBAR
} from "../RouteSidebar";
import { PersonIcon } from "../../assets/images/icons";
import FeatureFlags from "common/config/FeatureFlags";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [
        SIDEBAR_MY_MEMBERS,
        SIDEBAR_ALL_MEMBERS,
        SIDEBAR_INACTIVE_MEMBERS,
        SIDEBAR_ORDERS
      ]
    }
  ];
  // provider_calendaring_feature_flag
  if (FeatureFlags().PROVIDER_CALENDARING) {
    sidebarItems.push({
      sectionName: SIDEBAR_SECTIONS.providers,
      items: [
        {
          itemName: "All Providers",
          itemLink: "/providers",
          ItemIcon: PersonIcon
        },
        PROVIDER_SCHEDULES_SIDEBAR
      ]
    });
  }

  sidebarItems.push(SIDEBAR_REPORTS);
  sidebarItems.push(SIDEBAR_SUPPORT);
  return sidebarItems;
};

const NURSE_PROVIDER = {
  label: "Nurse Provider",
  routes: [
    ...COMMON_ROUTES,
    ...PROVIDER_ROUTES,
    ...MEMBER_CHART(RolesEnum.NURSE_PROVIDER),
    VISITS
  ],
  sidebar: sidebar()
};

export default NURSE_PROVIDER;
