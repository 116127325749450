import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  event: null,
  eventId: null,
  carerTimezone: null
};

export const ptoSlice = createSlice({
  name: "pto",
  initialState,
  reducers: {
    resetPtoState: (state) => {
      state.event = initialState.event;
      state.eventId = initialState.eventId;
      state.carerTimezone = initialState.carerTimezone;
    },
    setPtoEvent: (state, action) => {
      state.event = action.payload;
    },
    setPtoEventId: (state, action) => {
      state.eventId = action.payload;
    },
    setCarerTimezone: (state, action) => {
      state.carerTimezone = action.payload;
    }
  }
});

export const { resetPtoState, setPtoEvent, setPtoEventId, setCarerTimezone } =
  ptoSlice.actions;

export default ptoSlice.reducer;
