export enum RelationshipNoteCategoryEnum {
  FAMILY = "FAMILY",
  TRAVEL = "TRAVEL",
  WORK = "WORK",
  PERSONAL = "PERSONAL",
  OTHER = "OTHER",
}

export enum RelationshipNoteStatusEnum {
  ACTIVE,
  INACTIVE
}

export interface RelationshipNoteType {
  relationship_note_id?: string;
  category: RelationshipNoteCategoryEnum;
  last_modified?: string;
  last_modified_by?: string;
  note?: string;
  patient_id: string;
  status?: RelationshipNoteStatusEnum;
}
