import MemberStatusEnum from "../enums/MemberStatusEnum";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import { getNameOrUsername } from "../helpers/helpers";
import CarerMemberType from "../types/CarerMemberType";
import UserType from "../types/UserType";
import UserTypeInner from "../types/UserTypeInner";
import { apiPAB, apiRemoteIQ } from "./AxiosService";
import { providesList } from "./ServicesCacheUtils";
import { encountersService } from "common/services/EncountersService";
import { DateTime } from "luxon";

export interface CarerMemberTypeReportType {
  patient: UserTypeInner;
  carer: UserType;
  readings: {
    compliance_periods: {
      start_date: string;
      end_date: string;
      reading_days: number;
    }[];
    last_reading_date: string;
  };
  encounters: {
    last_nurse_encounter_date: string;
    last_provider_encounter_date: string;
    minutes_month_to_date: number;
  };
  most_recent_order: {
    shipping: {
      delivered: string;
    };
  };
}

const providesTags = (result: CarerMemberType[] | undefined) => {
  return providesList<CarerMemberType, ReduxTagEnum.CarerMemberType>(
    result,
    ReduxTagEnum.CarerMemberType,
    (item: CarerMemberType) => item.carer_patient.patient_id
  );
};

const providesTagsCarerReport = (
  result: CarerMemberTypeReportType[] | undefined
) => {
  return providesList<CarerMemberTypeReportType, ReduxTagEnum.CarerMemberType>(
    result,
    ReduxTagEnum.CarerMemberType,
    (item: CarerMemberTypeReportType) => item.patient.user_id
  );
};

export enum CarerType {
  PROVIDER = "providers",
  NURSE = "nurses"
}

const carerMembersRemoteIQService = apiRemoteIQ
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.CarerMemberType]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCarerMemberReport: builder.query<
        CarerMemberTypeReportType[],
        { carer_id: string; carer_type: CarerType; limit?: number }
      >({
        query: ({ limit, carer_id, carer_type }) => {
          const params = QueryString_stringify({ limit });

          return {
            url: `/reports/carer-patients/${carer_type}/${carer_id}?${params}`,
            method: "GET"
          };
        },
        providesTags: providesTagsCarerReport,
        transformResponse: (
          response: CarerMemberTypeReportType[],
          meta,
          arg
        ) => {
          const finalResponse = response?.map((patient) => {
            const displayName = getNameOrUsername(patient.patient);
            return {
              ...patient,
              patient: { ...patient.patient, fullname: displayName }
            };
          });

          const assignedMembers = [...finalResponse]?.sort((a, b) => {
            return a?.patient?.fullname
              ?.toLowerCase()
              ?.localeCompare(b?.patient?.fullname?.toLowerCase());
          });

          return assignedMembers;
        }
      })
    })
  });

// TODO: I would propose to join getCarerMemberTypesByMember,
// getCarerMemberTypesByNurse and getCarerMemberTypesByProvider in one function.
export const carerMembersService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.CarerMemberType, ReduxTagEnum.Member]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getCarerMembersByMember: builder.query<CarerMemberType[], string>({
        query: (patient_id) => {
          return {
            url: `/carers-patients?patient_id=${patient_id}`,
            method: "GET"
          };
        },
        providesTags
      }),
      getCarerMembersByNurse: builder.query<CarerMemberType[], string>({
        query: (nurse_id: string) => {
          return {
            url: `/carers-patients?nurse_id=${nurse_id}`,
            method: "GET"
          };
        },
        providesTags
      }),
      getCarerMembersByProvider: builder.query<
        CarerMemberType[],
        {
          provider_id: string;
          patient_status: MemberStatusEnum[];
          size?: number;
        }
      >({
        query: ({ provider_id, patient_status, size = 3000 }) => {
          const params = QueryString_stringify({ patient_status, size });

          return {
            url: `/carers-patients?provider_id=${provider_id}&${params}`,
            method: "GET"
          };
        },
        providesTags,
        transformResponse: (response: CarerMemberType[]) => {
          const finalResponse = response.map((patient) => {
            const displayName = getNameOrUsername(patient.patient);
            return {
              ...patient,
              patient: { ...patient.patient, fullname: displayName }
            };
          });
          return finalResponse;
        }
      }),

      // Mutations
      assignNurseToMember: builder.mutation<
        CarerMemberType,
        Omit<{ nurse_id: string; patient_id: string }, "id">
      >({
        query: ({ nurse_id, patient_id }) => ({
          url: `/carers-patients/${patient_id}`,
          method: "PUT",
          data: { nurse_id, patient_id }
        }),
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.CarerMemberType },
          { type: ReduxTagEnum.CarerMemberType, id: arg.patient_id },
          { type: ReduxTagEnum.Member, id: arg.patient_id },
          { type: ReduxTagEnum.CarerMemberType, id: arg.nurse_id }
        ],
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            setTimeout(() => {
              dispatch(
                encountersService.util.invalidateTags([ReduxTagEnum.Encounter])
              );
              dispatch(
                carerMembersRemoteIQService.util.invalidateTags([
                  ReduxTagEnum.CarerMemberType
                ])
              );
            }, 2000);
          } catch (error) {}
        }
      }),
      deleteCarerMemberTypeRecord: builder.mutation<
        CarerMemberType,
        Omit<{ patient_id: string }, "id">
      >({
        query: ({ patient_id }) => ({
          url: `/carers-patients/${patient_id}`,
          method: "DELETE"
        }),
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.CarerMemberType },
          { type: ReduxTagEnum.CarerMemberType, id: arg.patient_id }
        ]
      }),
      deleteCarerMemberProvider: builder.mutation<
        CarerMemberType,
        Omit<{ patient_id: string }, "id">
      >({
        query: ({ patient_id }) => ({
          url: `/carers-patients/${patient_id}/provider`,
          method: "DELETE"
        }),
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.CarerMemberType },
          { type: ReduxTagEnum.CarerMemberType, id: arg.patient_id },
          { type: ReduxTagEnum.Member, id: arg.patient_id }
        ]
      }),
      deleteCarerMemberNurse: builder.mutation<
        CarerMemberType,
        Omit<{ patient_id: string }, "id">
      >({
        query: ({ patient_id }) => ({
          url: `/carers-patients/${patient_id}/nurse`,
          method: "DELETE"
        }),
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.CarerMemberType },
          { type: ReduxTagEnum.CarerMemberType, id: arg.patient_id },
          { type: ReduxTagEnum.Member, id: arg.patient_id }
        ]
      })
    })
  });

export const {
  useGetCarerMembersByNurseQuery,
  useGetCarerMembersByProviderQuery,
  useLazyGetCarerMembersByNurseQuery,
  useLazyGetCarerMembersByProviderQuery,
  useAssignNurseToMemberMutation,
  useDeleteCarerMemberTypeRecordMutation,
  useDeleteCarerMemberNurseMutation,
  useDeleteCarerMemberProviderMutation
} = carerMembersService;

export const { useGetCarerMemberReportQuery } = carerMembersRemoteIQService;
