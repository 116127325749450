import styled from "@emotion/styled";
import { DangerIcon } from "../../assets/images/icons";
import { getErrorMessage } from "common/helpers/helpers";

import { Spinner } from "../../styling";
import { CustomTooltip } from "../../styling/StyleComponents";
import DebouncedInput from "../Input/DebouncedInput";

const WIDTH = "clamp(200px, 250%, 414px)";

const NavbarSearchContainer = styled.div`
  position: relative;
  width: ${WIDTH};
`;

const StyledInput = styled(DebouncedInput)(() => ({
  "& input": {
    paddingLeft: "15px",
    height: "10px"
  },
  "& fieldset": {
    borderRadius: "10px"
  }
}));

const NavbarSpinner = styled(Spinner)`
  left: unset;
  right: 15px;
  top: 6px;
`;

const TooltipContainer = styled.div`
  position: absolute;
  right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const StyledDangerIcon = styled(DangerIcon)`
  width: 23px;
  height: 23px;
`;

function NavbarInputField({
  searchText,
  handleText,
  isFetching,
  isSearchError,
  searchErrors,
  noSearchResults
}: any) {
  return (
    <NavbarSearchContainer>
      <NavbarSpinner loading={isFetching.toString()} />
      {isSearchError && (
        <TooltipContainer>
          <CustomTooltip title={getErrorMessage(searchErrors)}>
            <StyledDangerIcon />
          </CustomTooltip>
        </TooltipContainer>
      )}
      {noSearchResults && searchText.length > 0 && (
        <TooltipContainer>
          <CustomTooltip title={"No search results found."}>
            <StyledDangerIcon />
          </CustomTooltip>
        </TooltipContainer>
      )}
      <StyledInput
        fullWidth
        debounceTimeout={300}
        value={searchText}
        onChange={handleText}
        type="text"
        placeholder="Search members"
        data-testid="NavbarInputField-search"
      />
    </NavbarSearchContainer>
  );
}

export default NavbarInputField;
