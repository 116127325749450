import { PersonIcon } from "../../assets/images/icons";
import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  COMPLETED_TODOS,
  INACTIVE_MEMBERS,
  MEMBER_CHART,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  STAFF_DETAILS,
  MY_TODOS,
  COMMON_ROUTES,
  PROVIDER_SCHEDULES,
  PROVIDER_LIST,
  PROVIDER_CALENDARING_ONLY,
  VISITS
} from "../RouteComponents";

import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_SUPPORT,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  PROVIDER_SCHEDULES_SIDEBAR
} from "../RouteSidebar";

import RolesEnum from "common/enums/RolesEnum";
import FeatureFlags from "common/config/FeatureFlags";

const sidebar = () => {
  const sidebarItems = [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "All Nurses",
          itemLink: "/nurses",
          ItemIcon: PersonIcon
        },
        { itemName: "To Do", itemLink: "/tasks", ItemIcon: PersonIcon }
      ]
    }
  ];

  // provider_calendaring_feature_flag
  if (FeatureFlags().PROVIDER_CALENDARING) {
    sidebarItems.push({
      sectionName: SIDEBAR_SECTIONS.providers,
      items: [
        {
          itemName: "All Providers",
          itemLink: "/providers",
          ItemIcon: PersonIcon
        },
        PROVIDER_SCHEDULES_SIDEBAR
      ]
    });
  }

  sidebarItems.push({
    sectionName: SIDEBAR_SECTIONS.members,
    items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
  });
  sidebarItems.push(SIDEBAR_REPORTS);
  sidebarItems.push(SIDEBAR_SUPPORT);

  return sidebarItems;
};

import MemberDetailsRedirect from "../../pages/MemberDetails/MemberDetailsRedirect";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";
import { RegisterPatient } from "../../pages/RegisterPatient";

const RCM_ADMIN = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: "/",
      components: ALL_MEMBERS({})
    },

    {
      path: "/nurses",
      components: NURSE_LIST({})
    },
    {
      path: "/nurses/nurseId/:userId",
      components: <MemberDetailsRedirect />
    },
    STAFF_DETAILS,
    {
      path: "/members",
      components: ALL_MEMBERS({})
    },
    {
      path: "/awaiting-provider",
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" }
        ]
      })
    },
    { path: "/inactive-members", components: INACTIVE_MEMBERS(true) },
    ...MEMBER_CHART(RolesEnum.RCM_ADMIN),
    {
      path: "/members/nurseId/:nurseId",
      components: [MEMBERS_WITH_NO_ACTIVITY({}), ASSIGNED_MEMBERS({})]
    },
    {
      path: "/orders",
      components: <OrderDevices />
    },
    {
      path: "/devices/:memberId",
      components: <OrderDevices />
    },
    ORDER_DETAILS(),
    STAFF_DETAILS,
    {
      path: "/register-member",
      components: <RegisterPatient />
    },

    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    COMPLETED_TODOS,
    MY_TODOS,
    // provider_calendaring_feature_flag
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/provider-schedules",
      components: [PROVIDER_SCHEDULES({})]
    },
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/providers",
      components: [
        PROVIDER_LIST({
          filterByAuthenticatedUser: false
        })
      ]
    },
    ...PROVIDER_CALENDARING_ONLY,
    VISITS
  ],
  sidebar: sidebar()
};

export default RCM_ADMIN;
