import { useGetOrdersByStaffIdQuery } from "common/services/OrdersService";
import Table from "../../../components/Table/Table";
import { useNavigate } from "react-router-dom";
import ErrorComponent from "../../../components/ErrorComponent";
import { getDeviceInfoBySku } from "../../../helpers/helpers";

const tableColumns = [
  { name: "order_id", size: 250 },
  { name: "orderPatientName" },
  { name: "ordered", size: 100 },
  { name: "orderStatus" },
  { name: "orderShipped", size: 100 },
  { name: "orderDelivered", size: 120 },
  { name: "tracking" },
  { name: "orderMember" },
  { name: "deviceOrdersItems", type: "dataList" }
];

interface IProps {
  staffId: string;
}

const OrdersTab = ({ staffId }: IProps) => {
  const navigate = useNavigate();

  const { data, error } = useGetOrdersByStaffIdQuery({ staffId });

  const mappedData = data?.map((item) => {
    const items = item.order?.items?.map((item) => {
      const { sku, quantity } = item;
      const deviceInfo = getDeviceInfoBySku(sku);
      const { description } = deviceInfo || {};
      if (description && quantity) {
        return {
          ...item,
          description: `${description} x${quantity}`
        };
      } else {
        return {
          ...item,
          description: `${sku} x${quantity}`
        };
      }
    });

    return {
      ...item,
      order: {
        ...item.order,
        items: items ?? []
      }
    };
  });
  return (
    <>
      {mappedData && (
        <Table
          data={mappedData}
          tableProps={{ navigate }}
          tableColumns={tableColumns}
          tableHeader="{{COUNT}} orders"
          estimateRowSize={(index: number) => {
            const item = mappedData[index];
            const itemsAmount = item?.order?.items?.length;

            return Math.max(79, 55 + 12 * itemsAmount);
          }}
        />
      )}
      <ErrorComponent error={error} />
    </>
  );
};

export default OrdersTab;
