import { DateTime } from "luxon";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import { apiVideo } from "./AxiosService";
import VideoRoomUpdateAction from "../enums/VideoRoomUpdateActionEnum";
import RoomType from "../types/RoomType";
import ChatType from "../types/ChatParticipantType";
import MemberChatType from "../types/MemberChatType";
import ChatParticipantType from "../types/ChatParticipantType";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { AppDispatch } from "../redux";

async function clearCache(
  queryFulfilled: PromiseWithKnownReason<any, any>,
  dispatch: AppDispatch
) {
  try {
    await queryFulfilled;
    setTimeout(() => {
      dispatch(videoService.util.invalidateTags([ReduxTagEnum.Video]));
    }, 3000);
  } catch (error) {}
}

const videoService = apiVideo
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Video]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      joinCarerRoom: builder.query<
        { access_token: string; room: RoomType },
        { code: string; carer_id: string }
      >({
        query: ({ code, carer_id }) => {
          return { url: `/join/carers/${code}/${carer_id}`, method: "GET" };
        },
        providesTags: [ReduxTagEnum.Video]
      }),
      joinMemberRoom: builder.query<
        { access_token: string; member_id: string },
        { code: string }
      >({
        query: ({ code }) => {
          return { url: `/join/members/${code}?$`, method: "GET" };
        },
        providesTags: [ReduxTagEnum.Video]
      }),
      getCarerOngoingCall: builder.query<
        RoomType,
        { carer_id: string; member_id: string }
      >({
        query: ({ carer_id, member_id }) => {
          return {
            url: `/rooms/carers/${carer_id}?member_id=${member_id}`,
            method: "GET"
          };
        },
        providesTags: [ReduxTagEnum.Video]
      }),
      getMemberOngoingCall: builder.query<RoomType, { member_id: string }>({
        query: ({ member_id }) => {
          return {
            url: `/rooms/members/${member_id}`,
            method: "GET"
          };
        },
        providesTags: [ReduxTagEnum.Video]
      }),
      // This is for member-facing requests, contains sanitized ChatParticipant type
      getPastMemberChats: builder.query<
        MemberChatType[],
        { member_id: string }
      >({
        query: ({ member_id }) => {
          return {
            url: `/chats/members/${member_id}`,
            method: "GET"
          };
        },
        providesTags: [ReduxTagEnum.Video]
      }),
      // This is for RIQ-facing requests, contains full ChatParticipant type
      getPastChatParticipants: builder.query<
        ChatParticipantType[],
        { participant_id: string }
      >({
        query: ({ participant_id }) => {
          return {
            url: `/chats/participants/${participant_id}`,
            method: "GET"
          };
        },
        providesTags: [ReduxTagEnum.Video]
      }),
      getExistingRoom: builder.query<RoomType, { member_id: string }>({
        query: ({ member_id }) => {
          return {
            url: `/rooms/members/${member_id}`,
            method: "GET"
          };
        },
        providesTags: [ReduxTagEnum.Video]
      }),

      // Mutations
      createRoom: builder.mutation<
        { join_url: string; code: string },
        Omit<
          { carerId: string; startDateTime: DateTime; memberId: string },
          "id"
        >
      >({
        query: ({ carerId, startDateTime, memberId }) => {
          const data = {
            start_datetime: startDateTime.toUTC().toISO(),
            carer_id: carerId,
            member_id: memberId
          };

          return {
            url: `/rooms`,
            method: "POST",
            data
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch);
        }
      }),
      disableRecording: builder.mutation<
        {},
        Omit<{ code: string; is_member_recording_opt_out: boolean }, "id">
      >({
        query: ({ code, is_member_recording_opt_out }) => {
          const data = {
            code: code,
            action: VideoRoomUpdateAction.OPT_OUT_MEMBER_RECORDING,
            is_member_recording_opt_out: is_member_recording_opt_out
          };
          return {
            url: `/rooms`,
            method: "PUT",
            data
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch);
        }
      }),
      closeRoom: builder.mutation<{}, Omit<{ code: string }, "id">>({
        query: ({ code }) => {
          const data = {
            code: code,
            action: VideoRoomUpdateAction.CLOSE
          };
          return {
            url: `/rooms`,
            method: "PUT",
            data
          };
        },
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch);
        }
      })
    })
  });

export const {
  useJoinCarerRoomQuery,
  useJoinMemberRoomQuery,
  useGetCarerOngoingCallQuery,
  useGetMemberOngoingCallQuery,
  useGetPastMemberChatsQuery,
  useGetPastChatParticipantsQuery,
  useGetExistingRoomQuery,
  useCreateRoomMutation,
  useDisableRecordingMutation,
  useCloseRoomMutation
} = videoService;
