import styled from "@emotion/styled";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography
} from "@mui/material";

import { RootState } from "common/redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ComponentHeader } from "../../styling";

import ReportType from "../../types/ReportType";
import ReportTypes from "./ReportTypes";

const TableHeading = styled(ComponentHeader)`
  margin: 24px 0;
`;

const StyledGrid = styled(Grid)`
  margin-bottom: 5px;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 200px;
`;

const StyledCardContent = styled(CardContent)`
  flex: 1;
`;

interface ReportItemProps {
  reportType: ReportType;
}

const ReportItem = ({ reportType }: ReportItemProps) => {
  const navigate = useNavigate();
  const { id, title, subtitle } = reportType;
  return (
    <StyledCard
      onClick={() => {
        navigate(id);
      }}
      sx={{ cursor: "pointer" }}
    >
      <StyledCardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {subtitle}
        </Typography>
      </StyledCardContent>

      <CardActions>
        <Button
          size="small"
          onClick={() => {
            navigate(id);
          }}
        >
          Select
        </Button>
      </CardActions>
    </StyledCard>
  );
};

const ReportListPage = () => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  return (
    <div style={{ margin: "36px" }}>
      <TableHeading>Report List</TableHeading>
      <StyledGrid container spacing={2} columns={{ xs: 2, md: 12 }}>
        {ReportTypes.filter((report) =>
          report.allowedRoles.includes(currentRole)
        ).map((reportType: ReportType) => {
          return (
            <Grid key={reportType.id} item xs={4}>
              <ReportItem reportType={reportType} />
            </Grid>
          );
        })}
      </StyledGrid>
    </div>
  );
};

export default ReportListPage;
