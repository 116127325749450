import { createSlice } from "@reduxjs/toolkit";
import CommunicationTypeEnum from "../enums/Calendaring/CommunicationTypeEnum";
import RecurrenceUpdateTypeEnum from "../enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";

const initialState = {
  selectedStartDate: null,
  selectedEndDate: null,
  communication_type: CommunicationTypeEnum.PHONE,
  attendees: [],
  staffId: null,
  staffName: null,
  recurrence: null,
  recurrenceUpdateType: RecurrenceUpdateTypeEnum.ONCE,
  appointmentType: null,
  memberName: null,
  assignedNurseId: null,
  memberTimezone: null,
  eventId: null,
  xTraceId: null
};

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    resetAppointmentState: (state) => {
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
      state.communication_type = initialState.communication_type;
      state.attendees = initialState.attendees;
      state.staffId = initialState.staffId;
      state.staffName = initialState.staffName;
      state.recurrence = initialState.recurrence;
      state.memberName = initialState.memberName;
      state.memberTimezone = initialState.memberTimezone;
      state.assignedNurseId = initialState.assignedNurseId;
      state.eventId = initialState.eventId;
      state.recurrenceUpdateType = initialState.recurrenceUpdateType;
      state.appointmentType = initialState.appointmentType;
      state.xTraceId = initialState.xTraceId;
    },
    resetStartEndDate: (state) => {
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
    },
    resetAppointmentSelections: (state) => {
      // don't reset attendees because those are passed from the previous page
      // state.attendees = initialState.attendees;
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
      state.communication_type = initialState.communication_type;
      state.staffId = initialState.staffId;
      state.staffName = initialState.staffName;
      state.recurrence = initialState.recurrence;
      state.recurrenceUpdateType = initialState.recurrenceUpdateType;
    },
    resetAppointmentToValues: (state, action) => {
      // don't reset attendees because those are passed from the previous page
      // state.attendees = initialState.attendees;
      state.selectedStartDate =
        action.payload?.selectedStartDate ?? initialState.selectedStartDate;
      state.selectedEndDate =
        action.payload?.selectedEndDate ?? initialState.selectedEndDate;
      state.communication_type =
        action.payload?.communication_type ?? initialState.communication_type;
      state.staffId = action.payload?.staffId ?? initialState.staffId;
      state.staffName = action.payload?.staffName ?? initialState.staffName;
      state.recurrence = action.payload?.recurrence ?? initialState.recurrence;
      state.memberName = action.payload?.memberName ?? initialState.memberName;
      state.recurrenceUpdateType =
        action.payload?.recurrenceUpdateType ??
        initialState.recurrenceUpdateType;
    },

    setStartDate: (state, action) => {
      state.selectedStartDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.selectedEndDate = action.payload;
    },
    setStartEndDate: (state, action) => {
      const { startDate, endDate } = action.payload;
      state.selectedStartDate = startDate;
      state.selectedEndDate = endDate;
    },
    setCommunicationType: (state, action) => {
      state.communication_type = action.payload;
    },
    setAttendees: (state, action) => {
      state.attendees = action.payload;
    },
    setMemberName: (state, action) => {
      state.memberName = action.payload;
    },
    setMemberTimezone: (state, action) => {
      state.memberTimezone = action.payload;
    },
    setStaffId: (state, action) => {
      state.staffId = action.payload;
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
    },
    setStaffName: (state, action) => {
      state.staffName = action.payload;
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
    },
    setAssignedNurseId: (state, action) => {
      state.assignedNurseId = action.payload;
    },
    setEventId: (state, action) => {
      state.eventId = action.payload;
    },
    setAppointmentType: (state, action) => {
      state.appointmentType = action.payload;
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
    },
    setRecurrence: (state, action) => {
      state.recurrence = action.payload;
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
    },
    setRecurrenceUpdateType: (state, action) => {
      state.recurrenceUpdateType = action.payload;
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
    },
    setXTraceId: (state, action) => {
      state.xTraceId = action.payload;
    }
  }
});

export const {
  resetAppointmentState,
  resetAppointmentSelections,
  resetStartEndDate,
  resetAppointmentToValues,
  setStartDate,
  setEndDate,
  setStartEndDate,
  setCommunicationType,
  setAttendees,
  setMemberName,
  setMemberTimezone,
  setStaffId,
  setStaffName,
  setAssignedNurseId,
  setEventId,
  setAppointmentType,
  setRecurrence,
  setRecurrenceUpdateType,
  setXTraceId
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
