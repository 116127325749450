import {
  SIDEBAR_DASHBOARD,
  SIDEBAR_MEMBER_MANAGEMENT,
  SIDEBAR_REPORTS
} from "../RouteSidebar";
import {
  REGISTER_MEMBER,
  REQUEST_CONSENTS,
  MY_CONVERSIONS,
  MY_REGISTRATIONS,
  MEMBER_CHART,
  REPORT_LIST,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  COMMON_ROUTES
} from "../RouteComponents";
import RolesEnum from "common/enums/RolesEnum";

const NPS = {
  routes: [
    ...COMMON_ROUTES,
    { path: "/", components: REGISTER_MEMBER },

    { path: "/register-member", components: REGISTER_MEMBER },
    { path: "/request-consents", components: REQUEST_CONSENTS },
    { path: "/my-conversions", components: MY_CONVERSIONS },
    { path: "/my-registrations", components: MY_REGISTRATIONS },
    ...MEMBER_CHART(RolesEnum.NPS),
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER
  ],
  sidebar: [SIDEBAR_DASHBOARD, SIDEBAR_MEMBER_MANAGEMENT(true), SIDEBAR_REPORTS]
};

export default NPS;
