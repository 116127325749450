import { useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";

import { useGetOrdersByMemberIdQuery } from "common/services/OrdersService";
import { useGetAthenaMemberDetailsQuery } from "common/services/MemberRegistrationService";
import OrderMemberType from "common/types/OrderMemberType";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";

import { getDeviceInfoBySku } from "../../helpers/helpers";

import cloneDeep from "lodash.clonedeep";
import { areAddressesEqual, checkIdValid } from "common/helpers/helpers";

function splitOrdersAndRefills(orders: OrderMemberType[]) {
  const devicesOrdered: OrderMemberType[] = [];
  const suppliesOrdered: OrderMemberType[] = [];
  orders?.forEach(async (item) => {
    const orderedItems = item?.order?.items;
    const devices: any[] = [];
    const device_addons: any[] = [];
    const supplies = [];
    if (orderedItems?.length > 0) {
      orderedItems.forEach((oi) => {
        let deviceInfo = getDeviceInfoBySku(oi?.sku);
        if (oi?.sku_type === "DEVICE") {
          devices.push({ ...oi, ...deviceInfo });
        } else if (oi?.sku_type === "INSERT") {
          device_addons.push({ ...oi, ...deviceInfo });
        } else if (oi?.sku_type === "REFILL") {
          supplies.push({ ...oi, ...deviceInfo });
        }
      });
    }
    if (devices.length > 0) {
      const devicesItem = cloneDeep(item);
      const device_addons_descriptions = device_addons?.map((da) => {
        if (da.description) {
          return da.description;
        }
        return null;
      });

      const device_addons_string = device_addons_descriptions?.join(", ");

      devicesItem.order.items = [
        ...devices,
        {
          description: device_addons_string,
          sku_type: "INSERT",
          sku: "device_addons_string",
          device_id: "device_addons_string"
        }
      ];
      devicesOrdered.push(devicesItem);
    }

    if (supplies.length > 0) {
      const suppliesItem = cloneDeep(item);
      suppliesItem.order.items = supplies;
      suppliesOrdered.push(suppliesItem);
    }
  });
  return { devicesOrdered, suppliesOrdered };
}

interface AdditionalProps {
  from?: DateTime;
  to?: DateTime;
}

const useGetOrderDevices = (
  memberId: string,
  additionalProps?: AdditionalProps
) => {
  const isValidId = checkIdValid(memberId);

  const [processedOrders, setProcessedOrders] = useState<OrderMemberType[]>();

  const {
    data: patientData,
    isFetching: isFetchingPatientDetails,
    isLoading: isLoadingPatientDetails,
    isError: isErrorPatientDetails,
    error: patientError,
    isSuccess: patientIsSuccess
  } = useGetMemberWithUsernameQuery(
    { username: memberId },
    {
      skip: memberId === undefined || isValidId === false,
      refetchOnMountOrArgChange: true
    }
  );

  const {
    data: orders,
    isFetching: isFetchingOrders,
    isLoading: isLoadingOrders,
    isError: isErrorOrders,
    error: ordersError,
    isSuccess: orderIsSuccess
  } = useGetOrdersByMemberIdQuery(
    {
      memberId
    },
    { skip: memberId === undefined || isValidId === false }
  );

  // Leaving this code commented for reference

  // const [getOrderStatusQuery] = useLazyGetOrderStatusQuery();

  // async function processOrders(orders: OrderMemberType[]) {
  //   if (needsStatus === false) {
  //     setProcessedOrders(orders);
  //     return;
  //   }

  //   const processedOrders = [...orders];
  //   const requests: Promise<any>[] = [];
  //   orders.forEach((order) => {
  //     const request = getOrderStatusQuery({ orderId: order.order.order_id });
  //     requests.push(request);
  //   });
  //   const responses = await Promise.allSettled(requests);

  //   responses.forEach((response, index) => {
  //     // @ts-ignore
  //     const { status, value } = response;
  //     if (status === "fulfilled" && value.data) {
  //       processedOrders[index] = {
  //         ...processedOrders[index],
  //         status: value.data.state_machine_status,
  //       };
  //     }
  //   });

  //   setProcessedOrders(processedOrders);
  // }

  useEffect(() => {
    if (orders === undefined) return;

    const filteredOrders = orders.filter((order) => {
      if (additionalProps?.from && additionalProps?.to) {
        const createdAt = DateTime.fromSQL(order.order.created_at);
        return (
          additionalProps?.from < createdAt && createdAt < additionalProps.to
        );
      } else return true;
    });

    setProcessedOrders(filteredOrders);
  }, [orders]);

  // @ts-ignore
  const { sortedOrders, devicesOrdered, suppliesOrdered } = useMemo(() => {
    if (processedOrders === undefined) return {};

    const sortedOrders = [...processedOrders].sort((a, b) => {
      const aDate = DateTime.fromSQL(a.order.created_at);
      const bDate = DateTime.fromSQL(b.order.created_at);

      return bDate.toMillis() - aDate.toMillis();
    });

    const { devicesOrdered, suppliesOrdered } =
      splitOrdersAndRefills(sortedOrders);

    return { sortedOrders, devicesOrdered, suppliesOrdered };
  }, [processedOrders]);

  const athenaId = patientData?.patient?.external_accounts?.athena;

  const {
    data: athenaPatient,
    isFetching: isFetchingAthena,
    isLoading: isLoadingAthena,
    isError: isErrorAthena,
    error: athenaError,
    isSuccess: athenaIsSuccess
  } = useGetAthenaMemberDetailsQuery(athenaId!, {
    skip: athenaId === undefined,
    refetchOnMountOrArgChange: true
  });

  let pabPatientAddress, athenaPatientAddress;
  if (patientData?.patient?.address) {
    const { city, postal_code, state, street1, street2 } =
      patientData.patient.address;
    pabPatientAddress = {
      city,
      country: "US",
      postal_code,
      state,
      street1,
      street2: street2 ?? ""
    };
  }

  if (athenaPatient) {
    const { city, postal_code, state, street1, street2 } = athenaPatient;
    athenaPatientAddress = {
      city,
      country: "US",
      postal_code,
      state,
      street1,
      street2: street2 ?? ""
    };
  }

  const addressesEqual = areAddressesEqual(
    pabPatientAddress,
    athenaPatientAddress
  );

  const isLoading = useMemo(() => {
    return isLoadingAthena || isLoadingPatientDetails || isLoadingOrders;
  }, [isLoadingAthena, isLoadingPatientDetails, isLoadingOrders]);

  const isFetching = useMemo(() => {
    return isFetchingAthena || isFetchingPatientDetails || isFetchingOrders;
  }, [isFetchingAthena, isFetchingPatientDetails, isFetchingOrders]);

  return {
    data: {
      athena: athenaPatient,
      orders: sortedOrders,
      patient: patientData,
      addresses: {
        addressesEqual,
        athenaPatientAddress,
        pabPatientAddress
      },
      devicesOrdered,
      suppliesOrdered
    },
    isSuccess:
      patientIsSuccess &&
      (athenaId === undefined || athenaIsSuccess) &&
      orderIsSuccess,
    isLoading,
    isFetching,
    isError: isErrorAthena || isErrorPatientDetails || isErrorOrders,
    error: {
      patient: patientError,
      athena: athenaError,
      orders: ordersError
    }
  };
};

export default useGetOrderDevices;
