import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Spinner } from "../../styling";
import { useRegisterMemberMutation } from "common/services/MemberRegistrationService";
import { RegisterMemberRequestBody } from "common/types/requestBody/RegisterMemberRequestBody";
import ErrorComponent from "../../components/ErrorComponent";

const SpinnerContainer = styled.div`
  position: relative;
`;

const StyledSpinner = styled(Spinner)`
  width: 16px;
  height: 16px;
  left: 8px;
  top: 8px;
`;

interface IngestComponentProps {
  shouldIngest: boolean;
  shouldIngestCallback: (value: boolean) => void;
  selectedAthenaLink: string;
  RegisterMemberRequestBody: RegisterMemberRequestBody;
  ingestedCallback: (value: boolean) => void;
  setLeadsIdCallback: (value: string) => void;
}

export function IngestComponent({
  shouldIngest,
  shouldIngestCallback,
  selectedAthenaLink,
  RegisterMemberRequestBody,
  ingestedCallback,
  setLeadsIdCallback
}: IngestComponentProps) {
  const [ingestLoading, setIngestLoading] = useState<boolean>(false);
  const [ingestResponse, setIngestResponse] = useState(null);
  const [ingestError, setIngestError] = useState(null);

  // Expect selectedAthenaLink to be a string separated by hyphens:
  const [athenaId, providerId, departmentId] = selectedAthenaLink.split("-");

  const [registerPatientMutation] = useRegisterMemberMutation();

  useEffect(() => {
    async function register(
      requestBody,
      athena_id,
      provider_id,
      department_id
    ) {
      const res = await registerPatientMutation({
        body: {
          ...requestBody,
          athena_id,
          department_id,
          provider_id
        }
      });
      let data, error;
      if ("data" in res) {
        data = res.data;
      }
      if ("error" in res) {
        error = res.error;
      }
      return { response: data, error };
    }

    if (shouldIngest && !ingestResponse && !ingestError && !ingestLoading) {
      setIngestLoading(true);
      shouldIngestCallback(false);
      register(
        RegisterMemberRequestBody,
        athenaId,
        providerId,
        departmentId
      ).then((res) => {
        const { response, error } = res;
        setLeadsIdCallback(response?.id);
        setIngestResponse(response);
        ingestedCallback(true);
        setIngestLoading(false);
        if (error) {
          setIngestError(error);
          ingestedCallback(false);
          setIngestLoading(false);
        }
      });
    }
  }, [
    athenaId,
    ingestError,
    ingestResponse,
    RegisterMemberRequestBody,
    shouldIngest,
    ingestLoading,
    ingestedCallback
  ]);

  return (
    <>
      {ingestLoading && (
        <SpinnerContainer>
          <StyledSpinner as="div" loading={ingestLoading.toString()} />
        </SpinnerContainer>
      )}
      {ingestError && <ErrorComponent error={ingestError} />}
    </>
  );
}
