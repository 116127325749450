enum CarePlanPhaseEnum {
  ONBOARDING = "ONBOARDING",
  CONCIERGE = "CONCIERGE",
  FOCUSED_IMPROVEMENT = "FOCUSED_IMPROVEMENT",
  MAINTENANCE = "MAINTENANCE"
}

enum CarePlanConditionsEnum {
  DIABETES = "DIABETES",
  HYPERTENSION = "HYPERTENSION"
}

enum CarePlanVisitFrequencyEnum {
  WEEKLY = "WEEKLY",
  BIWEEKLY = "BIWEEKLY",
  MONTHLY = "MONTHLY"
}

interface CarePlanType {
  patient_id: string;
  conditions?: CarePlanConditionsEnum[];
  member_journey_phase?: CarePlanPhaseEnum;
  target_time_per_month?: number;
  visit_frequency?: CarePlanVisitFrequencyEnum;
}

export default CarePlanType;

export {
  CarePlanConditionsEnum,
  CarePlanVisitFrequencyEnum,
  CarePlanPhaseEnum
};
