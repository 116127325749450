import { useMemo, useState } from "react";
import styled from "@emotion/styled";
import { DateTime } from "luxon";

import MemberStatusEnum from "common/enums/MemberStatusEnum";
import RolesEnum from "common/enums/RolesEnum";
import MemberType from "common/types/MemberType";

import { AssignNursePatientModal } from "../../helpers/components/Forms/AssignNursePatientModal/AssignNursePatientModal";
import { AssignProviderPatientModal } from "../../helpers/components/Forms/AssignProviderPatientModal/AssignProviderPatientModal";
import { useGetAllPatientList } from "../../hooks/data_loaders/useGetAllPatientList";
import {
  HeaderComponent,
  IHeaderProps,
  TableComponentContainer
} from "../../styling/StyleComponents";
import { TableColumn } from "../../components/Table/TableTypes";
import Table from "../../components/Table/Table";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import DateFilterFieldEnum from "../../enums/DateFilterFieldEnum";
import DateRangePicker from "../../components/DateRangePicker";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import ErrorComponent from "../../components/ErrorComponent";
import ContactAttemptModal from "../../components/Modal/ContactAttemptModal";
import ContactAttemptDetailsModal from "../../components/Modal/ContactAttemptDetailsModal";
import MemberRetentionActionsType from "common/types/MemberRetentionActionsType";
import MemberStatusReasonEnum from "common/enums/MemberStatusReasonEnum";
import SurveyModal from "../PatientData/Survey/SurveyModal";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { isFalsy } from "common/helpers/helpers";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 20px;
`;

interface IProps extends IHeaderProps {
  userListFilters: {
    roles?: RolesEnum[];
    status: MemberStatusEnum[];
    linked_entities?: MemberLinkedEntitiesEnum[];
    status_reason?: MemberStatusReasonEnum[];
  };
  filterByAssignedNurse?: boolean;
  loadReadingsEncounters: boolean;
  externalLink?: string;
  tableColumns: TableColumn[];
  tableHeader: string;
  dateFilterField?: DateFilterFieldEnum;
  filterBy?: (item: MemberType) => boolean;
  sortBy?: (a: MemberType, b: MemberType) => number;
}

const PatientList = ({
  userListFilters,
  loadReadingsEncounters,
  filterByAssignedNurse,
  componentHeader,
  ComponentTooltip,
  tableColumns,
  externalLink,
  tableHeader,
  dateFilterField,
  filterBy,
  sortBy
}: IProps) => {
  const [assignNurseModalOpen, setAssignNurseModalOpen] =
    useState<boolean>(false);
  const [providerModalOpen, setProviderModalOpen] = useState<boolean>(false);
  const [contactAttemptSendModalOpen, setContactAttemptSendModalOpen] =
    useState<boolean>(false);
  const [surveyModalOpen, setSurveyModalOpen] = useState<boolean>(false);

  const [selectedRetentionActions, setSelectedRetentionActions] =
    useState<MemberRetentionActionsType[]>(undefined);

  const [selectedPatientId, setSelectedPatientId] = useState<string>();

  const now = DateTime.now();
  const [startDate, setStartDate] = useState<DateTime>(
    dateFilterField != undefined ? now.minus({ days: 30 }) : undefined
  );
  const [endDate, setEndDate] = useState<DateTime>(
    dateFilterField != undefined ? now : undefined
  );

  const { user, currentRole } = useSelector((state: RootState) => state.auth);

  const setModalStateCallback = (value: string) => {
    setAssignNurseModalOpen(value === "open");
  };

  const setProviderModalStateCallback = (value: string) => {
    setProviderModalOpen(value === "open");
  };

  const onContactAttemptClose = () => {
    setContactAttemptSendModalOpen(false);
  };

  const openContactAttemptModalHandler = () => {
    setContactAttemptSendModalOpen(true);
  };

  const listFilters = {
    ...userListFilters,
    carer_id: filterByAssignedNurse ? user?.user_id : undefined
  };

  const { data, isLoading, isError, error, isDataSummarized } =
    useGetAllPatientList(
      dateFilterField === DateFilterFieldEnum.ENROLLED
        ? {
            ...listFilters,
            enrolled_after: startDate?.toISODate(),
            enrolled_before: endDate?.toISODate()
          }
        : listFilters,
      loadReadingsEncounters,
      dateFilterField === DateFilterFieldEnum.CREATED_AT
        ? {
            field: dateFilterField,
            startDate,
            endDate
          }
        : undefined
    );

  const componentHeaderWithDays =
    dateFilterField && endDate && startDate
      ? `${componentHeader} (${Math.floor(
          endDate.diff(startDate, "days").days
        )} Days)`
      : componentHeader;

  const processedData = useMemo(() => {
    if (data === undefined) return undefined;

    let copiedData = [...data];
    if (filterBy) {
      copiedData = data.filter((item) => filterBy(item));
    }
    if (sortBy) {
      copiedData = data.sort(sortBy);
    }
    return copiedData;
  }, [data, filterBy, sortBy]);

  const { data: selectedPatient } = useGetMemberWithUsernameQuery(
    {
      username: selectedPatientId
    },
    {
      skip: selectedPatientId === undefined
    }
  );

  const hasActions = tableColumns?.find(
    (column) => column.name === "patientActions"
  );

  return (
    <TableComponentContainer>
      <HeaderComponent
        componentHeader={componentHeaderWithDays}
        ComponentTooltip={ComponentTooltip}
        key={componentHeader}
      />

      {dateFilterField != undefined && (
        <Row>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={(dateTime: DateTime) => {
              setStartDate(dateTime.startOf("day"));
            }}
            onEndDateChange={(dateTime: DateTime) => {
              setEndDate(dateTime.endOf("day"));
            }}
            maxDate={DateTime.now()}
          />
        </Row>
      )}

      {isLoading && <LoadingFallback delay={500} count={10} />}
      {!isLoading && processedData && processedData?.length >= 0 && (
        <Table
          tableHeader={isDataSummarized ? undefined : tableHeader}
          tableColumns={tableColumns}
          tableProps={{
            currentRole,
            setSelectedPatientIdCallback: setSelectedPatientId,
            setModalStateCallback,
            setProviderModalStateCallback,
            openContactAttemptModalHandler,
            openContactAttemptDetailsModalHandler: (
              contactAttempts: MemberRetentionActionsType[]
            ) => setSelectedRetentionActions(contactAttempts),
            externalLink
          }}
          data={processedData}
        />
      )}

      {isError && <ErrorComponent error={error} />}

      {!isFalsy(hasActions) && (
        <>
          <AssignNursePatientModal
            key={`assignnurse_${selectedPatientId}`}
            modalOpen={assignNurseModalOpen}
            setModalStateCallback={setModalStateCallback}
            selectedPatientId={selectedPatientId}
          />
          <AssignProviderPatientModal
            key={`assignprovider_${selectedPatientId}`}
            modalOpen={providerModalOpen}
            setModalStateCallback={setProviderModalStateCallback}
            selectedPatientId={selectedPatientId}
          />
        </>
      )}

      <ContactAttemptModal
        isOpen={contactAttemptSendModalOpen}
        onRequestClose={onContactAttemptClose}
        selectedPatientId={selectedPatientId}
        handleOpenSurveyModal={() => setSurveyModalOpen(true)}
      />
      {!isFalsy(hasActions) && (
        <SurveyModal
          isOpen={surveyModalOpen}
          onRequestClose={() => setSurveyModalOpen(false)}
          title={"Cancellation Reasons"}
          patient={selectedPatient}
        />
      )}

      <ContactAttemptDetailsModal
        retentionActions={selectedRetentionActions}
        onRequestClose={() => setSelectedRetentionActions(undefined)}
        memberId={selectedPatientId}
        key={`contactModal${selectedPatientId}`}
      />
    </TableComponentContainer>
  );
};

export default PatientList;
