import { ComponentHeader } from "../../styling";
import { TableComponentContainer } from "../../styling/StyleComponents";
import { useGetTeamsQuery } from "common/services/TeamsService";
import { TableColumn } from "../../components/Table/TableTypes";
import ErrorComponent from "../../components/ErrorComponent";
import Table from "../../components/Table/Table";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";

interface IProps {
  types?: TeamTypeEnum[];
  tableColumns?: TableColumn[];
}

const AllTeams = ({
  tableColumns = [
    { name: "teamCreatedAt", size: 120 },
    { name: "teamName", externalLink: "/team/:userId" },
    { name: "teamType" }
  ],
  types = undefined
}: IProps) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const { data, isFetching, error, isError } = useGetTeamsQuery(
    { types },
    { skip: user === undefined }
  );

  return (
    <TableComponentContainer>
      <ComponentHeader>Clinical Teams</ComponentHeader>

      {isError && <ErrorComponent error={error} />}
      {isFetching && <LoadingFallback delay={500} count={10} />}
      {data && (
        <Table tableColumns={tableColumns} tableProps={{}} data={data} />
      )}
    </TableComponentContainer>
  );
};

export default AllTeams;
