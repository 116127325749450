import { useMemo } from "react";
import styled from "@emotion/styled";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import get from "lodash.get";
import MemberType from "common/types/MemberType";
import EncounterSubmitterType from "common/types/EncounterSubmitterType";

const UserCount = styled.div<{ padding?: string; margin?: string }>`
  margin: ${(props) => props.margin ?? "8px 0px 24px 0px"};
  width: 95%;
  padding: ${(props) => props.padding ?? "0"};
  color: rgba(24, 24, 25, 0.42);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

interface IProps {
  users: (MemberType | EncounterSubmitterType)[];
  tableHeader: string;
  tableHeaderUserAccessor?: string;
  padding?: string;
  margin?: string;
}

const TableHeaderUserCount = ({
  users,
  tableHeader,
  tableHeaderUserAccessor = "patient.status",
  padding,
  margin
}: IProps) => {
  const tableHeaderString = useMemo(() => {
    if (
      tableHeader === undefined ||
      users === undefined ||
      users.length === 0
    ) {
      return "";
    }

    const active = users.filter(
      (user: any) =>
        get(user, tableHeaderUserAccessor) === MemberStatusEnum.ACTIVE
    );
    const pending = users?.filter(
      (user: any) =>
        get(user, tableHeaderUserAccessor) === MemberStatusEnum.PENDING
    );

    return tableHeader
      .replace("{{COUNT}}", "" + users.length)
      .replace("{{MemberStatusEnum.ACTIVE}}", "" + active.length)
      .replace("{{MemberStatusEnum.PENDING}}", "" + pending.length);
  }, [tableHeader, users, tableHeaderUserAccessor]);

  return (
    <UserCount padding={padding} margin={margin}>
      {tableHeaderString}
    </UserCount>
  );
};

export default TableHeaderUserCount;
