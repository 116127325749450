import { useEffect, useMemo, ReactNode, CSSProperties } from "react";
import styled from "@emotion/styled";
import cloneDeep from "lodash.clonedeep";
import c3, { ChartType } from "c3";
import { DateTime } from "luxon";
import "c3/c3.css";
import "react-loading-skeleton/dist/skeleton.css";
import { getFormattedDateTime } from "../../../helpers/helpers";
import { ErrorText } from "../../../styling/StyleComponents";
import {
  ChartHeader,
  ChartHeaderText,
  ChartLeft,
  ChartSubHeaderText,
  getBloodPressureColorFromDataIndex,
  transformBloodPressureDataToColumns
} from "../../../helpers/components/Charts/ChartHelpers";
import MemberType from "common/types/MemberType";
import ErrorComponent from "../../../components/ErrorComponent";
import { CustomDataPoint } from "../../../@types/customc3";
import DeviceReadingType from "common/types/DeviceReadingType";

const BloodPressureChartContainer = styled.div<{
  chartId: string;
  isBlackAndWhite: boolean;
}>`
  position: ${(props) => (props.isBlackAndWhite ? "absolute" : "relative")};
  margin: 36px;
  border-radius: 4px;
  border: 1px solid #e6e7e9;
  background: #ffffff;
  padding: 18px 8px;
  height: 100%;
  transition: all 0.66s ease-out;

  ${(props) => `#${props.chartId}`} {
    width: 700px;
    height: 200px;
    margin-left: 24px;
  }

  ${(props) => `#${props.chartId}`} svg {
    width: calc(100%);
  }

  ${(props) => `#${props.chartId}`} .c3-circle {
    r: 4;
  }

  ${(props) => `#${props.chartId}`} .c3-circle._expanded_ {
    visibility: visible;
    r: 6;
  }

  ${(props) => `#${props.chartId}`} .c3-legend-background {
    stroke: none;
  }

  .c3-circles {
    display: none;
  }

  // dashed lines
  .c3-shapes-SYSTOLIC.c3-lines.c3-lines-SYSTOLIC {
    stroke-dasharray: 5, 5;
  }
  .c3-line-SYSTOLIC.c3-line {
    stroke-width: 2px;
  }
  .c3-line-DIASTOLIC.c3-line {
    stroke-width: 2px;
  }

  // hide dots

  .c3-circles-DIASTOLIC-AVG {
    display: none;
  }

  .c3-circles-SYSTOLIC-AVG {
    display: none;
  }

  ${(props) => `#${props.chartId}`} .c3-legend-item {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    color: rgba(24, 24, 25, 0.42);
  }

  ${(props) => `#${props.chartId}`} .c3-axis-x-label {
    font: 13px "Inter";
  }

  .tick line {
    visibility: hidden;
  }
`;

interface IProps {
  bloodPressureData: DeviceReadingType[];
  bloodPressureError: unknown;
  patient?: MemberType;
  startDate: DateTime;
  endDate: DateTime;
  renderMoreInfo?: () => ReactNode;
  chartCssId: string;
  isBlackAndWhite: boolean;
  style?: CSSProperties;
}

const X_AXIS_MAX_TICK_COUNT = 13.1;

const generateData = ({
  data,
  isBlackAndWhite,
  filteredBloodPressureData,
  chartSvgCssId,
  patientTimezone
}) => {
  if (!data) return;
  const { columns, pulseArr, xAxisParams } = data;

  const finalColumns = columns;
  const [
    dateArr,
    systolicArr,
    diastolicArr,
    dataArraySystolic,
    dataArrayDiastolic
  ] = columns;

  // possible future enhancement could be a dropdown to toggle between different modes, like line, spline, area, bar, etc.
  const dataTypes = {
    SYSTOLIC: "spline" as ChartType,
    DIASTOLIC: "spline" as ChartType
  };

  const dataColors = {
    // bp chart color #243357
    DIASTOLIC: isBlackAndWhite ? "#2c2e34" : "#243357",
    SYSTOLIC: isBlackAndWhite ? "#2c2e34" : "#243357"
  };

  const names = {
    DIASTOLIC_AVG__: `Diastolic Average ${
      dataArrayDiastolic?.[1] ? `(${dataArrayDiastolic?.[1]})` : ""
    }`,
    SYSTOLIC_AVG__: `Systolic Average ${
      dataArraySystolic?.[1] ? `(${dataArraySystolic?.[1]})` : ""
    }`,
    SYSTOLIC: "Systolic",
    DIASTOLIC: "Diastolic"
  };

  if (dateArr && systolicArr && diastolicArr) {
    if (filteredBloodPressureData?.length === 0) {
      // if no data found, display "No Data Found" chart
      c3.generate({
        bindto: `#${chartSvgCssId}`,
        data: {
          x: "date",
          xFormat: "%m/%d/%Y %H:%M",
          columns: finalColumns
        },
        legend: {
          show: false
        },
        axis: {
          x: {
            type: "category",
            label: {
              text: "No readings taken in selected time range",
              position: "inner-right"
            },
            tick: {
              outer: false
            },
            ...xAxisParams
          },
          y: {
            tick: {
              outer: false
            },
            label: "mmHg"
          }
        },
        padding: {
          bottom: 20 //adjust chart padding bottom
        }
      });
    } else {
      c3.generate({
        bindto: `#${chartSvgCssId}`,
        data: {
          x: "date",
          xFormat: "%m/%d/%Y, %H:%M:%S %p",
          columns: finalColumns,
          types: dataTypes,
          names: names,
          colors: dataColors,
          color: (color, dataPoint) => {
            return isBlackAndWhite
              ? "#2c2e34"
              : getBloodPressureColorFromDataIndex(
                  color,
                  dataPoint as CustomDataPoint,
                  systolicArr,
                  diastolicArr
                );
          }
        },
        legend: {
          show: false
        },
        axis: {
          x: {
            type: "timeseries",
            tick: {
              format: "%b %d",
              count: X_AXIS_MAX_TICK_COUNT
            }
          },
          y: {
            tick: {
              outer: false
            },
            label: "mmHg"
          }
        },
        padding: {
          bottom: 20 //adjust chart padding bottom
        },
        tooltip: {
          contents: function (
            d: CustomDataPoint[],
            defaultTitleFormat,
            defaultValueFormat,
            color
          ) {
            const config = this.config,
              nameFormat =
                config.tooltip_format_name ||
                function (name: string) {
                  return name;
                },
              valueFormat = config.tooltip_format_value || defaultValueFormat;

            let text, i, title, value, name, bgcolor;
            bgcolor = this.levelColor;

            const pulseIndex = d?.[0]?.index;
            const pulse = pulseArr?.[pulseIndex];

            for (i = 0; i < d.length; i++) {
              if (!(d[i] && (d[i].value || d[i].value === 0))) {
                continue;
              }

              if (!text) {
                const titleValue = getFormattedDateTime(
                  d[i].x as DateTime,
                  patientTimezone
                );
                title = titleValue;
                text =
                  "<table class='" +
                  this.CLASS.tooltip +
                  "'>" +
                  (title ? "<tr><th colspan='2'>" + title + "</th></tr>" : "");
              }

              name = nameFormat(d[i].name);
              value = valueFormat(d[i].value, d[i].name, d[i].id, d[i].index);
              bgcolor = this.levelColor
                ? this.levelColor(d[i].value)
                : color(d[i].id);
              text +=
                "<tr class='" + this.CLASS.tooltipName + "-" + d[i].id + "'>";
              text +=
                "<td class='name'><span style='background-color:" +
                bgcolor +
                "'></span>" +
                name +
                "</td>";
              text += "<td class='value'>" + value + "</td>";

              text += "</tr>";
            }

            // add  pulse row
            text +=
              "<tr class='" + this.CLASS.tooltipName + "-" + "PULSE" + "'>";
            text +=
              "<td class='name'><span style='background-color:" +
              "green" +
              "'></span>" +
              "PULSE" +
              "</td>";
            text += "<td class='value'>" + pulse + "</td>";

            text += "</tr>";

            return text + "</table>";
          }
        }
      });
    }
  }
};

const ReportBloodPressureChart = ({
  bloodPressureData,
  bloodPressureError,
  patient,
  renderMoreInfo,
  chartCssId,
  isBlackAndWhite,
  startDate,
  endDate
}: IProps) => {
  const chartSvgCssId = `chart${chartCssId}`;

  const DAYS = endDate.diff(startDate, "days").as("days");

  const filteredBloodPressureData = useMemo(() => {
    return bloodPressureData?.filter((item) => {
      return (
        Math.abs(
          DateTime.fromSeconds(item.measure_timestamp)
            .diff(endDate, "days")
            .as("days")
        ) < DAYS
      );
    });
  }, [bloodPressureData]);

  const patientTimezone = patient?.patient?.timezone;

  const data = useMemo(() => {
    if (filteredBloodPressureData === undefined) return;
    const dataCopy = cloneDeep(filteredBloodPressureData);
    const columns = transformBloodPressureDataToColumns(
      dataCopy,
      patientTimezone,
      true
    );
    return columns;
  }, [bloodPressureData]);

  useEffect(() => {
    generateData({
      data,
      isBlackAndWhite,
      filteredBloodPressureData,
      chartSvgCssId,
      patientTimezone
    });
  }, [data, isBlackAndWhite]);

  return (
    <BloodPressureChartContainer
      id={chartCssId}
      chartId={chartSvgCssId}
      isBlackAndWhite={isBlackAndWhite}
    >
      <ChartHeader>
        <ChartLeft>
          <ChartHeaderText>Blood Pressure Chart</ChartHeaderText>
          <ChartSubHeaderText>
            {`Evaluate the member's blood pressure trends`}
          </ChartSubHeaderText>
        </ChartLeft>
      </ChartHeader>
      {bloodPressureData ? (
        <>
          <div id={chartSvgCssId} />
        </>
      ) : (
        bloodPressureError && (
          <ErrorText marginLeft="20px">
            <ErrorComponent error={bloodPressureError} />
          </ErrorText>
        )
      )}
      {renderMoreInfo && renderMoreInfo()}
    </BloodPressureChartContainer>
  );
};

export default ReportBloodPressureChart;
