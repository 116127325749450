// @ts-nocheck

import { forwardRef, useEffect, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import styled from "@emotion/styled";
import { DateTime } from "luxon";

import {
  getErrorMessage,
  formatName,
  unmaskPhoneNumber
} from "common/helpers/helpers";

import { ErrorTextSpan } from "../../styling";
import {
  CustomSelectComponent,
  FormLabel,
  getFormItems
} from "../../helpers/components/Forms/FormHelpers";

import MigratePatientStatus from "./enums/MigratePatientStatus";

import { useRegisterMigratedMutation } from "common/services/MemberRegistrationService";
import SalesforceEnum from "common/enums/SalesforceEnum";
import ErrorComponent from "../../components/ErrorComponent";

const NurseAndEnrolledFormContainer = styled.div`
  background: #ffffff;
`;

const ErrorContainer = styled.div`
  transition: height 0.66s ease-out;
  min-height: 28px;
`;

const StyledErrorTextSpan = styled(ErrorTextSpan)`
  margin-top: 12px;
`;

const FormikFormWrapper = ({
  setFieldValue,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  nursesList,
  setCanSubmitConfirmationCallback
}) => {
  const systemOptions = [
    { value: "S_100Plus", label: "100plus" },
    { value: "CopilotIQ", label: "RemoteIQ" }
  ];

  useEffect(() => {
    if (values?.["assigned_nurse"] && values?.enrolled && values?.["system"]) {
      setCanSubmitConfirmationCallback(true);
    }
  }, [values, setCanSubmitConfirmationCallback]);

  let selectedNurseOption, selectedSystemOption;
  if (values?.["assigned_nurse"]) {
    selectedNurseOption = nursesList.find(
      (reason) => reason.value === values?.["assigned_nurse"]
    );
  }
  if (values?.["system"]) {
    selectedSystemOption = systemOptions.find(
      (reason) => reason.value === values?.["system"]
    );
  }
  return (
    <Form>
      <Fragment key={"assigned_nurse"}>
        <FormLabel htmlFor={"assigned_nurse"}>Assign to Nurse</FormLabel>
        <Field
          isSearchable={true}
          component={CustomSelectComponent}
          options={nursesList}
          id={"assigned_nurse"}
          name={"assigned_nurse"}
          // this schema is important for working with react-select { label: label, value: value }
          value={{
            label: selectedNurseOption
              ? selectedNurseOption?.label
              : values?.["assigned_nurse"],
            value: values?.["assigned_nurse"]
          }}
          //   disabled={disabled}
          onChange={(e) => {
            setFieldValue("assigned_nurse", e?.value);
          }}
        ></Field>
        {errors?.["assigned_nurse"] && (
          <ErrorContainer>
            <StyledErrorTextSpan>
              {errors?.["assigned_nurse"]}
            </StyledErrorTextSpan>
          </ErrorContainer>
        )}
      </Fragment>
      {getFormItems(
        [
          {
            dataKey: "enrolled",
            label: "Enrolled Date",
            required: true,
            elementType: "datePicker",
            onlyErrorOnTouched: false
          }
        ],
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        values
      )}
      <Fragment key={"system"}>
        <FormLabel htmlFor={"system"}>
          Is the member staying in 100Plus or migrating to RemoteIQ?
        </FormLabel>
        <Field
          isSearchable={true}
          component={CustomSelectComponent}
          options={systemOptions}
          id={"system"}
          name={"system"}
          value={{
            label: selectedSystemOption
              ? selectedSystemOption?.label
              : values?.["system"],
            value: values?.["system"]
          }}
          onChange={(e) => {
            setFieldValue("system", e?.value);
          }}
        ></Field>
        {errors?.["system"] && (
          <ErrorContainer>
            <StyledErrorTextSpan>{errors?.["system"]}</StyledErrorTextSpan>
          </ErrorContainer>
        )}
      </Fragment>
    </Form>
  );
};

const NurseAndEnrolledForm = forwardRef(
  (
    {
      dataObj,
      nursesList,
      setCanSubmitConfirmationCallback,
      setCurrentModalStateCallback,
      setMigratePatientResponseCallback,
      setMigratePatientErrorCallback,
      setMigratedPatientIdCallback,
      migratePatientError,
      setMigratePatientLoadingCallback
    },
    ref
  ) => {
    const [
      registerMigratedMutation,
      { data, error, isSuccess, isError, isLoading }
    ] = useRegisterMigratedMutation();

    useEffect(() => {
      // Status 201
      if (data !== undefined) {
        setMigratePatientLoadingCallback(false);
        setMigratePatientResponseCallback(data);
        setMigratedPatientIdCallback(data?.user_id);
        setMigratePatientErrorCallback(undefined);
        setCurrentModalStateCallback(MigratePatientStatus.STATE_2);
      } else if (error !== undefined) {
        setMigratePatientLoadingCallback(false);
        if (error.status === 200) {
          // Expect 200 for migrating a patient that already exists and 201 for creating a PAB patient during migration
          setMigratePatientResponseCallback(undefined);
          setMigratePatientErrorCallback(error?.response?.data);
          setCurrentModalStateCallback(MigratePatientStatus.STATE_2);
        } else {
          setMigratePatientErrorCallback(getErrorMessage(error));
          setMigratePatientResponseCallback(undefined);
        }
      }
    }, [data, error]);

    const validateFunc = (
      values,
      props /* only available when using withFormik */
    ) => {
      const errors = {};

      return errors;
    };

    const onSubmit = async (values) => {
      const { assigned_nurse, system } = values;

      const {
        salesforce_acc_id,
        timezone,
        athena_id,
        first,
        middle,
        last,
        athenaFirstName,
        athenaLastName,
        athenaMiddleName,
        birthdate,
        phone,
        mobile,
        primarydepartmentid,
        Notes__c
      } = dataObj;

      const enrolled = DateTime?.fromJSDate(new Date(values.enrolled))
        ?.toUTC()
        ?.toISO();

      const body = {
        salesforce_acc_id,
        timezone,
        athena_id,
        first: formatName(athenaFirstName),
        middle: athenaMiddleName ? formatName(athenaMiddleName) : "",
        last: formatName(athenaLastName),
        birthdate,
        phone: unmaskPhoneNumber(phone),
        mobile: unmaskPhoneNumber(mobile),
        enrolled,
        assigned_nurse,
        system,
        primarydepartmentid
      };

      if (Notes__c && Notes__c === SalesforceEnum.NOTES_IS_TEST) {
        body.is_test = true;
      }

      await registerMigratedMutation({
        body
      });
    };

    return (
      <NurseAndEnrolledFormContainer>
        <Formik
          validate={validateFunc}
          initialValues={{
            assigned_nurse: "",
            enrolled: "",
            system: ""
          }}
          onSubmit={onSubmit}
          innerRef={ref}
          enableReinitialize
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            handleChange,
            handleBlur
          }) => {
            return (
              <FormikFormWrapper
                setCanSubmitConfirmationCallback={
                  setCanSubmitConfirmationCallback
                }
                nursesList={nursesList}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            );
          }}
        </Formik>
        {migratePatientError && <ErrorComponent error={migratePatientError} />}
      </NurseAndEnrolledFormContainer>
    );
  }
);

export default NurseAndEnrolledForm;
