import { useState } from "react";
import { DateTime } from "luxon";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import Modal from "./Modal";
import { TurqoiseButton, WhiteButton } from "../../styling";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader
} from "../../styling/StyleModal";

import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays
} from "date-fns";

// https://github.com/hypeserver/react-date-range/blob/master/src/defaultRanges.js#L2
// https://github.com/hypeserver/react-date-range/issues/531#issuecomment-962133488
const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1))
};

interface IProps {
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: (from: DateTime, to: DateTime) => void;
}
const INITIAL_STATE = {
  startDate: new Date(),
  endDate: new Date(),
  key: "selection"
};

const DateRangePickerModal = ({ isVisible, onCancel, onConfirm }: IProps) => {
  const [selection, setSelection] = useState(INITIAL_STATE);

  const handleDateSelect = ({ selection }) => {
    setSelection(selection);
  };

  const handleConfirmClick = () => {
    const start = DateTime.fromJSDate(selection.startDate);
    const end = DateTime.fromJSDate(selection.endDate);

    onConfirm(start, end);
    setSelection(INITIAL_STATE);
  };

  const handleCancelClick = () => {
    onCancel();
    setSelection(INITIAL_STATE);
  };

  const defaultInputRanges = [
    {
      label: "days up to today",
      range(value) {
        console.log("VALUE", value);
        if (value) {
          return {
            startDate: addDays(
              defineds.startOfToday,
              (Math.max(Number(value), 1) - 1) * -1
            ),
            endDate: defineds.endOfToday,
            clearField: false
          };
        }
        return { startDate: new Date(), endDate: new Date(), clearField: true };
      },
      getCurrentValue(range) {
        console.log("RANGE", range);
        if (range.clearField) return "";
        if (!isSameDay(range.endDate, defineds.endOfToday)) return "-";
        if (!range.startDate) return "∞";
        return (
          differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
        );
      }
    }
  ];

  return (
    <Modal isOpen={isVisible} contentLabel="Select Dates" modalHeight="600px">
      <ModalHeader>Select date range</ModalHeader>
      <ModalBody style={{ alignItems: "center", justifyContent: "center" }}>
        <DateRangePicker
          ranges={[selection]}
          onChange={handleDateSelect}
          maxDate={DateTime.now().toJSDate()}
          inputRanges={defaultInputRanges}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          <TurqoiseButton onClick={handleConfirmClick}>Select</TurqoiseButton>
          <WhiteButton onClick={handleCancelClick}>Cancel</WhiteButton>
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  );
};

export default DateRangePickerModal;
