import { createSlice } from "@reduxjs/toolkit";

// Set this to false to enable Dark support
export const DISABLE_DARK_THEME = true;

interface StateType {
  biometricsEnabled: boolean;
  notificationsEnabled: boolean;
  useOSThemeEnabled: boolean;
  darkThemeEnabled: boolean;
}

const initialState: StateType = {
  useOSThemeEnabled: true,
  darkThemeEnabled: false,
  biometricsEnabled: undefined,
  notificationsEnabled: undefined
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setNotificationsEnabled: (state, action: { payload: boolean }) => {
      state.notificationsEnabled = action.payload;
    },
    setBiometricsEnabled: (state, action: { payload: boolean }) => {
      state.biometricsEnabled = action.payload;
    },
    setUseOSThemeEnabled: (state, action: { payload: boolean }) => {
      state.useOSThemeEnabled = action.payload;
    },
    setDarkThemeEnabled: (state, action: { payload: boolean }) => {
      state.darkThemeEnabled = action.payload;
    }
  }
});

export const {
  setNotificationsEnabled,
  setBiometricsEnabled,
  setUseOSThemeEnabled,
  setDarkThemeEnabled
} = settingsSlice.actions;

export default settingsSlice.reducer;
