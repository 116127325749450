import { QueryString_stringify } from "../helpers/QueryStringHelper";
import { apiOrders, apiPAB } from "./AxiosService";

import { DateTime } from "luxon";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import OrderType from "../types/OrderType";
import OrderMemberType from "../types/OrderMemberType";
import OrderStatusEnum from "../enums/OrderStatusEnum";
import OrderLostStatusReasonEnum from "../enums/OrderLostStatusReasonEnum";
import { AppDispatch } from "../redux";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import OrderItemType from "../types/OrderItemType";

const DELAY_AFTER_ORDER_REQUEST_COMPLETED = 2000;

const transformResponseOrders = (response: OrderMemberType[]) => {
  return response.sort((a, b) => {
    // sort by delivered date if delivered date exists
    if (
      a?.order?.shipping?.delivered_date &&
      b?.order?.shipping?.delivered_date
    ) {
      return (
        DateTime.fromSQL(b.order.shipping.delivered_date).toMillis() -
        DateTime.fromSQL(a.order.shipping.delivered_date).toMillis()
      );
    }

    return (
      DateTime.fromSQL(b.order.created_at).toMillis() -
      DateTime.fromSQL(a.order.created_at).toMillis()
    );
  });
};

const formatDateRangeToQueryStringISO = (from: DateTime, to: DateTime) => {
  const paramObject = {
    since: from.toISO(),
    until: to.toISO()
  };
  return QueryString_stringify(paramObject);
};

async function clearCache(
  queryFulfilled: PromiseWithKnownReason<any, any>,
  dispatch: AppDispatch,
  id: string
) {
  try {
    await queryFulfilled;
    setTimeout(() => {
      dispatch(
        ordersPABService.util.invalidateTags([
          ReduxTagEnum.Order,
          { type: ReduxTagEnum.Order, id }
        ])
      );
      dispatch(orderService.util.invalidateTags([ReduxTagEnum.Order]));
    }, 3000);
  } catch (error) {}
}

const ordersPABService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Order]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getOrderById: builder.query<OrderType, { orderId: string }>({
        query: ({ orderId }) => {
          return {
            url: `/orders/${orderId}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Order, id: "order_id_" + arg.orderId }
        ]
      }),
      getOrdersByMemberId: builder.query<
        OrderMemberType[],
        { memberId: string }
      >({
        query: ({ memberId }) => {
          return {
            url: `/orders/patient_id/${memberId}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Order, id: "patient_id_" + arg.memberId }
        ],
        transformResponse: transformResponseOrders
      }),
      getOrdersByStaffId: builder.query<OrderMemberType[], { staffId: string }>(
        {
          query: ({ staffId }) => {
            return {
              url: `/orders/ordered_by/${staffId}`,
              method: "GET"
            };
          },
          providesTags: (result, error, arg) => [
            { type: ReduxTagEnum.Order, id: "patient_id_" + arg.staffId }
          ],
          transformResponse: transformResponseOrders
        }
      ),
      getOrdersByCreationDate: builder.query<
        OrderMemberType[],
        { since: DateTime; until: DateTime }
      >({
        query: ({ since, until }) => {
          const params = formatDateRangeToQueryStringISO(since, until);
          return {
            url: `/orders/created_at?${params}`,
            method: "GET"
          };
        },
        providesTags: [ReduxTagEnum.Order]
      })
    })
  });

const orderService = apiOrders
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Order]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      downloadOrderPDF: builder.query<any, { order_id: string }>({
        query: ({ order_id }) => {
          return {
            url: `/orders/${order_id}/file`,
            method: "GET"
          };
        }
      }),

      // Mutations
      createOrder: builder.mutation<any, OrderType>({
        query: (body) => ({
          url: `/order/${body.order_type}`,
          method: "POST",
          data: body
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch, props.patient_id);
        }
      }),
      updateOrderStatus: builder.mutation<
        any,
        Omit<
          {
            orderId: string;
            orderStatus: OrderStatusEnum;
            date?: DateTime;
            updatedBy?: string;
            reason?: OrderLostStatusReasonEnum;
          },
          "id"
        >
      >({
        query: ({ orderId, date, orderStatus, updatedBy, reason }) => {
          return {
            url: `/orders/${orderId}/status/${orderStatus}`,
            method: "PUT",
            data: {
              order_id: orderId,
              status: {
                sm_status: orderStatus,
                updated_at: date?.toUTC().toFormat("yyyy-MM-dd hh:mm:ss ZZZZ"),
                updated_by: updatedBy,
                reason_note: reason
              }
            }
          };
        },
        async onQueryStarted({ orderId }, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            setTimeout(() => {
              clearCache(queryFulfilled, dispatch, "order_id_" + orderId);
            }, DELAY_AFTER_ORDER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      refillOrder: builder.mutation<any, OrderType>({
        query: (body) => ({
          url: "/order/REFILL",
          method: "POST",
          data: body
        }),
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            setTimeout(() => {
              dispatch(
                ordersPABService.util.invalidateTags([
                  ReduxTagEnum.Order,
                  { type: ReduxTagEnum.Order, id: props.patient_id }
                ])
              );
              dispatch(orderService.util.invalidateTags([ReduxTagEnum.Order]));
            }, 3000);
          } catch (error) {}
        }
      })
    })
  });

export const {
  useGetOrdersByMemberIdQuery,
  useGetOrdersByCreationDateQuery,
  useGetOrderByIdQuery,
  useGetOrdersByStaffIdQuery
} = ordersPABService;

export const {
  useUpdateOrderStatusMutation,
  useCreateOrderMutation,
  useRefillOrderMutation,
  useLazyDownloadOrderPDFQuery
} = orderService;

export { DELAY_AFTER_ORDER_REQUEST_COMPLETED };
