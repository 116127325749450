import RolesEnum from "common/enums/RolesEnum";
import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  MEMBER_CHART,
  NURSE_LIST,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  PROVIDER_LIST,
  COMPLETED_TODOS,
  MEMBER_CHART_CALENDARING,
  ALL_TEAMS,
  TEAM_DETAILS,
  STAFF_DETAILS,
  MY_TODOS,
  SCHEDULE_TODO,
  PROVIDER_SCHEDULES,
  COMMON_ROUTES,
  VISITS
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_CLINICAL_TEAMS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SECTIONS,
  SIDEBAR_SUPPORT,
  PROVIDER_SCHEDULES_SIDEBAR
} from "../RouteSidebar";
import { PersonIcon } from "../../assets/images/icons";
import { TeamTypeEnum } from "common/enums/TeamTypeEnum";
import FeatureFlags from "common/config/FeatureFlags";
import MemberDetailsRedirect from "../../pages/MemberDetails/MemberDetailsRedirect";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";

const providerSidebar = () => {
  const sidebarItems = [
    {
      itemName: "My Providers",
      itemLink: "/my-providers",
      ItemIcon: PersonIcon
    }
  ];

  // provider_calendaring_feature_flag
  if (FeatureFlags().PROVIDER_CALENDARING) {
    sidebarItems.push(PROVIDER_SCHEDULES_SIDEBAR);
  }

  sidebarItems.push({
    itemName: "To Do",
    itemLink: "/tasks",
    ItemIcon: PersonIcon
  });
  sidebarItems.push(SIDEBAR_CLINICAL_TEAMS);

  return sidebarItems;
};

const PROVIDER_MANAGER = {
  routes: [
    ...COMMON_ROUTES,
    {
      path: "/",
      components: [
        SCHEDULE_TODO(true),
        NURSE_LIST({
          componentHeader: "Providers List",
          tableColumns: [
            {
              name: "fullname",
              accessor: "user.fullname",
              filterEnabled: true,
              filterFn: "fuzzy"
            },
            {
              name: "roles",
              size: 200,
              type: "dataList"
            }
          ],
          externalLink: null,
          roles: [RolesEnum.MD_PROVIDER, RolesEnum.NURSE_PROVIDER]
        })
      ]
    },
    {
      path: "/nurses",
      components: NURSE_LIST({})
    },
    {
      path: "/nurses/nurseId/:userId",
      components: <MemberDetailsRedirect />
    },
    STAFF_DETAILS,
    {
      path: "/members",
      components: ALL_MEMBERS({})
    },
    {
      path: "/awaiting-provider",
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" }
        ]
      })
    },
    { path: "/inactive-members", components: INACTIVE_MEMBERS(true) },
    ...MEMBER_CHART(RolesEnum.PROVIDER_MANAGER),
    ...MEMBER_CHART_CALENDARING,
    STAFF_DETAILS,
    {
      path: "/members/nurseId/:nurseId",
      components: [MEMBERS_WITH_NO_ACTIVITY({}), ASSIGNED_MEMBERS({})]
    },
    {
      path: "/orders",
      components: <OrderDevices />
    },
    {
      path: "/devices/:memberId",
      components: <OrderDevices />
    },
    ORDER_DETAILS(),

    {
      path: "/my-providers",
      components: PROVIDER_LIST({
        componentHeader: "My Providers",
        filterByAuthenticatedUser: true
      })
    },
    COMPLETED_TODOS,
    VISITS,
    MY_TODOS,
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    ALL_TEAMS({
      types: [
        TeamTypeEnum.NP_NURSES,
        TeamTypeEnum.PROVIDERS,
        TeamTypeEnum.MEMBER_CARE_SPECIALISTS
      ]
    }),
    TEAM_DETAILS,
    // provider_calendaring_feature_flag
    FeatureFlags().PROVIDER_CALENDARING && {
      path: "/provider-schedules",
      components: [PROVIDER_SCHEDULES({})]
    }
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.providers,
      items: providerSidebar()
    },
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [SIDEBAR_ALL_MEMBERS, SIDEBAR_INACTIVE_MEMBERS, SIDEBAR_ORDERS]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default PROVIDER_MANAGER;
