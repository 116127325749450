enum ComplexityEnum {
  "99202 (New Straightforward)" = "99202",
  "99203 (New Low)" = "99203",
  "99204 (New Moderate)" = "99204",
  "99205 (New High)" = "99205",

  "99212 (Established Straightforward)" = "99212",
  "99213 (Established Low)" = "99213",
  "99214 (Established Moderate)" = "99214",
  "99215 (Established High)" = "99215"
}

export default ComplexityEnum;
