enum OrderStatusEnum {
  "started" = "started",
  "backordered" = "backordered",
  "accepted" = "accepted",
  "in_fulfillment" = "in_fulfillment",
  "shipped" = "shipped",
  "returned" = "returned",
  "delivered" = "delivered",
  "delivered_lost" = "delivered_lost",
  "lost" = "lost",
  "rejected" = "rejected",
  "exception" = "exception",
  "deleted" = "deleted",
  "delivered_override" = "delivered_override"
}

export const OrderStatuses = Object.keys(OrderStatusEnum);

export default OrderStatusEnum;
