import { MenuItem, TextField, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import MemberType from "common/types/MemberType";
import { ModalFooter, ModalFooterButtons } from "../../styling/StyleModal";
import {
  RelationshipNoteCategoryEnum,
  RelationshipNoteStatusEnum,
  RelationshipNoteType
} from "common/types/RelationshipNoteType";
import { TurqoiseButton, WhiteButton } from "../../styling/StyleComponents";
import { gray } from "../../styling/colors";
import { useUpsertRelationshipNoteMutation } from "common/services/MemberGoalsRelationshipService";
import { Flexbox } from "../../styling/NewStyleComponents";
import { Alert_close } from "common/helpers/AlertHelper";
import { dispatch } from "common/redux";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface IProps {
  member: MemberType;
  relationshipNote?: RelationshipNoteType;
}

const relationshipNoteCategories = [
  { label: "Family", value: RelationshipNoteCategoryEnum.FAMILY },
  { label: "Travel", value: RelationshipNoteCategoryEnum.TRAVEL },
  { label: "Work", value: RelationshipNoteCategoryEnum.WORK },
  { label: "Personal", value: RelationshipNoteCategoryEnum.PERSONAL },
  { label: "Other", value: RelationshipNoteCategoryEnum.OTHER }
];

const EditRelationshipNoteForm = ({ member, relationshipNote }: IProps) => {
  const onSubmit = async (
    values: RelationshipNoteType,
    { resetForm }: { resetForm: () => void }
  ) => {
    const body = {
      patient_id: member?.patient?.patient_id,
      category: values.category,
      note: values.note,
      status: RelationshipNoteStatusEnum.ACTIVE
    };
    if (relationshipNote)
      body["relationship_note_id"] = relationshipNote.relationship_note_id;

    await upsertRelationshipNote(body);
    resetForm();
    handleModalClose();
  };

  const formik = useFormik<RelationshipNoteType>({
    initialValues: {
      patient_id: member?.patient?.patient_id,
      category: relationshipNote ? relationshipNote.category : undefined,
      note: relationshipNote ? relationshipNote.note : undefined
    },
    enableReinitialize: true,
    onSubmit,
    validate: (values) => {
      const errors = {};
      if (!values.note) {
        errors["note"] = "Mandatory field";
      }

      if (!values.category) {
        errors["category"] = "Mandatory field";
      }
      return errors;
    }
  });

  const handleModalClose = () => {
    formik.resetForm();
    Alert_close({ dispatch, id: "editRelationshipNote" });
  };

  const [
    upsertRelationshipNote,
    { isLoading: upsertRelationshipNoteIsLoading }
  ] = useUpsertRelationshipNoteMutation({});

  const verb = relationshipNote ? "Edit" : "Add";

  return (
    <Flexbox
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Flexbox
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
          margin={"0px 15px"}
          gap={"10px"}
        >
          <Typography variant="h5">Select Category</Typography>
          <TextField
            value={formik.values?.category ?? ""}
            label="Category"
            select
            fullWidth
            onChange={(event) => {
              formik.setFieldValue("category", event.target.value);
            }}
          >
            {relationshipNoteCategories?.map((reason) => (
              <MenuItem key={reason.value} value={reason.value}>
                {reason.label}
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="h5">Add Note</Typography>
          <TextField
            label={"Notes"}
            fullWidth
            multiline
            rows={2}
            value={formik.values?.note ?? ""}
            sx={{
              input: {
                color: gray[900]
              }
            }}
            onChange={(event) => {
              formik.setFieldValue("note", event.target.value);
            }}
          />
        </Flexbox>

        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              loading={upsertRelationshipNoteIsLoading}
              disabled={!formik.dirty || !formik.isValid}
              type="submit"
            >
              {verb} Relationship Note
            </TurqoiseButton>
            <WhiteButton onClick={handleModalClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
    </Flexbox>
  );
};

export default EditRelationshipNoteForm;
