enum StorageEnum {
  AUTHENTICATION_USER_NAME = "authentication_user_name",

  FCM_TOKEN = "fcm_token",
  TWILIO_ACCESS_TOKEN = "twilio_access_token",
  NAVIGATION_STATE = "navigation_state",

  TWILIO_BLUR_ENABLED = "twilio_blur_enabled",

  LOGIN_CREDENTIALS = "login_credentials",
  RETENTION_FILTER = "retention_filter",
  RETENTION_ROUTE = "retention_route",
  SESSION_ID = "session_id",
  CHART_SHOW_TABLE = "chart_show_table",

  // used for "remember device" functionality on the login page
  LOGIN = "login"
}

export default StorageEnum;
