import EnvVars from "../config/EnvVars";

export const getAthenaPatientUrl = (athenaId: string | number) => {
  return `${EnvVars.REACT_APP_ATHENA_ROOT_URL}/${EnvVars.REACT_APP_ATHENA_PRACTICE_ID}/3/globalframeset.esp?MAIN=${EnvVars.REACT_APP_ATHENA_ROOT_URL}/${EnvVars.REACT_APP_ATHENA_PRACTICE_ID}/3/client/clientsummary.esp?PATIENTID=${athenaId}`;
};

export const getAthenaEncounterUrl = (encounterId: string | number) => {
  return `${EnvVars.REACT_APP_ATHENA_ROOT_URL}/${EnvVars.REACT_APP_ATHENA_PRACTICE_ID}/3/ax/encounter/${
    encounterId
  }/exam`;
};
