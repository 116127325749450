import { DateTime } from "luxon";
import MemberStatusEnum from "../enums/MemberStatusEnum";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import RolesEnum from "../enums/RolesEnum";
import { getNameOrUsername } from "../helpers/helpers";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import DropdownNurseType from "../types/DropdownNurseType";
import ReportNurseType from "../types/ReportNurseType";
import { apiPAB } from "./AxiosService";
import { providesList } from "./ServicesCacheUtils";
import UserStatusEnum from "../enums/UserStatusEnum";

interface GetPropType {
  type?: string;
  carer_roles?: RolesEnum[];
  carer_status?: UserStatusEnum[];
  patient_status?: boolean;
  team_leader_id?: string;
  getNurseListForAppointments?: boolean;
  is_excluded_from_scheduling?: boolean;
}

const providesTags = (result: DropdownNurseType[] | undefined) => {
  return providesList<DropdownNurseType, ReduxTagEnum.Report>(
    result,
    ReduxTagEnum.Report,
    (item: DropdownNurseType) => item.value
  );
};

const reportsService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Report, ReduxTagEnum.Team]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getSortedMembersWithActiveNurses: builder.query<
        DropdownNurseType[],
        GetPropType
      >({
        query: ({ carer_roles, carer_status, is_excluded_from_scheduling }) => {
          const paramsObject = {
            type: "CARERS_PATIENTS",
            carer_roles,
            carer_status,
            patient_status: [
              MemberStatusEnum.ACTIVE,
              MemberStatusEnum.PENDING,
              MemberStatusEnum.ELIGIBLE
            ].join(","),
            sort: "ASC",
            is_test: false,
            is_excluded_from_scheduling
          };
          const params = QueryString_stringify(paramsObject);
          return { url: `/reports?${params}`, method: "GET" };
        },
        transformResponse: (response: ReportNurseType[], meta, arg) => {
          let getNurseListForAppointments = false;

          if (arg?.getNurseListForAppointments) {
            // tbd this logic should be on the backend ENG-4591
            getNurseListForAppointments = true;
          }
          const filteredData = response
            // this data is not currently being passed from the API in the carer object
            .filter((item) => {
              const defaultConditions =
                item.carer.is_test === false ||
                item.carer.status !== MemberStatusEnum.INACTIVE;

              if (getNurseListForAppointments) {
                return (
                  defaultConditions &&
                  // tbd this logic should be on the backend  ENG-4591
                  ((item.carer.roles.includes(RolesEnum.TH_NURSE) &&
                    item.carer.roles.length === 1) ||
                    // TH_NURSE and THN_MANAGER are the only roles ENG-5190
                    (item.carer.roles.includes(RolesEnum.TH_NURSE) &&
                      item.carer.roles.includes(RolesEnum.THN_MANAGER) &&
                      item.carer.roles.length === 2))
                );
              }

              return defaultConditions;
            });
          const finalResponse = filteredData
            .map((item) => {
              const displayLastNameFirst = getNameOrUsername(item.carer);
              const displayFirstNameFirst = getNameOrUsername(
                item.carer,
                false
              );
              return {
                label: {
                  displayLastNameFirst,
                  displayFirstNameFirst,
                  patient_count: item.patient_count,
                  timezone: item.carer?.timezone
                },
                value: item.carer.user_id
              };
            })
            // tbd add sorting on backend
            .sort(function (a, b) {
              return (
                // sort by count ascending, then display name alphabetically
                a.label.patient_count - b.label.patient_count ||
                a.label.displayLastNameFirst.localeCompare(
                  b.label.displayLastNameFirst
                )
              );
            });
          return finalResponse;
        },
        providesTags
      }),
      getLeaderSummaryByLeaderId: builder.query<ReportNurseType[], GetPropType>(
        {
          query: ({ team_leader_id }) => {
            const now = DateTime.now();
            const paramsObject = {
              type: "CARERS_LEADER_SUMMARY",
              starts_after: now.startOf("month").startOf("day").toISO(),
              starts_before: now.toISO(),
              status: "ACTIVE",
              is_test: false,
              team_leader_id
            };
            const params = QueryString_stringify(paramsObject);
            return { url: `/reports?${params}`, method: "GET" };
          },
          providesTags: (result, error, arg) => [
            {
              type: ReduxTagEnum.Team,
              id: arg?.team_leader_id
            }
          ]
        }
      ),
      getTimeEnteredByRole: builder.query<
        ReportNurseType[],
        {
          roles: RolesEnum[];
          startsAfter: DateTime;
          startsBefore: DateTime;
          carer_status?: UserStatusEnum;
        }
      >({
        query: ({ roles, startsAfter, startsBefore, carer_status }) => {
          const paramsObject = {
            type: "CARERS_ROLES_SUMMARY",
            carer_roles: roles,
            starts_after: startsAfter.toISO(),
            starts_before: startsBefore.toISO(),
            is_test: false,
            carer_status
          };
          const params = QueryString_stringify(paramsObject);
          return { url: `/reports?${params}`, method: "GET" };
        },
        providesTags: (result, error, arg) => [
          {
            type: ReduxTagEnum.Report
          }
        ],
        transformResponse: (response: ReportNurseType[]) => {
          const finalResponse = response.map((item) => {
            const displayName = getNameOrUsername(item.carer);
            return {
              ...item,
              fullname: displayName
            };
          });
          return finalResponse;
        }
      })
    })
  });

export const {
  useGetSortedMembersWithActiveNursesQuery,
  useGetLeaderSummaryByLeaderIdQuery,
  useGetTimeEnteredByRoleQuery
} = reportsService;
