import { RootState } from "common/redux";
import { useSelector } from "react-redux";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import UserLinkedEntitiesEnum from "../enums/UserLinkedEntitiesEnum";

const useGetAuthenticatedUser = (
  {
    skip,
    linkedEntities
  }: { skip?: boolean; linkedEntities?: UserLinkedEntitiesEnum[] } = {
    skip: false,
    linkedEntities: []
  }
) => {
  const { isLoggedIn, user } = useSelector((state: RootState) => state.auth);

  const { data, isLoading, error, isError, refetch, isSuccess } =
    useGetUserWithUsernameQuery(
      { username: user?.user_id!, linked_entities: linkedEntities },
      { skip: isLoggedIn === false || user?.user_id === undefined || skip }
    );

  return { data, isLoading, error, isError, refetch, isSuccess };
};

export default useGetAuthenticatedUser;
