import {
  getNameOrUsername,
  isFalsy,
  prettyStatusGraphQLString,
  trimText
} from "common/helpers/helpers";
import { Box, MenuItem, styled, TextField, Autocomplete } from "@mui/material";
import { TaskCategory } from "common/graphql/generated";
import DateTimePicker from "../../../components/DateTimePicker";
import { DateTime } from "luxon";
import { useGetUserWithQueryParamsQuery } from "common/services/UserService";
import { useGetMembersWithQueryParamsQuery } from "common/services/MemberService";
import { useMemo, useState } from "react";
import { StyledTextField } from "../../../helpers/components/Forms/FormHelpers";
import MemberType from "common/types/MemberType";
import UserType from "common/types/UserType";

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
`;

let setTextTimeout: NodeJS.Timeout;

export interface AddTaskFormType {
  member_id: string;
  task_category: TaskCategory;
  assigned_user_id: string;
  due_date: DateTime;
  priority_score: number;
  notes: string;
}

interface IProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: AddTaskFormType;
  details?: boolean;
}

const AddTaskForm = ({ setFieldValue, values, details = false }: IProps) => {
  const [memberSearchText, setMemberSearchText] = useState<string>();
  const [staffSearchText, setStaffSearchText] = useState<string>();

  const { memberTrimmedText, staffTrimmedText } = useMemo(() => {
    return {
      memberTrimmedText: trimText(memberSearchText),
      staffTrimmedText: trimText(staffSearchText)
    };
  }, [memberSearchText, staffSearchText]);

  const { data: staffUsers } = useGetUserWithQueryParamsQuery({
    search: staffTrimmedText,
    sort: "lastName,ASC,firstName,ASC"
  });
  const { data: members } = useGetMembersWithQueryParamsQuery({
    search: memberTrimmedText,
    sort: "lastName,ASC,firstName,ASC"
  });

  const selectedMember = useMemo(() => {
    return members?.find(
      (item) => item.patient.patient_id === values.member_id
    );
  }, [members, values.member_id]);

  const selectedStaff = useMemo(() => {
    return staffUsers?.find(
      (item) => item.user.user_id === values.assigned_user_id
    );
  }, [staffUsers, values.assigned_user_id]);

  return (
    <>
      <Grid>
        <TextField
          label="Task Category"
          value={values.task_category ?? ""}
          select
          onChange={(event) => {
            setFieldValue("task_category", event.target.value);
          }}
          disabled={details}
        >
          {Object.keys(TaskCategory).map((key) => (
            <MenuItem key={key} value={TaskCategory[key]}>
              {prettyStatusGraphQLString(key)}
            </MenuItem>
          ))}
        </TextField>

        <Autocomplete<MemberType>
          options={members}
          disabled={details || members === undefined}
          value={selectedMember}
          getOptionLabel={(item) => {
            return getNameOrUsername(item?.patient);
          }}
          onInputChange={(e, value) => {
            if (setTextTimeout) clearTimeout(setTextTimeout);

            setTextTimeout = setTimeout(() => {
              setMemberSearchText(value);
            }, 1000);
          }}
          onChange={(e, value) => {
            setFieldValue("member_id", value.patient.patient_id);
          }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              aria-label="Select Member"
              label="Select Member"
              variant="outlined"
            />
          )}
        />

        <Autocomplete<UserType>
          options={staffUsers}
          disabled={details || staffUsers === undefined}
          value={selectedStaff}
          getOptionLabel={(item) => {
            return getNameOrUsername(item?.user);
          }}
          onInputChange={(e, value) => {
            if (setTextTimeout) clearTimeout(setTextTimeout);

            setTextTimeout = setTimeout(() => {
              setStaffSearchText(value);
            }, 1000);
          }}
          onChange={(e, value) => {
            setFieldValue("assigned_user_id", value.user.user_id);
          }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              aria-label="Task Assignee"
              label="Task Assignee"
              variant="outlined"
            />
          )}
        />

        <DateTimePicker
          sx={{ marginBottom: -5 }}
          label="Due date/time"
          value={values.due_date}
          onChange={(newDate: DateTime) => {
            setFieldValue("due_date", newDate);
          }}
          disabled={details}
        />
        <TextField
          label="Urgency"
          value={values.priority_score ?? ""}
          select
          onChange={(event) => {
            setFieldValue("priority_score", event.target.value);
          }}
          disabled={details}
        >
          <MenuItem value={20}>Low</MenuItem>
          <MenuItem value={40}>Medium</MenuItem>
          <MenuItem value={60}>High</MenuItem>
        </TextField>
      </Grid>
      <br />
      <TextField
        label="Task Description"
        multiline
        value={values.notes ?? ""}
        onChange={(event) => {
          setFieldValue("notes", event.target.value);
        }}
        disabled={details}
      />
    </>
  );
};

export default AddTaskForm;
