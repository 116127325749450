enum ReadingQualifierReasonEnum {
  NO_TAG_SELECTED = 0,
  BEFORE_BREAKFAST = 1,
  AFTER_BREAKFAST = 2,
  BEFORE_LUNCH = 3,
  AFTER_LUNCH = 4,
  BEFORE_DINNER = 5,
  AFTER_DINNER = 6,
  NIGHT = 7,
  BEFORE_SNACK = 8,
  AFTER_SNACK = 9,
  BEFORE_ACTIVITY = 10,
  AFTER_ACTIVITY = 11,
  OTHER = 12,
  CONTROL_SOLUTION_L1 = 13,
  CONTROL_SOLUTION_L2 = 14,
  BREAKFAST = 15,
  LUNCH = 16,
  DINNER = 17,
  SNACK = 18
}

export const ReadingQualifierReasonEnum_toString = (
  reason: ReadingQualifierReasonEnum
) => {
  if (reason === undefined) return undefined;
  if (reason === ReadingQualifierReasonEnum.BEFORE_BREAKFAST)
    return "Before Breakfast/Fasting";

  const key = ReadingQualifierReasonEnum[reason];
  if (key === undefined) return undefined;

  const splittedString = key.split("_");
  const splittedStringLowerCase = splittedString.map((item) => {
    return item.charAt(0) + item.slice(1).toLowerCase();
  });

  return splittedStringLowerCase.join(" ");
};

export const isReadingQualifierBeforeMeal = (
  reason: ReadingQualifierReasonEnum
) => {
  return [
    ReadingQualifierReasonEnum.BEFORE_BREAKFAST,
    ReadingQualifierReasonEnum.BEFORE_LUNCH,
    ReadingQualifierReasonEnum.BEFORE_DINNER,
    ReadingQualifierReasonEnum.BEFORE_SNACK
  ].includes(reason);
};

export const isReadingQualifierAfterMeal = (
  reason: ReadingQualifierReasonEnum
) => {
  return [
    ReadingQualifierReasonEnum.AFTER_BREAKFAST,
    ReadingQualifierReasonEnum.AFTER_LUNCH,
    ReadingQualifierReasonEnum.AFTER_DINNER,
    ReadingQualifierReasonEnum.AFTER_SNACK,
    ReadingQualifierReasonEnum.BREAKFAST,
    ReadingQualifierReasonEnum.LUNCH,
    ReadingQualifierReasonEnum.DINNER,
    ReadingQualifierReasonEnum.SNACK
  ].includes(reason);
};

export const isReadingQualifierOther = (reason: ReadingQualifierReasonEnum) => {
  return [
    ReadingQualifierReasonEnum.OTHER,
    ReadingQualifierReasonEnum.NO_TAG_SELECTED,
    ReadingQualifierReasonEnum.NIGHT,
    ReadingQualifierReasonEnum.BEFORE_ACTIVITY,
    ReadingQualifierReasonEnum.AFTER_ACTIVITY,
    ReadingQualifierReasonEnum.CONTROL_SOLUTION_L1,
    ReadingQualifierReasonEnum.CONTROL_SOLUTION_L2
  ].includes(reason);
};

export default ReadingQualifierReasonEnum;
