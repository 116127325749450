import { useRef, useState } from "react";
import styled from "@emotion/styled";

import { FormikProps } from "formik";

import RolesEnum from "common/enums/RolesEnum";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import MemberType from "common/types/MemberType";

import {
  WhiteButton,
  SubmitButtonContainer,
  TurqoiseButton
} from "../../../styling/StyleComponents";
import { Modal } from "..";
import SearchPatientSteps from "./SearchPatientSteps";
import { SearchPatientFormType } from "./SearchPatientForm";
import {
  ModalBody,
  ModalFooterButtons,
  ModalHeader
} from "../../../styling/StyleModal";
import ErrorType from "common/types/ErrorType";

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSelectedUser: (user_id: string) => void;
  disableSelection?: boolean;
  onSubmitProps?: {
    userListFilters: {
      roles?: RolesEnum[];
      status?: MemberStatusEnum[];
      linked_entities?: MemberLinkedEntitiesEnum[];
    };
  };
  dataLoadingProps?: {
    setDataCallback?: (users: MemberType[] | undefined) => void;
    setDataLoadingCallback?: (isDataLoading: boolean) => void;
    setDataErrorCallback?: (error: ErrorType) => void;
  };
}

const SearchPatientModal = ({
  onSubmitProps,
  isOpen,
  handleClose,
  handleSelectedUser,
  disableSelection = false,
  dataLoadingProps = {}
}: IProps) => {
  const searchPatientFormRef = useRef<FormikProps<SearchPatientFormType>>();

  const [searchPatientStep, setSearchPatientStep] = useState<number>(0);
  const [selectedSearchId, setSelectedSearchId] = useState<string>();
  const [selectPatientFormDirty, updateSelectPatientFormDirty] =
    useState<boolean>();

  const handleSubmit = () => {
    if (searchPatientFormRef.current) {
      searchPatientFormRef.current.handleSubmit();
    }
  };

  const resetSearchState = () => {
    handleClose();
    setSearchPatientStep(0);
    setSelectedSearchId(undefined);
  };

  const [searchPatientLoading, setSearchPatientLoading] =
    useState<boolean>(false);

  return (
    <StyledModal
      isOpen={isOpen}
      contentLabel="Search Patient Modal"
      modalHeight="70vh"
      onRequestClose={resetSearchState}
    >
      <ModalHeader onRequestClose={resetSearchState}>Search</ModalHeader>
      <ModalBody>
        <SearchPatientSteps
          ref={searchPatientFormRef}
          resetSearchState={resetSearchState}
          searchPatientStep={searchPatientStep}
          setSearchPatientStepCallback={setSearchPatientStep}
          setSelectedUserCallback={handleSelectedUser}
          setSelectedSearchIdCallback={setSelectedSearchId}
          updateSelectPatientFormDirty={updateSelectPatientFormDirty}
          disableSelection={disableSelection}
          onSubmitProps={onSubmitProps}
          dataLoadingProps={dataLoadingProps}
          searchPatientLoading={searchPatientLoading}
          setSearchPatientLoading={setSearchPatientLoading}
        />
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons width="auto">
          {getSearchPatientModalButton(
            searchPatientStep,
            selectedSearchId,
            selectPatientFormDirty,
            handleSubmit,
            handleSelectedUser,
            resetSearchState,
            disableSelection,
            "50px",
            searchPatientLoading
          )}
          <WhiteButton onClick={resetSearchState}>
            {disableSelection ? "Done" : "Cancel"}
          </WhiteButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

function getSearchPatientModalButton(
  searchPatientStep: number,
  selectedSearchId: string,
  selectPatientFormDirty: boolean | undefined,
  handleSearchPatientSubmit: () => void,
  setSelectedUserCallback: (user_id: string | undefined) => void,
  resetSearchState: () => void,
  disableSelection: boolean,
  buttonHeight = "50px",
  searchPatientLoading: boolean = false
) {
  if (searchPatientStep === 0) {
    return (
      <SubmitButtonContainer>
        <StyledModalTurquoiseButton
          height={buttonHeight}
          disabled={!selectPatientFormDirty || searchPatientLoading}
          loading={searchPatientLoading}
          onClick={() => {
            handleSearchPatientSubmit();
          }}
          type="submit"
          data-testid="SearchPatientModal-search"
        >
          Search
        </StyledModalTurquoiseButton>
      </SubmitButtonContainer>
    );
  }
  if (searchPatientStep === 1) {
    if (disableSelection) {
      return null;
    }
    return (
      <StyledModalTurquoiseButton
        height={buttonHeight}
        disabled={!selectedSearchId || selectedSearchId?.length === 0}
        onClick={() => {
          setSelectedUserCallback(selectedSearchId);
          resetSearchState();
        }}
        type="submit"
        data-testid="SearchPatientModal-select"
      >
        Select Member
      </StyledModalTurquoiseButton>
    );
  }

  return null;
}

const StyledModal = styled(Modal)<{ modalHeight: string }>`
  display: flex;
  flex-direction: column;

  height: ${(props) => props.modalHeight};
`;

const ModalFooter = styled.div``;

const StyledModalTurquoiseButton = styled(TurqoiseButton)<{
  height: string;
}>`
  height: ${(props) => props.height};
`;

export default SearchPatientModal;
