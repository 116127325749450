// @ts-nocheck

import { useRef, useEffect, useCallback, useState } from "react";
import styled from "@emotion/styled";

import useGetSortedNursesList from "common/hooks/useGetSortedNursesList";

import { ComponentHeader } from "../../styling";
import MigratePatientForm from "./MigratePatientForm";
import MigratePatientStatus from "./enums/MigratePatientStatus";
import "./MigratePatientModal.css";
import MigratePatientModal from "./MigratePatientModal";

const MigratePatientContainer = styled.div`
  width: 100%;
  margin: 2.5%;
`;

const CreatePatientCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  border: 1px solid red;
  background: #ffffff;
  border: 1px solid #e8eaf0;
  text-decoration: none;
  /* Shadow 2 - Card */

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 4px;

  transition: all 0.66s ease-out;
`;

const MigratePatientHeader = styled(ComponentHeader)`
  margin-bottom: 2.5%;
`;

const MigratePatient = () => {
  const [patientToMigrateDataArray, setPatientToMigrateDataArray] = useState(
    []
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModalState, setCurrentModalState] =
    useState<MigratePatientStatus>(MigratePatientStatus.STATE_0);
  const setCurrentModalStateCallback = useCallback(
    (val: MigratePatientStatus) => setCurrentModalState(val),
    [setCurrentModalState]
  );
  const [hasDataErrors, setHasDataErrors] = useState<boolean>(false);
  const setHasDataErrorsCallback = useCallback(
    (val: boolean) => setHasDataErrors(val),
    [setHasDataErrors]
  );

  const nurseEnrolledFormRef = useRef(null);
  const handleNurseEnrolledFormSubmit = (...args: any) => {
    if (nurseEnrolledFormRef.current) {
      nurseEnrolledFormRef.current.handleSubmit(...args);
    }
  };

  const [athena_id, setathena_id] = useState<string>("");
  const setathena_idCallback = useCallback(
    (val: string) => setathena_id(val),
    [setathena_id]
  );

  const [canSubmitConfirmation, setCanSubmitConfirmation] =
    useState<boolean>(false);
  const setCanSubmitConfirmationCallback = useCallback(
    (val: boolean) => setCanSubmitConfirmation(val),
    [setCanSubmitConfirmation]
  );

  const [migratePatientLoading, setMigratePatientLoading] =
    useState<boolean>(false);
  const [migratePatientResponse, setMigratePatientResponse] =
    useState(undefined);
  const [migratePatientError, setMigratePatientError] = useState(undefined);

  const [migratedPatientId, setMigratedPatientId] = useState(undefined);
  const setMigratePatientResponseCallback = useCallback(
    (val) => setMigratePatientResponse(val),
    [setMigratePatientResponse]
  );
  const setMigratePatientErrorCallback = useCallback(
    (val) => setMigratePatientError(val),
    [setMigratePatientError]
  );
  const setMigratePatientLoadingCallback = useCallback(
    (val) => setMigratePatientLoading(val),
    [setMigratePatientLoading]
  );
  const setMigratedPatientIdCallback = useCallback(
    (val) => setMigratedPatientId(val),
    [setMigratedPatientId]
  );

  useEffect(() => {
    if (
      currentModalState === MigratePatientStatus.STATE_1 &&
      patientToMigrateDataArray.length > 0
    ) {
      setIsModalOpen(true);
    }
  }, [currentModalState, patientToMigrateDataArray]);

  const closeModalCallback = useCallback(() => {
    const closeModal = () => {
      setIsModalOpen(false);
      setCurrentModalState(MigratePatientStatus.STATE_0);
      setHasDataErrors(false);
      setMigratePatientResponse(undefined);
      setMigratePatientError(undefined);
      setMigratePatientLoading(false);
      setCanSubmitConfirmation(false);
      setMigratedPatientId(undefined);
    };
    closeModal();
  }, []);

  const { data: nursesList, error: nursesListError } = useGetSortedNursesList();

  return (
    <MigratePatientContainer>
      <MigratePatientHeader>Migrate Member</MigratePatientHeader>
      <CreatePatientCard>
        <MigratePatientForm
          setPatientToMigrateDataArray={setPatientToMigrateDataArray}
          setCurrentModalState={setCurrentModalStateCallback}
          setathena_idCallback={setathena_idCallback}
          setMigratePatientLoadingCallback={setMigratePatientLoadingCallback}
          migratePatientLoading={migratePatientLoading}
        />
      </CreatePatientCard>
      <MigratePatientModal
        isModalOpen={isModalOpen}
        currentModalState={currentModalState}
        patientToMigrateDataArray={patientToMigrateDataArray}
        setHasDataErrors={setHasDataErrorsCallback}
        nursesList={nursesList}
        nursesListError={nursesListError}
        nurseEnrolledFormRef={nurseEnrolledFormRef}
        setCanSubmitConfirmationCallback={setCanSubmitConfirmationCallback}
        athena_id={athena_id}
        setCurrentModalStateCallback={setCurrentModalStateCallback}
        setMigratePatientResponseCallback={setMigratePatientResponseCallback}
        setMigratePatientErrorCallback={setMigratePatientErrorCallback}
        setMigratePatientLoadingCallback={setMigratePatientLoadingCallback}
        migratePatientLoading={migratePatientLoading}
        setMigratedPatientIdCallback={setMigratedPatientIdCallback}
        migratePatientResponse={migratePatientResponse}
        migratePatientError={migratePatientError}
        migratedPatientId={migratedPatientId}
        closeModal={closeModalCallback}
        handleNurseEnrolledFormSubmit={handleNurseEnrolledFormSubmit}
        hasDataErrors={hasDataErrors}
        canSubmitConfirmation={canSubmitConfirmation}
      />
    </MigratePatientContainer>
  );
};

export default MigratePatient;
