import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  styled,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import { useGetTasksQuery } from "common/services/GraphQLPABService";

import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { Task, TaskStatus } from "common/graphql/generated";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { getNameOrUsername } from "common/helpers/helpers";
import { useNavigate } from "react-router-dom";
import CreateNewTaskModal from "../../components/Modal/CreateNewTaskModal";
import ArcOfDaySidebar from "./ui/ArcOfDaySidebar";
import ErrorComponent from "../../components/ErrorComponent";

const Container = styled(Box)`
  margin: 2.5%;
`;

const Row = styled(Box)`
  display: flex;
  gap: 10px;
`;

const Content = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
`;

enum TabPanelIndex {
  taskQueue = 0,
  completed = 1
}

const RenderTask = ({ task }: { task: Task }) => {
  const navigate = useNavigate();
  const { data: member } = useGetMemberWithUsernameQuery({
    username: task.patientId
  });

  const typeString = useMemo(() => {
    const splittedString = task?.type?.split("_");
    const splittedStringLowerCase = splittedString.map((item) => {
      return item.charAt(0) + item.slice(1).toLowerCase();
    });

    return splittedStringLowerCase.join(" ");
  }, [task]);

  return (
    <>
      <Row
        alignItems={"center"}
        justifyContent={"space-between"}
        padding={"10px"}
      >
        <Typography width={200} variant="h6">
          {getNameOrUsername(member?.patient)}
        </Typography>
        <Typography width={200} variant="body1">
          {typeString}
        </Typography>
        <Typography variant="body1">
          {DateTime.fromISO(task.dueDate).toFormat("MM/dd/yyyy hh:mm a")}
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate(`/aod-dashboard/${task.id}`)}
        >
          {task.status === TaskStatus.Queued ? "Start" : "View"}
        </Button>
      </Row>
      <Divider />
    </>
  );
};

const RenderTasks = ({
  tasks,
  status
}: {
  tasks: Task[];
  status: TaskStatus;
}) => {
  const header = useMemo(() => {
    switch (status) {
      case TaskStatus.Queued:
        return "Upcoming Tasks";
      case TaskStatus.Completed:
        return "Completed Tasks";
    }
  }, [status]);

  return (
    <>
      <Typography variant="h5">{header}</Typography>
      <br />

      {tasks?.map((item: Task) => {
        return <RenderTask key={item.id} task={item} />;
      })}

      {tasks?.length === 0 && (
        <Typography variant="body1">There are no tasks to display</Typography>
      )}
    </>
  );
};

const ArcOfDayDashboard = () => {
  const [tabIndex, setTabIndex] = useState<number>(TabPanelIndex.taskQueue);
  const [createTaskModalOpen, setCreateTaskModalOpen] =
    useState<boolean>(false);

  const { data: authenticatedUser } = useGetAuthenticatedUser();
  const {
    data: todoTasks,
    isLoading: todoLoading,
    error: errorQueued
  } = useGetTasksQuery(
    {
      pageNumber: 0,
      pageSize: 25,
      staffId: authenticatedUser?.user?.user_id,
      status: TaskStatus.Queued
    },
    { skip: authenticatedUser === undefined }
  );
  const {
    data: completedTasks,
    isLoading: completedLoading,
    error: errorCompleted
  } = useGetTasksQuery(
    {
      pageNumber: 0,
      pageSize: 25,
      staffId: authenticatedUser?.user?.user_id,
      status: TaskStatus.Completed
    },
    { skip: authenticatedUser === undefined }
  );

  const isLoading = todoLoading || completedLoading;
  const isSuccess = completedTasks && todoTasks;
  const error = errorQueued || errorCompleted;

  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Container>
      {isLoading && <CircularProgress />}

      <ErrorComponent error={error} />

      {isSuccess && (
        <Row>
          <ArcOfDaySidebar
            todoTaskCount={todoTasks?.tasks?.totalResults}
            completedTaskCount={completedTasks?.tasks?.totalResults}
          />

          <Content>
            <Typography variant="h4">Tasks</Typography>
            <br />
            <Divider style={{ margin: "0px -20px" }} />

            <Tabs value={tabIndex} onChange={handleTabSelect}>
              <Tab
                label={"Task Queue"}
                style={{ fontWeight: "600" }}
                value={TabPanelIndex.taskQueue}
              />
              <Tab
                label={"Completed"}
                style={{ fontWeight: "600" }}
                value={TabPanelIndex.completed}
              />
            </Tabs>

            <br />

            {
              <>
                <RenderTasks
                  tasks={
                    tabIndex === TabPanelIndex.taskQueue
                      ? todoTasks.tasks.items
                      : completedTasks.tasks.items
                  }
                  status={
                    tabIndex === TabPanelIndex.taskQueue
                      ? TaskStatus.Queued
                      : TaskStatus.Completed
                  }
                />

                <br />
                <Button
                  variant="contained"
                  style={{ alignSelf: "start" }}
                  onClick={() => setCreateTaskModalOpen(true)}
                >
                  Create New Task
                </Button>
              </>
            }
          </Content>
        </Row>
      )}

      <CreateNewTaskModal
        isOpen={createTaskModalOpen}
        onRequestClose={() => setCreateTaskModalOpen(false)}
      />
    </Container>
  );
};

export default ArcOfDayDashboard;
