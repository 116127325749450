import styled from "@emotion/styled";

import {
  useGetLicensedStatesQuery,
  useGetMemberWithUsernameQuery
} from "common/services/MemberService";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { ErrorTextSpan } from "../../../styling/StyleComponents";
import ErrorComponent from "../../../components/ErrorComponent";
import { useEffect, useRef, useState } from "react";
import MemberValidationErrorBanner from "../../PatientData/MemberValidationErrorBanner";
import { checkIdValid } from "common/helpers/helpers";
import { Typography } from "@mui/material";
import useSanitizedParams from "../../../hooks/useSanitizedParams";

const LicensedStatesContainer = styled.div``;

const LicensedStates = () => {
  const params = useSanitizedParams();

  const { memberId } = params;
  const isValidId = checkIdValid(memberId);

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: [
        MemberLinkedEntitiesEnum.NURSE,
        MemberLinkedEntitiesEnum.PROVIDER,
        MemberLinkedEntitiesEnum.PROVIDER_METADATA,
        MemberLinkedEntitiesEnum.PATIENT_ACCESS_SUMMARY
      ]
    },
    { skip: memberId === undefined || isValidId === false }
  );

  const [isLicensedState, setIsLicensedState] = useState<boolean>(false);

  // need this variable to prevent flash of MemberValidationErrorBanner when switching between member details sidebar items
  const [componentIsLoaded, setComponentIsLoaded] = useState<boolean>(false);

  const {
    data: licensedStatesData,
    error: licensedStatesError,
    isSuccess: licensedStatesIsSuccess
  } = useGetLicensedStatesQuery(undefined, { skip: isValidId === false });

  useEffect(() => {
    if (
      licensedStatesData &&
      patient?.patient?.address?.state &&
      licensedStatesData.findIndex(
        (item) => item.state_id === patient?.patient?.address?.state
      ) > -1
    ) {
      setIsLicensedState(true);
    }
    return () => setIsLicensedState(false);
  }, [licensedStatesData, patient]);

  const timeoutRef = useRef(null);

  useEffect(() => {
    if (patient && (licensedStatesIsSuccess || licensedStatesError)) {
      timeoutRef.current = setTimeout(() => {
        setComponentIsLoaded(true);
        // slow down the loading of the component by 150ms to prevent flash of MemberValidationErrorBanner when switching between member details sidebar items
      }, 150);
    }
    return () => {
      setComponentIsLoaded(false);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [licensedStatesError, licensedStatesIsSuccess, patient]);

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );

  return (
    <LicensedStatesContainer>
      {licensedStatesError && patient && (
        <div>
          <ErrorTextSpan>Error getting licensed states:</ErrorTextSpan>{" "}
          <ErrorComponent error={licensedStatesError} />
        </div>
      )}

      {componentIsLoaded &&
        !isLicensedState &&
        licensedStatesData &&
        patient && (
          <MemberValidationErrorBanner
            // TBD remove prop from here and from component with move to v2
            containerMargin="0"
            type="STATE"
            value={patient?.patient?.address?.state}
          />
        )}
    </LicensedStatesContainer>
  );
};

export default LicensedStates;
