import { getErrorMessage, isFalsy } from "common/helpers/helpers";
import { ErrorText } from "../styling";
import ErrorType from "common/types/ErrorType";

interface IProps {
  header?: string;
  footer?: string;
  error?: ErrorType | string;
  style?: React.CSSProperties;
  showErrorResponseMessage?: boolean;
  hideErrorCode?: boolean;
}

const ErrorComponent = ({
  header,
  footer,
  error,
  showErrorResponseMessage = true,
  hideErrorCode = true,
  style
}: IProps) => {
  if (isFalsy(error)) return null;

  let errorString = "";
  if (typeof error === "string") {
    errorString = error;
  } else {
    errorString = getErrorMessage(error, {
      showErrorResponseMessage,
      hideErrorCode
    });
  }

  return (
    <ErrorText style={style}>
      <>
        {header}
        {header != undefined && <br />}
        {errorString}
        {footer != undefined && <br />}
        {footer}
      </>
    </ErrorText>
  );
};
export default ErrorComponent;
