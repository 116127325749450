import styled from "@emotion/styled";
import MemberType from "common/types/MemberType";
import ComplianceBannerNew from "./ComplianceBannerNew";
import { useFeatureFlags } from "common/config/FeatureFlags";

const ComplianceBannerContainer = styled.div`
  padding: 16px;
  display: flex;
  max-width: 240px;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.color.defaultBorder};
  border-radius: 4px;
  align-items: center;
  margin-top: 5px;
`;

interface IProps {
  patient: MemberType;
}

const ComplianceBanner = ({ patient }: IProps) => {
  const { patient_id, timezone } = patient.patient;
  const featureFlags = useFeatureFlags();

  if (featureFlags.MEMBER_HEADER_NEW) {
    return <ComplianceBannerNew patient={patient} />;
  } else {
    return (
      <ComplianceBannerContainer>
        <ComplianceBannerNew patient={patient} />
      </ComplianceBannerContainer>
    );
  }
};

export default ComplianceBanner;
