import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { RootState, useAppDispatch } from "common/redux";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import MemberType from "common/types/MemberType";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, useTheme } from "@mui/material";
import { CustomTooltip } from "../../../styling/StyleComponents";
import { gray } from "../../../styling/colors";
import {
  RenderStep,
  StepHorizontalSpacing,
  StepRowContainer
} from "./VisitContentField";
import { CheckCircle, EventAvailable, EventBusy } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDeleteCalendarEventsMutation } from "common/services/CalendarService";
import { isFalsy } from "common/helpers/helpers";
import { setAnswer } from "common/redux/VisitsSlice";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import { QuoteContainer, QuoteTypography } from "./VisitFieldQuote";

interface IProps {
  field: CareFlowFieldType;
  member: MemberType;
  visit: GetVisitResponseType;
}

const VisitFieldCarePlanWeeklyVisitsConvinceQuote = () => {
  return (
    <>
      <QuoteContainer
        style={{ display: "flex", flexDirection: "column", marginTop: "5px" }}
      >
        <QuoteTypography variant="body1">
          We've found that patients make the fastest progress when we increase
          the frequency of our visits to weekly. Here's why:
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
          <b>Real-time support</b> - If you're having any issues with your
          medications, diet, or anything else, we can catch it and make
          adjustments right away, instead of waiting two weeks to find and fix
          any problems.
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
          <b>Faster medication adjustments</b> - Sometimes, your treatment plan
          might need bigger changes, like adjusting your medication or dosage.
          Seeing you weekly helps us catch the signs earlier and make those
          changes faster, so you start feeling better sooner.
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
          <b>More accountability</b> - Whether you love it or not, most of our
          patients benefit from having more accountability. We're here to keep
          you on track, but more importantly, to support you through difficult
          changes.
        </QuoteTypography>
        <br />
        <QuoteTypography variant="body1">
          So yes, this means more appointments. But the big picture is that this
          change gives you the best chance to improve your health as quickly as
          possible. How does that sound?
        </QuoteTypography>
      </QuoteContainer>
      <br />
      <Typography variant="h4">FAQ</Typography>
      <br />
      <Typography variant="h6">
        I really don't want to move to weekly.
      </Typography>
      <QuoteContainer>
        <QuoteTypography variant="body1">
          I understand — weekly can feel like a big change. We're recommending
          it to help you make faster progress, with the goal of reducing visits
          down the line. Let's try it and re-evaluate after a few weeks?
        </QuoteTypography>
      </QuoteContainer>
      <Typography variant="h6">
        Ok, I can do weekly, but I'll need a different time.
      </Typography>
      <QuoteContainer>
        <QuoteTypography variant="body1">
          Of course! Let me know your preferred times, and I'll adjust to fit
          your schedule so we stay on track.
        </QuoteTypography>
      </QuoteContainer>
      <Typography variant="h6">Are you sure this is necessary?</Typography>
      <QuoteContainer>
        <QuoteTypography variant="body1">
          I get it — weekly can feel like a lot. But based on your health,
          weekly visits allow us to stay proactive and help you improve more
          quickly
        </QuoteTypography>
      </QuoteContainer>
    </>
  );
};

const VisitFieldCarePlanWeeklyVisits = ({ member, field, visit }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [step, setStep] = useState<number>(0);

  const [cancelPatientAppointments, { isLoading, isSuccess }] =
    useDeleteCalendarEventsMutation();

  useEffect(() => {
    if (isSuccess) setStep(2);
  }, [isSuccess]);

  const { answers } = useSelector((state: RootState) => state.visits);

  useEffect(() => {
    if (!isFalsy(answers[CareFlowFieldIdsEnum.CALL_CARE_PLAN_WEEKLY_VISITS]))
      setStep(
        Number(answers[CareFlowFieldIdsEnum.CALL_CARE_PLAN_WEEKLY_VISITS])
      );
  }, [answers]);

  const disabled = visit.status !== VisitStatusEnum.IN_PROGRESS;

  return (
    <>
      <Typography variant="body1">
        To rebook the member to weekly visits, click{" "}
        <b>{`'Cancel Appointments'`}</b>, wait for this to finish then click{" "}
        <b>{`'Reschedule'`}</b>.
      </Typography>
      <Typography variant="body1">
        After successfully scheduling a new recurring weekly visit, press{" "}
        <b>{`'Resume'`}</b> from the banner at the top to return to this visit.
      </Typography>
      <br />
      <StepRowContainer>
        <RenderStep step={1} disabled={disabled || step < 0} />
        <CustomTooltip
          title={"Cancels every appointment for this member"}
          placement="top"
        >
          <div>
            <Button
              variant="contained"
              onClick={() => {
                setStep(1);
                cancelPatientAppointments({
                  patient_id: member?.patient?.patient_id
                });
                dispatch(
                  setAnswer({
                    id: field.field_id,
                    value: `${1}`
                  })
                );
              }}
              startIcon={<EventBusy />}
              disabled={disabled || step < 0}
            >
              Cancel Appointments
            </Button>
          </div>
        </CustomTooltip>

        <StepHorizontalSpacing />

        <RenderStep step={2} disabled={disabled || step < 1} />
        <LoadingButton
          aria-label="End Call"
          sx={{
            minWidth: "32px",
            width: "32px",
            cursor: "default"
          }}
          loading={isLoading}
        >
          {!isLoading && (
            <CheckCircle
              style={{
                color: isSuccess ? theme.palette.primary.main : gray[200],
                fontSize: "32px"
              }}
            />
          )}
        </LoadingButton>

        <StepHorizontalSpacing />

        <RenderStep step={3} disabled={!isSuccess && (disabled || step < 2)} />
        <CustomTooltip
          title={"Click to reschedule this member to weekly visits"}
          placement="top"
        >
          <div>
            <LoadingButton
              variant="contained"
              disabled={!isSuccess && (disabled || step < 2)}
              onClick={() => {
                setStep(3);
                dispatch(
                  setAnswer({
                    id: field.field_id,
                    value: `${3}`
                  })
                );
                const nurseAppointment = `/members/memberId/${member?.patient?.patient_id}/new-nurse-appointment`;
                const intake = `/members/memberId/${member?.patient?.patient_id}/start-intake`;

                const stayWithNurse =
                  answers[
                    CareFlowFieldIdsEnum.CALL_CARE_PLAN_NEEDS_TO_STAY_WITH_NURSE
                  ] === "true";

                const link = stayWithNurse ? nurseAppointment : intake;
                navigate(link);
              }}
              startIcon={<EventAvailable />}
            >
              Reschedule
            </LoadingButton>
          </div>
        </CustomTooltip>
      </StepRowContainer>
      <br />
    </>
  );
};

export default VisitFieldCarePlanWeeklyVisits;
export { VisitFieldCarePlanWeeklyVisitsConvinceQuote };
