import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import { TurqoiseButton } from "../../styling";
import ReportedCancellations from "../../pages/ReportedCancellations/ReportedCancellations";
import SurveyLinkedEntitiesEnum from "common/enums/SurveyLinkedEntitiesEnum";

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const CancellationRequestModal = ({ isOpen, onRequestClose }: IProps) => {
  const header = "Cancellation Request History";

  return (
    <StyledModal
      isOpen={isOpen}
      modalHeight="70vh"
      modalWidth="1232px"
      contentLabel={header}
      onRequestClose={onRequestClose}
    >
      <ModalHeader onRequestClose={onRequestClose}>{header}</ModalHeader>
      <ModalBody>
        <ReportedCancellations
          showHeader={false}
          linked_entities={[SurveyLinkedEntitiesEnum.SUBMITTER]}
          tableColumns={[
            { name: "date", accessor: "patient_survey.created_at" },
            {
              name: "default",
              id: "reasonCategory",
              accessor: "category",
              header: "Category"
            },
            {
              name: "default",
              id: "subCategory",
              accessor: "subCategory",
              header: "Sub Category"
            },
            {
              name: "notes",
              id: "notes",
              accessor: "notes",
              header: "Notes"
            },
            {
              name: "cancellationRequestTeamMember"
            }
          ]}
        />
      </ModalBody>

      <ModalFooter>
        <ModalFooterButtons>
          <TurqoiseButton onClick={onRequestClose}>Close</TurqoiseButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default CancellationRequestModal;
