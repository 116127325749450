import { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import { TurqoiseButton, WhiteButton } from "../../../styling";
import { ModalFooter, ModalFooterButtons } from "../../../styling/StyleModal";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import ErrorComponent from "../../../components/ErrorComponent";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import { Flexbox } from "../../../styling/NewStyleComponents";
import {
  useDeleteCalendarEventMutation,
  DELAY_AFTER_REQUEST_COMPLETED
} from "common/services/CalendarService";
import RecurrenceUpdateTypeEnum from "common/enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import { isFalsy } from "common/helpers/helpers";

const updateTypeOptions = [
  { value: RecurrenceUpdateTypeEnum.ONCE, label: "Delete this event" },
  {
    value: RecurrenceUpdateTypeEnum.THIS_EVENT_FORWARD,
    label: "Delete this and following events"
  }
];

const StyledWhiteButton = styled(WhiteButton)`
  display: flex;
`;

const Form = styled.form`
  height: 100%;
`;
interface FormValues {
  updateType: RecurrenceUpdateTypeEnum;
}

interface IProps {
  eventId: string;
  attendeeId: string;
  recurring: boolean;
  modalId: string;
  handleClose: () => void;
}

const DeleteBlockedTimeForm = ({
  eventId,
  attendeeId,
  recurring,
  modalId,
  handleClose
}: IProps) => {
  const dispatch = useAppDispatch();

  const timerRef = useRef(null);

  const [
    deleteEventMutation,
    {
      isSuccess: isDeleteEventSuccess,
      isLoading: isDeleteEventLoading,
      fulfilledTimeStamp: isDeleteEventFulfilledTimestamp,
      error: deleteEventError
    }
  ] = useDeleteCalendarEventMutation();

  useEffect(() => {
    if (isDeleteEventSuccess) {
      timerRef.current = setTimeout(() => {
        Alert_close({ dispatch, id: modalId });
        handleClose();
        const id = "deleteEventSuccess";
        formik.resetForm();
        Alert_show({
          dispatch,
          id,
          title: "Success",
          content: "Cancelled appointment successfully.",
          type: "success",
          size: "small",
          buttons: [
            {
              text: "Close",
              onPress: () => {
                Alert_close({ dispatch, id });
              }
            }
          ]
        });
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [isDeleteEventSuccess]);

  useEffect(() => {
    if (deleteEventError) {
      Alert_close({ dispatch, id: modalId });
      handleClose();
      formik.resetForm();
      const id = "deleteEventError";
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={deleteEventError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [deleteEventError]);

  const validate = () => {
    const errors = {};
    if (isFalsy(formik.values.updateType)) {
      errors["updateType"] = "Required";
    }
    return errors;
  };

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value).catch(() => {
      return;
    });
  };

  const onSubmit = async (values: FormValues) => {
    const { updateType } = values;

    await deleteEventMutation({
      event_id: eventId,
      staff_or_team_id: attendeeId,
      delete_type: updateType
    });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      updateType: RecurrenceUpdateTypeEnum.ONCE
    },
    enableReinitialize: true,
    validate,
    onSubmit
  });

  const handleModalClose = () => {
    formik.resetForm();
    Alert_close({ dispatch, id: modalId });
    handleClose();
  };

  const isLoading = useGetDelayedLoadingBoolean(
    isDeleteEventLoading,
    isDeleteEventFulfilledTimestamp,
    isDeleteEventSuccess,
    DELAY_AFTER_REQUEST_COMPLETED
  );

  return (
    <Flexbox
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Flexbox
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Flexbox gap="24px" flexDirection="column">
            <Typography variant="body1" color="text.primary">
              Are you sure you want to delete this Blocked Time?
              <br />
              This action cannot be undone.
            </Typography>
            {recurring && (
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={formik?.values?.updateType ?? ""}
                onChange={(e) => setFieldValue("updateType", e.target.value)}
              >
                {updateTypeOptions.map(({ value, label }) => (
                  <Flexbox marginTop="4px" key={value}>
                    <FormControlLabel
                      sx={{
                        width: "100%",
                        margin: 0
                      }}
                      value={value}
                      control={<Radio sx={{ padding: "4px 0" }} />}
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            marginLeft: "2px"
                          }}
                        >
                          {label}
                        </Typography>
                      }
                    />
                  </Flexbox>
                ))}
              </RadioGroup>
            )}
            <br />
          </Flexbox>

          <ModalFooter>
            <ModalFooterButtons margin="0">
              <TurqoiseButton type="submit" loading={isLoading}>
                Confirm
              </TurqoiseButton>
              <StyledWhiteButton
                onClick={handleModalClose}
                disabled={isLoading}
              >
                Cancel
              </StyledWhiteButton>
            </ModalFooterButtons>
          </ModalFooter>
        </Flexbox>
      </Form>
    </Flexbox>
  );
};

export { DeleteBlockedTimeForm };
