import { DateTime } from "luxon";
import styled from "@emotion/styled";

import {
  LocalizationProvider,
  DateTimePicker as DateTimePickerMUI,
  DateTimePickerProps
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
`;

const DateTimePicker = (props: DateTimePickerProps<DateTime>) => {
  return (
    <Column>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DateTimePickerMUI {...props} />
      </LocalizationProvider>
    </Column>
  );
};

export default DateTimePicker;
