import { Button, Card, Link, Typography, styled } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";

import { DateTime } from "luxon";
import { RowContainer } from "../MemberDetails/StartIntake/StyledComponents";
import { Column, CustomTooltip } from "../../styling/StyleComponents";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux";
import { setSelectedSection } from "common/redux/VisitsSlice";
import {
  CareFlowSectionType,
  CareFlowSubSectionType
} from "common/types/Visits/CareFlowResponseType";
import * as MuiIcons from "@mui/icons-material";
import { blue, gray } from "../../styling/colors";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { getAthenaEncounterUrl } from "common/helpers/EnvVarsHelper";
import { Flexbox } from "../../styling/NewStyleComponents";
import { VisitMotivationTypesEnum_toString } from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import { isFalsy } from "common/helpers/helpers";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { getElapsedTime, tickElapsedTime } from "./VisitHelper";
import RecentReadings from "./ui/RecentReadings";

const Container = styled(Card)`
  background: #ffffff;
`;

const Spacing = styled("div")`
  flex: 1;
`;

const Row = styled(Flexbox)`
  display: flex;
  align-items: center;
`;

const TimerTypography = styled(Typography)`
  margin-left: 20px;
  margin-right: 3px;
  padding: 8px 5px;
  border-radius: 6px;
  width: 55px;
  text-align: center;
`;

export const SidebarContainerStyle = {
  minWidth: 260,
  border: 1,
  borderColor: `${gray[300]}`,
  boxShadow: 0
};

interface SubSectionItemProps {
  subSection: CareFlowSubSectionType;
  onClick: () => void;
}

const getTimeFormat = (dateTime: DateTime) => {
  if (dateTime.toUTC().hour === 0) return "mm:ss";
  else return "hh:mm:ss";
};

const SubSectionItem = ({ subSection, onClick }: SubSectionItemProps) => {
  const { name } = subSection;

  const { selectedSubSection, elapsedTime } = useSelector(
    (state: RootState) => state.visits
  );

  const totalSeconds = useMemo(() => {
    const sectionElapsedTime = elapsedTime?.filter(
      (item) => item?.subsection_id === subSection.section_id
    );

    return (
      sectionElapsedTime?.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue?.actual_time_seconds || 0,
        0
      ) ?? 0
    );
  }, [elapsedTime, subSection]);

  const linkStyle = useMemo(() => {
    const BASE_STYLE = {
      cursor: "pointer",
      color: "primary",
      textDecoration: "none"
    };
    if (subSection.section_id === selectedSubSection) {
      return {
        ...BASE_STYLE,
        fontWeight: "bold",
        textDecoration: "underline"
      };
    } else if (totalSeconds > 0) {
      return BASE_STYLE;
    } else return { ...BASE_STYLE, fontWeight: "bold" };
  }, [subSection, selectedSubSection, totalSeconds]);

  return (
    <Link
      key={subSection.name}
      sx={linkStyle}
      variant="subtitle2"
      onClick={onClick}
    >
      {name}
    </Link>
  );
};

const getMUIIcon = (section: CareFlowSectionType) => {
  switch (section.name) {
    case "Pre-call Preparation":
      return "Monitor";
    case "Call":
      return "Call";
    case "Post-call":
      return "EventAvailable";
    default:
      return "Monitor";
  }
};

const SectionItem = ({
  section,
  syncCareFlowToAPI
}: {
  section: CareFlowSectionType;
  syncCareFlowToAPI: () => void;
}) => {
  const { name, subsections, section_id } = section;

  const dispatch = useAppDispatch();
  const { selectedSection } = useSelector((state: RootState) => state.visits);

  const expectedTimeMinutes = Math.round(section.expected_time_seconds / 60);

  const Icon = MuiIcons[getMUIIcon(section)];

  return (
    <Accordion
      elevation={0}
      sx={{
        backgroundColor:
          selectedSection === section.section_id ? blue[50] : "white"
      }}
      disableGutters
      expanded={section.section_id === selectedSection}
      onChange={() => {
        dispatch(
          setSelectedSection({
            section_id: section.section_id,
            subsection_id: section.subsections[0].section_id
          })
        );
      }}
    >
      <AccordionSummary>
        <RowContainer sx={{ cursor: "pointer", gap: "5px" }}>
          <Icon
            htmlColor={selectedSection !== section.section_id && gray[900]}
            color={
              selectedSection === section.section_id ? "primary" : undefined
            }
          />
          <Typography
            variant="subtitle2"
            color={
              selectedSection === section.section_id ? "primary" : gray[900]
            }
          >
            {name} ({expectedTimeMinutes} min)
          </Typography>

          <Spacing />

          {/* Commenting out for now -- times in sections get desynced
          {elapsedSectionDateTime && (
            <CustomTooltip title="Time in Section" placement="right">
              <Typography
                variant="subtitle2"
                color={
                  selectedSection === section.section_id ? "primary" : gray[900]
                }
              >
                {elapsedSectionDateTime.toFormat(
                  getTimeFormat(elapsedSectionDateTime)
                )}
              </Typography>
            </CustomTooltip>
          )} */}
        </RowContainer>
      </AccordionSummary>
      <Column marginLeft={8} marginBottom={1}>
        {subsections.map((item) => {
          return (
            <SubSectionItem
              key={item.section_id}
              subSection={item}
              onClick={() => {
                syncCareFlowToAPI();

                dispatch(
                  setSelectedSection({
                    section_id: section_id,
                    subsection_id: item.section_id
                  })
                );
              }}
            />
          );
        })}
      </Column>
    </Accordion>
  );
};

let lastProcessedTime: DateTime;

const VisitSections = ({
  visit,
  syncCareFlowToAPI
}: {
  visit: GetVisitResponseType;
  syncCareFlowToAPI: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { selectedSection, elapsedTime } = useSelector(
    (state: RootState) => state.visits
  );

  useEffect(() => {
    const interval = tickElapsedTime(dispatch, lastProcessedTime);

    return () => {
      if (!interval) return;
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    // This is required for the first time use only, because we want to select the first section if no section is selected.
    if (!visit) return;

    const foundSection = visit?.care_flow?.sections.find(
      (section) => section.section_id === selectedSection
    );

    if (!foundSection && visit?.care_flow?.sections?.length > 0) {
      const newSection = visit?.care_flow?.sections[0];
      dispatch(
        setSelectedSection({
          section_id: newSection.section_id,
          subsection_id: newSection.subsections[0].section_id
        })
      );
    }
  }, [selectedSection, visit]);

  const elapsedCompletedTotalDateTime = useMemo(() => {
    const totalSeconds =
      elapsedTime?.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue?.actual_time_seconds || 0,
        0
      ) ?? 0;

    return DateTime.fromSeconds(totalSeconds);
  }, [elapsedTime]);

  const elapsedStartedTotalDateTime = getElapsedTime(visit);

  const elapsedTotalDateTime =
    visit?.status === VisitStatusEnum.COMPLETED
      ? elapsedCompletedTotalDateTime
      : elapsedStartedTotalDateTime;

  return (
    <Column>
      <Row justifyContent={"space-between"}>
        <Typography variant="h6" color={gray[900]}>
          {VisitMotivationTypesEnum_toString(visit.motivation_reason)}
        </Typography>

        {elapsedTotalDateTime && (
          <CustomTooltip title="Total Time in Task" placement="right">
            <TimerTypography
              variant="h6"
              color={gray[900]}
              sx={{ fontSize: "110%" }}
            >
              {elapsedTotalDateTime.toFormat(
                getTimeFormat(elapsedTotalDateTime)
              )}
            </TimerTypography>
          </CustomTooltip>
        )}
      </Row>

      <Container
        sx={SidebarContainerStyle}
      >
        {visit?.care_flow?.sections?.map((item) => {
          return (
            <SectionItem
              syncCareFlowToAPI={syncCareFlowToAPI}
              data-testid={item.name}
              key={item.name}
              section={item}
            />
          );
        })}
      </Container>

      <Button
        variant="contained"
        onClick={() => {
          window.open(
            getAthenaEncounterUrl(visit.athena_encounter_id),
            "_blank"
          );
        }}
        disabled={isFalsy(visit.athena_encounter_id)}
      >
        View Encounter in Athena
      </Button>

      {visit && <RecentReadings memberId={visit?.patient?.patient_id} />}
    </Column>
  );
};

export default VisitSections;
