import React from "react";
import LocalizedStrings from "../localizations/LocalizedStrings";

import {
  AlertProps,
  alertShow,
  alertClose,
  alertLoading,
  alertCloseAll
} from "../redux/AlertSlice";
import { AppDispatch } from "../redux";

interface AlertPropsHelper extends AlertProps {
  dispatch: AppDispatch;
}

export const Alert_loading = (props: AlertPropsHelper) => {
  const { dispatch, ...payload } = props;
  dispatch(alertLoading(payload));
};

export const Alert_show = (props: AlertPropsHelper) => {
  const { dispatch, ...payload } = props;
  dispatch(alertShow(payload));
};

export const Alert_close = (props: AlertPropsHelper) => {
  const { dispatch, ...payload } = props;
  dispatch(alertClose(payload));
};

export const Alert_closeAll = (dispatch: AppDispatch) => {
  dispatch(alertCloseAll());
};

export const Alert_showDestructive = (
  dispatch: AppDispatch,
  id: string,
  title: string,
  content: React.ReactNode | React.ReactNode[],
  onDestroyPressed: () => void,
  onCancelPressed = () => Alert_close({ dispatch, id, title })
) => {
  const payload: AlertProps = {
    id,
    title,
    content,
    type: "warning",
    row: true,
    buttons: [
      {
        text: LocalizedStrings.unassign,
        style: "destructive",
        onPress: onDestroyPressed,
        hasLoadingState: true
      },
      {
        text: LocalizedStrings.cancel,
        style: "cancel",
        onPress: onCancelPressed
      }
    ]
  };

  dispatch(alertShow(payload));
};
