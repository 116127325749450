const language = {
  unassign: "Unassign",
  close: "Close",
  cancel: "Cancel",
  submit: "Submit",

  videoCall: {
    memberTurnedOffVideo: "Member turned off video",
    carerTurnedOffVideo: "Care team member turned off video",
    roomFailedToConnectModalTitle: "Room failed to connect",
    areYouSureYouWantToLeave: "Are you sure you want to leave the video call?",
    waitingForMemberToJoin: "Waiting for member to join",
    waitingForCarerToJoin: "Waiting for care team member to join"
  },

  error: {
    title: "An error has occurred",
    sms_code:
      "This does not match our records. If you believe this is an error, please contact your nurse.",
    sign_in_code: "Invalid verification code.",
    video_room_not_found: "This video room code is not valid or has expired.",

    200: "Query was successful.",
    201: "Query was successful.",
    400: "The request is invalid.",
    401: "The request is unauthorized.",
    403: "The request is forbidden.",
    404: "Content not found.",
    409: "There is a conflict",
    500: "Unexpected error. Please contact support.",
    default: "Something went wrong. Please try again later.",
    code: "Error Code: {{CODE}}."
  }
};

export default language;
