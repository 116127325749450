import styled from "@emotion/styled";
import { Athena, AthenaGrey } from "../../assets/images/icons";
import { CustomTooltip } from "../../styling/StyleComponents";
import Typography from "@mui/material/Typography";
import { isFalsy } from "common/helpers/helpers";
import { getAthenaPatientUrl } from "common/helpers/EnvVarsHelper";

const StyledAthenaIcon = styled(Athena)`
  height: 28px;
  width: 28px;
`;

const GreyAthenaIcon = styled(AthenaGrey)`
  height: 28px;
  width: 28px;
`;

const AthenaButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 40px;
  align-items: center;
`;

interface IProps {
  athenaId?: string;
}

const AthenaChartButton = ({ athenaId }: IProps) => {
  if (isFalsy(athenaId)) {
    return (
      <CustomTooltip placement="top" title={`No Athena ID found`}>
        <AthenaButtonWrapper>
          <GreyAthenaIcon />
          <Typography variant="body2">{athenaId}</Typography>
        </AthenaButtonWrapper>
      </CustomTooltip>
    );
  }

  return (
    <CustomTooltip placement="top" title={`View Member in Athena #${athenaId}`}>
      <AthenaButtonWrapper
        onClick={() => window.open(getAthenaPatientUrl(athenaId), "_blank")}
      >
        <StyledAthenaIcon />
        <Typography variant="body2" aria-label="athenaId">
          {athenaId}
        </Typography>
      </AthenaButtonWrapper>
    </CustomTooltip>
  );
};

export default AthenaChartButton;
