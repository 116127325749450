import styled from "@emotion/styled";
import { Flexbox } from "../../../../styling/NewStyleComponents";
import { MoreTime } from "@mui/icons-material";
import { useCallback, useState } from "react";
import { SubmitEncounterModal } from "./SubmitEncounterModal";
import { useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux";
import { ProviderRoles } from "common/enums/RolesEnum";
import {
  arrayHasProviderSubmittedEncounter,
  newEncounterReasons
} from "common/helpers/EncounterHelper";
import { DateTime } from "luxon";
import MemberType from "common/types/MemberType";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { getNameOrUsername } from "common/helpers/helpers";
import LocalizedStrings from "common/localizations/LocalizedStrings";
import { useGetEncountersQuery } from "common/services/EncountersService";
import StyledIconButton from "../../../../components/Button/StyledIconButton";
// import { useGetEncounterVisitsQuery } from "common/services/VisitsService";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 10px;
`;

const SubmitEncounterContainer = ({
  memberId,
  patient
}: {
  memberId: string;
  patient: MemberType;
}) => {
  const { data: dailyEncounters } = useGetEncountersQuery({
    // useGetEncounterVisitsQuery({
    patient_id: memberId,
    startsAfter: DateTime.now().startOf("day")
    // param for encounter-visit endpoint - comment this back in when we are ready to use this
    // encounter_starts_on_after: DateTime.now().startOf("day")
  });

  const { data: historicalEncounters } = useGetEncountersQuery({
    // useGetEncounterVisitsQuery({
    patient_id: memberId,
    startsAfter: DateTime.fromFormat("2021-01-01", "yyyy-MM-dd").startOf("day")
    // param for encounter-visit endpoint - comment this back in when we are ready to use this
    // encounter_starts_on_after: DateTime.fromFormat(
    //   "2021-01-01",
    //   "yyyy-MM-dd"
    // ).startOf("day")
  });

  const hasProviderSubmittedEncounter =
    arrayHasProviderSubmittedEncounter(historicalEncounters);

  const hasInvalidProviderAssigned = patient?.provider_metadata === undefined;

  const isNewPatientNotFirstEncounter =
    patient?.patient?.migrated === false && historicalEncounters?.length > 0;

  const isMigratedPatient = patient?.patient?.migrated;

  const hasRepeatedEncounter =
    dailyEncounters != undefined && dailyEncounters.length > 0;

  // commenting this code out for now - we will need it in a follow up

  // const totalDuration = dailyEncounters?.reduce(
  //   (accumulator: number, encounter) => {
  //     let result = accumulator;
  //     if (
  //       typeof encounter?.encounter?.duration === "number" &&
  //       encounter?.encounter?.duration > 0
  //     ) {
  //       result = result + encounter?.encounter?.duration;
  //     } else if (
  //       typeof encounter?.visit?.total_time === "number" &&
  //       encounter?.visit?.total_time > 0
  //     ) {
  //       result = result + encounter?.visit?.total_time;
  //     }
  //     return result;
  //   },
  //   0
  // );

  return (
    <SubmitEncounterContainerRender
      memberId={memberId}
      patient={patient}
      isMigratedPatient={isMigratedPatient}
      hasRepeatedEncounter={hasRepeatedEncounter}
      hasProviderSubmittedEncounter={hasProviderSubmittedEncounter}
      hasInvalidProviderAssigned={hasInvalidProviderAssigned}
      isNewPatientNotFirstEncounter={isNewPatientNotFirstEncounter}
    />
  );
};

const SubmitEncounterContainerRender = ({
  memberId,
  patient,
  isMigratedPatient,
  hasRepeatedEncounter,
  hasProviderSubmittedEncounter,
  hasInvalidProviderAssigned,
  isNewPatientNotFirstEncounter
}: {
  memberId: string;
  patient: MemberType;
  isMigratedPatient: boolean;
  hasRepeatedEncounter: boolean;
  hasProviderSubmittedEncounter: boolean;
  hasInvalidProviderAssigned: boolean;
  isNewPatientNotFirstEncounter: boolean;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { currentRole } = useSelector((state: RootState) => state.auth);
  const isProvider = ProviderRoles.includes(currentRole);
  const encounterReasons = newEncounterReasons(currentRole);

  const onClick = useCallback(() => {
    if (hasRepeatedEncounter) {
      const id = "hasRepeatedEncounter";
      Alert_show({
        dispatch,
        id,
        title: "Create encounter confirmation",
        content: (
          <div>
            <div>
              Member {getNameOrUsername(patient.patient)} already has{" "}
              <b>time entered today</b>.
            </div>
            <div>Are you sure you want to continue?</div>
          </div>
        ),
        type: "warning",
        size: "medium",
        buttons: [
          {
            text: "Yes, duplicate",
            style: "cancel",
            onPress: () => {
              Alert_close({ dispatch, id });
              setModalOpen(true);
            }
          },
          {
            text: LocalizedStrings.cancel,
            style: "default",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    } else {
      setModalOpen(true);
    }
  }, [
    isMigratedPatient,
    hasRepeatedEncounter,
    hasProviderSubmittedEncounter,
    hasInvalidProviderAssigned,
    isNewPatientNotFirstEncounter
  ]);

  return (
    <Container>
      <Flexbox width="100%" gap="8px">
        <Flexbox gap="4px">
          <StyledIconButton
            Icon={MoreTime}
            iconColor={theme.palette.primary.main}
            border="square"
            onClick={onClick}
          />
        </Flexbox>
      </Flexbox>
      <SubmitEncounterModal
        memberId={memberId}
        memberName={getNameOrUsername(patient?.patient)}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isProvider={isProvider}
        encounterReasons={encounterReasons}
        hasProviderSubmittedEncounter={hasProviderSubmittedEncounter}
        hasInvalidProviderAssigned={hasInvalidProviderAssigned}
      />
    </Container>
  );
};

export default SubmitEncounterContainer;
