import { useEffect, StrictMode } from "react";
import { createRoot } from "react-dom/client";

import {
  init,
  browserProfilingIntegration,
  replayIntegration,
  browserTracingIntegration,
  reactRouterV6BrowserTracingIntegration,
  contextLinesIntegration,
  extraErrorDataIntegration
} from "@sentry/react";
import { Global } from "@emotion/react";
import ReduxWrapper from "common/redux/ReduxWrapper";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "common/redux";
import EnvVars from "common/config/EnvVars";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from "react-router-dom";

// not dev
if (!(!process.env.NODE_ENV || process.env.NODE_ENV === "development")) {
  // init Sentry
  init({
    dsn: EnvVars.REACT_APP_SENTRY_DSN,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      browserProfilingIntegration(),
      replayIntegration({
        // pass extra.ignore_replay = true to an event to not replay it
        // https://github.com/getsentry/sentry-javascript/issues/9413#issuecomment-1804801573
        beforeErrorSampling: (event) => !event?.extra?.ignore_replay
      }),
      browserTracingIntegration({
        routingInstrumentation: reactRouterV6BrowserTracingIntegration(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      contextLinesIntegration(),
      extraErrorDataIntegration()
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV,
    release: "remoteiq-frontend@" + process.env.REACT_APP_VERSION
  });
}

const root = createRoot(document.getElementById("root"));
root.render(
  <ReduxWrapper store={store}>
    <StrictMode>
      <Global
        styles={{
          body: {
            margin: 0,
            padding: 0,
            fontFamily: "Inter"
          }
        }}
      />
      <App />
    </StrictMode>
  </ReduxWrapper>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
