import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState
} from "react";

import { useFormik, FormikProps } from "formik";
import styled from "@emotion/styled";

import { unmaskPhoneNumber } from "common/helpers/helpers";
import {
  useLazyGetMembersWithQueryParamsQuery,
  useLazyGetMemberWithAthenaIdQuery
} from "common/services/MemberService";
import MemberType from "common/types/MemberType";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import RolesEnum from "common/enums/RolesEnum";

import ErrorComponent from "../../ErrorComponent";

import { TextField, Typography } from "@mui/material";
import PhoneInputField from "../../Input/PhoneInputField";
import ErrorType from "common/types/ErrorType";

const SearchPatientFormContainer = styled.div``;

export interface SearchPatientFormType {
  first: string;
  last: string;
  //phone: string;
  mobile: string;
  athena: string;
}

interface IProps {
  setSearchPatientResultsCallback: ({
    results
  }: {
    results: MemberType[];
  }) => void;
  setSearchPatientStepCallback: (value: number) => void;
  updateSelectPatientFormDirty: (dirty: boolean) => void;
  dataLoadingProps?: {
    setDataCallback?: (users: MemberType[] | undefined) => void;
    setDataLoadingCallback?: (isDataLoading: boolean) => void;
    setDataErrorCallback?: (error: ErrorType) => void;
  };
  resetSearchState: () => void;
  onSubmitProps?: {
    userListFilters: {
      roles?: RolesEnum[];
      status?: MemberStatusEnum[];
      linked_entities?: MemberLinkedEntitiesEnum[];
    };
  };
  setSearchPatientLoading: (loading: boolean) => void;
}

const SearchPatientForm = forwardRef(
  (props: IProps, ref: Ref<FormikProps<SearchPatientFormType>>) => {
    const [getPatientsWithQueryParams] =
      useLazyGetMembersWithQueryParamsQuery();
    const [getPatientWithAthenaId] = useLazyGetMemberWithAthenaIdQuery();

    const {
      setSearchPatientResultsCallback,
      setSearchPatientStepCallback,
      updateSelectPatientFormDirty,
      dataLoadingProps,
      resetSearchState,
      onSubmitProps,
      setSearchPatientLoading
    } = props;
    const { userListFilters } = onSubmitProps || {};
    const { setDataCallback, setDataLoadingCallback, setDataErrorCallback } =
      dataLoadingProps || {};
    const [searchPatientError, setSearchPatientError] = useState(undefined);

    useEffect(() => {
      return () => {
        updateSelectPatientFormDirty(false);
      };
    }, []);

    const validate = (values: SearchPatientFormType) => {
      const errors: { [key: string]: string } = {};

      if (
        values.first.trim() === "" &&
        values.last.trim() === "" &&
        (values.mobile === undefined || values.mobile.length < 4) &&
        values.athena === ""
      ) {
        errors["required"] = "Please fill out at least one field";
        updateSelectPatientFormDirty(false);
      } else updateSelectPatientFormDirty(true);

      if (/\D/.test(values.athena)) {
        errors["athena"] = "Please enter a valid Athena ID. Use only numbers.";
      }

      return errors;
    };

    const onSubmit = async (values: SearchPatientFormType) => {
      /*const phoneValue = filteredValues.phone
      ? unmaskPhoneNumber(filteredValues.phone)
      : "";*/
      const mobileValue = values.mobile ? unmaskPhoneNumber(values.mobile) : "";
      const first = values.first.trim();
      const last = values.last.trim();
      const filters = {
        ...userListFilters,
        first: first.length > 0 ? first : undefined,
        last: last.length > 0 ? last : undefined,

        //phone: phoneValue,
        mobile: mobileValue,
        sort: "lastName,ASC,firstName,ASC"
      };

      const searchByAthenaID = values?.athena?.length > 0;

      setSearchPatientLoading(true);
      if (setDataLoadingCallback) setDataLoadingCallback(true);

      let searchPatientResult;
      if (searchByAthenaID) {
        searchPatientResult = await getPatientWithAthenaId({
          athenaId: values.athena
        });
      } else {
        searchPatientResult = await getPatientsWithQueryParams(filters);
      }
      setSearchPatientLoading(false);
      const { isSuccess, data, error } = searchPatientResult;
      if (isSuccess) {
        // short circuit evaluation - we need 2 separate lines here

        if (setDataCallback) {
          setDataCallback(data);
          resetSearchState();
          if (setDataLoadingCallback) setDataLoadingCallback(false);

          // break if we execute the above 2 lines
          return;
        }

        // improvement opportunity - we can pass the following 2 lines as a callback and execute it in the parent component
        // This will require a larger scale refactor of the modal component, adding a stepper component, and having logic to pass props
        // between variable child components
        // Leaving this note here so if we have time for this larger refactor, we can do it
        setSearchPatientResultsCallback({
          results: data
        });

        setSearchPatientStepCallback(1);
        setSearchPatientError(undefined);

        if (setDataErrorCallback) setDataErrorCallback(undefined);
      } else {
        if (setDataErrorCallback) setDataErrorCallback(error?.message);
        setSearchPatientError(error);
      }

      if (setDataLoadingCallback) setDataLoadingCallback(false);
    };

    const formik = useFormik<SearchPatientFormType>({
      validate,
      initialValues: {
        first: "",
        last: "",
        //phone: "",
        mobile: "",
        athena: ""
      },
      onSubmit,
      innerRef: ref
    });

    useImperativeHandle(ref, () => ({
      ...formik
    }));

    const { dirty, values } = formik;
    useEffect(() => {
      const keyDownHandler = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();

          if (dirty) {
            onSubmit(values);
          }
        }
      };

      document.addEventListener("keydown", keyDownHandler);
      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, [dirty, values]);

    const setFieldValue = (key, value) => {
      formik.setFieldValue(key, value).catch(() => {});
    };

    return (
      <SearchPatientFormContainer>
        <form
          onSubmit={formik.handleSubmit}
          data-testid="SearchPatientForm-form"
        >
          <TextField
            id="first"
            value={formik.values.first}
            onChange={(e) => {
              setFieldValue("first", e.target.value);
            }}
            label="First Name"
            fullWidth
            placeholder={"Enter first name here"}
            error={formik.errors["first"] !== undefined}
            helperText={formik.errors["first"]}
            autoComplete="given-name"
          />
          <br />
          <br />
          <TextField
            id="last"
            value={formik.values.last}
            onChange={(e) => {
              setFieldValue("last", e.target.value);
            }}
            label="Last Name"
            fullWidth
            placeholder={"Enter last name here"}
            error={formik.errors["last"] !== undefined}
            helperText={formik.errors["last"]}
            autoComplete="family-name"
          />
          <br />
          <br />
          {/*<PhoneInputField
            value={formik.values.phone}
            field={{}}
            form={{}}
            label={"Phone number"}
            fullWidth
            onChange={(event) =>
              setFieldValue("phone", event.target.value)
            }
            placeholder={"Enter phone number"}
          />  
          <br /> 
          <br />*/}
          <PhoneInputField
            autoComplete="tel"
            value={formik.values.mobile}
            label={"Mobile number"}
            fullWidth
            onChange={(event) => setFieldValue("mobile", event.target.value)}
            placeholder={"Enter mobile number"}
          />
          <br />
          <br />
          <Typography variant="body2">OR</Typography>
          <br />
          <TextField
            id="athena"
            value={formik.values.athena}
            onChange={(e) => {
              setFieldValue("athena", e.target.value);
            }}
            label="Member Athena ID"
            fullWidth
            placeholder={"Enter Member Athena ID here"}
            error={formik.errors["athena"] !== undefined}
            helperText={formik.errors["athena"]}
          />
        </form>
        {searchPatientError && <ErrorComponent error={searchPatientError} />}
      </SearchPatientFormContainer>
    );
  }
);

export default SearchPatientForm;
