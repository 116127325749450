import { QueryString_stringify } from "../helpers/QueryStringHelper";

import { apiPAB } from "./AxiosService";

import ReduxTagEnum from "../enums/ReduxTagEnum";
import { providesList } from "./ServicesCacheUtils";
import LinkedEntitiesEnum from "../enums/MemberLinkedEntitiesEnum";
import { ProviderMetadataType } from "../types/ProviderMetadataType";

const providesTags = (result: ProviderMetadataType[] | undefined) => {
  return providesList<ProviderMetadataType, ReduxTagEnum.ProviderMetadata>(
    result,
    ReduxTagEnum.ProviderMetadata,
    (item: ProviderMetadataType) => item.provider_metadata.provider_id
  );
};

const providersMetadataService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.ProviderMetadata]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getProvidersMetadata: builder.query<
        ProviderMetadataType[],
        {
          state?: string;
          linked_entities?: LinkedEntitiesEnum | LinkedEntitiesEnum[];
        }
      >({
        query: ({ state, linked_entities }) => {
          const queryParams = QueryString_stringify({
            state,
            linked_entities
          });
          return { url: `/providers-metadata?${queryParams}`, method: "GET" };
        },
        providesTags,
        transformResponse: (response: ProviderMetadataType[]) => {
          // TBD do this on the backend instead  https://copilotiq.atlassian.net/browse/ENG-5984
          const filteredResponse = response?.filter((item) => {
            return item?.provider?.status === "ACTIVE";
          });
          return filteredResponse;
        }
      }),
      getProviderMetadata: builder.query<
        ProviderMetadataType,
        { username?: string }
      >({
        query: ({ username }) => {
          return { url: `/providers-metadata/${username}`, method: "GET" };
        },
        providesTags: (result, error, arg) => [
          {
            type: ReduxTagEnum.ProviderMetadata,
            id: arg?.username
          }
        ]
      })
    })
  });

export const { useGetProviderMetadataQuery, useGetProvidersMetadataQuery } =
  providersMetadataService;
