import { createSlice } from "@reduxjs/toolkit";
import CommunicationTypeEnum from "../enums/Calendaring/CommunicationTypeEnum";
import RecurrenceUpdateTypeEnum from "../enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";
import AppointmentTypeEnum from "../enums/Calendaring/Appointments/AppointmentTypeEnum";

const initialState = {
  activeStep: 1,
  step2SelectionsAreValid: false,
  step2IsLoading: false,
  step3IsLoading: false,
  selectedStartDate: null,
  selectedEndDate: null,
  communication_type: CommunicationTypeEnum.PHONE,
  attendees: [],
  staffId: null,
  staffName: null,
  recurrence: null,
  recurrenceUpdateType: RecurrenceUpdateTypeEnum.ONCE,
  appointmentType: AppointmentTypeEnum.NURSE_FOLLOWUP,
  memberName: null,
  memberTimezone: null,
  memberAssignedNurse: null,
  xTraceId: null
};

export const startIntakeSlice = createSlice({
  name: "startIntake",
  initialState,
  reducers: {
    resetStartIntakeState: (state) => {
      state.activeStep = initialState.activeStep;
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
      state.communication_type = initialState.communication_type;
      state.attendees = initialState.attendees;
      state.staffId = initialState.staffId;
      state.staffName = initialState.staffName;
      state.recurrence = initialState.recurrence;
      state.memberName = initialState.memberName;
      state.memberTimezone = initialState.memberTimezone;
      state.recurrenceUpdateType = initialState.recurrenceUpdateType;
      state.appointmentType = initialState.appointmentType;
      state.step2SelectionsAreValid = initialState.step2SelectionsAreValid;
      state.step2IsLoading = initialState.step2IsLoading;
      state.step3IsLoading = initialState.step3IsLoading;
      state.memberAssignedNurse = initialState.memberAssignedNurse;
      state.xTraceId = initialState.xTraceId;
    },
    resetAppointmentSelections: (state) => {
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
      state.communication_type = initialState.communication_type;
      state.staffId = initialState.staffId;
      state.staffName = initialState.staffName;
      state.recurrence = initialState.recurrence;
      state.recurrenceUpdateType = initialState.recurrenceUpdateType;
    },
    setSelectedTime: (state, action) => {
      const { startDate, endDate, staffId, staffName } = action.payload;
      state.selectedStartDate = startDate;
      state.selectedEndDate = endDate;
      state.staffId = staffId;
      state.staffName = staffName;
    },
    resetSelectedTime: (state) => {
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
      state.staffId = initialState.staffId;
      state.staffName = initialState.staffName;
    },
    setAttendees: (state, action) => {
      state.attendees = action.payload;
    },
    setMemberInfo: (state, action) => {
      const { memberName, memberTimezone, memberAssignedNurse } =
        action.payload;
      state.memberName = memberName;
      state.memberTimezone = memberTimezone;
      state.memberAssignedNurse = memberAssignedNurse;
    },
    setRecurrence: (state, action) => {
      state.recurrence = action.payload;
      state.selectedStartDate = initialState.selectedStartDate;
      state.selectedEndDate = initialState.selectedEndDate;
      state.staffId = initialState.staffId;
      state.staffName = initialState.staffName;
    },
    setStep2SelectionsAreValid: (state, action) => {
      state.step2SelectionsAreValid = action.payload;
    },
    setStep2IsLoading: (state, action) => {
      state.step2IsLoading = action.payload;
    },
    setStep3IsLoading: (state, action) => {
      state.step3IsLoading = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setXTraceId: (state, action) => {
      state.xTraceId = action.payload;
    }
  }
});

export const {
  resetAppointmentSelections,
  resetSelectedTime,
  setSelectedTime,
  setAttendees,
  setMemberInfo,
  setRecurrence,
  setStep2SelectionsAreValid,
  setStep2IsLoading,
  setStep3IsLoading,
  resetStartIntakeState,
  setActiveStep,
  setXTraceId
} = startIntakeSlice.actions;

export default startIntakeSlice.reducer;
