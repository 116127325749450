import { createElement } from "react";
import RolesEnum from "common/enums/RolesEnum";
import fuzzysort from "fuzzysort";
import get from "lodash.get";
import { DateTime } from "luxon";
import skuMappings from "../config/skuMappings.json";
import EncounterType from "common/types/EncounterType";
import AddressesType from "common/types/AddressesType";
import OrderTypeEnum from "common/enums/OrderTypeEnum";
import EncounterSubmitterType from "common/types/EncounterSubmitterType";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import {
  ProviderMetadataDropdownOption,
  ProviderMetadataType
} from "common/types/ProviderMetadataType";
import OrderSKUEnum from "common/enums/OrderSKUEnum";
import { AppDispatch } from "common/redux";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import {
  isFalsy,
  maskPhoneNumber,
  prettyStatusString,
  unmaskPhoneNumber
} from "common/helpers/helpers";
import MemberTypeInner from "common/types/common/MemberTypeInner";
import { Call } from "@mui/icons-material";
import OrderItemType from "common/types/OrderItemType";

const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const ONEDAY = 86400000;

const scrollbarWidth = () => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement("div");
  scrollDiv.setAttribute(
    "style",
    "width: 100px; height: 100px; overflow: auto; position:absolute; top:-9999px;"
  );
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

/**
 * Converts an array to a matrix
 * @param  {Object} arr array to convert
 * @param  {Number} width number of columns
 * @return {Array}  Returns a 2D array
 */
const toMatrix = (arr: any[], width: number) =>
  arr.reduce(
    (rows, key, index) =>
      (index % width == 0
        ? rows.push([key])
        : rows[rows.length - 1].push(key)) && rows,
    []
  );

/**
 * Sanitizes and lowercases string
 * @param  {String} str Input
 * @return {String}  the string lowercased and with whitespaces removed
 */

function sanitizeLowercaseString(str: string) {
  return str
    ?.replace(/[^a-zA-Z0-9'-]/g, "")
    ?.toLowerCase()
    ?.trim();
}

/**
 * Helper function to handle when a search term has more than one word - searches by first and last name in either order
 * @param  {String} searchTerm Search term
 * @param  {Array} userList Array of users to search through
 * @param  {String} usernameAccessor Accessor for username
 * @param  {String} fullnameAccessor Accessor for fullname
 * @param  {Number} fuzzysortThreshold A higher threshold is better. If the result is below the threshold, it is not returned. Default is -10000.0
 * @return {Array} a filtered userlist by search term
 */

function firstLastUsernameSearch(
  searchTerm: string,
  userList: any[],
  usernameAccessor: string,
  fullnameAccessor: string,
  fuzzysortThreshold = -10000
) {
  let multipleWords = false;
  let searchTermArray: string[];
  if (searchTerm.includes(" ")) {
    multipleWords = true;
    const removedExtraSpaces = searchTerm?.replace(/\s+/g, " ").trim();
    searchTermArray = removedExtraSpaces?.split(" ");
  }

  const filteredList = userList?.filter((user) => {
    let username = get(user, usernameAccessor);
    let fullname = get(user, fullnameAccessor);
    let target = sanitizeLowercaseString(username);
    if (fullname) {
      target = sanitizeLowercaseString(fullname);
    }
    if (multipleWords) {
      let isGoodMatch = true;
      searchTermArray.forEach((word) => {
        const result = fuzzysort.single(word, target);
        // if any of the search terms are not a good match, then the whole thing is not a good match
        if (!result || result?.score <= fuzzysortThreshold) {
          isGoodMatch = false;
        }
      });
      return isGoodMatch;
    } else {
      const result = fuzzysort.single(
        sanitizeLowercaseString(searchTerm),
        target
      );
      return (
        target?.includes(sanitizeLowercaseString(searchTerm)) ||
        (result?.score && result.score > fuzzysortThreshold)
      );
    }
  });
  return filteredList;
}

/**
 * replaces variables of the format {{key}} in an input string with the value from an object containing key value pairs
 * @param  {String} inputStr String with key(s) that needs to be replaced
 * Example: "{{user_id}}"
 * @param  {String} obj Object with key value pairs
 * Example: "{ user_id: 12341242312 }"
 * @return {String}  Returns a String with the variables replaced
 */

function replaceVariables(inputStr: string, obj: any) {
  const curlyBracesRegex = /[^0-9a-zA-Z._]/g;
  const keyMatchRegex = /\{\{([^}]+)\}\}/g;
  let finalStr = inputStr;

  const matches = inputStr?.match(keyMatchRegex);
  if (matches && matches?.length > 0) {
    matches.forEach((match) => {
      const key = match?.replace(curlyBracesRegex, "");
      const value = obj?.[key];
      if (value) {
        // if key-value pair found in input obj, replace
        finalStr = finalStr.replaceAll(match, value);
      }
    });
  }
  return finalStr;
}

function getTeamTypeLabel(teamType: string) {
  switch (teamType) {
    case "PROVIDERS":
      return "Providers";
    case "NURSE_MANAGERS":
      return "Nurse Managers";
    default:
      return prettyStatusString(teamType);
  }
}

function isFeatureAllowed(role: RolesEnum, allowedRoles: RolesEnum[]) {
  return allowedRoles.includes(role);
}

/**
 * Takes an argument and functions to run later. This is used for executing promises in a Promise.all array
 * @param  {Function} func function to call later
 * @param  {Array} args arguments to invoke func with
 * @return {Array.<func: Function, args: Array>}  Returns an array with the function and arguments
 */

function runLater(func: any, ...args: any) {
  return [func, args];
}

/**
 * Gets UTC Date as a string
 * @param  {Date} date Javascript Date object
 * @return {String}  Returns a String representing the date in MM/DD/YY format
 */
function getFormattedDate(date: DateTime) {
  return date.toLocaleString({
    month: "2-digit",
    day: "2-digit",
    year: "2-digit"
  });
}

/**
 * Gets UTC Date Time as a string
 * @param  {DateTime | Date} date Luxon Date object or Javascript Date object
 * @return {String}  Returns a String representing the date time in MM/DD/YYYY  hh:mm:ss format
 */
function getFormattedDateTime(
  date: DateTime,
  timezone: string,
  showTimezone: boolean = false
) {
  if (!date) return "";

  // if it is already a luxon date
  if (date?.isValid) {
    return date
      .setZone(timezone)
      .toFormat(`L/d/yyyy, h:mm:ss a ${showTimezone && "ZZZZ"}`);
  } else {
    // if it is a javascript date cast it to a luxon date so we can set timezone, javascript dates don't accept timezones
    // @ts-ignore
    const newdate = DateTime.fromJSDate(date);
    if (newdate?.isValid) {
      return newdate
        .setZone(timezone)
        .toFormat(`L/d/yyyy, h:mm:ss a${showTimezone ? " ZZZZ" : ""}`);
    } else {
      return "";
    }
  }
}

const convertObjToJsx = (obj: any) => {
  const { type, props } = obj || {};
  let { children: originalChildren } = props || {};
  let children = originalChildren?.map((child: any) => {
    if (typeof child === "string") {
      // If the child is a string, just return it
      return child;
    } else {
      // Otherwise, create a React element from the child
      // and spread the children of the child element into the React.createElement() call
      return convertObjToJsx(child);
    }
  });
  return createElement(type, props, children);
};

/**
 * Gets SKU items needed for create order request
 * @param {Array} selectedItemsArray Array of selected items from the form
 * @param {String} orderType Either devices or refills
 * @param {boolean} hasDeviceOrderDelivered has had a device order delivered before
 */

interface GetSkuItemsProps {
  selectedItemsArray: string[];
  orderType: OrderTypeEnum;
  stripsOnly?: boolean;
  controlSolution?: boolean;
  hasDeviceOrderDelivered?: boolean;
}

const getSkuItems = ({
  selectedItemsArray,
  orderType,
  stripsOnly = false,
  controlSolution = false,
  hasDeviceOrderDelivered = false
}: GetSkuItemsProps) => {
  const items = [];

  let orderHasWeightScale = false;

  selectedItemsArray.forEach((item) => {
    let skuInfo = skuMappings?.[orderType]?.[item];

    if (stripsOnly) {
      // filter out lancets
      skuInfo = skuInfo.filter(
        (item) => item.sku !== OrderSKUEnum.GLUCOSE_LANCETS
      );
    }

    if (skuInfo && skuInfo?.length > 0) {
      items.push(...skuInfo);
    }
    if (item === "weightScale") {
      orderHasWeightScale = true;
    }
  });

  if (controlSolution) {
    const controlSolutionInfo: OrderItemType = {
      sku: "TC0003",
      quantity: 1,
      sku_type: "REFILL"
    };

    items.push(controlSolutionInfo);
  }

  const devicesAddOns = skuMappings?.devices_add_ons;

  // block add ons if the only item is a weight scale
  const blockAddOns =
    (orderHasWeightScale && selectedItemsArray.length === 1) ||
    // or if the patient has already had a device order delivered
    hasDeviceOrderDelivered;
  // add add-ons to devices order if it's the first device order delivered
  if (
    orderType === OrderTypeEnum.DEVICE &&
    !blockAddOns &&
    devicesAddOns &&
    devicesAddOns?.length > 0
  ) {
    items.push(...devicesAddOns);
  }
  return items;
};

function getDeviceInfoBySku(sku: string) {
  return skuMappings.skus[sku];
}

// We need to convert the encounters object received from /encounters to a string
function getLastEncounter(encounters: EncounterSubmitterType[]) {
  if (encounters === undefined || encounters.length === 0) return undefined;

  let encounter: EncounterType = encounters[0].encounter;
  encounters.forEach((item) => {
    if (
      DateTime.fromISO(item.encounter.starts_on) >
      DateTime.fromISO(encounter.starts_on)
    ) {
      encounter = item?.encounter;
    }
  });
  // convert to utc to get 2022-11-16T15:47:37.075Z format
  const dt = DateTime.fromISO(encounter.starts_on, { zone: "utc" });
  const lastEncounterStr = dt.toISO();
  return lastEncounterStr;
}

function parseJSON(str: string) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

function getDepartmentsAndProviders(
  patientState: string,
  departments: any[],
  stateProviderMappings: any
) {
  const options: any[] = [];

  const providerNames = stateProviderMappings[patientState]
    ? stateProviderMappings[patientState]
    : [];

  const providerOptions = providerNames.map((providerName: string) => {
    const result = departments.find(
      (department) => department.Name === providerName
    );
    if (result) {
      return result;
    }
    return null;
  });

  providerOptions.forEach((item: any) => {
    const departmentID = item?.["Department ID"];
    const { Name, ProviderID, AthenaDepartmentName } = item;
    options.push({
      value: Name,
      label: `${AthenaDepartmentName}`,
      requestBody: {
        provider_id: ProviderID,
        department_id: departmentID
      }
    });
  });

  return { options };
}

function getProviderOptionsFromProviderMetadata(
  metadata: ProviderMetadataType[]
) {
  const options: ProviderMetadataDropdownOption[] = [];

  metadata?.forEach((item: ProviderMetadataType) => {
    const { provider, provider_metadata } = item;
    const { athena_dpt_id, provider_id, athena_provider_id, athena_dpt_name } =
      provider_metadata;
    if (provider.status !== MemberStatusEnum.INACTIVE) {
      options.push({
        value: provider_id,
        label: `${athena_dpt_name}`,
        requestBody: {
          athena_provider_id: athena_provider_id,
          provider_id: provider_id,
          department_id: athena_dpt_id
        }
      });
    }
  });

  return { options };
}

const getTrackingUrl = (trackingNumber: string, carrierCode: string) => {
  const trackingUrl = carrierCode?.toUpperCase()?.includes("UPS")
    ? "https://www.ups.com/track?loc=en_US&tracknum="
    : // default to USPS
      "https://tools.usps.com/go/TrackConfirmAction.action?tLabels=";
  return trackingUrl + trackingNumber;
};

const callPatientModalSelector = (
  dispatch: AppDispatch,
  patient: Partial<MemberTypeInner>,
  onClose?: () => void
) => {
  const phone = patient?.phone;
  const mobile = patient?.mobile;
  // if phone and mobile numbers exist and they aren't the same, show a modal to ask which one to call
  if (phone && mobile && phone !== mobile) {
    Alert_show({
      dispatch,
      title: "Call Member",
      id: "callPatient",
      content: (
        <>
          Click a number below to dial <br /> <br />
          {/* We can't use styled components here, it will break in a build but not in local development environment
          See: https://copilotiq.sentry.io/issues/4509180514/?project=4504476246605824 */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              cursor: "pointer",
              width: "fit-content"
            }}
            onClick={() => {
              window.open(`tel:+1${unmaskPhoneNumber(phone)}`);
              Alert_close({ dispatch, id: "callPatient" });
              onClose && onClose();
            }}
          >
            <div
              style={{
                width: "100%",
                color: "#00B7C4",
                textDecoration: "none",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px"
              }}
            >
              Home: {maskPhoneNumber(phone)}&nbsp;
            </div>
            <div style={{ fontSize: "16px" }}>
              <Call sx={{ mt: "2px" }} color={"primary"} fontSize="inherit" />
            </div>
          </div>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              cursor: "pointer",
              width: "fit-content"
            }}
            onClick={() => {
              window.open(`tel:+1${unmaskPhoneNumber(mobile)}`);
              Alert_close({ dispatch, id: "callPatient" });
              onClose && onClose();
            }}
          >
            <div
              style={{
                width: "100%",
                color: "#00B7C4",
                textDecoration: "none",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px"
              }}
            >
              Mobile: {maskPhoneNumber(mobile)}&nbsp;
            </div>
            <div style={{ fontSize: "16px" }}>
              <Call sx={{ mt: "2px" }} color={"primary"} fontSize="inherit" />
            </div>
          </div>
        </>
      ),
      buttons: [],
      size: "small"
    });
    // else if only one number exists, call that number
  } else if (mobile === phone || (phone && !mobile) || (mobile && !phone)) {
    // default to mobile number first
    if (mobile) {
      window.open(`tel:+1${unmaskPhoneNumber(mobile)}`, "_top", "popup:†rue");
    } else if (phone) {
      window.open(`tel:+1${unmaskPhoneNumber(phone)}`, "_top", "popup:†rue");
    }
  }
};

const isPOBoxAddress = (address: AddressesType) => {
  return (
    isPOBox(address?.pabPatientAddress?.street1) ||
    isPOBox(address?.pabPatientAddress?.street2)
  );
};

const isPOBox = (text: string) => {
  if (isFalsy(text)) return false;

  const replacedText = text
    .replace(/\s{2,}/g, " ")
    .trim()
    .toLowerCase();

  return (
    replacedText.includes("po box") ||
    replacedText.includes("p.o box") ||
    replacedText.includes("p.o. box") ||
    replacedText.includes("po. box")
  );
};

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
function getTextWidth(text, font) {
  // re-use canvas object for better performance
  const canvas =
    // @ts-ignore
    getTextWidth.canvas ||
    // @ts-ignore
    (getTextWidth.canvas = document.createElement("canvas"));
  const context = canvas.getContext("2d");
  context.font = font || getComputedStyle(document.body).font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export {
  scrollbarWidth,
  toMatrix,
  phoneNumberMask,
  sanitizeLowercaseString,
  firstLastUsernameSearch,
  replaceVariables,
  getTeamTypeLabel,
  isFeatureAllowed,
  runLater,
  getFormattedDate,
  getFormattedDateTime,
  convertObjToJsx,
  getSkuItems,
  getDeviceInfoBySku,
  getLastEncounter,
  parseJSON,
  getDepartmentsAndProviders,
  getProviderOptionsFromProviderMetadata,
  getTrackingUrl,
  callPatientModalSelector,
  isPOBoxAddress,
  getTextWidth
};
