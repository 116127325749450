enum OrderSKUEnum {
  GLUCOSE_METER = "TM0009",
  GLUCOSE_TEST_STRIPS = "TT0003",
  GLUCOSE_LANCETS = "TL001",
  BLOOD_PRESSURE_CUFF = "70710034",
  GLUCOSE_CONTROL_SOLUTION = "TC0003",
  GUIDE = "GSG-v1"
  //MUG = "MUG-1"
}

export default OrderSKUEnum;
