import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";

import DeviceTypeEnum from "../enums/DeviceTypeEnum";
import { useGetDevicesQuery } from "../services/DevicesService";
import { useGetReadingsByMemberQuery } from "../services/ReadingsService";
import { useGetReadingsTrendsByMemberQuery } from "../services/ReadingsService";
import DeviceReadingType from "../types/DeviceReadingType";
import DeviceStatusEnum from "../enums/DeviceStatusEnum";
import MemberType from "../types/MemberType";
import { AxiosService_MOCK_QUERY } from "../services/AxiosService";
import { isReadingQualifierOther } from "../enums/ReadingQualifierReasonEnum";
import DeviceTrendParam from "../enums/DeviceTrendParamEnum";

export interface DeviceReadingSummaryType {
  data: DeviceReadingType[];
  max: DeviceReadingType;
  min: DeviceReadingType;
  average: number | string;
}

export const defaultFetchAPIs = [
  DeviceTypeEnum.BLOOD_PRESSURE,
  DeviceTypeEnum.GLUCOSE_CATEGORY,
  DeviceTypeEnum.OXIMETER,
  DeviceTypeEnum.WEIGHT_SCALE
];
export type ReadingAccessorType =
  | "systolic"
  | "diastolic"
  | "pulse"
  | "glucose"
  | "spo2"
  | "weight";

function getStartEndDate({ patientTimezone, days, dateFrom, dateTo }) {
  const timezone = patientTimezone ? patientTimezone : "local";
  let endDate = dateTo ? dateTo : DateTime.now().setZone(timezone).endOf("day");

  let startDate = dateFrom;

  if (days !== undefined) {
    startDate = DateTime.now().minus({ days }).setZone(timezone).startOf("day");
  }

  return { startDate, endDate };
}

interface IProps {
  patient: MemberType | undefined;
  days?: number;
  dateFrom?: DateTime;
  dateTo?: DateTime;
  sort?: "DESC" | "ASC";
  fetchOnlyLastReadings?: boolean;
  fetchAPIs?: DeviceTypeEnum[];
  ignoreGlucoseOtherReadings?: boolean;
  getTrends?: boolean;
}

const useGetMemberDataSummary = ({
  patient,
  days,
  fetchOnlyLastReadings = false,
  dateFrom,
  dateTo,
  sort = "DESC",
  fetchAPIs = defaultFetchAPIs,
  ignoreGlucoseOtherReadings = false,
  getTrends = false
}: IProps) => {
  const { startDate, endDate } = getStartEndDate({
    patientTimezone: patient?.patient?.timezone,
    days,
    dateFrom,
    dateTo
  });

  const skip =
    startDate === undefined ||
    endDate === undefined ||
    patient === undefined ||
    fetchOnlyLastReadings;

  const bloodPressureSkip =
    skip || !fetchAPIs.includes(DeviceTypeEnum.BLOOD_PRESSURE);

  const glucoseSkip =
    skip || !fetchAPIs.includes(DeviceTypeEnum.GLUCOSE_CATEGORY);
  const oximeterSkip =
    skip ||
    !fetchAPIs.includes(DeviceTypeEnum.OXIMETER) ||
    patient?.patient.migrated === false;

  const weightScaleSkip =
    skip || !fetchAPIs.includes(DeviceTypeEnum.WEIGHT_SCALE);

  const [bpMax, setBPMax] = useState<DeviceReadingType>();
  const [bpMin, setBPMin] = useState<DeviceReadingType>();
  const [bpAverage, setBPAverage] = useState<string>();

  const [pulseMax, setPulseMax] = useState<DeviceReadingType>();
  const [pulseMin, setPulseMin] = useState<DeviceReadingType>();
  const [pulseAverage, setPulseAverage] = useState<string>();

  const [gMax, setGMax] = useState<DeviceReadingType>();
  const [gMin, setGMin] = useState<DeviceReadingType>();
  const [gAverage, setGAverage] = useState<string>();

  const [oMax, setOMax] = useState<DeviceReadingType>();
  const [oMin, setOMin] = useState<DeviceReadingType>();
  const [oAverage, setOAverage] = useState<string>();

  const [wMax, setWMax] = useState<DeviceReadingType>();
  const [wMin, setWMin] = useState<DeviceReadingType>();
  const [wAverage, setWAverage] = useState<string>();

  const {
    data: bloodPressureData,
    isFetching: bloodFetching,
    isLoading: bloodLoading,
    error: bloodError,
    isSuccess: bloodIsSuccess,
    isUninitialized: bloodIsUninitialized,
    fulfilledTimeStamp: bloodPressureFulfilledTimeStamp,
    refetch: bloodRefetch
  } = useGetReadingsByMemberQuery(
    {
      memberId: patient ? patient.patient.patient_id : "",
      paramsObject: {
        sort: "measure_timestamp," + sort,
        device_type: DeviceTypeEnum.BLOOD_PRESSURE,
        startdate: Math.round(startDate?.toSeconds()),
        enddate: Math.round(endDate?.toSeconds())
      }
    },
    {
      skip: bloodPressureSkip
    }
  );

  let {
    data: glucoseData,
    isFetching: glucoseFetching,
    isLoading: glucoseLoading,
    error: glucoseError,
    isSuccess: glucoseIsSuccess,
    // @ts-ignore not sure why it is throwing an error. Seems OK.
    isUninitialized: glucoseIsUnitialized,
    // @ts-ignore not sure why it is throwing an error. Seems OK.
    fulfilledTimeStamp: glucoseFulfilledTimeStamp,
    refetch: glucoseRefetch
  } = useGetReadingsByMemberQuery(
    {
      memberId: patient ? patient.patient.patient_id : "",
      paramsObject: {
        sort: "measure_timestamp," + sort,
        device_type: DeviceTypeEnum.GLUCOSE_CATEGORY,
        startdate: Math.round(startDate?.toSeconds()),
        enddate: Math.round(endDate?.toSeconds())
      }
    },
    {
      skip: glucoseSkip,
      selectFromResult: (result) =>
        glucoseSkip ? AxiosService_MOCK_QUERY : result
    }
  );

  // for patient chart export, we want to ignore glucose readings in the "Other" category
  // (readings that are not tagged as before meal or after meal)
  if (ignoreGlucoseOtherReadings) {
    glucoseData = glucoseData?.filter(
      (item) => !isReadingQualifierOther(item.reading_qualifier)
    );
  }

  const {
    data: trendData,
    isFetching: trendFetching,
    isLoading: trendLoading,
    error: trendError,
    isSuccess: trendIsSuccess,
    isUninitialized: trendIsUninitialized,
    refetch: trendRefetch
  } = useGetReadingsTrendsByMemberQuery(
    {
      memberId: patient ? patient.patient.patient_id : "",
      paramsObject: {
        device_type: [
          DeviceTrendParam.BLOOD_PRESSURE,
          DeviceTrendParam.GLUCOSE
        ],
        enddate: Math.round(endDate?.toSeconds()),
        trend_days: [30, 90, 365]
      }
    },
    {
      skip: !getTrends || !patient
    }
  );

  const {
    data: oximeterData,
    isFetching: oximeterFetching,
    isLoading: oximeterLoading,
    error: oximeterError,
    isSuccess: oximeterIsSuccess,
    // @ts-ignore not sure why it is throwing an error. Seems OK.
    isUninitialized: oximeterIsUninitialized,
    // @ts-ignore not sure why it is throwing an error. Seems OK.
    fulfilledTimeStamp: oximeterFulfilledTimeStamp,
    refetch: oximeterRefetch
  } = useGetReadingsByMemberQuery(
    {
      memberId: patient ? patient.patient.patient_id : "",
      paramsObject: {
        sort: "measure_timestamp," + sort,
        device_type: DeviceTypeEnum.OXIMETER,
        startdate: Math.round(startDate?.toSeconds()),
        enddate: Math.round(endDate?.toSeconds())
      }
    },
    {
      skip: oximeterSkip,
      selectFromResult: (result) =>
        oximeterSkip ? AxiosService_MOCK_QUERY : result
    }
  );

  const {
    data: weightScaleData,
    isFetching: weightScaleFetching,
    isLoading: weightScaleLoading,
    error: weightScaleError,
    isSuccess: weightScaleIsSuccess,
    // @ts-ignore not sure why it is throwing an error. Seems OK.
    isUninitialized: weightScaleIsUninitialized,
    // @ts-ignore not sure why it is throwing an error. Seems OK.
    fulfilledTimeStamp: weightScaleFulfilledTimeStamp,
    refetch: weightScaleRefetch
  } = useGetReadingsByMemberQuery(
    {
      memberId: patient ? patient.patient.patient_id : "",
      paramsObject: {
        sort: "measure_timestamp," + sort,
        device_type: DeviceTypeEnum.WEIGHT_SCALE,
        startdate: Math.round(startDate?.toSeconds()),
        enddate: Math.round(endDate?.toSeconds())
      }
    },
    {
      skip: weightScaleSkip,
      selectFromResult: (result) =>
        weightScaleSkip ? AxiosService_MOCK_QUERY : result
    }
  );

  useEffect(() => {
    if (bloodPressureData) {
      const BPMaxMin = getMaxMinAverage(bloodPressureData, [
        "systolic",
        "diastolic"
      ]);
      const { maxData, minData, average } = BPMaxMin;

      const PulseMaxMin = getMaxMinAverage(bloodPressureData, ["pulse"]);
      const {
        maxData: pulseMaxData,
        minData: pulseMinData,
        average: pulseAverage
      } = PulseMaxMin;

      setBPMax(maxData?.[0]);
      setBPMin(minData?.[0]);
      setBPAverage(average);

      setPulseMax(pulseMaxData?.[0]);
      setPulseMin(pulseMinData?.[0]);
      setPulseAverage(pulseAverage);
    }
  }, [bloodPressureData]);

  useEffect(() => {
    if (glucoseData) {
      const GMaxMin = getMaxMinAverage(glucoseData, ["glucose"]);
      const { maxData, minData, average } = GMaxMin;

      setGMax(maxData);
      setGMin(minData);
      setGAverage(average);

      setGMax(maxData?.[0]);
      setGMin(minData?.[0]);
      setGAverage(average);
    }
  }, [glucoseData]);

  useEffect(() => {
    if (oximeterData) {
      const OMaxMin = getMaxMinAverage(oximeterData, ["spo2"]);
      const { maxData, minData, average } = OMaxMin;

      setOMax(maxData?.[0]);
      setOMin(minData?.[0]);
      setOAverage(average);
    }
  }, [oximeterData]);

  useEffect(() => {
    if (weightScaleData) {
      const WMaxMin = getMaxMinAverage(weightScaleData, ["weight"]);
      const { maxData, minData, average } = WMaxMin;

      setWMax(maxData?.[0]);
      setWMin(minData?.[0]);

      const weightAverage = average
        ? Number.parseFloat(average).toFixed(1)
        : "N/A";
      setWAverage(weightAverage);
    }
  }, [weightScaleData]);

  const { data: devices } = useGetDevicesQuery(
    { memberId: patient?.patient?.patient_id },
    { skip: patient === undefined }
  );

  const {
    data: lastBloodPressureReadingFromAPI,
    isSuccess: lastBloodIsSuccess,
    isFetching: lastBloodIsFetching,
    isUninitialized: lastBloodIsUninitialized,
    refetch: lastBloodRefetch
  } = useGetReadingsByMemberQuery(
    {
      memberId: patient?.patient?.patient_id,
      paramsObject: {
        sort: "measure_timestamp,DESC",
        limit: 1,
        device_type: DeviceTypeEnum.BLOOD_PRESSURE
      }
    },
    {
      skip:
        patient === undefined ||
        !fetchAPIs.includes(DeviceTypeEnum.BLOOD_PRESSURE) ||
        bloodLoading ||
        bloodPressureData?.length > 0
    }
  );

  const {
    data: lastGlucoseReadingFromAPI,
    isSuccess: lastGlucoseIsSuccess,
    isFetching: lastGlucoseIsFetching,
    isUninitialized: lastGlucoseIsUninitialized,
    refetch: lastGlucoseRefetch
  } = useGetReadingsByMemberQuery(
    {
      memberId: patient?.patient?.patient_id,
      paramsObject: {
        sort: "measure_timestamp,DESC",
        limit: 1,
        device_type: DeviceTypeEnum.GLUCOSE_CATEGORY
      }
    },
    {
      skip:
        patient === undefined ||
        !fetchAPIs.includes(DeviceTypeEnum.GLUCOSE_CATEGORY) ||
        glucoseLoading ||
        glucoseData?.length > 0
    }
  );
  const {
    data: lastOximeterReadingFromAPI,
    isSuccess: lastOximeterIsSuccess,
    isFetching: lastOximeterIsFetching,
    isUninitialized: lastOximeterIsUninitialized,
    refetch: lastOximeterRefetch
  } = useGetReadingsByMemberQuery(
    {
      memberId: patient?.patient?.patient_id,
      paramsObject: {
        sort: "measure_timestamp,DESC",
        limit: 1,
        device_type: DeviceTypeEnum.OXIMETER
      }
    },
    {
      skip:
        patient === undefined ||
        !fetchAPIs.includes(DeviceTypeEnum.OXIMETER) ||
        patient?.patient.migrated === false ||
        oximeterLoading ||
        glucoseData?.length > 0
    }
  );
  const {
    data: lastWeightScaleReadingFromAPI,
    isSuccess: lastWeightScaleIsSuccess,
    isFetching: lastWeightScaleIsFetching,
    isUninitialized: lastWeightScaleIsUninitialized,
    refetch: lastWeightScaleRefetch
  } = useGetReadingsByMemberQuery(
    {
      memberId: patient?.patient?.patient_id,
      paramsObject: {
        sort: "measure_timestamp,DESC",
        limit: 1,
        device_type: DeviceTypeEnum.WEIGHT_SCALE
      }
    },
    {
      skip:
        patient === undefined ||
        !fetchAPIs.includes(DeviceTypeEnum.WEIGHT_SCALE) ||
        patient?.patient.migrated === false ||
        weightScaleLoading ||
        weightScaleData?.length > 0
    }
  );

  const bloodIndex = sort === "DESC" ? 0 : bloodPressureData?.length - 1;
  const lastBloodPressureReading =
    lastBloodPressureReadingFromAPI ||
    (bloodPressureData?.length > 0 && [bloodPressureData[bloodIndex]]);

  const glucoseIndex = sort === "DESC" ? 0 : glucoseData?.length - 1;
  const lastGlucoseReading =
    lastGlucoseReadingFromAPI ||
    (glucoseData?.length > 0 && [glucoseData[glucoseIndex]]);

  const oximeterIndex = sort === "DESC" ? 0 : oximeterData?.length - 1;
  const lastOximeterReading =
    lastOximeterReadingFromAPI ||
    (oximeterData?.length > 0 && [oximeterData[oximeterIndex]]);

  const weightIndex = sort === "DESC" ? 0 : weightScaleData?.length - 1;
  const lastWeightScaleReading =
    lastWeightScaleReadingFromAPI ||
    (weightScaleData?.length > 0 && [weightScaleData[weightIndex]]);

  const {
    hasBloodDevice,
    hasGlucoseDevice,
    hasWeightScaleDevice,
    hasOximeterDevice
  } = useMemo(() => {
    if (devices === undefined) return {};

    const filteredDevices = devices.filter(
      (device) => device.status === DeviceStatusEnum.ACTIVE
    );

    const bloodDevice = filteredDevices.find(
      (device) => device.extra.type === DeviceTypeEnum.BLOOD_PRESSURE
    );

    const glucoseDevice = filteredDevices.find(
      (device) => device.extra.type === DeviceTypeEnum.GLUCOSE_CATEGORY
    );

    const oximeterDevice = filteredDevices.find(
      (device) => device.extra.type === DeviceTypeEnum.OXIMETER
    );

    const weightScaleDevice = filteredDevices.find(
      (device) => device.extra.type === DeviceTypeEnum.WEIGHT_SCALE
    );

    return {
      hasBloodDevice:
        bloodPressureSkip === false &&
        (bloodDevice !== undefined ||
          (lastBloodPressureReading && lastBloodPressureReading?.length > 0)),
      hasGlucoseDevice:
        glucoseSkip === false &&
        (glucoseDevice !== undefined ||
          (lastGlucoseReading && lastGlucoseReading?.length > 0)),
      hasOximeterDevice:
        oximeterSkip === false &&
        (oximeterDevice !== undefined ||
          (lastOximeterReading && lastOximeterReading?.length > 0)),
      hasWeightScaleDevice:
        weightScaleSkip &&
        (weightScaleDevice !== undefined ||
          (lastWeightScaleReading && lastWeightScaleReading?.length > 0))
    };
  }, [
    devices,
    lastBloodPressureReading,
    lastGlucoseReading,
    lastOximeterReading,
    lastWeightScaleReading,
    bloodPressureSkip,
    glucoseSkip,
    oximeterSkip,
    weightScaleSkip
  ]);

  const isLoading =
    bloodLoading ||
    glucoseLoading ||
    oximeterLoading ||
    weightScaleLoading ||
    trendLoading;

  function refetch() {
    if (!bloodPressureSkip && !bloodIsUninitialized) {
      bloodRefetch();
    }

    if (!glucoseSkip && !glucoseIsUnitialized) {
      glucoseRefetch();
    }

    if (getTrends && !trendIsUninitialized) {
      trendRefetch();
    }

    if (!oximeterSkip && !oximeterIsUninitialized) {
      oximeterRefetch();
    }

    if (!weightScaleSkip && !weightScaleIsUninitialized) {
      weightScaleRefetch();
    }

    if (
      fetchAPIs.includes(DeviceTypeEnum.BLOOD_PRESSURE) &&
      !lastBloodIsUninitialized
    ) {
      lastBloodRefetch();
    }

    if (
      fetchAPIs.includes(DeviceTypeEnum.GLUCOSE_CATEGORY) &&
      !lastGlucoseIsUninitialized
    ) {
      lastGlucoseRefetch();
    }

    if (
      fetchAPIs.includes(DeviceTypeEnum.OXIMETER) &&
      !lastOximeterIsUninitialized
    ) {
      lastOximeterRefetch();
    }

    if (
      fetchAPIs.includes(DeviceTypeEnum.WEIGHT_SCALE) &&
      !lastWeightScaleIsUninitialized
    ) {
      lastWeightScaleRefetch();
    }
  }

  return {
    refetch,
    isLoading,
    isFetching:
      bloodFetching ||
      glucoseFetching ||
      oximeterFetching ||
      weightScaleFetching ||
      trendFetching,
    bloodPressure: {
      data: bloodPressureData,
      lastReading: lastBloodPressureReading?.[0],
      max: bpMax,
      min: bpMin,
      average: bpAverage,
      maxPulse: pulseMax,
      minPulse: pulseMin,
      averagePulse: pulseAverage,
      loading: bloodLoading,
      fetching: bloodFetching || lastBloodIsFetching,
      isSuccess: fetchOnlyLastReadings ? lastBloodIsSuccess : bloodIsSuccess,
      error: bloodError,
      hasDevice: hasBloodDevice,
      fulfilledTimeStamp: bloodPressureFulfilledTimeStamp
    },
    glucose: {
      data: glucoseData,
      lastReading: lastGlucoseReading?.[0],
      max: gMax,
      min: gMin,
      average: gAverage,
      loading: glucoseLoading,
      fetching: glucoseFetching || lastGlucoseIsFetching,
      isSuccess: fetchOnlyLastReadings
        ? lastGlucoseIsSuccess
        : glucoseIsSuccess,
      error: glucoseError,
      hasDevice: hasGlucoseDevice,
      fulfilledTimeStamp: glucoseFulfilledTimeStamp
    },
    oximeter: {
      data: oximeterData,
      lastReading: lastOximeterReading?.[0],
      max: oMax,
      min: oMin,
      average: oAverage,
      loading: oximeterLoading,
      fetching: oximeterFetching || lastOximeterIsFetching,
      isSuccess: fetchOnlyLastReadings
        ? lastOximeterIsSuccess
        : oximeterIsSuccess,
      error: oximeterError,
      hasDevice: hasOximeterDevice,
      fulfilledTimeStamp: oximeterFulfilledTimeStamp
    },
    weightScale: {
      data: weightScaleData,
      lastReading: lastWeightScaleReading?.[0],
      max: wMax,
      min: wMin,
      average: wAverage,
      loading: weightScaleLoading,
      fetching: weightScaleFetching || lastWeightScaleIsFetching,
      isSuccess: fetchOnlyLastReadings
        ? lastWeightScaleIsSuccess
        : weightScaleIsSuccess,
      error: weightScaleError,
      hasDevice: hasWeightScaleDevice,
      fulfilledTimeStamp: weightScaleFulfilledTimeStamp
    },
    trends: {
      data: trendData,
      loading: trendLoading,
      isSuccess: trendIsSuccess,
      error: trendError
    },
    isError:
      bloodError ||
      glucoseError ||
      oximeterError ||
      weightScaleError ||
      trendError
  };
};

const getMaxMinAverage = (
  data: DeviceReadingType[],
  accessors: ReadingAccessorType[]
) => {
  let maxData = undefined;
  let minData = undefined;

  if (!data || data?.length === 0) {
    return { minData, maxData, average: undefined };
  }
  maxData = Array(accessors.length).fill(undefined);
  minData = Array(accessors.length).fill(undefined);
  let sum = Array(accessors.length).fill(0);

  [...data].forEach((item) => {
    accessors.forEach((accessor, index) => {
      // type coercion to number - tbd return a number on backend for weight
      const value =
        typeof item[accessor] === "string"
          ? // @ts-ignore
            Number.parseFloat(item[accessor])
          : item[accessor];

      sum[index] += value;

      if (maxData[index] === undefined || value > maxData[index]?.[accessor])
        maxData[index] = item;
      if (minData[index] === undefined || value < minData[index]?.[accessor])
        minData[index] = item;
    });
  });

  const average = sum
    .map((value) => {
      return data.length > 0 ? Math.round(value / data.length) : undefined;
    })
    // combine systolic and diastolic into one value
    .join("/");

  return { minData, maxData, average };
};

export default useGetMemberDataSummary;
