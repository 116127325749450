import { useState, ReactNode, useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { ComponentHeader, TurqoiseButton, WhiteButton } from "../../styling";

import { SuccessText } from "../../styling/StyleComponents";
import AddTeamMemberTable from "./AddTeamMemberTable";

import {
  useAddMemberMutation,
  useDeleteMemberMutation
} from "common/services/TeamsService";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import ReportTeamType from "common/types/ReportTeamType";
import { getNameOrUsername } from "common/helpers/helpers";
import UserType from "common/types/UserType";
import Table from "../../components/Table/Table";
import { TableColumn } from "../../components/Table/TableTypes";
import ErrorComponent from "../../components/ErrorComponent";
import {
  Alert_close,
  Alert_loading,
  Alert_showDestructive
} from "common/helpers/AlertHelper";
import { RootState, useAppDispatch } from "common/redux";
import {
  BodyHeader,
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import UserTypeInner from "common/types/UserTypeInner";
import { useSelector } from "react-redux";
import {
  canEditTeam,
  TeamTypeEnum,
  TeamTypeDropdownLabels
} from "common/enums/TeamTypeEnum";

const TeamDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.5% 0;
`;

const TeamInfoContainer = styled.div``;

const AddMembersContainer = styled.div`
  padding: 2.5% 0;
`;

const TeamDetailsContainer = styled.div<{
  width?: string;
  padding?: string;
  showBorder: boolean;
  children: ReactNode | ReactNode[];
}>`
  // TBD styling refactor, make this a reusable component called "OuterContainer" or something similar
  width: ${(props) => (props.width ? props.width : "95%")};
  padding-top: ${(props) => (props.padding ? props.padding : "2.5%")};
  background: #ffffff;
  border: ${(props) =>
    props.showBorder ? `1px solid ${props.theme.color.defaultBorder}` : "none"};
  border-radius: ${(props) => (props.showBorder ? "4px" : "0")};
`;

const StyledErrorComponent = styled(ErrorComponent)`
  padding: 2.5% 0;
`;

const StyledTurqoiseButton = styled(TurqoiseButton)`
  width: max-content;
  padding: 10px;
  margin-top: 10px;
`;

const StyledSuccessText = styled(SuccessText)`
  // width: 95%;
  // padding: 0 2.5%;
  margin-bottom: 12px;
  transition: all 0.66s ease-out;
`;

interface IProps {
  params: {
    team: ReportTeamType;
    outerContainerStyles: {
      showBorder?: boolean;
      padding?: string;
      width?: string;
    };
  };
  tableColumns: TableColumn[];
  componentHeader?: string;
  externalLink?: string;
}

const TeamDetails = ({
  params,
  tableColumns,
  componentHeader,
  externalLink
}: IProps) => {
  const dispatch = useAppDispatch();

  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const { outerContainerStyles = {}, team } = params;
  const { showBorder = true, padding, width } = outerContainerStyles;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedNewTeamMember, setSelectedNewTeamMember] =
    useState<UserType>();

  const { data: leader } = useGetUserWithUsernameQuery(
    {
      username: team.leader_id
    },
    { skip: team === undefined }
  );

  const currentUserCanEditTeam = useMemo(() => {
    return (
      team.leader_id === user?.user_id ||
      canEditTeam(currentRole, TeamTypeEnum[team?.type])
    );
  }, [team?.leader_id, team?.type, user?.user_id, currentRole]);

  const [
    addTeamMemberMutation,
    {
      isSuccess: isSuccessAddMember,
      error: errorAddMember,
      isLoading: isLoadingAddMember,
      reset: resetAddMember
    }
  ] = useAddMemberMutation();

  useEffect(() => {
    if (isSuccessAddMember) {
      handleModalClose();
    }
  }, [isSuccessAddMember]);

  const [deleteTeamMemberMutation, { error: errorDeleteMember }] =
    useDeleteMemberMutation();

  function handleModalClose() {
    setModalOpen(false);
    resetAddMember();
  }

  function handleModalOpen() {
    setModalOpen(true);
  }

  function handleUserSelected(selectedUsersArray: UserType[]) {
    if (selectedUsersArray.length && selectedUsersArray.length > 0) {
      // we should only be getting one selected user at a time when we pass "enableMultiRowSelection" as false
      setSelectedNewTeamMember(selectedUsersArray[0]);
    } else {
      setSelectedNewTeamMember(undefined);
    }
  }
  async function handleAddTeamMember() {
    if (selectedNewTeamMember?.team !== undefined) {
      await deleteTeamMemberMutation({
        team_id: selectedNewTeamMember?.team.team_id,
        member_id: selectedNewTeamMember?.user.user_id
      });
    }

    await addTeamMemberMutation({
      team_id: team.team_id,
      member_id: selectedNewTeamMember?.user.user_id
    });
  }

  async function handleDeleteTeamMember(member: UserTypeInner) {
    const id = `delete${member?.user_id}`;
    Alert_showDestructive(
      dispatch,
      id,
      "Delete confirmation",
      `Are you sure you wish to unassign ${getNameOrUsername(member)} from ${
        team.name
      }?`,
      async () => {
        Alert_loading({ dispatch, id });
        await deleteTeamMemberMutation({
          team_id: team.team_id,
          member_id: member?.user_id
        });

        Alert_close({ dispatch, id });
      }
    );
  }

  return (
    <>
      <TeamDetailsContainer
        showBorder={showBorder}
        width={width}
        padding={padding}
      >
        {componentHeader && (
          <TeamDetailsHeader>
            <ComponentHeader>{componentHeader}</ComponentHeader>
          </TeamDetailsHeader>
        )}
        <TeamInfoContainer>
          <div>Team Name: {team.name}</div>
          <div>Team Type: {TeamTypeDropdownLabels?.[team?.type]?.label}</div>
          {leader && <div>Leader Name: {getNameOrUsername(leader?.user)}</div>}
        </TeamInfoContainer>
        {currentUserCanEditTeam && (
          <AddMembersContainer>
            <StyledTurqoiseButton onClick={handleModalOpen}>
              Add Members
            </StyledTurqoiseButton>
          </AddMembersContainer>
        )}
        {isSuccessAddMember && (
          <StyledSuccessText>
            Successfully added nurse to team.
          </StyledSuccessText>
        )}
        <StyledErrorComponent
          header="Error deleting team member:"
          error={errorDeleteMember}
        />

        {team?.team_members ? (
          <Table
            data={team.team_members}
            tableProps={{
              externalLink,
              handleDeleteTeamMember,
              currentUserCanEditTeam
            }}
            tableColumns={tableColumns}
          />
        ) : (
          <div>No team members found.</div>
        )}
        <StyledModal
          isOpen={modalOpen}
          contentLabel="Team Leader Modal"
          modalHeight="90vh"
          modalWidth={"1025px"}
          onRequestClose={handleModalClose}
        >
          <ModalHeader onRequestClose={handleModalClose}>
            Add Team Member
          </ModalHeader>
          <ModalBody>
            <BodyHeader>
              {errorAddMember && <ErrorComponent error={errorAddMember} />}

              <AddTeamMemberTable
                team={team}
                onUserSelected={handleUserSelected}
                enableMultiRowSelection={false}
              />
            </BodyHeader>
          </ModalBody>
          <ModalFooter>
            <ModalFooterButtons>
              <TurqoiseButton
                onClick={handleAddTeamMember}
                loading={isLoadingAddMember}
                disabled={!selectedNewTeamMember}
                type="submit"
              >
                {selectedNewTeamMember?.team === undefined ? "Add" : "Reassign"}
              </TurqoiseButton>
              <WhiteButton onClick={handleModalClose}>Cancel</WhiteButton>
            </ModalFooterButtons>
          </ModalFooter>
        </StyledModal>
      </TeamDetailsContainer>
    </>
  );
};

export default TeamDetails;
