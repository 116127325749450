import * as Sentry from "@sentry/react";
import { User } from "@sentry/react";
import type { CaptureContext, UserFeedback } from "@sentry/types";

let lastCapturedEvent = null;

export function SentryHelper_isLastEventIdUnexpected(eventId: string) {
  if (eventId === lastCapturedEvent) return false;
  else return true;
}

export function SentryHelper_captureException(
  error: any,
  context?: CaptureContext
) {
  // @ts-ignore
  const eventId = Sentry.captureException(error, context);

  lastCapturedEvent = eventId;
  return eventId;
}

export function SentryHelper_captureEvent(
  event: Sentry.Event,
  hint?: Sentry.EventHint
) {
  return Sentry.captureEvent(event, hint);
}

export function SentryHelper_setUser(user: User | null) {
  Sentry.setUser(user);
}

export function Sentry_wrap(component: any) {
  // Do nothing, look for solution on React Native Web

  return component;
  //return Sentry.wrap(component);
}

export function Sentry_addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  Sentry.addBreadcrumb(breadcrumb);
}

export function Sentry_captureUserFeedback(userFeedback: UserFeedback) {
  Sentry.captureUserFeedback(userFeedback);
}

export function SentryHelper_lastEventId() {
  return Sentry.lastEventId();
}
