import styled from "@emotion/styled";
import { CheckCircleIcon, LinkIcon } from "../assets/images/icons";
import { useEffect, useRef, useState } from "react";
import { CustomTooltip } from "./StyleComponents";
import replace from "lodash.replace";

const LinkIconContainer = styled.span`
  position: relative;
  width: 23px;
  height: 23px;
`;

const StyledLinkIcon = styled(LinkIcon, {
  shouldForwardProp: (prop) => prop !== "copiedToClipboard"
})<{ copiedToClipboard: boolean }>`
  position: absolute;
  margin-left: 4px;
  display: ${(props) => (props.copiedToClipboard ? "none" : "block")};
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const StyledCheckCircleIcon = styled(CheckCircleIcon, {
  shouldForwardProp: (prop) => prop !== "copiedToClipboard"
})<{ copiedToClipboard: boolean }>`
  position: absolute;
  margin-left: 4px;
  width: 20px;
  height: 20px;
  display: ${(props) => (props.copiedToClipboard ? "block" : "none")};
  transition: display 0.66s ease-in-out;
`;

/**
 * Copy text to clipboard
 * @param text the text to copy
 * @returns Boolean indicating success or failure
 */

export async function copyToClipboard(text: string) {
  let outcome = true;
  try {
    await navigator.clipboard.writeText(text);
  } catch {
    outcome = false;
    window.alert(
      "Failed to copy to clipboard, please try again with the current tab in focus."
    );
  }
  return Promise.resolve(outcome);
}

/**
 * Converts an array to a matrix
 * @param  {string} patientId PAB patientId
 * @param  {string} customLink relative path to custom link, i.e. `/members/memberId/:memberId`
 * @param  {string} pattern pattern to replace in the customLink, i.e. `:memberId`
 */

export default function CopyPatientLinkToClipboard({
  memberId,
  customLink
}: {
  memberId: string;
  customLink?: string;
}) {
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

  const timerRef = useRef(null);

  useEffect(() => {
    if (copiedToClipboard) {
      timerRef.current = setTimeout(() => {
        setCopiedToClipboard(false);
      }, 1000);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [copiedToClipboard]);

  const url = customLink
    ? `${window.location.origin}${replace(customLink, ":memberId", memberId)}`
    : window.location.href;

  return (
    <CustomTooltip placement="top" title={"Copy link to member"}>
      <LinkIconContainer>
        <StyledLinkIcon
          copiedToClipboard={copiedToClipboard}
          onClick={async (e) => {
            e.preventDefault();
            const copyToClipboardResult = await copyToClipboard(url);
            if (copyToClipboardResult) {
              setCopiedToClipboard(true);
            }
          }}
        />
        <StyledCheckCircleIcon copiedToClipboard={copiedToClipboard} />
      </LinkIconContainer>
    </CustomTooltip>
  );
}
