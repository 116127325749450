import styled from "@emotion/styled";
import { LightRedDangerIcon } from "../../assets/images/icons";

const PatientStateOutsideCoverageContainer = styled.div<{
  width?: string;
  containerMargin?: string;
}>`
  margin: ${(props) => props.containerMargin ?? "36px"};
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.color.defaultBorder};
  border-radius: 4px;
  align-items: center;
  // border is 1px on each side, so we need to subtract 2px from 100%
  width: ${(props) => (props.containerMargin ? "calc(100% - 2px)" : "auto")};
  justify-content: space-between;
  height: max-content;
`;

const FlexLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InnerFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PDBTextHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #434656;
`;

const PDBTextSubHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props?.theme?.color?.veryDarkBlue};
`;

const StyledDangerIcon = styled(LightRedDangerIcon)`
  margin: 0 8px 0 16px;
  width: 46px;
  height: 46px;
`;

interface IProps {
  type: string;
  value?: string;
  containerMargin?: string;
}

const RenderContent = ({ type, value }: { type: string; value: string }) => {
  switch (type) {
    case "STATE":
      return (
        <>
          {value ? (
            <PDBTextSubHeading>
              Member’s state is{" "}
              <span style={{ color: "red" }}>
                <b>{value}</b>
              </span>
              , which is outside CopilotIQ coverage. Please fix it if incorrect,
              else{" "}
              <span style={{ color: "red" }}>
                <b>forward</b>
              </span>
              &nbsp;to billing@copilotiq.com for next steps
            </PDBTextSubHeading>
          ) : (
            <PDBTextSubHeading>
              Member’s address is missing. Please add address details
            </PDBTextSubHeading>
          )}
        </>
      );
    case "ADDRESS":
      return (
        <PDBTextSubHeading>
          Member’s address is missing. Please add address details
        </PDBTextSubHeading>
      );
    case "DOB":
      return (
        <>
          {value ? (
            <PDBTextSubHeading>
              Member’s date of birth is{" "}
              <span style={{ color: "red" }}>
                <b>{value}</b>
              </span>
              , which is invalid. Please fix it if incorrect, else{" "}
              <span style={{ color: "red" }}>
                <b>forward</b>
              </span>
              &nbsp;to billing@copilotiq.com for next steps
            </PDBTextSubHeading>
          ) : (
            <PDBTextSubHeading>
              Member’s date of birth is missing. Please add address details
            </PDBTextSubHeading>
          )}
        </>
      );
    case "CONTACT_INFO":
      return (
        <>
          {value ? (
            <PDBTextSubHeading>
              Member’s contact information is{" "}
              <span style={{ color: "red" }}>
                <b>{value}</b>
              </span>
              , which is invalid. Please fix it if incorrect, else{" "}
              <span style={{ color: "red" }}>
                <b>forward</b>
              </span>
              &nbsp;to billing@copilotiq.com for next steps
            </PDBTextSubHeading>
          ) : (
            <PDBTextSubHeading>
              Member’s contact information is missing. Please add address
              details
            </PDBTextSubHeading>
          )}
        </>
      );
  }
};

const MemberValidationErrorBanner = ({
  type,
  value,
  containerMargin
}: IProps) => {
  return (
    <PatientStateOutsideCoverageContainer containerMargin={containerMargin}>
      <FlexLeftContainer>
        <div>
          <StyledDangerIcon />
        </div>
        <InnerFlexContainer>
          <PDBTextHeading>Attention required</PDBTextHeading>
          <RenderContent type={type} value={value} />
        </InnerFlexContainer>
      </FlexLeftContainer>
    </PatientStateOutsideCoverageContainer>
  );
};

export default MemberValidationErrorBanner;
