import RolesEnum, {
  hasProviderRole,
  isProviderRole
} from "common/enums/RolesEnum";
import { isFalsy } from "common/helpers/helpers";

const deviceCheckboxToSkuMap = {
  bloodPressureCuff: "70710034",
  glucoseMeter: "TM0009"
};

function getDeviceSkusFromSelectedDevices(selectedDevices) {
  return selectedDevices.map((device) => deviceCheckboxToSkuMap[device]);
}

function providerHasOrderedSelectedDevices(orders, selectedDeviceSkus) {
  if (orders?.length === 0) {
    return false;
  }

  const selectedDevicesSkusMap = {};
  selectedDeviceSkus.forEach((sku) => {
    selectedDevicesSkusMap[sku] = false;
  });

  orders?.forEach((orderData) => {
    const orderItems = orderData?.order?.items;

    orderItems?.forEach((item) => {
      if (
        selectedDeviceSkus.includes(item?.sku) &&
        // if ordered_by.roles has a provider role
        (hasProviderRole(orderData?.ordered_by?.roles) ||
          // or if the order has any medical credentials (which corresponds to a provider role)
          !isFalsy(orderData?.medical_credentials)) &&
        // the order has been delivered
        !isFalsy(orderData?.order?.shipping?.delivered_date)
      ) {
        selectedDevicesSkusMap[item?.sku] = true;
      }
    });
  });

  return Object.keys(selectedDevicesSkusMap).every(
    (val) => selectedDevicesSkusMap[val]
  );
}

function showProviderCreatedOrderWarning(
  currentRole,
  orders,
  selectedDeviceSkus
) {
  if (isProviderRole(currentRole) || currentRole === RolesEnum.ADMIN) {
    // don't have restrictions for providers and Admin
    return false;
  } else {
    if (
      // A non-provider role should not be able to order devices for a member
      // without any provider-created orders in the past
      orders &&
      orders.length > 0 &&
      // check if any of the orders have a provider role in ordered_by.roles
      (orders?.findIndex((order) => hasProviderRole(order?.ordered_by?.roles)) >
        -1 ||
        // or if any of the orders have medical credentials (which corresponds to a provider role)
        orders?.findIndex((order) => !isFalsy(order?.medical_credentials)) >
          -1) &&
      providerHasOrderedSelectedDevices(orders, selectedDeviceSkus)
    ) {
      return false;
    }

    return true;
  }
}

export { getDeviceSkusFromSelectedDevices, showProviderCreatedOrderWarning };
