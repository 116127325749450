import { isFalsy } from "common/helpers/helpers";
import { useSearchParams } from "react-router-dom";

interface Params {
  nurseId?: string;
}

const LENGTH_IDS = {
  keys: [
    "nurseId",
  ],
  maxLength: 24
};

const useSanitizedSearchParams = () => {
  const [searchParams] = useSearchParams();

  const sanitizedParams: Params = {};

  LENGTH_IDS.keys.forEach((key) => {
    const value = searchParams.get(key);
    if (!isFalsy(value)) {
      sanitizedParams[key] = value.substring(0, LENGTH_IDS.maxLength);
    }
  });

  return sanitizedParams;
};

export default useSanitizedSearchParams;
