import styled from "@emotion/styled";
import { Box } from "@mui/material";
import MemberType from "common/types/MemberType";

const SystemInformationBannerContainer = styled(Box)<{ width?: string }>`
  padding: 16px;
  display: block;
  flex-basis: 49%;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.color.defaultBorder};
  border-radius: 4px;
  align-items: center;
  width: ${(props) => (props?.width ? props?.width : "100%")};
`;

const PDBTextHeading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #434656;
`;

const PDBTextSubHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${(props) => props?.theme?.color?.veryDarkBlue};
`;

interface IProps {
  patient: MemberType;
  width?: string;
  outerContainerMarginTop?: string;
}

function getSystemInformation(migrated: boolean, system: string) {
  let systemInformation = "";
  if (system === "CopilotIQ") {
    systemInformation = "CopilotIQ";

    if (migrated) {
      systemInformation += " (Migrated)";
    } else {
      systemInformation += " (New Member)";
    }
  } else if (system === "S_100Plus") {
    systemInformation = "100Plus";
  }
  return systemInformation;
}

const SystemInformationBanner = ({
  patient,
  width,
  outerContainerMarginTop
}: IProps) => {
  return (
    <SystemInformationBannerContainer
      width={width}
      mt={outerContainerMarginTop}
    >
      <PDBTextHeading>System Information </PDBTextHeading>
      <PDBTextSubHeading>
        Platform:{" "}
        {getSystemInformation(
          patient?.patient?.migrated,
          patient?.patient?.system
        )}
      </PDBTextSubHeading>
    </SystemInformationBannerContainer>
  );
};

export default SystemInformationBanner;
