import {
  Box,
  CircularProgress,
  styled,
  TextField,
  Typography
} from "@mui/material";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader
} from "../../../styling/StyleModal";
import Modal from "../Modal";
import { TurqoiseButton, WhiteButton } from "../../../styling";
import { useMemo, useState } from "react";
import { useGetDevicesBySerialNumberQuery } from "common/services/DevicesService";
import {
  ArrowBack,
  RadioButtonChecked,
  RadioButtonUnchecked
} from "@mui/icons-material";
import DeviceType from "common/types/DeviceType";
import { StatusBadge } from "../../../styling/StyleComponents";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import { getNameOrUsername } from "common/helpers/helpers";

const StyledModal = styled(Modal)<{ modalHeight: string }>`
  display: flex;
  flex-direction: column;

  height: ${(props) => props.modalHeight};
`;

const Row = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  onDeviceSelected: (device: DeviceType) => void;
}

interface RenderBodyStep0Props {
  value: string;
  onValueChange: (value: string) => void;
}

const RenderBodyStep0 = ({ value, onValueChange }: RenderBodyStep0Props) => {
  return (
    <TextField
      placeholder="Device Serial Number"
      label={"Device Serial Number"}
      value={value}
      onChange={(event) => onValueChange(event.target.value)}
    />
  );
};

interface RenderBodyStep1Props {
  onBackPressed: () => void;
  data: DeviceType[];
  selectedDevice: DeviceType;
  onDeviceSelected: (device: DeviceType) => void;
}

const RenderBodyStep1 = ({
  onBackPressed,
  data,
  onDeviceSelected,
  selectedDevice
}: RenderBodyStep1Props) => {
  return (
    <Box gap={1}>
      <Row onClick={onBackPressed}>
        <ArrowBack />
        <Typography variant="body2">Search again</Typography>
      </Row>
      <br />
      <Typography variant="h5">The following members were found</Typography>

      <br />
      {data ? (
        data.map((device) => {
          return (
            <RenderItem
              key={device.device_id}
              device={device}
              selectedDevice={selectedDevice}
              onDeviceSelected={onDeviceSelected}
            />
          );
        })
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

interface RenderItemProps {
  device: DeviceType;
  selectedDevice: DeviceType;
  onDeviceSelected: (device: DeviceType) => void;
}

const RenderItem = ({
  device,
  selectedDevice,
  onDeviceSelected
}: RenderItemProps) => {
  const { data: member } = useGetMemberWithUsernameQuery({
    username: device.patient_id
  });

  return (
    <Row
      sx={{ gap: "10px", marginBottom: 1 }}
      onClick={() => onDeviceSelected(device)}
    >
      {device === selectedDevice ? (
        <RadioButtonChecked color={"primary"} />
      ) : (
        <RadioButtonUnchecked color={"primary"} />
      )}
      <div style={{ flex: 1 }}>
        <Typography variant="h6" color={"primary"}>
          {member ? getNameOrUsername(member.patient) : "-"}
        </Typography>
        <Typography variant="body2" color="secondary">
          {device.activated_on}
        </Typography>
        <Typography variant="body2" color="secondary">
          {device.extra.vendor_device_id}
        </Typography>
      </div>
      <StatusBadge status={device.status} />
    </Row>
  );
};

const SearchBySerialNumberModal = ({
  isOpen,
  handleClose,
  onDeviceSelected
}: IProps) => {
  const [selectedDevice, setSelectedDevice] = useState<DeviceType>(null);
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [step, setStep] = useState<number>(0);

  const { data, isLoading } = useGetDevicesBySerialNumberQuery(
    { device_id: serialNumber },
    { skip: step === 0 }
  );

  const resetSearchState = () => {
    handleClose();
    setStep(0);
    setSerialNumber("");
  };

  const searchDisabled = useMemo(() => {
    if (step === 0) {
      return serialNumber.length < 2;
    }
    if (step === 1) {
      return selectedDevice === null;
    }
  }, [step, serialNumber, selectedDevice]);

  return (
    <StyledModal
      isOpen={isOpen}
      contentLabel="Search Patient Modal"
      modalWidth="700px"
      modalHeight="70vh"
      onRequestClose={resetSearchState}
    >
      <ModalHeader onRequestClose={resetSearchState}>
        Search by Device
      </ModalHeader>
      <ModalBody>
        {step === 0 && (
          <RenderBodyStep0
            value={serialNumber}
            onValueChange={(value) => setSerialNumber(value)}
          />
        )}
        {step === 1 && (
          <RenderBodyStep1
            onBackPressed={() => setStep(0)}
            data={data}
            onDeviceSelected={setSelectedDevice}
            selectedDevice={selectedDevice}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          <TurqoiseButton
            loading={isLoading}
            disabled={searchDisabled}
            onClick={() => {
              switch (step) {
                case 0:
                  setStep(1);
                  break;
                case 1:
                  onDeviceSelected(selectedDevice);
                  resetSearchState();
                  break;
                default:
              }
            }}
          >
            {step === 0 && "Search"}
            {step === 1 && "Select"}
          </TurqoiseButton>
          <WhiteButton onClick={resetSearchState}>Cancel</WhiteButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default SearchBySerialNumberModal;
