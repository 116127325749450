import styled from "@emotion/styled";
import { DateTime } from "luxon";
import {
  LocalizationProvider,
  DatePicker as DatePickerMUI,
  DatePickerProps
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const DatePicker = (props: DatePickerProps<DateTime>) => {
  return (
    <Column>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePickerMUI {...props} />
      </LocalizationProvider>
    </Column>
  );
};

export default DatePicker;
