import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { FormikValues, useFormik } from "formik";
import { MenuItem, TextField, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

import {
  useGetSurveyQuery,
  useSendAnswersMutation
} from "common/services/SurveyService";
import { useUpdateMemberStatusMutation } from "common/services/MemberRegistrationService";
import MemberType from "common/types/MemberType";
import { RootState } from "common/redux";
import { getCancelMemberStatusByRole } from "common/enums/RolesEnum";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import MemberStatusReasonEnum from "common/enums/MemberStatusReasonEnum";

import { Modal } from "../../../components/Modal";
import {
  SuccessText,
  TurqoiseButton,
  WhiteButton
} from "../../../styling/StyleComponents";
import ErrorComponent from "../../../components/ErrorComponent";

import SurveyField from "./SurveyField";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalFooterButtons
} from "../../../styling/StyleModal";
import DatePicker from "../../../components/DatePicker";

const StyledForm = styled.form<{ modalHeight: string }>`
  display: flex;
  flex-direction: column;

  height: ${(props) => props.modalHeight};
`;

const SurveyContainer = styled.div`
  margin: 10px 0px;
`;

const isDeceasedFn = (values: FormikValues) =>
  -1 !== Object.keys(values).findIndex((key) => values[key] === "Deceased");

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  title: string;
  patient: MemberType;
}

const SurveyModal = ({ isOpen, onRequestClose, title, patient }: IProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const {
    status: cancelStatus,
    status_reason: cancelStatusReason,
    success_text: cancelSuccessText
  } = getCancelMemberStatusByRole(currentRole, patient?.patient?.status);

  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const { data: surveyData } = useGetSurveyQuery();

  const [
    changePatientStatusMutation,
    {
      isLoading: changeUserStatusLoading,
      isSuccess: changeUserStatusSuccess,
      error: changeUserStatusError,
      reset: resetChangePatientStatusMutation
    }
  ] = useUpdateMemberStatusMutation();

  const [
    sendAnswersMutation,
    {
      isLoading: sendingAnswersLoading,
      isSuccess: sendingAnswersSuccess,
      error: sendingAnswersError,
      reset: resetSendAnswersMutation
    }
  ] = useSendAnswersMutation();

  const makePatientInactive = async (values: FormikValues) => {
    if (surveyData === undefined || patient?.patient?.email === undefined)
      return;

    const effectiveDate = values.effectiveDate;
    delete values.effectiveDate;

    sendAnswersMutation({
      surveyData,
      answers: values,
      patient_id: patient.patient.patient_id
    });

    if (isDeceased) {
      changePatientStatusMutation({
        patient_id: patient.patient.patient_id,
        status: MemberStatusEnum.CANCELED,
        status_reason: MemberStatusReasonEnum.DECEASED,
        status_effective_date: effectiveDate
      });
    } else
      changePatientStatusMutation({
        patient_id: patient.patient.patient_id,
        status: cancelStatus,
        status_reason: cancelStatusReason
      });
  };

  const isLoading = changeUserStatusLoading || sendingAnswersLoading;
  const isSuccess = changeUserStatusSuccess && sendingAnswersSuccess;
  const error = changeUserStatusError || sendingAnswersError;

  const { surveyFields, filteredSurveyField } = useMemo(() => {
    let surveyFields = surveyData?.survey_version?.fields;
    if (surveyFields === undefined) return {};

    surveyFields = surveyFields.filter((field) => field.status === "ACTIVE");
    const filteredSurveyField = surveyFields.filter(
      (survey) => survey.category !== "General"
    );

    return { surveyFields, filteredSurveyField };
  }, [surveyData]);

  const surveyFieldsByCategory = useMemo(() => {
    if (surveyFields === undefined || selectedCategory === undefined) return [];

    const generalSurvey = surveyFields.find(
      (survey) => survey.category === "General"
    );

    const surveysToShow = surveyFields.filter(
      (survey) => survey.category === selectedCategory
    );
    surveysToShow.push(generalSurvey);

    return surveysToShow;
  }, [surveyFields, selectedCategory]);

  const initialValues = useMemo(() => {
    const data = {
      effectiveDate: null
    };
    surveyFieldsByCategory?.forEach((field) => (data[field.id] = ""));
    return data;
  }, [surveyFieldsByCategory]);

  const validate = (values: FormikValues) => {
    const errors: { [key: string]: string } = {};
    surveyFieldsByCategory.forEach((item) => {
      if (!values[item.id] || values[item.id] === "") {
        errors[item.id] = "Required";
      }
    });

    if (isDeceasedFn(values) && values.effectiveDate === null) {
      errors["effectiveDate"] = "Required";
    }

    return errors;
  };

  const formik = useFormik<FormikValues>({
    initialValues,
    onSubmit: makePatientInactive,
    validate
  });

  const isDeceased = useMemo(() => {
    return isDeceasedFn(formik.values);
  }, [formik.values]);

  useEffect(() => {
    if (surveyFields === undefined) return;
    const generalSurvey = surveyFields.find(
      (survey) => survey.category === "General"
    );

    const savedGeneralValue = formik.values[generalSurvey.id];

    formik.resetForm();
    formik.setFieldValue(generalSurvey.id, savedGeneralValue).catch(() => {});
  }, [selectedCategory, surveyFields]);

  const handleClose = () => {
    formik.resetForm();
    resetChangePatientStatusMutation();
    resetSendAnswersMutation();

    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen && surveyData !== undefined}
      contentLabel="Attrition Modal"
      onRequestClose={handleClose}
    >
      <StyledForm modalHeight="100%" onSubmit={formik.handleSubmit}>
        <ModalHeader onRequestClose={handleClose}>{title}</ModalHeader>

        <ModalBody>
          {isSuccess ? (
            <div>
              <SuccessText margin={"0 12px 0 0"}>
                Successfully submitted attrition reason.
              </SuccessText>

              <SuccessText margin={"0 12px 0 0"}>
                {cancelSuccessText}
              </SuccessText>
            </div>
          ) : (
            <>
              <Typography variant="subtitle1">
                Please select the attrition category.
              </Typography>
              <br />

              <TextField
                name="form-category"
                value={selectedCategory}
                onChange={(event) => setSelectedCategory(event.target.value)}
                select
              >
                {filteredSurveyField?.map((surveyField) => {
                  return (
                    <MenuItem
                      key={surveyField.category}
                      value={surveyField.category}
                    >
                      {surveyField.category}
                    </MenuItem>
                  );
                })}
              </TextField>

              {surveyFieldsByCategory?.map((field) => (
                <SurveyContainer key={field.id}>
                  <Typography variant="h5">{field.question}</Typography>
                  <br />
                  <SurveyField field={field} formik={formik} />
                  <ErrorComponent error={formik.errors[field.id]?.toString()} />
                </SurveyContainer>
              ))}
            </>
          )}

          {isDeceased && !isSuccess && (
            <>
              <DatePicker
                label="Effective Date"
                maxDate={DateTime.now()}
                value={formik.values.effectiveDate}
                onChange={(value: DateTime) => {
                  formik.setFieldValue("effectiveDate", value);
                }}
              />
              <br />
            </>
          )}

          {error && <ErrorComponent error={error} />}
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            {!isSuccess && (
              <TurqoiseButton
                loading={isLoading}
                type="submit"
                disabled={!formik.isValid || !formik.dirty}
              >
                Confirm
              </TurqoiseButton>
            )}
            <WhiteButton onClick={handleClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </StyledForm>
    </Modal>
  );
};

export default SurveyModal;
