import { useState } from "react";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";

import RequestConsentsForm from "./RequestConsentsForm";
import { Typography } from "@mui/material";

const RequestConsentsContainer = styled.div<{ containerMaxWidth?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 30px 0;
  max-width: ${(props) => props.containerMaxWidth || "none"};
  margin: 2.5%;
`;

const RequestConsentsHeader = styled(Typography)`
  margin-bottom: 2.5%;
`;
const RequestConsentsSubtitle = styled(Typography)`
  margin-bottom: 2.5%;
  font-weight: 500;
  font-size: medium;
`;

const RequestConsents = () => {
  const [searchParams] = useSearchParams();
  const salesforceLeadParam = searchParams.get("salesforceLead");
  const [salesforceLead, setSalesforceLead] = useState<string>(
    salesforceLeadParam ?? ""
  );

  return (
    <RequestConsentsContainer containerMaxWidth="700px">
      <RequestConsentsHeader variant="h1" color="text.primary">
        Request Consents
      </RequestConsentsHeader>
      <RequestConsentsSubtitle variant="h6" color="text.primary">
        Lookup a Salesforce Lead then send a request for consents by SMS or
        Email
      </RequestConsentsSubtitle>
      <RequestConsentsForm
        salesforceLead={salesforceLead}
        salesforceInitialLead={salesforceLead}
        setSalesforceLead={setSalesforceLead}
      />
    </RequestConsentsContainer>
  );
};

export default RequestConsents;
