import { useState, MouseEvent } from "react";
import {
  InputAdornment,
  IconButton,
  TextField,
  TextFieldProps
} from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordInput = (props: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      type={showPassword ? "text" : "password"}
      autoComplete="current-password"
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ backgroundColor: "transparent" }}
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        },
        htmlInput: {
          // https://stackoverflow.com/a/16528080 OWASP recommendations for minimum max length for passwords
          // we need to set a max length to prevent ReDoS attacks
          // https://owasp.org/www-community/attacks/Regular_expression_Denial_of_Service_-_ReDoS
          maxLength: 128
        }
      }}
    />
  );
};

export default PasswordInput;
