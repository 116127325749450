import { api as generatedApi, Query } from "../graphql/generated";

import ReduxTagEnum from "../enums/ReduxTagEnum";

export const memberGraphQLService = generatedApi.enhanceEndpoints({
  addTagTypes: [ReduxTagEnum.Tasks],
  endpoints: {
    getTasks: {
      providesTags: [ReduxTagEnum.Tasks],
      transformResponse: (res: Query) => {
        return res;
      }
    }
  }
});

export const { useGetTasksQuery } = memberGraphQLService;
