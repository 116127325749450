enum ReportEnum {
  ENCOUNTERS = "encounters",
  READINGS = "readings",
  TIME_ENTERED_BY_TNS = "time_entered_by_tns",
  TIME_ENTERED_BY_INS = "time_entered_by_ins",
  FINANCIAL_ASSISTANCE_QUALIFICATIONS = "financial_assistance_qualifications",
  ATTRITION = "attrition",
  REQUESTS_TO_CANCEL = "requests_to_cancel",
  MEMBER_CHART_SHARE = "member_chart_share",
  SMS_REPLIES = "sms_replies"
}

export default ReportEnum;
