import { apiDevices, apiPartners, apiPAB } from "./AxiosService";

import DeviceType from "../types/DeviceType";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import DeviceStatusEnum from "../enums/DeviceStatusEnum";
import { providesList } from "./ServicesCacheUtils";
import AddressType from "../types/AddressType";
import ValidateAddressResponseType from "../types/ValidateAddress/ValidateAddressResponseType";
import { areAddressesEqual } from "../helpers/helpers";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { AppDispatch } from "../redux";

const providesTags = (result: DeviceType[] | undefined) => {
  return providesList<DeviceType, ReduxTagEnum.Device>(
    result,
    ReduxTagEnum.Device,
    (item: DeviceType) => item.device_id
  );
};

const UPDATE_DELAY = 1500;

async function clearCache(
  queryFulfilled: PromiseWithKnownReason<any, any>,
  dispatch: AppDispatch,
  id: string
) {
  try {
    await queryFulfilled;
    setTimeout(() => {
      dispatch(
        devicesPABService.util.invalidateTags([
          ReduxTagEnum.Device,
          { type: ReduxTagEnum.Device, id }
        ])
      );
    }, UPDATE_DELAY);
  } catch (error) {}
}

const devicesPABService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Device]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getDevices: builder.query<DeviceType[], { memberId: string }>({
        query: ({ memberId }) => {
          return { url: `/devices/patient_id/${memberId}`, method: "GET" };
        },
        providesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Device },
          { type: ReduxTagEnum.Device, id: arg.memberId }
        ]
      }),
      getDevicesBySerialNumber: builder.query<
        DeviceType[],
        { device_id: string }
      >({
        query: ({ device_id }) => {
          return {
            url: `/devices/extra?vendor_device_id=${device_id}`,
            method: "GET"
          };
        },
        providesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Device },
          { type: ReduxTagEnum.Device, id: arg.device_id }
        ]
      }),

      //Mutations
      updateDeviceExtra: builder.mutation<
        DeviceType,
        { device_id: string; extra: any; memberId: string }
      >({
        query: ({ device_id, extra }) => {
          return {
            url: `/devices/${device_id}/extra`,
            method: "PUT",
            data: extra
          };
        },
        async onQueryStarted({ device_id }, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch, device_id);
        }
      })
    })
  });

const partnersService = apiPartners
  .enhanceEndpoints({
    addTagTypes: []
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      validateAddress: builder.mutation<
        ValidateAddressResponseType,
        { address: AddressType }
      >({
        query: ({ address }) => {
          return {
            url: `/shipengine/validate-address`,
            method: "POST",
            data: address
          };
        },
        transformResponse: (response: ValidateAddressResponseType) => {
          const { original_address, matched_address, ...rest } = response;
          const originalAddress = {
            ...original_address,
            // truncate postal code to 5 digits
            postal_code: original_address.postal_code?.slice(0, 5)
          };
          const matchedAddress = {
            ...matched_address,
            // truncate postal code to 5 digits
            postal_code: matched_address.postal_code?.slice(0, 5)
          };

          const addressesEqual = areAddressesEqual(
            originalAddress,
            matchedAddress
          );

          return {
            original_address: originalAddress,
            matched_address: matchedAddress,
            addressesEqual,
            ...rest
          };
        }
      })
    })
  });

const devicesService = apiDevices
  .enhanceEndpoints({
    addTagTypes: []
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      //Mutations
      updateDeviceStatus: builder.mutation<
        DeviceType,
        {
          device_id: string;
          status: DeviceStatusEnum;
          new_serial_number?: string;
          memberId?: string;
        }
      >({
        query: ({ device_id, status, new_serial_number }) => {
          let body = new_serial_number
            ? { new_serial_number: new_serial_number }
            : {};

          return {
            url: `/devices/${device_id}/status/${status.toLowerCase()}`,
            method: "PUT",
            data: body
          };
        },
        invalidatesTags: [ReduxTagEnum.Device],
        async onQueryStarted({ device_id }, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch, device_id);
        }
      }),
      validateAddress: builder.mutation<
        ValidateAddressResponseType,
        { address: AddressType }
      >({
        query: ({ address }) => {
          return {
            url: `/validate_address`,
            method: "POST",
            data: address
          };
        },
        transformResponse: (response: ValidateAddressResponseType) => {
          const { original_address, matched_address, ...rest } = response;
          const originalAddress = {
            ...original_address,
            // truncate postal code to 5 digits
            postal_code: original_address.postal_code?.slice(0, 5)
          };
          const matchedAddress = {
            ...matched_address,
            // truncate postal code to 5 digits
            postal_code: matched_address.postal_code?.slice(0, 5)
          };

          const addressesEqual = areAddressesEqual(
            originalAddress,
            matchedAddress
          );

          return {
            original_address: originalAddress,
            matched_address: matchedAddress,
            addressesEqual,
            ...rest
          };
        }
      })
    })
  });

export const {
  useGetDevicesQuery,
  useGetDevicesBySerialNumberQuery,
  useUpdateDeviceExtraMutation
} = devicesPABService;
export const { useValidateAddressMutation } = partnersService;
export const { useUpdateDeviceStatusMutation } = devicesService;
