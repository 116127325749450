import { prettyStatusString } from "../helpers/helpers";
import RolesEnum from "./RolesEnum";

enum MemberStatusEnum {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  AUTO_CANCELED = "AUTO_CANCELED",
  CANCELLATION_REQUESTED = "CANCELLATION_REQUESTED",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
  ELIGIBLE = "ELIGIBLE",
  QUALIFIED = "QUALIFIED",
  ENROLLED = "ENROLLED",
  NEVER_ENGAGED = "NEVER_ENGAGED",
  REEVALUATING_PATIENT = "REEVALUATING_PATIENT",
  INPUT_ERROR = "INPUT_ERROR"
}

export const MemberStatusEnum_toString = (status: MemberStatusEnum) => {
  if (status === undefined || MemberStatusEnum[status] === undefined)
    return undefined;

  return prettyStatusString(MemberStatusEnum[status]);
};

export default MemberStatusEnum;
