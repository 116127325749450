import { useState } from "react";
import CreateTeamForm from "./CreateTeamForm";
import styled from "@emotion/styled";
import { TurqoiseButton, WhiteButton, ComponentHeader } from "../../styling";

import UserType from "common/types/UserType";
import SelectTeamLeadTable from "./SelectTeamLeadTable";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";

const CreateTeamContainer = styled.div`
  padding: 5%;
  width: 85%;
  background: ${(props) => props.theme.color.white};
  /* Stroke */

  border: 1px solid ${(props) => props.theme.color.defaultBorder};
  border-radius: 4px;
  margin: 2.5%;
`;

const StyledTurqoiseButton = styled(TurqoiseButton)`
  width: max-content;
  padding: 10px;
  margin-top: 10px;
`;

const CreateTeam = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedTeamLead, setSelectedTeamLead] = useState<UserType>();

  function handleModalOpen() {
    setModalOpen(true);
  }
  function handleModalClose() {
    setModalOpen(false);
  }
  function handleSelectedTeamLeader(teamLeader: UserType[]) {
    const leader = teamLeader?.[0];
    setSelectedTeamLead(leader);
  }

  return (
    <CreateTeamContainer>
      <ComponentHeader>Create Team</ComponentHeader>

      <StyledTurqoiseButton aria-label="openModal" onClick={handleModalOpen}>
        Select Team Leader
      </StyledTurqoiseButton>
      <CreateTeamForm teamLeader={selectedTeamLead} />
      <StyledModal
        isOpen={modalOpen}
        contentLabel="Team Leader Modal"
        modalHeight="750px"
        modalWidth="1025px"
        onRequestClose={handleModalClose}
      >
        <ModalHeader onRequestClose={handleModalClose}>
          Select Team Leader
        </ModalHeader>
        <ModalBody>
          <SelectTeamLeadTable
            onSelectedTeamLeader={handleSelectedTeamLeader}
          />
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              onClick={handleModalClose}
              disabled={!selectedTeamLead}
              type="submit"
            >
              Select
            </TurqoiseButton>
            <WhiteButton onClick={handleModalClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </StyledModal>
    </CreateTeamContainer>
  );
};

export default CreateTeam;
