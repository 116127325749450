import { useMemo } from "react";

import { useGetUserWithQueryParamsQuery } from "common/services/UserService";
import RolesEnum from "common/enums/RolesEnum";
import UserType from "common/types/UserType";
import Table from "../../components/Table/Table";
import ReportTeamType from "common/types/ReportTeamType";

import ErrorComponent from "../../components/ErrorComponent";
import { getNameOrUsername } from "common/helpers/helpers";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import UserLinkedEntitiesEnum from "common/enums/UserLinkedEntitiesEnum";
import { TeamTypeEnum, getAllowedMemberRoles } from "common/enums/TeamTypeEnum";

interface IProps {
  onUserSelected: (selectedUsersArray: UserType[]) => void;
  team: ReportTeamType;
  enableMultiRowSelection: boolean;
}

const AddTeamMemberTable = ({
  onUserSelected,
  team,
  enableMultiRowSelection
}: IProps) => {
  const teamMembersType = getAllowedMemberRoles(TeamTypeEnum[team.type]);
  const { data: teamMemberOptions, error: teamMemberOptionsError } =
    useGetUserWithQueryParamsQuery({
      roles: teamMembersType as RolesEnum[],
      status: [MemberStatusEnum.ACTIVE],
      linked_entities: [UserLinkedEntitiesEnum.TEAM]
    });

  const sortedMembers = useMemo(() => {
    if (teamMemberOptions === undefined) return undefined;

    return [...teamMemberOptions].sort((a, b) => {
      return getNameOrUsername(a?.user).localeCompare(
        getNameOrUsername(b?.user)
      );
    });
  }, [teamMemberOptions]);

  return (
    <>
      {sortedMembers && (
        <Table
          data={sortedMembers}
          enableMultiRowSelection={enableMultiRowSelection}
          setSelectionCallback={onUserSelected}
          tableProps={{}}
          estimateRowSize={(index: number) => {
            const member = sortedMembers[index];
            const rolesAmount = member?.user?.roles?.length;
            return 45 + 20 * rolesAmount;
          }}
          tableColumns={[
            { name: "checkboxColumn", type: "checkbox" },
            {
              name: "fullname",
              filterFn: "fuzzy",
              filterEnabled: true,
              size: 250
            },
            {
              name: "team",
              header: "Current Team",
              accessor: "team.name",
              size: 300
            },
            { name: "roles", size: 250, type: "dataList" }
          ]}
        />
      )}

      {teamMemberOptionsError && (
        <ErrorComponent
          header="Error loading team members:"
          error={teamMemberOptionsError}
        />
      )}
    </>
  );
};

export default AddTeamMemberTable;
