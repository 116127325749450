import {
  FormLabel,
  InputLabel,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import styled from "@emotion/styled";
import { TurqoiseButton, WhiteButton } from "../../styling";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import { DateTime } from "luxon";
import VisitReasonsEnum from "common/enums/Calendaring/Visits/VisitReasonsEnum";
import {
  Alert_close,
  Alert_loading,
  Alert_show
} from "common/helpers/AlertHelper";
import { RootState, useAppDispatch } from "common/redux";
import { Flexbox } from "../../styling/NewStyleComponents";
import MemberType from "common/types/MemberType";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { getNameOrUsername } from "common/helpers/helpers";
import CommunicationTypeEnum from "common/enums/Calendaring/CommunicationTypeEnum";
import {
  useCreateEncounterMutation,
  useGetEncountersQuery
} from "common/services/EncountersService";
import LocalizedStrings from "common/localizations/LocalizedStrings";
import { InfoOutlined } from "@mui/icons-material";
import { CustomTooltip } from "../../styling/StyleComponents";
import NumberInput from "../Input/NumberInput";
import { gray } from "../../styling/colors";
import RolesEnum, { isIntakeNurseRole, isTnRole } from "common/enums/RolesEnum";
import { tnVisitTypeReasons } from "common/helpers/EncounterHelper";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  member: MemberType;
}

interface FormikFormValues {
  timeSpent: number | string;
  reason: {
    value: string;
    label: string;
  };
  visitType: {
    value: string;
    label: string;
  };
}

const RecordEncounterModal = ({ isOpen, onRequestClose, member }: IProps) => {
  const dispatch = useAppDispatch();
  const { user, currentRole } = useSelector((state: RootState) => state.auth);
  const [createEncounterMutation, { isLoading: createEncounterLoading }] =
    useCreateEncounterMutation();
  const tnVisitReasons = tnVisitTypeReasons();
  const intakeVisitReasons = [
    {
      label: "Nurse Intake",
      value: VisitReasonsEnum.NURSE_INTAKE
    }
  ];
  const showTnDropdown =
    isTnRole(currentRole) ||
    // https://copilotiq.atlassian.net/browse/ENG-5162
    currentRole === RolesEnum.NURSE_DIRECTOR;

  const { data: dailyEncounters } = useGetEncountersQuery(
    {
      patient_id: member?.patient?.patient_id,
      startsAfter: DateTime.now().startOf("day")
    },
    { skip: member === undefined }
  );

  const validateFunc = (values: FormikFormValues) => {
    const errors: { [key: string]: string } = {};
    if (!values.timeSpent) {
      errors.timeSpent = "Required";
    }

    if (!values.visitType?.value && showTnDropdown) {
      errors.visitType = "Required";
    }

    return errors;
  };

  async function createEncounterHandler(duration: number, reason?: string) {
    await createEncounterMutation({
      body: {
        patient_id: member?.patient?.patient_id,
        submitted_by: user?.user_id,
        reason,
        starts_on: DateTime.now().toUTC().toISO(),
        modality: CommunicationTypeEnum.PHONE,
        duration
      }
    });
  }

  const onSubmit = async (
    values: FormikFormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    const duration = +values.timeSpent;
    const visitType = values?.visitType?.value;

    const hasRepeatedEncounter =
      dailyEncounters != undefined && dailyEncounters.length > 0;

    if (hasRepeatedEncounter) {
      const id = "hasRepeatedEncounter";
      Alert_show({
        dispatch,
        id,
        title: "Create encounter confirmation",
        content: (
          <div>
            <div>
              Member {getNameOrUsername(member.patient)} already has{" "}
              <b>time entered today</b>.
            </div>
            <div>Are you sure you want to submit this?</div>
          </div>
        ),
        type: "warning",
        size: "medium",
        buttons: [
          {
            text: "Yes, duplicate",
            style: "cancel",
            hasLoadingState: true,
            onPress: async () => {
              Alert_loading({ dispatch, id });
              if (isIntakeNurseRole(currentRole)) {
                // if the user is an intake nurse, then we will always submit an initial visit
                await createEncounterHandler(
                  duration,
                  VisitReasonsEnum.NURSE_INTAKE
                );
              } else if (isTnRole(currentRole)) {
                await createEncounterHandler(duration, visitType);
              }
              Alert_close({ dispatch, id });
            }
          },
          {
            text: LocalizedStrings.cancel,
            style: "default",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    } else if (isIntakeNurseRole(currentRole)) {
      await createEncounterHandler(duration, VisitReasonsEnum.NURSE_INTAKE);
    } else if (isTnRole(currentRole)) {
      await createEncounterHandler(duration, visitType);
    }

    resetForm();
    onRequestClose();
  };

  const formik = useFormik<FormikFormValues>({
    initialValues: {
      timeSpent: "",
      reason: { value: undefined, label: undefined },
      visitType: { value: undefined, label: undefined }
    },
    validate: validateFunc,
    onSubmit
  });

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Record Encounter Modal"
      modalHeight="500px"
      modalWidth="400px"
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader onRequestClose={onRequestClose}>
          Enter Time Spent
        </ModalHeader>
        <ModalBody>
          <Flexbox gap="20px" flexDirection="column">
            <Flexbox gap="8px" flexDirection="column">
              <InputLabel>
                <Typography variant="h6" color="text.primary">
                  Visit Type
                </Typography>
              </InputLabel>
              {showTnDropdown && (
                <TextField
                  data-testid="Visit Type"
                  value={formik.values?.visitType?.value ?? ""}
                  placeholder="Select Visit Type"
                  fullWidth
                  select
                  onChange={(event) => {
                    formik.setFieldValue("visitType", event.target);
                  }}
                >
                  {tnVisitReasons?.map((reason) => {
                    if (reason?.disabled) return;
                    return (
                      <MenuItem
                        key={reason.value}
                        value={reason.value}
                        data-testid={reason.label}
                      >
                        {reason.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
              {!showTnDropdown && (
                <TextField
                  data-testid="Visit Type"
                  value={"Nurse Intake"}
                  placeholder="Select Visit Type"
                  fullWidth
                  disabled
                >
                  {intakeVisitReasons?.map((reason) => {
                    return (
                      <MenuItem
                        key={reason.value}
                        value={reason.value}
                        data-testid={reason.label}
                      >
                        {reason.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            </Flexbox>

            <Flexbox gap="8px" flexDirection="column">
              <FormLabel>
                <Typography variant="h6" color="text.primary">
                  <Flexbox alignItems="center" gap="2px">
                    Total Time{" "}
                    <CustomTooltip
                      backgroundColor={gray[200]}
                      title={
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          maxWidth="225px"
                        >
                          This is the total time spent in service of members
                          including call attempts, async messaging, task
                          completion, charting, etc.
                        </Typography>
                      }
                    >
                      <InfoOutlined color="primary" fontSize="small" />
                    </CustomTooltip>
                  </Flexbox>
                </Typography>
              </FormLabel>
              <NumberInput
                sx={{ width: "100%" }}
                placeholder="1-60 min"
                id={"total_time"}
                name={"total_time"}
                data-testid="totalTimeInput"
                value={formik.values?.timeSpent ?? ""}
                errorString={formik.errors.timeSpent}
                onValueChange={(value) => {
                  formik.setFieldValue("timeSpent", value);
                }}
                min={1}
                max={60}
              />
            </Flexbox>
          </Flexbox>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              data-testid="submitButton"
              loading={createEncounterLoading}
              sx={{ height: "53px" }}
              disabled={!formik.values?.timeSpent || createEncounterLoading}
              type="submit"
            >
              Submit
            </TurqoiseButton>
            <WhiteButton onClick={onRequestClose}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
    </StyledModal>
  );
};

export default RecordEncounterModal;
