// @ts-nocheck
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

import { getErrorMessage, maskPhoneNumber } from "common/helpers/helpers";

import { ErrorText, TurqoiseButton, WhiteButton } from "../../styling";
import { ErrorTextSpan, SuccessText } from "../../styling/StyleComponents";
import MigratePatientStatus from "./enums/MigratePatientStatus";
import NurseAndEnrolledForm from "./NurseAndEnrolledForm";
import departmentsData from "../../config/departmentsData.json";

import ErrorComponent from "../../components/ErrorComponent";
import {
  BodyHeader,
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";

const PatientLink = styled(Link)`
  color: ${(props) => props.theme.color.veryDarkBlue};
  text-decoration: none;
  font-family: ${(props) => props.theme.font.family};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const DataItem = styled.div`
  font-size: 16px;
`;

function ShowMigratePatientResult({
  migratePatientResponse,
  migratePatientError
}) {
  if (migratePatientError?.patient_id) {
    return (
      <>
        <ErrorText>Account is already registered.</ErrorText>
        <div>
          <PatientLink
            to={`/members/memberId/${migratePatientError?.patient_id}`}
          >
            Click here to view the account.
          </PatientLink>
        </div>
      </>
    );
  }

  if (migratePatientResponse?.patient_id) {
    return (
      <>
        <SuccessText>Account successfully registered.</SuccessText>
        <div>
          <PatientLink
            to={`/members/memberId/${migratePatientResponse?.patient_id}`}
          >
            Click here to view the account.
          </PatientLink>
        </div>
      </>
    );
  }

  return "An error occurred. Please try again.";
}

function showData(dataObj, setHasDataErrors) {
  const {
    first,
    middle,
    last,
    athenaFirstName,
    athenaLastName,
    timezone,
    birthdate,
    mobile,
    phone,
    primarydepartmentid
  } = dataObj;

  const LEGACY_DEPARTMENT_MESSAGE = "(Legacy Department)";
  let isLegacyDepartment = false;
  let departmentDetails = departmentsData.find(
    (provider) => provider["Department ID"] === Number(primarydepartmentid)
  );

  // One-off legacy exception: we want to allow DepartmentID: 1
  // for migrations but not for new patient registrations
  if (!departmentDetails && Number(primarydepartmentid) === 1) {
    // Hard coding to find DR LITCHFIELD & provide warning
    isLegacyDepartment = true;
    departmentDetails = departmentsData.find(
      (provider) => provider["Department ID"] === Number(5)
    );
  }

  if (!timezone || !departmentDetails) {
    setHasDataErrors(true);
  }

  return (
    <>
      {athenaFirstName !== first || athenaLastName !== last ? (
        <ErrorText>
          Records do not match:
          <DataItem>
            <b>Salesforce: </b>
            {`${first} ${last}`}
          </DataItem>
          <DataItem>
            <b>Athena: </b>
            {`${athenaFirstName} ${athenaLastName}`}
          </DataItem>
        </ErrorText>
      ) : (
        ""
      )}
      <DataItem>
        <b>Name:</b>{" "}
        {`${athenaFirstName} ${middle ? `${middle} ` : ""}${athenaLastName}`}
      </DataItem>
      <DataItem>
        <b>Date of Birth:</b> {birthdate}
      </DataItem>
      <DataItem>
        <b>Phone:</b> {maskPhoneNumber(phone)}
      </DataItem>
      <DataItem>
        <b>Mobile:</b> {maskPhoneNumber(mobile)}
      </DataItem>
      <DataItem>
        <b>Timezone:</b>{" "}
        {timezone ? (
          timezone
        ) : (
          <ErrorTextSpan>
            No time zone found. Please update Salesforce.
          </ErrorTextSpan>
        )}
      </DataItem>
      <DataItem>
        <b>Department:</b>{" "}
        {departmentDetails ? (
          `${departmentDetails.Name} ${
            isLegacyDepartment ? LEGACY_DEPARTMENT_MESSAGE : ""
          }`
        ) : (
          <ErrorTextSpan>
            Invalid Athena assigned department, please fix this on Athena
          </ErrorTextSpan>
        )}
      </DataItem>
    </>
  );
}
function getErrors(salesforceError, athenaError) {
  const errors = [];
  if (salesforceError) {
    let sfErrorText = `Salesforce Error: `;
    sfErrorText += getErrorMessage(salesforceError);
    errors.push(sfErrorText);
  }
  if (athenaError) {
    let athenaErrorText = `Athena Error: `;
    athenaErrorText += getErrorMessage(athenaError);
    errors.push(athenaErrorText);
  }
  return errors;
}

function DisplayMigrateData({
  patientToMigrateDataArray,
  setHasDataErrors,
  nursesList,
  nursesListError,
  nurseEnrolledFormRef,
  setCanSubmitConfirmationCallback,
  athena_id,
  setCurrentModalStateCallback,
  setMigratePatientResponseCallback,
  setMigratePatientErrorCallback,
  setMigratedPatientIdCallback,
  migratePatientError,
  setMigratePatientLoadingCallback
}) {
  const [salesforceDataObj, athenaDataObj] = patientToMigrateDataArray;
  const { data: salesforceResponse, error: salesforceError } =
    salesforceDataObj.value;
  const { data: athenaResponse, error: athenaError } = athenaDataObj.value;

  if (salesforceError || athenaError) {
    setHasDataErrors(true);
    const errors = getErrors(salesforceError, athenaError);
    return (
      <ErrorText>
        There was an error loading some data:
        <br />
        {errors?.map((error) => <ErrorText key={error}>{error}</ErrorText>)}
        Please retry or contact your support team.
      </ErrorText>
    );
  }

  if (salesforceResponse && athenaResponse) {
    const {
      FirstName: first,
      MiddleName: middle,
      LastName: last,
      tz__Timezone_IANA__c: timezone,
      Id: salesforce_acc_id,
      Notes__c
    } = salesforceResponse;

    const {
      firstname: athenaFirstName,
      lastname: athenaLastName,
      middlename: athenaMiddleName,
      dob: birthdate,
      mobile_number: mobile,
      phone_number: phone,
      primaryproviderid,
      primarydepartmentid
    } = athenaResponse;

    const dataObj = {
      first,
      middle,
      last,
      athenaFirstName,
      athenaLastName,
      athenaMiddleName,
      timezone,
      birthdate,
      mobile,
      phone,
      primaryproviderid,
      primarydepartmentid,
      salesforce_acc_id,
      athena_id,
      Notes__c
    };

    return (
      <>
        {showData(dataObj, setHasDataErrors)}
        {nursesList?.length > 0 && (
          <>
            <br />
            <NurseAndEnrolledForm
              ref={nurseEnrolledFormRef}
              nursesList={nursesList}
              dataObj={dataObj}
              setCanSubmitConfirmationCallback={
                setCanSubmitConfirmationCallback
              }
              setCurrentModalStateCallback={setCurrentModalStateCallback}
              setMigratePatientResponseCallback={
                setMigratePatientResponseCallback
              }
              setMigratePatientErrorCallback={setMigratePatientErrorCallback}
              setMigratedPatientIdCallback={setMigratedPatientIdCallback}
              migratePatientError={migratePatientError}
              setMigratePatientLoadingCallback={
                setMigratePatientLoadingCallback
              }
            />
          </>
        )}
        {nursesListError && <ErrorComponent error={nursesListError} />}
      </>
    );
  }
}

export default function MigratePatientModal({
  isModalOpen,
  currentModalState,
  patientToMigrateDataArray,
  setHasDataErrors,
  nursesList,
  nursesListError,
  nurseEnrolledFormRef,
  setCanSubmitConfirmationCallback,
  athena_id,
  setCurrentModalStateCallback,
  setMigratePatientResponseCallback,
  setMigratePatientErrorCallback,
  setMigratePatientLoadingCallback,
  migratePatientLoading,
  setMigratedPatientIdCallback,
  migratePatientResponse,
  migratePatientError,
  migratedPatientId,
  closeModal,
  handleNurseEnrolledFormSubmit,
  hasDataErrors,
  canSubmitConfirmation
}) {
  const navigate = useNavigate();

  return (
    <StyledModal
      isOpen={isModalOpen}
      contentLabel="Migrate Member Modal"
      className="migratePatientModal"
      overlayClassName="migratePatientModalOverlay"
      modalHeight="70vh"
      onRequestClose={() => closeModal()}
    >
      <ModalHeader>Review and Confirm</ModalHeader>
      <ModalBody>
        <BodyHeader>
          {currentModalState === MigratePatientStatus.STATE_1 && (
            <DisplayMigrateData
              patientToMigrateDataArray={patientToMigrateDataArray}
              setHasDataErrors={setHasDataErrors}
              nursesList={nursesList}
              nursesListError={nursesListError}
              nurseEnrolledFormRef={nurseEnrolledFormRef}
              setCanSubmitConfirmationCallback={
                setCanSubmitConfirmationCallback
              }
              athena_id={athena_id}
              setCurrentModalStateCallback={setCurrentModalStateCallback}
              setMigratePatientResponseCallback={
                setMigratePatientResponseCallback
              }
              setMigratePatientErrorCallback={setMigratePatientErrorCallback}
              setMigratedPatientIdCallback={setMigratedPatientIdCallback}
              migratePatientError={migratePatientError}
              setMigratePatientLoadingCallback={
                setMigratePatientLoadingCallback
              }
            />
          )}
          {currentModalState === MigratePatientStatus.STATE_2 && (
            <>
              <ShowMigratePatientResult
                migratePatientResponse={migratePatientResponse}
                migratePatientError={migratePatientError}
              />
            </>
          )}
        </BodyHeader>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          {currentModalState === MigratePatientStatus.STATE_1 && (
            <TurqoiseButton
              loading={migratePatientLoading}
              onClick={() => {
                handleNurseEnrolledFormSubmit();
                setMigratePatientLoadingCallback(true);
              }}
              disabled={
                hasDataErrors || !canSubmitConfirmation || migratePatientLoading
              }
              type="submit"
            >
              Confirm
            </TurqoiseButton>
          )}
          {currentModalState === MigratePatientStatus.STATE_2 && (
            <TurqoiseButton
              onClick={() => {
                navigate(`/devices/${migratedPatientId}`);
              }}
              disabled={
                hasDataErrors || !canSubmitConfirmation || !migratedPatientId
              }
              type="submit"
            >
              Order Devices
            </TurqoiseButton>
          )}
          <WhiteButton
            onClick={() => {
              closeModal();
            }}
          >
            {currentModalState === MigratePatientStatus.STATE_2
              ? "Close"
              : "Cancel"}
          </WhiteButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
}
