import { apiPAB } from "./AxiosService";
import { DateTime } from "luxon";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import TeamType from "../types/TeamType";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import { providesList } from "./ServicesCacheUtils";
import { userService } from "./UserService";
import { TeamTypeEnum } from "../enums/TeamTypeEnum";

const providesTags = (result: TeamType[] | undefined) => {
  return providesList<TeamType, ReduxTagEnum.Team>(
    result,
    ReduxTagEnum.Team,
    (item: TeamType) => item.team_id
  );
};

const teamsService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Team]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getTeams: builder.query<
        TeamType[],
        Partial<{
          name?: string;
          member_id?: string;
          leader_id?: string;
          types?: TeamTypeEnum[];
        }>
      >({
        query: (paramObject) => {
          const params = QueryString_stringify(paramObject);
          return { url: `/teams?${params}`, method: "GET" };
        },
        providesTags
      }),
      getTeamById: builder.query<TeamType, { team_id: string }>({
        query: ({ team_id }) => {
          return { url: `/teams/${team_id}`, method: "GET" };
        },
        providesTags: (result) => [
          {
            type: ReduxTagEnum.Team,
            id: result?.team_id
          }
        ]
      }),

      // Mutations
      createTeam: builder.mutation<TeamType, Omit<Partial<TeamType>, "id">>({
        query: (data) => ({
          url: "/teams",
          method: "POST",
          data
        }),
        invalidatesTags: [ReduxTagEnum.Team]
      }),
      addMember: builder.mutation<
        TeamType,
        Omit<{ team_id: string; member_id: string }, "id">
      >({
        query: ({ team_id, member_id }) => {
          const data = {
            member_id,
            since: DateTime.now().toISODate()
          };
          return {
            url: `/teams/${team_id}/members`,
            method: "PUT",
            data
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Team, id: arg.team_id },
          { type: ReduxTagEnum.Team, id: result.leader_id }
        ],
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            setTimeout(() => {
              dispatch(userService.util.invalidateTags([ReduxTagEnum.User]));
            }, 2000);
          } catch (error) {}
        }
      }),
      deleteMember: builder.mutation<
        TeamType,
        Omit<{ team_id: string; member_id: string }, "id">
      >({
        query: ({ team_id, member_id }) => {
          return {
            url: `/teams/${team_id}/members/${member_id}`,
            method: "DELETE"
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Team, id: arg.team_id },
          { type: ReduxTagEnum.Team, id: result.leader_id }
        ],
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            setTimeout(() => {
              dispatch(userService.util.invalidateTags([ReduxTagEnum.User]));
            }, 2000);
          } catch (error) {}
        }
      })
    })
  });

export const {
  useGetTeamsQuery,
  useGetTeamByIdQuery,
  useCreateTeamMutation,
  useAddMemberMutation,
  useDeleteMemberMutation
} = teamsService;
