import styled from "@emotion/styled";
import { DateTime, Duration } from "luxon";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";

import { memo, useState } from "react";
import {
  Alert_close,
  Alert_show,
  Alert_loading
} from "common/helpers/AlertHelper";
import LocalizedStrings from "common/localizations/LocalizedStrings";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import {
  maskPhoneNumber,
  getNameOrUsername,
  formatName,
  isFalsy,
  getStatusOrDispositionFromMultipleVisits
} from "common/helpers/helpers";
import DeviceType from "common/types/DeviceType";
import RolesEnum, {
  ProviderRoles,
  canCallMember,
  canEditDeleteTimeOff,
  canUpdateOutcomeNurseAppointments,
  canUpdateOutcomeProviderAppointments,
  canScheduleNurses,
  canScheduleProviders,
  canSeeEncountersOnStaffDetailsCurrentRole,
  canSeeOrdersOnStaffDetailsCurrentRole,
  canSeeScheduleOnStaffProfile,
  canSetStaffInactive,
  canUpdateNurseAssignment,
  canUpdateProviderAssignment,
  hasProviderRole,
  getRoleLabel,
  canSeeCareFlow
} from "common/enums/RolesEnum";
import MemberTypeInner from "common/types/common/MemberTypeInner";
import { store, useAppDispatch } from "common/redux";
import { ReadingQualifierReasonEnum_toString } from "common/enums/ReadingQualifierReasonEnum";

import {
  getFormattedDate,
  getFormattedDateTime,
  getLastEncounter,
  getTrackingUrl,
  callPatientModalSelector,
  getTeamTypeLabel
} from "../../../helpers/helpers";
import { EditEncounterCell } from "../../../components/Table/helpers/EditEncounterCell";

import {
  Column,
  CustomTooltip,
  DefaultTableCell,
  DefaultTableHeader,
  ExternalLink,
  StatusBadge,
  PhoneNumber
} from "../../../styling/StyleComponents";
import { Athena, HelpIcon, ThreeDotsIcon } from "../../../assets/images/icons";
import { isEditDeleteAllowed } from "./TableHelpers";

import ErrorComponent from "../../ErrorComponent";
import { Box, Button, Chip, Link, Typography, useTheme } from "@mui/material";
import {
  Call,
  Cancel,
  CheckCircle,
  Delete,
  DeleteOutlineOutlined,
  Diversity1,
  Download,
  Edit,
  EditOutlined,
  PhoneOutlined,
  VideocamOutlined
} from "@mui/icons-material";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { blue, error, gray, warning } from "../../../styling/colors";
import StyledIconButton from "../../Button/StyledIconButton";
import { red } from "@mui/material/colors";
import replace from "lodash.replace";
import AppointmentOperationTypeEnum from "common/enums/Calendaring/Appointments/AppointmentOperationTypeEnum";
import CommunicationTypeEnum from "common/enums/Calendaring/CommunicationTypeEnum";
import { DeleteRecurringAppointmentForm } from "../../../pages/MemberDetails/Appointments/DeleteRecurringAppointmentForm";
import { UpdateAppointmentOutcomeForm } from "../../../pages/MemberDetails/Appointments/UpdateAppointmentOutcomeForm";
import { copyToClipboard } from "../../../styling/CopyPatientLinkToClipboard";
import AthenaChartButton from "../../Button/AthenaChartButton";
import EditDeleteMenu from "../../ReassignAppointments/EditDeleteMenu";
import { getAthenaPatientUrl } from "common/helpers/EnvVarsHelper";
import DeleteRelationshipNoteForm from "../../../pages/Visits/DeleteRelationshipNoteForm";
import EditRelationshipNoteForm from "../../../pages/Visits/EditRelationshipNoteForm";
import { getPrettyGoalAssessmentStatus } from "common/types/GoalType";
import {
  isNurseAppointment,
  isProviderAppointment
} from "common/helpers/CalendarHelper";
import { VisitMotivationTypesEnum_toString } from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import { dateWithRelative } from "../TableCells";
import {
  DAYS_TO_LOOK_AHEAD,
  DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING
} from "../../../pages/MemberDetails/Appointments/constants";

const patientIdPattern = ":memberId";

const AddColumnContainer = styled.div`
  height: 100%;
  position: relative;
`;

const DataContainer = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: rgba(20, 21, 25, 0.9);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

interface UserNameProps {
  id?: string;
  link?: string;
  children?: string;
}
const UserName = ({ id, link, children }: UserNameProps) => {
  if (link) {
    return (
      <Link
        id={id}
        color={"primary"}
        fontSize={"14px"}
        fontWeight={"600"}
        style={{ textDecoration: "none" }}
        href={link}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <Typography
        id={id}
        color={"primary"}
        fontSize={"14px"}
        fontWeight={"bold"}
      >
        {children ?? "N/A"}
      </Typography>
    );
  }
};

const StyledAthenaIcon = styled(Athena)`
  height: 24px;
  width: 24px;
`;

const StyledColumn = styled(Column)`
  align-items: center;
  gap: 0px;
`;

export const CallTooltip = ({
  patient,
  phone
}: {
  patient: MemberTypeInner;
  phone: string;
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  if (!phone) return null;

  const onClick = (e) => {
    e.stopPropagation();
    callPatientModalSelector(dispatch, patient);
  };
  return (
    <Flexbox
      justifyContent="flex-start"
      style={{ cursor: "pointer" }}
      alignItems={"center"}
      onClick={onClick}
    >
      <PhoneNumber width="120px">{maskPhoneNumber(phone)}</PhoneNumber>
      <div style={{ fontSize: "16px" }}>
        <StyledIconButton
          Icon={Call}
          sx={{ mt: "2px" }}
          iconColor={theme.palette.primary.main}
          size={"small"}
          border="square"
          onClick={onClick}
        />
      </div>
    </Flexbox>
  );
};

/**
 * gets dateString (i.e. 2/8/2024) and startEndString (i.e. 3:00 - 4:00 PM EST)
 * @param {String} timezone timezone to use
 * @param {String} startDateString start date ISO string
 * @param {String} endDateString end date ISO string
 */

function getAppointmentDateStartEndString(
  timezone,
  startDateString,
  endDateString
) {
  const start = DateTime.fromISO(startDateString).setZone(timezone);
  const end = DateTime.fromISO(endDateString).setZone(timezone);
  let dateString = "N/A";
  if (
    !isFalsy(timezone) &&
    !isFalsy(start) &&
    !isFalsy(end) &&
    start.isValid &&
    end.isValid
  ) {
    dateString = start.toLocaleString(DateTime.DATE_SHORT);
  }

  const startString = start.toFormat("h:mm");
  const endString = end.toFormat("h:mm a ZZZZ");
  const startEndString = `${startString} - ${endString}`;

  return { dateString, startEndString };
}

function AppointmentCommunicationType({ type }) {
  if ((type as CommunicationTypeEnum) === CommunicationTypeEnum.PHONE) {
    return (
      <DefaultTableCell>
        <Flexbox gap="12px" alignItems="center">
          <PhoneOutlined
            sx={{
              borderRadius: "100px",
              border: "1px solid #D0D5DD",
              background: "#F9FAFB",
              padding: "10px"
            }}
          />
          <Typography variant="body1" color="secondary">
            Phone call
          </Typography>
        </Flexbox>
      </DefaultTableCell>
    );
  }
  if ((type as CommunicationTypeEnum) === CommunicationTypeEnum.VIDEO) {
    return (
      <DefaultTableCell>
        <Flexbox gap="12px" alignItems="center">
          <VideocamOutlined
            sx={{
              borderRadius: "100px",
              border: "1px solid #D0D5DD",
              background: "#F9FAFB",
              padding: "10px"
            }}
          />
          <Typography variant="body1" color="secondary">
            Video call
          </Typography>
        </Flexbox>
      </DefaultTableCell>
    );
  }
  return "N/A";
}

export function getDataFieldColumn(
  name: string,
  tableProps: any,
  columnProps?: any
) {
  const { size = 200 } = columnProps;
  switch (name) {
    case "visitDuration":
      return {
        id: "visitDuration",
        accessor: "duration_diff",
        func: "accessor",
        sortingFn: "numberSorting",
        size,
        cell: ({ getValue, row }: any) => {
          const durationSeconds = getValue();

          const units = ["hours", "minutes"];

          let durationString = "N/A";
          // https://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
          if (durationSeconds) {
            const date = new Date(0);
            date.setSeconds(durationSeconds);
            durationString = date.toISOString().substring(11, 16);
          }

          /*if (start.isValid && end.isValid) {
            const diff = end.diff(start, ["hours", "minutes", "seconds"]);
            durationString = `${diff.hours === 0 ? "00" : diff.hours}:${
              diff.minutes == 0 ? "00" : diff.minutes
            }:${diff.seconds == 0 ? "00" : diff.seconds}`;
          }*/
          return (
            <DefaultTableCell>
              <Typography variant="body1" color="primary">
                {durationString}
              </Typography>
            </DefaultTableCell>
          );
        },
        header: "Duration",
        ...columnProps
      };
    case "visitStartEnd":
      return {
        id: "visitStartEnd",
        accessor: "patient",
        func: "accessor",
        sortingFn: "dateTimeSortingISO",
        size,
        cell: ({ getValue, row }: any) => {
          const value = getValue();

          const attendeeTZ = value?.timezone;

          const start = DateTime.fromISO(
            row?.original?.["calendar_event_start"]
          );
          const end = DateTime.fromISO(row?.original?.["calendar_event_end"]);

          let showDate = false;

          let dateString = "N/A";
          if (!isFalsy(value) && start.isValid && end.isValid) {
            dateString = start.toLocaleString(DateTime.DATE_SHORT);
            showDate = true;
          }
          return (
            <DefaultTableCell>
              <Typography variant="body1" color="primary">
                {dateString}
              </Typography>
              {showDate && (
                <Typography variant="body2" color="secondary">
                  {start.setZone(attendeeTZ).toFormat("h:mm")} -{" "}
                  {end.setZone(attendeeTZ).toFormat("h:mm a ZZZZ")}
                </Typography>
              )}
            </DefaultTableCell>
          );
        },
        header: "Date",
        ...columnProps
      };
    case "appointmentStartEnd":
      return {
        id: "appointmentStartEnd",
        accessor: "event.attendees",
        func: "accessor",
        size,
        cell: ({ getValue, row }: any) => {
          const staffTimezone = tableProps?.staffTimezone;
          const value = getValue();

          const attendee = value?.find(
            (item) => item.attendee_type === "PATIENT"
          );

          const attendeeTZ = attendee?.timezone;

          const timezone = staffTimezone ?? attendeeTZ;

          const { dateString, startEndString } =
            getAppointmentDateStartEndString(
              timezone,
              row?.original?.event?.["startdate"],
              row?.original?.event?.["enddate"]
            );

          return (
            <Flexbox flexWrap="wrap" gap="4px" alignItems="center">
              <Typography variant="body1" color="primary">
                {dateString}
              </Typography>
              <Typography variant="body2" color="secondary">
                {startEndString}
              </Typography>
            </Flexbox>
          );
        },
        header: "Date",
        ...columnProps
      };
    case "calendarVisitsStartEnd":
      return {
        id: "calendarVisitsStartEnd",
        accessor: "attendees",
        func: "accessor",
        size,
        cell: ({ getValue, row }: any) => {
          const staffTimezone = tableProps?.staffTimezone;
          const value = getValue();

          const attendee = value?.find(
            (item) => item.attendee_type === "PATIENT"
          );

          const attendeeTZ = attendee?.timezone;

          const timezone = staffTimezone ?? attendeeTZ;

          const recurrenceFrequency = formatName(
            row?.original?.recurrenceData?.recurrence?.frequency
          );

          const { dateString, startEndString } =
            getAppointmentDateStartEndString(
              timezone,
              row?.original?.["startdate"],
              row?.original?.["enddate"]
            );

          return (
            <Flexbox flexWrap="wrap" gap="4px" alignItems="center">
              <div>
                <Typography variant="body1" color="primary" id={dateString}>
                  {dateString}
                </Typography>
              </div>
              <br />
              <div>
                <Typography variant="body2" color="secondary">
                  {startEndString}
                </Typography>
                {recurrenceFrequency && (
                  <Typography variant="body2" color="secondary">
                    {recurrenceFrequency}
                  </Typography>
                )}
              </div>
            </Flexbox>
          );
        },
        header: "Date",
        ...columnProps
      };
    case "calendarVisitsCreatedModified":
      return {
        id: "calendarVisitsCreatedModified",
        accessor: "",
        func: "accessor",
        size,
        cell: ({ row }: any) => {
          const created = row?.original?.created_by_staff;
          const modified = row?.original?.modified_by_staff;

          const createdName = getNameOrUsername(created, false);
          const modifiedName = getNameOrUsername(modified, false);

          const staffTimezone = tableProps?.staffTimezone;

          const attendee = row?.original?.attendees?.find(
            (item) => item.attendee_type === "PATIENT"
          );

          const attendeeTZ = attendee?.timezone;

          const timezone = staffTimezone ?? attendeeTZ;

          const createdDate = DateTime.fromISO(row?.original?.created_at)
            ?.setZone(timezone)
            ?.toFormat("D h:mm a ZZZZ");

          const modifiedDate = DateTime.fromISO(row?.original?.modified_at)
            ?.setZone(timezone)
            ?.toFormat("D");

          const createdRoles = created?.roles;
          const modifiedRoles = modified?.roles;

          const createdRoleLabels = [];
          const modifiedRoleLabels = [];

          createdRoles?.forEach((role) => {
            createdRoleLabels.push(getRoleLabel(role));
          });

          modifiedRoles?.forEach((role) => {
            modifiedRoleLabels.push(getRoleLabel(role));
          });

          const createdRolesString = createdRoleLabels?.slice(0, 3).join(", ");

          return (
            <Flexbox flexWrap="wrap" gap="4px" alignItems="left" flexDirection={"column"}>
              <Typography variant="body1" color="primary">
                {createdDate}
              </Typography>
              <Typography variant="body1" color="primary">
                {createdName}
              </Typography>
              <Typography variant="body2" color="secondary">
                {createdRolesString}
              </Typography>
              {(row?.original?.created_at !== row?.original?.modified_at) && (
                <>
                  <Typography variant="body2" color="primary">
                    Modified {modifiedDate} by {modifiedName}
                  </Typography>
                </>
              )}
            </Flexbox>
          );
        },
        header: "Created",
        ...columnProps
      };
    // delete this when we switch staff schedule over to appointments
    case "staffCalendarStartEnd":
      return {
        id: "staffCalendarStartEnd",
        accessor: "attendees",
        func: "accessor",
        size,
        cell: ({ getValue, row }: any) => {
          const value = getValue();

          const attendee = value?.find(
            (item) => item.attendee_type === "PATIENT"
          );

          const attendeeTZ = attendee?.timezone;

          const { dateString, startEndString } =
            getAppointmentDateStartEndString(
              attendeeTZ,
              row?.original?.["startdate"],
              row?.original?.["enddate"]
            );

          return (
            <Flexbox flexWrap="wrap" gap="4px" alignItems="center">
              <Typography variant="body1" color="primary">
                {dateString}
              </Typography>
              <Typography variant="body2" color="secondary">
                {startEndString}
              </Typography>
            </Flexbox>
          );
        },
        header: "Date",
        ...columnProps
      };
    case "appointmentType":
      return {
        id: "appointmentType",
        accessor: "communication_type",
        func: "accessor",
        size,
        cell: ({ getValue, row }: any) => {
          const value = getValue();

          return <AppointmentCommunicationType type={value} />;
        },
        header: "Type/Modality",
        ...columnProps
      };
    case "appointmentMemberAttendee":
      return {
        id: "appointmentMemberAttendee",
        // switch this to event.attendees when we switch staff schedule over to appointments
        accessor: "attendees",
        func: "accessor",
        size,
        sortingFn: (a, b) => {
          const attendeesA = a.original.attendees;
          const attendeesB = b.original.attendees;

          const attendeeA = attendeesA?.find(
            (item) => item.attendee_type === "PATIENT"
          );
          const attendeeB = attendeesB?.find(
            (item) => item.attendee_type === "PATIENT"
          );

          const nameA = getNameOrUsername(attendeeA, false);
          const nameB = getNameOrUsername(attendeeB, false);

          return nameA.localeCompare(nameB);
        },
        cell: ({ getValue, row }: any) => {
          const attendees = getValue();
          const attendee = attendees?.find(
            (item) => item.attendee_type === "PATIENT"
          );
          const name = getNameOrUsername(attendee, false);

          const link = `/members/memberId/${attendee?.attendee_id}`;
          return <UserName link={link}>{name}</UserName>;
        },
        header: "Member",
        ...columnProps
      };
    case "appointmentStaffMember":
      return {
        id: "appointmentStaffMember",
        accessor: "staff",
        func: "accessor",
        size,
        sortingFn: (a, b) => {
          const labelA = getNameOrUsername(a.original?.staff, false);
          const labelB = getNameOrUsername(b.original?.staff, false);
          return labelA?.localeCompare(labelB);
        },
        cell: ({ getValue, row }: any) => {
          const value = getValue();
          const { navigate, currentRole } = tableProps;

          const name = getNameOrUsername(value, false);

          const roles = [];
          if (!isFalsy(value?.roles)) {
            value?.roles?.forEach((role) => {
              const roleLabel = getRoleLabel(role);

              // MEMBER_CARE_SPECIALIST role on Adam Admin user account is causing a crash
              if (!isFalsy(roleLabel)) {
                roles.push(roleLabel);
              }
            });
          }

          const rolesString = roles?.slice(0, 3).join(", ");
          const link = `/staff/staffId/${value?.id}/schedule`;
          const canSeeSchedule = canSeeScheduleOnStaffProfile(currentRole);

          return (
            <DefaultTableCell
              style={{ cursor: canSeeSchedule && "pointer" }}
              onClick={() => {
                if (canSeeSchedule) navigate(link);
              }}
            >
              <Typography variant="body1" color="primary">
                {name}
              </Typography>
              <Typography variant="body2" color="secondary">
                {rolesString}
              </Typography>
            </DefaultTableCell>
          );
        },
        header: "Team Member",
        ...columnProps
      };
    case "appointmentActions":
      return {
        header: "Actions",
        id: "appointmentActions",
        accessor: "event.attendees",
        func: "accessor",
        enableSorting: false,
        size,
        cell: ({ getValue, row }: any) => {
          const { dispatch, navigate, currentRole } = tableProps;
          const event_id = row?.original?.event?.["event_id"];
          const staff = row?.original?.staff;
          const recurring = row?.original?.event?.recurring;

          const visit = row?.original?.visit;

          const eventStart = DateTime.fromISO(
            row?.original?.event?.["startdate"]
          );

          // we can't see availability too far into the future
          const shouldShowEdit = eventStart?.diffNow("days").days <= 42;

          // we can't update outcome for upcoming appointments past the end of the current day
          const shouldShowUpdateOutcome =
            eventStart?.diff(DateTime.now().endOf("day"), "days").days < 0;

          // remove the below logic and allow users to update outcome whenever
          // https://copilotiq.slack.com/archives/C03G56CLFT2/p1708985682566629?thread_ts=1708977741.549509&cid=C03G56CLFT2

          // &&
          // don't show update outcome for appointments that have already been updated
          // https://copilotiq.slack.com/archives/C05L024MLNB/p1707758950559839?thread_ts=1707757219.571479&cid=C05L024MLNB
          // !visitCompleted;

          const attendees = getValue();
          const attendee = attendees?.find(
            (item) => item.attendee_type === "PATIENT"
          );
          const attendeeId = attendee?.attendee_id;

          const externalLink = "/members/memberId/:memberId/edit-appointment";

          const link = attendeeId
            ? replace(externalLink, ":memberId", attendeeId)
            : replace(externalLink, patientIdPattern, attendeeId);

          const { dateString, startEndString } =
            getAppointmentDateStartEndString(
              attendee?.timezone,
              row?.original?.event?.["startdate"],
              row?.original?.event?.["enddate"]
            );

          return (
            <Flexbox
              gap="8px"
              alignItems="center"
              justifyContent={"flex-start"}
            >
              {shouldShowUpdateOutcome && (
                <>
                  {ProviderRoles.includes(currentRole) ? (
                    // TBD - remove this when we enable provider appointments
                    // providers should not be able to update TN outcomes
                    // this will give a 400 from the backend "total time must be a positive number"

                    // <Button
                    //   variant="outlined"
                    //   color="primary"
                    //   onClick={() => {
                    //     setNurseProviderModalOpen(true, attendeeId);
                    //   }}
                    // >
                    //   UPDATE OUTCOME
                    // </Button>
                    <></>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        Alert_show({
                          dispatch,
                          id: "updateAppointmentOutcome",
                          title: `Update Appointment on ${dateString} ${startEndString}`,
                          content: (
                            <UpdateAppointmentOutcomeForm
                              eventId={visit?.visit_id}
                              staff_id={staff.id}
                              attendeeId={attendeeId}
                              event={row.original}
                            />
                          ),
                          size: "medium",
                          buttons: []
                        });
                      }}
                    >
                      UPDATE OUTCOME
                    </Button>
                  )}
                </>
              )}

              {shouldShowEdit && (
                <EditOutlined
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(link, {
                      state: {
                        operationType: AppointmentOperationTypeEnum.UPDATE,
                        staff,
                        eventId: event_id,
                        memberId: attendeeId,
                        event: row?.original?.event,
                        recurring,
                        appointmentType: row?.original?.event?.appointment_type
                      }
                    });
                  }}
                />
              )}

              <DeleteOutlineOutlined
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  Alert_show({
                    dispatch,
                    id: "deleteEvent",
                    title: "Cancel appointment",
                    content: (
                      <DeleteRecurringAppointmentForm
                        eventId={event_id}
                        attendeeId={attendeeId}
                        recurring={recurring}
                      />
                    ),
                    type: "warning",
                    size: "medium",
                    buttons: []
                  });
                }}
              />
            </Flexbox>
          );
        },
        ...columnProps
      };
    // delete calendarVisitsActions when we switch staff schedule over to appointments
    case "calendarVisitsActions":
      return {
        header: "Actions",
        id: "calendarVisitsActions",
        accessor: "attendees",
        func: "accessor",
        enableSorting: false,
        size,
        cell: ({ getValue, row }: any) => {
          const { dispatch, navigate, currentRole, user } = tableProps;
          const event_id = row?.original?.["event_id"];
          const current_user_id = user?.user_id;
          const staff = row?.original?.staff;
          const recurring = row?.original?.recurring;
          const isCurrentUserAppointment = staff?.id === current_user_id;
          const isProviderAppt = isProviderAppointment(
            row?.original?.appointment_type
          );
          const isNurseAppt = isNurseAppointment(
            row?.original?.appointment_type
          );

          const visits = row?.original?.visits;

          const eventStart = DateTime.fromISO(row?.original?.["startdate"]);

          const shouldShowEdit =
            (isNurseAppt && canScheduleNurses(currentRole)) ||
            (isProviderAppt && canScheduleProviders(currentRole));

          // show the edit button but disable it if the event is too far into the future
          const disableEdit =
            (eventStart?.diffNow("days").days > DAYS_TO_LOOK_AHEAD &&
              isNurseAppt &&
              canScheduleNurses(currentRole)) ||
            (eventStart?.diffNow("days").days >
              // we can't see availability too far into the future
              DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING - 7 &&
              isProviderAppt &&
              canScheduleProviders(currentRole));

          const shouldShowDelete =
            (isNurseAppt && canScheduleNurses(currentRole)) ||
            (isProviderAppt && canScheduleProviders(currentRole));

          const visitCompleted = visits?.[0]?.status === "COMPLETED";

          // we can't update outcome for upcoming appointments past the end of the current day
          const shouldShowUpdateOutcome =
            eventStart?.diff(DateTime.now().endOf("day"), "days").days < 0 &&
            (isCurrentUserAppointment ||
              (isProviderAppt &&
                canUpdateOutcomeProviderAppointments(currentRole)) ||
              (isNurseAppt && canUpdateOutcomeNurseAppointments(currentRole)));
          const inCareFlow = window.location.href.includes("visits");

          // remove the below logic and allow users to update outcome whenever
          // https://copilotiq.slack.com/archives/C03G56CLFT2/p1708985682566629?thread_ts=1708977741.549509&cid=C03G56CLFT2

          // &&
          // don't show update outcome for appointments that have already been updated
          // https://copilotiq.slack.com/archives/C05L024MLNB/p1707758950559839?thread_ts=1707757219.571479&cid=C05L024MLNB
          // !visitCompleted;

          const attendees = getValue();
          const attendee = attendees?.find(
            (item) => item.attendee_type === "PATIENT"
          );
          const attendeeId = attendee?.attendee_id;

          const externalLink = "/members/memberId/:memberId/edit-appointment";

          const link = attendeeId
            ? replace(externalLink, ":memberId", attendeeId)
            : replace(externalLink, patientIdPattern, attendeeId);

          const { dateString, startEndString } =
            getAppointmentDateStartEndString(
              attendee?.timezone,
              row?.original?.["startdate"],
              row?.original?.["enddate"]
            );

          return (
            <Flexbox
              gap="8px"
              alignItems="center"
              justifyContent={"flex-start"}
            >
              {shouldShowUpdateOutcome &&
                !inCareFlow &&
                !canSeeCareFlow(currentRole) && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      Alert_show({
                        dispatch,
                        id: "updateAppointmentOutcome",
                        title: `Update Appointment on ${dateString} ${startEndString}`,
                        content: (
                          <UpdateAppointmentOutcomeForm
                            eventId={visits?.[0]?.visit_id}
                            staff_id={staff.id}
                            attendeeId={attendeeId}
                            event={row.original}
                          />
                        ),
                        size: "medium",
                        buttons: []
                      });
                    }}
                  >
                    UPDATE OUTCOME
                  </Button>
                )}

              {disableEdit && shouldShowEdit && (
                <CustomTooltip
                  title={
                    <div>
                      You cannot edit an event this far into the future.
                      <br />
                      Please delete this one and reschedule a new one if
                      necessary.
                    </div>
                  }
                >
                  <EditOutlined
                    sx={{ cursor: "not-allowed", color: gray[300] }}
                    onClick={() => {}}
                  />
                </CustomTooltip>
              )}
              {!disableEdit && shouldShowEdit && (
                <EditOutlined
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(link, {
                      state: {
                        operationType: AppointmentOperationTypeEnum.UPDATE,
                        staff,
                        eventId: event_id,
                        memberId: attendeeId,
                        event: row?.original,
                        recurring,
                        appointmentType: row?.original?.appointment_type
                      }
                    });
                  }}
                />
              )}

              {shouldShowDelete && (
                <DeleteOutlineOutlined
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    Alert_show({
                      dispatch,
                      id: "deleteEvent",
                      title: "Cancel appointment",
                      content: (
                        <DeleteRecurringAppointmentForm
                          eventId={event_id}
                          attendeeId={attendeeId}
                          recurring={recurring}
                        />
                      ),
                      type: "warning",
                      size: "medium",
                      buttons: []
                    });
                  }}
                />
              )}
            </Flexbox>
          );
        },
        ...columnProps
      };
    case "pastAppointmentActions":
      return {
        header: "Actions",
        id: "pastAppointmentActions",
        accessor: "",
        enableSorting: false,
        func: "accessor",
        size,
        cell: ({ row }: any) => {
          const { dispatch, currentRole } = tableProps;
          const visit = row?.original?.visit;
          const event_id = visit?.["visit_id"];
          const staff_id = row?.original?.staff?.id;
          const attendee = row?.original?.event?.attendees?.[0];
          const attendee_id = attendee?.attendee_id;

          const shouldShowUpdateOutcome = !canSeeCareFlow(currentRole);

          const { dateString, startEndString } =
            getAppointmentDateStartEndString(
              attendee?.timezone,
              row?.original?.event?.["startdate"],
              row?.original?.event?.["enddate"]
            );

          return (
            <Flexbox gap="8px" alignItems="center">
              {shouldShowUpdateOutcome && (
                <>
                  {ProviderRoles.includes(currentRole) ? (
                    // TBD - remove this when we enable provider appointments
                    // providers should not be able to update TN outcomes
                    // this will give a 400 from the backend "total time must be a positive number"

                    // <Button
                    //   variant="outlined"
                    //   color="primary"
                    //   onClick={() => {
                    //     setNurseProviderModalOpen(
                    //       true,
                    //       attendee_id,
                    //       encounterStart
                    //     );
                    //   }}
                    // >
                    //   UPDATE OUTCOME
                    // </Button>
                    <></>
                  ) : (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        Alert_show({
                          dispatch,
                          id: "updateAppointmentOutcome",
                          title: `Update Appointment on ${dateString} ${startEndString}`,
                          content: (
                            <UpdateAppointmentOutcomeForm
                              eventId={event_id}
                              staff_id={staff_id}
                              attendeeId={attendee_id}
                              event={row.original.event}
                            />
                          ),
                          size: "medium",
                          buttons: []
                        });
                      }}
                    >
                      UPDATE OUTCOME
                    </Button>
                  )}
                </>
              )}
            </Flexbox>
          );
        },
        ...columnProps
      };
    case "pastCalendarVisitsActions":
      return {
        header: "Actions",
        id: "pastCalendarVisitsActions",
        accessor: "",
        enableSorting: false,
        func: "accessor",
        size,
        cell: ({ row }: any) => {
          const { dispatch, currentRole, currentUser } = tableProps;
          const visit = row?.original?.visits?.[0];
          const event_id = visit?.["visit_id"];
          const staff_id = row?.original?.staff?.id;
          const current_user_id = currentUser?.user_id;
          const isCurrentUserAppointment = staff_id === current_user_id;
          const attendee_id = row?.original?.attendees?.[0]?.attendee_id;
          const isProviderAppt = isProviderAppointment(
            row?.original?.appointment_type
          );
          const isNurseAppt = isNurseAppointment(
            row?.original?.appointment_type
          );
          const encounterStart = row?.original?.startdate;

          // remove the below logic and allow users to update outcome whenever
          // https://copilotiq.slack.com/archives/C03G56CLFT2/p1708985682566629?thread_ts=1708977741.549509&cid=C03G56CLFT2
          const shouldShowUpdateOutcome =
            (isCurrentUserAppointment &&
              !canSeeCareFlow(currentRole)) ||
            (isProviderAppt &&
              canUpdateOutcomeProviderAppointments(currentRole)) ||
            (isNurseAppt &&
              canUpdateOutcomeNurseAppointments(currentRole) &&
              !canSeeCareFlow(currentRole));

          // don't show update outcome for appointments that have already been updated
          // https://copilotiq.slack.com/archives/C05L024MLNB/p1707758950559839?thread_ts=1707757219.571479&cid=C05L024MLNB
          // const shouldShowUpdateOutcome = !(
          //   visit?.status === VisitStatusEnum.COMPLETED ||
          //   visit?.status === VisitStatusEnum.TIME_SUBMITTED
          // );

          return (
            <Flexbox gap="8px" alignItems="center">
              {shouldShowUpdateOutcome && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    Alert_show({
                      dispatch,
                      id: "updateAppointmentOutcome",
                      title: "Update Appointment Outcome",
                      content: (
                        <UpdateAppointmentOutcomeForm
                          visit={visit}
                          eventId={event_id}
                          staff_id={staff_id}
                          attendeeId={attendee_id}
                          event={row.original}
                        />
                      ),
                      size: "medium",
                      buttons: []
                    });
                  }}
                >
                  UPDATE OUTCOME
                </Button>
              )}
            </Flexbox>
          );
        },
        ...columnProps
      };
    case "pastAppointmentOutcome":
      return {
        header: "Outcome",
        id: "pastAppointmentOutcome",
        accessor: "visit",
        func: "accessor",
        size,
        cell: ({ getValue }: any) => {
          const value = getValue();
          const outcome = formatName(value?.disposition);

          return (
            <DefaultTableCell>
              <Typography variant="body1" color="primary">
                {outcome}
              </Typography>
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "pastCalendarVisitsOutcome":
      return {
        header: "Outcome",
        id: "pastCalendarVisitsOutcome",
        accessor: "visits",
        func: "accessor",
        size,
        cell: ({ getValue, row }: any) => {
          const value = getValue();

          const startDateISOString = row?.original?.startdate;

          const outcome = getStatusOrDispositionFromMultipleVisits(
            value,
            startDateISOString,
            false
          );

          const displayValue = formatName(outcome);

          return (
            <DefaultTableCell>
              <Typography variant="body1" color="primary">
                {displayValue}
              </Typography>
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "agreementsInformation":
      return {
        id: "agreementsInformation",
        accessor: "requested_date",
        func: "accessor",
        size,
        cell: ({ getValue }: any) => {
          const value = getValue();
          const date = DateTime.fromISO(value);
          const displayValue = date.isValid
            ? `Requested on ${date.toFormat("MMM dd, yyyy")}`
            : "";

          return <DefaultTableCell>{displayValue}</DefaultTableCell>;
        },
        header: "Information",
        ...columnProps
      };
    case "agreementsFormName":
      return {
        id: "agreementsFormName",
        accessor: "name",
        func: "accessor",
        size,
        cell: ({ row, getValue }: any) => {
          const value = getValue();
          return (
            <DefaultTableCell>
              <Typography variant="body1">{value ?? "N/A"}</Typography>
            </DefaultTableCell>
          );
        },
        header: "Information",
        ...columnProps
      };
    case "agreementsSigned":
      return {
        id: "agreementsSigned",
        accessor: "accepted_on",
        func: "accessor",
        size,
        cell: ({ row }: any) => {
          const { accepted_on, expiration, attestation_type, attestation } =
            row.original;
          const dateAccepted = DateTime.fromISO(accepted_on);
          const displayValueAccepted = dateAccepted.isValid
            ? dateAccepted.toFormat("MMM dd, yyyy")
            : "N/A";

          const is_completed_attestation = attestation_type && accepted_on;
          const qualified = attestation_type && attestation?.is_qualified;
          const dateExpires = DateTime.fromISO(expiration);

          let isExpired = false;
          if (dateExpires.isValid) {
            const diffDays = dateExpires.diff(DateTime.now(), "days");
            isExpired = diffDays.days <= 0;
          }

          return (
            <DefaultTableCell>
              <Typography variant="body1">
                Signed on {displayValueAccepted}
              </Typography>
              {dateExpires.isValid && (
                <>
                  <br />
                  <Typography variant="body1">
                    {isExpired ? "Expired" : "Expires"} on{" "}
                    {dateExpires.toFormat("MMM dd, yyyy")}
                  </Typography>
                </>
              )}
              {is_completed_attestation && qualified ? (
                <Flexbox alignItems={"center"} gap={"5px"}>
                  <CheckCircle color={"success"} />
                  <Typography variant="body1">Member Qualified</Typography>
                </Flexbox>
              ) : (
                ""
              )}
              {is_completed_attestation && !qualified ? (
                <Flexbox alignItems={"center"} gap={"5px"}>
                  <Cancel color={"error"} />
                  <Typography variant="body1">Member Not Qualified</Typography>
                </Flexbox>
              ) : (
                ""
              )}
            </DefaultTableCell>
          );
        },
        header: "Information",
        ...columnProps
      };
    case "agreementAnnualIncome":
      return {
        id: "agreementAnnualIncome",
        accessor: "attestation.attestation.annual_income_category",
        func: "accessor",
        size,
        cell: ({ getValue }: any) => {
          const value = getValue();
          const displayValue = value ?? "N/A";
          return <DefaultTableCell>{displayValue}</DefaultTableCell>;
        },
        header: "Annual Income",
        ...columnProps
      };
    case "agreementHouseholdSize":
      return {
        id: "agreementHouseholdSize",
        accessor: "attestation.attestation.household_size",
        func: "accessor",
        size,
        cell: ({ getValue }: any) => {
          const value = getValue();
          const displayValue = value ?? "N/A";
          return <DefaultTableCell>{displayValue}</DefaultTableCell>;
        },
        header: "Household Size",
        ...columnProps
      };
    case "downloadOrderPDF":
      return {
        id: "downloadOrderPDF",
        accessor: "",
        func: "accessor",
        header: "Order PDF",
        size: 250,
        cell: ({ row }: any) => {
          const { s3_file_path } = row.original.order;

          const { handleDownloadPDF } = tableProps;
          return (
            <Button
              variant="outlined"
              startIcon={<Download />}
              color="primary"
              disabled={isFalsy(s3_file_path)}
              onClick={() => handleDownloadPDF(row.original.order)}
            >
              Download PDF
            </Button>
          );
        }
      };
    case "agreementsCompletedActions":
      return {
        id: "agreementsCompletedActions",
        accessor: "",
        func: "accessor",
        size: 250,
        cell: ({ row, column: { id } }: any) => {
          const { expiration, s3_file_path } = row.original;

          let isExpiring = false;
          let isExpired = false;
          if (expiration) {
            const dateExpires = DateTime.fromISO(expiration);
            const diffDays = dateExpires.diff(DateTime.now(), "days");
            isExpiring = diffDays.days > 0 && diffDays.days < 30;
            isExpired = diffDays.days <= 0;
          }

          const { handleDownloadPDF } = tableProps;

          return (
            <Row>
              {s3_file_path && (
                <CustomTooltip
                  backgroundColor="white"
                  placement="left"
                  title={
                    <Column>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleDownloadPDF(row.original)}
                      >
                        Download PDF
                      </Button>
                    </Column>
                  }
                >
                  <ThreeDotsIcon id={id} />
                </CustomTooltip>
              )}
              {isExpiring && (
                <Chip
                  sx={{ marginLeft: 2, backgroundColor: warning[50] }}
                  color="warning"
                  label={"Soon to expire"}
                  variant="outlined"
                />
              )}
              {isExpired && (
                <Chip
                  sx={{ marginLeft: 2, backgroundColor: error[100] }}
                  color="error"
                  label={"Expired"}
                  variant="outlined"
                />
              )}
            </Row>
          );
        },
        header: "Actions",
        ...columnProps
      };
    case "fullname":
      return {
        id: "fullname",
        accessor: "user.fullname",
        func: "accessor",
        cell: ({ getValue, row }: any) => {
          const userId = row?.original?.user?.["user_id"];
          const patientId = row?.original?.patient?.["patient_id"];

          const { externalLink } = tableProps;

          const value = getValue();

          let link = userId
            ? replace(externalLink, ":userId", userId)
            : replace(externalLink, patientIdPattern, patientId);

          if (link.includes(":nurseId") && userId) {
            link = replace(externalLink, ":nurseId", userId);
          }
          if (link.includes(":staffId") && userId) {
            link = replace(externalLink, ":staffId", userId);
          }

          return <UserName link={link}>{value}</UserName>;
        },
        header: "Name",
        sortDescFirst: true,
        size: 200,
        enableColumnFilter: true,
        ...columnProps
      };
    case "name":
      return {
        id: "name",
        accessor: "patient",
        func: "accessor",
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const userId = row?.original?.patient?.["user_id"];
          const patientId = row?.original?.patient?.["patient_id"];
          const { externalLink } = tableProps;
          const link = userId
            ? replace(externalLink, ":userId", userId)
            : replace(externalLink, patientIdPattern, patientId);

          const value = getValue();
          let displayValue = getValue();
          if (typeof value === "object" && value !== null) {
            displayValue = getNameOrUsername(value);
          }
          return <UserName link={link}>{displayValue}</UserName>;
        },
        header: "Name",
        sortDescFirst: true,
        size: 200,
        enableColumnFilter: true,
        ...columnProps
      };
    case "birthdate":
      return {
        id: "birthdate",
        accessor: "patient.birthdate",
        func: "accessor",
        size,
        cell: (info: any) => {
          const value = info.getValue();
          let date = undefined;
          if (value) {
            date = DateTime.fromFormat(value, "MM/dd/yyyy");
          }
          return (
            <DefaultTableCell>{dateWithRelative({ date })}</DefaultTableCell>
          );
        },
        header: "Date of Birth",
        sortingFn: "dateTimeSortingMMDDYYYY",
        ...columnProps
      };
    case "phone":
      return {
        id: "phone",
        accessor: "patient",
        func: "accessor",
        size: 150,
        sortingFn: (a, b) => {
          const labelA =
            a.original?.patient?.mobile ?? a.original?.patient?.phone;
          const labelB =
            b.original?.patient?.mobile ?? b.original?.patient?.phone;

          return labelA?.localeCompare(labelB);
        },
        cell: ({ getValue }) => {
          const value = getValue();
          // we are displaying the mobile number if it exists, otherwise the phone number
          const phone = value?.mobile ?? value?.phone;
          const { currentRole } = tableProps;

          if (canCallMember(currentRole)) {
            return <CallTooltip patient={value} phone={phone} />;
          } else return <PhoneNumber>{maskPhoneNumber(phone)}</PhoneNumber>;
        },
        header: "Mobile",
        ...columnProps
      };
    case "status":
      return {
        id: "status",
        accessor: "patient.status",
        func: "accessor",
        size: 225,
        cell: memo((info: any) => {
          const value = info.getValue();
          return <StatusBadge status={value} />;
        }),
        header: () => {
          return (
            <DefaultTableHeader style={{ display: "flex" }}>
              Member Status&nbsp;
              <CustomTooltip
                title={
                  <>
                    Pending members have their devices ordered (no
                    readings/encounters).
                    <br />
                    Active members have at least one reading and encounter
                    <br />
                    Inactive members have not had any readings or encounters in
                    30 days or more
                    <br />
                    Canceled members are those that are confirmed canceled by
                    Retention.
                  </>
                }
              >
                <HelpIcon />
              </CustomTooltip>
            </DefaultTableHeader>
          );
        },
        ...columnProps
      };
    case "reportMemberCount":
      return {
        id: "reportMemberCount",
        accessor: "patient_count",
        func: "accessor",
        cell: memo((info: any) => {
          const value = info.getValue();
          return <DefaultTableCell>{value ?? "N/A"}</DefaultTableCell>;
        }),
        header: () => {
          return (
            <DefaultTableHeader style={{ display: "flex" }}>
              Member Count&nbsp;
              <CustomTooltip title="Member count is the number of unique members that time was submitted for">
                <HelpIcon />
              </CustomTooltip>
            </DefaultTableHeader>
          );
        },
        ...columnProps
      };
    case "nurseAssigned":
      return {
        id: "nurseAssigned",
        accessor: "assigned_nurse",
        func: "accessor",
        size,
        cell: (info: any) => {
          const value = info.getValue();
          let userNameString = "N/A";

          if (value) userNameString = getNameOrUsername(value);
          return <DefaultTableCell>{userNameString}</DefaultTableCell>;
        },
        header: "Nurse",
        ...columnProps
      };
    case "patientActivityLastNurseEncounter":
      return {
        id: "patientActivityLastNurseEncounter",
        accessor: "last_nurse_encounter",
        func: "accessor",
        size,
        sortingFn: "dateTimeSortingISO",
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          let date;
          if (value) {
            date = DateTime.fromISO(value);
          }

          return dateWithRelative({ date });
        },
        header: "Last Nurse Encounter",
        ...columnProps
      };
    case "patientActivityLastReading":
      return {
        id: "patientActivityLastReading",
        accessor: "last_reading",
        func: "accessor",
        sortingFn: "dateTimeSortingISO",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          let date;
          if (value) {
            date = DateTime.fromISO(value);
          }

          return dateWithRelative({ date });
        },
        header: "Last Reading",
        ...columnProps
      };
    case "encountersDataLastEncounter":
      return {
        id: "encountersDataLastEncounter",
        accessor: "lastEncounter",
        func: "accessor",
        sortingFn: "dateTimeSortingISO",
        size,
        cell: ({ getValue }: any) => {
          const value = getValue();
          const lastEncounter = getLastEncounter(value);
          let date;
          if (lastEncounter) {
            date = DateTime.fromISO(lastEncounter);
          }

          return dateWithRelative({ date });
        },
        header: "Last Encounter",
        ...columnProps
      };
    case "nurseReportDataLastEncounter":
      return {
        id: "nurseReportDataLastEncounter",
        accessor: "encounters.last_nurse_encounter_date",
        func: "accessor",
        sortingFn: "dateTimeSortingISO",
        size,
        cell: ({ getValue }: any) => {
          const last_nurse_encounter_date = getValue();

          let date;
          if (last_nurse_encounter_date) {
            date = DateTime.fromISO(last_nurse_encounter_date);
          }

          return dateWithRelative({ date });
        },
        header: "Last Encounter",
        ...columnProps
      };
    case "nurseReportDataNextNurseAppointment":
      return {
        id: "nurseReportDataNextNurseAppointment",
        accessor: "appointments.next_nurse_appointment_date",
        func: "accessor",
        sortingFn: "dateTimeSortingISO",
        size,
        cell: ({ getValue }: any) => {
          const value = getValue();
          let str = "N/A";
          if (value) {
            const lastEncounterDate = DateTime.fromISO(value);
            str = lastEncounterDate.toLocaleString(DateTime.DATE_SHORT);
          }

          return <DefaultTableCell>{str}</DefaultTableCell>;
        },
        header: "Next Nurse Appointment",
        ...columnProps
      };
    case "nurseReportDataNextProviderAppointment":
      return {
        id: "nurseReportDataNextProviderAppointment",
        accessor: "appointments.next_provider_appointment_date",
        func: "accessor",
        sortingFn: "dateTimeSortingISO",
        size,
        cell: ({ getValue }: any) => {
          const value = getValue();
          let str = "N/A";
          if (value) {
            const lastEncounterDate = DateTime.fromISO(value);
            str = lastEncounterDate.toLocaleString(DateTime.DATE_SHORT);
          }

          return <DefaultTableCell>{str}</DefaultTableCell>;
        },
        header: "Next Provider Appointment",
        ...columnProps
      };
    case "carerPatientSince":
      return {
        id: "carerPatientSince",
        accessor: "patient.enrolled",
        func: "accessor",
        sortingFn: "dateTimeSortingISO",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const enrolled = getValue();
          let date;
          if (enrolled !== undefined) {
            date = DateTime.fromISO(enrolled);
          }

          return dateWithRelative({ date });
        },
        header: "Member Since",
        ...columnProps
      };
    case "patientSince":
      return {
        id: "patientSince",
        accessor: "patient.enrolled",
        func: "accessor",
        sortingFn: "dateTimeSortingISO",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const enrolled = getValue();

          let date;
          if (enrolled) {
            date = DateTime.fromISO(enrolled);
          }

          return dateWithRelative({ date });
        },
        header: "Member Since",
        ...columnProps
      };
    case "carerPatientTableName":
      return {
        header: "Name",
        id: "carerPatientTableName",
        accessor: "patient.fullname",
        func: "accessor",
        enableColumnFilter: true,
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const patientId = row?.original?.patient?.["patient_id"];
          let displayValue = getValue();
          const { externalLink } = tableProps;
          const link = replace(externalLink, patientIdPattern, patientId);

          return <UserName link={link}>{displayValue}</UserName>;
        },
        ...columnProps
      };
    case "carerPatientTableStatus":
      return {
        header: () => {
          return (
            <DefaultTableHeader style={{ display: "flex" }}>
              Member Status&nbsp;
              <CustomTooltip
                title={
                  <>
                    Pending members have their devices ordered (no
                    readings/encounters).
                    <br />
                    Active members have at least one reading and encounter
                  </>
                }
              >
                <HelpIcon />
              </CustomTooltip>
            </DefaultTableHeader>
          );
        },
        id: "carerPatientTableStatus",
        accessor: "patient.status",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          try {
            let value = getValue();
            return <StatusBadge status={value} />;
          } catch (error) {}
        },
        ...columnProps
      };
    case "carerPatientTableBirthdate":
      return {
        header: "Date of Birth",
        id: "carerPatientTableBirthdate",
        accessor: "patient.birthdate",
        func: "accessor",
        size,
        sortingFn: "dateTimeSortingMMDDYYYY",
        cell: ({ getValue, row, column: { id }, table }: any) => {
          let value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "carerPatientTableReadings":
      return {
        header: "Reading Days",
        id: "carerPatientTableReadings",
        accessor: "readings",
        func: "accessor",
        size,
        sortingFn: (a, b) => {
          function getReadingDays(row) {
            const compliancePeriods =
              row.original.readings?.compliance_periods || [];
            const patientTimezone = row?.original?.patient?.timezone;

            const now = DateTime.now().setZone(patientTimezone);

            let readingDays = 0;
            compliancePeriods.forEach((item: any) => {
              const { start_date, end_date } = item;
              const startDate = DateTime.fromISO(start_date)
                .startOf("day")
                .setZone(patientTimezone);
              const endDate = DateTime.fromISO(end_date)
                .endOf("day")
                .setZone(patientTimezone);

              if (startDate < now && now < endDate) {
                readingDays = item.reading_days;
              }
            });

            return readingDays;
          }

          const readingDaysA = getReadingDays(a);
          const readingDaysB = getReadingDays(b);

          return readingDaysB - readingDaysA;
        },
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const readings = getValue();
          const value = readings?.compliance_periods || [];

          const { patient } = row.original;

          const now = DateTime.now().setZone(patient.timezone);

          let readingDays = 0;
          value.forEach((item: any) => {
            const { start_date, end_date } = item;
            const startDate = DateTime.fromISO(start_date)
              .startOf("day")
              .setZone(patient.timezone);
            const endDate = DateTime.fromISO(end_date)
              .endOf("day")
              .setZone(patient.timezone);

            if (startDate < now && now < endDate) {
              readingDays = item.reading_days;
            }
          });

          const totalReadings = 16;

          return (
            <DataContainer>
              <span
                style={
                  readingDays < totalReadings
                    ? { color: "#FF5364" }
                    : { color: "green" }
                }
              >
                {readingDays}
              </span>
              /{totalReadings}
            </DataContainer>
          );
        },
        ...columnProps
      };
    case "connectedMTD":
      return {
        header: () => {
          return (
            <div style={{ display: "flex" }}>
              Connected Time
              <br />
              (MTD)
            </div>
          );
        },
        id: "connectedMTD",
        accessor: "encounters.connected_time_month_to_date_in_minutes",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return (
            <DefaultTableCell>
              {!isNaN(value) ? `${value} min` : "N/A"}
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "nonDeviceSetupMTD":
      return {
        header: () => {
          return (
            <Flexbox gap="2px">
              Time Spent
              <br />
              (MTD)&nbsp;
              <CustomTooltip title="Nurse Followup times submitted, both connected and not connected">
                <HelpIcon />
              </CustomTooltip>
            </Flexbox>
          );
        },
        id: "nonDeviceSetupMTD",
        accessor: "encounters.non_device_setup_time_month_to_date_in_minutes",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return (
            <DefaultTableCell>
              {!isNaN(value) ? `${value} min` : "N/A"}
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "carerPatientTableDelivered":
      return {
        header: "Delivered",
        id: "carerPatientTableDelivered",
        accessor: "most_recent_order",
        sortingFn: "dateTimeSortingISO",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const most_recent_order = getValue();
          const value = most_recent_order?.shipping?.delivered;

          let date;
          if (value) {
            date = DateTime.fromISO(value);
          }

          return dateWithRelative({ date });
        },
        ...columnProps
      };
    case "carerPatientTableProviderLastEncounter":
      return {
        header: "Provider Last Encounter",
        id: "carerPatientTableProviderLastEncounter",
        accessor: "encounters.last_provider_encounter_date",
        sortingFn: "dateTimeSortingISO",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          let date;
          if (value !== undefined) {
            date = DateTime.fromISO(value);
          }

          return dateWithRelative({ date });
        },
        ...columnProps
      };

    case "order_id":
      return {
        header: "Order No.",
        id: "order_id",
        accessor: "order.order_id",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();

          return (
            <UserName id="orderLink" link={`/orders/${value}`}>
              {value}
            </UserName>
          );
        },
        ...columnProps
      };
    case "orderPatientName":
      return {
        header: "Name",
        id: "orderPatientName",
        accessor: "patient",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          const patientId = row?.original?.patient?.["patient_id"];
          let displayValue = getNameOrUsername(value);
          const { externalLink } = tableProps;
          const link = replace(externalLink, patientIdPattern, patientId);

          return <UserName link={link}>{displayValue}</UserName>;
        },
        ...columnProps
      };
    case "orderPatientStatus":
      return {
        header: () => {
          return (
            <DefaultTableHeader style={{ display: "flex" }}>
              Member Status&nbsp;
              <CustomTooltip
                title={
                  <>
                    Pending members have their devices ordered (no
                    readings/encounters).
                    <br />
                    Active members have at least one reading and encounter
                  </>
                }
              >
                <HelpIcon />
              </CustomTooltip>
            </DefaultTableHeader>
          );
        },
        id: "orderPatientStatus",
        accessor: "patient",
        func: "accessor",
        size,
        cell: memo(({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          // need to do it like this instead of in the accessor or it will break the table :(
          const status = value?.status;
          return status ? <StatusBadge status={status} /> : null;
        }),
        ...columnProps
      };
    case "orderType":
      return {
        header: "Order Type",
        id: "orderType",
        accessor: "order.order_type",
        func: "accessor",
        size: 110,
        cell: memo(({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return <StatusBadge status={value} hideDot={true} />;
        }),
        enableColumnFilter: true,
        ...columnProps
      };
    case "orderStatus":
      return {
        header: "Order Status",
        id: "orderStatus",
        accessor: "order.status.sm_status",
        func: "accessor",
        size,
        cell: memo(({ getValue }: any) => {
          const status = getValue();
          return (
            <>{status && <StatusBadge status={status} hideDot={true} />}</>
          );
        }),
        enableColumnFilter: true,
        ...columnProps
      };
    case "goalAssessmentAssessmentStatus":
      return {
        header: "Status",
        id: "goalAssessmentAssessmentStatus",
        accessor: "assessment_status",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return (
            <DefaultTableCell>
              {getPrettyGoalAssessmentStatus(value)}
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "goalAssessmentCurrentValue":
      return {
        header: "Value",
        id: "goalAssessmentCurrentValue",
        accessor: "current_value",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "goalAssessmentNotes":
      return {
        header: "Notes",
        id: "goalAssessmentNotes",
        accessor: "notes",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "goalAssessmentAssessedOn":
      return {
        header: "Assessed On",
        id: "goalAssessmentAssessedOn",
        accessor: "assessed_on",
        func: "accessor",
        sortingFn: "dateTimeSortingSQL",
        size,
        cell: ({ getValue, row, accessor }: any) => {
          const value = getValue();
          let date = DateTime.fromSQL(value);
          if (date?.isValid === false) date = DateTime.fromISO(value);

          return (
            <DefaultTableCell>{dateWithRelative({ date })}</DefaultTableCell>
          );
        },
        enableColumnFilter: true,
        ...columnProps
      };
    case "relationshipNotesNote":
      return {
        header: "Note",
        id: "relationshipNotesNote",
        accessor: "note",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "relationshipNotesCategory":
      return {
        header: "Category",
        id: "relationshipNotesCategory",
        accessor: "category",
        func: "accessor",
        size,
        cell: memo(({ getValue }: any) => {
          const category = getValue();
          return (
            <>{category && <StatusBadge status={category} hideDot={true} />}</>
          );
        }),
        enableColumnFilter: true,
        ...columnProps
      };
    case "relationshipNotesLastModified":
      return {
        header: "Last Updated",
        id: "relationshipNotesLastModified",
        accessor: "last_modified",
        func: "accessor",
        sortingFn: "dateTimeSortingSQL",
        size,
        cell: ({ getValue, row, accessor }: any) => {
          const value = getValue();
          let date = DateTime.fromSQL(value);
          if (date?.isValid === false) date = DateTime.fromISO(value);

          return (
            <DefaultTableCell>{dateWithRelative({ date })}</DefaultTableCell>
          );
        },
        enableColumnFilter: true,
        ...columnProps
      };
    case "relationshipNotesActions":
      return {
        header: "Actions",
        id: "relationshipNotesActions",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const { dispatch, member, isEditDeleteAllowed } = tableProps;
          const relationshipNote = row?.original;

          if (!isEditDeleteAllowed) return <></>;

          return (
            <Flexbox
              gap="8px"
              alignItems="center"
              justifyContent={"flex-start"}
            >
              <EditOutlined
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  Alert_show({
                    dispatch,
                    id: "editRelationshipNote",
                    title: "Edit Relationship Note",
                    content: (
                      <EditRelationshipNoteForm
                        member={member}
                        relationshipNote={relationshipNote}
                      />
                    ),
                    size: "large",
                    buttons: []
                  });
                }}
              />
              <DeleteOutlineOutlined
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  Alert_show({
                    dispatch,
                    id: "deleteEvent",
                    title: "Delete Relationship Note",
                    content: (
                      <DeleteRelationshipNoteForm
                        relationshipNote={relationshipNote}
                      />
                    ),
                    type: "warning",
                    size: "large",
                    buttons: []
                  });
                }}
              />
            </Flexbox>
          );
        },
        enableColumnFilter: true,
        ...columnProps
      };
    case "encounterDurationEditable":
      return {
        header: "Duration",
        id: "encounterDurationEditable",
        accessor: "time.duration",
        func: "accessor",
        sortingFn: "numberSorting",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const {
            currentUserId,
            setSelectedEncounter,
            setEditPastEncounterModalVisible
          } = tableProps;
          const value = getValue();

          const isProviderEncounter = hasProviderRole(
            row?.original?.encounter?.submitter_roles ??
              row?.original?.visit?.submitted_by_roles ??
              row?.original?.user?.roles
          );

          const editable =
            columnProps?.editable &&
            // use submitted_by instead of created_by, created_by is the user who created the encounter which could be different
            // for add past encounter
            (row?.original?.encounter?.submitted_by === currentUserId ||
              row?.original?.encounter?.requested_by === currentUserId);
          // (row?.original?.visit?.submitted_by === currentUserId ||
          //  && !isProviderEncounter;

          if (isProviderEncounter && row?.original?.encounter) {
            const complexityParsed =
              row?.original?.encounter?.complexity?.replace("CPT_", "");
            const selectedEncounter = {
              member_name: row?.original?.fullname,
              total_time: row?.original?.encounter?.duration,
              visit_type: row?.original?.encounter?.reason,
              modality: row?.original?.encounter?.modality,
              complexity: complexityParsed,
              encounter_id: row?.original?.encounter?.encounter_id
            };

            return (
              <Flexbox alignItems="center">
                <DefaultTableCell>{value}</DefaultTableCell>
                {editable && isEditDeleteAllowed(row?.original?.starts_on) && (
                  <StyledIconButton
                    Icon={Edit}
                    sx={{ marginLeft: 1 }}
                    iconColor={blue[700]}
                    onClick={() => {
                      setSelectedEncounter(selectedEncounter);
                      setEditPastEncounterModalVisible(true);
                    }}
                    color={"transparent"}
                    size={"small"}
                  />
                )}
              </Flexbox>
            );
          }

          if (!value) {
            return <DefaultTableCell>N/A</DefaultTableCell>;
          }
          return (
            <EditEncounterCell
              editable={editable}
              value={value}
              id={id}
              row={row}
            />
          );
        },
        ...columnProps
      };
    case "encounterDurationReport":
      return {
        header: "Duration (min)",
        id: "encounterDurationReport",
        accessor: "encounter.duration",
        sortingFn: "numberSorting",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();

          const totalTime = row?.original?.visit?.total_time;

          if (totalTime > 0) {
            return <DefaultTableCell>{totalTime}</DefaultTableCell>;
          }

          if (!value) {
            return <DefaultTableCell>N/A</DefaultTableCell>;
          }
          return (
            <EditEncounterCell
              editable={false}
              value={value}
              id={id}
              row={row}
            />
          );
        },
        ...columnProps
      };
    case "encounterTotalDuration":
      return {
        header: "Total Duration",
        id: "encounterTotalDuration",
        accessor: "visit",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();

          const totalTime = value?.total_time;

          if (totalTime) {
            return <DefaultTableCell>{totalTime}</DefaultTableCell>;
          } else {
            return <DefaultTableCell>N/A</DefaultTableCell>;
          }
        },
        ...columnProps
      };
    case "encounterTalkDuration":
      return {
        header: "Talk Duration",
        id: "encounterTalkDuration",
        sortingFn: "numberSorting",
        accessor: "visit",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();

          const talkTime = value?.talk_time;

          if (talkTime) {
            return <DefaultTableCell>{talkTime}</DefaultTableCell>;
          } else {
            return <DefaultTableCell>N/A</DefaultTableCell>;
          }
        },
        ...columnProps
      };
    case "encounterComplexity":
      return {
        header: "Complexity",
        id: "encounterComplexity",
        accessor: "encounter.complexity",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();

          if (!value) {
            return <DefaultTableCell>N/A</DefaultTableCell>;
          }
          return <DefaultTableCell>{formatName(value)}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "encounterType":
      return {
        header: "Type",
        id: "encounterType",
        accessor: "type",
        func: "accessor",
        size,
        cell: ({ getValue, row }: any) => {
          const value = getValue();
          const motivationReason = row?.original?.visit?.motivation_reason;

          return (
            <DefaultTableCell>
              <Typography variant="body1">{value}</Typography>
              {!isFalsy(motivationReason) && (
                <Typography variant="body2">
                  {VisitMotivationTypesEnum_toString(motivationReason)}
                </Typography>
              )}
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "encounterModality":
      return {
        header: "Modality",
        id: "encounterModality",
        accessor: "encounter.modality",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return (
            <DefaultTableCell data-testid={value}>
              {formatName(value)}
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "totalEncounterDuration":
      return {
        header: "Total Duration",
        id: "totalEncounterDuration",
        accessor: "totalDuration",
        func: "accessor",
        sortingFn: "numberSorting",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "encounterDate":
      return {
        header: "Date",
        id: "encounterDate",
        // we need this to be "" because encounter visits could return a null value for this accessor
        accessor: "",
        func: "accessor",
        size,
        sortingFn: "dateTimeSortingISO",
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = row?.original?.encounter?.starts_on;
          const date = DateTime.fromISO(value);

          return dateWithRelative({ date });
        },
        ...columnProps
      };
    case "encounterDateV2":
      return {
        header: "Date",
        id: "encounterDateV2",
        // accessor for PAB encounters endpoint
        accessor: "encounter.starts_on",
        // accessor for encounter visits endpoint - comment this back in when we want to use this endpoint
        // accessor: "starts_on",
        func: "accessor",
        sortingFn: "dateTimeSortingISO",
        size,
        cell: ({ getValue }: any) => {
          const value = getValue();
          const date = DateTime.fromISO(value);

          return dateWithRelative({ date });
        },
        ...columnProps
      };
    case "encounterSubmitter":
      return {
        header: "Name",
        id: "encounterSubmitter",
        accessor: "submitter",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          const displayValue = getNameOrUsername(value);

          return (
            <>
              <DefaultTableCell style={{ overflowWrap: "anywhere" }}>
                {displayValue}
              </DefaultTableCell>
            </>
          );
        },
        ...columnProps
      };
    case "encounterMember":
      return {
        header: "Member",
        id: "encounterMember",
        accessor: "patient",
        func: "accessor",
        sortingFn: (a, b) => {
          const labelA = getNameOrUsername(a.original.patient);
          const labelB = getNameOrUsername(b.original.patient);
          return labelA.localeCompare(labelB);
        },
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          const displayValue = getNameOrUsername(value);

          return (
            <UserName
              link={`/members/memberId/${value?.patient_id}/encounters`}
            >
              {displayValue}
            </UserName>
          );
        },
        ...columnProps
      };
    case "orderMember":
      return {
        header: "Member",
        id: "orderMember",
        accessor: "patient",
        func: "accessor",
        sortingFn: (a, b) => {
          const labelA = getNameOrUsername(a.original.patient);
          const labelB = getNameOrUsername(b.original.patient);
          return labelA.localeCompare(labelB);
        },
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          const displayValue = getNameOrUsername(value);

          return (
            <UserName link={`/members/memberId/${value?.patient_id}`}>
              {displayValue}
            </UserName>
          );
        },
        ...columnProps
      };
    case "encounterSubmitterV2":
      return {
        header: "Team Member",
        id: "encounterSubmitterV2",
        // accessor for PAB encounters endpoint
        accessor: "submitter",
        // accessor for encounter visits endpoint - comment this back in when we want to use this endpoint
        // accessor: "user",
        func: "accessor",
        size,
        sortingFn: (a, b) => {
          const labelA = getNameOrUsername(a.original?.user);
          const labelB = getNameOrUsername(b.original?.user);
          return labelA?.localeCompare(labelB);
        },
        cell: ({ getValue }: any) => {
          const value = getValue();
          const displayValue = getNameOrUsername(value);

          const { navigate, currentRole } = tableProps;

          const roles = [];
          value?.roles?.forEach((role) => {
            const roleLabel = getRoleLabel(role);
            // MEMBER_CARE_SPECIALIST role on Adam Admin user account is causing a crash
            if (!isFalsy(roleLabel)) {
              roles.push(roleLabel);
            }
          });

          const roleString = roles.slice(0, 3).join(", ");

          const isMe = value?.user_id === store.getState().auth.user.user_id;

          const link = `/staff/staffId/${value?.user_id}/encounters`;
          const canSeeEncountersOnStaffDetails =
            canSeeEncountersOnStaffDetailsCurrentRole(currentRole);
          return (
            <DefaultTableCell
              style={{
                overflowWrap: "anywhere",
                cursor: canSeeEncountersOnStaffDetails && "pointer"
              }}
              onClick={() => {
                if (canSeeEncountersOnStaffDetails) navigate(link);
              }}
            >
              <Typography color="primary" variant="body1">
                {displayValue ?? "User no longer exists."} {isMe && "(me)"}
              </Typography>
              <Typography color="secondary" variant="body1" fontSize={12}>
                {roleString ?? "N/A"}
              </Typography>
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "encounterSubmitterRole":
      return {
        header: "Submitter Role",
        id: "encounterSubmitter",
        accessor: "user",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          const displayValue = value?.roles
            .map((role) => getRoleLabel(role))
            .join(", ");

          return (
            <>
              <DefaultTableCell style={{ overflowWrap: "anywhere" }}>
                {displayValue}
              </DefaultTableCell>
            </>
          );
        }
      };
    case "cancellationRequestTeamMember":
      return {
        id: "cancellationRequestTeamMember",
        accessor: "submitter",
        func: "accessor",
        size,
        sortingFn: (a, b) => {
          const labelA = getNameOrUsername(a.original?.staff, false);
          const labelB = getNameOrUsername(b.original?.staff, false);
          return labelA?.localeCompare(labelB);
        },
        cell: ({ getValue, row }: any) => {
          const value = getValue();

          const name = getNameOrUsername(value, false);

          const roles = [];
          if (!isFalsy(value?.roles)) {
            value?.roles?.forEach((role) => {
              const roleLabel = getRoleLabel(role);

              // MEMBER_CARE_SPECIALIST role on Adam Admin user account is causing a crash
              if (!isFalsy(roleLabel)) {
                roles.push(roleLabel);
              }
            });
          }

          const rolesString = roles?.slice(0, 3).join(", ");
          return (
            <DefaultTableCell>
              <Typography variant="body1" color="primary">
                {name}
              </Typography>
              <Typography variant="body2" color="secondary">
                {rolesString}
              </Typography>
            </DefaultTableCell>
          );
        },
        header: "Team Member",
        ...columnProps
      };
    case "roles":
      return {
        header: "Role",
        id: "roles",
        func: "accessor",
        size,
        cell: ({ getValue, row }) => {
          const value = getValue();

          let displayValue = "N/A";

          if (value) {
            displayValue = value.map((role) => getRoleLabel(role)).join(", ");
          }

          return (
            <>
              <DefaultTableCell style={{ overflowWrap: "anywhere" }}>
                {displayValue}
              </DefaultTableCell>
            </>
          );
        },
        ...columnProps
      };
    case "encounterActions":
      return {
        header: "Actions",
        id: "encounterActions",
        accessor: "",
        func: "accessor",
        enableSorting: false,
        size,
        cell: ({ row }: any) => {
          const {
            navigate,
            handleDeleteEncounter,
            currentRole,
            currentUserId
          } = tableProps;

          const encounterId = row?.original?.encounter?.["encounter_id"];
          const isDeleteAllowed =
            isEditDeleteAllowed(row?.original?.encounter?.starts_on) &&
            // use submitted_by instead of created_by, created_by is the user who created the encounter which could be different
            // for add past encounter
            (row?.original?.encounter?.submitted_by === currentUserId ||
              row?.original?.encounter?.requested_by === currentUserId ||
              currentRole === RolesEnum.ADMIN);

          // const encounterId = row?.original?.visit?.["visit_id"];

          // const isDeleteAllowed =
          //   isEditDeleteAllowed(row?.original?.visit?.encounter_started_on) &&
          //   // use submitted_by instead of created_by, created_by is the user who created the encounter which could be different
          //   // for add past encounter
          //   (row?.original?.visit?.submitted_by === currentUserId ||
          //     currentRole === RolesEnum.ADMIN);

          const athenaId = row?.original?.patient?.external_accounts?.athena;

          const [copiedToClipboard, setCopiedToClipboard] =
            useState<boolean>(false);

          return (
            <DefaultTableCell
              style={{
                overflowWrap: "anywhere",
                alignItems: "center",
                display: "flex",
                gap: "5px"
              }}
            >
              <Row style={{ gap: "10px" }}>
                {athenaId && (
                  <>
                    {copiedToClipboard ? (
                      <CheckCircle
                        color={"success"}
                        style={{ margin: "9px" }}
                      />
                    ) : (
                      <CustomTooltip
                        title={"Copy a link to the member's Athena profile"}
                        placement="left"
                      >
                        <div>
                          <StyledIconButton
                            Icon={ContentCopyIcon}
                            iconColor={blue[700]}
                            border="square"
                            onClick={(e) => {
                              e.stopPropagation();
                              const athenaUrl = getAthenaPatientUrl(athenaId);

                              copyToClipboard(athenaUrl).then((result) => {
                                if (result) {
                                  setCopiedToClipboard(true);
                                  setTimeout(() => {
                                    setCopiedToClipboard(false);
                                  }, 1000);
                                }
                              });
                            }}
                          />
                        </div>
                      </CustomTooltip>
                    )}
                    <CustomTooltip title={"Go to member’s Athena profile"}>
                      <div>
                        <StyledColumn
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(getAthenaPatientUrl(athenaId))
                          }
                        >
                          <StyledAthenaIcon />
                          {athenaId}
                        </StyledColumn>
                      </div>
                    </CustomTooltip>
                  </>
                )}
                {isDeleteAllowed && (
                  <StyledIconButton
                    Icon={Delete}
                    testId={`deleteIcon-${row?.original?.time?.duration}`}
                    iconColor={red[700]}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteEncounter(encounterId);
                    }}
                    color={"transparent"}
                  />
                )}

                {canSeeCareFlow(currentRole) &&
                  row?.original?.visit?.has_care_flow && (
                    <CustomTooltip title={"View this task"}>
                      <div>
                        <StyledIconButton
                          Icon={Diversity1}
                          iconColor={blue[700]}
                          border="square"
                          onClick={(e) => {
                            navigate(
                              `/visits/${row?.original?.visit?.visit_id}`
                            );
                          }}
                        />
                      </div>
                    </CustomTooltip>
                  )}
              </Row>
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "encounterMonth":
      return {
        header: "Month",
        id: "encounterMonth",
        accessor: "date",
        func: "accessor",
        sortingFn: "datetime",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return (
            <DefaultTableCell style={{ overflowWrap: "anywhere" }}>
              {value.toFormat("MMMM")}
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "encounterNurseContacts":
      return {
        header: "Nurse Contacts",
        id: "encounterNurseContacts",
        accessor: "nurseContacts",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return (
            <DefaultTableCell style={{ overflowWrap: "anywhere" }}>
              {value}
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "encounterProviderContacts":
      return {
        header: "Provider Contacts",
        id: "encounterProviderContacts",
        accessor: "providerContacts",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return (
            <DefaultTableCell style={{ overflowWrap: "anywhere" }}>
              {value}
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "ordered":
      return {
        header: "Ordered",
        id: "ordered",
        accessor: "order.created_at",
        sortingFn: "dateTimeSortingSQL",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          let date;
          if (value) {
            date = DateTime.fromSQL(value);
          }

          return dateWithRelative({ date });
        },
        ...columnProps
      };
    case "orderShipped":
      return {
        header: "Shipped",
        id: "orderShipped",
        accessor: "order.shipping.shipping_date",
        sortingFn: "dateTimeSortingSQL",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const shipped = getValue();
          let date;
          if (shipped) {
            date = DateTime.fromSQL(shipped);
          }

          return dateWithRelative({ date });
        },
        ...columnProps
      };
    case "orderDelivered":
      return {
        header: "Delivered",
        id: "orderDelivered",
        accessor: "order.shipping.delivered_date",
        func: "accessor",
        sortingFn: "dateTimeSortingSQL",
        size,
        cell: ({ getValue }: any) => {
          const delivered = getValue();
          let date;
          if (delivered) {
            date = DateTime.fromSQL(delivered);
          }

          return dateWithRelative({ date });
        },
        ...columnProps
      };
    case "tracking":
      return {
        header: "Tracking",
        id: "tracking",
        accessor: "order.shipping.tracking_number",
        func: "accessor",
        size: 260,
        sortingFn: (a, b) => {
          let trackingNumberA = a?.original?.order?.shipping?.tracking_number;
          let trackingNumberB = b?.original?.order?.shipping?.tracking_number;

          if (isFalsy(trackingNumberA)) trackingNumberA = "";
          if (isFalsy(trackingNumberB)) trackingNumberB = "";

          return trackingNumberA?.localeCompare(trackingNumberB);
        },
        cell: ({ row, column: { id }, table }: any) => {
          const value = row?.original?.order;
          const trackingNumber = value?.shipping?.tracking_number;
          const carrierCode = value?.shipping?.carrier_code ?? "USPS";

          const trackingUrl = getTrackingUrl(trackingNumber, carrierCode);

          // TBD - display error when we get api errors, for example peter parker athena id 7474
          if (value) {
            return (
              <ExternalLink href={trackingUrl} target="_blank">
                {trackingNumber}
              </ExternalLink>
            );
          }

          return <></>;
        },
        ...columnProps
      };
    case "ordered_by":
      return {
        header: "Ordered By",
        id: "ordered_by",
        accessor: "ordered_by",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          const { navigate, currentRole } = tableProps;
          const name = getNameOrUsername(value);

          const link = `/staff/staffId/${value?.user_id}/orders`;

          const canSeeOrdersOnStaffProfile =
            canSeeOrdersOnStaffDetailsCurrentRole(currentRole);
          return (
            <DefaultTableCell
              style={{
                cursor: canSeeOrdersOnStaffProfile ? "pointer" : "default"
              }}
              onClick={() => {
                if (canSeeOrdersOnStaffProfile) navigate(link);
              }}
            >
              {name}
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "teamMemberName":
      return {
        id: "teamMemberName",
        header: "Nurse",
        func: "accessor",
        accessor: "carer",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          const userId = value?.["user_id"];
          let displayValue = getNameOrUsername(value);
          const { externalLink } = tableProps;

          const link = replace(externalLink, ":userId", userId);

          return <UserName link={link}>{displayValue}</UserName>;
        },
        ...columnProps
      };
    case "athenaId":
      return {
        id: "athenaId",
        header: "Athena ID",
        func: "accessor",
        sortingFn: "numberSorting",
        accessor: "patient.external_accounts.athena",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "patientCount":
      return {
        id: "patientCount",
        sortingFn: "numberSorting",
        header: () => {
          return (
            <div style={{ display: "flex" }}>
              Member Count (MTD)&nbsp;
              <CustomTooltip
                title={
                  "Unique members nurse/clinician has met with month-to-date"
                }
              >
                <HelpIcon />
              </CustomTooltip>
            </div>
          );
        },
        func: "accessor",
        accessor: "patient_count",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          if (value?.error) {
            return <ErrorComponent error={value.error} />;
          }

          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "myTeamTimeEntered":
      return {
        id: "myTeamTimeEntered",
        header: () => {
          return (
            <div style={{ display: "flex" }}>
              Time Entered <br />
              (MTD)&nbsp;
              <CustomTooltip title={"Month-to-Date"}>
                <HelpIcon />
              </CustomTooltip>
            </div>
          );
        },
        func: "accessor",
        accessor: "submitted_time",
        sortingFn: "numberSorting",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          if (value?.error) {
            return <ErrorComponent error={value.error} />;
          }
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "patientsAssigned":
      return {
        id: "patientsAssigned",
        header: () => {
          return (
            <div style={{ display: "flex" }}>
              Assigned Members&nbsp;
              <CustomTooltip title={"Nurse/clinician load of “ACTIVE” members"}>
                <HelpIcon />
              </CustomTooltip>
            </div>
          );
        },
        func: "accessor",
        accessor: "patients_assigned",
        sortingFn: "numberSorting",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          if (value?.error) {
            return <ErrorComponent error={value.error} />;
          }

          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "deleteTeamMember": {
      return {
        id: "deleteTeamMember",
        func: "accessor",
        accessor: "carer",
        header: null,
        size: 40,
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to render a checkbox
        cell: ({ getValue }: any) => {
          const member = getValue();
          const { handleDeleteTeamMember, currentUserCanEditTeam } = tableProps;
          if (!currentUserCanEditTeam) {
            return null;
          }
          return (
            <AddColumnContainer>
              <StyledIconButton
                Icon={Delete}
                iconColor={red[700]}
                onClick={() => handleDeleteTeamMember(member)}
                color={"transparent"}
              />
            </AddColumnContainer>
          );
        },
        ...columnProps
      };
    }
    case "joined":
      return {
        id: "joined",
        accessor: "patient.created_at",
        func: "accessor",
        size,
        cell: (info: any) => {
          const value = info.getValue();
          const date = DateTime.fromSQL(value);
          const displayValue = date.isValid ? getFormattedDate(date) : "N/A";
          return <DefaultTableCell>{displayValue}</DefaultTableCell>;
        },
        header: "Registered",
        ...columnProps
      };
    case "readingMeasureTimestamp":
      return {
        id: "readingMeasureTimestamp",
        accessor: "measure_timestamp",
        func: "accessor",
        sortingFn: "numberSorting",
        size,
        cell: ({ getValue }) => {
          const value = getValue();
          const { patientTimezone } = tableProps;
          const date = DateTime.fromSeconds(value);
          const formattedDate = getFormattedDateTime(
            date,
            patientTimezone,
            true
          );
          return <DefaultTableCell>{formattedDate}</DefaultTableCell>;
        },
        header: "Time",
        ...columnProps
      };
    case "glucoseReading":
      return {
        id: "glucoseReading",
        accessor: "glucose",
        func: "accessor",
        sortingFn: "numberSorting",
        size,
        cell: (info: any) => {
          const value = info.getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        header: "Reading",
        ...columnProps
      };
    case "glucoseTag":
      return {
        id: "glucoseTag",
        accessor: "reading_qualifier",
        func: "accessor",
        size,
        cell: (info: any) => {
          const value = info.getValue();
          const reason = ReadingQualifierReasonEnum_toString(value);
          return (
            <DefaultTableCell>{reason ? reason : "Other"}</DefaultTableCell>
          );
        },
        header: "Tag",
        ...columnProps
      };
    case "systolic":
      return {
        id: "systolic",
        accessor: "systolic",
        func: "accessor",
        size,
        header: "Systolic",
        sortingFn: "numberSorting",
        cell: ({ getValue }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "diastolic":
      return {
        id: "diastolic",
        accessor: "diastolic",
        func: "accessor",
        size,
        header: "Diastolic",
        sortingFn: "numberSorting",
        cell: ({ getValue }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "pulse":
      return {
        id: "pulse",
        accessor: "pulse",
        func: "accessor",
        size,
        header: "Pulse",
        sortingFn: "numberSorting",
        cell: ({ getValue }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "weight":
      return {
        id: "weight",
        accessor: "weight",
        func: "accessor",
        size,
        header: "Weight",
        sortingFn: "numberSorting",
        cell: ({ getValue }: any) => {
          const value = getValue();
          // round to one decimal point
          const roundedValue = value ? Number.parseFloat(value).toFixed(1) : "";
          return <DefaultTableCell>{roundedValue}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "spo2":
      return {
        id: "spo2",
        accessor: "spo2",
        func: "accessor",
        size,
        header: "SpO2",
        sortingFn: "numberSorting",
        cell: ({ getValue }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "reportReadingsDate":
      return {
        id: "reportReadingsDate",
        accessor: "date",
        func: "accessor",
        size: 100,
        header: "Date",
        sortingFn: "dateTimeSortingMMDDYYYY",
        cell: ({ getValue }: any) => {
          const value = getValue() as string;
          return <DefaultTableCell>{value}</DefaultTableCell>;
        }
      };
    case "reportReadingsDevices":
      return {
        id: "reportReadingsDevices",
        accessor: "device_types",
        func: "accessor",
        size: 400,
        header: "Device(s)",
        cell: ({ getValue }: any) => {
          const devicesTypes = getValue() as DeviceType[];
          return (
            <DefaultTableCell>{devicesTypes?.join(", ")}</DefaultTableCell>
          );
        }
      };
    case "reportReadingsCount":
      return {
        id: "reportReadingsCount",
        accessor: "count",
        func: "accessor",
        size: 75,
        header: "Count",
        sortingFn: "numberSorting",
        cell: ({ getValue }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        }
      };
    case "patientActions":
      return {
        id: "patientActions",
        accessor: "patient.patient_id",
        func: "accessor",
        size,
        getDataFieldColumn,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          const {
            currentRole,
            setModalStateCallback,
            setProviderModalStateCallback,
            setSelectedPatientIdCallback
          } = tableProps;
          if (
            !canUpdateNurseAssignment(currentRole) &&
            !canUpdateProviderAssignment(currentRole)
          ) {
            return null;
          } else {
            return (
              <div className="button">
                <CustomTooltip
                  backgroundColor="white"
                  title={
                    <Column>
                      {canUpdateNurseAssignment(currentRole) && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setSelectedPatientIdCallback(value);
                            setModalStateCallback("open");
                          }}
                        >
                          Assign To Nurse
                        </Button>
                      )}
                      {canUpdateProviderAssignment(currentRole) && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setSelectedPatientIdCallback(value);
                            setProviderModalStateCallback("open");
                          }}
                        >
                          Assign To Provider
                        </Button>
                      )}
                    </Column>
                  }
                >
                  <ThreeDotsIcon id={id} />
                </CustomTooltip>
              </div>
            );
          }
        },
        header: "Actions",
        enableSorting: false,
        ...columnProps
      };
    case "staffActions":
      return {
        id: "staffActions",
        accessor: "user.username",
        func: "accessor",
        size,
        cell: ({ getValue, row, column: { id }, table }: any) => {
          const value = getValue();
          const fullname = row.original.fullname;

          // this is a react component but because "cell" is lowercase the linter will yell at us
          // if we don't add these ignores

          const {
            changeUserStatusMutation,
            dispatch,
            setSelectedUsername,
            setResetPasswordModalStateCallback,
            currentRole
          } = tableProps;

          return (
            <CustomTooltip
              backgroundColor="#ffffff"
              title={
                <Column>
                  {canSetStaffInactive(
                    currentRole,
                    row.original.user.roles
                  ) && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        Alert_show({
                          dispatch,
                          id: "makeUserInactive",
                          title: "Make user inactive",
                          content: (
                            <>
                              Are you sure you want to make {fullname} inactive?
                            </>
                          ),
                          type: "warning",
                          size: "small",
                          buttons: [
                            {
                              text: LocalizedStrings.submit,
                              style: "default",
                              onPress: async () => {
                                Alert_loading({
                                  dispatch,
                                  id: "makeUserInactive"
                                });
                                await changeUserStatusMutation({
                                  email: value,
                                  status: MemberStatusEnum.INACTIVE
                                });
                                Alert_close({
                                  dispatch,
                                  id: "makeUserInactive"
                                });
                              },
                              hasLoadingState: true
                            },
                            {
                              text: LocalizedStrings.cancel,
                              style: "cancel",
                              onPress: () => {
                                Alert_close({
                                  dispatch,
                                  id: "makeUserInactive"
                                });
                              }
                            }
                          ]
                        });
                      }}
                    >
                      Set inactive
                    </Button>
                  )}
                  {/* ONLY ADMIN & TECH SUPPORT should be able to reset user password
              https://copilotiq.atlassian.net/browse/ENG-2807 */}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setSelectedUsername(value);
                      setResetPasswordModalStateCallback("open");
                    }}
                  >
                    Reset Password
                  </Button>
                </Column>
              }
            >
              <ThreeDotsIcon id={id} />
            </CustomTooltip>
          );
        },
        header: "Actions",
        enableSorting: false,
        ...columnProps
      };

    case "teamCreatedAt":
      return {
        id: "teamCreatedAt",
        accessor: "created_at",
        func: "accessor",
        size,
        header: "Created At",
        sortingFn: "dateTimeSortingSQL",
        cell: ({ getValue }: any) => {
          const value = getValue();
          let date = DateTime.fromSQL(value);
          if (date.isValid === false) date = DateTime.fromISO(value);

          return dateWithRelative({ date });
        }
      };
    case "teamName":
      return {
        id: "teamName",
        accessor: "name",
        func: "accessor",
        size,
        header: "Team Name",
        cell: ({ getValue, row, accessor }: any) => {
          const value = getValue();
          const userId =
            row.original?.["leader_id"] ?? row.original?.team?.leader_id;

          const { externalLink } = columnProps;

          const link = replace(externalLink, ":userId", userId);

          return <UserName link={link}>{value}</UserName>;
        },
        ...columnProps
      };
    case "teamType":
      return {
        id: "teamType",
        accessor: "type",
        func: "accessor",
        size,
        header: "Team Type",
        sortingFn: (a, b) => {
          const labelA = getTeamTypeLabel(a.original.type);
          const labelB = getTeamTypeLabel(b.original.type);
          return labelA.localeCompare(labelB);
        },
        cell: ({ getValue }: any) => {
          const value = getValue();
          return <DefaultTableCell>{getTeamTypeLabel(value)}</DefaultTableCell>;
        }
      };
    case "lastContact":
      return {
        id: "lastContact",
        accessor: "latestContactCreatedAt",
        func: "accessor",
        size,
        header: "Last Retention Contact",
        sortingFn: "dateTimeSortingSQL",
        cell: ({ getValue, row }) => {
          const {
            openContactAttemptDetailsModalHandler,
            setSelectedPatientIdCallback
          } = tableProps;

          let lastContactString = "N/A";
          const latestContactCreatedAt = getValue();
          if (latestContactCreatedAt) {
            const contactDate = DateTime.fromSQL(latestContactCreatedAt);
            lastContactString = contactDate.toLocaleString(DateTime.DATE_SHORT);
          }

          const handleModalOpen = () => {
            setSelectedPatientIdCallback(row?.original.patient.patient_id);
            openContactAttemptDetailsModalHandler(
              row?.original?.patient_retention_actions
            );
          };
          return (
            <Row>
              <DefaultTableCell>{lastContactString}</DefaultTableCell>
              {latestContactCreatedAt && (
                <Button onClick={handleModalOpen}>
                  All <KeyboardArrowDownIcon />
                </Button>
              )}
            </Row>
          );
        }
      };
    case "contactAttempts":
      return {
        id: "contactAttempts",
        func: "accessor",
        accessor: "patient_retention_actions.length",
        size: 115,
        header: "Contact Attempts",
        sortingFn: "alphanumeric",
        cell: ({ getValue }: any) => {
          const value = getValue();

          return <DefaultTableCell>{value}</DefaultTableCell>;
        }
      };
    case "latestAttritionReasonCategory":
      return {
        id: "latestAttritionReasonCategory",
        accessor: "latest_attrition_reasons",
        func: "accessor",
        size,
        header: "Category",
        sortingFn: "category",
        cell: ({ getValue, row }: any) => {
          const latestAttritionReasons = getValue();
          const filtered = latestAttritionReasons.filter(
            (r: { question: string }) => r.question !== "Notes"
          );
          const value = filtered.length > 0 ? filtered[0].category : "N/A";

          return <DefaultTableCell>{value}</DefaultTableCell>;
        }
      };
    case "latestAttritionReasonSubCategory":
      return {
        id: "latestAttritionReasonSubCategory",
        accessor: "latest_attrition_reasons",
        func: "accessor",
        size,
        header: "Sub Category",
        sortingFn: "answer",
        cell: ({ getValue, row }: any) => {
          const latestAttritionReasons = getValue();
          const filtered = latestAttritionReasons.filter(
            (r: { question: string }) => r.question !== "Notes"
          );
          const value = filtered.length > 0 ? filtered[0].answer : "N/A";

          return <DefaultTableCell>{value}</DefaultTableCell>;
        }
      };
    case "latestAttritionReasonNotes":
      return {
        id: "latestAttritionReasonNotes",
        accessor: "latest_attrition_reasons",
        func: "accessor",
        size,
        header: "Notes",
        enableSorting: false,
        cell: ({ getValue, row }: any) => {
          const latestAttritionReasons = getValue();
          const filtered = latestAttritionReasons.filter(
            (r: { question: string }) => r.question === "Notes"
          );
          const value = filtered.length > 0 ? filtered[0].answer : "N/A";

          return <DefaultTableCell>{value}</DefaultTableCell>;
        }
      };
    case "lastContactAction":
      return {
        id: "lastContactAction",
        accessor: "patient.patient_id",
        func: "accessor",
        size,
        enableSorting: false,
        header: "Actions",
        cell: ({ getValue, row }) => {
          const patientId = getValue();
          const {
            openContactAttemptModalHandler,
            setSelectedPatientIdCallback
          } = tableProps;

          const handleModalOpen = () => {
            setSelectedPatientIdCallback(patientId);
            openContactAttemptModalHandler();
          };

          const athenaId = row?.original?.patient?.external_accounts?.athena;

          return (
            <Flexbox gap="10px" alignItems="center">
              <CustomTooltip placement="top" title={`Submit Contact Attempt`}>
                <div>
                  <StyledIconButton
                    Icon={ContactPhoneIcon}
                    iconColor={blue[700]}
                    size={"small"}
                    border="square"
                    onClick={handleModalOpen}
                  />
                </div>
              </CustomTooltip>
              <AthenaChartButton athenaId={athenaId} />
            </Flexbox>
          );
        }
      };
    case "requestConsentsMethod":
      return {
        id: "requestConsentsMethod",
        accessor: "lead_id",
        func: "accessor",
        size: 215,
        header: "Sent to",
        cell: ({ getValue, row }: any) => {
          const mobile = row.original.mobile;
          const email = row.original.email;

          const value = mobile ? maskPhoneNumber(mobile) : email;

          return <DefaultTableCell>{value}</DefaultTableCell>;
        }
      };
    case "taskContactAttemptType":
      return {
        id: "taskContactAttemptType",
        accessor: "type",
        func: "accessor",
        size: 100,
        header: "Type",
        cell: ({ getValue }: any) => {
          const value = getValue();

          return <DefaultTableCell>{formatName(value)}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "taskContactOutcome":
      return {
        id: "taskContactOutcome",
        accessor: "outcome",
        func: "accessor",
        size: 150,
        header: "Outcome",
        cell: ({ getValue }: any) => {
          const value = getValue();

          return <DefaultTableCell>{formatName(value)}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "taskContactPerformedBy":
      return {
        id: "taskContactPerformedBy",
        accessor: "performed_by",
        func: "accessor",
        size: 150,
        header: "Performed By",
        cell: ({ getValue }: any) => {
          const value = getValue();

          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "ptoNurseName":
      return {
        id: "ptoNurseName",
        accessor: "staff",
        func: "accessor",
        size: 150,
        header: "Name",
        sortingFn: (a, b) => {
          const fullnameA = a.original.staff.fullname;
          const fullnameB = b.original.staff.fullname;

          return fullnameA.localeCompare(fullnameB);
        },
        cell: ({ getValue }: any) => {
          const value = getValue();
          const name = value?.fullname;

          return <DefaultTableCell>{name}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "ptoNurseDuration":
      return {
        id: "ptoNurseDuration",
        accessor: "ooo_slots",
        func: "accessor",
        size: 100,
        header: "Duration",
        sortingFn: "ptoDurationSorting",
        cell: ({ getValue, row }: any) => {
          const value = getValue();
          const duration = Duration.fromISO(value?.[0]?.duration).toObject();
          const weeks = duration?.weeks;
          const days = duration?.days;
          const hours = duration?.hours;

          return (
            <DefaultTableCell>
              {weeks > 0 && `${weeks} week(s)${days > 0 ? " " : ""}`}
              {days > 0 && `${days} day(s)`}
              {hours > 0 && ` ${hours} hour(s)`}
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "ptoNursePolicy":
      return {
        id: "ptoNursePolicy",
        accessor: "ooo_slots",
        func: "accessor",
        size: 150,
        header: "Policy",
        sortingFn: "ptoPolicySorting",
        cell: ({ getValue, row }: any) => {
          const value = getValue();
          const policy = formatName(value?.[0]?.appointment_type);

          return <DefaultTableCell>{policy}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "ptoNursePeriod":
      return {
        id: "ptoNursePeriod",
        accessor: "ooo_slots",
        func: "accessor",
        size: 150,
        header: "Period",
        sortingFn: "ptoNursePeriodSorting",
        cell: ({ getValue, row }: any) => {
          const value = getValue();
          const timezone = row?.original?.staff?.timezone;
          const start = DateTime.fromISO(value?.[0]?.["start_time"]).setZone(
            timezone
          );
          const end = DateTime.fromISO(value?.[0]?.["end_time"]).setZone(
            timezone
          );

          // remove time/hours for v1, only show days
          // const startDateString = start.toFormat("MM/dd h:mm a");
          // const endDateString = end.toFormat("MM/dd h:mm a ZZZZ");
          const startDateString = start.toFormat("EEE MM/dd");
          const endDateString = end.toFormat("EEE MM/dd");
          const startEndDateString = `${startDateString} - ${endDateString}`;

          return <DefaultTableCell>{startEndDateString}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "ptoNurseToReassign":
      return {
        id: "ptoNurseToReassign",
        accessor: "ooo_slots",
        func: "accessor",
        size: 200,
        header: "To Reassign",
        sortingFn: "ptoAffectedAppointmentsSorting",
        cell: ({ getValue, row }: any) => {
          const { navigate, currentRole, setIsEditTimeOffModalOpen } =
            tableProps;
          const value = getValue();
          const slots = value?.[0]?.affected_appointments;
          const userId = row?.original?.staff?.staff_id;

          if (slots === 0) {
            return (
              <DefaultTableCell>
                <Flexbox alignItems="center" justifyContent="space-between">
                  <Box>{slots} appointments</Box>
                  <Flexbox alignItems="center" gap="4px">
                    {canEditDeleteTimeOff(currentRole) && (
                      <EditDeleteMenu
                        id={value?.[0]?.ooo_event_id}
                        staffId={row?.original?.staff?.staff_id}
                        event={{
                          appointmentType: value?.[0]?.appointment_type,
                          visitsRequest: {
                            calendar_event_start: value?.[0]?.start_time,
                            calendar_event_end: value?.[0]?.end_time
                          }
                        }}
                        setIsEditTimeOffModalOpen={setIsEditTimeOffModalOpen}
                        refetchCalendarVisits={() => {}}
                        isPto={true}
                      />
                    )}
                  </Flexbox>
                </Flexbox>
              </DefaultTableCell>
            );
          }

          return (
            <DefaultTableCell>
              <Flexbox alignItems="center" justifyContent="space-between">
                <Box>{slots} appointments</Box>
                <Flexbox alignItems="center" gap="4px">
                  <Button
                    variant="outlined"
                    onClick={() =>
                      navigate(`/nurses-schedules/time-off/${userId}`, {
                        state: {
                          staffId: userId,
                          start_time: value?.[0]?.start_time,
                          end_time: value?.[0]?.end_time
                        }
                      })
                    }
                  >
                    View
                  </Button>
                  {canEditDeleteTimeOff(currentRole) && (
                    <EditDeleteMenu
                      id={value?.[0]?.ooo_event_id}
                      staffId={row?.original?.staff?.staff_id}
                      event={{
                        appointmentType: value?.[0]?.appointment_type,
                        visitsRequest: {
                          calendar_event_start: value?.[0]?.start_time,
                          calendar_event_end: value?.[0]?.end_time
                        }
                      }}
                      setIsEditTimeOffModalOpen={setIsEditTimeOffModalOpen}
                      refetchCalendarVisits={() => {}}
                      isPto={true}
                    />
                  )}
                </Flexbox>
              </Flexbox>
            </DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "ptoReassign":
      return {
        header: "Actions",
        id: "ptoReassign",
        accessor: "event_id",
        func: "accessor",
        enableSorting: false,
        size,
        cell: ({ getValue, row }: any) => {
          const { setSelectedEventId } = tableProps;
          const event_id = getValue();

          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setSelectedEventId(event_id);
              }}
            >
              Reassign
            </Button>
          );
        },
        ...columnProps
      };
    case "sms_report_member_link": {
      return {
        id: "sms_report_member_link",
        func: "accessor",
        accessor: "patient_id",
        size: 150,
        cell: ({ getValue }: any) => {
          const value = getValue();

          const link = `/members/memberId/${value}`;
          return <UserName link={link}>{value}</UserName>;
        },
        ...columnProps
      };
    }

    // No specific code after this, only generic code (that can be used in multiple tables)
    case "number":
      return {
        id: "number",
        func: "accessor",
        sortingFn: "numberSorting",
        size,
        cell: ({ getValue }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value}</DefaultTableCell>;
        },
        ...columnProps
      };
    case "date":
      return {
        id: "date",
        accessor: "date",
        func: "accessor",
        size: 150,
        header: "Date",
        sortingFn: "dateTimeSortingSQL",
        cell: ({ getValue, row, accessor }: any) => {
          const value = getValue();
          let date = DateTime.fromSQL(value);
          if (date?.isValid === false) date = DateTime.fromISO(value);

          return (
            <DefaultTableCell>{dateWithRelative({ date })}</DefaultTableCell>
          );
        },
        ...columnProps
      };
    case "timezone":
      return {
        id: "timezone",
        accessor: "timezone",
        func: "accessor",
        size: 100,
        header: "Timezone",
        cell: ({ getValue, row, accessor }: any) => {
          const value = getValue();

          const timezone = DateTime.now().setZone(value).toFormat("ZZZZ");

          return <DefaultTableCell>{timezone}</DefaultTableCell>;
        },
        ...columnProps
      };

    case "empty":
      return {
        id: "timezone",
        accessor: "",
        func: "accessor",
        size: 250,
        header: "",
        cell: () => {
          return <div />;
        },
        ...columnProps
      };

    default:
      return {
        id: "default",
        func: "accessor",
        size,
        cell: ({ getValue, row }: any) => {
          const value = getValue();
          return <DefaultTableCell>{value ?? "N/A"}</DefaultTableCell>;
        },
        ...columnProps
      };
  }
}
