import RolesEnum from "common/enums/RolesEnum";
import { useGetEncountersMTD } from "common/hooks/useGetEncountersMTD";
import { DateTime } from "luxon";
import { Flexbox, OverviewCard } from "../../../styling/NewStyleComponents";
import { Typography } from "@mui/material";
import { CustomTooltip } from "../../../styling/StyleComponents";
import { HelpIcon } from "../../../assets/images/icons";
import { formatMTD } from "common/helpers/helpers";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import ErrorComponent from "../../../components/ErrorComponent";
import useGetLastEncounter from "common/hooks/useGetLastEncounter";
// import useGetLastEncounterVisit from "common/hooks/useGetLastEncounterVisit";

const dateFormat = "MM/dd/yyyy";

function OverviewCardsRender({
  encountersMTD,
  lastTNEncounter,
  lastNPEncounter,
  lastReadingDate
}) {
  return (
    <Flexbox gap="8px" justifyContent="space-between" width="100%">
      <OverviewCard flexBasis="25%" background="white">
        <Flexbox justifyContent="space-between">
          <Flexbox gap="2px">
            <Typography variant="h6" fontWeight={400}>
              Time Spent (MTD)
            </Typography>
            <CustomTooltip
              title={
                "Nurse Visit times submitted, both connected and not connected"
              }
            >
              <HelpIcon />
            </CustomTooltip>
          </Flexbox>
          <Typography variant="h6" data-testid="TotalTime">
            {formatMTD(encountersMTD)}
          </Typography>
        </Flexbox>
      </OverviewCard>
      <OverviewCard flexBasis="25%" background="white">
        <Flexbox justifyContent="space-between">
          <Flexbox gap="2px">
            <Typography variant="h6" fontWeight={400}>
              Last TN Encounter
            </Typography>
            <CustomTooltip
              title={
                "Latest connected encounter (either Device Set Up or Nurse Visit)"
              }
            >
              <HelpIcon />
            </CustomTooltip>
          </Flexbox>
          <Typography variant="h6" data-testid="TotalTNTime">
            {lastTNEncounter
              ? DateTime.fromISO(lastTNEncounter).toFormat(dateFormat)
              : "N/A"}
          </Typography>
        </Flexbox>
      </OverviewCard>
      <OverviewCard flexBasis="25%" background="white">
        <Flexbox justifyContent="space-between">
          <Typography variant="h6" fontWeight={400}>
            Last NP Encounter
          </Typography>
          <Typography variant="h6" data-testid="TotalNPTime">
            {lastNPEncounter
              ? DateTime.fromISO(lastNPEncounter).toFormat(dateFormat)
              : "N/A"}
          </Typography>
        </Flexbox>
      </OverviewCard>
      <OverviewCard flexBasis="25%" background="white">
        <Flexbox justifyContent="space-between">
          <Typography variant="h6" fontWeight={400}>
            Last Reading
          </Typography>
          <Typography variant="h6">{lastReadingDate ?? "N/A"}</Typography>
        </Flexbox>
      </OverviewCard>
    </Flexbox>
  );
}

function OverviewCards({ memberId, lastReadingDate }) {
  const startDate = DateTime.local().startOf("month");

  const {
    lastEncounterVisit: lastTNEncounter,
    isLoaded: lastTNEncounterIsLoaded,
    error: lastTNEncounterError
  } = useGetLastEncounter(memberId, RolesEnum.TH_NURSE);

  // } = useGetLastEncounterVisit(memberId, RolesEnum.TH_NURSE);
  const {
    lastEncounterVisit: lastNPEncounter,
    isLoaded: lastNPEncounterIsLoaded,
    error: lastNPEncounterError
  } = useGetLastEncounter(memberId, RolesEnum.NURSE_PROVIDER);
  // } = useGetLastEncounterVisit(memberId, RolesEnum.NURSE_PROVIDER);
  const {
    mtdEncountersSum: encountersMTD,
    isLoaded: encountersMTDIsLoaded,
    error: encountersMTDError
  } = useGetEncountersMTD(memberId, startDate);

  const isLoaded =
    lastTNEncounterIsLoaded && lastNPEncounterIsLoaded && encountersMTDIsLoaded;

  const isError =
    lastTNEncounterError || lastNPEncounterError || encountersMTDError;
  return (
    // Render content after dataloading finishes to prevent race condition
    <>
      {isLoaded ? (
        isError ? (
          <>
            <ErrorComponent
              error={
                encountersMTDError ??
                lastTNEncounterError ??
                lastNPEncounterError
              }
            />
          </>
        ) : (
          // Then render content
          <OverviewCardsRender
            encountersMTD={encountersMTD}
            lastTNEncounter={lastTNEncounter}
            lastNPEncounter={lastNPEncounter}
            lastReadingDate={lastReadingDate}
          />
        )
      ) : (
        <LoadingFallback />
      )}
    </>
  );
}

export default OverviewCards;
