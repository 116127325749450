import { requestMediaPermissions } from "mic-check";
import { Alert_show } from "./AlertHelper";
import { AppDispatch } from "../redux";

interface RequestPermissionsProps {
  video: boolean;
  audio: boolean;
}

const requestPermissions = ({ video, audio }: RequestPermissionsProps) => {
  return new Promise((resolve, reject) => {
    requestMediaPermissions({ video, audio })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const PermissionsHelper_hasPushNotification = () => {
  return new Promise<{ enabled: boolean; settingsRequired: boolean }>(
    (resolve, reject) => {
      if ("Notification" in window) {
        const settingsRequired = Notification.permission === "denied";
        const enabled = Notification.permission === "granted";
        resolve({ enabled, settingsRequired });
      } else {
        reject("Not supported on browser");
      }
    }
  );
};

export const PermissionsHelper_askForPushNotifications = () => {
  return new Promise((resolve, reject) => {
    if ("Notification" in window) {
      Notification.requestPermission()
        .then((value) => {
          resolve(value === "granted");
        })
        .catch((error) => reject(error));
    } else {
      reject("Not supported on browser");
    }
  });
};

export const PermissionsHelper_askForMicrophone = () => {
  return requestPermissions({ video: false, audio: true });
};

export const PermissionsHelper_askForCamera = () => {
  return requestPermissions({ video: true, audio: true });
};

export const PermissionsHelper_askForBluetooth = () =>
  Promise.reject("Bluetooth feature not needed on web");

export const PermissionsHelper_openSettings = (dispatch: AppDispatch) => {
  Alert_show({
    dispatch,
    title: "CopilotIQ needs permissions",
    content:
      "Permissions have been rejected in the past. Please open your browser's settings and accept the required permission"
  });
};
