import RolesEnum from "common/enums/RolesEnum";
import { useGetUserWithQueryParamsQuery } from "common/services/UserService";
import UserType from "common/types/UserType";

import Table from "../../components/Table/Table";
import ErrorComponent from "../../components/ErrorComponent";
import { ChangeEvent, useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";
import DebouncedInput from "../../components/Input/DebouncedInput";
import { blue } from "@mui/material/colors";
import styled from "@emotion/styled";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import { trimText } from "common/helpers/helpers";

const TABLE_INPUT_WIDTH = "clamp(200px, 250%, 350px)";
const StyledInput = styled(DebouncedInput)`
  background: ${blue[50]};
  width: ${TABLE_INPUT_WIDTH};
`;
const CircularProgressContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const Container = styled.div`
  flex: 1;
  flex-direction: column;
`;

const TEAM_LEAD_ROLES = [
  RolesEnum.MD_PROVIDER,
  RolesEnum.NURSE_PROVIDER,
  RolesEnum.THN_MANAGER,
  RolesEnum.NPN_MANAGER,
  RolesEnum.NURSE_DIRECTOR,
  RolesEnum.NPS_MANAGER,
  RolesEnum.PROVIDER_MANAGER
];

const tableColumns = [
  { name: "checkboxColumn", type: "checkbox" },
  { name: "fullname", size: 300 },
  { name: "roles", size: 250, type: "dataList" }
];

interface IProps {
  onSelectedTeamLeader: (teamLeader: UserType[]) => void;
}

const SelectTeamLeadTable = ({ onSelectedTeamLeader }: IProps) => {
  const [searchText, setSearchText] = useState<string>("");

  const handleText = (event: ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setSearchText(newText);
  };

  const trimmedText = useMemo(() => {
    return trimText(searchText);
  }, [searchText]);

  const {
    data: userList,
    error,
    isLoading,
    isFetching
  } = useGetUserWithQueryParamsQuery({
    roles: TEAM_LEAD_ROLES,
    status: [MemberStatusEnum.ACTIVE],
    search: trimmedText
  });

  return (
    <Container>
      <InputContainer>
        <StyledInput
          fullWidth
          debounceTimeout={300}
          value={searchText}
          onChange={handleText}
          type="text"
          placeholder="Search members"
        />
        {isFetching && isLoading === false && <CircularProgress size={24} />}
      </InputContainer>
      <br />
      {isLoading && (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      )}
      {userList && (
        <Table
          tableColumns={tableColumns}
          data={userList}
          setSelectionCallback={onSelectedTeamLeader}
          tableProps={{}}
          enableMultiRowSelection={false}
          tableMaxHeight="340px"
        />
      )}
      {error && <ErrorComponent error={error} />}
    </Container>
  );
};

export default SelectTeamLeadTable;
