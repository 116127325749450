import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  styled
} from "@mui/material";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { useSelector } from "react-redux";
import { RootState, dispatch, useAppDispatch } from "common/redux";
import {
  checkIdValid,
  getNameOrUsername,
  isFalsy
} from "common/helpers/helpers";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { blue, error, gray } from "../../../styling/colors";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  useDeleteCalendarEventMutation,
  useGetCalendarEventQuery,
  useGetCalendarEventsQuery,
  useReassignCalendarEventMutation,
  DELAY_AFTER_REQUEST_COMPLETED
} from "common/services/CalendarService";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";
import StaffAvailabilityObject from "common/types/Calendaring/StaffAvailabilityObject";
import { useGetSortedMembersWithActiveNursesQuery } from "common/services/ReportsService";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import {
  resetAppointmentState,
  resetAppointmentSelections,
  setAttendees,
  setMemberName,
  setRecurrence,
  setStaffId,
  setStaffName,
  setMemberTimezone,
  setStartEndDate,
  setAppointmentType,
  setAssignedNurseId,
  setXTraceId
} from "common/redux/AppointmentSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import RecurrenceFrequencyEnum from "common/enums/Calendaring/Appointments/RecurrenceFrequencyEnum";
import AppointmentOperationTypeEnum from "common/enums/Calendaring/Appointments/AppointmentOperationTypeEnum";
import { LoadingButton } from "@mui/lab";
import {
  Alert_close,
  Alert_loading,
  Alert_show
} from "common/helpers/AlertHelper";
import ErrorComponent from "../../../components/ErrorComponent";
import { DelayedRender } from "common/helpers/components/DelayedRender";
import RecurrenceUpdateTypeEnum from "common/enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import {
  isBusinessDay,
  plusBusinessDays
} from "common/helpers/BusinessDaysHelper/BusinessDaysHelper";
import RolesEnum, {
  canScheduleNurses,
  hasFourBusinessDaySchedulingRestriction
} from "common/enums/RolesEnum";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import UserStatusEnum from "common/enums/UserStatusEnum";
import cloneDeep from "lodash.clonedeep";
import { dateIsBlocked } from "common/helpers/CalendarHelper";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "../../../styling";
import { StyledModal } from "../../../styling/StyleModal";
import { useGetStaffAvailabilityQuery } from "common/services/PanelManagementService";
import {
  DAYS_TO_LOOK_AHEAD,
  DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING
} from "./constants";

const ManualReassignAppointmentContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
`;

/**
 * gets the start date as a week day, skipping weekends and holidays
 * @param {String} timezone the timezone of the carer
 * @param {RolesEnum} role the role of the logged in user
 * @param {string} startdate a string representation of the start date
 * @returns {DateTime} the start date
 */
function getStartDate(timezone: string, role: RolesEnum, startdate?: string) {
  // allow booking now - take the current time and subtract 61 minutes to make sure start time is before the current time slot
  const endOfHour = DateTime.now()
    .setZone(timezone)
    .minus({ minutes: 61 })
    .startOf("minute");

  if (startdate) {
    let startDateTime = DateTime.fromISO(startdate).startOf("week").toUTC();
    if (hasFourBusinessDaySchedulingRestriction(role)) {
      // add 4 business days after the current day, or 5 business days
      startDateTime = plusBusinessDays(endOfHour.startOf("day"), 5);
    }
    return startDateTime.diffNow().milliseconds > 0 ? startDateTime : endOfHour;
  }

  let startDate = endOfHour;
  if (hasFourBusinessDaySchedulingRestriction(role)) {
    // add 4 business days after the current day, or 5 business days
    startDate = plusBusinessDays(endOfHour.startOf("day"), 5);
  }

  // if it is a weekend or holiday, set the start date to the start of the next business day
  if (!isBusinessDay(startDate)) {
    startDate = plusBusinessDays(startDate.startOf("day"), 1);
  }

  return startDate.toUTC();
}

const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

function getCurrentWeekIsoDays(currentDay: DateTime) {
  const currentWeek = [];

  const startOfWeek = currentDay.startOf("week");

  for (let i = 0; i < 5; i++) {
    currentWeek.push(startOfWeek.plus({ days: i }).toISODate());
  }
  return currentWeek;
}

function hasAvailabilityInCurrentWeek(currentDay: DateTime, availabilityMap) {
  const currentWeek = getCurrentWeekIsoDays(currentDay);

  return currentWeek.some((day) => {
    return availabilityMap[day]?.length > 0;
  });
}

function RenderWeekdays({
  currentDay,
  startDate,
  availabilityMap,
  availabilityIsFetching,
  availabilityError,
  selectedStartDate,
  isAvailableThisWeek,
  availabilityIsUninitialized,
  timezone,
  appointmentType,
  staffScheduleError,
  eventId
}: Readonly<{
  currentDay: DateTime;
  startDate: DateTime;
  availabilityMap: any;
  availabilityIsFetching: boolean;
  selectedStartDate: DateTime;
  isAvailableThisWeek: boolean;
  availabilityIsUninitialized: boolean;
  availabilityError: unknown;
  appointmentType: AppointmentTypeEnum;
  timezone: string;
  staffScheduleError: unknown;
  eventId: string;
}>) {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const currentDayIndex = currentDay.weekday - 1;

  const [
    deleteTimeOff,
    {
      isSuccess: deleteTimeOffSuccess,
      error: deleteTimeOffError,
      reset: resetDeleteTimeOff
    }
  ] = useDeleteCalendarEventMutation({});

  useEffect(() => {
    if (deleteTimeOffSuccess) {
      setTimeout(() => {
        Alert_close({ dispatch, id: "cancelAppointment" });
        resetDeleteTimeOff();
        setTimeout(() => {
          navigate(`/nurses-schedules/time-off/${state?.staffId}`, {
            state
          });
        }, 50);
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }
  }, [deleteTimeOffSuccess]);

  useEffect(() => {
    if (deleteTimeOffError) {
      Alert_close({ dispatch, id: "cancelAppointment" });
      resetDeleteTimeOff();
      Alert_show({
        dispatch,
        title: "Error Cancelling Appointment",
        id: "cancelAppointmentError",
        content: <ErrorComponent error={deleteTimeOffError} />,
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () =>
              Alert_close({ dispatch, id: "cancelAppointmentError" })
          }
        ]
      });
    }
  }, [deleteTimeOffError]);

  async function deleteEventHandler(eventId: string) {
    await deleteTimeOff({
      event_id: eventId,
      delete_type: RecurrenceUpdateTypeEnum.ONCE
    });
  }

  return (
    <>
      {!availabilityIsFetching &&
        !isAvailableThisWeek &&
        // check to make sure the query has been initialized before showing this message
        !availabilityIsUninitialized &&
        !availabilityError && (
          <DelayedRender delay={500}>
            <Box mt="40px" textAlign="center">
              <Typography variant="body1" color="text.secondary">
                The selected nurse has no available slots this week.
              </Typography>
              <Typography
                mt="20px"
                variant="body1"
                fontSize="12px"
                color={error[600]}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  const modalId = "cancelAppointment";
                  Alert_show({
                    dispatch,
                    id: modalId,
                    title: "Please confirm",
                    content: (
                      <div>
                        Are you sure you want to cancel this appointment? The
                        member will be notified.
                        <br />
                        This action cannot be undone.
                      </div>
                    ),
                    type: "warning",
                    size: "medium",
                    buttons: [
                      {
                        text: "Cancel Appointment",
                        style: "destructive",
                        onPress: async () => {
                          Alert_loading({ dispatch, id: modalId });
                          await deleteEventHandler(eventId);
                        },
                        hasLoadingState: true
                      },
                      {
                        text: "Go Back",
                        style: "cancel",
                        onPress: () => {
                          Alert_close({ dispatch, id: modalId });
                        }
                      }
                    ]
                  });
                }}
              >
                Cancel Appointment
              </Typography>
            </Box>
          </DelayedRender>
        )}
      {availabilityError && (
        <Box mt="12px" textAlign="center">
          <ErrorComponent error={availabilityError} />
        </Box>
      )}
      {staffScheduleError && (
        <Box mt="12px" textAlign="center">
          <ErrorComponent error={staffScheduleError} />
        </Box>
      )}
      <Flexbox>
        {!availabilityIsFetching &&
          !availabilityError &&
          !staffScheduleError &&
          weekdays.map((weekday, index) => {
            if (
              currentDayIndex > index &&
              Math.abs(currentDay.diff(startDate, "days").days) < 7
            ) {
              return (
                <Flexbox
                  flexDirection="column"
                  padding="16px"
                  key={weekday}
                  flexBasis="20%"
                  minWidth="143px"
                ></Flexbox>
              );
            } else {
              const day = currentDay.plus({ days: index - currentDayIndex });
              const availableDays = availabilityMap?.[day.toISODate()];
              return (
                <Flexbox
                  flexDirection="column"
                  padding="16px"
                  key={weekday}
                  flexBasis="20%"
                  minWidth="143px"
                >
                  <Flexbox gap="12px" flexDirection="column">
                    {availableDays?.map((availability) => {
                      const selectedButton =
                        availability.start === selectedStartDate;
                      if (
                        // this is for backward compatibility with backend changes
                        typeof availability.available_appointments !==
                          "object" ||
                        // we can remove the above line once ENG-4696 is released
                        availability.available_appointments[appointmentType] > 0
                      ) {
                        const dateToMap = DateTime.fromISO(
                          availability.start
                        ).setZone(timezone);
                        return (
                          <Button
                            variant="outlined"
                            key={availability.start}
                            sx={{
                              width: "100%",
                              height: "50px",
                              ...(selectedButton && {
                                backgroundColor: blue[700],
                                color: "white"
                              }),
                              "&:hover": {
                                ...(selectedButton && {
                                  backgroundColor: blue[700],
                                  color: "white"
                                })
                              }
                            }}
                            onClick={() => {
                              dispatch(
                                setStartEndDate({
                                  startDate: availability.start,
                                  endDate: availability.end
                                })
                              );
                            }}
                          >
                            <Box
                              minWidth="max-content"
                              id={`${dateToMap.toFormat("h:mm")}-${weekday}`}
                              data-testid={dateToMap.toFormat("MM-dd")}
                              aria-label="Available Date"
                            >
                              {DateTime.fromISO(availability.start)
                                .setZone(timezone)
                                .toFormat("h:mm")}
                              &nbsp;-&nbsp;
                              {DateTime.fromISO(availability.end)
                                .setZone(timezone)
                                .toFormat("h:mm a")}
                            </Box>
                          </Button>
                        );
                      }
                    })}
                  </Flexbox>
                </Flexbox>
              );
            }
          })}
      </Flexbox>
    </>
  );
}

const appointmentTypeTimeMappings = {
  [AppointmentTypeEnum.NURSE_FOLLOWUP]: 20,
  [AppointmentTypeEnum.PROVIDER_FOLLOWUP]: 30,
  [AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP]: 40
};

// this variable is 80 because we overbook 60 minute windows
const timeBlockTime = 80;

/**
 * Checks if we can schedule an appointment of a given type based on the number of existing appointments of that type
 * @param appointmentsInTimeBlock
 * {
 *   NURSE_FOLLOWUP: number;
 *   PROVIDER_FOLLOWUP: number;
 *   TELEHEALTH_NURSE_SETUP: number;
 * }
 * @param {AppointmentTypeEnum} appointmentType
 */

function canScheduleInTimeBlock(
  appointmentsInTimeBlock: number,
  appointmentType: AppointmentTypeEnum
) {
  if (isFalsy(appointmentsInTimeBlock)) {
    return true;
  }
  const timeLeft =
    timeBlockTime -
    appointmentsInTimeBlock[AppointmentTypeEnum.NURSE_FOLLOWUP] *
      appointmentTypeTimeMappings[AppointmentTypeEnum.NURSE_FOLLOWUP] -
    appointmentsInTimeBlock[AppointmentTypeEnum.PROVIDER_FOLLOWUP] *
      appointmentTypeTimeMappings[AppointmentTypeEnum.PROVIDER_FOLLOWUP] -
    appointmentsInTimeBlock[AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP] *
      appointmentTypeTimeMappings[AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP];
  switch (appointmentType) {
    case AppointmentTypeEnum.NURSE_FOLLOWUP:
      return (
        timeLeft >=
        appointmentTypeTimeMappings[AppointmentTypeEnum.NURSE_FOLLOWUP]
      );
    case AppointmentTypeEnum.PROVIDER_FOLLOWUP:
      return (
        timeLeft >=
        appointmentTypeTimeMappings[AppointmentTypeEnum.PROVIDER_FOLLOWUP]
      );
    case AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP:
      return (
        timeLeft >=
        appointmentTypeTimeMappings[AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP]
      );
    // we should never hit this case - just here for completeness
    default:
      return false;
  }
}

const recurrenceFrequencyWeekMappings = {
  [RecurrenceFrequencyEnum.NEVER]: 0,
  [RecurrenceFrequencyEnum.WEEKLY]: 1,
  [RecurrenceFrequencyEnum.BIWEEKLY]: 2
};

const recurrenceUpdateTypeWeekMappings = {
  [RecurrenceUpdateTypeEnum.ONCE]: 0,
  [RecurrenceUpdateTypeEnum.THIS_EVENT_FORWARD]: 1
};

function getFinalAvailabilityMap(
  availabilityMap,
  staffScheduleMap,
  recurrence,
  recurrenceUpdateType,
  appointmentType,
  timezone,
  daysToLookAhead = DAYS_TO_LOOK_AHEAD * 2
) {
  // feature flag - keep this code in case we need it - calculating recurring availabilty on the front end
  if (recurrence === RecurrenceFrequencyEnum.NEVER) {
    return availabilityMap;
  } else {
    // get the number of weeks between occurrences
    const weekAmount =
      recurrenceFrequencyWeekMappings?.[recurrence] ??
      // tbd we need to know the frequency of the recurrence which we don't have yet - backend can handle this in ENG-4827
      // let's default this to recurring every ONE week for now
      recurrenceUpdateTypeWeekMappings?.[recurrenceUpdateType];
    const keys = Object.keys(availabilityMap);
    // make a clone - we don't want to mutate the original object
    const clone = cloneDeep(availabilityMap);
    const maxWeeks = Math.floor(daysToLookAhead / 7);
    keys.forEach((key) => {
      clone[key].forEach((availability) => {
        const start = DateTime.fromISO(availability.start);
        const end = DateTime.fromISO(availability.end);
        const dateKey = start.setZone(timezone).toISODate();
        let canSchedule = true;
        // if there are some appointments scheduled, we need to double check availability
        if (staffScheduleMap?.[dateKey]) {
          let iterator = 1;
          while (iterator <= maxWeeks) {
            const startPlus = start
              .plus({ weeks: weekAmount * iterator })
              .setZone("utc")
              .toISO({ suppressMilliseconds: true });
            const endPlus = end
              .plus({ weeks: weekAmount * iterator })
              .setZone("utc")
              .toISO({ suppressMilliseconds: true });
            const dateKeyPlus = start
              .plus({ weeks: weekAmount * iterator })
              .setZone(timezone)
              .toISODate();
            if (staffScheduleMap?.[dateKeyPlus]?.[`${startPlus}-${endPlus}`]) {
              canSchedule =
                canSchedule &&
                canScheduleInTimeBlock(
                  staffScheduleMap?.[dateKeyPlus]?.[`${startPlus}-${endPlus}`]
                    ?.appointments,
                  appointmentType
                );
            }
            iterator++;
          }
        }
        if (!canSchedule) {
          // remove from availability
          clone[key] = clone[key].filter((item) => {
            return availability.start !== item.start;
          });
        }
      });
    });
    return clone;
  }
}

function DisplayWeekAvailability({
  currentDay,
  startDate,
  availabilityMap,
  availabilityIsFetching,
  availabilityIsUninitialized,
  availabilityError,
  appointmentType,
  recurrence,
  recurrenceUpdateType,
  timezone,
  staffScheduleMap,
  staffScheduleError,
  eventId
}: Readonly<{
  currentDay: DateTime;
  startDate: DateTime;
  availabilityMap: any;
  availabilityIsFetching: boolean;
  availabilityIsUninitialized: boolean;
  availabilityError: unknown;
  appointmentType: AppointmentTypeEnum;
  recurrence: RecurrenceFrequencyEnum;
  recurrenceUpdateType: RecurrenceUpdateTypeEnum;
  timezone: string;
  staffScheduleMap: any;
  staffScheduleError: unknown;
  eventId: string;
}>) {
  const { selectedStartDate, staffId } = useSelector(
    (state: RootState) => state.appointment
  );
  const currentDayIndex = currentDay.weekday - 1;

  const isAvailableThisWeek = hasAvailabilityInCurrentWeek(
    currentDay,
    availabilityMap
  );

  // reset appointment state on mount
  useEffect(() => {
    dispatch(resetAppointmentState());
    return () => {
      dispatch(resetAppointmentState());
    };
  }, []);

  const [isCalculatingAvailability, setIsCalculatingAvailability] =
    useState<boolean>(false);

  const [finalAvailabilityMap, setFinalAvailabilityMap] = useState<any>(null);

  useEffect(() => {
    setIsCalculatingAvailability(true);
    const finalMap = getFinalAvailabilityMap(
      availabilityMap,
      staffScheduleMap,
      recurrence,
      recurrenceUpdateType,
      appointmentType,
      timezone
    );

    setFinalAvailabilityMap(finalMap);
    setIsCalculatingAvailability(false);
  }, [recurrence, recurrenceUpdateType, staffScheduleMap, availabilityMap]);

  return (
    <Box>
      <Flexbox sx={{ borderBottom: `1px solid ${gray[300]}` }}>
        {weekdays.map((weekday, index) => {
          if (
            currentDayIndex > index &&
            Math.abs(currentDay.diff(startDate, "days").days) < 7
          ) {
            return (
              <Flexbox
                flexDirection="column"
                alignItems="center"
                padding="16px"
                key={weekday}
                flexBasis="20%"
                minWidth="143px"
              >
                <Typography variant="h5">{weekday}</Typography>
                <Typography variant="body1" color={gray[600]}>
                  {currentDay
                    .minus({ days: currentDayIndex - index })
                    .toFormat("MMM d")}
                </Typography>
              </Flexbox>
            );
          } else {
            const day = currentDay.plus({ days: index - currentDayIndex });
            return (
              <Flexbox
                flexDirection="column"
                alignItems="center"
                padding="16px"
                key={weekday}
                flexBasis="20%"
                minWidth="143px"
              >
                <Typography variant="h5">{weekday}</Typography>
                <Typography variant="body1" color={gray[600]}>
                  {day.toFormat("MMM d")}
                </Typography>
              </Flexbox>
            );
          }
        })}
      </Flexbox>
      <Box height="100%">
        {(availabilityIsFetching || isCalculatingAvailability) && (
          <LoadingFallback count={15} />
        )}
        {!availabilityIsFetching && !isCalculatingAvailability && staffId && (
          <RenderWeekdays
            appointmentType={appointmentType}
            currentDay={currentDay}
            startDate={startDate}
            selectedStartDate={selectedStartDate}
            availabilityMap={finalAvailabilityMap}
            availabilityIsFetching={availabilityIsFetching}
            availabilityError={availabilityError}
            staffScheduleError={staffScheduleError}
            availabilityIsUninitialized={availabilityIsUninitialized}
            isAvailableThisWeek={isAvailableThisWeek}
            timezone={timezone}
            eventId={eventId}
          />
        )}
      </Box>
    </Box>
  );
}

function ManualReassignSidebar({
  nurses,
  nursesError,
  nursesIsLoading,
  defaultRecurrence
}) {
  const { staffId, recurrence, appointmentType } = useSelector(
    (state: RootState) => state.appointment
  );
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  // TBD change this to support telehealth nurse setup
  const [appointmentTypeState, setAppointmentTypeState] = useState(
    canScheduleNurses(currentRole)
      ? AppointmentTypeEnum.NURSE_FOLLOWUP
      : AppointmentTypeEnum.PROVIDER_FOLLOWUP
  );

  useEffect(() => {
    if (appointmentTypeState !== appointmentType) {
      dispatch(setAppointmentType(appointmentTypeState));
    }
  }, [appointmentTypeState, appointmentType]);

  useEffect(() => {
    if (!recurrence && defaultRecurrence) {
      dispatch(setRecurrence(defaultRecurrence));
    }
  }, [recurrence, defaultRecurrence]);

  const mappedNurses = useMemo(() => {
    return nurses?.map((nurse) => ({
      patient_count: nurse.label.patient_count,
      displayLastNameFirst: nurse.label.displayLastNameFirst,
      displayFirstNameFirst: nurse.label.displayFirstNameFirst,
      value: nurse.value
    }));
  }, [nurses]);

  const selectedNurse = useMemo(() => {
    return mappedNurses?.find((nurse) => nurse.value === staffId);
  }, [staffId, mappedNurses]);

  const [autocompleteIsOpen, setAutocompleteIsOpen] = useState(false);

  return (
    <Flexbox
      flexDirection="column"
      padding="20px"
      borderRadius="12px"
      border={`1px solid ${gray[300]}`}
      gap="20px"
    >
      <Flexbox flexDirection="column" gap="8px">
        {(appointmentType === AppointmentTypeEnum.NURSE_FOLLOWUP ||
          appointmentType === AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP) && (
          <>
            <Flexbox gap="2px">
              <Typography variant="h5" color="text.primary">
                Reassign to:
              </Typography>
            </Flexbox>
            {nursesError && (
              <ErrorComponent
                error={"Error getting nurse list, please try again later."}
              />
            )}
            {mappedNurses?.length > 0 && (
              <Autocomplete
                open={autocompleteIsOpen}
                onOpen={() => setAutocompleteIsOpen(true)}
                onClose={(e, reason) => {
                  setAutocompleteIsOpen(false);
                }}
                options={mappedNurses}
                componentsProps={{
                  popper: {
                    placement: "bottom",
                    sx: { maxWidth: "186px" }
                  }
                }}
                // this needs to be null for autocomplete to prevent a console warning https://stackoverflow.com/a/77059921
                value={selectedNurse ?? null}
                isOptionEqualToValue={(option, value) =>
                  option?.value === value.value
                }
                getOptionLabel={(label) => {
                  if (label) {
                    return `${label?.displayLastNameFirst}`;
                  }
                  return "";
                }}
                renderOption={(props, option) => {
                  return (
                    <Flexbox
                      key={option.value}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: gray[200]
                        }
                      }}
                      padding="6px 12px"
                      onClick={() => {
                        dispatch(setStaffId(option.value));
                        dispatch(setStaffName(option.displayFirstNameFirst));
                        setAutocompleteIsOpen(false);
                      }}
                      justifyContent="space-between"
                    >
                      <Typography
                        data-testid={option?.displayLastNameFirst ?? "N/A"}
                        sx={{ wordBreak: "break-all" }}
                      >
                        {option?.displayLastNameFirst}
                      </Typography>
                      {/* <Typography>{option?.patient_count}</Typography> */}
                    </Flexbox>
                  );
                }}
                onChange={(e, value) => {
                  const selectedValue = nurses?.find(
                    (nurse) => nurse.value === value?.value
                  );
                  if (selectedValue) {
                    dispatch(
                      setStartEndDate({ startDate: null, endDate: null })
                    );
                    dispatch(setStaffId(value.value));
                    dispatch(setStaffName(value.displayFirstNameFirst));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Nurse"
                    name="selectedNurse"
                    id="selectedNurse"
                    data-testid="Select Nurse"
                  />
                )}
              />
            )}
            {nursesIsLoading && <LoadingFallback count={1} />}
          </>
        )}
      </Flexbox>
    </Flexbox>
  );
}

function ManualReassignAppointmentSubmitContainer({
  refetchStaffMemberAvailability,
  refetchCalendarEvents,
  eventId,
  oldStaffId,
  oldStaffName,
  oldStartDate,
  oldEndDate
}) {
  const {
    selectedStartDate,
    selectedEndDate,
    staffId,
    staffName,
    memberTimezone
  } = useSelector((state: RootState) => state.appointment);

  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false);

  const timerRef = useRef(null);

  useEffect(() => {
    if (
      !isFalsy(selectedStartDate) &&
      !isFalsy(selectedEndDate) &&
      !isFalsy(staffId)
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [selectedStartDate, selectedEndDate, staffId]);

  const [
    reassignCalendarEventMutation,
    {
      data: reassignCalendarEventData,
      isLoading: reassignCalendarEventIsLoading,
      error: reassignCalendarEventError,
      fulfilledTimeStamp: reassignCalendarEventFulfilledTimestamp
    }
  ] = useReassignCalendarEventMutation();

  const onSubmit = useCallback(() => {
    const oldDateString = `${DateTime.fromISO(oldStartDate)
      .setZone(memberTimezone)
      .toFormat("cccc LLL, d h:mm")}
            -
            ${DateTime.fromISO(oldEndDate)
              .setZone(memberTimezone)
              .toFormat("h:mm a ZZZZ")}`;

    const nextDateString = `${DateTime.fromISO(selectedStartDate)
      .setZone(memberTimezone)
      .toFormat("cccc LLL, d h:mm")}
          -
          ${DateTime.fromISO(selectedEndDate)
            .setZone(memberTimezone)
            .toFormat("h:mm a ZZZZ")}`;

    Alert_show({
      dispatch,
      id: "reassignResults",
      title: "Reassign Results",
      hideCloseIcon: true,
      content: (
        <div>
          <div>
            The system will reassign the appointment. This action cannot be
            undone.
          </div>
          <div
            style={{
              borderRadius: "8px",
              height: "64px",
              border: "1px solid #d0d5dd",
              margin: "8px 0"
            }}
          >
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center"
              }}
            >
              <div style={{ margin: "8px", width: "50px" }}>From:</div>
              <div>
                <b>
                  {oldStaffName}
                  <br />
                  {oldDateString}
                </b>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "8px",
              height: "64px",
              border: "1px solid #d0d5dd",
              margin: "8px 0"
            }}
          >
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center"
              }}
            >
              <div style={{ margin: "8px", width: "50px" }}>To:</div>
              <div>
                <b>
                  {staffName}
                  <br />
                  {nextDateString}
                </b>
              </div>
            </div>
          </div>
        </div>
      ),
      type: "default",
      size: { width: "640px", height: "500px" },
      buttons: [
        {
          text: "Confirm",
          style: "default",
          onPress: () => {
            reassignCalendarEventMutation({
              staffId,
              oldStaffId,
              eventId,
              body: {
                staff_id: staffId,
                start_date: selectedStartDate,
                end_date: selectedEndDate
              }
            });
            Alert_close({ dispatch, id: "reassignResults" });
            setLoadingModalOpen(true);
          }
        },
        {
          text: "Go Back",
          style: "cancel",
          onPress: () => {
            Alert_close({ dispatch, id: "reassignResults" });
          }
        }
      ]
    });
  }, [selectedStartDate, selectedEndDate, staffId, memberTimezone, staffName]);

  useEffect(() => {
    if (reassignCalendarEventData) {
      timerRef.current = setTimeout(() => {
        setLoadingModalOpen(false);

        dispatch(resetAppointmentSelections());
        const id = "reassignCalendarEventSuccess";
        const dateString = `${DateTime.fromISO(selectedStartDate)
          .setZone(memberTimezone)
          .toFormat("cccc LLL, d h:mm")}
            -
            ${DateTime.fromISO(selectedEndDate)
              .setZone(memberTimezone)
              .toFormat("h:mm a ZZZZ")}`;

        Alert_show({
          dispatch,
          id,
          title: "Success",
          content: (
            <Box>
              <div>The appointment has been reassigned successfully.</div>
              <div>Who: {staffName}</div>
              <div>Date: {dateString}</div>
            </Box>
          ),
          type: "success",
          size: "medium",
          buttons: [
            {
              text: "Close",
              onPress: () => {
                Alert_close({ dispatch, id });
                navigate(`/nurses-schedules/time-off/${state?.staffId}`, {
                  state
                });
              }
            }
          ]
        });
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [reassignCalendarEventData]);

  useEffect(() => {
    if (reassignCalendarEventError) {
      const id = "reassignCalendarEventError";
      dispatch(setStartEndDate({ startDate: null, endDate: null }));
      refetchCalendarEvents();
      refetchStaffMemberAvailability();
      setLoadingModalOpen(false);
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={reassignCalendarEventError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [reassignCalendarEventError]);

  return (
    <>
      <Flexbox flexDirection="column" gap="8px">
        <LoadingButton
          variant="contained"
          disabled={isButtonDisabled}
          onClick={onSubmit}
          loading={loadingModalOpen}
        >
          Reassign
        </LoadingButton>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(`/nurses-schedules/time-off/${state?.staffId}`, {
              state
            });
            dispatch(resetAppointmentState());
          }}
        >
          Back
        </Button>
      </Flexbox>
      <StyledModal
        isOpen={loadingModalOpen}
        modalHeight="72px"
        modalWidth="180px"
        minWidth="180px"
        contentLabel="Working"
      >
        <Flexbox height="100%" justifyContent="center">
          <Flexbox
            position="relative"
            width="60px"
            height="100%"
            alignItems="center"
          >
            <Spinner loading="true" />
          </Flexbox>
          <Flexbox height="100%" alignItems="center">
            Working
          </Flexbox>
        </Flexbox>
      </StyledModal>
    </>
  );
}

const ManualReassignAppointment = () => {
  const { eventId } = useParams();

  const {
    data: calendarEventData,
    isFetching: isFetchingCalendarEventData,
    error: calendarEventError
  } = useGetCalendarEventQuery(
    {
      event_id: eventId
    },
    { skip: !checkIdValid(eventId) }
  );
  const memberId = useMemo(() => {
    return calendarEventData?.attendees?.find(
      (item) => item?.attendee_type === "PATIENT"
    )?.attendee_id;
  }, [calendarEventData]);

  const {
    data: memberData,
    isFetching: isFetchingMemberData,
    error: memberError
  } = useGetMemberWithUsernameQuery(
    {
      username: memberId,
      linked_entities: [
        MemberLinkedEntitiesEnum.PROVIDER,
        MemberLinkedEntitiesEnum.NURSE
      ]
    },
    { skip: !checkIdValid(memberId) }
  );

  const traceId = useMemo(() => `appointments-${uuidv4()}`, []);

  useEffect(() => {
    dispatch(setXTraceId(traceId));
  }, [traceId]);

  const isValidId = checkIdValid(memberId);

  const isFetching = isFetchingMemberData || isFetchingCalendarEventData;

  if (!isValidId && calendarEventData && !isFetching) {
    return (
      <Typography variant="body1">{`Invalid Member ID ${memberId}`}</Typography>
    );
  }

  if (memberError) {
    return <ErrorComponent error={memberError} />;
  }

  return (
    <>
      {isFetching && <LoadingFallback count={15} />}
      {calendarEventData && memberData && (
        <ManualReassignAppointmentRender
          event={calendarEventData}
          memberData={memberData}
          memberId={memberId}
        />
      )}
      {calendarEventError && <ErrorComponent error={calendarEventError} />}
    </>
  );
};

const ManualReassignAppointmentRender = ({ event, memberData, memberId }) => {
  const operationType = AppointmentOperationTypeEnum.MANUAL_REASSIGN;

  const eventId = event?.event_id;
  const editAppointmentType = event?.appointment_type;

  const dispatch = useAppDispatch();

  const { user, currentRole } = useSelector((state: RootState) => state.auth);
  const {
    staffId,
    memberTimezone,
    appointmentType,
    recurrence,
    recurrenceUpdateType,
    xTraceId
  } = useSelector((state: RootState) => state.appointment);

  const [availabilityMap, setAvailabilityMap] = useState<{
    [key: string]: StaffAvailabilityObject[];
  }>({});
  const [staffScheduleMap, setStaffScheduleMap] = useState<{
    [key: string]: any;
  }>({});

  const [startDate, setStartDate] = useState(
    getStartDate(user?.timezone, currentRole, event?.startdate)
  );

  useEffect(() => {
    if (Object.keys(availabilityMap)?.length > 0) {
      const isAvailableFirstWeek = hasAvailabilityInCurrentWeek(
        startDate,
        availabilityMap
      );

      if (!isAvailableFirstWeek) {
        const nextWeek = startDate.plus({ weeks: 1 });
        setSelectedDate(nextWeek);
      }
    }
  }, [availabilityMap, staffId]);

  const endDate = startDate.endOf("week").plus({ days: DAYS_TO_LOOK_AHEAD });

  const threeMonthsOut = startDate
    .startOf("day")
    .plus({ days: DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING });

  let calendarEventsEndDate = threeMonthsOut;
  // start date is different when editing recurring events
  if (
    threeMonthsOut >
    DateTime.now()
      .startOf("day")
      .plus({ days: DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING })
  ) {
    calendarEventsEndDate = DateTime.now()
      .startOf("day")
      .plus({ days: DAYS_TO_LOOK_AHEAD_PROVIDER_CALENDARING });
  }

  const [selectedDate, setSelectedDate] = useState(startDate);

  useEffect(() => {
    if (isFalsy(appointmentType) && editAppointmentType) {
      dispatch(setAppointmentType(editAppointmentType));
    }
  }, [editAppointmentType, appointmentType]);

  useEffect(() => {
    if (memberData?.patient?.email) {
      dispatch(
        setAttendees([
          {
            attendee_type: "PATIENT",
            patient_id: memberId,
            email: memberData?.patient?.email
          }
        ])
      );
      dispatch(setMemberName(getNameOrUsername(memberData?.patient)));
    }
  }, [memberData]);

  const {
    data: availabilityData,
    isFetching: availabilityIsFetching,
    error: availabilityError,
    isUninitialized: availabilityIsUninitialized,
    refetch: refetchStaffMemberAvailability
  } = useGetStaffAvailabilityQuery(
    {
      staff_id: staffId,
      startdate: startDate?.toISO(),
      enddate: endDate?.toISO(),
      xTraceId
      // we always reassign only one instance and not the entire series so we don't send recurrence_frequency
    },
    { skip: !staffId }
  );

  const {
    data: staffScheduleData,
    isFetching: staffScheduleIsFetching,
    error: staffScheduleError,
    refetch: refetchCalendarEvents
  } = useGetCalendarEventsQuery(
    {
      staff_id: staffId,
      startdate: startDate,
      // get 12 weeks of events to look ahead and check availability for recurring appointments
      enddate: calendarEventsEndDate
    },
    { skip: isFalsy(staffId) }
  );

  // once we get staff schedule data, create a dictionary of events by date
  useEffect(() => {
    const memberTZ = memberData?.patient?.timezone;
    if (memberTZ && staffScheduleData?.length > 0) {
      const staffScheduleMap = {};

      // create map
      for (let i = 0; i < DAYS_TO_LOOK_AHEAD * 2; i++) {
        const date = startDate.plus({ days: i });
        const dateKey = date.setZone(memberTZ).toISODate();
        if (!staffScheduleMap[dateKey]) {
          staffScheduleMap[dateKey] = {};
        }
      }

      // add events to map
      staffScheduleData.forEach((item) => {
        const date = DateTime.fromISO(item.startdate).setZone(memberTZ);
        const dateKey = date.toISODate();

        const startendKey = `${item.startdate}-${item.enddate}`;

        if (!staffScheduleMap[dateKey]) {
          staffScheduleMap[dateKey] = {};
        }

        if (!staffScheduleMap[dateKey][startendKey]) {
          staffScheduleMap[dateKey][startendKey] = {
            appointments: {
              NURSE_FOLLOWUP: 0,
              PROVIDER_FOLLOWUP: 0,
              TELEHEALTH_NURSE_SETUP: 0
            }
          };

          staffScheduleMap[dateKey][startendKey].appointments[
            item.appointment_type
          ] =
            staffScheduleMap[dateKey][startendKey].appointments[
              item.appointment_type
            ] + 1;
        } else {
          staffScheduleMap[dateKey][startendKey].appointments[
            item.appointment_type
          ] =
            staffScheduleMap[dateKey][startendKey].appointments[
              item.appointment_type
            ] + 1;
        }
      });

      setStaffScheduleMap(staffScheduleMap);
    }
  }, [memberData, staffScheduleData]);

  useEffect(() => {
    const memberTZ = memberData?.patient?.timezone;
    if (memberTZ && memberTimezone !== memberTZ) {
      dispatch(setMemberTimezone(memberTZ));
    }
    dispatch(setAssignedNurseId(memberData?.assigned_nurse?.user_id));
    if (memberTZ && availabilityData?.length > 0) {
      const availabilityMap = {};

      // create map
      for (let i = 0; i < DAYS_TO_LOOK_AHEAD * 2; i++) {
        const date = startDate.plus({ days: i });
        const dateKey = date.setZone(memberData?.patient?.timezone).toISODate();
        if (!availabilityMap[dateKey]) {
          availabilityMap[dateKey] = [];
        }
      }

      // add events to map
      availabilityData.forEach((availability) => {
        const date = DateTime.fromISO(availability.start).setZone(memberTZ);

        // tbd change this to only be for TNs once we support provider appointments
        if (!dateIsBlocked(availability)) {
          const dateKey = date.toISODate();

          if (!availabilityMap[dateKey]) {
            availabilityMap[dateKey] = [];
          }

          // skip holidays - this check should be on the backend too
          if (isBusinessDay(date)) {
            availabilityMap[dateKey].push(availability);
          }
        }
      });
      setAvailabilityMap(availabilityMap);
    }
  }, [memberData, availabilityData]);

  const {
    data: nurses,
    error: nursesError,
    isLoading: nursesIsLoading
  } = useGetSortedMembersWithActiveNursesQuery(
    {
      carer_roles: [RolesEnum.TH_NURSE, RolesEnum.THN_MANAGER],
      carer_status: [UserStatusEnum.ACTIVE],
      getNurseListForAppointments: true,
      is_excluded_from_scheduling: false
    },
    { skip: !canScheduleNurses(currentRole) }
  );

  const defaultRecurrence = RecurrenceFrequencyEnum.NEVER;

  return (
    <ManualReassignAppointmentContainer
      key={`${operationType}${eventId ?? ""}`}
    >
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="scroll"
      >
        <Typography variant="h2" color="text.primary">
          Manual Reassignment
        </Typography>
        {memberData && (
          <Typography variant="h5" color="text.primary" fontWeight={500}>
            Reassigning appointment for {getNameOrUsername(memberData?.patient)}
            &nbsp;scheduled for&nbsp;
            {DateTime.fromISO(event.startdate)
              .setZone(memberData?.patient?.timezone)
              .toFormat("cccc LLL, d h:mm")}
            &nbsp;-&nbsp;
            {DateTime.fromISO(event.enddate)
              .setZone(memberData?.patient?.timezone)
              .toFormat("h:mm a ZZZZ")}
          </Typography>
        )}

        <Flexbox gap="8px" alignItems="center">
          <Typography variant="h2">
            {selectedDate.toFormat("MMMM y")}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedDate(selectedDate.minus({ days: 7 }));
            }}
            disabled={startDate.toISODate() === selectedDate.toISODate()}
          >
            <ChevronLeft />
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedDate(selectedDate.plus({ days: 7 }));
            }}
            disabled={
              Math.abs(selectedDate.diff(startDate, "days").days) >=
              // only show 6 weeks of data into the future
              DAYS_TO_LOOK_AHEAD - 7
            }
          >
            <ChevronRight />
          </Button>
          <Typography variant="h5">Times are in member time zone.</Typography>
        </Flexbox>
        <Flexbox gap="15px" flexDirection="row">
          <Box
            flex="1"
            sx={{
              background: "#ffffff",
              border: `1px solid ${gray[300]}`,
              borderRadius: "12px",
              transition: "all 0.66s ease-out"
            }}
            width="100%"
            minWidth="min-content"
          >
            <DisplayWeekAvailability
              key={`appointment-${memberId}`}
              currentDay={selectedDate}
              startDate={startDate}
              appointmentType={appointmentType}
              recurrence={recurrence}
              recurrenceUpdateType={recurrenceUpdateType}
              availabilityMap={availabilityMap}
              availabilityIsFetching={
                availabilityIsFetching || staffScheduleIsFetching
              }
              availabilityError={availabilityError}
              availabilityIsUninitialized={availabilityIsUninitialized}
              timezone={memberData?.patient?.timezone}
              staffScheduleMap={staffScheduleMap}
              staffScheduleError={staffScheduleError}
              eventId={eventId}
            />
          </Box>
          <Flexbox
            flexDirection="column"
            flexBasis="228px"
            width="228px"
            gap="20px"
          >
            <ManualReassignSidebar
              key={`appointment-${memberId}`}
              nurses={nurses}
              nursesError={nursesError}
              nursesIsLoading={nursesIsLoading}
              defaultRecurrence={defaultRecurrence}
            />

            <ManualReassignAppointmentSubmitContainer
              refetchStaffMemberAvailability={refetchStaffMemberAvailability}
              refetchCalendarEvents={refetchCalendarEvents}
              eventId={eventId}
              oldStaffId={event?.staff?.id}
              oldStaffName={getNameOrUsername(event?.staff, false)}
              oldStartDate={event?.startdate}
              oldEndDate={event?.enddate}
            />
          </Flexbox>
        </Flexbox>
      </Flexbox>
    </ManualReassignAppointmentContainer>
  );
};

export default ManualReassignAppointment;
