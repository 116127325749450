enum CareFlowFieldTypeEnum {
  TEXT = "TEXT",
  RADIO = "RADIO",
  SELECT = "SELECT",
  MULTISELECT = "MULTISELECT",
  CHECKBOX = "CHECKBOX",
  COMPONENT = "COMPONENT",
  SHORT_INPUT = "SHORTINPUT",
  LONG_INPUT = "LONGINPUT",
  MULTI = "MULTI"
}

export default CareFlowFieldTypeEnum;
