import { LoadingButton } from "@mui/lab";
import { getAthenaPatientUrl } from "common/helpers/EnvVarsHelper";
import { useCreateOnboardingAppointmentMutation } from "common/services/MemberRegistrationService";
import { useNavigate } from "react-router-dom";

export default function StartIntakeButton({
  athena_id,
  member_id
}: {
  athena_id?: string;
  member_id?: string;
}) {
  const navigate = useNavigate();

  const [createOnboardingAppointmentMutation, { isSuccess, isLoading }] =
    useCreateOnboardingAppointmentMutation();

  return (
    <>
      {Boolean(athena_id) && (
        <LoadingButton
          variant="outlined"
          color="primary"
          loading={isLoading}
          disabled={isSuccess}
          onClick={() => {
            createOnboardingAppointmentMutation({ athena_id });
            window.open(getAthenaPatientUrl(athena_id), "_blank");

            navigate(`/members/memberId/${member_id}/start-intake`);
          }}
        >
          Start Intake
        </LoadingButton>
      )}
    </>
  );
}
