const GoogleDocLinks = {
  CLINICAL_ESCALATION_CRITERIA:
    "https://docs.google.com/document/d/10of_VWU3WEHLgFO3csODg0lPFR0vZCtT-FGnJaC0AAo/edit",
  DEVICE_SETUP_GUIDE:
    "https://docs.google.com/document/d/1652JbBr9cTefi6Ja1u0oTZxImPD5zx93roI2hHyosbk/edit",
  MISSING_AGREEMENTS:
    "https://docs.google.com/document/d/17SLj18H2bYokQLVF2kyqC_QjYtl_WVrZU3ILi5z2CKA/edit"
};

export default GoogleDocLinks;
