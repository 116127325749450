import { useState, useCallback, useMemo } from "react";
import CustomSearchTable from "./CustomSearchTable";
import styled from "@emotion/styled";
import UserSearchInput from "../UserSearchInput/UserSearchInput";
import {
  ComponentHeader,
  SuccessText,
  TableComponentContainer
} from "../../styling/StyleComponents";
import { AssignNursePatientModal } from "../../helpers/components/Forms/AssignNursePatientModal/AssignNursePatientModal";
import { TableColumn } from "../Table/TableTypes";
import TableHeaderUserCount from "../Table/TableHeaderUserCount";
import MemberType from "common/types/MemberType";
import { AssignProviderPatientModal } from "../../helpers/components/Forms/AssignProviderPatientModal/AssignProviderPatientModal";

import MemberStatusEnum from "common/enums/MemberStatusEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import ErrorType from "common/types/ErrorType";

const OuterContainer = styled.div`
  border: 1px solid #e6e7e9;
  border-radius: 4px;
  background: white;
  transition: height 0.66s ease-out;
  overflow-x: auto;
  margin: 2.5%;
`;

const TableHeading = styled(ComponentHeader)`
  margin: 24px 2.5%;
`;

const StyledSuccessText = styled(SuccessText)`
  margin: 24px 2.5%;
`;

interface IProps {
  tableColumns: TableColumn[];
  tableHeader?: string;
  userListFilters: {
    status?: MemberStatusEnum[];
    linked_entities?: MemberLinkedEntitiesEnum[];
  };
  externalLink: string;
  componentHeader: string;
}

const CustomSearchContainer = ({
  tableColumns,
  tableHeader,
  userListFilters,
  externalLink,
  componentHeader
}: IProps) => {
  const [data, setData] = useState<MemberType[]>();
  const setDataCallback = useCallback(
    (users?: MemberType[]) => {
      setData(users);
    },
    [setData]
  );

  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const setDataLoadingCallback = useCallback(
    (isDataLoading: boolean) => {
      setDataLoading(isDataLoading);
    },
    [setDataLoading]
  );

  const [dataError, setDataError] = useState<ErrorType>();
  const setDataErrorCallback = useCallback(
    (dataError: ErrorType) => {
      setDataError(dataError);
    },
    [setDataError]
  );

  const hasActionsColumn = useMemo(() => {
    return (
      tableColumns.findIndex(
        (col: TableColumn) => col.name === "patientActions"
      ) > -1
    );
  }, [tableColumns]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [providerModalOpen, setProviderModalOpen] = useState<boolean>(false);
  const [selectedPatientId, setSelectedPatientId] = useState<string>();
  const [assignNursePatientResponse, setAssignNursePatientResponse] =
    useState<MemberType>();
  const [assignProviderPatientResponse, setAssignProviderPatientResponse] =
    useState<MemberType>();
  const setModalStateCallback = useCallback(
    (val: string) => {
      if (val === "open") {
        setModalOpen(true);
      } else {
        setModalOpen(false);
      }
    },
    [setModalOpen]
  );
  const setProviderModalStateCallback = useCallback(
    (val: string) => {
      if (val === "open") {
        setProviderModalOpen(true);
      } else {
        setProviderModalOpen(false);
      }
    },
    [setProviderModalOpen]
  );
  const setSelectedPatientIdCallback = useCallback(
    (val: string) => {
      setSelectedPatientId(val);
    },
    [setSelectedPatientId]
  );
  const setAssignNursePatientResponseCallback = useCallback(
    (val: MemberType) => {
      setAssignNursePatientResponse(val);
    },
    [setAssignNursePatientResponse]
  );
  const setAssignProviderPatientResponseCallback = useCallback(
    (val: MemberType) => {
      setAssignProviderPatientResponse(val);
    },
    [setAssignProviderPatientResponse]
  );

  return (
    <OuterContainer>
      <TableHeading>{componentHeader}</TableHeading>
      <UserSearchInput
        key="custom-search-input"
        containerStyles={`margin: 24px 2.5%;`}
        searchContainerWidth="353px"
        inputFieldType="table"
        onSubmitProps={{ userListFilters }}
        dataError={dataError}
        dataLoadingProps={{
          setDataCallback,
          setDataLoadingCallback,
          setDataErrorCallback
        }}
      />
      {data && (
        <TableHeaderUserCount
          padding={"0px 2.5%"}
          margin={"0px"}
          users={data}
          tableHeader={tableHeader}
        />
      )}
      {assignNursePatientResponse && (
        <StyledSuccessText>
          Successfully linked nurse to member.
        </StyledSuccessText>
      )}

      {assignProviderPatientResponse && (
        <StyledSuccessText>
          Successfully linked provider to member.
        </StyledSuccessText>
      )}
      <TableComponentContainer
        hideBorder={true}
        margin={"0px 2.5%"}
        width="95%"
        padding={"0px 0%"}
        sx={{ flex: 1, display: "flex" }}
      >
        <CustomSearchTable
          key={componentHeader}
          data={data}
          dataLoading={dataLoading}
          dataError={dataError}
          tableColumns={tableColumns}
          externalLink={externalLink}
          setModalStateCallback={setModalStateCallback}
          setSelectedPatientIdCallback={setSelectedPatientIdCallback}
          setAssignNursePatientResponseCallback={
            setAssignNursePatientResponseCallback
          }
          setProviderModalStateCallback={setProviderModalStateCallback}
          setAssignProviderPatientResponseCallback={
            setAssignProviderPatientResponseCallback
          }
        />
      </TableComponentContainer>
      {hasActionsColumn && (
        <>
          <AssignNursePatientModal
            key={`assignnurse_${selectedPatientId}`}
            modalOpen={modalOpen}
            setModalStateCallback={setModalStateCallback}
            selectedPatientId={selectedPatientId}
            setAssignNursePatientResponseCallback={
              setAssignNursePatientResponseCallback
            }
          />
          <AssignProviderPatientModal
            key={`assignprovider_${selectedPatientId}`}
            modalOpen={providerModalOpen}
            setModalStateCallback={setProviderModalStateCallback}
            selectedPatientId={selectedPatientId}
          />
        </>
      )}
    </OuterContainer>
  );
};

export default CustomSearchContainer;
