import { TurqoiseButton } from "../../../../styling";
import {
  ModalBody,
  ModalHeader,
  StyledModal
} from "../../../../styling/StyleModal";
import {
  useUpdateDeviceStatusMutation,
  useUpdateDeviceExtraMutation
} from "common/services/DevicesService";
import { Box, Typography, MenuItem } from "@mui/material";
import styled from "@emotion/styled";
import { Flexbox } from "../../../../styling/NewStyleComponents";
import DeviceType from "common/types/DeviceType";
import { StyledTextField } from "../FormHelpers";
import { DateTime } from "luxon";
import { useFormik } from "formik";
import DeviceStatusEnum from "common/enums/DeviceStatusEnum";
import { isFalsy } from "common/helpers/helpers";
import { useEffect, useState } from "react";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { RootState, useAppDispatch } from "common/redux";
import ErrorComponent from "../../../../components/ErrorComponent";
import { canSeeVendorReplacementForm } from "common/enums/RolesEnum";
import { useSelector } from "react-redux";

interface IProps {
  modalOpen: boolean;
  setModalStateCallback: (val: string, errorMessage?: string) => void;
  selectedDevice: DeviceType | undefined;
  memberId: string;
}

interface FormType {
  notes: string;
  newSerialNumber: string;
  statusUpdateType: DeviceStatusEnum;
}

const NOTES = "notes";
const NEW_SERIAL_NUMBER = "newSerialNumber";
const STATUS_UPDATE = "statusUpdateType";

const DeviceSupportSectionHeader = styled(Typography)`
  margin-bottom: 10px;
`;

const DeviceInfoText = styled(StyledTextField)`
  margin-bottom: 20px;
`;

const DeviceSupportModal = ({
  modalOpen,
  setModalStateCallback,
  selectedDevice,
  memberId
}: IProps) => {
  const dispatch = useAppDispatch();
  const { currentRole } = useSelector((state: RootState) => state.auth);
  const isVendorReplacementFormAllowed =
    canSeeVendorReplacementForm(currentRole);
  let lastModifiedDate = selectedDevice.last_modified
    ? DateTime.fromSQL(selectedDevice.last_modified).toLocaleString(
        DateTime.DATETIME_FULL
      )
    : "";

  const [
    updateDeviceStatusMutation,
    {
      isSuccess: updateDeviceStatusSuccess,
      isLoading: updateDeviceStatusLoading,
      error: updateDeviceStatusError
    }
  ] = useUpdateDeviceStatusMutation();

  const [
    updateDeviceExtraMutation,
    {
      isSuccess: updateExtraSuccess,
      isLoading: updateExtraLoading,
      error: updateExtraError
    }
  ] = useUpdateDeviceExtraMutation();

  const onSubmitNotes = async (values: FormType) => {
    const { notes } = values;
    await updateDeviceExtraMutation({
      memberId,
      device_id: selectedDevice.device_id,
      extra: {
        extra: {
          notes: notes
        }
      }
    });
  };

  const onSubmitVendorReplacement = async (values: FormType) => {
    const { newSerialNumber } = values;
    await updateDeviceStatusMutation({
      device_id: selectedDevice.device_id,
      status: DeviceStatusEnum.VENDOR_REPLACEMENT,
      new_serial_number: newSerialNumber,
      memberId
    });
  };

  const onSubmitUpdateStatus = async (values: FormType) => {
    const { statusUpdateType } = values;

    await updateDeviceStatusMutation({
      device_id: selectedDevice.device_id,
      status: statusUpdateType,
      memberId
    });
  };

  const validate = (values: FormType) => {
    const errors: { [key: string]: string } = {};

    if (values[NOTES]) {
      if (values[NOTES].length > 256) {
        errors[NOTES] = "Max length is 256";
      }
    }

    if (values[STATUS_UPDATE]) {
      if (
        values.statusUpdateType === DeviceStatusEnum.REACTIVATE &&
        selectedDevice.status !== DeviceStatusEnum.PENDING_DEACTIVATION
      ) {
        errors.statusUpdateType =
          "Only Pending Dectivation devices can be reactivated";
      }
    }

    return errors;
  };

  const statusUpdateTypes = [
    { label: "Lost", value: DeviceStatusEnum.PATIENT_LOST },
    { label: "Damaged", value: DeviceStatusEnum.PATIENT_DAMAGED },
    { label: "Defective", value: DeviceStatusEnum.DEFECTIVE },
    { label: "Reactivate", value: DeviceStatusEnum.REACTIVATE }
  ];

  const formik = useFormik<FormType>({
    validate,
    initialValues: {
      notes: selectedDevice.extra?.notes,
      newSerialNumber: "",
      statusUpdateType: DeviceStatusEnum.PATIENT_LOST
    },
    // we are not using formik's onsubmit
    onSubmit: () => {},
    enableReinitialize: true
  });

  const { values: values, setFieldValue, errors, resetForm } = formik;

  function closeModal() {
    setModalStateCallback("close");
    resetForm();
  }

  useEffect(() => {
    if (updateExtraSuccess) {
      closeModal();
      const id = "updateExtraSuccess";
      Alert_show({
        dispatch,
        id,
        title: "Success",
        content: "Details have been successfully updated.",
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [updateExtraSuccess]);

  useEffect(() => {
    if (updateDeviceStatusSuccess) {
      closeModal();
      const id = "updateDeviceStatusSuccess";
      Alert_show({
        dispatch,
        id,
        title: "Success",
        content: "Details have been successfully updated.",
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [updateDeviceStatusSuccess]);

  return (
    <StyledModal
      isOpen={modalOpen}
      contentLabel="Device Support Modal"
      modalHeight="92vh"
      onRequestClose={() => setModalStateCallback("close")}
    >
      <ModalHeader onRequestClose={() => setModalStateCallback("close")}>
        Device Support
      </ModalHeader>
      <ModalBody>
        <DeviceSupportSectionHeader variant="h4" color="text.primary">
          Device Info
        </DeviceSupportSectionHeader>

        <Flexbox gap="16px">
          <Box width="50%">
            <DeviceInfoText
              disableinput={true}
              disabled={true}
              label="Device ID"
              value={selectedDevice?.device_id}
              fullWidth
            />
            <DeviceInfoText
              disableinput={true}
              disabled={true}
              label="Device Status"
              value={selectedDevice?.status}
              fullWidth
            />
            <DeviceInfoText
              disableinput={true}
              disabled={true}
              label="Device Sub Status"
              value={selectedDevice?.status_reason}
              fullWidth
            />
          </Box>
          <Box width="50%">
            <DeviceInfoText
              disableinput={true}
              disabled={true}
              label="Serial Number"
              value={selectedDevice?.extra?.vendor_device_id}
              fullWidth
            />
            <DeviceInfoText
              disableinput={true}
              disabled={true}
              label="Device Type"
              value={selectedDevice?.extra?.type}
              fullWidth
            />
            <DeviceInfoText
              disableinput={true}
              disabled={true}
              label="Last Modified"
              value={lastModifiedDate}
              fullWidth
            />
          </Box>
        </Flexbox>

        <DeviceSupportSectionHeader variant="h4" color="text.primary">
          Support Actions
        </DeviceSupportSectionHeader>
        <form>
          <DeviceSupportSectionHeader variant="h6" color="text.primary">
            Update Notes
          </DeviceSupportSectionHeader>

          <Flexbox gap="16px">
            <Box width="50%">
              <DeviceInfoText
                label="Notes"
                value={values[NOTES] || ""}
                placeholder={selectedDevice.extra?.notes ? "" : "Notes"}
                fullWidth
                rows={4}
                multiline
                onChange={(event) => setFieldValue(NOTES, event.target.value)}
                error={!isFalsy(errors[NOTES])}
                helperText={errors[NOTES]}
              />
            </Box>
            <Box width="50%">
              <TurqoiseButton
                disabled={!isFalsy(errors[NOTES])}
                onClick={() => {
                  onSubmitNotes(values);
                }}
                loading={updateExtraLoading}
              >
                Submit
              </TurqoiseButton>
            </Box>
          </Flexbox>

          <DeviceSupportSectionHeader variant="h6" color="text.primary">
            Update Status
          </DeviceSupportSectionHeader>

          <Flexbox gap="16px">
            <Box width="50%">
              <DeviceInfoText
                label="Device Status"
                value={values[STATUS_UPDATE]}
                fullWidth
                select
                onChange={(event) =>
                  setFieldValue(STATUS_UPDATE, event.target.value)
                }
                error={!isFalsy(errors[STATUS_UPDATE])}
                helperText={errors[STATUS_UPDATE]}
              >
                {statusUpdateTypes?.map((statusUpdateType) => (
                  <MenuItem
                    key={statusUpdateType.value}
                    value={statusUpdateType.value}
                  >
                    {statusUpdateType.label}
                  </MenuItem>
                ))}
              </DeviceInfoText>
            </Box>
            <Box width="50%">
              <TurqoiseButton
                disabled={!isFalsy(errors[STATUS_UPDATE])}
                onClick={() => {
                  onSubmitUpdateStatus(values);
                }}
                loading={updateDeviceStatusLoading}
              >
                Submit
              </TurqoiseButton>
            </Box>
          </Flexbox>

          {isVendorReplacementFormAllowed && (
            <div>
              <DeviceSupportSectionHeader variant="h6" color="text.primary">
                Vendor Replacement
              </DeviceSupportSectionHeader>

              <Flexbox gap="16px">
                <Box width="50%">
                  <DeviceInfoText
                    label="New Serial Number"
                    value={values[NEW_SERIAL_NUMBER]}
                    fullWidth
                    onKeyPress={(e) => {
                      e.which === 13 && e.preventDefault();
                    }}
                    onChange={(event) =>
                      setFieldValue(NEW_SERIAL_NUMBER, event.target.value)
                    }
                    error={errors[NEW_SERIAL_NUMBER] !== undefined}
                    helperText={errors[NEW_SERIAL_NUMBER]}
                  />
                </Box>
                <Box width="50%">
                  <TurqoiseButton
                    disabled={
                      !isFalsy(errors[NEW_SERIAL_NUMBER]) ||
                      isFalsy(values[NEW_SERIAL_NUMBER])
                    }
                    onClick={() => {
                      onSubmitVendorReplacement(values);
                    }}
                    loading={updateDeviceStatusLoading}
                  >
                    Submit
                  </TurqoiseButton>
                </Box>
              </Flexbox>
            </div>
          )}
        </form>
        {updateDeviceStatusError || updateExtraError ? (
          <ErrorComponent error={updateDeviceStatusError || updateExtraError} />
        ) : (
          ""
        )}
      </ModalBody>
    </StyledModal>
  );
};

export { DeviceSupportModal };
