import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import { useGetReadingsTrendsByMemberQuery } from "common/services/ReadingsService";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import MemberType from "common/types/MemberType";
import { useEffect } from "react";
import { isFalsy } from "common/helpers/helpers";
import { useAppDispatch } from "common/redux";
import { setAnswer } from "common/redux/VisitsSlice";

interface IProps {
  field: CareFlowFieldType;
  days: number;
  member: MemberType;
}

const VisitFieldTrendGlucose = ({ field, days, member }: IProps) => {
  const dispatch = useAppDispatch();

  const { data } = useGetReadingsTrendsByMemberQuery(
    {
      memberId: member?.patient?.patient_id,
      paramsObject: {
        device_type: [DeviceTrendParam.GLUCOSE],
        trend_days: [days]
      }
    },
    { skip: isFalsy(member?.patient?.patient_id) }
  );

  useEffect(() => {
    if (isFalsy(data) || isFalsy(data.glucose)) return;
    const trends = data.glucose?.trends;
    if (isFalsy(trends) || isFalsy(trends[days.toString()])) return;

    const trendData = trends[days.toString()];
    if (
      isFalsy(trendData.average_fasting) ||
      isFalsy(trendData.average_non_fasting)
    )
      return;
    const avgFasting =
      trendData?.average_fasting < 0 ? "No data" : trendData?.average_fasting;
    const avgNonFasting =
      trendData?.average_non_fasting < 0
        ? "No data"
        : trendData?.average_non_fasting;

    dispatch(
      setAnswer({
        id: field.field_id,
        value: `Glucose ${days.toString()}-day fasting average: ${avgFasting}, non-fasting average: ${avgNonFasting}`
      })
    );
  }, [data]);
  return null;
};

export default VisitFieldTrendGlucose;
