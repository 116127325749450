import { ReactNode } from "react";
import styled from "@emotion/styled";
import useGetOrderDevices from "../../hooks/data_loaders/useGetOrderDevices";
import Table from "../../components/Table/Table";
import { StyledLink } from "../../styling/StyleComponents";
import { getNameOrUsername } from "common/helpers/helpers";
import MemberType from "common/types/MemberType";
import { Typography } from "@mui/material";
import replace from "lodash.replace";
import { MEMBERS_PATH } from "../../routes/RoutePaths";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux";
import OrderType from "common/types/OrderType";
import { Alert_show } from "common/helpers/AlertHelper";
import { useLazyDownloadOrderPDFQuery } from "common/services/OrdersService";

const OuterContainer = styled.div<{
  children: ReactNode | ReactNode[];
}>`
  margin: 22px 0;
  overflow: scroll;
`;

const deviceOrdersColumns = [
  { name: "order_id", size: 250 },
  { name: "orderPatientName" },
  { name: "ordered", size: 100 },
  { name: "orderStatus" },
  {
    name: "downloadOrderPDF"
  },
  { name: "orderShipped", size: 100 },
  { name: "orderDelivered", size: 120 },
  { name: "tracking" },
  { name: "ordered_by" },
  { name: "deviceOrdersItems", type: "dataList" }
];

const refillOrdersColumns = [
  { name: "order_id", size: 250 },
  { name: "orderPatientName" },
  { name: "ordered", size: 100 },
  { name: "orderStatus" },
  { name: "orderShipped", size: 100 },
  { name: "orderDelivered", size: 120 },
  { name: "tracking" },
  { name: "ordered_by" },
  { name: "refillOrdersItems", type: "dataList" }
];

interface IProps {
  memberId: string;
  memberDetails?: boolean;
  width?: number;
}

interface PatientLinkProps {
  patient: MemberType;
}
function PatientLink({ patient }: Readonly<PatientLinkProps>) {
  const patientName = getNameOrUsername(patient.patient);
  const patientId = patient.patient.patient_id;
  const link = replace(MEMBERS_PATH, ":memberId", patientId);
  return (
    <StyledLink
      style={{
        textDecoration: "none",
        pointerEvents: patientId ? undefined : "none"
      }}
      to={link}
    >
      {patientName}
    </StyledLink>
  );
}

const OrderHistoryTable = ({
  memberId,
  memberDetails = false,
  width
}: IProps) => {
  const navigate = useNavigate();
  const { currentRole } = useSelector((state: RootState) => state.auth);
  const { data } = useGetOrderDevices(memberId);
  const { patient, suppliesOrdered, devicesOrdered } = data;

  const dispatch = useAppDispatch();
  const [downloadOrderPDF] = useLazyDownloadOrderPDFQuery();

  const handleDownloadPDF = (item: OrderType) => {
    const promiseSuccess = ({ data }) => {
      if (data?.encoded_file) {
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:application/pdf;base64," + data.encoded_file;
        downloadLink.download = item.order_id + ".pdf";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        Alert_show({
          dispatch,
          type: "error",
          size: "small",
          title: "Download Error",
          content: "An error has occured while downloading the pdf"
        });
      }
    };

    downloadOrderPDF({
      order_id: item.order_id
    }).then(({ data }) => promiseSuccess({ data }));
  };

  return (
    <OuterContainer style={{ width: width ?? "auto" }}>
      {!memberDetails && (
        <div>Orders for {patient && <PatientLink patient={patient} />}</div>
      )}

      <Typography variant="h4">Refill Orders</Typography>
      {suppliesOrdered && (
        <Table
          tableMaxHeight="325px"
          data={suppliesOrdered}
          tableProps={{ navigate, currentRole }}
          tableColumns={refillOrdersColumns}
        />
      )}

      <Typography variant="h4" mt="8px">
        Device Orders
      </Typography>
      {devicesOrdered && (
        <Table
          tableMaxHeight="325px"
          data={devicesOrdered}
          tableProps={{ navigate, currentRole, handleDownloadPDF }}
          tableColumns={deviceOrdersColumns}
          tableHeader="{{COUNT}} orders"
        />
      )}
      <br />
    </OuterContainer>
  );
};

export default OrderHistoryTable;
