import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import APIConstants from "../config/APIConstants";
import StorageHelper from "../helpers/StorageHelper";
import StorageEnum from "../enums/StorageEnum";

const graphqlBaseQuery = graphqlRequestBaseQuery({
  url: `${APIConstants.PAB}/graphql`,
  prepareHeaders: async (headers) => {
    const credentialsJson = await StorageHelper.getItem(
      StorageEnum.LOGIN_CREDENTIALS
    );
    if (credentialsJson !== undefined) {
      const credentials = JSON.parse(credentialsJson);
      if (credentials?.access_token) {
        headers.set("authorization", `Bearer ${credentials.access_token}`);
      }
    }

    return headers;
  }
});

export const apiGraphQL = createApi({
  reducerPath: "apiGraphQL",
  baseQuery: graphqlBaseQuery,
  endpoints: () => ({}),
  tagTypes: []
});
