import { Tabs, Tab } from "@mui/material";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { useState } from "react";
import VisitContentField from "./VisitContentField";
import MemberType from "common/types/MemberType";
import { useGetTasksQuery } from "common/services/TaskingService";
import { isFalsy } from "common/helpers/helpers";
import TaskStatusEnum from "common/enums/TaskStatusEnum";
import { LightRedDangerIcon } from "../../../assets/images/icons";
import { CustomTooltip } from "../../../styling/StyleComponents";

interface IProps {
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
  member: MemberType;
}

const VisitFieldTabGroup = ({ field, visit, member }: IProps) => {
  // Expected data structure:
  // Field = TAB_GROUP
  //      SubField = TAB
  //          SubField = other fields
  const [tabIndex, setTabIndex] = useState<number>(0);
  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const { data } = useGetTasksQuery(
    { patient_ids: member?.patient?.patient_id, status: [TaskStatusEnum.TODO] },
    {
      // if all 3 values are false, skip making request
      skip: isFalsy(member?.patient?.patient_id)
    }
  );
  const URGENT_ALERTS = "Urgent Alerts";

  const childrenTabs = field.subfields;

  const renderTabIndex = (index) => {
    const tab = field.subfields[index];
    return (
      <>
        {tab?.subfields?.map((item) => (
          <>
            <VisitContentField key={item.field_id} visit={visit} field={item} member={member} />
            <br />
          </>
        ))}
      </>
    );
  };

  return (
    <>
      <Tabs
        value={tabIndex}
        onChange={handleTabSelect}
        aria-label={field?.value}
      >
        {childrenTabs.map((item, i) => {
          return item.value === URGENT_ALERTS ? (
            isFalsy(data) ? (
              <CustomTooltip placement="top" title={`No Urgent Alerts found`}>
                <div>
                  <Tab
                    key={item.field_id}
                    label={item.value}
                    style={{ fontWeight: "600" }}
                    disabled
                  />
                </div>
              </CustomTooltip>
            ) : (
              <Tab
                key={item.field_id}
                label={item.value}
                style={{ fontWeight: "600", color: "rgb(255, 83, 100)" }}
                iconPosition="start"
                icon={<LightRedDangerIcon />}
              />
            )
          ) : (
            <Tab
              key={item.field_id}
              label={item.value}
              style={{ fontWeight: "600" }}
            />
          );
        })}
      </Tabs>
      {renderTabIndex(tabIndex)}
      <br />
    </>
  );
};

export default VisitFieldTabGroup;
