import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import {
  Card,
  CardContent,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button,
  TextField,
  MenuItem
} from "@mui/material";
import { useSelector } from "react-redux";

import {
  useUpdateOrderStatusMutation,
  useGetOrderByIdQuery
} from "common/services/OrdersService";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import OrderStatusEnum from "common/enums/OrderStatusEnum";
import OrderLostStatusReasonEnum from "common/enums/OrderLostStatusReasonEnum";
import {
  checkIdValid,
  getAddress,
  getNameOrUsername
} from "common/helpers/helpers";

import { useGetUserWithUsernameQuery } from "common/services/UserService";
import { canEditOrderStatus } from "common/enums/RolesEnum";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";

import { RootState } from "common/redux";

import { ComponentHeader } from "../../styling";
import { getDeviceInfoBySku, getTrackingUrl } from "../../helpers/helpers";
import useSanitizedParams from "../../hooks/useSanitizedParams";

import {
  StyledModal,
  ModalBody,
  BodyHeader,
  ModalFooter,
  ModalFooterButtons
} from "../../styling/StyleModal";
import DatePicker from "../../components/DatePicker";
import ErrorComponent from "../../components/ErrorComponent";

import {
  CustomTooltip,
  ExternalLink,
  OrderStatusComponent,
  StyledLink,
  TurqoiseButton,
  WhiteButton
} from "../../styling/StyleComponents";
import { ArrowBackIcon, HelpIcon } from "../../assets/images/icons";
import replace from "lodash.replace";
import { MEMBERS_PATH } from "../../routes/RoutePaths";
import { useFormik } from "formik";

interface FormType {
  selectedOrderStatus: OrderStatusEnum;
  deliveredDate: DateTime;
  selectedReason: OrderLostStatusReasonEnum;
}

const Container = styled.div`
  margin: 30px 20px;
`;

const BackContainer = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(props) => props.theme.color.darkGrey};
  display: flex;
  margin-bottom: 18px;
`;

const Spacing = styled.div<{ height: string }>`
  height: ${({ height }) => height};
`;

const TableHeading = styled(ComponentHeader)`
  margin: 24px 0px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const UpdateStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const RowCard = styled(Card)`
  flex: 1;
`;

const DashedStepConnector = styled(StepConnector)`
  > .MuiStepConnector-line {
    border-top-style: dashed;
  }
`;

const MyStep = styled(Step, {
  shouldForwardProp: (prop) => prop !== "isError"
})<{ isError: boolean }>`
  svg.Mui-completed {
    color: ${({ theme, isError }) => (isError ? theme.color.red : undefined)};
  }
  svg.Mui-active {
    color: ${({ theme, isError }) =>
      isError ? theme.color.red : "rgba(0, 0, 0, 0.38)"};
  }
`;

const OrderDevicesDetails = () => {
  const { orderId } = useSanitizedParams();
  const navigate = useNavigate();

  const isValidId = checkIdValid(orderId);

  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!isModalOpen) {
      formik.resetForm();
      resetUpdateOrderStatus();
    }
  }, [isModalOpen]);

  const orderStatusToString = (orderStatusEnum: OrderStatusEnum) => {
    const label =
      orderStatusEnum === OrderStatusEnum.delivered_override
        ? OrderStatusEnum.delivered.toString()
        : orderStatusEnum.toString();
    return capitalize(label);
  };

  const capitalize = (input: string) => {
    const label = input.toString().toLocaleLowerCase().replace("_", " ");

    return label.charAt(0).toUpperCase() + label.slice(1);
  };

  const updateOrderModalOptions = [
    OrderStatusEnum.delivered_override,
    OrderStatusEnum.lost,
    OrderStatusEnum.returned
  ];

  const orderStatusReasons = [
    OrderLostStatusReasonEnum.DELIVERED_LOST,
    OrderLostStatusReasonEnum.PATIENT_LOST
  ];

  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const [
    updateOrderStatusMutation,
    {
      isLoading: updateOrderLoading,
      error: updateOrderError,
      isSuccess: isUpdateOrderSuccess,
      reset: resetUpdateOrderStatus
    }
  ] = useUpdateOrderStatusMutation();

  const { data, isSuccess, isLoading, isError, error } = useGetOrderByIdQuery(
    {
      orderId
    },
    { skip: isValidId === false }
  );

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: data?.patient_id
    },
    {
      skip: data?.patient_id === undefined || isValidId === false
    }
  );

  const { data: orderedByUser } = useGetUserWithUsernameQuery(
    {
      username: data?.ordered_by
    },
    {
      skip: data?.ordered_by === undefined || isValidId === false
    }
  );

  useEffect(() => {
    if (isUpdateOrderSuccess) {
      setModalOpen(false);
    }
  }, [isUpdateOrderSuccess]);

  const STEPS = [
    {
      title: "Order Received",
      date: data?.created_at,
      errorStatus: [OrderStatusEnum.backordered, OrderStatusEnum.deleted],
      renderLink: () => null
    },
    {
      title: "Shipped",
      date: data?.shipping?.shipping_date,
      renderLink: () => {
        return (
          <ExternalLink
            href={getTrackingUrl(
              data?.shipping?.tracking_number,
              data?.shipping?.carrier_code ?? "USPS"
            )}
            target="_blank"
          >
            Tracking Number
          </ExternalLink>
        );
      },
      errorStatus: []
    },
    {
      title: "Delivered",
      date: data?.shipping?.delivered_date,
      renderLink: () => null,
      errorStatus: [
        OrderStatusEnum.exception,
        OrderStatusEnum.lost,
        OrderStatusEnum.returned,
        OrderStatusEnum.delivered_lost,
        OrderStatusEnum.rejected
      ]
    }
  ];

  const {
    hasBloodPressureDevice,
    hasGlucoseMeterDevice,
    hasWeightScaleDevice
  } = useMemo(() => {
    if (data?.items === undefined) return {};
    let hasBloodPressureDevice = false;
    let hasGlucoseMeterDevice = false;
    let hasWeightScaleDevice = false;

    if (data?.order_type !== "DEVICE")
      return {
        hasBloodPressureDevice,
        hasGlucoseMeterDevice,
        hasWeightScaleDevice
      };

    data.items.forEach((item) => {
      if (item.sku_type !== "DEVICE") return;
      const deviceInfo = getDeviceInfoBySku(item.sku);
      if (DeviceTypeEnum.BLOOD_PRESSURE === deviceInfo?.description) {
        hasBloodPressureDevice = true;
      } else if (DeviceTypeEnum.GLUCOSE_CATEGORY === deviceInfo?.category) {
        hasGlucoseMeterDevice = true;
      } else if (DeviceTypeEnum.WEIGHT_SCALE === deviceInfo?.category) {
        hasWeightScaleDevice = true;
      }
    });

    return {
      hasBloodPressureDevice,
      hasGlucoseMeterDevice,
      hasWeightScaleDevice
    };
  }, [data]);

  const orderStatusIndex = useMemo(() => {
    if (data === undefined) return undefined;
    switch (data?.status?.sm_status) {
      case OrderStatusEnum.started:
      case OrderStatusEnum.accepted:
      case OrderStatusEnum.in_fulfillment:
      case OrderStatusEnum.backordered:
      case OrderStatusEnum.deleted:
        return 1;
      case OrderStatusEnum.shipped:
        return 2;
      case OrderStatusEnum.delivered:
      case OrderStatusEnum.exception:
      case OrderStatusEnum.lost:
      case OrderStatusEnum.delivered_lost:
      case OrderStatusEnum.returned:
      case OrderStatusEnum.rejected:
      case OrderStatusEnum.delivered_override:
        return 3;
      default:
        return 1;
    }
  }, [data]);

  const nonDeviceItems = useMemo(() => {
    if (data === undefined) return undefined;
    return data?.items?.filter((item) => item.sku_type !== "DEVICE");
  }, [data]);

  const link = useMemo(() => {
    if (patient?.patient?.patient_id) {
      return replace(MEMBERS_PATH, ":memberId", patient.patient.patient_id);
    } else {
      return null;
    }
  }, [patient]);

  const validate = (values: FormType) => {
    const errors = {};

    if (!values["selectedOrderStatus"]) {
      errors["selectedOrderStatus"] = "Required";
    }
    if (
      !values["selectedReason"] &&
      values["selectedOrderStatus"] === OrderStatusEnum.lost
    ) {
      errors["selectedReason"] = "Required";
    }

    if (
      !values["deliveredDate"] &&
      values["selectedOrderStatus"] !== OrderStatusEnum.lost
    ) {
      errors["deliveredDate"] = "Required";
    }

    if (values["deliveredDate"] && !values["deliveredDate"].isValid) {
      errors["deliveredDate"] = "Please enter a valid date";
    }

    return errors;
  };

  const onSubmit = async (values: FormType) => {
    await updateOrderStatusMutation({
      orderId,
      orderStatus: values.selectedOrderStatus,
      date: values.deliveredDate,
      updatedBy: user.user_id,
      reason: values.selectedReason
    });
  };

  const formik = useFormik<FormType>({
    initialValues: {
      selectedOrderStatus: null,
      deliveredDate: null,
      selectedReason: null
    },
    onSubmit,
    validate
  });

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value).catch(() => {});
  };

  if (!isValidId)
    return (
      <Typography variant="body1">{`Invalid Order ID ${orderId}`}</Typography>
    );

  return (
    <Container>
      <BackContainer onClick={() => navigate(-1)}>
        <ArrowBackIcon />
        Back
      </BackContainer>

      <TableHeading>Order Details: {data?.order_id}</TableHeading>
      {isLoading && <CircularProgress />}
      {isSuccess && (
        <Row>
          {hasBloodPressureDevice ||
          hasGlucoseMeterDevice ||
          hasWeightScaleDevice ? (
            <RowCard>
              <CardContent>
                <ComponentHeader>Device Items</ComponentHeader>
                <br />
                <Row>
                  <FormGroup>
                    <FormControlLabel
                      checked={hasBloodPressureDevice}
                      control={<Checkbox />}
                      label="Blood Pressure Cuff"
                    />
                    <FormControlLabel
                      checked={hasGlucoseMeterDevice}
                      control={<Checkbox />}
                      label="Glucose Meter"
                    />
                    <FormControlLabel
                      checked={hasWeightScaleDevice}
                      control={<Checkbox />}
                      label="Weight Scale"
                    />
                  </FormGroup>
                </Row>
              </CardContent>
            </RowCard>
          ) : null}
          {nonDeviceItems?.length > 0 && (
            <RowCard>
              <CardContent>
                <ComponentHeader>Refill Items</ComponentHeader>
                <br />

                <FormGroup>
                  {nonDeviceItems?.map((item) => {
                    const deviceInfo = getDeviceInfoBySku(item.sku);
                    return (
                      <FormControlLabel
                        key={item.sku}
                        checked={true}
                        control={<Checkbox />}
                        label={
                          (deviceInfo?.description || item.sku) +
                          " x " +
                          item.quantity
                        }
                      />
                    );
                  })}
                </FormGroup>
              </CardContent>
            </RowCard>
          )}
          <RowCard>
            <CardContent>
              <ComponentHeader>Order Details</ComponentHeader>
              <br />
              {patient && (
                <StyledLink to={link}>
                  <Typography variant="body1" color="text.secondary">
                    <b>Member Name:</b> {getNameOrUsername(patient.patient)}
                  </Typography>
                </StyledLink>
              )}
              {patient && (
                <Typography variant="body1" color="text.secondary">
                  <b>DOB:</b> {patient?.patient?.birthdate}
                </Typography>
              )}
              {orderedByUser && (
                <Typography variant="body1" color="text.secondary">
                  <b>Ordered By:</b> {getNameOrUsername(orderedByUser.user)}
                </Typography>
              )}
              <Typography variant="body1" color="text.secondary">
                <b>Shipped To:</b> <br />
                {getAddress(data?.address, ["street1", "street2"])}
                <br />
                {getAddress(data?.address, [
                  "city",
                  "state",
                  "postal_code",
                  "country"
                ])}
              </Typography>
            </CardContent>
          </RowCard>
        </Row>
      )}
      <Spacing height="20px" />
      {isSuccess && (
        <Card>
          <CardContent>
            <Row>
              <ComponentHeader>Order Status</ComponentHeader>
              <OrderStatusComponent
                status={data?.status?.sm_status as OrderStatusEnum}
              />
            </Row>

            <Spacing height="60px" />

            <Stepper
              activeStep={orderStatusIndex}
              alternativeLabel
              connector={<DashedStepConnector />}
            >
              {STEPS.map((step) => {
                const isError = step.errorStatus.includes(
                  data?.status?.sm_status as OrderStatusEnum
                );
                return (
                  <MyStep
                    key={step.title}
                    isError={isError}
                    completed={isError ? false : undefined}
                    active={isError ? true : undefined}
                  >
                    <StepLabel
                      sx={{ ".MuiStepIcon-text": { display: "none" } }}
                    >
                      {step.title}
                      <br />
                      {step.date &&
                        DateTime.fromSQL(step.date).toFormat("MM/dd/yyyy")}

                      <br />
                      {data?.shipping?.tracking_number != null &&
                        step.renderLink()}
                    </StepLabel>
                  </MyStep>
                );
              })}
            </Stepper>

            <Spacing height="100px" />
          </CardContent>
        </Card>
      )}
      <Spacing height="20px" />
      {canEditOrderStatus(currentRole) && (
        <UpdateStatusContainer>
          <Button variant="contained" onClick={() => setModalOpen(true)}>
            Update Order Status
          </Button>
          <CustomTooltip
            title={
              'Override the order status i.e. update status to "Delivered" if the member reports receiving the order even though we didn\'t get that delivery notification'
            }
          >
            <HelpIcon />
          </CustomTooltip>
        </UpdateStatusContainer>
      )}

      <Spacing height="20px" />
      {isError && (
        <Card>
          <CardContent>
            <ErrorComponent error={error} />
          </CardContent>
        </Card>
      )}

      <StyledModal
        isOpen={isModalOpen}
        contentLabel={"Update Order Status"}
        modalHeight={"70vh"}
        shouldCloseOnEsc={true}
        onRequestClose={() => setModalOpen(false)}
      >
        <form onSubmit={formik.handleSubmit}>
          <ModalBody>
            <BodyHeader>Update Order Status</BodyHeader>
            <br />

            <TextField
              value={formik.values?.selectedOrderStatus ?? ""}
              label="Select Order Status"
              select
              onChange={(event) => {
                setFieldValue(
                  "selectedOrderStatus",
                  event.target.value as OrderStatusEnum
                );
              }}
              error={formik.errors["selectedOrderStatus"] !== undefined}
              helperText={formik.errors["selectedOrderStatus"]}
            >
              {updateOrderModalOptions.map((orderStatusEnum) => (
                <MenuItem key={orderStatusEnum} value={orderStatusEnum}>
                  {orderStatusToString(orderStatusEnum)}
                </MenuItem>
              ))}
            </TextField>

            <br />
            {formik.values?.selectedOrderStatus !== OrderStatusEnum.lost && (
              <>
                <DatePicker
                  label="Delivered date"
                  value={formik.values?.deliveredDate}
                  onChange={(newDate: DateTime) => {
                    if (newDate?.isValid) {
                      setFieldValue("deliveredDate", newDate);
                    }
                  }}
                  maxDate={DateTime.now()}
                  slotProps={{
                    textField: {
                      // prevent user from typing in the date as this can lead to bugs
                      // see ENG-3757
                      // the below code needs to be here instead of in DateTimePicker.tsx
                      // until this PR is merged https://github.com/mui/material-ui/pull/35088
                      onKeyDown: (e) => {
                        e.preventDefault();
                      }
                    }
                  }}
                />
                <br />
              </>
            )}

            {formik.values.selectedOrderStatus === OrderStatusEnum.lost && (
              <>
                <TextField
                  value={formik.values?.selectedReason ?? ""}
                  label="Reason"
                  select
                  onChange={(event) => {
                    setFieldValue(
                      "selectedReason",
                      event.target.value as OrderLostStatusReasonEnum
                    );
                  }}
                >
                  {orderStatusReasons.map((orderStatusReason) => (
                    <MenuItem key={orderStatusReason} value={orderStatusReason}>
                      {capitalize(orderStatusReason)}
                    </MenuItem>
                  ))}
                </TextField>
              </>
            )}

            {updateOrderError && <ErrorComponent error={updateOrderError} />}
          </ModalBody>
          <ModalFooter>
            <ModalFooterButtons>
              <WhiteButton onClick={() => setModalOpen(false)} type="submit">
                Cancel
              </WhiteButton>
              <TurqoiseButton
                loading={updateOrderLoading}
                disabled={!formik.isValid || !formik.dirty}
                type="submit"
              >
                Update Order Status
              </TurqoiseButton>
            </ModalFooterButtons>
          </ModalFooter>
        </form>
      </StyledModal>
    </Container>
  );
};

export default OrderDevicesDetails;
