enum TaskActionTypeEnum {
  READ = "READ",
  PHONE_CALL = "PHONE_CALL",
  SMS = "SMS",
  RESOLVED = "RESOLVED",
  ESCALATED = "ESCALATED",
  AUTO_ESCALATED = "AUTO_ESCALATED"
}

export default TaskActionTypeEnum;
