import styled from "@emotion/styled";
import { DelayedRender } from "./DelayedRender";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonContainer = styled("div", {
  shouldForwardProp: (prop) =>
    !["$componentWidth", "$componentHeight", "$componentMargin"].includes(prop)
})<{
  $componentWidth?: string;
  $componentHeight?: string;
  $componentMargin?: string;
}>`
  position: relative;
  ${(props: any) =>
    props.$componentWidth && `width: ${props.$componentWidth};`};
  ${(props: any) =>
    props.$componentHeight && `height: ${props.$componentHeight};`};
  ${(props: any) =>
    props.$componentMargin && `margin: ${props.$componentMargin};`};
`;

const InlineSkeleton = styled(Skeleton)`
  width: ${(props) => (props.width ? props.width : "100%")};
`;

const LoadingFallback = ({
  delay = 500,
  count = 20,
  componentWidth = "100%",
  componentHeight,
  componentMargin
}: {
  delay?: number;
  count?: number;
  componentWidth?: string;
  componentHeight?: string;
  componentMargin?: string;
}) => (
  <DelayedRender delay={delay}>
    <SkeletonContainer
      $componentWidth={componentWidth}
      $componentHeight={componentHeight}
      $componentMargin={componentMargin}
    >
      <InlineSkeleton count={count} />
    </SkeletonContainer>
  </DelayedRender>
);

export default LoadingFallback;
