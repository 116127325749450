import DeviceReadingType from "common/types/DeviceReadingType";
import Table from "../../../components/Table/Table";

interface IProps {
  weightScaleData: DeviceReadingType[];
  patientTimezone: string;
}

const WeightScaleTable = ({ weightScaleData, patientTimezone }: IProps) => {
  return (
    <Table
      data={weightScaleData}
      tableContainerStyles={{ margin: "0 2.5%", width: "95%" }}
      tableProps={{ patientTimezone }}
      tableColumns={[{ name: "readingMeasureTimestamp" }, { name: "weight" }]}
    />
  );
};

export default WeightScaleTable;
