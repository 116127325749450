import { useEffect, useState } from "react";
import {
  useGetSalesforceLeadMutation,
  useRegisterMemberMutation
} from "../services/MemberRegistrationService";
import { formatName, getNameOrUsername } from "../helpers/helpers";
import SalesforceEnum from "../enums/SalesforceEnum";
import { RegisterMemberRequestBody } from "../types/requestBody/RegisterMemberRequestBody";

interface IProps {
  registered_by: string;
}

const useGetRegisterMember = ({ registered_by }: IProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [salesforceLead, setSalesforceLead] = useState<string>("");
  const [memberName, setMemberName] = useState<string>("");
  const [step1Done, setStep1Done] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);
  const [registerMemberReqBody, setRegisterMemberReqBody] =
    useState<RegisterMemberRequestBody | null>(null);

  const [
    getSalesforceLeadMutation,
    {
      data: salesforceLeadData,
      error: salesforceLeadError,
      isSuccess: salesforceLeadIsSuccess,
      isLoading: salesforceLeadIsLoading,
      reset: salesforceLeadReset
    }
  ] = useGetSalesforceLeadMutation();

  const [
    registerMemberMutation,
    {
      data: registerMemberData,
      error: registerMemberError,
      // we need this api call as a mutation so we can have a reset function to reset stale state
      reset: registerMemberReset
    }
  ] = useRegisterMemberMutation();

  function reset() {
    salesforceLeadReset();
    setIsError(false);
    setError(null);
    setIsLoaded(false);
    setIsFetching(false);
    setSalesforceLead("");
    setMemberName("");
    setStep1Done(false);
    registerMemberReset();
    setRegisterMemberReqBody(null);
  }

  function softReset() {
    setIsError(false);
    setIsLoaded(false);
    setIsFetching(false);
  }

  function step1GetSalesforceLead(sfLead: string) {
    setIsLoaded(false);
    if (sfLead && !isFetching) {
      setSalesforceLead(sfLead);
      setIsFetching(true);
      getSalesforceLeadMutation(sfLead);
    }
  }

  async function fetchData(sfLead: string) {
    softReset();
    setStep1Done(false);
    await step1GetSalesforceLead(sfLead);
  }

  async function step2GetRegisterResponse(
    RegisterMemberRequestBody: RegisterMemberRequestBody
  ) {
    if (RegisterMemberRequestBody) {
      await registerMemberMutation({ body: RegisterMemberRequestBody });
    } else {
      return Promise.resolve();
    }
  }

  // step 1
  useEffect(() => {
    if (
      salesforceLeadData &&
      !salesforceLeadData.validation_message &&
      !step1Done &&
      !salesforceLeadIsLoading &&
      salesforceLeadIsSuccess
    ) {
      const {
        FirstName: first = "",
        MiddleName: middle = "",
        LastName: last = "",
        Birthdate__c: birthdate = "",
        Gender__c: sex = "",
        MobilePhone: mobile = "",
        Phone: phone = "",
        Street: street1 = "",
        Street_2__c: street2 = "",
        City: city = "",
        PostalCode: postal_code = "",
        StateCode: state = "",
        Email: email = "",
        LeadSource: lead_source = "",
        tz__Timezone_IANA__c: timezone = "",
        Status = "",
        Notes__c = ""
      } = salesforceLeadData || {};

      const name = getNameOrUsername({ first, middle, last, username: email });
      setMemberName(name);

      const RegisterMemberRequestBody: RegisterMemberRequestBody = {
        leads_id: salesforceLead,
        birthdate,
        first: formatName(first),
        middle: formatName(middle),
        last: formatName(last),
        sex,
        phone,
        mobile,
        street1,
        street2,
        city,
        postal_code,
        lead_source,
        // hardcode country as US
        country: "US",
        email,
        state,
        timezone,
        registered_by: registered_by
      };

      if (Notes__c && Notes__c === SalesforceEnum.NOTES_IS_TEST) {
        RegisterMemberRequestBody.is_test = true;
      }
      const requestBody = {
        ...RegisterMemberRequestBody
      };
      setStep1Done(true);
      setRegisterMemberReqBody(requestBody);
      step2GetRegisterResponse(requestBody);
    }
  }, [salesforceLeadData, step1Done, salesforceLeadIsLoading]);

  // step 1 error handling
  useEffect(() => {
    if (
      salesforceLeadError ||
      (salesforceLeadData && salesforceLeadData.validation_message)
    ) {
      setIsFetching(false);
      setIsLoaded(true);
      setIsError(true);

      if (salesforceLeadError) setError(salesforceLeadError);
      else if (salesforceLeadData && salesforceLeadData.validation_message)
        setError(salesforceLeadData.validation_message);
    }
  }, [salesforceLeadError, salesforceLeadData]);

  // step 2
  useEffect(() => {
    if (registerMemberData) {
      setIsFetching(false);
      setIsLoaded(true);
    }
  }, [registerMemberData]);

  // step 2 error handling
  useEffect(() => {
    if (registerMemberError) {
      setIsFetching(false);
      setIsLoaded(true);
      setError(registerMemberError);
      setIsError(true);
    }
  }, [registerMemberError]);

  return {
    isFetching,
    isLoaded,
    isError,
    error,
    fetchData,
    memberName,
    registerMemberLeadsId: registerMemberData?.id,
    registerMemberReqBody,
    reset
  };
};

export default useGetRegisterMember;
