import { IngestComponent } from "./IngestComponent";
import { PollingComponent } from "./PollingComponent";
import RegisterPatientModalStateEnum from "./enums/RegisterPatientModalStateEnum";
import { useCallback, useEffect, useState } from "react";
import { ExternalLink, TurqoiseButton, WhiteButton } from "../../styling";
import {
  BodyHeader,
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import { RegisterMemberRequestBody } from "common/types/requestBody/RegisterMemberRequestBody";

interface CloseModalButtonIProps {
  closeModal: () => void;
  currentModalState: RegisterPatientModalStateEnum;
  athenaLink: string;
  isPolling: boolean;
  duplicateAthenaLinkIngested: boolean;
}

const CloseModalButton = ({
  closeModal,
  currentModalState,
  athenaLink = "",
  isPolling,
  duplicateAthenaLinkIngested
}: CloseModalButtonIProps) => {
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    if (
      duplicateAthenaLinkIngested &&
      currentModalState === RegisterPatientModalStateEnum.STATE_3 &&
      !isPolling
    ) {
      setIsDone(true);
    }
    if (athenaLink && !isPolling) {
      setIsDone(true);
    }
  }, [duplicateAthenaLinkIngested, athenaLink, isPolling, currentModalState]);
  if (isDone) {
    return (
      <WhiteButton
        onClick={() => {
          closeModal();
          setIsDone(false);
        }}
      >
        Done
      </WhiteButton>
    );
  }

  return (
    <WhiteButton
      onClick={() => {
        closeModal();
        setIsDone(false);
      }}
    >
      Cancel
    </WhiteButton>
  );
};

interface RegisterModalButtonIProps {
  currentModalState: RegisterPatientModalStateEnum;
  setCurrentModalStateCallback: (val: RegisterPatientModalStateEnum) => void;
  registerPatientLoading: boolean;
  athenaLink?: string;
  athenaButtonText: string;
  selectedAthenaLink: string;
  hasMultipleAthenaLinks: boolean;
  shouldIngest: boolean;
  shouldIngestCallback: (val: boolean) => void;
  duplicateAthenaLinkIngested: boolean;
}

const RegisterModalButton = ({
  currentModalState,
  setCurrentModalStateCallback,
  athenaLink = "",
  athenaButtonText,
  selectedAthenaLink,
  hasMultipleAthenaLinks,
  shouldIngest,
  shouldIngestCallback,
  duplicateAthenaLinkIngested
}: RegisterModalButtonIProps) => {
  if (currentModalState === RegisterPatientModalStateEnum.STATE_1) {
    if (hasMultipleAthenaLinks) {
      return (
        <>
          <TurqoiseButton
            // disable if does not have selected athena link or
            // if shouldIngestCallback is true
            disabled={!selectedAthenaLink || shouldIngest}
            onClick={() => {
              // move to state 2, ingest component
              shouldIngestCallback(true);
              setCurrentModalStateCallback(
                RegisterPatientModalStateEnum.STATE_2
              );
            }}
          >
            Register
          </TurqoiseButton>
        </>
      );
    }

    if (duplicateAthenaLinkIngested) {
      return (
        <>
          <ExternalLink href={`${[athenaLink]}`} target="_blank">
            <TurqoiseButton disabled={!athenaLink}>
              {athenaButtonText}
            </TurqoiseButton>
          </ExternalLink>
        </>
      );
    }

    return (
      <>
        <ExternalLink href={`${[athenaLink]}`} target="_blank">
          <TurqoiseButton disabled={!athenaLink}>
            {athenaButtonText}
          </TurqoiseButton>
        </ExternalLink>
      </>
    );
  }

  if (currentModalState === RegisterPatientModalStateEnum.STATE_2) {
    const isMultipleMatchesCase = athenaLink?.includes("MULTIPLE_BEST_MATCHES");
    return (
      <>
        <ExternalLink href={`${[athenaLink]}`} target="_blank">
          <TurqoiseButton disabled={!athenaLink || isMultipleMatchesCase}>
            {athenaButtonText}
          </TurqoiseButton>
        </ExternalLink>
      </>
    );
  }

  if (currentModalState === RegisterPatientModalStateEnum.STATE_3) {
    return (
      <>
        <ExternalLink href={`${[athenaLink]}`} target="_blank">
          <TurqoiseButton disabled={!athenaLink}>
            {athenaButtonText}
          </TurqoiseButton>
        </ExternalLink>
      </>
    );
  }
};

interface IProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  currentModalState: RegisterPatientModalStateEnum;
  setCurrentModalState: (state: RegisterPatientModalStateEnum) => void;
  setSalesforceLead: (lead: string) => void;
  patientName: string;
  leadsId: string;
  setLeadsId: (lead: string) => void;
  reset: () => void;
  registerPatientReqBody: RegisterMemberRequestBody;
  registerPatientLoading: boolean;
}

const RegisterPatientModal = ({
  currentModalState,
  setCurrentModalState,
  setModalOpen,
  setSalesforceLead,
  modalOpen,
  patientName,
  leadsId,
  setLeadsId,
  reset,
  registerPatientReqBody,
  registerPatientLoading
}: IProps) => {
  const [isPolling, setIsPolling] = useState(false);
  const setIsPollingCallback = useCallback(
    (val) => {
      setIsPolling(val);
    },
    [setIsPolling]
  );

  useEffect(() => {
    if (modalOpen && !isPolling) {
      // start polling on opening the modal
      setIsPolling(true);
    }
  }, [modalOpen]);

  const [athenaLink, setAthenaLink] = useState("");
  const setAthenaLinkCallback = useCallback(
    (val: string) => {
      setAthenaLink(val);
    },
    [setAthenaLink]
  );

  // used for the case where there are multiple athena links to choose from
  const [selectedAthenaLink, setSelectedAthenaLink] = useState<string>();
  const [hasMultipleAthenaLinks, setHasMultipleAthenaLinks] =
    useState<boolean>(false);
  const [shouldIngest, setShouldIngest] = useState<boolean>(false);
  const [duplicateAthenaLinkIngested, setDuplicateAthenaLinkIngested] =
    useState<boolean>(false);

  const setSelectedAthenaLinkCallback = useCallback(
    (val: string) => {
      setSelectedAthenaLink(val);
    },
    [setSelectedAthenaLink]
  );
  const setHasMultipleAthenaLinksCallback = useCallback(
    (val) => {
      setHasMultipleAthenaLinks(val);
    },
    [setHasMultipleAthenaLinks]
  );
  const shouldIngestCallback = useCallback(
    (val) => {
      setShouldIngest(val);
    },
    [setShouldIngest]
  );
  const ingestedCallback = useCallback(
    (val) => {
      // expecting boolean true
      if (val) {
        setDuplicateAthenaLinkIngested(true);
      } else {
        setShouldIngest(true);
      }
    },
    [setShouldIngest, setDuplicateAthenaLinkIngested]
  );

  const [athenaButtonText, setAthenaButtonText] = useState(
    "Complete Setup in Athena"
  );
  const setAthenaButtonTextCallback = useCallback(
    (val) => {
      setAthenaButtonText(val);
    },
    [setAthenaButtonText]
  );

  // Move to state 1 poll status
  useEffect(() => {
    // start polling
    if (leadsId) {
      setCurrentModalState(RegisterPatientModalStateEnum.STATE_1);
      setIsPolling(true);
    }
  }, [leadsId]);

  // duplicate case start polling again (state 3)
  useEffect(() => {
    // start polling
    if (duplicateAthenaLinkIngested) {
      setCurrentModalState(RegisterPatientModalStateEnum.STATE_3);
      setIsPolling(true);
    }
  }, [duplicateAthenaLinkIngested]);

  const closeModal = () => {
    reset();
    setSalesforceLead("");
    setModalOpen(false);
    setCurrentModalState(RegisterPatientModalStateEnum.STATE_1);
    setIsPolling(false);
    setLeadsId(null);
    setShouldIngest(false);
    setAthenaLink("");
    setSelectedAthenaLink(null);
    setHasMultipleAthenaLinks(false);
    setDuplicateAthenaLinkIngested(false);
  };

  return (
    <StyledModal
      isOpen={modalOpen}
      contentLabel="Register Member Modal"
      modalHeight="60vh"
      onRequestClose={closeModal}
    >
      <ModalHeader onRequestClose={closeModal}>
        Register a new member (Remote IQ and Athena)
      </ModalHeader>
      <ModalBody>
        <BodyHeader>
          {currentModalState === RegisterPatientModalStateEnum.STATE_1 && (
            <>
              <PollingComponent
                isPolling={isPolling}
                leadsId={leadsId}
                patientName={patientName}
                setIsPollingCallback={setIsPollingCallback}
                setAthenaLinkCallback={setAthenaLinkCallback}
                setAthenaButtonTextCallback={setAthenaButtonTextCallback}
                setSelectedAthenaLinkCallback={setSelectedAthenaLinkCallback}
                setHasMultipleAthenaLinksCallback={
                  setHasMultipleAthenaLinksCallback
                }
              />
            </>
          )}
          {currentModalState === RegisterPatientModalStateEnum.STATE_2 && (
            <IngestComponent
              shouldIngest={shouldIngest}
              selectedAthenaLink={selectedAthenaLink}
              RegisterMemberRequestBody={registerPatientReqBody}
              ingestedCallback={ingestedCallback}
              setLeadsIdCallback={setLeadsId}
              shouldIngestCallback={shouldIngestCallback}
            />
          )}
          {currentModalState === RegisterPatientModalStateEnum.STATE_3 && (
            <>
              <PollingComponent
                isPolling={isPolling}
                leadsId={leadsId}
                patientName={patientName}
                setIsPollingCallback={setIsPollingCallback}
                setAthenaLinkCallback={setAthenaLinkCallback}
                setAthenaButtonTextCallback={setAthenaButtonTextCallback}
                setSelectedAthenaLinkCallback={setSelectedAthenaLinkCallback}
                setHasMultipleAthenaLinksCallback={
                  setHasMultipleAthenaLinksCallback
                }
              />
            </>
          )}
        </BodyHeader>
      </ModalBody>
      <ModalFooter>
        <ModalFooterButtons>
          <CloseModalButton
            currentModalState={currentModalState}
            closeModal={closeModal}
            athenaLink={athenaLink}
            isPolling={isPolling}
            duplicateAthenaLinkIngested={duplicateAthenaLinkIngested}
          />
          <RegisterModalButton
            setCurrentModalStateCallback={setCurrentModalState}
            currentModalState={currentModalState}
            registerPatientLoading={registerPatientLoading}
            athenaLink={athenaLink}
            athenaButtonText={athenaButtonText}
            selectedAthenaLink={selectedAthenaLink}
            hasMultipleAthenaLinks={hasMultipleAthenaLinks}
            shouldIngest={shouldIngest}
            shouldIngestCallback={shouldIngestCallback}
            duplicateAthenaLinkIngested={duplicateAthenaLinkIngested}
          />
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default RegisterPatientModal;
