import { apiPanelManagement } from "./AxiosService";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import RecurrenceFrequencyEnum from "../enums/Calendaring/Appointments/RecurrenceFrequencyEnum";
import { AxiosHeaders } from "axios";

const panelManagementService = apiPanelManagement
  .enhanceEndpoints({
    addTagTypes: []
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getTNAvailability: builder.query<
        any,
        {
          memberId: string;
          startdate: string;
          enddate: string;
          recurrence_frequency: RecurrenceFrequencyEnum;
          xTraceId: string;
        }
      >({
        query: ({
          memberId,
          startdate,
          enddate,
          recurrence_frequency,
          xTraceId
        }) => {
          const params = QueryString_stringify({
            patient_id: memberId,
            startdate,
            enddate,
            recurrence_frequency
          });
          return {
            url: `/staff-availability/tn?${params}`,
            method: "GET",
            headers: new AxiosHeaders({
              "X-Trace-Id": xTraceId
            })
          };
        },
        // don't cache
        keepUnusedDataFor: 0,
        providesTags: []
      }),
      getStaffAvailability: builder.query<
        any,
        {
          staff_id: string;
          startdate: string;
          enddate: string;
          recurrence_frequency?: RecurrenceFrequencyEnum;
          xTraceId: string;
        }
      >({
        query: ({
          staff_id,
          startdate,
          enddate,
          recurrence_frequency,
          xTraceId
        }) => {
          const params = QueryString_stringify({
            startdate,
            enddate,
            recurrence_frequency
          });
          return {
            url: `/staff-availability/staff/${staff_id}/availability?${params}`,
            method: "GET",
            headers: new AxiosHeaders({
              "X-Trace-Id": xTraceId
            })
          };
        },
        // don't cache
        keepUnusedDataFor: 0,
        providesTags: []
        // transformResponse: (response) => {
        //   // hardcode no availability for testing
        //   return [];
        // }
      }),
      getStaffAvailabilityRescheduleEvent: builder.query<
        any,
        {
          event_id: string;
        }
      >({
        query: ({ event_id }) => {
          return {
            url: `/staff-availability/reschedule-event/${event_id}`,
            method: "GET"
          };
        },
        // don't cache
        keepUnusedDataFor: 0,
        providesTags: []
      })
    })
  });

export const {
  useGetTNAvailabilityQuery,
  useGetStaffAvailabilityQuery,
  useGetStaffAvailabilityRescheduleEventQuery
} = panelManagementService;
