import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";

import { StyledTextField } from "../../../helpers/components/Forms/FormHelpers";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../../styling/StyleModal";
import { TurqoiseButton } from "../../../styling";
import { formatName, getNameOrUsername, isFalsy } from "common/helpers/helpers";
import {
  useCreateCalendarEventMutation,
  useUpdateCalendarEventMutation,
  DELAY_AFTER_REQUEST_COMPLETED
} from "common/services/CalendarService";
import { v4 as uuidv4 } from "uuid";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import { RootState, useAppDispatch } from "common/redux";
import { useSelector } from "react-redux";
import { DateTime, Duration } from "luxon";
import ErrorComponent from "../../../components/ErrorComponent";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import DatePicker from "../../../components/DatePicker";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { error, gray } from "../../../styling/colors";
import RecurrenceUpdateTypeEnum from "common/enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";
import { resetPtoState } from "common/redux/PtoSlice";
import UserScheduleType from "common/types/UserScheduleType";
import {
  calculateAvailableTimeslots,
  calculateTimeSlotsForProviderBlock,
  getRecurrenceObject,
  getStringFromStartEndISODateStrings,
  isEvenBiweeklyRecurrence,
  isProviderAppointment,
  isProviderBlockAppointment,
  ProviderBlockReasons,
  recurrenceUpdateTypeValues,
  recurrenceValues
} from "common/helpers/CalendarHelper";
import CalendarEventResponseType from "common/types/Calendaring/CalendarEventResponseType";
import { Repeat } from "@mui/icons-material";
import RecurrenceFrequencyEnum from "common/enums/Calendaring/Appointments/RecurrenceFrequencyEnum";
import CalendarRecurringEventInfoResponseType from "common/types/Calendaring/CalendarRecurringEventInfoResponseType";

interface IProps {
  isVisible: boolean;
  onRequestClose: (refetch: boolean) => void;
  isEdit?: boolean;
  selectedCarerId?: string;
  modalKey: string;
  carerName: string;
  providerScheduleType: UserScheduleType;
  calendarEventsData: CalendarEventResponseType[];
  recurringEventsWeekdaysMap: Record<
    string,
    CalendarRecurringEventInfoResponseType[]
  >;
}

interface TimeslotDropdownType {
  label: string;
  value: number;
  hidden?: boolean;
}

interface IPropsRender extends IProps {
  event?: any;
  eventId?: string;
  carerTimezone?: string;
  availableTimeSlots: TimeslotDropdownType[];
}

function getTimeConflictEventTitle(event) {
  const attendee = getNameOrUsername(event?.attendees?.[0]);
  const appointmentType = formatName(event?.appointment_type);
  const frequency =
    event?.recurrence?.frequency ??
    event?.recurrenceData?.recurrence?.frequency;
  const dayOfWeek =
    event?.recurrence?.date_of_week ??
    event?.recurrenceData?.recurrence?.date_of_week;
  if (frequency) {
    if (isProviderBlockAppointment(event?.appointment_type)) {
      return `${formatName(dayOfWeek)} ${appointmentType} (${frequency})`;
    }
    return `${attendee} (${frequency})`;
  }

  if (isProviderBlockAppointment(event?.appointment_type)) {
    return `${appointmentType}`;
  }
  return `${attendee}`;
}

function getTimeConflicts(
  selectedDateStart: string,
  selectedDateEnd: string,
  calendarEventsData: CalendarEventResponseType[],
  providerScheduleType: UserScheduleType,
  selectedProviderTimezone: string,
  showDateForConflict: boolean,
  eventId?: string,
  recurrenceId?: string
) {
  const conflicts = [];
  const slots = calculateTimeSlotsForProviderBlock(
    selectedDateStart,
    selectedDateEnd,
    providerScheduleType
  );
  slots?.forEach((slot) => {
    const occupiedTimeSlotIndices = slot.occupiedTimeSlotIndices;
    // out of the 3 available slots, how many are occupied by conflicting appointments?
    // increment this counter for each Provider Block or Provider appointment
    let conflictedTimeSlotCount = 0;

    const eventsIntersectingWithSlot = calendarEventsData?.filter((event) => {
      const eventStart = DateTime.fromISO(event.startdate);
      const eventEnd = DateTime.fromISO(event.enddate);

      return (
        eventStart < slot.timeslotEndDateObject &&
        eventEnd > slot.timeslotStartDateObject &&
        // don't include the event that is being edited
        event?.event_id !== eventId &&
        // don't include the recurring event that is being edited
        event?.recurring_id !== recurrenceId
      );
    });

    const finalEventsIntersectingWithSlot = eventsIntersectingWithSlot?.map(
      (event) => {
        // calculate if there is a conflict based on the other provider block
        if (isProviderBlockAppointment(event.appointment_type)) {
          const providerBlockSlots = calculateTimeSlotsForProviderBlock(
            event.startdate,
            event.enddate,
            providerScheduleType
          );

          const affectedProviderBlockSlot = providerBlockSlots?.find(
            (item) => item.timeSlotForThisHour === slot.timeSlotForThisHour
          );

          const affectedOccupiedSlots =
            affectedProviderBlockSlot?.occupiedTimeSlotIndices;

          const isConflict = affectedOccupiedSlots?.some((item) =>
            slot?.occupiedTimeSlotIndices.includes(item)
          );

          affectedOccupiedSlots?.forEach(() => {
            conflictedTimeSlotCount++;
          });

          return {
            ...event,
            isConflict
          };
        } else if (isProviderAppointment(event.appointment_type)) {
          // increment conflicted time slot count
          conflictedTimeSlotCount++;
          return { ...event, isConflict: undefined };
        }
      }
    );

    finalEventsIntersectingWithSlot?.forEach((event) => {
      if (
        // if we have a provider block conflict
        (isProviderBlockAppointment(event.appointment_type) &&
          event?.isConflict) ||
        // or a provider appointment conflict
        (isProviderAppointment(event.appointment_type) &&
          occupiedTimeSlotIndices?.length + conflictedTimeSlotCount > 3)
      ) {
        const hasConflictAlready = conflicts.some(
          (conflict) => conflict.event_id === event.event_id
        );
        if (!hasConflictAlready) {
          conflicts.push({
            ...event,
            title: getTimeConflictEventTitle(event),
            conflictTime: getStringFromStartEndISODateStrings(
              event.startdate,
              event.enddate,
              selectedProviderTimezone,
              showDateForConflict
            )
          });
        }
      }
    });
  });

  return conflicts;
}

// get the start and end dates for the recurring event
// day represents
function getRecurrenceStartEndDates(event, dateToCompareWith: DateTime) {
  let recurrentEventStart;
  let recurrentEventEnd;
  if (isFalsy(event?.recurrence?.time)) {
    return {
      recurrentEventStart,
      recurrentEventEnd,
      recurrentEventStartISO: recurrentEventStart?.toISO(),
      recurrentEventEndISO: recurrentEventEnd?.toISO()
    };
  }
  const recurrentEventHour = +event?.recurrence?.time?.substring(0, 2);
  const recurrentEventMinute = +event?.recurrence?.time?.substring(3, 5);

  recurrentEventStart = dateToCompareWith
    .setZone(event?.recurrence?.staff_timezone)
    ?.set({
      hour: recurrentEventHour,
      minute: recurrentEventMinute,
      second: 0,
      millisecond: 0
    });

  if (isFalsy(recurrentEventStart?.isValid)) {
    return {
      recurrentEventStart,
      recurrentEventEnd,
      recurrentEventStartISO: recurrentEventStart?.toISO(),
      recurrentEventEndISO: recurrentEventEnd?.toISO()
    };
  }

  recurrentEventEnd = recurrentEventStart.plus(
    Duration.fromObject({ minutes: event?.recurrence?.duration })
  );

  return {
    recurrentEventStart,
    recurrentEventEnd,
    recurrentEventStartISO: recurrentEventStart?.toISO(),
    recurrentEventEndISO: recurrentEventEnd?.toISO()
  };
}

function getRecurrentTimeConflicts(
  selectedDateStart: string,
  selectedDateEnd: string,
  recurringEventsWeekdaysMap: Record<
    string,
    CalendarRecurringEventInfoResponseType[]
  >,
  selectedProviderTimezone: string,
  recurrenceFrequency: RecurrenceFrequencyEnum = RecurrenceFrequencyEnum.WEEKLY,
  recurrenceId?: string
) {
  const conflicts = [];
  const start = DateTime.fromISO(selectedDateStart).setZone(
    selectedProviderTimezone
  );
  const end = DateTime.fromISO(selectedDateEnd).setZone(
    selectedProviderTimezone
  );

  if (isFalsy(start?.isValid) || isFalsy(end?.isValid)) {
    return [];
  }

  const selectedDay = start.weekdayLong.toUpperCase();

  const recurrentEventsForDay = recurringEventsWeekdaysMap?.[selectedDay];

  const isEven = isEvenBiweeklyRecurrence(start);

  const eventsIntersectingWithSlot = recurrentEventsForDay?.filter((event) => {
    const { recurrentEventStart, recurrentEventEnd } =
      getRecurrenceStartEndDates(event, start);

    if (
      event?.recurrence?.frequency === RecurrenceFrequencyEnum.BIWEEKLY &&
      recurrenceFrequency === RecurrenceFrequencyEnum.BIWEEKLY
    ) {
      const isEventToCheckEven =
        event?.recurrence?.frequency_type_identifier === "EVEN";

      if ((!isEventToCheckEven && isEven) || (isEventToCheckEven && !isEven)) {
        // ignore opposite biweekly recurrences
        return false;
      }
      return true;
    }

    if (
      isFalsy(recurrentEventStart?.isValid) ||
      isFalsy(recurrentEventEnd?.isValid) ||
      // don't include the event that is being edited
      event?.recurring_event_id === recurrenceId
    ) {
      return false;
    }

    return (
      (recurrentEventStart > start &&
        recurrentEventStart < end &&
        recurrentEventStart.weekdayLong.toUpperCase() === selectedDay) ||
      (recurrentEventEnd > start &&
        recurrentEventEnd < end &&
        recurrentEventEnd.weekdayLong.toUpperCase() === selectedDay)
    );
  });

  eventsIntersectingWithSlot?.forEach((event) => {
    const hasConflictAlready = conflicts.some(
      (conflict) => conflict.recurring_event_id === event.recurring_event_id
    );
    if (!hasConflictAlready) {
      const {
        recurrentEventStart,
        recurrentEventEnd,
        recurrentEventStartISO,
        recurrentEventEndISO
      } = getRecurrenceStartEndDates(event, start);

      if (recurrentEventStart?.isValid && recurrentEventEnd?.isValid) {
        conflicts.push({
          ...event,
          title: getTimeConflictEventTitle(event),
          conflictTime: getStringFromStartEndISODateStrings(
            recurrentEventStartISO,
            recurrentEventEndISO,
            selectedProviderTimezone,
            false
          )
        });
      }
    }
  });

  return conflicts;
}

function getTimeForRequest(
  selectedDate: DateTime,
  selectedTime: number,
  providerTimezone: string
) {
  const selectedTimeDT =
    DateTime.fromMillis(selectedTime).setZone(providerTimezone);
  return selectedDate.setZone(providerTimezone).set({
    hour: selectedTimeDT.hour,
    minute: selectedTimeDT.minute,
    second: 0,
    millisecond: 0
  });
}

function getRecurrenceIncrementer(recurrenceType: RecurrenceFrequencyEnum) {
  switch (recurrenceType) {
    case RecurrenceFrequencyEnum.BIWEEKLY:
      return 2;
    case RecurrenceFrequencyEnum.WEEKLY:
      return 1;
    default:
      return 1;
  }
}

function getInitialTime(date: string, providerTimezone: string) {
  const today = DateTime.now();
  return DateTime.fromISO(date)
    .setZone(providerTimezone)
    .set({ day: today.day, month: today.month, year: today.year })
    .toMillis();
}

function startTimeGreaterThanEndTime(startTime: number, endTime: number) {
  return startTime && endTime && startTime >= endTime;
}

function startTimeIsInThePast(
  startTime: number,
  selectedDate: DateTime,
  now: DateTime
) {
  return (
    startTime &&
    now &&
    DateTime.fromMillis(startTime).set({
      year: selectedDate?.year,
      month: selectedDate?.month,
      day: selectedDate?.day
    }) < now
  );
}

const ProviderBlockTimeModalRender = ({
  isVisible,
  onRequestClose,
  isEdit = false,
  event,
  eventId,
  selectedCarerId,
  carerTimezone,
  modalKey,
  carerName,
  availableTimeSlots,
  calendarEventsData,
  recurringEventsWeekdaysMap,
  providerScheduleType
}: IPropsRender) => {
  const dispatch = useAppDispatch();
  const now = useMemo(() => {
    return DateTime.now().startOf("minute");
  }, []);
  const [userSelectedCarerId, setUserSelectedCarerId] =
    useState<string>(selectedCarerId);
  const [selectedProviderBlockReason, setSelectedProviderBlockReason] =
    useState<AppointmentTypeEnum>(
      event?.appointmentType as AppointmentTypeEnum
    );
  const [selectedProviderTimezone, setSelectedProviderTimezone] =
    useState<string>(carerTimezone);

  const [selectedDate, setSelectedDate] = useState<DateTime | null>(
    event?.visitsRequest?.calendar_event_start
      ? DateTime.fromISO(event?.visitsRequest?.calendar_event_start)
      : DateTime.now().startOf("minute")
  );

  const finalAvailableTimeSlots: TimeslotDropdownType[] = useMemo(() => {
    if (isFalsy(selectedDate?.isValid) || !carerTimezone) {
      return availableTimeSlots;
    }

    const selectedDateTZ = selectedDate
      ?.setZone(carerTimezone)
      ?.toFormat("ZZZZ");

    const finalSlots: TimeslotDropdownType[] = availableTimeSlots.map(
      (slot) => {
        const finalSlotLabel = `${slot.label.substring(0, slot.label.length - 3)} ${selectedDateTZ}`;
        return {
          label: finalSlotLabel,
          value: slot.value
        };
      }
    );

    // if we are editing a calendar event
    if (event?.visitsRequest?.calendar_event_start) {
      const initialStartTime = getInitialTime(
        event?.visitsRequest?.calendar_event_start,
        carerTimezone
      );
      const initialStartTimeISO = DateTime.fromMillis(initialStartTime)
        ?.setZone(carerTimezone)
        ?.toFormat("hh:mm a");

      const initialStartTimeLabel = `${initialStartTimeISO.toString().substring(0, initialStartTimeISO.toString().length - 3)} ${selectedDateTZ}`;
      const initialEndTime = getInitialTime(
        event?.visitsRequest?.calendar_event_end,
        carerTimezone
      );
      const initialEndTimeISO = DateTime.fromMillis(initialEndTime)
        ?.setZone(carerTimezone)
        ?.toFormat("hh:mm a");
      const initialEndTimeLabel = `${initialEndTimeISO.toString().substring(0, initialEndTimeISO.toString().length - 3)} ${selectedDateTZ}`;

      // and the start time attached to the event is not in the list of available slots
      if (
        finalSlots?.findIndex((item) => item.value === initialStartTime) === -1
      ) {
        // add the slot with "hidden" set to true
        finalSlots.push({
          label: initialStartTimeLabel,
          value: initialStartTime,
          hidden: true
        });
      }

      // and the end time attached to the event is not in the list of available slots
      if (
        finalSlots?.findIndex((item) => item.value === initialEndTime) === -1
      ) {
        // add the slot with "hidden" set to true
        finalSlots.push({
          label: initialEndTimeLabel,
          value: initialEndTime,
          hidden: true
        });
      }
    }
    return finalSlots;
  }, [
    availableTimeSlots,
    selectedDate,
    carerTimezone,
    event?.visitsRequest?.calendar_event_start
  ]);
  const [selectedStartTime, setSelectedStartTime] = useState<number>(
    event?.visitsRequest?.calendar_event_start
      ? getInitialTime(
          event?.visitsRequest?.calendar_event_start,
          carerTimezone
        )
      : null
  );

  const [selectedEndTime, setSelectedEndTime] = useState<number>(
    event?.visitsRequest?.calendar_event_start
      ? getInitialTime(event?.visitsRequest?.calendar_event_end, carerTimezone)
      : null
  );

  const defaultRecurrenceUpdateType = RecurrenceUpdateTypeEnum.ONCE;

  const [recurrenceUpdateType, setRecurrenceUpdateType] = useState(
    defaultRecurrenceUpdateType
  );
  const defaultRecurrence = RecurrenceFrequencyEnum.NEVER;
  const [initialRecurrenceType, setInitialRecurrenceType] =
    useState(defaultRecurrence);

  const hasChanges = useMemo(() => {
    if (isEdit) {
      const startDT = DateTime.fromISO(
        event?.visitsRequest?.calendar_event_start
      );

      const originalStartTime = getInitialTime(
        event?.visitsRequest?.calendar_event_start,
        carerTimezone
      );
      const originalEndTime = getInitialTime(
        event?.visitsRequest?.calendar_event_end,
        carerTimezone
      );
      return (
        selectedProviderBlockReason !== event?.appointmentType ||
        selectedDate?.toISODate() !== startDT.toISODate() ||
        originalStartTime !== selectedStartTime ||
        originalEndTime !== selectedEndTime
      );
    }
    return false;
  }, [
    event,
    isEdit,
    selectedProviderBlockReason,
    selectedDate,
    selectedStartTime,
    selectedEndTime
  ]);

  const changes = useMemo(() => {
    const changesObj = {};
    changesObj["update_type"] = RecurrenceUpdateTypeEnum.ONCE;
    if (isEdit) {
      if (selectedProviderBlockReason !== event?.appointmentType) {
        changesObj["appointment_type"] = selectedProviderBlockReason;
      }

      const originalStartTime = getInitialTime(
        event?.visitsRequest?.calendar_event_start,
        carerTimezone
      );
      const originalEndTime = getInitialTime(
        event?.visitsRequest?.calendar_event_end,
        carerTimezone
      );

      if (
        selectedDate?.toISODate() !==
          DateTime.fromISO(
            event?.visitsRequest?.calendar_event_start
          ).toISODate() ||
        originalStartTime !== selectedStartTime ||
        originalEndTime !== selectedEndTime
      ) {
        const start = getTimeForRequest(
          selectedDate,
          selectedStartTime,
          selectedProviderTimezone
        );
        const end = getTimeForRequest(
          selectedDate,
          selectedEndTime,
          selectedProviderTimezone
        );

        changesObj["start_date"] = start?.toUTC()?.toISO();
        changesObj["end_date"] = end?.toUTC()?.toISO();
      }

      return changesObj;
    }
    return {};
  }, [
    event,
    isEdit,
    selectedProviderBlockReason,
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    selectedProviderTimezone
  ]);

  const { user } = useSelector((state: RootState) => state.auth);

  const resetSelectedProvider = useCallback(() => {
    setUserSelectedCarerId("");
    setSelectedProviderBlockReason(undefined);
    setSelectedProviderTimezone("");
  }, [
    setUserSelectedCarerId,
    setSelectedProviderBlockReason,
    setSelectedProviderTimezone
  ]);

  const handleProviderBlockTimeModalClose = (refetch: boolean = false) => {
    onRequestClose(refetch);
    resetCreateCalendarEvent();
    resetUpdateCalendarEvent();
    resetSelectedProvider();
    setSelectedDate(null);
    dispatch(resetPtoState());
  };

  const [
    createCalendarEventMutation,
    {
      data: createCalendarEventData,
      isLoading: createCalendarEventIsLoading,
      fulfilledTimeStamp: createCalendarFulfilledTimestamp,
      error: createCalendarEventError,
      reset: resetCreateCalendarEvent
    }
  ] = useCreateCalendarEventMutation();

  const [
    updateCalendarEventMutation,
    {
      data: updateCalendarEventData,
      isLoading: updateCalendarEventIsLoading,
      fulfilledTimeStamp: updateCalendarFulfilledTimestamp,
      error: updateCalendarEventError,
      reset: resetUpdateCalendarEvent
    }
  ] = useUpdateCalendarEventMutation();

  useEffect(() => {
    if (createCalendarEventData) {
      setTimeout(() => {
        handleProviderBlockTimeModalClose(true);
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }
  }, [createCalendarEventData]);

  useEffect(() => {
    if (updateCalendarEventData) {
      setTimeout(() => {
        handleProviderBlockTimeModalClose(true);
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }
  }, [updateCalendarEventData]);

  const timeConflicts = useMemo(() => {
    if (
      isFalsy(selectedDate) ||
      isFalsy(selectedStartTime) ||
      isFalsy(selectedEndTime) ||
      isFalsy(selectedProviderTimezone)
    ) {
      return [];
    }

    const conflicts = [];
    if (
      // code to handle time conflicts when editing recurring events
      recurrenceUpdateType === RecurrenceUpdateTypeEnum.THIS_EVENT_FORWARD &&
      isEdit
    ) {
      const daysToCheckAsISOStrings = [];
      const start = getTimeForRequest(
        selectedDate,
        selectedStartTime,
        selectedProviderTimezone
      );
      const end = getTimeForRequest(
        selectedDate,
        selectedEndTime,
        selectedProviderTimezone
      );

      const selectedDateStart = start.toISO();
      const selectedDateEnd = end.toISO();

      const recurrentConflicts = getRecurrentTimeConflicts(
        selectedDateStart,
        selectedDateEnd,
        recurringEventsWeekdaysMap,
        selectedProviderTimezone,
        event?.recurrenceData?.recurrence?.frequency,
        event?.recurrenceData?.recurring_event_id
      );

      recurrentConflicts?.forEach((conflict) => {
        conflicts.push(conflict);
      });

      daysToCheckAsISOStrings.push({
        start: selectedDateStart,
        end: selectedDateEnd
      });

      const recurrenceIncrementer = getRecurrenceIncrementer(
        event?.recurrenceData?.frequency
      );

      for (let i = 0; i < 7; i++) {
        const nextWeekStart = start.plus({ weeks: i * recurrenceIncrementer });
        const nextWeekEnd = end.plus({ weeks: i * recurrenceIncrementer });

        daysToCheckAsISOStrings.push({
          start: nextWeekStart.toISO(),
          end: nextWeekEnd.toISO()
        });
      }

      daysToCheckAsISOStrings.forEach((day) => {
        const conflictsForSlot = getTimeConflicts(
          day.start,
          day.end,
          calendarEventsData,
          providerScheduleType,
          selectedProviderTimezone,
          true,
          eventId,
          event?.recurrenceData?.recurring_event_id
        );

        conflictsForSlot?.forEach((conflict) => {
          const hasConflictAlready = conflicts?.some(
            (item) => conflict?.event_id === item?.event_id
          );
          if (!hasConflictAlready) {
            conflicts.push(conflict);
          }
        });
      });

      return conflicts;
    } else if (
      // code to handle creating recurring events
      initialRecurrenceType !== RecurrenceFrequencyEnum.NEVER &&
      !isEdit
    ) {
      const daysToCheckAsISOStrings = [];
      const start = getTimeForRequest(
        selectedDate,
        selectedStartTime,
        selectedProviderTimezone
      );
      const end = getTimeForRequest(
        selectedDate,
        selectedEndTime,
        selectedProviderTimezone
      );

      const selectedDateStart = start.toISO();
      const selectedDateEnd = end.toISO();

      daysToCheckAsISOStrings.push({
        start: selectedDateStart,
        end: selectedDateEnd
      });

      const recurrentConflicts = getRecurrentTimeConflicts(
        selectedDateStart,
        selectedDateEnd,
        recurringEventsWeekdaysMap,
        selectedProviderTimezone,
        initialRecurrenceType
      );

      recurrentConflicts?.forEach((conflict) => {
        conflicts.push(conflict);
      });

      const incrementer = getRecurrenceIncrementer(initialRecurrenceType);

      for (let i = 0; i < 7; i++) {
        const nextWeekStart = start.plus({ weeks: i * incrementer });
        const nextWeekEnd = end.plus({ weeks: i * incrementer });

        daysToCheckAsISOStrings.push({
          start: nextWeekStart.toISO(),
          end: nextWeekEnd.toISO()
        });
      }

      daysToCheckAsISOStrings.forEach((day) => {
        const conflictsForSlot = getTimeConflicts(
          day.start,
          day.end,
          calendarEventsData,
          providerScheduleType,
          selectedProviderTimezone,
          true,
          eventId
        );

        conflictsForSlot?.forEach((conflict) => {
          const hasConflictAlready = conflicts?.some(
            (item) => conflict?.event_id === item?.event_id
          );
          if (!hasConflictAlready) {
            conflicts.push(conflict);
          }
        });
      });

      return conflicts;
    } else {
      const start = getTimeForRequest(
        selectedDate,
        selectedStartTime,
        selectedProviderTimezone
      );
      const end = getTimeForRequest(
        selectedDate,
        selectedEndTime,
        selectedProviderTimezone
      );

      const selectedDateStart = start.toISO();
      const selectedDateEnd = end.toISO();

      const conflictsForSlot = getTimeConflicts(
        selectedDateStart,
        selectedDateEnd,
        calendarEventsData,
        providerScheduleType,
        selectedProviderTimezone,
        false,
        eventId
      );

      conflictsForSlot?.forEach((conflict) => {
        const hasConflictAlready = conflicts?.some(
          (item) => conflict?.event_id === item?.event_id
        );
        if (!hasConflictAlready) {
          conflicts.push(conflict);
        }
      });

      return conflicts;
    }
  }, [
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    selectedProviderTimezone,
    calendarEventsData,
    recurringEventsWeekdaysMap,
    isEdit,
    recurrenceUpdateType,
    initialRecurrenceType,
    providerScheduleType,
    event,
    eventId
  ]);

  const xTraceId = useMemo(() => `add-provider-block-${uuidv4()}`, []);

  const onSubmit = useCallback(() => {
    const start = getTimeForRequest(
      selectedDate,
      selectedStartTime,
      selectedProviderTimezone
    );
    const end = getTimeForRequest(
      selectedDate,
      selectedEndTime,
      selectedProviderTimezone
    );

    const recurrenceObject = getRecurrenceObject(
      initialRecurrenceType,
      start.toISO(),
      end.toISO(),
      carerTimezone
    );

    if (isEdit && changes) {
      updateCalendarEventMutation({
        body: { ...changes, update_type: recurrenceUpdateType },
        staffId: selectedCarerId,
        eventId
      });
    } else {
      createCalendarEventMutation({
        body: {
          start_date: start?.toUTC()?.toISO(),
          end_date: end?.toUTC()?.toISO(),
          appointment_type: selectedProviderBlockReason,
          created_by: user?.user_id,
          ...(recurrenceObject && { recurrence: recurrenceObject })
        },
        staffId: userSelectedCarerId,
        xTraceId
      });
    }
  }, [
    selectedCarerId,
    userSelectedCarerId,
    selectedProviderTimezone,
    selectedProviderBlockReason,
    selectedDate,
    selectedStartTime,
    selectedEndTime,
    initialRecurrenceType,
    recurrenceUpdateType,
    changes
  ]);

  const isLoading = useGetDelayedLoadingBoolean(
    isEdit ? updateCalendarEventIsLoading : createCalendarEventIsLoading,
    isEdit
      ? updateCalendarFulfilledTimestamp
      : createCalendarFulfilledTimestamp,
    isEdit ? updateCalendarEventData : createCalendarEventData,
    DELAY_AFTER_REQUEST_COMPLETED
  );

  const submitButtonDisabled = useMemo(() => {
    if (timeConflicts?.length > 0) {
      return true;
    }

    if (
      startTimeGreaterThanEndTime(selectedStartTime, selectedEndTime) ||
      startTimeIsInThePast(selectedStartTime, selectedDate, now) ||
      isFalsy(selectedStartTime) ||
      isFalsy(selectedEndTime)
    ) {
      return true;
    }

    if (isEdit) {
      return (
        isFalsy(selectedProviderBlockReason) ||
        !selectedDate?.isValid ||
        !hasChanges
      );
    }
    return (
      isFalsy(userSelectedCarerId) ||
      isFalsy(selectedProviderBlockReason) ||
      !selectedDate?.isValid
    );
  }, [
    selectedCarerId,
    userSelectedCarerId,
    selectedProviderBlockReason,
    event,
    hasChanges,
    selectedDate,
    timeConflicts,
    selectedStartTime,
    selectedEndTime
  ]);

  return (
    <StyledModal
      key={modalKey}
      isOpen={isVisible}
      onRequestClose={() => handleProviderBlockTimeModalClose(false)}
      modalHeight="90vh"
      modalWidth="65%"
      contentLabel="Change Role"
    >
      <ModalHeader
        onRequestClose={() => handleProviderBlockTimeModalClose(false)}
      >
        {isEdit ? "Edit" : "Add"} Blocked Time
      </ModalHeader>
      <ModalBody>
        <div>
          Provider: <b>{carerName}</b>
        </div>
        <br />

        {isFalsy(providerScheduleType) ? (
          <ErrorComponent error="Can't add blocked time. Provider schedule type is missing. Please add it in their staff profile." />
        ) : (
          <>
            <DatePicker
              label={"Date"}
              name="selectedDate"
              value={selectedDate}
              minDate={DateTime.now().startOf("day")}
              maxDate={DateTime.now().plus({ years: 1 })}
              onChange={(dateTime: DateTime) => {
                if (dateTime?.isValid) {
                  setSelectedDate(dateTime);
                }
              }}
              slotProps={{
                field: {
                  readOnly: true
                },
                textField: {
                  error: false,
                  helperText: "",
                  // prevent user from typing in the date as this can lead to bugs
                  // see ENG-3757
                  // the below code needs to be here instead of in DateTimePicker.tsx
                  // until this PR is merged https://github.com/mui/material-ui/pull/35088
                  onKeyDown: (e) => {
                    e.preventDefault();
                  }
                }
              }}
            />

            <br />

            <Flexbox alignItems="center" justifyContent="space-between">
              <StyledTextField
                select
                aria-label="Start Time"
                label="Start Time"
                error={
                  startTimeGreaterThanEndTime(
                    selectedStartTime,
                    selectedEndTime
                  ) ||
                  startTimeIsInThePast(selectedStartTime, selectedDate, now)
                }
                helperText={
                  startTimeGreaterThanEndTime(
                    selectedStartTime,
                    selectedEndTime
                  )
                    ? "Start time must be less than end time"
                    : startTimeIsInThePast(selectedStartTime, selectedDate, now)
                      ? "Start time must be in the future"
                      : ""
                }
                SelectProps={{
                  variant: "outlined",
                  value: selectedStartTime,
                  defaultValue: "",
                  sx: { minWidth: "150px" },
                  onChange: (event) => {
                    setSelectedStartTime(event.target.value);
                  },
                  MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
                }}
              >
                {finalAvailableTimeSlots?.map((slot) => {
                  return (
                    <MenuItem
                      // make the menu item invisible (but still selectable) if it's outside of the range
                      // of acceptable time slots
                      // this is to prevent the user from selecting a time slot that is not available
                      // while still showing the current time on an event that is being edited
                      sx={{ display: slot?.hidden ? "none" : "block" }}
                      key={slot.label}
                      value={slot.value}
                    >
                      {slot.label}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
              <Typography margin="0 24px" color={gray[500]}>
                to
              </Typography>
              <StyledTextField
                select
                aria-label="End Time"
                label="End Time"
                error={
                  startTimeGreaterThanEndTime(
                    selectedStartTime,
                    selectedEndTime
                  ) ||
                  startTimeIsInThePast(selectedStartTime, selectedDate, now)
                }
                helperText={
                  startTimeGreaterThanEndTime(
                    selectedStartTime,
                    selectedEndTime
                  )
                    ? "End time must be greater than start time"
                    : startTimeIsInThePast(selectedStartTime, selectedDate, now)
                      ? "Event must be in the future"
                      : ""
                }
                SelectProps={{
                  variant: "outlined",
                  value: selectedEndTime,
                  sx: { minWidth: "150px" },
                  defaultValue: "",
                  onChange: (event) => {
                    setSelectedEndTime(event.target.value);
                  },
                  MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
                }}
              >
                {finalAvailableTimeSlots?.map((slot) => {
                  return (
                    <MenuItem
                      sx={{ display: slot?.hidden ? "none" : "block" }}
                      key={slot.label}
                      value={slot.value}
                    >
                      {slot.label}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            </Flexbox>

            <br />
            <StyledTextField
              select
              aria-label="Select Reason"
              label="Reason"
              SelectProps={{
                variant: "outlined",
                value: selectedProviderBlockReason,
                defaultValue: "",
                onChange: (event) => {
                  setSelectedProviderBlockReason(event.target.value);
                },
                MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
              }}
            >
              {ProviderBlockReasons?.map((reason) => {
                return (
                  <MenuItem key={reason} value={reason}>
                    {formatName(reason)}
                  </MenuItem>
                );
              })}
            </StyledTextField>
            <br />
            {!isEdit && (
              <TextField
                select
                fullWidth
                value={initialRecurrenceType ?? ""}
                label={
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px"
                    }}
                  >
                    <Repeat />
                    Repeat
                  </Typography>
                }
                id="recurrence"
                defaultValue={defaultRecurrence}
                onChange={(e) => {
                  setInitialRecurrenceType(
                    e.target.value as RecurrenceFrequencyEnum
                  );
                }}
              >
                {recurrenceValues.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {event?.recurring && isEdit && (
              <Flexbox flexDirection="column" gap="8px">
                <Typography variant="h5" color="text.primary">
                  Recurring Event
                </Typography>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={recurrenceUpdateType ?? ""}
                  onChange={(e) => {
                    setRecurrenceUpdateType(
                      e.target.value as RecurrenceUpdateTypeEnum
                    );
                  }}
                >
                  {recurrenceUpdateTypeValues.map(({ value, label }, i) => (
                    <Flexbox marginTop="4px" key={value}>
                      <FormControlLabel
                        sx={{
                          width: "100%",
                          margin: 0,
                          ...(i !== recurrenceUpdateTypeValues.length - 1 && {
                            borderBottom: `1px solid ${gray[300]}`
                          })
                        }}
                        value={value}
                        control={<Radio sx={{ padding: "4px 0" }} />}
                        label={
                          <Typography
                            variant="body1"
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                              marginLeft: "2px"
                            }}
                          >
                            {label}
                          </Typography>
                        }
                      />
                    </Flexbox>
                  ))}
                </RadioGroup>
              </Flexbox>
            )}
            <br />
            {timeConflicts?.length > 0 && (
              <Box
                padding="8px 16px"
                border="1px solid"
                borderColor="error.main"
                bgcolor={error[100]}
              >
                <Flexbox gap="12px" flexDirection="column">
                  <Typography>
                    There are calendar conflicts with this time selection. You
                    must solve them from the schedule before blocking this time,
                    or adjust your time selection above.
                  </Typography>
                  <Box>
                    {timeConflicts.map((conflict) => {
                      return (
                        <Flexbox
                          key={conflict.event_id}
                          gap="8px"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box flexBasis="55%">
                            <Typography fontWeight={600}>
                              {conflict.title}
                            </Typography>
                          </Box>
                          <Box flexBasis="45%">
                            <Typography>{conflict.conflictTime}</Typography>
                          </Box>
                        </Flexbox>
                      );
                    })}
                  </Box>
                </Flexbox>
              </Box>
            )}
          </>
        )}
        {createCalendarEventError && (
          <ErrorComponent error={createCalendarEventError} />
        )}
        {updateCalendarEventError && (
          <ErrorComponent
            error={updateCalendarEventError}
            showErrorResponseMessage
            hideErrorCode
          />
        )}
      </ModalBody>

      <ModalFooter>
        <ModalFooterButtons>
          <TurqoiseButton
            onClick={() => {
              onSubmit();
            }}
            disabled={submitButtonDisabled}
            loading={isLoading}
          >
            Submit
          </TurqoiseButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

const ProviderBlockTimeModal = ({
  isVisible,
  onRequestClose,
  selectedCarerId,
  isEdit = false,
  modalKey,
  carerName,
  providerScheduleType,
  calendarEventsData,
  recurringEventsWeekdaysMap
}: IProps) => {
  const { event, eventId, carerTimezone } = useSelector(
    (state: RootState) => state.pto
  );

  const availableTimeSlots = useMemo(
    () => calculateAvailableTimeslots(carerTimezone, providerScheduleType),
    [providerScheduleType, carerTimezone]
  );

  if (!isVisible) {
    return null;
  }

  if (isEdit) {
    if (event && eventId && availableTimeSlots && carerTimezone) {
      return (
        <ProviderBlockTimeModalRender
          key={modalKey}
          modalKey={modalKey}
          isVisible={isVisible}
          onRequestClose={onRequestClose}
          isEdit={isEdit}
          event={event}
          eventId={eventId}
          carerName={carerName}
          carerTimezone={carerTimezone}
          availableTimeSlots={availableTimeSlots}
          calendarEventsData={calendarEventsData}
          recurringEventsWeekdaysMap={recurringEventsWeekdaysMap}
          providerScheduleType={providerScheduleType}
        />
      );
    }
    return <></>;
  } else if (carerTimezone && selectedCarerId && availableTimeSlots) {
    return (
      <ProviderBlockTimeModalRender
        modalKey={modalKey}
        isVisible={isVisible}
        onRequestClose={onRequestClose}
        isEdit={isEdit}
        selectedCarerId={selectedCarerId}
        carerTimezone={carerTimezone}
        carerName={carerName}
        availableTimeSlots={availableTimeSlots}
        calendarEventsData={calendarEventsData}
        recurringEventsWeekdaysMap={recurringEventsWeekdaysMap}
        providerScheduleType={providerScheduleType}
      />
    );
  } else {
    if (!carerTimezone && !selectedCarerId && availableTimeSlots) {
      return (
        <ProviderBlockTimeModalRender
          modalKey={modalKey}
          isVisible={isVisible}
          onRequestClose={onRequestClose}
          isEdit={isEdit}
          carerName={carerName}
          availableTimeSlots={availableTimeSlots}
          calendarEventsData={calendarEventsData}
          recurringEventsWeekdaysMap={recurringEventsWeekdaysMap}
          providerScheduleType={providerScheduleType}
        />
      );
    }

    return <></>;
  }
};

export default ProviderBlockTimeModal;
