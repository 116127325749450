import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import {
  useLazyGetReadingCompliancePeriodsByMemberQuery,
  useLazyGetReadingDaysByMemberQuery
} from "../services/ReadingsService";

interface ReadingsDataType {
  readingDays: number;
  daysRemaining: number;
  totalReadings: number;
}

const useGetPeriodReadings = (memberId: string, timezone: string) => {
  const [getReadingCompliancePeriods] =
    useLazyGetReadingCompliancePeriodsByMemberQuery();
  const [getReadingDays] = useLazyGetReadingDaysByMemberQuery();

  const [readingsData, setReadingsData] = useState<ReadingsDataType>();
  const [error, setError] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>();

  const loadData = async () => {
    const { data: periodReadings, error } = await getReadingCompliancePeriods({
      memberId,
      limit: 2
    });

    if (error) {
      setError(error);
      setLoading(false);
      return;
    }

    if (periodReadings === undefined || periodReadings.length === 0) {
      setReadingsData({
        readingDays: 0,
        daysRemaining: 0,
        totalReadings: 16
      });
      setError("No compliance period found");
      setLoading(false);
      return;
    }

    const thisPeriod = periodReadings[0];
    const lastPeriod = periodReadings[1];

    let daysRemaining = getDaysRemaining(thisPeriod, timezone);
    let { start_date, end_date } = thisPeriod;
    if (daysRemaining === -1 && lastPeriod) {
      // use last compliance period if present
      start_date = lastPeriod.start_date;
      end_date = lastPeriod.end_date;
      daysRemaining = getDaysRemaining(lastPeriod, timezone);
    }

    const start = DateTime.fromFormat(start_date!, "yyyy-MM-dd");
    const startDate = getLocalDate(start, timezone);
    const end = DateTime.fromFormat(end_date!, "yyyy-MM-dd");
    const endDate = getLocalEndDate(end, timezone);
    const startDateSeconds = Math.round(startDate.toSeconds());
    const endDateSeconds = Math.round(endDate.toSeconds());

    const { data: readingsResponse, error: readingsError } =
      await getReadingDays({
        memberId,
        paramsObject: {
          startdate: startDateSeconds,
          enddate: endDateSeconds
        }
      });
    if (error) {
      setError(readingsError);
      setLoading(false);
      return;
    }

    let reading_days = 0;
    if (readingsResponse && readingsResponse.reading_days) {
      reading_days = readingsResponse.reading_days;
    }

    setReadingsData({
      readingDays: reading_days,
      daysRemaining,
      totalReadings: 16
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    loadData();
  }, [memberId, timezone]);

  return { data: readingsData, error, isLoading };
};

function getLocalDate(start: DateTime, timezone: string) {
  return start.setZone(timezone).startOf("day");
}

function getLocalEndDate(end: DateTime, timezone: string) {
  return end.setZone(timezone).endOf("day");
}

function getDaysRemaining(compliancePeriod: any, timezone: string) {
  let daysRemaining;
  const { start_date, end_date } = compliancePeriod;
  const start = DateTime.fromFormat(start_date!, "yyyy-MM-dd");
  const startDate = getLocalDate(start, timezone);

  const end = DateTime.fromFormat(end_date!, "yyyy-MM-dd");
  const endDate = getLocalEndDate(end, timezone);

  const today = DateTime.now().startOf("day");
  const todayInUTC = getLocalDate(today, timezone);

  if (todayInUTC > endDate || todayInUTC < startDate) {
    //we are outside of the compliance period
    daysRemaining = -1;
  } else {
    daysRemaining = Math.floor(endDate.diff(todayInUTC, "days").days);
  }
  return daysRemaining;
}

export default useGetPeriodReadings;
