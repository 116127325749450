import { useMemo, MouseEvent } from "react";
import { IconButton, SxProps, Theme, useTheme } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { LightenDarkenColor } from "lighten-darken-color";

interface IProps {
  color?: string;
  testId?: string;
  iconColor?: string;
  Icon: SvgIconComponent;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  disabledWithNoOpacity?: boolean;
  disableHoverColor?: boolean;
  border?: "circle" | "square";
  sx?: SxProps<Theme>;
  size?: "small" | "medium" | "large";
}

const StyledIconButton = ({
  color = "transparent",
  iconColor = "white",
  onClick,
  testId,
  Icon,
  disabled,
  disabledWithNoOpacity,
  disableHoverColor,
  border,
  sx = {},
  size
}: IProps) => {
  const theme = useTheme();

  const hoverColor = useMemo(
    () => (!disableHoverColor ? LightenDarkenColor(color, -40) : color),
    [color]
  );

  const borderStyles = useMemo(() => {
    if (border === undefined) return {};
    else if (border === "circle")
      return {
        border: 1,
        borderColor: iconColor,
        borderRadius: "50%"
      };
    else if (border === "square")
      return {
        border: 1,
        borderColor: iconColor,
        borderRadius: 2
      };
  }, [border, iconColor]);

  const disabledBackgroundColor = useMemo(() => {
    if (color === "transparent") return theme.palette.grey[300];
    return disabledWithNoOpacity ? color : color + "88";
  }, [color]);

  return (
    <IconButton
      sx={{
        ...borderStyles,
        "&:disabled": {
          backgroundColor: disabledBackgroundColor
        },
        "&:hover": { backgroundColor: hoverColor, pointer: "cursor" },
        backgroundColor: color,
        ...sx
      }}
      data-testid={testId}
      onClick={onClick}
      disabled={disabled || disabledWithNoOpacity}
      size={size}
    >
      <Icon sx={{ color: iconColor }} fontSize={size} />
    </IconButton>
  );
};

export default StyledIconButton;
