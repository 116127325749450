import { FormatterInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import luxon3Plugin from "@fullcalendar/luxon3";
import timeGridPlugin from "@fullcalendar/timegrid";

const viewClassNames = ["schedule-today"];
const plugins = [dayGridPlugin, timeGridPlugin, luxon3Plugin];

const dayHeaderFormat: FormatterInput = {
  weekday: "short",
  month: "numeric",
  day: "numeric",
  omitCommas: true
};

const slotDuration = {
  hours: 0.125
};

const slotLabelInterval = { hours: 0.5 };

const slotLabelFormat: FormatterInput = {
  hour: "numeric",
  minute: "2-digit",
  omitZeroMinute: false,
  meridiem: "short"
};

export {
  viewClassNames,
  plugins,
  dayHeaderFormat,
  slotDuration,
  slotLabelInterval,
  slotLabelFormat
};
