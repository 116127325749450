import { Box, styled } from "@mui/material";

const PreferencesForm = styled("form")`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const BorderedCheckboxContainer = styled("div")``;

const FirstColumn = styled("div")`
  padding: 0 12px;
`;

const SecondColumn = styled(Box)`
  width: 116px;
  text-align: center;
`;

const RowContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;

export {
  PreferencesForm,
  BorderedCheckboxContainer,
  FirstColumn,
  SecondColumn,
  RowContainer
};
