import {
  Box,
  Card,
  CircularProgress,
  MenuItem,
  styled,
  TextField
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import ArcOfDaySidebar from "./ui/ArcOfDaySidebar";
import ErrorComponent from "../../components/ErrorComponent";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";
import {
  ActionOutcome,
  ActionType,
  TaskStatus
} from "common/graphql/generated";
import { useGetTasksQuery } from "common/services/GraphQLPABService";
import AddTaskForm from "./ui/AddTaskForm";
import { useFormik } from "formik";
import { useMemo } from "react";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import { isFalsy, prettyStatusGraphQLString } from "common/helpers/helpers";
import { DateTime } from "luxon";
import { useUpdateTaskStatusMutation } from "common/services/TaskingService";
import { LoadingButton } from "@mui/lab";

const Container = styled(Box)`
  margin: 2.5%;
`;
const Row = styled(Box)`
  display: flex;
  gap: 10px;
`;

const Content = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
`;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
`;

interface EditTaskFormType {
  action_notes: string;
  action_outcome: ActionOutcome;
  action_type: ActionType;
}

const ArcOfDayDetails = () => {
  const { taskId } = useSanitizedParams();

  const navigate = useNavigate();

  const { data: authenticatedUser } = useGetAuthenticatedUser();
  const {
    data: todoTasks,
    isLoading: todoLoading,
    error: errorQueued
  } = useGetTasksQuery(
    {
      pageNumber: 0,
      pageSize: 25,
      staffId: authenticatedUser?.user?.user_id,
      status: TaskStatus.Queued
    },
    { skip: authenticatedUser === undefined }
  );
  const {
    data: completedTasks,
    isLoading: completedLoading,
    error: errorCompleted
  } = useGetTasksQuery(
    {
      pageNumber: 0,
      pageSize: 25,
      staffId: authenticatedUser?.user?.user_id,
      status: TaskStatus.Completed
    },
    { skip: authenticatedUser === undefined }
  );

  const [
    updateTaskStatusMutation,
    { isLoading: updateTaskLoading, error: updateTaskError }
  ] = useUpdateTaskStatusMutation();

  const isLoading = todoLoading || completedLoading;
  const isSuccess = completedTasks && todoTasks;
  const error = errorQueued || errorCompleted;

  const validate = (values) => {
    let errors = {};
    if (values.action_notes === "") {
      errors["action_notes"] = "Required Field";
    }
    if (values.action_outcome === null) {
      errors["action_outcome"] = "Required Field";
    }
    if (values.action_type === null) {
      errors["action_type"] = "Required Field";
    }
    return errors;
  };

  const task = useMemo(() => {
    if (isFalsy(todoTasks) || isFalsy(completedTasks)) return null;
    return (
      todoTasks?.tasks?.items?.find((item) => item.id === taskId) ||
      completedTasks?.tasks?.items?.find((item) => item.id === taskId)
    );
  }, [todoTasks, completedTasks]);

  const onSubmit = async (values: EditTaskFormType) => {
    await updateTaskStatusMutation({
      task_id: task?.id,
      status: TaskStatus.Completed,
      body: [
        {
          notes: values.action_notes,
          outcome: values.action_outcome,
          type: values.action_type
        }
      ]
    });
    navigate(-1);
  };

  const { setFieldValue, values, handleSubmit, isValid, dirty } =
    useFormik<EditTaskFormType>({
      validate,
      initialValues: {
        action_notes: "",
        action_outcome: null,
        action_type: null
      },
      onSubmit
    });

  const formValues = useMemo(() => {
    if (isFalsy(task)) return null;
    return {
      member_id: task.patientId,
      task_category: task.taskCategory,
      assigned_user_id: task.assigneeIds.length > 0 && task.assigneeIds[0],
      due_date: DateTime.fromISO(task.dueDate),
      priority_score: task.priorityScore,
      notes: task.notes
    };
  }, [task]);

  return (
    <Container>
      {isLoading && <CircularProgress />}
      <ErrorComponent error={error} />
      {isSuccess && (
        <Row>
          <ArcOfDaySidebar
            todoTaskCount={todoTasks?.tasks?.totalResults}
            completedTaskCount={completedTasks?.tasks?.totalResults}
          />

          {formValues && (
            <Content>
              <AddTaskForm
                setFieldValue={setFieldValue}
                values={formValues}
                details
              />

              <br />
              <Grid>
                <TextField
                  label="Action Outcome"
                  value={values.action_outcome ?? ""}
                  select
                  onChange={(event) => {
                    setFieldValue("action_outcome", event.target.value);
                  }}
                  disabled={task.status !== TaskStatus.Queued}
                >
                  {Object.keys(ActionOutcome).map((key) => (
                    <MenuItem key={key} value={ActionOutcome[key]}>
                      {prettyStatusGraphQLString(key)}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Action Type"
                  value={values.action_type ?? ""}
                  select
                  onChange={(event) => {
                    setFieldValue("action_type", event.target.value);
                  }}
                  disabled={task.status !== TaskStatus.Queued}
                >
                  {Object.keys(ActionType).map((key) => (
                    <MenuItem key={key} value={ActionType[key]}>
                      {prettyStatusGraphQLString(key)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <br />
              <TextField
                label="Action Taken"
                multiline
                value={values.action_notes ?? ""}
                onChange={(event) => {
                  setFieldValue("action_notes", event.target.value);
                }}
                disabled={task.status !== TaskStatus.Queued}
              />

              <br />
              <LoadingButton
                disabled={!isValid || !dirty}
                loading={updateTaskLoading}
                variant="contained"
                sx={{ alignSelf: "flex-start" }}
                onClick={() => handleSubmit()}
              >
                Mark Completed
              </LoadingButton>

              <ErrorComponent error={updateTaskError} />
            </Content>
          )}
        </Row>
      )}
    </Container>
  );
};

export default ArcOfDayDetails;
