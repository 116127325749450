import { apiPAB } from "./AxiosService";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import { RelationshipNoteType } from "../types/RelationshipNoteType";
import { GoalAssessmentType, GoalType } from "../types/GoalType";

const RELATIONSHIP_NOTES = "relationship-notes";
const GOALS = "goals";

const memberRelationshipNotesService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Member, ReduxTagEnum.RelationshipNotes]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      //Mutations
      upsertRelationshipNote: builder.mutation<any, RelationshipNoteType>({
        query: (body) => ({
          url: `/${RELATIONSHIP_NOTES}/${body.patient_id}`,
          data: body,
          method: "PUT"
        }),
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Member, id: arg.patient_id },
          { type: ReduxTagEnum.RelationshipNotes }
        ]
      }),
      deleteRelationshipNote: builder.mutation<
        any,
        { relationship_note_id: string }
      >({
        query: ({ relationship_note_id }) => ({
          url: `/${RELATIONSHIP_NOTES}/${relationship_note_id}`,
          method: "DELETE"
        }),
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Member },
          { type: ReduxTagEnum.RelationshipNotes }
        ]
      })
    })
  });

const memberGoalsService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Member, ReduxTagEnum.Goals]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getGoals: builder.query<GoalType[], { member_id: string }>({
        query: ({ member_id }) => {
          return { url: `/${GOALS}/${member_id}`, method: "GET" };
        },
        providesTags: () => [{ type: ReduxTagEnum.Goals }]
      }),

      //Mutations
      upsertGoals: builder.mutation<
        any,
        {
          goals: Partial<GoalType>[];
          member_id: string;
        }
      >({
        query: ({ goals, member_id }) => ({
          url: `/${GOALS}/${member_id}`,
          data: goals,
          method: "POST"
        }),
        invalidatesTags: (result) =>
          result ? [{ type: ReduxTagEnum.Goals }] : []
      }),
      upsertGoalAssessments: builder.mutation<
        any,
        {
          goalAssessments: Partial<GoalAssessmentType>[];
          member_id: string;
        }
      >({
        query: ({ goalAssessments, member_id }) => ({
          url: `/${GOALS}/${member_id}/assessments`,
          data: goalAssessments,
          method: "POST"
        }),
        invalidatesTags: (result) =>
          result ? [{ type: ReduxTagEnum.Goals }] : []
      })
    })
  });

export const {
  useUpsertRelationshipNoteMutation,
  useDeleteRelationshipNoteMutation
} = memberRelationshipNotesService;

export const {
  useGetGoalsQuery,
  useUpsertGoalsMutation,
  useUpsertGoalAssessmentsMutation
} = memberGoalsService;
