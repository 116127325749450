import { Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import { Flexbox } from "../../../styling/NewStyleComponents";
import StartCareFlow from "../../../components/ScheduleToday/StartCareFlow";
import { canSeeCareFlow } from "common/enums/RolesEnum";
import { isFalsy } from "common/helpers/helpers";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";

interface IProps {
  upcomingAppointments: any;
  staffTimezone?: string;
  memberId: string;
}

const Appointment = ({
  visitRequest,
  primaryText,
  secondaryText,
  visit = null
}: {
  visitRequest: any;
  primaryText: string;
  secondaryText: string;
  visit?: GetVisitResponseType;
}) => {
  let confirmMessage = "";
  if (!isFalsy(visit)) {
    const createdAt = DateTime.fromISO(visit.created_date).toFormat("h:mm a ZZZZ")
    const info = `${visit.staff?.first} ${visit.staff?.last} at ${createdAt}`
    secondaryText += ` (${info})`
    confirmMessage += `A visit for this appointment was already started by ${info}. Do you want still want to start a new visit?`
  }
  return (
    <Flexbox
      flexDirection={"column"}
      sx={{
        // maxWidth: "400px",
        backgroundColor: "#f1f9ff",
        padding: "10px",
        border: 1,
        borderColor: "#4c97ca",
        borderRadius: "4px",
        marginBottom: "10px"
      }}
    >
      <Flexbox
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignContent={"center"}
      >
        <Flexbox flexDirection={"column"}>
          <Typography variant="body1" fontWeight="700" color="primary.main">
            {primaryText}
          </Typography>
          <Typography variant="body1" color="primary.main">
            {secondaryText}
          </Typography>
        </Flexbox>
        
        <StartCareFlow visitsRequest={visitRequest} confirmMessage={confirmMessage} />
      </Flexbox>
    </Flexbox>
  );
};

const TodaysAppointments = ({
  upcomingAppointments,
  staffTimezone,
  memberId
}: IProps) => {
  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  if (!canSeeCareFlow(currentRole))
    return;

  return (
    <>
      <Typography variant="h4" color="text.secondary" mb="12px">
        Today's Appointments
      </Typography>
      {isFalsy(upcomingAppointments) && (
        <>
          <Typography variant="h6" color="text.secondary" mb="12px">
            The member has no appointment scheduled for today. Do you want to
            start an on-demand appointment?
          </Typography>
          <Appointment
            visitRequest={{
              staff_id: user?.user_id,
              patient_id: memberId,
              motivation_reason: VisitMotivationTypesEnum.PATIENT_INITIATED
            }}
            primaryText={`${user?.first} ${user?.last}`}
            secondaryText={`On-Demand`}
          />
        </>
      )}
      {upcomingAppointments?.map((item) => {
        const timezone = staffTimezone ?? item.attendees[0]?.timezone;

        const startDate = DateTime.fromISO(item.startdate).setZone(timezone);
        const endDate = DateTime.fromISO(item.enddate).setZone(timezone);

        return (
          <Appointment
            key={item?.calendar_id}
            visitRequest={{
              calendar_event_start: startDate,
              calendar_event_end: endDate,
              staff_id: item.staff.id,
              patient_id: item.attendees[0].attendee_id,
              calendar_id: item.event_id,
              motivation_reason: VisitMotivationTypesEnum.APPOINTMENT,
            }}
            primaryText={`${item.staff.first} ${item.staff.last}`}
            secondaryText={`${startDate.toFormat("h:mm")} - ${endDate.toFormat("h:mm a ZZZZ")}`}
            visit={item.visits?.length > 0 ? item.visits[0] : null}
          />
        );
      })}
    </>
  );
};

export default TodaysAppointments;
