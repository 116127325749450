import { Fragment, Ref, forwardRef, useImperativeHandle } from "react";

import { DateTime } from "luxon";

import ReportRefInterface from "./ReportRefInterface";
import Table from "../../../components/Table/Table";
import { CircularProgress } from "@mui/material";
import { ErrorText } from "../../../styling";
import ErrorComponent from "../../../components/ErrorComponent";
import { useGetTwilioInboundSMSNotificationsQuery } from "common/services/MessagingService";
import ReportEnum from "../../../enums/ReportEnum";
import { DefaultTableCell } from "../../../styling/StyleComponents";
import { getTextWidth } from "../../../helpers/helpers";
import { sanitizeUserTextInput } from "common/helpers/helpers";

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  id: ReportEnum;
  csvFilename: string;
}

const tableColumns = [
  {
    name: "sms_report_member_link",
    id: "sms_report_member_link",
    accessor: "patient_id",
    size: 150,
    header: "Member ID"
  },
  {
    name: "date",
    id: "date",
    accessor: "timestamp",
    size: 150,
    header: "Date"
  },
  {
    name: "default",
    id: "message_type",
    accessor: "messaging_service_name",
    size: 150,
    header: "Automated Message Type",
    cell: ({ getValue }: { getValue: () => string }) => {
      const value = getValue();
      return <DefaultTableCell>{mapTypeFn(value)}</DefaultTableCell>;
    }
  },
  {
    name: "default",
    id: "sms_content",
    accessor: "body",
    size: 150,
    header: "SMS",
    cell: ({ getValue }: { getValue: () => string }) => {
      const value = getValue();
      return <DefaultTableCell>{value}</DefaultTableCell>;
    }
  }
];

const formatDate = (dateString: string) => {
  let date = DateTime.fromSQL(dateString);
  if (date?.isValid === false) date = DateTime.fromISO(dateString);
  return date.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
};

const mapTypeFn = (type: string) => {
  switch (type) {
    case "SMS_MSG_SVC_ACCOUNT_NOTIFICATION":
      return "Account Notification";
    case "SMS_MSG_SVC_CONNECT_TO_SALESFORCE":
      return "Sales";
    case "SMS_MSG_SVC_CUSTOMER_CARE":
      return "Customer Care";
    case "SMS_MSG_SVC_DELIVERY_NOTIFICATION":
      return "Delivery Notification";
    case "SMS_MSG_SVC_SEC_ALERT":
      return "Security Alert";
    case "SMS_MSG_SVC_TWO_FACTOR_AUTH":
      return "Two Factor Auth";
    case "SMS_MSG_SVC_VIDEO_LINKS":
      return "Video Link";
    case "SMS_MSG_SVC_APPT_REMINDERS":
      return "Appointment Reminder";
    case "SMS_MSG_SVC_MEMBER_SURVEYS":
      return "Member Survey";
  }
};

const ReportSMSReplies = forwardRef(
  (
    { startDate, endDate, id, csvFilename }: IProps,
    ref: Ref<ReportRefInterface>
  ) => {
    const { data, isLoading, isSuccess, isError, error } =
      useGetTwilioInboundSMSNotificationsQuery({
        startDate,
        endDate
      });

    useImperativeHandle(ref, () => ({
      getCSVReportData() {
        return {
          filename: csvFilename,
          columns: ["Member ID", "Date", "Automated Message Type", "SMS"],
          data: data.map(
            ({ patient_id, timestamp, messaging_service_name, body }) => [
              patient_id,
              formatDate(timestamp),
              mapTypeFn(messaging_service_name),
              sanitizeUserTextInput(body)
            ]
          )
        };
      },
      getReportData: () => {
        return [
          {
            title: "Member Responses to Automated Messages",
            columnNames: ["Member ID", "Date", "Automated Message Type", "SMS"],
            data: data.map(
              ({ patient_id, timestamp, messaging_service_name, body }) => {
                return [
                  patient_id,
                  formatDate(timestamp),
                  mapTypeFn(messaging_service_name),
                  sanitizeUserTextInput(body)
                ];
              }
            ),
            reportSummary: []
          }
        ];
      }
    }));

    return (
      <Fragment key={id}>
        {data && data.length > 0 && (
          <Table
            tableColumns={tableColumns}
            data={data}
            tableMaxHeight={"none"}
            estimateRowSize={(index: number) => {
              const item = data[index];

              const textLines = getTextWidth(item.body, undefined) / 168;
              return Math.max(textLines * 20, 20);
            }}
          />
        )}
        {isLoading && <CircularProgress />}
        {isSuccess && data && data.length === 0 && (
          <ErrorText>No data found.</ErrorText>
        )}
        {isError && <ErrorComponent error={error} />}
      </Fragment>
    );
  }
);

ReportSMSReplies.displayName = "ReportSMSReplies";

export default ReportSMSReplies;
