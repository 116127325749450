import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
  ChangeEvent
} from "react";
import { useFormik } from "formik";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress, TextField } from "@mui/material";
import { getAddress, getNameOrUsername } from "common/helpers/helpers";
import { useCreateOrderMutation } from "common/services/OrdersService";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import LocalizedStrings from "common/localizations/LocalizedStrings";
import OrderTypeEnum from "common/enums/OrderTypeEnum";
import OrderStatusEnum from "common/enums/OrderStatusEnum";

import {
  getDeviceInfoBySku,
  getSkuItems,
  isPOBoxAddress
} from "../../helpers/helpers";
import RenderLastNOrders from "../../helpers/components/RenderLastNOrders";
import useGetOrderDevices from "../../hooks/data_loaders/useGetOrderDevices";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { ErrorTextSpan, TurqoiseButton } from "../../styling";
import { ComponentHeader, SuccessText } from "../../styling/StyleComponents";

import glucoseMeter from "../../assets/images/glucose_meter.png";
import bloodPressureCuff from "../../assets/images/blood_pressure_cuff.png";

import ErrorComponent from "../../components/ErrorComponent";
import { RootState, useAppDispatch } from "common/redux";

import { FormCheckbox } from "../../helpers/components/Forms/FormHelpers";
import { EditAddressModal } from "../../helpers/components/Forms/EditAddressModal/EditAddressModal";
import AddressSourceEnum from "common/enums/AddressSourceEnum";
import { PencilIcon } from "../../assets/images/icons";
import { useValidateAddressMutation } from "common/services/DevicesService";
import { ValidateAddressModal } from "../../helpers/components/Forms/ValidateAddressModal/ValidateAddressModal";
import AddressValidationStatusEnum from "common/enums/AddressValidationStatusEnum";
import { canOrderBloodPressureOrGlucose } from "common/enums/RolesEnum";
import { useSelector } from "react-redux";
import { MEMBERS_PATH } from "../../routes/RoutePaths";
import replace from "lodash.replace";
import OrderType from "common/types/OrderType";
import {
  showProviderCreatedOrderWarning,
  getDeviceSkusFromSelectedDevices
} from "./helpers";

const OrderDeviceFormContainer = styled.div<{
  children: ReactNode | ReactNode[];
}>``;

const SuccessContainer = styled.div`
  transition: height 0.66s ease-out;
  min-height: 28px;
`;

const SubmitButton = styled(TurqoiseButton)`
  width: 250px;
`;

const StyledTextField = styled(TextField)`
  width: 500px;
`;

const DeviceCheckboxOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

const OrderDevicesImageContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  text-align: center;
`;

const OrderDevicesImage = styled.img`
  height: 100%;
`;

const Row = styled.div`
  display: flex;
`;

interface FormType {
  orderDevicesCheckboxes: string[];
  stripsOnly: boolean;
}
interface IProps {
  memberId: string;
  setAddressChanged?: Dispatch<SetStateAction<boolean>>;
  addressChanged?: boolean;
  setAddressSource?: Dispatch<SetStateAction<AddressSourceEnum>>;
  addressSource?: AddressSourceEnum;
}

interface DeviceCheckboxProps {
  onChange: (checked: boolean) => void;
  checked: boolean;
  label: string;
  alt: string;
  image: string;
  disabled?: boolean;
  tooltip?: string;
  children?: ReactNode | ReactNode[];
}

const DeviceCheckbox = ({
  onChange,
  disabled,
  checked,
  label,
  alt,
  image,
  tooltip,
  children
}: DeviceCheckboxProps) => {
  return (
    <DeviceCheckboxOptionContainer>
      <OrderDevicesImageContainer>
        <OrderDevicesImage src={image} alt={alt} />
      </OrderDevicesImageContainer>
      <FormCheckbox
        label={label}
        checked={checked}
        disabled={disabled}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange(event.target.checked)
        }
        tooltip={tooltip}
      />
      {children}
    </DeviceCheckboxOptionContainer>
  );
};

const OrderDeviceForm = ({
  memberId,
  setAddressChanged,
  addressChanged,
  addressSource,
  setAddressSource
}: IProps) => {
  const [editAddressModalOpen, setEditAddressModalOpen] =
    useState<boolean>(false);
  const [validateAddressModalOpen, setValidateAddressModalOpen] =
    useState<boolean>(false);
  const [createOrderRequest, setCreateOrderRequest] = useState(null);
  const timerRef = useRef(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const { data, isLoading, isFetching } = useGetOrderDevices(memberId);
  const { patient, devicesOrdered, athena, addresses, orders } = data;
  const { addressesEqual, pabPatientAddress, athenaPatientAddress } = addresses;

  const [
    createOrderMutation,
    {
      isSuccess: createOrderIsSuccess,
      error: createOrderError,
      isLoading: createOrderIsLoading,
      reset: resetCreateOrder
    }
  ] = useCreateOrderMutation();

  const [
    validateAddressMutation,
    {
      data: validateAddressData,
      isSuccess: validateAddressIsSuccess,
      error: validateAddressError,
      isLoading: validateAddressIsLoading,
      reset: resetValidateAddress
    }
  ] = useValidateAddressMutation();

  const [confirmOrder, setConfirmOrder] = useState<boolean>(false);

  const patientName = getNameOrUsername(patient?.patient);

  const [hasDeviceOrderDelivered, setHasDeviceOrderDelivered] =
    useState<boolean>(false);
  // if patient has any device orders, show a confirmation modal
  const hasDeviceOrders = useMemo(() => {
    if (devicesOrdered && devicesOrdered?.length > 0) {
      const filteredOrders = devicesOrdered.filter((order) => {
        const items = order?.order?.items;

        const filteredItems = items?.filter((item) => {
          const deviceInfo = getDeviceInfoBySku(item?.sku);
          const { sku_type, stockKeepingUnit } = deviceInfo || {};
          return (
            sku_type === "DEVICE" &&
            // we don't want to include weight scale orders in this delivered device logic
            stockKeepingUnit !== "EAN-13"
          );
        });

        if (filteredItems?.length > 0) {
          const sm_status = order?.order?.status?.sm_status;
          if (sm_status === (OrderStatusEnum.delivered as string)) {
            setHasDeviceOrderDelivered(true);
          }
        }

        return filteredItems?.length > 0;
      });

      if (filteredOrders?.length > 0) {
        return true;
      }
    }
    return false;
  }, [devicesOrdered]);

  useEffect(() => {
    if (createOrderIsSuccess && hasDeviceOrders === false) {
      const link = replace(
        MEMBERS_PATH,
        ":memberId",
        patient.patient.patient_id
      );
      Alert_show({
        dispatch,
        title: "Success! Order Created",
        id: "orderCreatedSuccess",
        content: (
          <>
            Your device order for {getNameOrUsername(patient.patient)} was
            successfully created! <br />
            Please proceed to <b>enter your time spent</b>
          </>
        ),
        type: "success",
        size: "small",
        buttons: [
          {
            text: "Done",
            style: "cancel",
            onPress: () => Alert_close({ dispatch, id: "orderCreatedSuccess" })
          },
          {
            text: "Enter time",
            style: "default",
            onPress: () => {
              navigate(link);
              Alert_close({ dispatch, id: "orderCreatedSuccess" });
            }
          }
        ]
      });
    }
  }, [createOrderIsSuccess, hasDeviceOrders]);

  const processSubmit = async (
    // if pabPatientAddress doesn't exist, submit with athena address
    addressSource: AddressSourceEnum = AddressSourceEnum.ATHENA
  ) => {
    const skuItems = getSkuItems({
      selectedItemsArray: values.orderDevicesCheckboxes,
      orderType: OrderTypeEnum.DEVICE,
      stripsOnly: values?.stripsOnly,
      hasDeviceOrderDelivered
    });

    const { patient_id } = patient.patient;
    const address =
      addressSource === AddressSourceEnum.ATHENA
        ? athenaPatientAddress
        : pabPatientAddress;

    const createOrderRequest: OrderType = {
      patient_id,
      address,
      order_type: OrderTypeEnum.DEVICE,
      ordered_by: user?.user_id,
      items: skuItems
    };

    setCreateOrderRequest(createOrderRequest);
    await validateAddressMutation({ address });
  };

  useEffect(() => {
    if (
      validateAddressIsSuccess &&
      validateAddressData &&
      createOrderRequest &&
      !createOrderIsSuccess &&
      !createOrderIsLoading &&
      !createOrderError
    ) {
      if (
        !validateAddressData?.addressesEqual ||
        validateAddressData?.status !== AddressValidationStatusEnum.VALID
      ) {
        setValidateAddressModalOpen(true);
      } else {
        createOrderMutation(createOrderRequest);
      }
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [
    validateAddressIsSuccess,
    validateAddressData,
    createOrderRequest,
    createOrderIsSuccess,
    createOrderIsLoading,
    createOrderError
  ]);

  useEffect(() => {
    if (validateAddressError) {
      Alert_show({
        dispatch,
        id: "validateAddressError",
        title: "Error",
        content: <ErrorComponent error={validateAddressError} />,
        type: "warning",
        size: "small"
      });
    }
  }, [validateAddressError]);

  function reset() {
    resetValidateAddress();
    resetCreateOrder();
  }

  const onSubmit = async () => {
    if (patient === undefined || athena === undefined) return;

    reset();

    const selectedDeviceSkus = getDeviceSkusFromSelectedDevices(
      values.orderDevicesCheckboxes
    );

    if (
      showProviderCreatedOrderWarning(currentRole, orders, selectedDeviceSkus)
    ) {
      Alert_show({
        dispatch,
        id: "noDeviceOrder",
        title: "Error",
        content: (
          <>
            You are not able to create this device order because a provider has
            not previously ordered this device(s) in remoteIQ. Please contact
            the assigned provider to have them create the order.
          </>
        ),
        type: "warning",
        size: "small"
      });
      return;
    }

    // if address has already been changed, then we don't need to check if it's equal
    // this is because there is a race condition with the athena endpoint
    // when changing address, the athena endpoint is not updated

    // if pabPatientAddress exists and addresses aren't equal, show Member Address Sync modal
    if (!addressesEqual && !addressChanged && !!pabPatientAddress) {
      setEditAddressModalOpen(true);
      return;
    }

    if (hasDeviceOrders && !confirmOrder) {
      Alert_show({
        dispatch,
        id: "confirmDeviceOrders",
        title: "Confirm Order",
        content: (
          <>
            Member has devices previously ordered:
            {devicesOrdered && (
              <RenderLastNOrders
                orders={devicesOrdered}
                deviceType="DEVICE"
                n={2}
              />
            )}
            Are you sure you want to proceed?
          </>
        ),
        type: "warning",
        buttons: [
          {
            text: LocalizedStrings.cancel,
            style: "cancel",
            onPress: () => {
              Alert_close({ dispatch, id: "confirmDeviceOrders" });
            }
          },
          {
            text: LocalizedStrings.submit,
            style: "default",
            onPress: async () => {
              setConfirmOrder(true);
              Alert_close({ dispatch, id: "confirmDeviceOrders" });
              processSubmit(addressSource);
            }
          }
        ]
      });
    } else {
      timerRef.current = setTimeout(() => {
        processSubmit(addressSource);
      }, 50);
    }
  };

  const formik = useFormik<FormType>({
    initialValues: {
      orderDevicesCheckboxes: [],
      stripsOnly: false
    },
    onSubmit,
    enableReinitialize: true
  });

  const { handleSubmit, values, setFieldValue } = formik;

  const onChange = (checked: boolean, key: string) => {
    let array = [...values.orderDevicesCheckboxes];
    if (checked) {
      array.push(key);
    } else {
      array = array.filter((item) => item !== key);
    }
    setFieldValue("orderDevicesCheckboxes", array);
  };

  const createOrder = useCallback(
    (request) => {
      createOrderMutation(request);
    },
    [createOrderMutation]
  );

  return (
    <OrderDeviceFormContainer>
      {isLoading ? (
        <LoadingFallback delay={500} count={10} />
      ) : (
        <>
          {patient && athena && (
            <form onSubmit={handleSubmit}>
              {patient === null ? (
                <LoadingFallback delay={500} count={10} />
              ) : (
                <>
                  <br />

                  <StyledTextField
                    value={patientName}
                    label="Member Name"
                    disabled
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                  <br />
                  <br />
                  <StyledTextField
                    value={
                      pabPatientAddress
                        ? getAddress(pabPatientAddress)
                        : getAddress(athenaPatientAddress)
                    }
                    label="Member Address"
                    disabled
                    slotProps={{
                      inputLabel: { shrink: true },
                      input: {
                        endAdornment: (
                          <>
                            {addressChanged && isFetching && (
                              <CircularProgress size={24} />
                            )}
                            {/* Disable edit address once address has been changed once - this is to handle the Athena endpoint race condition */}
                            {!addressChanged && (
                              <Button
                                sx={{
                                  ":hover": {
                                    bgcolor: "#D0D5DD"
                                  }
                                }}
                                onClick={() => setEditAddressModalOpen(true)}
                              >
                                <PencilIcon />
                              </Button>
                            )}
                          </>
                        )
                      }
                    }}
                  />
                  <br />
                  <br />
                </>
              )}
              {devicesOrdered && devicesOrdered?.length > 0 && (
                <RenderLastNOrders
                  orders={devicesOrdered}
                  deviceType="DEVICE"
                  n={2}
                  showRecentOrdersHeading
                  recentOrdersHeading="Recent Member Device Orders"
                />
              )}
              {canOrderBloodPressureOrGlucose(
                currentRole,
                patient?.patient?.migrated
              ) && (
                <>
                  <ComponentHeader>Devices available to order</ComponentHeader>
                  <br />

                  <Row>
                    {canOrderBloodPressureOrGlucose(
                      currentRole,
                      patient?.patient?.migrated
                    ) && (
                      <DeviceCheckbox
                        alt="blood pressure cuff"
                        label={"Blood Pressure Cuff"}
                        image={bloodPressureCuff}
                        onChange={(checked: boolean) =>
                          onChange(checked, "bloodPressureCuff")
                        }
                        checked={values.orderDevicesCheckboxes.includes(
                          "bloodPressureCuff"
                        )}
                      />
                    )}

                    {canOrderBloodPressureOrGlucose(
                      currentRole,
                      patient?.patient?.migrated
                    ) && (
                      <DeviceCheckbox
                        alt="Glucose Meter"
                        label={"Glucose Meter"}
                        image={glucoseMeter}
                        onChange={(checked: boolean) =>
                          onChange(checked, "glucoseMeter")
                        }
                        checked={values.orderDevicesCheckboxes.includes(
                          "glucoseMeter"
                        )}
                        tooltip={
                          "Includes 100 glucose monitor strips, 100 lancets, and control solution"
                        }
                      >
                        <FormCheckbox
                          tooltip={
                            "If selected, supplies will only contain strips and control solution"
                          }
                          label={"Strips Only (no lancets)"}
                          checked={values.stripsOnly}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue("stripsOnly", event.target.checked);
                          }}
                        />
                      </DeviceCheckbox>
                    )}
                  </Row>

                  <br />
                  {createOrderError !== undefined && (
                    <>
                      <br />
                      <ErrorTextSpan>
                        Something went wrong when creating your order.
                      </ErrorTextSpan>
                      <br />
                      <ErrorComponent
                        error={createOrderError}
                        showErrorResponseMessage
                        hideErrorCode
                      />
                      <br />
                    </>
                  )}

                  {isPOBoxAddress(addresses) && (
                    <ErrorComponent error="Warning: PO Box address. Please check if a home address is available" />
                  )}

                  <br />
                  <SubmitButton
                    // this type of "submit" will execute the <form> onsubmit function
                    type="submit"
                    loading={createOrderIsLoading || validateAddressIsLoading}
                    disabled={
                      values.orderDevicesCheckboxes.length === 0 ||
                      createOrderIsSuccess ||
                      createOrderIsLoading ||
                      !patient
                    }
                  >
                    Submit Order
                  </SubmitButton>
                  <SuccessContainer>
                    {createOrderIsSuccess && (
                      <SuccessText
                        margin={"12px 0 0 0"}
                      >{`Order submitted successfully.`}</SuccessText>
                    )}
                  </SuccessContainer>
                </>
              )}
            </form>
          )}
          {patient && !athena && (
            <ErrorComponent
              error={{
                message: "Error: Selected patient does not have Athena account."
              }}
            />
          )}
        </>
      )}

      <EditAddressModal
        key={`order-device-edit-address-modal${memberId}`}
        patientName={patientName}
        modalOpen={editAddressModalOpen}
        setModalOpen={setEditAddressModalOpen}
        selectedPatientId={memberId}
        addresses={addresses}
        setAddressChanged={setAddressChanged}
        setAddressSource={setAddressSource}
      />
      <ValidateAddressModal
        key={`order-device-validate-address-modal${memberId}`}
        modalOpen={validateAddressModalOpen}
        setModalOpen={setValidateAddressModalOpen}
        selectedPatientId={memberId}
        addresses={validateAddressData}
        makeOrder={createOrder}
        makeOrderRequest={createOrderRequest}
      />
    </OrderDeviceFormContainer>
  );
};

export default OrderDeviceForm;
