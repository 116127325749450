import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { PersonIcon } from "../../assets/images/icons";
import {
  ALL_MEMBERS,
  ASSIGNED_MEMBERS,
  AWAITING_PROVIDER,
  INACTIVE_MEMBERS,
  MEMBER_CHART,
  ORDER_DETAILS,
  MEMBERS_WITH_NO_ACTIVITY,
  REPORT_DETAILS,
  REPORT_DETAILS_BY_MEMBER,
  REPORT_LIST,
  UNASSIGNED_MEMBERS,
  MEMBER_CHART_CALENDARING,
  NURSE_SCHEDULE,
  NURSE_SCHEDULES,
  STAFF_DETAILS,
  START_INTAKE,
  COMMON_ROUTES,
  VISITS
} from "../RouteComponents";
import {
  SIDEBAR_ALL_MEMBERS,
  SIDEBAR_DASHBOARD,
  SIDEBAR_INACTIVE_MEMBERS,
  SIDEBAR_UNASSIGNED_MEMBERS,
  SIDEBAR_ORDERS,
  SIDEBAR_REPORTS,
  SIDEBAR_SUPPORT,
  SIDEBAR_SECTIONS
} from "../RouteSidebar";
import RolesEnum from "common/enums/RolesEnum";

import { MEMBERS_PATH } from "../RoutePaths";
import Intakes from "../../pages/Intakes/Intakes";
import OrderDevices from "../../pages/OrderDevices/OrderDevices";

const NP_NURSE = {
  routes: [
    COMMON_ROUTES,
    {
      path: "/",
      components: ALL_MEMBERS({
        tableColumns: [
          { name: "name" },
          { name: "status" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" },
          { name: "patientActions", size: 75 }
        ]
      })
    },
    {
      path: "/my-intakes",
      components: (
        <Intakes
          externalLink={MEMBERS_PATH}
          componentHeader={"My Intake History"}
          linked_entities={[MemberLinkedEntitiesEnum.NURSE]}
        />
      )
    },
    {
      path: "/members",
      components: ALL_MEMBERS({
        tableColumns: [
          { name: "name" },
          { name: "status" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" },
          { name: "patientActions", size: 75 }
        ]
      })
    },
    {
      path: "/awaiting-provider",
      components: AWAITING_PROVIDER({
        tableColumns: [
          { name: "name" },
          { name: "phone" },
          { name: "birthdate" },
          { name: "nurseAssigned" },
          { name: "patientActions", size: 75 }
        ]
      })
    },
    { path: "/inactive-members", components: INACTIVE_MEMBERS(false) },
    {
      path: "/unassigned-members",
      components: UNASSIGNED_MEMBERS
    },
    ...MEMBER_CHART(RolesEnum.NP_NURSE),
    ...MEMBER_CHART_CALENDARING,
    {
      path: "/members/nurseId/:nurseId",
      components: [MEMBERS_WITH_NO_ACTIVITY({}), ASSIGNED_MEMBERS({})]
    },
    {
      path: "/orders",
      components: <OrderDevices />
    },
    {
      path: "/devices/:memberId",
      components: <OrderDevices />
    },
    START_INTAKE,
    ORDER_DETAILS(),

    NURSE_SCHEDULES,
    NURSE_SCHEDULE,
    STAFF_DETAILS,
    REPORT_LIST,
    REPORT_DETAILS,
    REPORT_DETAILS_BY_MEMBER,
    VISITS
  ],
  sidebar: [
    SIDEBAR_DASHBOARD,
    {
      sectionName: SIDEBAR_SECTIONS.members,
      items: [
        {
          itemName: "My Intakes",
          itemLink: "/my-intakes",
          ItemIcon: PersonIcon
        },
        SIDEBAR_ALL_MEMBERS,
        SIDEBAR_UNASSIGNED_MEMBERS,
        SIDEBAR_INACTIVE_MEMBERS,

        SIDEBAR_ORDERS
      ]
    },
    {
      sectionName: SIDEBAR_SECTIONS.nurses,
      items: [
        {
          itemName: "Nurse Schedules",
          itemLink: "/nurse-schedules",
          ItemIcon: PersonIcon
        }
      ]
    },
    SIDEBAR_REPORTS,
    SIDEBAR_SUPPORT
  ]
};

export default NP_NURSE;
