enum DeviceStatusEnum {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  PENDING_DEACTIVATION = "PENDING_DEACTIVATION",
  DEFECTIVE = "DEFECTIVE",
  PATIENT_DAMAGED = "PATIENT_DAMAGED",
  PATIENT_LOST = "PATIENT_LOST",
  RETURNED = "RETURNED",
  DEACTIVATED = "DEACTIVATED",
  INACTIVE = "INACTIVE",
  SHIPPED = "SHIPPED",
  REACTIVATE = "REACTIVATE",
  VENDOR_REPLACEMENT = "VENDOR_REPLACEMENT"
}

export default DeviceStatusEnum;
