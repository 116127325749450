import {
  Box,
  Card,
  CircularProgress,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import DropdownDateRangePicker from "../../../components/DropdownDateRangePicker";

import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import { RowContainer } from "../../MemberDetails/StartIntake/StyledComponents";
import { useGetReadingsByMemberQuery } from "common/services/ReadingsService";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import { isFalsy } from "common/helpers/helpers";
import { useCollapse } from "react-collapsed";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { SidebarContainerStyle } from "../VisitSections";

enum TabPanelIndex {
  glucose = 0,
  bloodPressure = 1
}

interface IProps {
  memberId: string;
}

const RecentReadings = ({ memberId }: IProps) => {
  const [startDate, setStartDate] = useState<DateTime>();
  const [endDate, setEndDate] = useState<DateTime>();
  const [tabIndex, setTabIndex] = useState<number>(TabPanelIndex.glucose);

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: true
  });

  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const deviceType = useMemo(() => {
    switch (tabIndex) {
      case TabPanelIndex.glucose:
        return DeviceTypeEnum.GLUCOSE_CATEGORY;
      case TabPanelIndex.bloodPressure:
        return DeviceTypeEnum.BLOOD_PRESSURE;
    }
  }, [tabIndex]);

  const { data, isFetching } = useGetReadingsByMemberQuery(
    {
      memberId,
      paramsObject: {
        sort: "measure_timestamp,DESC",
        device_type: deviceType,
        startdate: Math.round(startDate?.toSeconds()),
        enddate: Math.round(endDate?.toSeconds())
      }
    },
    {
      skip:
        startDate === undefined ||
        memberId === undefined ||
        endDate === undefined
    }
  );

  const containsData = isFetching || data?.length === 0;

  return (
    <>
      <RowContainer justifyContent={"space-between"}>
        <Box display={"flex"} sx={{ cursor: "pointer" }} {...getToggleProps()}>
          {isExpanded ? (
            <ExpandLess fontSize={"small"} />
          ) : (
            <ExpandMore fontSize={"small"} />
          )}
          <Typography variant="h6">Recent Readings</Typography>
        </Box>
        <DropdownDateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={(date) => setStartDate(date)}
          onEndDateChange={(date) => setEndDate(date)}
          small
        />
      </RowContainer>

      <Card {...getCollapseProps()} sx={SidebarContainerStyle}>
        <Tabs value={tabIndex} onChange={handleTabSelect} variant="fullWidth">
          <Tab
            label={"Glucose"}
            style={{ fontWeight: "600", fontSize: "82%" }}
            value={TabPanelIndex.glucose}
          />
          <Tab
            label={"Blood Pressure"}
            style={{ fontWeight: "600", fontSize: "82%" }}
            value={TabPanelIndex.bloodPressure}
          />
        </Tabs>

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={containsData && "center"}
          justifyContent={containsData && "center"}
          height={containsData && 180}
        >
          {isFetching && <CircularProgress />}
          {!isFetching && data?.length === 0 && (
            <Typography variant="body1">No data</Typography>
          )}

          {!isFetching &&
            data?.slice(0, 5).map((item) => {
              if (isFalsy(item)) return;
              const {
                measure_timestamp,
                glucose,
                systolic,
                diastolic,
                c_is_fasting_v1
              } = item;

              const dateTime = DateTime.fromSeconds(measure_timestamp);
              const value =
                tabIndex === TabPanelIndex.glucose
                  ? `${glucose} mg/dL`
                  : `${systolic}/${diastolic} mmHg`;

              return (
                <Box
                  key={item.reading_id}
                  display={"flex"}
                  justifyContent={"space-between"}
                  margin={1}
                >
                  <Typography variant={"body1"} color={"primary"}>
                    {dateTime?.toFormat("MMM d hh:mm a")}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    color={"primary"}
                    sx={{ fontSize: "80%", fontWeight: 600 }}
                  >
                    {c_is_fasting_v1 ? "(Fasting)" : ""} {value}
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </Card>
    </>
  );
};

export default RecentReadings;
