import ReduxTagEnum from "../enums/ReduxTagEnum";

//https://redux-toolkit.js.org/rtk-query/usage/automated-refetching#tags
export function providesList<R, T>(
  resultsWithIds: R[] | undefined,
  tagType: T,
  getId: (item: R) => string
) {
  return resultsWithIds && resultsWithIds.length > 0
    ? [
        ...resultsWithIds.map((item) => ({
          type: tagType,
          id: getId(item)
        }))
      ]
    : [{ type: tagType }];
}
