import LocalizedStringsLib from "react-localization";
import EnvVars from "./../config/EnvVars";
import en from "./languages/en";
import ProductEnum from "../enums/ProductEnum";
//import es from "./languages/es";

// We want to force english for RemoteIQ.
let languages = { en };
if (EnvVars.REACT_APP_PRODUCT === ProductEnum.CopilotIQ) {
  languages = {
    en
    // es
    // it, (for italian)
    // etc
  };
}
const LocalizedStrings = new LocalizedStringsLib(languages);

export default LocalizedStrings;
