import { useFormik } from "formik";
import styled from "@emotion/styled";
import { DateTime } from "luxon";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import isEqual from "lodash.isequal";

import { useUpdateUserByUsernameMutation } from "common/services/UserService";

import {
  EMAIL_VALIDATION_REGEXP,
  unmaskPhoneNumber,
  PHONE_VALIDATION_REGEXP,
  validateFieldLength,
  isFalsy,
  PHONE_NUMBER_REGEXP
} from "common/helpers/helpers";
import UserTypeInner from "common/types/UserTypeInner";
import timezones from "common/config/timezones.json";
import { RootState, useAppDispatch } from "common/redux";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";

import ErrorComponent from "../../components/ErrorComponent";

import PhoneInputField from "../../components/Input/PhoneInputField";
import DatePicker from "../../components/DatePicker";
import { Section } from "../../styling/StyleComponents";
import { canUpdateMobileNumber } from "common/enums/RolesEnum";
import { useCallback } from "react";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";

const DATE_FORMAT = "MM/dd/yyyy";
const minDate = DateTime.fromFormat("01/01/1900", DATE_FORMAT);
const maxDate = DateTime.now();

const ProfileFormContainer = styled.div`
  margin: 2.5% 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: row;
  gap: 20px;
`;

interface IProps {
  user: UserTypeInner;
}

type FormType = Partial<UserTypeInner>;

const ProfileForm = ({ user }: IProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();

  const [
    updateUserByUsernameMutation,
    {
      error: updateUserError,
      isLoading: updateUserLoading,
      isSuccess: updateUserSuccess
    }
  ] = useUpdateUserByUsernameMutation();

  const { data: authUser } = useGetAuthenticatedUser();

  const validate = (values) => {
    const errors = {};

    if (!EMAIL_VALIDATION_REGEXP.test(values["email"])) {
      errors["email"] = "Invalid email address";
    }

    if (!validateFieldLength(2, 50, values["first"])) {
      errors["first"] = "Please enter a value between 2 and 50 characters.";
    }

    if (!validateFieldLength(2, 50, values["last"])) {
      errors["last"] = "Please enter a value between 2 and 50 characters.";
    }

    if (isFalsy(values["birthdate"])) {
      errors["birthdate"] = "Please select a valid birthdate.";
    }

    if (values["birthdate"]) {
      const birthdate = DateTime.fromFormat(values?.birthdate, "MM/dd/yyyy");

      if (!birthdate?.isValid) {
        errors["birthdate"] = "Please select a valid birthdate.";
      }

      if (
        birthdate.diff(minDate, "days").days < 0 ||
        birthdate.diff(maxDate, "days").days > 0
      ) {
        errors["birthdate"] = "Please select a valid birthdate.";
      }
    }

    if (
      !isFalsy(values["timezone"]) &&
      timezones.find((timezone) => {
        return timezone.value == values["timezone"];
      }) === undefined
    ) {
      errors["timezone"] = "Please select a valid timezone.";
    }

    if (
      values["phone"] &&
      !PHONE_VALIDATION_REGEXP.test(unmaskPhoneNumber(values?.["phone"]))
    ) {
      errors["phone"] = "Please enter a valid 10-digit phone number";
    }

    if (
      values["mobile"] &&
      !PHONE_VALIDATION_REGEXP.test(unmaskPhoneNumber(values?.["mobile"]))
    ) {
      errors["mobile"] = "Please enter a valid 10-digit mobile number";
    }

    return errors;
  };

  const onChangeTimezone = useCallback(
    (e) => {
      const modalId = "changeTimezoneModal";
      Alert_show({
        dispatch,
        id: modalId,
        title: "Error",
        size: "small",
        hideCloseIcon: true,
        content: (
          <Typography variant="h6" color={"error"}>
            Email eng.support@copilotiq.com to request this to be updated.
          </Typography>
        ),
        buttons: [
          {
            text: "Cancel",
            style: "cancel",
            onPress: () => {
              Alert_close({ dispatch, id: modalId });
            }
          }
        ]
      });
    },
    [dispatch]
  );

  const onSubmit = async (values, initialValues) => {
    const changes: Partial<UserTypeInner> = {};
    const valuesKeys = Object.keys(values);
    valuesKeys.forEach((key) => {
      if (!isEqual(initialValues[key], values[key])) {
        let value = values[key];
        if (key === "phone" || key === "mobile") {
          value = unmaskPhoneNumber(value).trim();
        }
        if (key === "first" || key === "last" || key === "middle") {
          value = value.trim();
        }
        changes[key] = value;
      }
    });

    if (Object.keys(changes)?.length > 0) {
      await updateUserByUsernameMutation({
        email: user.email,
        user: changes
      });
    }
  };

  const {
    setFieldValue,
    handleSubmit,
    handleReset,
    values,
    errors,
    dirty,
    isValid
  } = useFormik<FormType>({
    validate,
    initialValues: user,
    onSubmit: (values) => {
      onSubmit(values, user).catch(() => {});
    },
    enableReinitialize: true
  });

  return (
    <ProfileFormContainer>
      <Section>
        <Box display={"flex"} alignItems={"center"}>
          <Typography flex={1} variant="h3" color="text.primary">
            Basic Information
          </Typography>
          <LoadingButton
            loading={updateUserLoading}
            disabled={!dirty || !isValid}
            onClick={() => handleSubmit()}
          >
            Save
          </LoadingButton>
          <Button disabled={!dirty} onClick={() => handleReset(null)}>
            Cancel
          </Button>
        </Box>

        <br />

        <Grid>
          <TextField
            value={values.first}
            onChange={(e) => {
              setFieldValue("first", e.target.value);
            }}
            label="First Name"
            fullWidth
            placeholder={"Enter first name here"}
            error={!isFalsy(errors["first"])}
            helperText={errors["first"]}
            autoComplete="given-name"
          />

          <TextField
            value={values.middle}
            onChange={(e) => {
              setFieldValue("middle", e.target.value);
            }}
            label="Middle Name"
            fullWidth
            placeholder={"Enter middle name here"}
            error={!isFalsy(errors["middle"])}
            helperText={errors["middle"]}
            autoComplete="additional-name"
          />

          <TextField
            value={values.last}
            onChange={(e) => {
              setFieldValue("last", e.target.value);
            }}
            label="Last Name"
            fullWidth
            placeholder={"Enter last name here"}
            error={!isFalsy(errors["last"])}
            helperText={errors["last"]}
            autoComplete="family-name"
          />
          <DatePicker
            format={DATE_FORMAT}
            label={"Date of Birth"}
            value={
              values.birthdate
                ? DateTime.fromFormat(values.birthdate, DATE_FORMAT)
                : null
            }
            onChange={(date) => {
              if (date?.isValid) {
                setFieldValue("birthdate", date.toFormat(DATE_FORMAT));
              }
            }}
            slotProps={{
              textField: {
                error: errors["birthdate"] !== undefined,
                helperText: <>{errors["birthdate"]}</>,
                // prevent user from typing in the date as this can lead to bugs
                // see ENG-3757
                // the below code needs to be here instead of in DateTimePicker.tsx
                // until this PR is merged https://github.com/mui/material-ui/pull/35088
                onKeyDown: (e) => {
                  e.preventDefault();
                }
              }
            }}
            minDate={minDate}
            maxDate={maxDate}
          />

          {user?.user_id === authUser?.user?.user_id && (
            <TextField
              fullWidth
              onChange={onChangeTimezone}
              select
              value={values.timezone}
              type="timezone"
              autoComplete="timezone"
              label="Timezone"
            >
              {timezones.map(({ label, value }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
            </TextField>
          )}

          <TextField
            value={values.email}
            onChange={(e) => {
              setFieldValue("email", e.target.value);
            }}
            label="Internal Email"
            fullWidth
            placeholder={"Enter email here"}
            error={!isFalsy(errors["email"])}
            helperText={errors["email"]}
            disabled
          />

          <PhoneInputField
            value={values.phone}
            label={"Phone number"}
            fullWidth
            onChange={(event) => {
              if (PHONE_NUMBER_REGEXP.test(event.target.value)) {
                setFieldValue("phone", event.target.value);
              }
            }}
            placeholder={"Enter phone number"}
            error={!isFalsy(errors["phone"])}
            helperText={errors["phone"]}
          />
          <PhoneInputField
            value={values.mobile}
            label={"Mobile number"}
            fullWidth
            onChange={(event) => {
              if (PHONE_NUMBER_REGEXP.test(event.target.value)) {
                setFieldValue("mobile", event.target.value);
              }
            }}
            disabled={!canUpdateMobileNumber(currentRole)}
            placeholder={"Enter mobile number"}
            error={!isFalsy(errors["mobile"])}
            helperText={errors["mobile"]}
          />
        </Grid>

        <ErrorComponent error={updateUserError} />
        {updateUserSuccess && (
          <Typography variant="body1" color="green" margin={"10px"}>
            User information has been updated successfully
          </Typography>
        )}
      </Section>
    </ProfileFormContainer>
  );
};

export default ProfileForm;
