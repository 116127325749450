import DeviceReadingType from "common/types/DeviceReadingType";
import Table from "../../../components/Table/Table";

interface IProps {
  oximeterData: DeviceReadingType[];
  patientTimezone: string;
}

const OximeterTable = ({ oximeterData, patientTimezone }: IProps) => {
  return (
    <Table
      data={oximeterData}
      tableProps={{ patientTimezone }}
      tableContainerStyles={{ margin: "0 2.5%", width: "95%" }}
      tableColumns={[
        { name: "readingMeasureTimestamp" },
        { name: "spo2" },
        { name: "pulse" }
      ]}
    />
  );
};

export default OximeterTable;
