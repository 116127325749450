import RolesEnum, { getRoleLabel, MEDICAL_STAFF } from "common/enums/RolesEnum";
import ReportType from "../../types/ReportType";
import ReportEnum from "../../enums/ReportEnum";

const ReportTypes: ReportType[] = [
  // https://copilotiq.atlassian.net/browse/ENG-5178
  // {
  //   id: ReportEnum.ENCOUNTERS,
  //   title: "Appointment History",
  //   subtitle: "Generate a report on a member’s encounter history",
  //   userRequired: true,
  //   noInputDataText:
  //     "Select a member to generate the encounter history report on",
  //   allowedRoles: [RolesEnum.RCM_ADMIN, RolesEnum.ADMIN],
  //   dropdown: undefined
  // },
  {
    id: ReportEnum.FINANCIAL_ASSISTANCE_QUALIFICATIONS,
    title: "Financial Attestations",
    subtitle: "Members who qualify for financial assistance by date",
    userRequired: false,
    noInputDataText:
      "Select date range for members who qualified for financial assistance",
    allowedRoles: [
      RolesEnum.RCM_ADMIN,
      RolesEnum.ADMIN,
      RolesEnum.NPS,
      RolesEnum.NPS_MANAGER,
      RolesEnum.SALES_DIRECTOR,
      RolesEnum.MEMBER_CARE_SPECIALIST
    ],
    dropdown: undefined
  },
  {
    id: ReportEnum.READINGS,
    title: "Device Usage History",
    subtitle: "Generate a report on a member’s device history",
    userRequired: true,
    noInputDataText: "Select a member to generate the device history report on",
    allowedRoles: [RolesEnum.RCM_ADMIN, RolesEnum.ADMIN],
    dropdown: undefined
  },
  {
    id: ReportEnum.MEMBER_CHART_SHARE,
    title: "Member Chart",
    subtitle: "PDF download and Athena upload",
    containerMaxWidth: "100%",
    userRequired: true,
    noInputDataText:
      "Select a member and a time period to generate reading charts for",
    allowedRoles: [
      ...MEDICAL_STAFF,
      RolesEnum.MEMBER_CARE_SPECIALIST,
      RolesEnum.RCM_ADMIN,
      RolesEnum.ADMIN
    ],
    dropdown: undefined
  },
  {
    id: ReportEnum.TIME_ENTERED_BY_INS,
    title: "Time Entered by INs",
    subtitle: "Total encounter time entered by INs",
    userRequired: false,
    noInputDataText: "Please select one or more roles",

    allowedRoles: [
      RolesEnum.NURSE_DIRECTOR,
      RolesEnum.PROVIDER_MANAGER,
      RolesEnum.THN_MANAGER,
      RolesEnum.ADMIN,
      RolesEnum.NPN_MANAGER,
      RolesEnum.RCM_ADMIN
    ],
    dropdownProps: {
      multiple: true,
      placeholder: "Select roles"
    },
    dropdown: [
      RolesEnum.NP_NURSE,
      RolesEnum.NPN_MANAGER
      // https://copilotiq.atlassian.net/browse/ENG-5178
      // RolesEnum.NURSE_PROVIDER,
      // RolesEnum.MD_PROVIDER,
      // RolesEnum.NURSE_DIRECTOR
    ].map((role: RolesEnum) => ({
      value: role.toString(),
      label: getRoleLabel(role)
    })),
    checkbox: true,
    checkboxProps: {
      label: "Show inactive carers"
    }
  },
  {
    id: ReportEnum.TIME_ENTERED_BY_TNS,
    title: "Time Entered by TNs",
    subtitle: "Total encounter time entered by TNs",
    userRequired: false,
    noInputDataText: "Please select one or more roles",

    allowedRoles: [
      RolesEnum.NURSE_DIRECTOR,
      RolesEnum.PROVIDER_MANAGER,
      RolesEnum.THN_MANAGER,
      RolesEnum.ADMIN,
      RolesEnum.NPN_MANAGER,
      RolesEnum.RCM_ADMIN
    ],
    dropdownProps: {
      multiple: true,
      placeholder: "Select roles"
    },
    dropdown: [
      RolesEnum.TH_NURSE,
      RolesEnum.THN_MANAGER
      // https://copilotiq.atlassian.net/browse/ENG-5178
      // RolesEnum.NURSE_PROVIDER,
      // RolesEnum.MD_PROVIDER,
      // RolesEnum.NPN_MANAGER,
      // RolesEnum.NURSE_DIRECTOR
    ].map((role: RolesEnum) => ({
      value: role.toString(),
      label: getRoleLabel(role)
    })),
    checkbox: true,
    checkboxProps: {
      label: "Show inactive carers"
    }
  },
  {
    id: ReportEnum.ATTRITION,
    title: "Attrition",
    subtitle: "Generate a report on member cancellation reasons",
    userRequired: false,
    noInputDataText: "",
    submitterRoles: [
      RolesEnum.RETENTION_SPECIALIST,
      RolesEnum.ADMIN,
      RolesEnum.SYSTEM
    ],
    allowedRoles: [
      RolesEnum.NURSE_DIRECTOR,
      RolesEnum.ADMIN,
      RolesEnum.RETENTION_SPECIALIST,
      RolesEnum.ADMIN
    ],
    dropdown: undefined
  },
  {
    id: ReportEnum.REQUESTS_TO_CANCEL,
    title: "Requests to Cancel",
    subtitle: "Generate a report on members requesting to cancel",
    userRequired: false,
    noInputDataText: "",
    submitterRoles: [
      RolesEnum.TH_NURSE,
      RolesEnum.THN_MANAGER,
      RolesEnum.MD_PROVIDER,
      RolesEnum.NURSE_PROVIDER,
      RolesEnum.PROVIDER_MANAGER,
      RolesEnum.NPN_MANAGER,
      RolesEnum.NP_NURSE,
      RolesEnum.NPS,
      RolesEnum.NPS_MANAGER
    ],
    allowedRoles: [
      RolesEnum.NURSE_DIRECTOR,
      RolesEnum.ADMIN,
      RolesEnum.RETENTION_SPECIALIST,
      RolesEnum.ADMIN
    ],
    dropdown: undefined
  },
  {
    id: ReportEnum.SMS_REPLIES,
    defaultDatePickerRangeDays: 1,
    title: "Member Responses to Automated Messages",
    subtitle: "Member Responses to Automated Messages",
    userRequired: false,
    noInputDataText: "Select date range for SMS Replies.",
    allowedRoles: [
      RolesEnum.ADMIN,
      ...MEDICAL_STAFF.filter((role) => role !== RolesEnum.TH_NURSE)
    ],
    dropdown: undefined
  }
];
export default ReportTypes;
