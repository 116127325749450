import * as holidays from "./holidays";

export const ONE_DAY = 1;
export const DEFAULT_BUSINESS_DAYS = [1, 2, 3, 4, 5];

// https://drive.google.com/file/d/18wlU21DDfYiSyLDO23xyScsGXJE9l97G/view?usp=drive_link
const COPILOTIQ_HOLIDAYS = [
  holidays.isNewYearsDay,
  holidays.isMLKDay,
  holidays.isMemorialDay,
  holidays.isIndependanceDay,
  holidays.isLaborDay,
  holidays.isThanksgivingDay,
  holidays.isDayAfterThanksgiving,
  holidays.isChristmasDay
];

export const DEFAULT_HOLIDAY_MATCHERS = [...COPILOTIQ_HOLIDAYS];
