import { useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { MenuItem, TextField } from "@mui/material";
import styled from "@emotion/styled";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";

import {
  useCreateRetentionActionMutation,
  useGetMemberWithUsernameQuery
} from "common/services/MemberService";
import { useUpdateMemberStatusMutation } from "common/services/MemberRegistrationService";
import { RootState } from "common/redux";
import { showsSurveyModalAfterCanceledContact } from "common/enums/RolesEnum";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { getNameOrUsername, isFalsy } from "common/helpers/helpers";

import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import { TurqoiseButton, WhiteButton } from "../../styling";
import DatePicker from "../DatePicker";
import ErrorComponent from "../ErrorComponent";
import CallOutcomeEnum from "common/enums/CallOutcomeEnum";

const minDate = DateTime.fromFormat("01/01/2021", "MM/dd/yyyy");
const maxDate = DateTime.now().endOf("day");

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const STATUSES = [
  {
    value: "CANCELED",
    label: "Canceled"
  },
  {
    value: "RECOVERED",
    label: "Recovered"
  },
  {
    value: "CALL_BACK",
    label: "Asked to be reached back"
  }
];

const CALLING_STATUSES = [
  {
    value: CallOutcomeEnum.LEFT_VOICEMAIL,
    label: "Left voicemail"
  },
  {
    value: CallOutcomeEnum.NO_ANSWER,
    label: "No answer"
  }
];

const TEXT_STATUSES = [
  {
    value: "SENT",
    label: "Sent"
  }
];

const METHODS = [
  {
    value: "CALL_PHONE",
    label: "Call Phone",
    accessor: "phone",
    statuses: [...STATUSES, ...CALLING_STATUSES]
  },
  {
    value: "CALL_MOBILE",
    label: "Call Mobile",
    accessor: "mobile",
    statuses: [...STATUSES, ...CALLING_STATUSES]
  },
  {
    value: "SMS_MOBILE",
    label: "Send SMS (Mobile)",
    accessor: "mobile",
    statuses: [...STATUSES, ...TEXT_STATUSES]
  },
  {
    value: "EMAIL",
    label: "Email",
    accessor: "email",
    statuses: [...STATUSES, ...TEXT_STATUSES]
  },
  {
    value: "EMERGENCY_CONTACT",
    label: "Emergency Contact",
    statuses: [...STATUSES, ...CALLING_STATUSES]
  }
];

interface FormType {
  method: string;
  status: string;
  date: DateTime;
  notes: string;
}

interface ErrorType extends Omit<FormType, "date"> {
  date: string;
}

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  selectedPatientId: string;
  handleOpenSurveyModal: () => void;
}

const ContactAttemptModal = ({
  isOpen,
  onRequestClose,
  selectedPatientId,
  handleOpenSurveyModal
}: IProps) => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: selectedPatientId,
      linked_entities: [MemberLinkedEntitiesEnum.PATIENT_INACTIVITY]
    },
    { skip: selectedPatientId === undefined }
  );

  const [updatePatientStatusMutation, { isLoading: patientStatusLoading }] =
    useUpdateMemberStatusMutation();
  const [
    createRetentionActionMutation,
    { isLoading: createRetentionLoading, isSuccess, error }
  ] = useCreateRetentionActionMutation();

  const isLoading = patientStatusLoading || createRetentionLoading;

  const onSubmit = async (values: FormType) => {
    const method = METHODS.find((method) => method.value === values.method);

    const attributes = {};
    if (method.accessor)
      attributes[method.accessor] = patient?.patient[method.accessor];

    if (values.status === "RECOVERED") {
      await updatePatientStatusMutation({
        patient_id: patient.patient.patient_id,
        status: MemberStatusEnum.REEVALUATING_PATIENT
      });
    }

    await createRetentionActionMutation({
      action_performed: values.method,
      notes: values.notes,
      patient_id: selectedPatientId,
      patient_status_reason: patient?.patient_inactivity?.patient_status_reason,
      attributes,
      retention_action_status: values.status,
      patient_status: patient?.patient.status
    });

    if (
      showsSurveyModalAfterCanceledContact(currentRole) &&
      values.status === "CANCELED"
    ) {
      handleOpenSurveyModal();
    }
  };

  const validate = (values: FormType) => {
    const errors: Partial<ErrorType> = {};

    if (!values["method"]) {
      errors["method"] = "Required";
    }

    if (!values["status"]) {
      errors["status"] = "Required";
    }

    if (values["date"] === null) {
      errors["date"] = "Required";
    }

    if (!values?.date?.isValid || isFalsy(values["date"])) {
      errors["date"] = "Please select a valid date.";
    }

    if (
      values?.date.diff(minDate, "days").days < 0 ||
      values?.date.diff(maxDate, "days").days > 0
    ) {
      errors["date"] = "Please select a valid date.";
    }

    return errors;
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    isValid,
    dirty,
    errors,
    resetForm
  } = useFormik<FormType>({
    initialValues: {
      date: DateTime.now(),
      method: "",
      status: "",
      notes: ""
    },
    validate,
    onSubmit
  });

  const title =
    "Submit Contact Attempt for " + getNameOrUsername(patient?.patient);

  const closeModal = () => {
    onRequestClose();
    resetForm();
  };

  useEffect(() => {
    if (isSuccess) closeModal();
  }, [isSuccess]);

  const statuses = useMemo(() => {
    if (values.method === "") return null;
    else {
      return METHODS.find((method) => method.value === values.method).statuses;
    }
  }, [values]);

  return (
    <StyledModal
      isOpen={isOpen}
      modalHeight="70vh"
      contentLabel={title}
      onRequestClose={closeModal}
    >
      <Form onSubmit={handleSubmit}>
        <ModalHeader onRequestClose={closeModal}>{title}</ModalHeader>
        <ModalBody>
          <DatePicker
            label={"Contact date"}
            value={values.date}
            onChange={(newDate: DateTime) => {
              if (newDate?.isValid) {
                setFieldValue("date", newDate);
              }
            }}
            slotProps={{
              textField: {
                error: errors["date"] !== undefined,
                helperText: <>{errors["date"]}</>,
                // prevent user from typing in the date as this can lead to bugs
                // see ENG-3757
                // the below code needs to be here instead of in DateTimePicker.tsx
                // until this PR is merged https://github.com/mui/material-ui/pull/35088
                onKeyDown: (e) => {
                  e.preventDefault();
                }
              }
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
          <br />

          <TextField
            id="contact-method"
            value={values.method}
            label="Contact Method"
            select
            fullWidth
            onChange={(event) => {
              setFieldValue("method", event.target.value);
            }}
          >
            {METHODS.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <br />

          <TextField
            id="outcome"
            value={values.status}
            label="Outcome"
            select={statuses != undefined}
            fullWidth
            onChange={(event) => {
              setFieldValue("status", event.target.value);
            }}
            disabled={statuses === null}
          >
            {statuses?.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
          <br />

          <TextField
            id="notes"
            value={values.notes}
            label="Notes"
            fullWidth
            onChange={(event) => {
              setFieldValue("notes", event.target.value);
            }}
          />

          <br />
          {error && <ErrorComponent error={error} />}
        </ModalBody>

        <ModalFooter>
          <ModalFooterButtons>
            <TurqoiseButton
              disabled={!isValid || !dirty}
              loading={isLoading}
              type="submit"
            >
              Submit
            </TurqoiseButton>
            <WhiteButton onClick={closeModal}>Cancel</WhiteButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
    </StyledModal>
  );
};

export default ContactAttemptModal;
