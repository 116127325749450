import styled from "@emotion/styled";

const NotFoundContainer = styled.div`
  margin: 5%;
  padding: 5%;
  width: 90%;
  background: ${({ theme }) => theme.color.defaultBackground};
  /* Stroke */

  border: 1px solid ${({ theme }) => theme.color.defaultBorder};
  border-radius: 4px;
`;

const NotFound = () => {
  return (
    <NotFoundContainer>
      Error: Page not found or you may not have permission to access it.
    </NotFoundContainer>
  );
};

export default NotFound;
