import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../styling/StyleModal";
import { TurqoiseButton } from "../../styling";
import MemberRetentionActionsType from "common/types/MemberRetentionActionsType";
import Table from "../Table/Table";
import { dateCell, defaultCell } from "../Table/TableCells";
import { getNameOrUsername } from "common/helpers/helpers";
import { useGetMemberWithUsernameQuery } from "common/services/MemberService";

interface IProps {
  retentionActions: MemberRetentionActionsType[];
  onRequestClose: () => void;
  memberId: string;
}

const ContactAttemptDetailsModal = ({
  retentionActions,
  onRequestClose,
  memberId
}: IProps) => {
  const { data: patient } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    {
      skip: memberId === undefined
    }
  );

  const header = patient
    ? "Retention Contact History for " + getNameOrUsername(patient.patient)
    : "Retention Contact History";

  return (
    <StyledModal
      isOpen={retentionActions !== undefined}
      modalHeight="70vh"
      modalWidth="970px"
      contentLabel={header}
      onRequestClose={onRequestClose}
    >
      <ModalHeader onRequestClose={onRequestClose}>{header}</ModalHeader>
      <ModalBody>
        <Table
          data={retentionActions}
          tableColumns={[
            {
              id: "Date",
              name: "Date",
              header: "Date",
              accessor: "created_at",
              cell: ({ getValue }) =>
                dateCell({ getValue, format: "MM/dd/yyyy hh:mm a" })
            },
            {
              id: "Method",
              name: "Method",
              header: "Method",
              accessor: "action_performed",
              cell: defaultCell
            },
            {
              id: "Outcome",
              name: "Outcome",
              header: "Outcome",
              accessor: "retention_action_status",
              cell: defaultCell
            },
            {
              id: "Notes",
              name: "Notes",
              header: "Notes",
              accessor: "notes",
              cell: defaultCell
            }
          ]}
        />
      </ModalBody>

      <ModalFooter>
        <ModalFooterButtons>
          <TurqoiseButton onClick={onRequestClose}>Close</TurqoiseButton>
        </ModalFooterButtons>
      </ModalFooter>
    </StyledModal>
  );
};

export default ContactAttemptDetailsModal;
