enum RegisterConflictStatus {
  MULTIPLE_BEST_MATCHES = "MULTIPLE_BEST_MATCHES",
  ATHENA_SALESFORCE_DO_NOT_MATCH = "ATHENA_SALESFORCE_DO_NOT_MATCH",
  ALREADY_EXISTS_IN_PAB = "ALREADY_EXISTS_IN_PAB",
  NEW_LEAD_EXISTING_PATIENT = "NEW_LEAD_EXISTING_PATIENT",
  INVALID_BIRTH_DATE = "INVALID_BIRTH_DATE",
  INVALID_DATA_IN_SALESFORCE_LEAD = "INVALID_DATA_IN_SALESFORCE_LEAD",
  INVALID_PROVIDER_DEPARTMENT_IN_ATHENA = "INVALID_PROVIDER_DEPARTMENT_IN_ATHENA"
}

export default RegisterConflictStatus;
