// @ts-nocheck

/*

TODO:
- MigratePatientRefactor to typescript and useFormik
- RegisterPatientRefactor to typescript and useFormik

Once we finally delete this file, remove:

- react-select
- react-datepicker
- ./FormHelpers.css
*/
import { ReactNode, Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, FormikProps } from "formik";
import { SlotProps, StandardTextFieldProps } from "@mui/material";
import Select, { components } from "react-select";
import { styled } from "@mui/material/styles";
import { ErrorTextSpan, TextFormField } from "../../../styling";
import cloneDeep from "lodash.clonedeep";
import { CalendarIcon, HelpIcon } from "../../../assets/images/icons";
import "./FormHelpers.css";
import { CustomTooltip } from "../../../styling/StyleComponents";
import {
  Checkbox,
  FormControlLabel,
  SelectProps,
  Stack,
  TextField
} from "@mui/material";
import get from "lodash.get";

export const CARE_FLOW_CHARACTER_LIMIT = 10000;

const FormLabel = styled("label")`
  font-family: ${(props) => props.theme.font.family};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.color.darkGrey};
  display: block;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const FormField = styled(TextFormField)``;

const StyledHelpIcon = styled(HelpIcon)`
  top: 3px;
  position: relative;
  left: 4px;
  width: 18px;
  height: 18px;
`;

const RadioButtonText = styled("div")<{ $active: boolean }>`
  color: ${(props) =>
    props?.$active ? blue[700] : props?.theme?.color?.darkGrey};
  font-weight: ${(props) => (props?.$active ? "700" : "400")};
`;

const ErrorContainer = styled("div")`
  transition: height 0.66s ease-out;
  clamp(28px, 100%, 38px);
  min-height: 28px;
`;

const StyledErrorTextSpan = styled(ErrorTextSpan)`
  margin-top: 12px;
  font-size: 14px;
`;

const DatePickerContainer = styled("div")`
  position: relative;
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  position: absolute;
  right: 15px;
  top: 15px;
`;

const StyledTextField = styled(
  (props: any) => {
    return (
      <TextField
        data-testid={props["data-testid"] ?? props.label ?? props.value}
        {...props}
      />
    );
  },
  {
    // don't forward to dom
    shouldForwardProp: (prop) => prop !== "disableinput"
  }
)((props) => {
  return {
    "& input.Mui-disabled": {
      backgroundColor: props?.disableinput ? "white" : "transparent",
      WebkitTextFillColor: props?.disableinput
        ? "rgba(0,0,0,0.99)"
        : "currentcolor",
      cursor: props?.disableinput ? "not-allowed" : "auto"
    }
  };
});

export const FormCheckbox = ({
  marginLeft = 0,
  label,
  size = "small",
  checked,
  onChange,
  disabled = false,
  tooltip = undefined
}) => {
  return (
    <FormControlLabel
      sx={{ marginLeft }}
      control={<Checkbox />}
      label={
        tooltip ? (
          <>
            {label}{" "}
            <CustomTooltip title={tooltip}>
              <StyledHelpIcon />
            </CustomTooltip>
          </>
        ) : (
          label
        )
      }
      size={size}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  );
};

const NoOptionsMessage = (props) => {
  const { children } = props;
  return (
    <components.NoOptionsMessage {...props}>
      <ErrorTextSpan>{children}</ErrorTextSpan>
    </components.NoOptionsMessage>
  );
};

interface TextFieldComponentProps extends StandardTextFieldProps {
  formik: FormikProps<unknown>;
  fieldName: string;
  label: string;
  placeholder: string;
  autocomplete?: string;
  disabled?: boolean;
  disableinput?: boolean;
  fullWidth?: boolean;
  select?: boolean;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean
  ) => void;
  children?: null | ReactNode | ReactNode[];
  value?: string;
  slotProps?: SlotProps;
  disableHelperText?: boolean;
}

function TextFieldComponent({
  formik,
  fieldName,
  label,
  placeholder,
  fullWidth,
  setFieldValue,
  select,
  sx,
  children,
  autocomplete,
  disabled,
  value,
  slotProps,
  disableinput,
  disableHelperText = false
}: TextFieldComponentProps) {
  const retrievedValue = get(formik?.values, fieldName, "");
  const retrievedError = get(formik?.errors, fieldName, undefined);
  return (
    <Stack sx={sx}>
      <StyledTextField
        value={value ?? retrievedValue}
        label={label}
        id={fieldName}
        select={select}
        disabled={disabled}
        disableinput={disableinput}
        fullWidth={fullWidth}
        onChange={(event) => setFieldValue(fieldName, event.target.value)}
        placeholder={placeholder}
        error={disableHelperText ? false : retrievedError !== undefined}
        helperText={disableHelperText ? undefined : retrievedError}
        autoComplete={autocomplete}
        slotProps={slotProps}
      >
        {children}
      </StyledTextField>
    </Stack>
  );
}

const CustomSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { setFieldValue, touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  options,
  isSearchable = false,
  noOptionsMessage = "No options",
  disabled,
  dropdownMaxMenuHeight = 200,
  ...props
}) => {
  return (
    <Select
      maxMenuHeight={dropdownMaxMenuHeight}
      isDisabled={disabled}
      options={options}
      type="text"
      value={field.value ? field.value : undefined}
      isSearchable={isSearchable}
      components={{ NoOptionsMessage }}
      noOptionsMessage={() => noOptionsMessage}
      {...field}
      {...props}
    />
  );
};

/**
 * This function validates whether the input is a valid Salesforce ID https://stackoverflow.com/questions/9742913/validating-a-salesforce-id
 * @param  {String} str  String to validate
 * @return {Boolean} returns true if the string is a valid Salesforce ID
 */

export function isValidSalesforceId(str) {
  // https://stackoverflow.com/a/29299786/1333724
  if (typeof str !== "string" || !/^[a-zA-Z0-9]{15,18}$/.test(str)) {
    return false;
  }

  // checksum for 18 characters

  if (str.length === 18) {
    let upperCaseToBit = (char) => (char.match(/[A-Z]/) ? "1" : "0");
    let binaryToSymbol = (digit) =>
      digit <= 25
        ? String.fromCharCode(digit + 65)
        : String.fromCharCode(digit - 26 + 48);

    let parts = [
      str.slice(0, 5).split("").reverse().map(upperCaseToBit).join(""),
      str.slice(5, 10).split("").reverse().map(upperCaseToBit).join(""),
      str.slice(10, 15).split("").reverse().map(upperCaseToBit).join("")
    ];

    let check = parts.map((str) => binaryToSymbol(parseInt(str, 2))).join("");

    return check === str.slice(-3);
  }

  if (str.length === 15) {
    // if we've reached this point, it is a valid 15 character ID
    return true;
  }

  return false;
}

const getFormItems = (
  sectionItemsArray,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  values
) => {
  /*** This is what sectionItemsArray looks like
   * sectionItemsArray = [{"dataKey": "first", "label": "First Name" }]
   */
  const arrayClone = cloneDeep(sectionItemsArray);
  return (
    <>
      {arrayClone.map((item) => {
        const {
          dataKey,
          label,
          marginLeft = 0,
          tooltip = undefined,
          required = false,
          placeholder = null,
          elementType = null,
          onlyErrorOnTouched = true,
          disabled = false,
          disableinput = false,
          dropdownOptions = [],
          callback = null,
          tooltipText = null
        } = item;
        return (
          <FormElement
            key={dataKey}
            dataKey={dataKey}
            label={label}
            type={item?.type}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            values={values}
            placeholder={placeholder}
            required={required}
            elementType={elementType}
            onlyErrorOnTouched={onlyErrorOnTouched}
            disabled={disabled}
            disableinput={disableinput.toString()}
            dropdownOptions={dropdownOptions}
            callback={callback}
            tooltipText={tooltipText}
            marginLeft={marginLeft}
            tooltip={tooltip}
          />
        );
      })}
    </>
  );
};

const FormElement = ({
  dataKey,
  label,
  type,
  errors,
  touched,
  setFieldValue,
  values,
  extraLabelText = "",
  placeholder,
  elementType,
  onlyErrorOnTouched,
  dropdownOptions,
  callback
}) => {
  let fullLabel = `${label}${extraLabelText}`;

  switch (elementType) {
    case "datePicker":
      return (
        <Fragment key={dataKey}>
          <FormLabel htmlFor={dataKey}>{fullLabel}</FormLabel>
          <DatePickerContainer>
            <FormField type={type} name={dataKey}>
              {({ field }) => (
                <DatePicker
                  {...field}
                  dateFormat="MM/dd/yyyy"
                  className="base-date-picker"
                  placeholderText={placeholder ? placeholder : "Select a Date"}
                  strictParsing
                  selected={(field.value && new Date(field.value)) || null}
                  onChange={(val) => {
                    setFieldValue(field.name, val);
                  }}
                />
              )}
            </FormField>
            <StyledCalendarIcon />
          </DatePickerContainer>
          {errors?.[dataKey] &&
          (onlyErrorOnTouched ? touched?.[dataKey] : true) ? (
            <StyledErrorTextSpan>{errors?.[dataKey]}</StyledErrorTextSpan>
          ) : null}
        </Fragment>
      );
    case "departmentDropdown":
      let selOption;
      if (values?.[dataKey]) {
        selOption = dropdownOptions.find(
          (reason) => reason.value === values?.[dataKey]
        );
      }
      return (
        <Fragment key={dataKey}>
          <FormLabel htmlFor={dataKey}>{fullLabel}</FormLabel>
          <Field
            component={CustomSelectComponent}
            options={dropdownOptions}
            id={dataKey}
            name={dataKey}
            // this schema is important for working with react-select { label: label, value: value }
            value={{
              label: selOption ? selOption?.label : values?.[dataKey],
              value: values?.[dataKey]
            }}
            onChange={(e) => {
              const selected = dropdownOptions?.find(
                (option) => option?.value === e?.value
              );
              callback(selected);
              setFieldValue(dataKey, e?.value);
            }}
            noOptionsMessage="No departments found for member's state"
            dropdownMaxMenuHeight={165}
          ></Field>
          {errors?.[dataKey] &&
          (onlyErrorOnTouched ? touched?.[dataKey] : true) ? (
            <ErrorContainer>
              <StyledErrorTextSpan>{errors?.[dataKey]}</StyledErrorTextSpan>
            </ErrorContainer>
          ) : null}
        </Fragment>
      );
    default:
      break;
  }
};

function isValidAthenaId(str) {
  // Must be a positive number
  return parseInt(str) && parseInt(str) > 0;
}

export {
  getFormItems,
  isValidAthenaId,
  CustomSelectComponent,
  FormLabel,
  RadioButtonText,
  TextFieldComponent,
  StyledTextField
};
