import { Box, Typography } from "@mui/material";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { DateTime } from "luxon";
import ErrorComponent from "../../../components/ErrorComponent";
import Table from "../../../components/Table/Table";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import useGetCalendarVisits, {
  filterTodaysUpcomingAppointment,
  filterUpcomingAppointments
} from "../../../hooks/data_loaders/useGetCalendarVisits";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import TodaysAppointments from "./TodaysAppointments";
import { isFalsy } from "common/helpers/helpers";
import { getCalendarVisitsCreatedModifiedSize } from "../../../components/Table/helpers/TableHelpers";
import { canScheduleNurses } from "common/enums/RolesEnum";

interface IProps {
  staffId?: string;
  memberId?: string;
  startDate: DateTime;
  endDate: DateTime;
  isStaffDetails: boolean;
  staffTimezone?: string;
}

const UpcomingAppointments = ({
  memberId,
  staffId,
  startDate,
  endDate,
  isStaffDetails,
  staffTimezone
}: IProps) => {
  const navigate = useNavigate();
  const { currentRole, user } = useSelector((state: RootState) => state.auth);
  const { memberId: memberIdParam } = useSanitizedParams();

  const {
    data: calendarVisitsData,
    isFetching: calendarVisitsIsFetching,
    error: calendarVisitsError
  } = useGetCalendarVisits({
    patient_id: memberId,
    staff_id: staffId,
    // we need to change this to the start of the day to grab PTO for the day
    startdate: startDate.startOf("day"),
    enddate: endDate,
    currentRole,
    skip: isFalsy(memberId) && isFalsy(staffId)
  });

  // filter out past appointments from upcoming by end date
  const filteredUpcomingAppointmentsData = useMemo(
    () => filterUpcomingAppointments({ data: calendarVisitsData, startDate }),
    [calendarVisitsData]
  );

  // find today's appointments
  const todaysUpcomingNurseAppointmentsData = useMemo(
    () => filterTodaysUpcomingAppointment({ data: calendarVisitsData }),
    [calendarVisitsData]
  );

  const appointmentMetWithColumn = isStaffDetails
    ? { name: "appointmentMemberAttendee", size: 165 }
    : { name: "appointmentStaffMember", size: 165 };

  return (
    <Box mb="32px">
      {memberIdParam && !calendarVisitsIsFetching && (
        <TodaysAppointments
          upcomingAppointments={todaysUpcomingNurseAppointmentsData}
          staffTimezone={staffTimezone}
          memberId={memberId}
        />
      )}

      <Typography variant="h4" color="text.secondary" mb="12px" mt="12px">
        Upcoming Appointments
      </Typography>
      {calendarVisitsIsFetching && <LoadingFallback count={5} />}
      {filteredUpcomingAppointmentsData && !calendarVisitsIsFetching && (
        <Table
          noDataText="There are no scheduled appointments."
          noDataButtonText={
            canScheduleNurses(currentRole) &&
            memberId &&
            "Schedule TN appointment"
          }
          noDataButtonOnClick={() =>
            navigate(`/members/memberId/${memberId}/new-nurse-appointment`)
          }
          tableColumns={[
            {
              name: "calendarVisitsStartEnd",
              size: 130
            },
            {
              name: "calendarVisitsCreatedModified",
              size: 200
            },
            // {
            //   name: "appointmentType",
            //   size: 150,
            // },
            appointmentMetWithColumn,
            { name: "calendarVisitsActions", size: 190 }
          ]}
          tableProps={{
            navigate,
            staffTimezone,
            currentRole,
            currentUser: user
          }}
          estimateRowSize={(index: number) => {
            const item = filteredUpcomingAppointmentsData[index];
            return getCalendarVisitsCreatedModifiedSize(item);
          }}
          data={filteredUpcomingAppointmentsData}
        />
      )}

      {calendarVisitsError && <ErrorComponent error={calendarVisitsError} />}
    </Box>
  );
};

export default UpcomingAppointments;
