import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";

import { Flexbox } from "../../../styling/NewStyleComponents";

import { checkIdValid, isFalsy } from "common/helpers/helpers";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import { useEffect, useMemo, useState } from "react";
import { useGetTNAvailabilityQuery } from "common/services/PanelManagementService";
import RecurrenceFrequencyEnum from "common/enums/Calendaring/Appointments/RecurrenceFrequencyEnum";
import { DateTime } from "luxon";
import { blue, gray } from "../../../styling/colors";
import ErrorComponent from "../../../components/ErrorComponent";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { RootState, useAppDispatch } from "common/redux";
import {
  AlarmOffOutlined,
  ChevronLeft,
  ChevronRight
} from "@mui/icons-material";
import {
  isBusinessDay,
  plusBusinessDays
} from "common/helpers/BusinessDaysHelper/BusinessDaysHelper";
import {
  AFTERNOON_SLOTS,
  MIDDAY_SLOTS,
  MORNING_SLOTS,
  dateIsBlocked
} from "common/helpers/CalendarHelper";
import {
  resetSelectedTime,
  setRecurrence,
  setSelectedTime
} from "common/redux/StartIntakeSlice";
import { useSelector } from "react-redux";
import { DAYS_IN_FUTURE, weekdays } from "./constants";

function weekDoesNotHaveAvailability(weekAvailabilityMap, weekIndex) {
  return (
    isFalsy(weekAvailabilityMap?.[weekIndex]?.MORNING) &&
    isFalsy(weekAvailabilityMap?.[weekIndex]?.MIDDAY) &&
    isFalsy(weekAvailabilityMap?.[weekIndex]?.AFTERNOON)
  );
}

const Step3 = ({
  onBack,
  defaultCadence
}: Readonly<{
  onBack: () => void;
  onNext: () => void;
  defaultCadence: string;
}>) => {
  const dispatch = useAppDispatch();
  const params = useSanitizedParams();
  const { memberId } = params;
  const isValidId = checkIdValid(memberId);
  const now = DateTime.now().startOf("hour");
  const endDate = now
    .startOf("day")
    .plus({ days: DAYS_IN_FUTURE })
    .endOf("week");

  const currentDay = DateTime.now().startOf("day");

  const startDate = useMemo(
    // add 4 business days after the current day, or 5 business days
    () => plusBusinessDays(currentDay.startOf("day"), 5),
    [currentDay]
  );

  const [selectedDate, setSelectedDate] = useState<DateTime>(startDate);
  const currentDayIndex = selectedDate.weekday - 1;
  const currentWeekIndex = selectedDate.weekNumber;

  const { selectedStartDate, memberTimezone, recurrence, xTraceId } =
    useSelector((state: RootState) => state.startIntake);

  const [cadence, setCadence] = useState(recurrence ?? defaultCadence);

  const {
    data: panelManagement,
    isFetching: panelManagementIsFetching,
    error: panelManagementError
  } = useGetTNAvailabilityQuery(
    {
      memberId,
      startdate: startDate.toUTC().toISO(),
      enddate: endDate.toUTC().toISO(),
      recurrence_frequency: cadence as RecurrenceFrequencyEnum,
      xTraceId
    },
    { skip: isValidId === false || isFalsy(cadence) }
  );

  const [availabilityMap, setAvailabilityMap] = useState<any>(null);

  useEffect(() => {
    if (panelManagement) {
      dispatch(resetSelectedTime());
      const availabilityMap = {};

      // create map
      for (let i = 0; i < DAYS_IN_FUTURE; i++) {
        const date = startDate.plus({ days: i });
        const dateKey = date.setZone(memberTimezone).toISODate();
        if (!availabilityMap[dateKey]) {
          availabilityMap[dateKey] = {
            MORNING: [],
            MIDDAY: [],
            AFTERNOON: []
          };
        }
      }

      // add events to map
      panelManagement.forEach((availability) => {
        const availabilityStart = DateTime.fromISO(availability.start).setZone(
          memberTimezone
        );
        const availabilityEnd = DateTime.fromISO(availability.end).setZone(
          memberTimezone
        );

        if (!dateIsBlocked(availability)) {
          const dateKey = availabilityStart.toISODate();

          if (!availabilityMap[dateKey]) {
            availabilityMap[dateKey] = {
              MORNING: [],
              MIDDAY: [],
              AFTERNOON: []
            };
          }

          // add helper function to calculate morning, mid-day, evening and put in the appropriate hash map

          // skip holidays - this check should be on the backend too
          if (isBusinessDay(availabilityStart)) {
            if (
              ((availabilityStart.hour === 8 &&
                availabilityStart.minute >= 30) ||
                availabilityStart.hour > 8) &&
              (availabilityEnd.hour < 10 ||
                (availabilityEnd.hour === 10 && availabilityEnd.minute <= 30))
            ) {
              availabilityMap[dateKey]?.["MORNING"]?.push(availability);
            } else if (
              ((availabilityStart.hour === 10 &&
                availabilityStart.minute >= 30) ||
                availabilityStart.hour > 10) &&
              (availabilityEnd.hour < 14 ||
                (availabilityEnd.hour === 14 && availabilityEnd.minute === 0))
            ) {
              availabilityMap[dateKey]?.["MIDDAY"]?.push(availability);
            } else if (
              availabilityStart.hour >= 14 &&
              (availabilityEnd.hour < 17 ||
                (availabilityEnd.hour === 17 && availabilityEnd.minute === 0))
            ) {
              availabilityMap[dateKey]?.["AFTERNOON"]?.push(availability);
            }
          }
        }
      });
      setAvailabilityMap(availabilityMap);
    }
  }, [panelManagement]);

  const doesNotHaveAvailability = useMemo(() => {
    let hasAnyAvailability = false;
    let hasAnyAvailabilityThisWeek = false;

    let hasMorningAvailability = false;
    let hasMidDayAvailability = false;
    let hasAfternoonAvailability = false;

    let hasMorningAvailabilityThisWeek = false;
    let hasMidDayAvailabilityThisWeek = false;
    let hasAfternoonAvailabilityThisWeek = false;

    const weekAvailabilityMap = {};

    if (!isFalsy(availabilityMap)) {
      const keys = Object.keys(availabilityMap);

      keys.forEach((key) => {
        const keyDate = DateTime.fromFormat(key, "yyyy-MM-dd");

        if (!weekAvailabilityMap[keyDate.weekNumber]) {
          weekAvailabilityMap[keyDate.weekNumber] = {
            MORNING: false,
            MIDDAY: false,
            AFTERNOON: false
          };
        }
        const isCurrentWeek =
          Math.abs(keyDate.diff(selectedDate.startOf("week"), "days").days) < 7;
        if (availabilityMap[key]?.["MORNING"]?.length > 0) {
          hasMorningAvailability = true;

          weekAvailabilityMap[keyDate.weekNumber].MORNING = true;
          if (isCurrentWeek) {
            hasMorningAvailabilityThisWeek = true;
          }
        }
        if (availabilityMap[key]?.["MIDDAY"]?.length > 0) {
          hasMidDayAvailability = true;

          weekAvailabilityMap[keyDate.weekNumber].MIDDAY = true;
          if (isCurrentWeek) {
            hasMidDayAvailabilityThisWeek = true;
          }
        }

        if (availabilityMap[key]?.["AFTERNOON"]?.length > 0) {
          hasAfternoonAvailability = true;

          weekAvailabilityMap[keyDate.weekNumber].AFTERNOON = true;
          if (isCurrentWeek) {
            hasAfternoonAvailabilityThisWeek = true;
          }
        }
      });

      hasAnyAvailability =
        hasMorningAvailability ||
        hasMidDayAvailability ||
        hasAfternoonAvailability;

      hasAnyAvailabilityThisWeek =
        hasMorningAvailabilityThisWeek ||
        hasMidDayAvailabilityThisWeek ||
        hasAfternoonAvailabilityThisWeek;
    }

    return {
      hasNoAvailability: !hasAnyAvailability,
      hasNoAvailabilityThisWeek: !hasAnyAvailabilityThisWeek,
      hasNoMorningAvailabilityThisWeek: !hasMorningAvailabilityThisWeek,
      hasNoMidDayAvailabilityThisWeek: !hasMidDayAvailabilityThisWeek,
      hasNoAfternoonAvailabilityThisWeek: !hasAfternoonAvailabilityThisWeek,
      weekAvailabilityMap
    };
  }, [availabilityMap, selectedDate]);

  useEffect(() => {
    if (
      doesNotHaveAvailability.hasNoAvailabilityThisWeek &&
      !doesNotHaveAvailability.hasNoAvailability
    ) {
      setSelectedDate(selectedDate.plus({ days: 7 }));
    }
  }, [doesNotHaveAvailability]);

  return (
    <Box minWidth="962px">
      <Flexbox justifyContent="space-between" marginBottom="12px">
        <Flexbox gap="8px" alignItems="center">
          <Typography variant="h2">
            {selectedDate.toFormat("MMMM y")}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedDate(selectedDate.minus({ days: 7 }));
            }}
            disabled={
              startDate.toISODate() === selectedDate.toISODate() ||
              weekDoesNotHaveAvailability(
                doesNotHaveAvailability.weekAvailabilityMap,
                +currentWeekIndex === 0 ? 52 : currentWeekIndex - 1
              ) ||
              panelManagementIsFetching
            }
          >
            <ChevronLeft />
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setSelectedDate(selectedDate.plus({ days: 7 }));
            }}
            disabled={
              Math.abs(selectedDate.diff(startDate, "days").days) >=
                // only show 2 weeks of data into the future
                DAYS_IN_FUTURE - 7 ||
              weekDoesNotHaveAvailability(
                doesNotHaveAvailability.weekAvailabilityMap,
                +currentWeekIndex === 52 ? 0 : currentWeekIndex + 1
              ) ||
              panelManagementIsFetching
            }
          >
            <ChevronRight />
          </Button>
          <Typography variant="body1" fontSize="16px" lineHeight="24px">
            Times are in member time zone.
          </Typography>
        </Flexbox>
        <Flexbox gap="12px" alignItems="center">
          <Typography variant="body1" fontWeight={600}>
            Cadence
          </Typography>
          <TextField
            value={cadence}
            onChange={(event) => {
              dispatch(setRecurrence(event.target.value));
              setCadence(event.target.value);
            }}
            select
            sx={{ width: "356px", backgroundColor: "white" }}
            label="Select an option"
            placeholder="Select an option"
          >
            <MenuItem key={"weekly"} value={RecurrenceFrequencyEnum.WEEKLY}>
              Weekly
            </MenuItem>
            <MenuItem key={"biweekly"} value={RecurrenceFrequencyEnum.BIWEEKLY}>
              Biweekly
            </MenuItem>
          </TextField>
        </Flexbox>
      </Flexbox>

      <Box
        border={`1px solid ${gray[300]}`}
        borderRadius="12px"
        sx={{ background: "white" }}
      >
        <Flexbox
          sx={{ borderBottom: `1px solid ${gray[300]}` }}
          padding="0 16px"
        >
          <Box flexBasis="64px">&nbsp;</Box>
          {weekdays.map((weekday, index) => {
            if (
              currentDayIndex > index &&
              Math.abs(startDate.diff(selectedDate, "days").days) < 7
            ) {
              return (
                <Flexbox
                  flexDirection="column"
                  alignItems="center"
                  padding="16px"
                  key={weekday}
                  flexBasis="20%"
                  minWidth="143px"
                >
                  <Typography variant="h5">{weekday}</Typography>
                  <Typography variant="body1" color={gray[600]}>
                    {selectedDate
                      .minus({ days: currentDayIndex - index })
                      .toFormat("MMM d")}
                  </Typography>
                </Flexbox>
              );
            } else {
              const day = selectedDate.plus({ days: index - currentDayIndex });
              return (
                <Flexbox
                  flexDirection="column"
                  alignItems="center"
                  padding="16px"
                  key={weekday}
                  flexBasis="20%"
                  minWidth="143px"
                >
                  <Typography variant="h5">{weekday}</Typography>
                  <Typography variant="body1" color={gray[600]}>
                    {day.toFormat("MMM d")}
                  </Typography>
                </Flexbox>
              );
            }
          })}
        </Flexbox>

        {panelManagementIsFetching && <LoadingFallback count={15} />}

        {!panelManagementIsFetching && !panelManagementError && (
          <Box
            height={
              doesNotHaveAvailability.hasNoAvailability ? "244px" : "100%"
            }
            // only have the gray background if there are no slots
            bgcolor={
              doesNotHaveAvailability.hasNoAvailability ? gray[50] : "white"
            }
            borderRadius="0 0 12px 12px"
          >
            {doesNotHaveAvailability.hasNoAvailability ? (
              <Flexbox
                gap="20px"
                flexDirection="column"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Flexbox
                  width="44px"
                  height="44px"
                  bgcolor={gray[100]}
                  borderRadius="1000px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AlarmOffOutlined sx={{ color: gray[500] }} />
                </Flexbox>
                <Typography
                  variant="body1"
                  fontSize="16px"
                  lineHeight="24px"
                  sx={{ color: gray[600] }}
                >
                  No slots available at member preference time
                </Typography>
                <Typography
                  variant="body1"
                  fontSize="16px"
                  lineHeight="24px"
                  fontWeight="600"
                  sx={{ color: blue[700], cursor: "pointer" }}
                  onClick={() => onBack()}
                >
                  Adjust time preferences
                </Typography>
              </Flexbox>
            ) : (
              <>
                <Flexbox
                  margin="16px"
                  border={`1px solid ${gray[300]}`}
                  bgcolor={gray[50]}
                  borderRadius="12px"
                  padding="12px 0"
                >
                  <Typography
                    flexBasis="32px"
                    margin="0 16px"
                    sx={{
                      writingMode: "vertical-lr",
                      transform: "rotate(180deg)"
                    }}
                    display="flex"
                    justifyContent="center"
                    // flex-end because we are rotating 180 degrees
                    alignItems="flex-end"
                  >
                    Morning
                  </Typography>
                  <Flexbox width="100%" justifyContent="space-between">
                    {doesNotHaveAvailability.hasNoMorningAvailabilityThisWeek ? (
                      <Flexbox
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        data-testid="Morning No Slots"
                      >
                        No slots available at member preference time
                      </Flexbox>
                    ) : (
                      <>
                        {weekdays.map((weekday, index) => {
                          if (
                            currentDayIndex > index &&
                            Math.abs(
                              selectedDate.diff(startDate, "days").days
                            ) < 7
                          ) {
                            return (
                              <Flexbox
                                flexDirection="column"
                                alignItems="center"
                                padding="16px"
                                key={weekday}
                                flexBasis="20%"
                                minWidth="143px"
                              ></Flexbox>
                            );
                          } else {
                            const day = selectedDate.plus({
                              days: index - currentDayIndex
                            });
                            const availableSlots =
                              availabilityMap?.[day.toISODate()]?.["MORNING"];
                            return (
                              <Flexbox
                                flexDirection="column"
                                alignItems="center"
                                padding="16px"
                                key={weekday}
                                flexBasis="20%"
                                minWidth="143px"
                              >
                                <Flexbox gap="12px" flexDirection="column">
                                  {MORNING_SLOTS?.map((slot) => {
                                    const availability = availableSlots?.find(
                                      (av) =>
                                        DateTime.fromISO(av?.start)
                                          .setZone(memberTimezone)
                                          .toFormat("h:mm")
                                          ?.includes(
                                            slot?.availability?.start
                                          ) &&
                                        DateTime.fromISO(av?.end)
                                          .setZone(memberTimezone)
                                          .toFormat("h:mm")
                                          ?.includes(slot?.availability?.end)
                                    );

                                    if (isFalsy(availability)) {
                                      return (
                                        <Box
                                          key={`morning${slot.availability.start}`}
                                          height="50px"
                                        >
                                          &nbsp;
                                        </Box>
                                      );
                                    }

                                    const selectedButton =
                                      availability?.start === selectedStartDate;

                                    return (
                                      <Button
                                        variant="outlined"
                                        key={availability.start}
                                        sx={{
                                          width: "100%",
                                          height: "50px",
                                          backgroundColor: "white",
                                          ...(selectedButton && {
                                            backgroundColor: blue[700],
                                            color: "white"
                                          }),
                                          "&:hover": {
                                            ...(selectedButton && {
                                              backgroundColor: blue[700],
                                              color: "white"
                                            })
                                          }
                                        }}
                                        onClick={() => {
                                          dispatch(
                                            setSelectedTime({
                                              startDate: availability.start,
                                              endDate: availability.end,
                                              staffId:
                                                availability.staff.staff_id,
                                              staffName: `${availability.staff.first_name} ${availability.staff.last_name}`
                                            })
                                          );
                                        }}
                                      >
                                        <Box
                                          minWidth="max-content"
                                          id={`${DateTime.fromISO(
                                            availability?.start
                                          )
                                            .setZone(memberTimezone)
                                            .toFormat("h:mm")}-${weekday}`}
                                          data-testid={DateTime.fromISO(
                                            availability?.start
                                          )
                                            .setZone(memberTimezone)
                                            .toFormat("MM-dd")}
                                          aria-label="Available Date"
                                        >
                                          {DateTime.fromISO(availability.start)
                                            .setZone(memberTimezone)
                                            .toFormat("h:mm")}
                                          &nbsp;-&nbsp;
                                          {DateTime.fromISO(availability.end)
                                            .setZone(memberTimezone)
                                            .toFormat("h:mm a")}
                                        </Box>
                                      </Button>
                                    );
                                  })}
                                </Flexbox>
                              </Flexbox>
                            );
                          }
                        })}
                      </>
                    )}
                  </Flexbox>
                </Flexbox>
                <Flexbox
                  margin="16px"
                  border={`1px solid ${gray[300]}`}
                  bgcolor={gray[50]}
                  borderRadius="12px"
                  padding="12px 0"
                >
                  <Typography
                    flexBasis="32px"
                    margin="0 16px"
                    sx={{
                      writingMode: "vertical-lr",
                      transform: "rotate(180deg)"
                    }}
                    display="flex"
                    justifyContent="center"
                    // flex-end because we are rotating 180 degrees
                    alignItems="flex-end"
                  >
                    Mid-Day
                  </Typography>
                  <Flexbox width="100%" justifyContent="space-between">
                    {doesNotHaveAvailability.hasNoMidDayAvailabilityThisWeek ? (
                      <Flexbox
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        data-testid="Midday No Slots"
                      >
                        No slots available at member preference time
                      </Flexbox>
                    ) : (
                      <>
                        {weekdays.map((weekday, index) => {
                          if (
                            currentDayIndex > index &&
                            Math.abs(
                              selectedDate.diff(startDate, "days").days
                            ) < 7
                          ) {
                            return (
                              <Flexbox
                                flexDirection="column"
                                alignItems="center"
                                padding="16px"
                                key={weekday}
                                flexBasis="20%"
                                minWidth="143px"
                              ></Flexbox>
                            );
                          } else {
                            const day = selectedDate.plus({
                              days: index - currentDayIndex
                            });
                            const availableSlots =
                              availabilityMap?.[day.toISODate()]?.["MIDDAY"];
                            return (
                              <Flexbox
                                flexDirection="column"
                                alignItems="center"
                                padding="16px"
                                key={weekday}
                                flexBasis="20%"
                                minWidth="143px"
                              >
                                <Flexbox gap="12px" flexDirection="column">
                                  {MIDDAY_SLOTS?.map((slot) => {
                                    const availability = availableSlots?.find(
                                      (av) =>
                                        DateTime.fromISO(av?.start)
                                          .setZone(memberTimezone)
                                          .toFormat("h:mm")
                                          ?.includes(
                                            slot?.availability?.start
                                          ) &&
                                        DateTime.fromISO(av?.end)
                                          .setZone(memberTimezone)
                                          .toFormat("h:mm")
                                          ?.includes(slot?.availability?.end)
                                    );

                                    if (isFalsy(availability)) {
                                      return (
                                        <Box
                                          key={`midday${slot.availability.start}`}
                                          height="50px"
                                        >
                                          &nbsp;
                                        </Box>
                                      );
                                    }

                                    const selectedButton =
                                      availability?.start === selectedStartDate;

                                    return (
                                      <Button
                                        variant="outlined"
                                        key={availability.start}
                                        sx={{
                                          width: "100%",
                                          height: "50px",
                                          backgroundColor: "white",
                                          ...(selectedButton && {
                                            backgroundColor: blue[700],
                                            color: "white"
                                          }),
                                          "&:hover": {
                                            ...(selectedButton && {
                                              backgroundColor: blue[700],
                                              color: "white"
                                            })
                                          }
                                        }}
                                        onClick={() => {
                                          dispatch(
                                            setSelectedTime({
                                              startDate: availability.start,
                                              endDate: availability.end,
                                              staffId:
                                                availability.staff.staff_id,
                                              staffName: `${availability.staff.first_name} ${availability.staff.last_name}`
                                            })
                                          );
                                        }}
                                      >
                                        <Box
                                          minWidth="max-content"
                                          id={`${DateTime.fromISO(
                                            availability?.start
                                          )
                                            .setZone(memberTimezone)
                                            .toFormat("h:mm")}-${weekday}`}
                                          data-testid={DateTime.fromISO(
                                            availability?.start
                                          )
                                            .setZone(memberTimezone)
                                            .toFormat("MM-dd")}
                                          aria-label="Available Date"
                                        >
                                          {DateTime.fromISO(availability.start)
                                            .setZone(memberTimezone)
                                            .toFormat("h:mm")}
                                          &nbsp;-&nbsp;
                                          {DateTime.fromISO(availability.end)
                                            .setZone(memberTimezone)
                                            .toFormat("h:mm a")}
                                        </Box>
                                      </Button>
                                    );
                                  })}
                                </Flexbox>
                              </Flexbox>
                            );
                          }
                        })}
                      </>
                    )}
                  </Flexbox>
                </Flexbox>
                <Flexbox
                  margin="16px"
                  border={`1px solid ${gray[300]}`}
                  bgcolor={gray[50]}
                  borderRadius="12px"
                  padding="12px 0"
                >
                  <Typography
                    flexBasis="32px"
                    margin="0 16px"
                    sx={{
                      writingMode: "vertical-lr",
                      transform: "rotate(180deg)"
                    }}
                    display="flex"
                    justifyContent="center"
                    // flex-end because we are rotating 180 degrees
                    alignItems="flex-end"
                  >
                    Afternoon
                  </Typography>
                  <Flexbox width="100%" justifyContent="space-between">
                    {doesNotHaveAvailability.hasNoAfternoonAvailabilityThisWeek ? (
                      <Flexbox
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                        data-testid="Afternoon No Slots"
                      >
                        No slots available at member preference time
                      </Flexbox>
                    ) : (
                      <>
                        {weekdays.map((weekday, index) => {
                          if (
                            currentDayIndex > index &&
                            Math.abs(
                              selectedDate.diff(startDate, "days").days
                            ) < 7
                          ) {
                            return (
                              <Flexbox
                                flexDirection="column"
                                alignItems="center"
                                padding="16px"
                                key={weekday}
                                flexBasis="20%"
                                minWidth="143px"
                              ></Flexbox>
                            );
                          } else {
                            const day = selectedDate.plus({
                              days: index - currentDayIndex
                            });
                            const availableSlots =
                              availabilityMap?.[day.toISODate()]?.["AFTERNOON"];
                            return (
                              <Flexbox
                                flexDirection="column"
                                alignItems="center"
                                padding="16px"
                                key={weekday}
                                flexBasis="20%"
                                minWidth="143px"
                              >
                                <Flexbox gap="12px" flexDirection="column">
                                  {AFTERNOON_SLOTS?.map((slot) => {
                                    const availability = availableSlots?.find(
                                      (av) =>
                                        DateTime.fromISO(av?.start)
                                          .setZone(memberTimezone)
                                          .toFormat("h:mm")
                                          ?.includes(
                                            slot?.availability?.start
                                          ) &&
                                        DateTime.fromISO(av?.end)
                                          .setZone(memberTimezone)
                                          .toFormat("h:mm")
                                          ?.includes(slot?.availability?.end)
                                    );

                                    if (isFalsy(availability)) {
                                      return (
                                        <Box
                                          key={`afternoon${slot.availability.start}`}
                                          height="50px"
                                        >
                                          &nbsp;
                                        </Box>
                                      );
                                    }

                                    const selectedButton =
                                      availability?.start === selectedStartDate;

                                    return (
                                      <Button
                                        variant="outlined"
                                        key={availability.start}
                                        sx={{
                                          width: "100%",
                                          height: "50px",
                                          backgroundColor: "white",
                                          ...(selectedButton && {
                                            backgroundColor: blue[700],
                                            color: "white"
                                          }),
                                          "&:hover": {
                                            ...(selectedButton && {
                                              backgroundColor: blue[700],
                                              color: "white"
                                            })
                                          }
                                        }}
                                        onClick={() => {
                                          dispatch(
                                            setSelectedTime({
                                              startDate: availability.start,
                                              endDate: availability.end,
                                              staffId:
                                                availability.staff.staff_id,
                                              staffName: `${availability.staff.first_name} ${availability.staff.last_name}`
                                            })
                                          );
                                        }}
                                      >
                                        <Box
                                          minWidth="max-content"
                                          id={`${DateTime.fromISO(
                                            availability?.start
                                          )
                                            .setZone(memberTimezone)
                                            .toFormat("h:mm")}-${weekday}`}
                                          data-testid={DateTime.fromISO(
                                            availability?.start
                                          )
                                            .setZone(memberTimezone)
                                            .toFormat("MM-dd")}
                                          aria-label="Available Date"
                                        >
                                          {DateTime.fromISO(availability.start)
                                            .setZone(memberTimezone)
                                            .toFormat("h:mm")}
                                          &nbsp;-&nbsp;
                                          {DateTime.fromISO(availability.end)
                                            .setZone(memberTimezone)
                                            .toFormat("h:mm a")}
                                        </Box>
                                      </Button>
                                    );
                                  })}
                                </Flexbox>
                              </Flexbox>
                            );
                          }
                        })}
                      </>
                    )}
                  </Flexbox>
                </Flexbox>
              </>
            )}
          </Box>
        )}

        {panelManagementError && (
          <ErrorComponent error={panelManagementError} />
        )}
      </Box>
    </Box>
  );
};

export default Step3;
