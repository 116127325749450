import { styled } from "@mui/material/styles";
import {
  ComponentHeader,
  TableComponentContainer
} from "../styling/StyleComponents";

const HelpTextContainer = styled("div")`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const HelpHeading = styled(ComponentHeader)`
  margin-bottom: 15px;
`;

const SupportLink = styled("a")`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const supportEmail = "eng.support@copilotiq.com";

const Help = () => {
  return (
    <TableComponentContainer>
      <HelpTextContainer>
        <HelpHeading>Help</HelpHeading>
        Need help? Email us at&nbsp;
        <SupportLink href={`mailto:${supportEmail}`}>
          eng.support@copilotiq.com
        </SupportLink>
        !
      </HelpTextContainer>
    </TableComponentContainer>
  );
};

export default Help;
