import styled from "@emotion/styled";
import Table from "../Table/Table";
import { TableColumn } from "../Table/TableTypes";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import MemberType from "common/types/MemberType";
import ErrorComponent from "../ErrorComponent";
import { useSelector } from "react-redux";
import { RootState } from "common/redux";
import ErrorType from "common/types/ErrorType";

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const StyledErrorComponent = styled(ErrorComponent)`
  margin: 24px 2.5%;
`;

interface IProps {
  data: MemberType[] | undefined;
  dataLoading: boolean;
  dataError: ErrorType;
  tableColumns: TableColumn[];
  externalLink?: string;
  setModalStateCallback?: (val: string, errorMessage?: string) => void;
  setSelectedPatientIdCallback?: (val: string) => void;
  setAssignNursePatientResponseCallback?: (val: MemberType) => void;
  setProviderModalStateCallback?: (val: string) => void;
  setAssignProviderPatientResponseCallback?: (val: MemberType) => void;
}

function CustomSearchTable({
  data,
  dataLoading,
  dataError,
  tableColumns,
  externalLink,
  setModalStateCallback,
  setSelectedPatientIdCallback,
  setAssignNursePatientResponseCallback,
  setProviderModalStateCallback,
  setAssignProviderPatientResponseCallback
}: IProps) {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  return (
    <OuterContainer>
      {dataLoading ? (
        <LoadingFallback count={10} />
      ) : (
        <>
          {data && data.length > 0 && (
            <Table
              data={data}
              tableProps={{
                currentRole,
                setSelectedPatientIdCallback,
                setAssignNursePatientResponseCallback,
                setProviderModalStateCallback,
                setAssignProviderPatientResponseCallback,
                externalLink,
                setModalStateCallback
              }}
              tableColumns={tableColumns}
            />
          )}
          {data && data.length === 0 && (
            <StyledErrorComponent error={"No results found."} />
          )}

          {dataError && <ErrorComponent error={dataError} />}
        </>
      )}
    </OuterContainer>
  );
}

export default CustomSearchTable;
