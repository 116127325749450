import { AxiosResponse } from "axios";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import AthenaUserType from "../types/AthenaUserType";
import MemberType from "../types/MemberType";
import SalesforceAccountType from "../types/SalesforceAccountType";
import UserType from "../types/UserType";
import { apiMemberRegistration } from "./AxiosService";
import { memberService } from "./MemberService";
import AddressType from "../types/AddressType";
import { PromiseWithKnownReason } from "@reduxjs/toolkit/dist/query/core/buildMiddleware/types";
import { AppDispatch } from "../redux";
import RegisterResponseType from "../types/RegisterResponseType";
import CarersType from "../types/CarersType";
import MemberTypeInner from "../types/common/MemberTypeInner";
import MemberStatusEnum from "../enums/MemberStatusEnum";
import MemberStatusReasonEnum from "../enums/MemberStatusReasonEnum";
import { DateTime } from "luxon";
import OnboardingAppointmentType from "../types/OnboardingAppointmentType";
import MemberContactInfoType from "../types/common/MemberContactInfoType";

interface RegisterStatusType {
  leads_id: string;
  creation_timestamp: number;
  sm_uuid: string;
  return_code: number;
  leads_status_message: string;
  state: string;
  athena_links: string[];
}

interface ClearCacheProps {
  queryFulfilled: PromiseWithKnownReason<any, any>;
  dispatch: AppDispatch;
  id: string;
}

async function clearCache(
  queryFulfilled: PromiseWithKnownReason<any, any>,
  dispatch: AppDispatch,
  id: string
) {
  try {
    await queryFulfilled;
    setTimeout(() => {
      dispatch(
        memberService.util.invalidateTags([
          ReduxTagEnum.Member,
          { type: ReduxTagEnum.Member, id }
        ])
      );
    }, 3000);
  } catch (error) {}
}

const memberRegistrationService = apiMemberRegistration
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Member]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getRegisterStatus: builder.query<RegisterStatusType, string>({
        query: (leadId: string) => {
          return { url: `/register/status/${leadId}`, method: "GET" };
        },
        providesTags: [ReduxTagEnum.Member]
      }),
      getAthenaMemberDetails: builder.query<AthenaUserType, Omit<string, "id">>(
        {
          query: (athenaMemberId) => ({
            url: `/athena/patient/${athenaMemberId}`,
            method: "GET"
          }),
          providesTags: [ReduxTagEnum.Member]
        }
      ),
      getSalesforceAccount: builder.query<SalesforceAccountType, string>({
        query: (salesforceId: string) => {
          return { url: `/salesforce/account/${salesforceId}`, method: "GET" };
        },
        providesTags: [ReduxTagEnum.Member]
      }),
      getOnboardingAppointment: builder.query<any, { athena_id: number }>({
        query: ({ athena_id }) => ({
          url: `/athena/patient/${athena_id}/onboarding-appointment`,
          method: "GET"
        })
      }),
      // Mutations
      // we need this api call as a mutation so we can have a reset function to reset stale state
      getSalesforceLead: builder.mutation<SalesforceAccountType, string>({
        query: (salesforceId: string) => {
          return { url: `/salesforce/lead/${salesforceId}`, method: "GET" };
        },
        invalidatesTags: [ReduxTagEnum.Member]
      }),
      registerMember: builder.mutation<
        RegisterResponseType,
        Omit<{ body: any }, "id">
      >({
        query: ({ body }) => ({
          url: `/register`,
          method: "POST",
          data: body
        }),
        invalidatesTags: [ReduxTagEnum.Member]
      }),

      registerMigrated: builder.mutation<UserType, Omit<{ body: any }, "id">>({
        query: ({ body }) => ({
          url: `/register_migrated`,
          method: "POST",
          data: body,
          validateStatus: (response: AxiosResponse<any, any>) => {
            return response.status === 201 || response.status === 200;
          }
        }),
        invalidatesTags: [ReduxTagEnum.Member]
      }),
      updateMemberStatus: builder.mutation<
        MemberTypeInner,
        Omit<
          {
            patient_id: string;
            status: MemberStatusEnum;
            status_reason?: MemberStatusReasonEnum;
            status_effective_date?: DateTime;
          },
          "id"
        >
      >({
        query: ({
          patient_id,
          status,
          status_reason = MemberStatusReasonEnum.CANCELED_RETENTION,
          status_effective_date
        }) => ({
          url: `/patients/${patient_id}/status/${status}`,
          method: "PUT",
          data: {
            status_reason,
            status_effective_date: status_effective_date
              ? status_effective_date.toISO()
              : undefined
          }
        }),
        invalidatesTags: [ReduxTagEnum.Member],
        async onQueryStarted(
          { patient_id, status, status_reason, status_effective_date },
          { dispatch, queryFulfilled }
        ) {
          clearCache(queryFulfilled, dispatch, patient_id);
        }
      }),
      updateMemberDetails: builder.mutation<
        MemberType,
        Omit<{ patient_id: string; patient: Partial<MemberTypeInner> }, "id">
      >({
        query: ({ patient_id, patient }) => ({
          url: `/patients/${patient_id}/details`,
          method: "PUT",
          data: patient
        }),
        invalidatesTags: [ReduxTagEnum.Member],
        async onQueryStarted(
          { patient_id, patient },
          { dispatch, queryFulfilled }
        ) {
          clearCache(queryFulfilled, dispatch, patient_id);
        }
      }),
      updateMemberCarers: builder.mutation<
        MemberType,
        Omit<{ patient_id: string; carers: Partial<CarersType> }, "id">
      >({
        query: ({ patient_id, carers }) => ({
          url: `/patients/${patient_id}/carers`,
          method: "PUT",
          data: carers
        }),
        invalidatesTags: [ReduxTagEnum.Member],
        async onQueryStarted(
          { patient_id, carers },
          { dispatch, queryFulfilled }
        ) {
          clearCache(queryFulfilled, dispatch, patient_id);
        }
      }),
      updateMemberData: builder.mutation<
        MemberType,
        Omit<
          {
            member_id: string;
            address?: Partial<AddressType>;
            contact_info?: Partial<MemberContactInfoType>;
          },
          "id"
        >
      >({
        query: ({ member_id, address, contact_info }) => ({
          url: `/patients/${member_id}/details`,
          method: "PUT",
          data: { address, contact_info }
        }),
        invalidatesTags: [ReduxTagEnum.Member],
        async onQueryStarted({ member_id }, { dispatch, queryFulfilled }) {
          clearCache(queryFulfilled, dispatch, member_id);
        }
      }),
      createOnboardingAppointment: builder.mutation<
        OnboardingAppointmentType,
        { athena_id: string }
      >({
        query: ({ athena_id }) => ({
          url: `/athena/patient/${athena_id}/onboarding-appointment`,
          method: "POST"
        })
      }),
      startOnboardingAppointment: builder.mutation<
        OnboardingAppointmentType,
        { body: any; athena_id: number }
      >({
        query: ({ athena_id, body }) => ({
          url: `/athena/patient/${athena_id}/onboarding-appointment`,
          method: "PUT",
          data: body
        })
      })
    })
  });

export const {
  useGetRegisterStatusQuery,
  useLazyGetRegisterStatusQuery,
  useGetAthenaMemberDetailsQuery,
  useLazyGetAthenaMemberDetailsQuery,
  useRegisterMemberMutation,
  useGetSalesforceAccountQuery,
  useLazyGetSalesforceAccountQuery,
  useGetSalesforceLeadMutation,
  useRegisterMigratedMutation,
  useUpdateMemberStatusMutation,
  useUpdateMemberDetailsMutation,
  useUpdateMemberCarersMutation,
  useUpdateMemberDataMutation,
  useGetOnboardingAppointmentQuery,
  useCreateOnboardingAppointmentMutation,
  useStartOnboardingAppointmentMutation
} = memberRegistrationService;
