import { DateTime } from "luxon";
import { AugmentedDateTime } from "./AugmentedDateTime/index";

/**
 * Adds x business days to a user-specified date
 * @param {DateTime} date
 * @param {number} days
 * @returns {DateTime} Returns a new DateTime
 */

export function plusBusinessDays(date: DateTime, days: number) {
  try {
    // convert to JS date and back so we have Typescript support and type completion for our DateTimes, instead of using the AugmentedDateTime prototype
    const convertedOriginalDate = date.toJSDate();
    const augmentedDate: InstanceType<typeof AugmentedDateTime> =
      AugmentedDateTime.fromJSDate(convertedOriginalDate);
    const calculatedDate = augmentedDate.plusBusiness({ days });
    const finalDate = DateTime.fromJSDate(calculatedDate.toJSDate());
    return finalDate;
  } catch (error) {
    console.error("Error in plusBusinessDays: ", error);
    return date;
  }
}

/**
 * Subtracts x business days to a user-specified date
 * @param {DateTime} date
 * @param {number} days
 * @returns {DateTime} Returns a new DateTime
 */

export function minusBusinessDays(date: DateTime, days: number) {
  try {
    // convert to JS date and back so we have Typescript support and type completion for our DateTimes, instead of using the AugmentedDateTime prototype
    const convertedOriginalDate = date.toJSDate();
    const augmentedDate: InstanceType<typeof AugmentedDateTime> =
      AugmentedDateTime.fromJSDate(convertedOriginalDate);
    const calculatedDate = augmentedDate.minusBusiness({ days });
    const finalDate = DateTime.fromJSDate(calculatedDate.toJSDate());
    return finalDate;
  } catch (error) {
    console.error("Error in minusBusinessDays: ", error);
    return date;
  }
}

/**
 * Returns true if the specified date is a business day
 * @param {DateTime} date
 * @returns {boolean}
 */

export function isBusinessDay(date: DateTime) {
  try {
    const convertedOriginalDate = date.toJSDate();
    const augmentedDate: InstanceType<typeof AugmentedDateTime> =
      AugmentedDateTime.fromJSDate(convertedOriginalDate);
    return augmentedDate.isBusinessDay() && !augmentedDate.isHoliday();
  } catch (error) {
    console.error("Error in isBusinessDay: ", error);
    return false;
  }
}

/**
 * Returns the count of business days within a date range
 * @param {DateTime} startdate
 * @param {DateTime} enddate
 * @param {number} count default is 0, we use this to count the business days recursively
 * @returns {number} count of business days
 */

export function affectedBusinessDays(
  startdate: DateTime,
  enddate: DateTime,
  count: number = 0
) {
  if (!startdate?.isValid || !enddate?.isValid) {
    return 0;
  }

  if (enddate.diff(startdate, "days").days > 600) {
    // We should not be trying to calculate more than 600 days
    return "many";
  }

  if (startdate > enddate) {
    return count;
  }
  if (isBusinessDay(startdate)) {
    count++;
    const newStartDate = plusBusinessDays(startdate, 1);
    return affectedBusinessDays(newStartDate, enddate, count);
  } else {
    const newStartDate = plusBusinessDays(startdate, 1);
    return affectedBusinessDays(newStartDate, enddate, count);
  }
}
