import { MouseEvent, KeyboardEvent, ReactNode, ReactElement } from "react";
import styled from "@emotion/styled";
import { Modal as ModalMUI } from "@mui/material";

interface IProps {
  isOpen: boolean;
  onRequestClose?: (event: MouseEvent | KeyboardEvent) => void;
  contentLabel: string;
  modalHeight?: string;
  modalWidth?: string;
  minWidth?: string;
  shouldCloseOnEsc?: boolean;
  children: ReactNode;
  closeOnBackdropClick?: boolean;
}

const ModalContainer = styled("div")<{
  modalHeight: string;
  modalWidth?: string;
  minWidth?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 50%;
  min-width: ${(props) => props.minWidth ?? "300px"};
  border-radius: 4px;
  background: white;
  transition: all 0.66s ease-out;
  height: ${(props) => props.modalHeight ?? "70vh"};
  width: ${(props) => props.modalWidth ?? "50%"};
  // https://github.com/mui/material-ui/issues/11504#issuecomment-390506409
  outline: 0;
`;

const ModalMUIStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderWidth: 0
};

const Modal = ({
  isOpen,
  onRequestClose,
  contentLabel,
  shouldCloseOnEsc = false,
  modalHeight,
  modalWidth,
  minWidth,
  closeOnBackdropClick = false,
  children
}: IProps) => {
  return (
    <ModalMUI
      open={isOpen}
      onClose={function (event: object, reason: string) {
        // we want to do nothing if the backdrop is clicked
        if (reason !== "backdropClick") {
          onRequestClose(event as MouseEvent);
        }
        if (closeOnBackdropClick) {
          onRequestClose(event as MouseEvent);
        }
      }}
      aria-label={contentLabel}
      disableEscapeKeyDown={!shouldCloseOnEsc}
      sx={ModalMUIStyle}
    >
      <ModalContainer
        modalHeight={modalHeight}
        modalWidth={modalWidth}
        minWidth={minWidth}
      >
        {children as ReactElement}
      </ModalContainer>
    </ModalMUI>
  );
};

export default Modal;
