import { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import { TurqoiseButton, WhiteButton } from "../../../styling";
import { ModalFooter, ModalFooterButtons } from "../../../styling/StyleModal";
import { MenuItem, Typography } from "@mui/material";
import ErrorComponent from "../../../components/ErrorComponent";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import { Flexbox } from "../../../styling/NewStyleComponents";
import { TextFieldComponent } from "../../../helpers/components/Forms/FormHelpers";
import useGetDelayedLoadingBoolean from "common/hooks/useGetDelayedLoadingBoolean";
import {
  useCreateVisitMutation,
  useUpdateVisitDispositionMutation
} from "common/services/VisitsService";
import VisitDispositionEnum from "common/enums/Calendaring/Visits/VisitDispositionEnum";
import VisitMotivationTypesEnum from "common/enums/Calendaring/Visits/VisitMotivationTypesEnum";
import CalendarEventResponseType from "common/types/Calendaring/CalendarEventResponseType";
import { DELAY_AFTER_REQUEST_COMPLETED } from "common/services/CalendarService";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import EnvVars from "common/config/EnvVars";

const updateTypeOptions =
  EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "prod"
    ? [
        { value: VisitDispositionEnum.COMPLETED, label: "Completed" },
        { value: VisitDispositionEnum.NO_SHOW, label: "No show" },
        {
          value: VisitDispositionEnum.TN_OOO,
          label: "Visit Not Attempted - TN OOO"
        },
        {
          value: VisitDispositionEnum.NO_CALL,
          label: "Visit Not Attempted - TN Didn't Call"
        }
      ]
    : [
        { value: VisitDispositionEnum.COMPLETED, label: "Completed" },
        { value: VisitDispositionEnum.NO_SHOW, label: "No show" }
      ];

const StyledWhiteButton = styled(WhiteButton)`
  display: flex;
`;

const Form = styled.form`
  height: 100%;
`;
interface FormValues {
  updateType: VisitDispositionEnum | undefined;
}

interface IProps {
  visit?: GetVisitResponseType;
  eventId: string;
  attendeeId: string;
  staff_id: string;
  event?: CalendarEventResponseType;
}

const initialValues: FormValues = {
  updateType: undefined
};

const UpdateAppointmentOutcomeForm = ({
  visit,
  eventId,
  attendeeId,
  staff_id,
  event
}: IProps) => {
  const dispatch = useAppDispatch();

  const timerRef = useRef(null);

  /* tbd remove once backend handles this ENG-4681 */
  const [
    createVisitMutation,
    {
      error: createVisitError,
      isSuccess: createVisitIsSuccess,
      isLoading: createVisitLoading,
      fulfilledTimeStamp: createVisitFulfilledTimestamp
    }
  ] = useCreateVisitMutation();

  const [
    updateVisitMutation,
    {
      error: updateVisitError,
      isSuccess: updateVisitIsSuccess,
      isLoading: updateVisitLoading,
      fulfilledTimeStamp: updateVisitFulfilledTimestamp
    }
  ] = useUpdateVisitDispositionMutation();

  useEffect(() => {
    if (updateVisitIsSuccess || createVisitIsSuccess) {
      timerRef.current = setTimeout(() => {
        Alert_close({ dispatch, id: "updateAppointmentOutcome" });
        const id = "updateAppointmentSuccess";
        formik.resetForm();
        Alert_show({
          dispatch,
          id,
          title: "Success",
          content: "Updated appointment successfully.",
          type: "success",
          size: "small",
          buttons: [
            {
              text: "Close",
              onPress: () => {
                Alert_close({ dispatch, id });
              }
            }
          ]
        });
      }, DELAY_AFTER_REQUEST_COMPLETED);
    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [updateVisitIsSuccess, createVisitIsSuccess]);

  useEffect(() => {
    if (updateVisitError) {
      Alert_close({ dispatch, id: "updateAppointmentOutcome" });
      formik.resetForm();
      const id = "updateAppointmentOutcomeError";
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={updateVisitError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [updateVisitError]);

  /* tbd remove once backend handles this ENG-4681 */
  useEffect(() => {
    if (createVisitError) {
      Alert_close({ dispatch, id: "updateAppointmentOutcome" });
      formik.resetForm();
      const id = "updateAppointmentOutcomeError";
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={createVisitError} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [createVisitError]);

  const validate = (values: FormValues) => {
    const errors = {};
    if (!values.updateType) {
      errors["updateType"] = "Required";
    }
    return errors;
  };

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value).catch(() => {
      return;
    });
  };

  const onSubmit = async (values: FormValues) => {
    const { updateType } = values;

    if (eventId) {
      await updateVisitMutation({
        visit_id: eventId,
        staff_id,
        patient_id: attendeeId,
        disposition: updateType
      });
    } else {
      /* tbd remove once backend handles this ENG-4681 */
      await createVisitMutation({
        staff_id,
        patient_id: attendeeId,
        body: {
          calendar_event_start: event.startdate,
          calendar_event_end: event.enddate,
          //   // change to event_id with this ticket ENG-4654
          calendar_id: event.event_id,
          patient_id: attendeeId,
          staff_id,
          motivation_reason: VisitMotivationTypesEnum.APPOINTMENT,
          disposition: updateType
        }
      });
    }
  };

  const formik = useFormik<FormValues>({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit
  });

  const handleModalClose = () => {
    formik.resetForm();
    Alert_close({ dispatch, id: "updateAppointmentOutcome" });
  };

  const isUpdateLoading = useGetDelayedLoadingBoolean(
    updateVisitLoading,
    updateVisitFulfilledTimestamp,
    updateVisitIsSuccess,
    DELAY_AFTER_REQUEST_COMPLETED
  );

  /* tbd remove once backend handles this ENG-4681 */
  const isCreateLoading = useGetDelayedLoadingBoolean(
    createVisitLoading,
    createVisitFulfilledTimestamp,
    createVisitIsSuccess,
    DELAY_AFTER_REQUEST_COMPLETED
  );

  return (
    <Flexbox
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Flexbox
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Flexbox gap="24px" flexDirection="column">
            <Typography variant="h5" color="text.primary">
              Appointment Outcome
            </Typography>

            <TextFieldComponent
              sx={{ mb: "8px" }}
              formik={formik}
              fieldName={"updateType"}
              label={null}
              select={true}
              slotProps={{
                select: {
                  variant: "outlined",
                  MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
                }
              }}
              placeholder={null}
              fullWidth={true}
              setFieldValue={setFieldValue}
            >
              {updateTypeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextFieldComponent>
          </Flexbox>

          <ModalFooter>
            <ModalFooterButtons margin="0">
              <TurqoiseButton
                type="submit"
                loading={isUpdateLoading || isCreateLoading}
                disabled={!formik.values.updateType}
              >
                Confirm
              </TurqoiseButton>
              <StyledWhiteButton onClick={handleModalClose}>
                Cancel
              </StyledWhiteButton>
            </ModalFooterButtons>
          </ModalFooter>
        </Flexbox>
      </Form>
    </Flexbox>
  );
};

export { UpdateAppointmentOutcomeForm };
