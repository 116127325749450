import localStorage from "redux-persist/lib/storage";

const getReduxStorage = () => {
  return localStorage;
};

const getItem = (key: string) => {
  return new Promise<string | null>((resolve) =>
    resolve(localStorage.getItem(key))
  );
};

const setItem = (key: string, value: string) => {
  return new Promise<void>((resolve) => {
    if (value === null || value === undefined) localStorage.removeItem(key);
    else resolve(localStorage.setItem(key, value));
  });
};

const removeItem = (key: string) => {
  return new Promise<void>((resolve) => resolve(localStorage.removeItem(key)));
};

export default { getItem, setItem, removeItem, getReduxStorage };
