import { forwardRef, Ref, useImperativeHandle, useMemo } from "react";
import { CircularProgress, Typography } from "@mui/material";

import { DateTime } from "luxon";

import { useGetTimeEnteredByRoleQuery } from "common/services/ReportsService";
import RolesEnum, { getRoleLabel } from "common/enums/RolesEnum";

import ReportRefInterface from "./ReportRefInterface";
import Table from "../../../components/Table/Table";
import { ErrorText } from "../../../styling";
import ErrorComponent from "../../../components/ErrorComponent";
import {
  getHoursMinutesFromMinutes,
  getNameOrUsername
} from "common/helpers/helpers";
import DropdownType from "../../../types/DropdownType";
import styled from "@emotion/styled";
import UserStatusEnum from "common/enums/UserStatusEnum";

const summaryColumns = [
  { name: "default", id: "role", accessor: "role", header: "Role" },
  {
    name: "default",
    id: "intake_time",
    accessor: "intake_time",
    header: "Total Time (in minutes)",
    size: 220
  }
];

const detailedColumns = [
  {
    name: "default",
    id: "nurse",
    accessor: "fullname",
    header: "Staff Member",
    size: 200
  },
  {
    name: "fullname",
    id: "intake_time",
    accessor: "intake_time",
    header: "Total Time (in minutes)",
    size: 220
  },
  {
    name: "reportMemberCount",
    size: 200
  }
];

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  dropdownSelection: DropdownType[];
  checkboxChecked?: boolean;
}

const ReportTimeByINs = forwardRef(
  (
    { startDate, endDate, dropdownSelection, checkboxChecked }: IProps,
    ref: Ref<ReportRefInterface>
  ) => {
    const selectedRoles = useMemo(() => {
      if (dropdownSelection === undefined) return [];
      return dropdownSelection.map((item) => item.value as RolesEnum);
    }, [dropdownSelection]);

    const { data, isLoading, isError, isSuccess, error } =
      useGetTimeEnteredByRoleQuery(
        {
          roles: selectedRoles,
          startsBefore: endDate.setZone("local").endOf("day"),
          startsAfter: startDate.setZone("local").startOf("day"),
          // if checkbox is not checked, we want to filter out carers who are inactive
          ...(!checkboxChecked && { carer_status: UserStatusEnum.ACTIVE })
        },
        { skip: selectedRoles.length === 0 }
      );

    const sortedData = useMemo(() => {
      if (data === undefined) return undefined;
      return [...data]
        .filter((item) => item.intake_time !== 0)
        .sort((a, b) => {
          return b.intake_time - a.intake_time;
        });
    }, [data]);

    const summaryData = useMemo(() => {
      if (data === undefined) return undefined;
      const hash = {};
      data
        // filter out the carers with 0 intake time
        .filter((item) => item.intake_time !== 0)
        .forEach((item) => {
          const { intake_time, carer } = item;
          carer?.roles?.forEach((role) => {
            if (hash[role] === undefined) hash[role] = intake_time;
            else hash[role] = hash[role] + intake_time;
          });
        });

      return Object.keys(hash)
        .filter(
          // https://copilotiq.atlassian.net/browse/ENG-5178
          (key) => selectedRoles.includes(key as RolesEnum)
        )
        .map((key) => {
          return {
            role: getRoleLabel(key as RolesEnum),
            intake_time: hash[key]
          };
        })
        .sort((a, b) => {
          return b.intake_time - a.intake_time;
        });
    }, [data]);

    const { totalTime, patientCount } = useMemo(() => {
      if (data === undefined) return { totalTime: 0, patientCount: 0 };

      const totalTime = data
        ?.filter((item) =>
          item?.carer?.roles?.some((role) => selectedRoles.includes(role))
        )
        ?.reduce((prevValue, currentValue) => {
          return prevValue + currentValue.intake_time;
        }, 0);

      const patientCount = data
        ?.filter(
          (item) =>
            item?.carer?.roles?.some((role) => selectedRoles.includes(role)) &&
            // filter out the carers with 0 intake time
            item.intake_time !== 0
        )
        ?.reduce((prevValue, currentValue) => {
          return prevValue + currentValue.patient_count;
        }, 0);

      return { totalTime, patientCount };
    }, [data]);

    useImperativeHandle(ref, () => ({
      getCSVReportData() {
        const formattedData = sortedData?.map((item) => {
          const formattedRoles = item.carer.roles
            .map((role) => {
              return getRoleLabel(role);
            })
            .join(", ");

          return [
            getNameOrUsername(item.carer),
            item.carer.user_id,
            item.intake_time.toString(),
            formattedRoles
          ];
        });

        return {
          filename: "time_entered_by_ins",
          columns: ["Staff Member", "User Id", "Total Time (min)", "Roles"],
          data: formattedData
        };
      },
      getReportData: () => {
        if (selectedRoles.length === 0) return;
        if (summaryData === undefined) return;

        const summaryDataTable = summaryData.map((item) => {
          return [item.role, item.intake_time.toString()];
        });

        const detailedDataTable = sortedData?.map((item) => {
          return [
            getNameOrUsername(item.carer),
            item.intake_time.toString(),
            item.patient_count.toString()
          ];
        });

        return [
          {
            title: "Summary by Role",
            columnNames: ["Role", "Total Time (in minutes)"],
            data: summaryDataTable,
            reportSummary: [
              `Total Time: ${getHoursMinutesFromMinutes(totalTime)?.hours} hours ${getHoursMinutesFromMinutes(totalTime)?.minutes} minutes`,
              `Total Member Count: ${patientCount}`
            ]
          },
          {
            title: "Detailed",
            columnNames: ["Nurse", "Total Time (in minutes)", "Member Count"],
            data: detailedDataTable
          }
        ];
      }
    }));

    return (
      <>
        {selectedRoles.length > 0 &&
          sortedData &&
          sortedData.length > 0 &&
          summaryData && (
            <>
              <Typography variant="h3">Summary by Role</Typography>
              <Table
                tableColumns={summaryColumns}
                data={summaryData}
                tableMaxHeight={"none"}
              />
              <Typography variant="body1" color="text.secondary">
                {`Total Time: ${getHoursMinutesFromMinutes(totalTime)?.hours} hours ${getHoursMinutesFromMinutes(totalTime)?.minutes} minutes`}
                <br />
                {`Total Member Count: ${patientCount}`}
              </Typography>

              <br />
              <br />
              <Row>
                <Typography variant="h3">Detailed</Typography>
              </Row>
              <Table
                tableColumns={detailedColumns}
                data={sortedData}
                tableMaxHeight={"none"}
              />
            </>
          )}
        {isLoading && <CircularProgress />}
        {isSuccess && data && data.length === 0 && (
          <ErrorText>No data found.</ErrorText>
        )}
        {isError && <ErrorComponent error={error} />}
      </>
    );
  }
);

ReportTimeByINs.displayName = "ReportTimeByINs";

export default ReportTimeByINs;
