import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Link, useNavigate } from "react-router-dom";

import { DownChevronIcon, DangerIcon } from "../../assets/images/icons";
import debounce from "debounce";
import UserSearchInput from "../UserSearchInput/UserSearchInput";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "common/redux";
import { logOut, setCurrentRole } from "common/redux/AuthSlice";

import UserSearchResultContainer from "../UserSearchInput/UserSearchResultContainer";
import { isFalsy } from "common/helpers/helpers";
import { useGetTasksCountQuery } from "common/services/TaskingService";
import { Badge, Box, Typography, useTheme } from "@mui/material";
import { CustomTooltip } from "../../styling/StyleComponents";
import ErrorComponent from "../ErrorComponent";
import RolesEnum, {
  canSeeBillingTasks,
  canSeeTasks,
  getRoleLabel
} from "common/enums/RolesEnum";
import { Flexbox } from "../../styling/NewStyleComponents";
import { blue, gray } from "../../styling/colors";
import { CopilotIQLogo } from "../../assets/images";
import StyledIconButton from "../Button/StyledIconButton";
import { NotificationsNone } from "@mui/icons-material";

import EnvVars from "common/config/EnvVars";

const isDev = () => {
  return (
    EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "prod" &&
    EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "staging"
  );
};

export const NAV_BAR_HEIGHT = "70px";

const StyledLink = styled(Link)`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 35px;
`;

const CopilotLogoImg = styled(CopilotIQLogo)`
  width: 80px;
  height: 80px;
`;

const NavBarOuterContainer = styled.div`
  z-index: 1001;
  display: flex;
  justify-content: space-between;
  height: ${NAV_BAR_HEIGHT};
  position: fixed;
  width: 100%;
  background: ${isDev() ? "#eaea7d" : "white"};
  font-size: 20px;
  line-height: 20px;
  border-bottom: solid 2px;
  border-bottom-color: ${gray[300]};
`;

const LeftSideContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: 20px;
`;

const RightSideContainer = styled.div`
  display: flex;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const UserInfoContainer = styled.div`
  display: flex;
  position: relative;
  margin-right: 15px;
  align-items: center;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
  margin: 10px;
`;

const UserInfoTextContainer = styled.div`
  margin: 0px 15px;
`;

const UserName = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #000000;
`;

const UserRole = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #8f8f8f;
`;

const DownChevronImg = styled(DownChevronIcon)`
  position: relative;
  width: 24px;
  display: flex;
  align-self: center;
`;

const UserInfoDropdown = styled.div`
  position: absolute;
  top: 45px;
  background: white;
  border-radius: 5px;
  filter: drop-shadow(0px 2px 14px rgba(38, 55, 70, 0.28));
  width: 160px;
  right: 0;
  height: min-content;
  max-height: calc(100vh - 70px);
  overflow-y: scroll;
`;

const UserInfoDropdownItem = styled.div`
  color: ${(props) => props.theme.color.mediumDarkGrey};
  padding: 7% 8%;
  font-family: ${(props) => props.theme.font.family};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;

  &:hover {
    color: ${blue[700]};
    cursor: pointer;
    background: ${blue[50]};
  }
`;

const DropdownLink = styled(Link)`
  text-decoration: none;
`;

const StyledDangerIcon = styled(DangerIcon)`
  width: 23px;
  height: 23px;
`;

function TaskNotification({
  assignee_id,
  currentRole
}: {
  assignee_id: string;
  currentRole: RolesEnum;
}) {
  const { data, error, isError, isSuccess } = useGetTasksCountQuery(
    { assignee_id, shouldShowBillingTasks: canSeeBillingTasks(currentRole) },
    { skip: isFalsy(assignee_id) }
  );
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Flexbox alignItems="center" gap="4px">
      {isSuccess && "TODO" in data && (
        <Badge
          color="error"
          badgeContent={data?.TODO}
          invisible={data?.TODO <= 0}
        >
          <StyledIconButton
            iconColor={theme.palette.primary.main}
            border="square"
            Icon={NotificationsNone}
            onClick={() => navigate("/tasks")}
          />
        </Badge>
      )}
      {isError && (
        <CustomTooltip
          backgroundColor="#ffffff"
          title={<ErrorComponent error={error} />}
        >
          <StyledDangerIcon />
        </CustomTooltip>
      )}
    </Flexbox>
  );
}

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    currentRole,
    roles,
    user: loggedInUser
  } = useSelector((state: RootState) => state.auth);

  const [showUserInfo, setShowUserInfo] = useState(false);

  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <NavBarOuterContainer>
      <StyledLink to={"/"} aria-label="CopilotIQ Home">
        <CopilotLogoImg />
      </StyledLink>

      <LeftSideContainer>
        <UserSearchInput key="navbar-search" clearOnClickOutside={true}>
          <UserSearchResultContainer
            clearHandler={function (): void {}}
            data={[]}
          />
        </UserSearchInput>
        {isDev() && (
          <Typography variant="h1" alignSelf={"center"} mr={"20px"}>
            DEV
          </Typography>
        )}
      </LeftSideContainer>
      <RightSideContainer>
        <Row>
          {canSeeTasks(currentRole) && loggedInUser && (
            <TaskNotification
              currentRole={currentRole}
              assignee_id={loggedInUser?.user_id}
            />
          )}
        </Row>
        <UserInfoContainer
          onMouseEnter={() => {
            setShowUserInfo(true);
          }}
          onMouseLeave={debounce(() => {
            setShowUserInfo(false);
          }, 100)}
        >
          {/* <UserAvatarContainer /> */}
          <UserInfoTextContainer>
            <UserName data-testid="NavBar-username">
              {loggedInUser?.fullname}
            </UserName>
            <UserRole data-testid="NavBar-userrole">
              {getRoleLabel(currentRole)}
            </UserRole>
          </UserInfoTextContainer>
          <DownChevronImg
          //alt="down chevron icon"
          />
          {showUserInfo && (
            <UserInfoDropdown>
              <Box style={{ height: "100%" }}>
                {roles && roles?.length > 1 && (
                  <>
                    {roles.map((role) => {
                      // Don't show option to switch to the same role
                      if (role === currentRole) {
                        return null;
                      }
                      return (
                        <UserInfoDropdownItem
                          key={role}
                          onClick={() => {
                            dispatch(setCurrentRole(role));
                            setShowUserInfo(false);
                            // we need this timeout to reload the route and show/hide the proper component from route config
                            timerRef.current = setTimeout(() => {
                              navigate(0);
                            }, 50);
                          }}
                        >
                          Switch to {getRoleLabel(role)}
                        </UserInfoDropdownItem>
                      );
                    })}
                  </>
                )}
                <DropdownLink to="/my-profile">
                  <UserInfoDropdownItem>My Profile</UserInfoDropdownItem>
                </DropdownLink>
                <UserInfoDropdownItem
                  onClick={() => {
                    dispatch(logOut(false));
                    navigate("/");
                  }}
                >
                  Logout
                </UserInfoDropdownItem>
              </Box>
            </UserInfoDropdown>
          )}
        </UserInfoContainer>
      </RightSideContainer>
    </NavBarOuterContainer>
  );
};

export default Navbar;
