import { useAppDispatch } from "common/redux";
import { logOut } from "common/redux/AuthSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LogoutPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logOut(true));
    navigate("/");
  }, []);

  return <></>;
};

export default LogoutPage;
