import { MutableRefObject } from "react";
import { DateTime } from "luxon";
import JsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import "svg2pdf.js";

import ReportRefInterface from "../pages/Reports/report_types/ReportRefInterface";
import ReportType from "../types/ReportType";
import {
  getNameOrUsername,
  sanitizeUserTextInput
} from "common/helpers/helpers";
import MemberType from "common/types/MemberType";
import DropdownType from "../types/DropdownType";

interface GeneratePDFProps {
  ref: MutableRefObject<ReportRefInterface>;
  patient: MemberType;
  dateFrom: DateTime;
  dateTo: DateTime;
  dropdownSelection: DropdownType[];
  report: ReportType;
  color: string;
}

const generateReport = async ({
  ref,
  patient,
  dateFrom,
  dateTo,
  dropdownSelection,
  report,
  color
}: GeneratePDFProps) => {
  const reportData = ref.current.getReportData();
  if (reportData === undefined) return;

  const jsPDF = new JsPDF("portrait", "pt", "a4");
  jsPDF.setFont("helvetica", "normal");
  jsPDF.setFontSize(12);

  const PAGE_WIDTH = jsPDF.internal.pageSize.width;

  const MARGIN_HORIZONTAL = 20;
  const TEXT_HEIGHT = 20;

  let heightAcummulator = 90;

  // @ts-ignore
  const element = document.getElementById("copilot-logo");

  await jsPDF.svg(element!, {
    x: PAGE_WIDTH / 2 - 100,
    y: 0,
    width: 200,
    height: 100
  });

  jsPDF.setFontSize(TEXT_HEIGHT);
  const TEXT = report.title;
  const TEXT_WIDTH = jsPDF.getTextWidth(TEXT);
  jsPDF.text(TEXT, PAGE_WIDTH / 2 - TEXT_WIDTH / 2, heightAcummulator);
  heightAcummulator += TEXT_HEIGHT;

  jsPDF.setFontSize(12);

  if (patient !== undefined) {
    jsPDF.text(
      "Member Name: " + getNameOrUsername(patient.patient),
      MARGIN_HORIZONTAL,
      heightAcummulator
    );
    heightAcummulator += TEXT_HEIGHT;

    jsPDF.text(
      "DOB: " + patient.patient.birthdate,
      MARGIN_HORIZONTAL,
      heightAcummulator
    );
    heightAcummulator += TEXT_HEIGHT;
  }

  jsPDF.text(
    "Date Range: " +
      dateFrom.toFormat("MM/dd/yyyy") +
      " - " +
      dateTo.toFormat("MM/dd/yyyy"),
    MARGIN_HORIZONTAL,
    heightAcummulator
  );
  heightAcummulator += TEXT_HEIGHT;

  if (dropdownSelection?.length > 0) {
    jsPDF.text(
      "Roles: " + dropdownSelection.map((item) => item.label).join(", "),
      MARGIN_HORIZONTAL,
      heightAcummulator
    );
    heightAcummulator += TEXT_HEIGHT;
  }

  heightAcummulator += TEXT_HEIGHT;

  reportData.forEach((reportDataItem) => {
    const { data, columnNames, reportSummary, title } = reportDataItem;

    if (title) {
      jsPDF.text(title, MARGIN_HORIZONTAL, heightAcummulator);
      heightAcummulator += TEXT_HEIGHT;
    }

    const columnStyles = {};
    columnNames.forEach((column, index) => {
      if (index === 0 || column.includes("Percentage"))
        columnStyles[index] = { cellWidth: 200 };
    });

    const mappedData = data.map((row) => {
      return row.map((cell) => cell ?? "N/A");
    });
    let tableHeight = 0;
    autoTable(jsPDF, {
      head: [columnNames],
      body: mappedData,
      margin: { left: MARGIN_HORIZONTAL, right: MARGIN_HORIZONTAL },
      startY: heightAcummulator,
      columnStyles,
      headStyles: {
        fillColor: color
      },
      didDrawCell: function (props) {
        tableHeight = props.cursor.y + props.cell.height * 2;
      }
    });

    heightAcummulator = tableHeight;

    if (reportSummary) {
      reportSummary.forEach((text) => {
        const sanitizedText = sanitizeUserTextInput(text);
        jsPDF.text(sanitizedText, MARGIN_HORIZONTAL, heightAcummulator);
        heightAcummulator += TEXT_HEIGHT;
      });
      heightAcummulator += TEXT_HEIGHT;
    }
  });

  jsPDF.save("report_" + report.id + ".pdf");
};

export default { generateReport };
