enum VisitMotivationTypesEnum {
  APPOINTMENT = "APPOINTMENT",
  DEVICE_SETUP = "DEVICE_SETUP",
  DEVICE_ISSUE = "DEVICE_ISSUE",
  MISSED_APPT = "MISSED_APPT",
  NEED_SUPPLIES = "NEED_SUPPLIES",
  OTHER = "OTHER",
  TASK = "TASK",
  URGENT_ALERT = "URGENT_ALERT",
  PATIENT_INITIATED = "PATIENT_INITIATED"
}

const capitalize = (input: string) => {
  const label = input?.toString()?.toLocaleLowerCase()?.replace("_", " ");

  return label?.charAt(0)?.toUpperCase() + label?.slice(1);
};

export const VisitMotivationTypesEnum_toString = (
  motivation: VisitMotivationTypesEnum
) => {
  switch (motivation) {
    case VisitMotivationTypesEnum.MISSED_APPT:
      return "Missed Appointment";
    case VisitMotivationTypesEnum.DEVICE_ISSUE:
      return "Device Support";
    case VisitMotivationTypesEnum.DEVICE_SETUP:
      return "Device Setup";
    case VisitMotivationTypesEnum.URGENT_ALERT:
      return "Urgent Alert";
    case VisitMotivationTypesEnum.APPOINTMENT:
      return "Scheduled Appointment";
    case VisitMotivationTypesEnum.PATIENT_INITIATED:
      return "On-Demand Appointment";
    default:
      return capitalize(motivation);
  }
};

export default VisitMotivationTypesEnum;
