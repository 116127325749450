import { useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";

import LoginForm from "./LoginForm";
import { CopilotIQFullLogo } from "../../assets/images";

import { RootState } from "common/redux";
import { DelayedRender } from "common/helpers/components/DelayedRender";
import EnvVars from "common/config/EnvVars";
import { Typography } from "@mui/material";

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;
`;

const LoginFormContainer = styled.div`
  width: 50%;
  padding: 10%;
`;

const SignInHeader = styled.h1`
  font-family: ${(props) => props.theme.font.family};
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;
  color: ${(props) => props.theme.color.darkBlue};
  text-align: left;
`;

const LoginImageContainer = styled.div`
  height: 100%;
  width: 50%;
`;

const CopilotLogoImg = styled(CopilotIQFullLogo)`
  position: absolute;
  top: 32px;
  left: 32px;
`;

const LoginImg = styled.img`
  height: 100%;
`;

interface IProps {
  to?: string;
}

const LoginPage = ({ to }: IProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (isLoggedIn && pathname === "/login") navigate("/");
  }, [isLoggedIn, pathname]);

  const buildDateTimeString = useMemo(() => {
    if (EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV === "prod") return null;
    if (EnvVars.REACT_APP_BUILD_DATETIME?.length < 4) return null;

    const dateTime = DateTime.fromISO(EnvVars.REACT_APP_BUILD_DATETIME);
    if (!dateTime.isValid) return null;

    return dateTime.toFormat("MM/dd/yyyy hh:mm a");
  }, []);

  return (
    <>
      {isLoggedIn ? (
        <div>You are already logged in</div>
      ) : (
        // Delay render to prevent UI flashing from logout navigation
        <DelayedRender delay={50}>
          <LoginContainer>
            <LoginFormContainer>
              <CopilotLogoImg //alt="CopilotIQ Logo"
              />

              <SignInHeader>Sign in to your account</SignInHeader>

              <br />
              <LoginForm />
              {buildDateTimeString && (
                <Typography>Last Updated: {buildDateTimeString}</Typography>
              )}
            </LoginFormContainer>
            <LoginImageContainer>
              <LoginImg
                src={"/login-banner.png"}
                width="auto"
                height="100%"
                alt="Copilot Nurse Image"
              />
            </LoginImageContainer>
          </LoginContainer>
        </DelayedRender>
      )}
    </>
  );
};

export default LoginPage;
