import { useMemo } from "react";
import { useGetVisitQuery } from "../services/VisitsService";
import { isFalsy } from "../helpers/helpers";
import VisitStatusEnum from "../enums/Calendaring/Visits/VisitStatusEnum";
import VisitLinkedEntitiesEnum from "../enums/VisitLinkedEntitiesEnum";
import { useGetVisitsQuery } from "../services/MemberService";

interface IProps {
  memberId?: string;
  staffId?: string;
}

const useGetCurrentVisit = ({ memberId, staffId }: IProps) => {
  const {
    data,
    isLoading: isLoadingVisits,
    error: errorVisits,
    refetch,
  } = useGetVisitsQuery(
    {
      patient_id: memberId,
      staff_id: staffId,
      status: VisitStatusEnum.IN_PROGRESS,
      linked_entities: [VisitLinkedEntitiesEnum.CARE_FLOW_SUMMARY]
    },
    { skip: staffId === undefined && memberId === undefined }
  );

  const ongoingVisit = useMemo(() => {
    if (isFalsy(data)) return;

    return data.find(
      (item) =>
        item.status === VisitStatusEnum.IN_PROGRESS && item.care_flow_summary
    );
  }, [data]);

  const {
    data: visitWithCareFlow,
    isLoading: isLoadingVisit,
    error: errorVisit
  } = useGetVisitQuery(
    { visit_id: ongoingVisit?.visit_id },
    {
      skip: ongoingVisit === undefined
    }
  );

  return {
    data: visitWithCareFlow,
    isLoading: isLoadingVisits || isLoadingVisit,
    error: errorVisits ?? errorVisit,
    refetch
  };
};

export default useGetCurrentVisit;
