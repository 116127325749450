import { apiRemoteIQ } from "./AxiosService";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import ReadingSummaryType from "../types/ReadingSummaryType";
import DeviceUsageSummaryType from "../types/DeviceUsageSummaryType";
import MemberActivitySummaryType from "../types/MemberActivitySummaryType";
import LinkedEntitiesEnum from "../enums/MemberLinkedEntitiesEnum";
import PageType from "../types/PageType";
import { UserQueryParams } from "./UserService";

interface DeviceUsageParams {
  start?: number;
  end?: number;
}

const remoteIQService = apiRemoteIQ
  .enhanceEndpoints({
    addTagTypes: []
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getReadingSummaryByMember: builder.query<
        ReadingSummaryType,
        { memberId: string }
      >({
        query: ({ memberId }) => {
          return {
            url: `/reports/reading-summary/${memberId}`,
            method: "GET"
          };
        },
        providesTags: []
      }),
      getDeviceUsageByMember: builder.query<
        DeviceUsageSummaryType,
        {
          memberId: string;
          paramsObject: DeviceUsageParams;
        }
      >({
        query: ({ memberId, paramsObject }) => {
          const params = QueryString_stringify(paramsObject);
          return {
            url: `/reports/device-usage/${memberId}?${params}`,
            method: "GET"
          };
        },
        providesTags: []
      }),
      getMemberActivityByStatus: builder.query<
        MemberActivitySummaryType[],
        PageType & UserQueryParams & { linked_entities?: LinkedEntitiesEnum[] }
      >({
        query: ({ size = 350, ...params }) => {
          const queryParams = QueryString_stringify({
            ...params,
            size
          });
          return {
            url: `/aggregate/patient-activity?${queryParams}`,
            method: "GET"
          };
        },
        providesTags: []
      })
    })
  });

export const {
  useGetDeviceUsageByMemberQuery,
  useGetMemberActivityByStatusQuery
} = remoteIQService;
