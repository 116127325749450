import { useEffect, useState } from "react";
import { useFormik } from "formik";
import styled from "@emotion/styled";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";

import {
  EMAIL_VALIDATION_REGEXP,
  getErrorMessage
} from "common/helpers/helpers";
import { RootState, useAppDispatch } from "common/redux";
import StorageHelper from "common/helpers/StorageHelper";
import { logInWithPassword } from "common/redux/AuthSlice";
import { useSelector } from "react-redux";

import ErrorComponent from "../../components/ErrorComponent";
import PasswordInput from "../../components/Input/PasswordInput";
import { TurqoiseButton } from "../../styling";
import StorageEnum from "common/enums/StorageEnum";

const RememberMeContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
`;

interface FormProps {
  email: string;
  password: string;
  rememberDevice: boolean;
}

const LoginForm = () => {
  const dispatch = useAppDispatch();

  const { error, isLoading } = useSelector((state: RootState) => state.auth);

  const [savedLogin, setSavedLogin] = useState<string>();
  const [loginError, setLoginError] = useState<string>();

  useEffect(() => {
    StorageHelper.getItem(StorageEnum.LOGIN)
      .then((savedLoginFromStorage) => {
        setSavedLogin(savedLoginFromStorage as string);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (error === undefined || error.response === undefined) return;

    const { status } = error.response;
    if (status === 401) {
      setLoginError("Username or password is not valid.");
    } else {
      setLoginError(getErrorMessage(error));
    }
  }, [error]);

  const validate = (values: FormProps) => {
    const errors: {
      email?: string;
      password?: string;
    } = {};

    if (!EMAIL_VALIDATION_REGEXP.test(values.email)) {
      errors.email = "Required";
    }

    if (!values.password) {
      errors.password = "Required";
    }

    return errors;
  };

  async function onSubmit(values: FormProps) {
    const { email, password, rememberDevice } = values;
    const sanitizedEmail = email?.trim();

    dispatch(logInWithPassword({ email: sanitizedEmail, password })).catch(
      (error) => console.log(error)
    );

    if (rememberDevice) {
      StorageHelper.setItem(StorageEnum.LOGIN, sanitizedEmail).catch((error) =>
        console.log(error)
      );
    } else {
      StorageHelper.removeItem(StorageEnum.LOGIN).catch((error) => {
        console.log(error);
      });
    }
  }

  const formik = useFormik<FormProps>({
    validate,
    initialValues: {
      email: savedLogin ? savedLogin : "",
      password: "",
      rememberDevice: savedLogin ? true : false
    },
    enableReinitialize: true,
    onSubmit
  });

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value).catch((error) => {});
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          id="email"
          label="Email"
          autoComplete="email"
          value={formik.values.email}
          onChange={(event) => setFieldValue("email", event.target.value)}
          type="email"
          fullWidth
        />
        <br />
        <br />
        <PasswordInput
          id="password"
          label="Password"
          value={formik.values.password}
          onChange={(event) => setFieldValue("password", event.target.value)}
          fullWidth
        />

        <RememberMeContainer>
          <FormControlLabel
            control={
              <Checkbox
                id="rememberDevice"
                checked={formik.values.rememberDevice}
                onChange={() => {
                  setFieldValue(
                    "rememberDevice",
                    !formik.values.rememberDevice
                  );
                }}
              />
            }
            label="Remember this device"
          />
        </RememberMeContainer>
        <TurqoiseButton
          loading={isLoading}
          type="submit"
          disabled={!formik.isValid || !formik.dirty}
          data-testid="LoginForm-submit"
        >
          Sign In
        </TurqoiseButton>
      </form>

      <br />
      {loginError && <ErrorComponent error={loginError} />}
    </>
  );
};

export default LoginForm;
