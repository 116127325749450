import styled from "@emotion/styled";
import VisitFieldGoalManagement from "../Visits/ui/VisitFieldGoalManagement";
import { Flexbox } from "../../styling/NewStyleComponents";
import MemberDetailsHeader from "./Header/MemberDetailsHeader";
import MemberDetailsLayout from "./MemberDetailsLayout";
import useSanitizedParams from "../../hooks/useSanitizedParams";

const MemberDetailsGoalsContainer = styled("div")`
  position: sticky;
  overflow: hidden;
  margin: 2.5%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const MemberDetailsGoals = ({
  hideReadings = false
}: {
  hideReadings?: boolean;
}) => {
  const params = useSanitizedParams();
  const { memberId } = params;
  return (
    <MemberDetailsGoalsContainer>
      <Flexbox
        flexDirection="column"
        height="inherit"
        width="inherit"
        gap="16px"
        overflow="hidden"
      >
        <MemberDetailsHeader key={`${memberId}-header-goals`} />
        <MemberDetailsLayout
          key={`${memberId}-layout-goals`}
          memberId={memberId}
          hideReadings={hideReadings}
        >
          <VisitFieldGoalManagement memberId={memberId} />
        </MemberDetailsLayout>
      </Flexbox>
    </MemberDetailsGoalsContainer>
  );
};

export default MemberDetailsGoals;
