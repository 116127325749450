import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography
} from "@mui/material";

import CareFlowFieldTypeEnum from "common/enums/CareFlowFieldTypeEnum";
import { CareFlowFieldType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { AppDispatch, RootState, useAppDispatch } from "common/redux";
import { setAnswer, setSelectedSection } from "common/redux/VisitsSlice";
import { blue, gray } from "../../../styling/colors";
import VisitContentComponentField from "./VisitContentComponentField";
import { Flexbox } from "../../../styling/NewStyleComponents";
import VisitStatusEnum from "common/enums/Calendaring/Visits/VisitStatusEnum";
import { CARE_FLOW_CHARACTER_LIMIT } from "../../../helpers/components/Forms/FormHelpers";
import { Alert_closeAll, Alert_show } from "common/helpers/AlertHelper";
import { CareFlowOptionActionEnum } from "common/enums/Calendaring/Visits/CareFlowOptionActionEnum";
import CareFlowSectionsEnum from "common/enums/Calendaring/Visits/CareFlowSectionsEnum";
import CareFlowFieldIdsEnum from "common/enums/Calendaring/Visits/CareFlowFieldIdsEnum";
import { CarePlanPhaseEnum } from "common/types/Visits/CarePlanType";
import { useUpsertCarePlanMutation } from "common/services/VisitsService";
import MemberType from "common/types/MemberType";

const FieldContainer = styled("div")`
  flex: 1;
`;

const Row = styled(Flexbox)`
  display: flex;
  align-items: center;
`;

const MultiFieldContainer = styled("div")`
  display: block;
  column-count: 2;
  flex-wrap: wrap;
  gap: 20px;
`;

const StepContainer = styled("div")`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`;

const StepHorizontalSpacing = styled("div")`
  width: 64px;
  height: 1px;
  background-color: ${gray[300]};
`;

const StepRowContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ConfirmThenGoToSection = ({
  modalBody,
  gotoId,
  dispatch,
  visit,
  syncCareFlowToAPI = () => {}
}: {
  modalBody: string;
  gotoId: string;
  dispatch: AppDispatch;
  visit: GetVisitResponseType;
  syncCareFlowToAPI?: () => void;
}) => {
  Alert_show({
    dispatch,
    title: "Please Review",
    content: (
      <>
        <Typography variant="h6" color={"secondary"} textAlign={"center"}>
          {modalBody}
        </Typography>
      </>
    ),
    size: "small",
    hideCloseIcon: true,
    buttons: [
      {
        text: "Confirm",
        onPress: () => {
          Alert_closeAll(dispatch);

          // Need to loop through to find the section obj with subsections
          const newSection = visit?.care_flow?.sections.find(
            (item) => item.section_id === gotoId
          );
          syncCareFlowToAPI();
          dispatch(
            setSelectedSection({
              section_id: newSection.section_id,
              subsection_id: newSection.subsections[0].section_id
            })
          );
        }
      },
      {
        style: "cancel",
        text: "Cancel",
        onPress: () => Alert_closeAll(dispatch)
      }
    ]
  });
};

const prettyGoToSectionName = (section_id) => {
  if (section_id === CareFlowSectionsEnum.PRE_CALL) return "Pre-Call";
  if (section_id === CareFlowSectionsEnum.CALL) return "Call";
  if (section_id === CareFlowSectionsEnum.POST_CALL) return "Post-Call";

  return "the next section";
};

const processActions = (
  dispatch,
  syncCareFlowToAPI,
  visit,
  optionsArray,
  value
) => {
  const optionConfig = optionsArray.find((item) => item.option === value);
  optionConfig?.actions?.forEach((action) => {
    if (action.action === CareFlowOptionActionEnum.GOTO) {
      ConfirmThenGoToSection({
        modalBody: `Are you sure? If so, confirm below to proceed to ${prettyGoToSectionName(action.target)}`,
        gotoId: action.target,
        dispatch,
        syncCareFlowToAPI,
        visit
      });
    }
  });
};

const RenderFieldValue = ({
  field,
  width,
  visit,
  member,
  syncCareFlowToAPI = () => {}
}: {
  field: CareFlowFieldType;
  width?: number;
  visit: GetVisitResponseType;
  member: MemberType;
  syncCareFlowToAPI?: () => void;
}) => {
  const dispatch = useAppDispatch();

  const [updateCarePlan] = useUpsertCarePlanMutation();

  // applyAnswer calls dispatch(setAnswer) and also checks for additional business logic based on the field_id
  const applyAnswer = ({ id, value }: { id: string; value: string }) => {
    dispatch(setAnswer({ id: id, value: value }));

    if (id === CareFlowFieldIdsEnum.CALL_CARE_PLAN_WEEKLY_VISIT_DID_CONVERT) {
      const phase =
        value === "Yes"
          ? CarePlanPhaseEnum.CONCIERGE
          : CarePlanPhaseEnum.MAINTENANCE;
      updateCarePlan({
        body: {
          patient_id: member?.patient?.patient_id,
          member_journey_phase: phase
        }
      });
    }
  };

  const { answers } = useSelector((state: RootState) => state.visits);

  const disabled = visit.status !== VisitStatusEnum.IN_PROGRESS;

  switch (field.type) {
    case CareFlowFieldTypeEnum.CHECKBOX: {
      const isChecked = answers[field.field_id] === "true";
      // If a checkbox has a child subfield, only show if isChecked
      const hasChild = field?.subfields?.length > 0;
      return (
        <>
          <FormControlLabel
            control={<Checkbox checked={isChecked} />}
            onChange={(event, checked) => {
              applyAnswer({ id: field.field_id, value: "" + checked });

              // If unchecked, unset children field values
              if (!isChecked && hasChild) {
                field.subfields.forEach((item) => {
                  applyAnswer({ id: item.field_id, value: "" });
                });
              }
            }}
            label={field.label}
            disabled={disabled}
            slotProps={{
              typography: {
                variant: "body1",
                color: gray[900]
              }
            }}
          />
          {isChecked &&
            hasChild &&
            field.subfields.map((item) => {
              // sometimes there is no item name or item description - we don't want to render empty content
              if (item?.type) {
                return (
                  <Flexbox
                    key={item.field_id}
                    flexDirection={"column"}
                    style={{ margin: "10px 40px" }}
                  >
                    <Typography
                      variant="h6"
                      color={gray[900]}
                      data-testid={item.name}
                    >
                      {item.name}
                    </Typography>
                    <Typography variant="body1" color={gray[900]}>
                      {item.description}
                    </Typography>
                    <RenderFieldValue
                      field={item}
                      visit={visit}
                      member={member}
                    />
                  </Flexbox>
                );
              }
            })}
        </>
      );
    }
    case CareFlowFieldTypeEnum.RADIO: {
      const optionsArray = field.options;
      const isYes = answers[field.field_id] === "Yes";
      // If a checkbox has a child subfield, only show if isChecked
      const hasChild = field?.subfields?.length > 0;
      return (
        <div>
          <Row>
            <Typography
              variant="body1"
              color={gray[900]}
              aria-label={field.label}
            >
              {field.label}
            </Typography>
          </Row>
          <RadioGroup
            name="radio-buttons-group"
            defaultValue={answers[field.field_id] ?? ""}
            row
            onChange={(event) => {
              applyAnswer({ id: field.field_id, value: event.target.value });
              // If unchecked, unset children field values
              if (!isYes && hasChild) {
                field.subfields.forEach((item) => {
                  applyAnswer({ id: item.field_id, value: "" });
                });
              }

              // Check for actions
              processActions(
                dispatch,
                syncCareFlowToAPI,
                visit,
                optionsArray,
                event.target.value
              );
            }}
          >
            {optionsArray.map((option) => {
              return (
                <FormControlLabel
                  key={option.option}
                  value={option.option}
                  control={<Radio />}
                  disabled={disabled}
                  label={option.option}
                  aria-label={option.option}
                />
              );
            })}
          </RadioGroup>
          {isYes &&
            hasChild &&
            field.subfields.map((item) => {
              return (
                <Flexbox
                  key={item.field_id}
                  flexDirection={"column"}
                  style={{ margin: "10px 40px" }}
                  aria-label={item.name}
                >
                  <Typography
                    variant="h6"
                    color={gray[900]}
                    data-testid={item.name}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    color={gray[900]}
                    style={{ marginBottom: "10px" }}
                    aria-label={item.description}
                  >
                    {item.description}
                  </Typography>
                  <RenderFieldValue
                    field={item}
                    visit={visit}
                    member={member}
                  />
                </Flexbox>
              );
            })}
        </div>
      );
    }
    case CareFlowFieldTypeEnum.COMPONENT:
      return (
        <VisitContentComponentField field={field} width={width} visit={visit} />
      );
    case CareFlowFieldTypeEnum.LONG_INPUT:
      return (
        <TextField
          label={field.label}
          fullWidth
          multiline
          rows={3}
          aria-label={field.label}
          value={answers[field.field_id] ?? ""}
          disabled={disabled}
          slotProps={{ htmlInput: { maxLength: CARE_FLOW_CHARACTER_LIMIT } }}
          sx={{
            input: {
              color: gray[900]
            }
          }}
          onChange={(event) => {
            applyAnswer({ id: field.field_id, value: event.target.value });
          }}
        />
      );
    case CareFlowFieldTypeEnum.MULTI:
      return (
        <div>
          <Typography variant="body1" color={gray[900]}>
            {field.label}
          </Typography>
          <MultiFieldContainer>
            {field.subfields.map((item) => {
              return (
                <Fragment key={item.field_id}>
                  <Typography
                    variant="h6"
                    color={gray[900]}
                    data-testid={item.name}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="body1" color={gray[900]}>
                    {item.description}
                  </Typography>
                  <br />
                  <RenderFieldValue
                    field={item}
                    visit={visit}
                    member={member}
                  />
                </Fragment>
              );
            })}
          </MultiFieldContainer>
        </div>
      );
    case CareFlowFieldTypeEnum.SELECT: {
      const optionsArray = field.options;
      return (
        <div>
          <TextField
            select
            aria-label={field.label}
            label={field.label}
            placeholder={field.placeholder}
            disabled={disabled}
            value={answers[field.field_id] ?? ""}
            sx={{
              width: 300,
              input: {
                color: gray[900]
              }
            }}
            onChange={(event) => {
              const value = event.target.value;
              applyAnswer({ id: field.field_id, value: value });

              // Check for actions
              processActions(
                dispatch,
                syncCareFlowToAPI,
                visit,
                optionsArray,
                value
              );
            }}
          >
            {optionsArray.map((item) => (
              <MenuItem key={item.option} value={item.option}>
                {item.option}
              </MenuItem>
            ))}
          </TextField>
        </div>
      );
    }
    case CareFlowFieldTypeEnum.MULTISELECT: {
      const optionsArray = field.options;
      const value = answers[field.field_id] as string;
      return (
        <div>
          <TextField
            select
            label={field.label}
            placeholder={field.placeholder}
            disabled={disabled}
            sx={{
              width: 300,
              input: {
                color: gray[900]
              }
            }}
            slotProps={{
              select: {
                variant: "outlined",
                multiple: true,
                value: value?.split(",") || [],
                onChange: (event) => {
                  const value = event.target.value as string[];
                  applyAnswer({
                    id: field.field_id,
                    value: value.filter((item) => item.length > 0).join(",")
                  });
                }
              }
            }}
          >
            {optionsArray.map((item) => (
              <MenuItem key={field.field_id + "_" + item} value={item.option}>
                {item.option}
              </MenuItem>
            ))}
          </TextField>
        </div>
      );
    }
    case CareFlowFieldTypeEnum.SHORT_INPUT:
      return (
        <TextField
          fullWidth
          aria-label={field.label}
          label={field.label}
          value={answers[field.field_id] ?? ""}
          placeholder={field.placeholder}
          disabled={disabled}
          slotProps={{ htmlInput: { maxLength: CARE_FLOW_CHARACTER_LIMIT } }}
          sx={{
            input: {
              color: gray[900]
            }
          }}
          onChange={(event) => {
            applyAnswer({ id: field.field_id, value: event.target.value });
          }}
        />
      );
    case CareFlowFieldTypeEnum.TEXT:
      return (
        <Typography variant="body1" color={gray[900]}>
          {field.label}
        </Typography>
      );
    default:
      return (
        <Typography variant="body1" color={gray[900]}>
          {field.type} IS UNDER CONSTRUCTION
        </Typography>
      );
  }
};

const RenderStep = ({
  step,
  disabled
}: {
  step: string | number;
  disabled: boolean;
}) => {
  const backgroundColor = disabled ? gray[200] : blue[200];
  const textColor = disabled ? gray[400] : gray[900];
  return (
    <StepContainer sx={{ backgroundColor }}>
      <Typography variant="h6" sx={{ color: textColor }}>
        {step}
      </Typography>
    </StepContainer>
  );
};

const VisitContentField = ({
  field,
  visit,
  member,
  syncCareFlowToAPI = () => {}
}: {
  field: CareFlowFieldType;
  visit: GetVisitResponseType;
  member: MemberType;
  syncCareFlowToAPI?: () => void;
}) => {
  const fieldContainerRef = useRef<HTMLDivElement>();

  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    setWidth(fieldContainerRef?.current?.offsetWidth);
  }, [fieldContainerRef.current]);

  return (
    <FieldContainer ref={fieldContainerRef}>
      {field.name && (
        <Typography
          variant="h6"
          color={gray[900]}
          textTransform={"uppercase"}
          margin={"15px 5px 10px 0px"}
        >
          {field.name}
          {field.name && field.is_required && <>*</>}
        </Typography>
      )}
      <Typography variant="body1" color={gray[900]} marginBottom={"10px"}>
        {field.description}
      </Typography>
      <RenderFieldValue
        syncCareFlowToAPI={syncCareFlowToAPI}
        field={field}
        width={width}
        visit={visit}
        member={member}
      />
    </FieldContainer>
  );
};

export default VisitContentField;

export {
  RenderStep,
  ConfirmThenGoToSection,
  StepHorizontalSpacing,
  StepRowContainer
};
