import { useMemo, ReactNode } from "react";
import { useFormik } from "formik";
import styled from "@emotion/styled";
import { MenuItem, TextField } from "@mui/material";

import RolesEnum from "common/enums/RolesEnum";
import UserType from "common/types/UserType";
import { useCreateTeamMutation } from "common/services/TeamsService";
import { getNameOrUsername, validateFieldLength } from "common/helpers/helpers";
import {
  getAllowedTeamTypesForLeaderRole,
  TeamTypeDropdownLabels
} from "common/enums/TeamTypeEnum";

import { SuccessText } from "../../styling/StyleComponents";
import { TurqoiseButton } from "../../styling";

import ErrorComponent from "../../components/ErrorComponent";

interface TeamType {
  label: string;
  value: string;
}

function getTeamTypes(roles: RolesEnum[]): TeamType[] {
  if (!roles) {
    return null;
  }
  let teamTypesArr: TeamType[] = [];
  roles.forEach((role) => {
    const teamTypes = getAllowedTeamTypesForLeaderRole(role)?.map(
      (team) => TeamTypeDropdownLabels[team]
    );
    if (teamTypes?.length > 0) {
      teamTypesArr = [...teamTypesArr, ...teamTypes];
    }
  });
  return [
    ...new Map(teamTypesArr.map((v: TeamType) => [v?.value, v])).values()
  ];
}

const CreateTeamFormContainer = styled.div<{
  children: ReactNode | ReactNode[];
}>``;

const SuccessContainer = styled.div`
  transition: height 0.66s ease-out;
  min-height: 28px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

interface FormType {
  name: string;
  type: string;
}
interface IProps {
  teamLeader?: UserType;
}

const CreateTeamForm = ({ teamLeader }: IProps) => {
  const [createTeamMutation, { isSuccess, data, error, isLoading }] =
    useCreateTeamMutation();

  const teamTypes = useMemo(() => {
    if (teamLeader?.user.roles === undefined) return;
    return getTeamTypes(teamLeader?.user.roles);
  }, [teamLeader]);

  const validate = (values: FormType) => {
    const errors: { [key: string]: string } = {};

    if (!values["name"]) {
      errors["name"] = "Required.";
    }

    if (values["name"] && !validateFieldLength(2, 50, values?.["name"])) {
      errors["name"] = "Please enter a value between 2 and 50 characters.";
    }

    if (!values["type"]) {
      errors["type"] = "Required.";
    }

    return errors;
  };

  const onSubmit = async (values: FormType) => {
    const { name, type } = values || {};
    const body = {
      leader_id: teamLeader?.user.user_id,
      type,
      name
    };

    await createTeamMutation(body);
  };

  const formik = useFormik<FormType>({
    validate,
    initialValues: {
      name: "",
      type: ""
    },
    onSubmit,
    enableReinitialize: true
  });

  const { values, setFieldValue, handleSubmit, errors, isValid, dirty } =
    formik;

  const leader = getNameOrUsername(teamLeader?.user);
  return (
    <CreateTeamFormContainer>
      <Form onSubmit={handleSubmit}>
        <br />
        {leader && (
          <TextField
            label={"Team Leader"}
            value={leader}
            disabled
            InputLabelProps={{ shrink: true }}
          />
        )}
        <br />
        <TextField
          label={"Team Name"}
          value={values.name}
          onChange={(event) => setFieldValue("name", event.target.value)}
          error={errors["name"] !== undefined}
          helperText={errors["name"]}
        />
        <br />
        {teamTypes && (
          <TextField
            label="Team Type"
            select
            disabled={!teamLeader?.user.user_id}
            value={values.type}
            onChange={(event) => {
              setFieldValue("type", event.target.value);
            }}
            error={errors["type"] !== undefined}
            helperText={errors["type"]}
          >
            {teamTypes.map((teamType) => (
              <MenuItem key={teamType.value} value={teamType.value}>
                {teamType.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        <br />
        <TurqoiseButton
          type="submit"
          loading={isLoading}
          disabled={!isValid || !dirty}
        >
          Create Team
        </TurqoiseButton>
        <SuccessContainer>
          {isSuccess && (
            <SuccessText
              margin={"12px 0 0 0"}
            >{`Team with name: ${data?.name} successfully created.`}</SuccessText>
          )}
        </SuccessContainer>
      </Form>
      <br />
      {error && <ErrorComponent error={error} />}
    </CreateTeamFormContainer>
  );
};

export default CreateTeamForm;
