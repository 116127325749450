import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const LoadingPage = () => {
  return (
    <Container>
      <CircularProgress />
    </Container>
  );
};

export default LoadingPage;
