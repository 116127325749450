import { useFormik } from "formik";
import styled from "@emotion/styled";
import { TurqoiseButton } from "../../styling";
import {
  isValidAthenaId,
  isValidSalesforceId
} from "../../helpers/components/Forms/FormHelpers";

import { runLater } from "../../helpers/helpers";
import MigratePatientStatus from "./enums/MigratePatientStatus";
import {
  useLazyGetAthenaMemberDetailsQuery,
  useLazyGetSalesforceAccountQuery
} from "common/services/MemberRegistrationService";
import { TextField, Typography } from "@mui/material";

const MigratePatientFormContainer = styled.div`
  padding: 2.5%;
  width: 95%;
  background: ${(props) => props.theme.color.white};
  border-radius: 4px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

interface FormType {
  salesforceAccountId: string;
  patientAthenaId: string;
}

const MigratePatientForm = ({
  setPatientToMigrateDataArray,
  setCurrentModalState,
  setathena_idCallback,
  setMigratePatientLoadingCallback,
  migratePatientLoading
}) => {
  const [getSalesforceAccount] = useLazyGetSalesforceAccountQuery();
  const [getAthenaPatientDetails] = useLazyGetAthenaMemberDetailsQuery();

  const onSubmit = async (values: FormType) => {
    const { salesforceAccountId, patientAthenaId } = values;
    setathena_idCallback(patientAthenaId);
    setMigratePatientLoadingCallback(true);
    const arrayPromises = [];

    arrayPromises.push(runLater(getSalesforceAccount, salesforceAccountId));
    arrayPromises.push(runLater(getAthenaPatientDetails, patientAthenaId));
    const arrayOfPromises = arrayPromises.map((promise) => {
      const [func, args] = promise;
      // execute the function with the arguments
      return func(...args);
    });

    const responseArray = await Promise.allSettled(arrayOfPromises);

    setPatientToMigrateDataArray(responseArray);
    setCurrentModalState(MigratePatientStatus.STATE_1);
    setMigratePatientLoadingCallback(false);
  };

  const validate = (values: FormType) => {
    const errors: Partial<FormType> = {};

    const { salesforceAccountId, patientAthenaId } = values || {};

    if (!salesforceAccountId) {
      errors.salesforceAccountId = "Required";
    }

    if (salesforceAccountId && !isValidSalesforceId(salesforceAccountId)) {
      errors.salesforceAccountId = "Please enter a valid Salesforce Account ID";
    }

    if (!patientAthenaId) {
      errors.patientAthenaId = "Required";
    }

    if (patientAthenaId && !isValidAthenaId(patientAthenaId)) {
      errors.patientAthenaId = "Please enter a valid Member Athena ID";
    }

    return errors;
  };

  const formik = useFormik<FormType>({
    initialValues: {
      salesforceAccountId: "",
      patientAthenaId: ""
    },
    validate,
    onSubmit
  });

  const { values, setFieldValue, errors, handleSubmit } = formik;

  return (
    <MigratePatientFormContainer>
      <Typography variant="h3">Migrate Member</Typography>
      <br />
      <Form onSubmit={handleSubmit}>
        <TextField
          value={values.salesforceAccountId}
          onChange={(event) =>
            setFieldValue("salesforceAccountId", event.target.value)
          }
          label="Salesforce Account ID"
          error={errors["salesforceAccountId"] !== undefined}
          helperText={errors["salesforceAccountId"]}
        />

        <br />
        <TextField
          value={values.patientAthenaId}
          onChange={(event) =>
            setFieldValue("patientAthenaId", event.target.value)
          }
          label="Member Athena ID"
          error={errors["patientAthenaId"] !== undefined}
          helperText={errors["patientAthenaId"]}
        />

        <br />
        <TurqoiseButton
          disabled={!formik.dirty || !formik.isValid}
          type="submit"
          loading={migratePatientLoading}
        >
          Migrate Member
        </TurqoiseButton>
      </Form>
    </MigratePatientFormContainer>
  );
};

export default MigratePatientForm;
