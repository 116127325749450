import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  ChangeEvent
} from "react";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";

import { useUpdateMemberDataMutation } from "common/services/MemberRegistrationService";

import {
  ErrorText,
  ErrorTextSpan,
  TurqoiseButton,
  WhiteButton
} from "../../../../styling";
import ErrorComponent from "../../../../components/ErrorComponent";

import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader,
  StyledModal
} from "../../../../styling/StyleModal";
import AddressSourceEnum from "common/enums/AddressSourceEnum";
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import {
  isStateAbbreviation,
  isValidUSPostalCode,
  validateFieldLength,
  getStates,
  isFalsy
} from "common/helpers/helpers";
import { DangerIcon } from "../../../../assets/images/icons";
import { TextFieldComponent } from "../FormHelpers";
import { EditAddressValidationModal } from "./EditAddressValidationModal";
import { useValidateAddressMutation } from "common/services/DevicesService";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import AddressValidationStatusEnum from "common/enums/AddressValidationStatusEnum";
import AddressesType from "common/types/AddressesType";

const stateDropdownOptions = getStates();

const defaultFormStyles = { mb: "20px" };

const StyledDangerIcon = styled(DangerIcon)`
  width: 23px;
  height: 23px;
`;

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 4px;
`;

const RadioGroupHeading = styled("span")`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.palette.text.primary};
`;

interface FormValues {
  city: string;
  country: string;
  postal_code: string;
  state: string;
  street1: string;
  street2: string;
  addressSource: AddressSourceEnum;
}

interface IProps {
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  setAddressChanged: Dispatch<SetStateAction<boolean>>;
  setAddressSource: Dispatch<SetStateAction<AddressSourceEnum>>;
  patientName: string;
  selectedPatientId: string | undefined;
  addresses: AddressesType;
}

const EditAddressModal = ({
  modalOpen,
  setModalOpen,
  selectedPatientId,
  addresses,
  patientName,
  setAddressChanged,
  setAddressSource
}: IProps) => {
  const dispatch = useAppDispatch();
  const [validateAddressModalOpen, setValidateAddressModalOpen] =
    useState<boolean>(false);
  const { athenaPatientAddress, pabPatientAddress, addressesEqual } = addresses;
  const [
    validateAddressMutation,
    {
      data: validateAddressData,
      isSuccess: validateAddressIsSuccess,
      error: validateAddressError,
      isLoading: validateAddressIsLoading
    }
  ] = useValidateAddressMutation();

  const [
    updateMemberdataMutation,
    {
      isSuccess: updateMemberAddressSuccess,
      isLoading: updateMemberAddressLoading,
      error: updateMemberAddressError
    }
  ] = useUpdateMemberDataMutation();

  useEffect(() => {
    if (updateMemberAddressSuccess) {
      setAddressChanged(true);
      handleModalClose();

      Alert_show({
        dispatch,
        title: "Member Address Updated",
        content: "Member Address Updated Successfully",
        id: "patientAddressUpdateSuccess",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () =>
              Alert_close({ dispatch, id: "patientAddressUpdateSuccess" })
          }
        ]
      });
    }
  }, [updateMemberAddressSuccess]);

  const validate = (values: FormValues) => {
    const errors = {};

    if (values.addressSource === AddressSourceEnum.INPUT) {
      if (!values.street1) {
        errors["street1"] = "Required";
      }
      if (!validateFieldLength(2, 50, values["street1"])) {
        errors["street1"] = "Please enter a value between 2 and 50 characters.";
      }

      if (values.street2 && !validateFieldLength(1, 50, values["street2"])) {
        errors["street2"] = "Please enter a value up to 50 characters.";
      }

      if (!values.city) {
        errors["city"] = "Required";
      }
      if (!validateFieldLength(2, 50, values["city"])) {
        errors["city"] = "Please enter a value between 2 and 50 characters.";
      }

      if (!values.state) {
        errors["state"] = "Required";
      }

      if (!isStateAbbreviation(values.state)) {
        errors["state"] = "Please enter a valid US state abbreviation.";
      }

      if (!values.postal_code) {
        errors["postal_code"] = "Required";
      }

      if (!isValidUSPostalCode(values.postal_code)) {
        errors["postal_code"] = "Please enter a valid US postal code.";
      }
    }

    if (!values.addressSource) {
      errors["addressSource"] = "Required";
    }

    return errors;
  };

  const onSubmit = async (values: FormValues) => {
    if (validateAddressError) {
      await updatePatientAddress(selectedPatientId, values);
      return;
    }

    let address;
    const { addressSource, ...rest } = values;
    if (addressSource === AddressSourceEnum.INPUT) {
      address = rest;
    } else {
      // hardcode country as "US" for consistency
      if (addressSource === AddressSourceEnum.ATHENA) {
        address = { ...athenaPatientAddress, country: "US" };
      }
      if (addressSource === AddressSourceEnum.PAB) {
        address = { ...pabPatientAddress, country: "US" };
      }
    }

    setAddressSource(addressSource);

    await validateAddressMutation({ address });
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      city: athenaPatientAddress?.city || pabPatientAddress?.city || "",
      country:
        athenaPatientAddress?.country || pabPatientAddress?.country || "",
      postal_code:
        athenaPatientAddress?.postal_code ||
        pabPatientAddress?.postal_code ||
        "",
      state: athenaPatientAddress?.state || pabPatientAddress?.state || "",
      street1:
        athenaPatientAddress?.street1 || pabPatientAddress?.street1 || "",
      street2:
        athenaPatientAddress?.street2 || pabPatientAddress?.street2 || "",
      addressSource: null
    },
    enableReinitialize: true,
    validate,
    onSubmit
  });

  const setFieldValue = (key, value) => {
    formik.setFieldValue(key, value).catch((error) => {});
  };

  const handleModalClose = () => {
    formik.resetForm();
    setModalOpen(false);
  };

  const handleRadioButtonChange = (event: ChangeEvent<HTMLInputElement>) => {
    void formik.setFieldValue(
      "addressSource",
      (event.target as HTMLInputElement).value
    );
  };

  async function updatePatientAddress(patient_id, address) {
    await updateMemberdataMutation({
      member_id: patient_id,
      address
    });
  }

  useEffect(() => {
    if (isFalsy(selectedPatientId)) return;
    if (validateAddressIsSuccess && validateAddressData) {
      if (
        validateAddressData?.addressesEqual &&
        validateAddressData?.status === AddressValidationStatusEnum.VALID
      ) {
        updatePatientAddress(
          selectedPatientId,
          validateAddressData.original_address
        );
      } else {
        setValidateAddressModalOpen(true);
      }
    }
  }, [validateAddressData, validateAddressIsSuccess]);

  const closeBothModals = useCallback(() => {
    setValidateAddressModalOpen(false);
    handleModalClose();
  }, [setValidateAddressModalOpen, handleModalClose]);

  return (
    <StyledModal
      isOpen={modalOpen}
      contentLabel="Assign Provider to Patient Modal"
      modalHeight="70vh"
      onRequestClose={handleModalClose}
    >
      <Form onSubmit={formik.handleSubmit}>
        <ModalHeader onRequestClose={handleModalClose}>
          {addressesEqual ? (
            "Update Member Address"
          ) : (
            <>
              <StyledDangerIcon />
              &nbsp;
              <ErrorTextSpan>Warning</ErrorTextSpan>&nbsp;Member Address is
              Out-Of-Sync
            </>
          )}
        </ModalHeader>
        <ModalBody>
          <Typography variant="body1">
            {addressesEqual
              ? "Select the current member address:"
              : `Member ${patientName}'s address in RemoteIQ is different than
            Athena's. Please select the right address before proceeding:`}
          </Typography>
          <RadioGroup
            value={formik.values.addressSource}
            onChange={handleRadioButtonChange}
          >
            {pabPatientAddress ? (
              <StyledFormControlLabel
                key={AddressSourceEnum.PAB}
                value={AddressSourceEnum.PAB}
                control={<Radio />}
                label={
                  <>
                    <RadioGroupHeading>RemoteIQ</RadioGroupHeading>
                    <br />
                    {pabPatientAddress.street1}
                    {pabPatientAddress.street2
                      ? `, ${pabPatientAddress.street2}`
                      : ""}
                    ,&nbsp;{pabPatientAddress.city},&nbsp;
                    {pabPatientAddress.state},&nbsp;
                    {pabPatientAddress.country},&nbsp;
                    {pabPatientAddress.postal_code}
                  </>
                }
              />
            ) : (
              <ErrorText>No member address found in RemoteIQ</ErrorText>
            )}
            {athenaPatientAddress ? (
              <StyledFormControlLabel
                key={AddressSourceEnum.ATHENA}
                value={AddressSourceEnum.ATHENA}
                control={<Radio />}
                label={
                  <>
                    <RadioGroupHeading>Athena</RadioGroupHeading>
                    <br />
                    {athenaPatientAddress.street1}
                    {athenaPatientAddress.street2
                      ? `, ${athenaPatientAddress.street2}`
                      : ""}
                    ,&nbsp;{athenaPatientAddress.city},&nbsp;
                    {athenaPatientAddress.state},&nbsp;
                    {athenaPatientAddress.country},&nbsp;
                    {athenaPatientAddress.postal_code}
                  </>
                }
              />
            ) : (
              <ErrorText>No member address found in Athena</ErrorText>
            )}

            <Typography variant="body1" sx={{ marginTop: "8px" }}>
              Or update the address:
            </Typography>

            <StyledFormControlLabel
              key={AddressSourceEnum.INPUT}
              value={AddressSourceEnum.INPUT}
              control={<Radio />}
              label={<RadioGroupHeading>Enter new address</RadioGroupHeading>}
            />

            {formik?.values?.addressSource === AddressSourceEnum.INPUT && (
              <div style={{ transition: "height 0.66s ease-out" }}>
                <TextFieldComponent
                  sx={defaultFormStyles}
                  formik={formik}
                  fieldName={"street1"}
                  label={"Street 1"}
                  placeholder={"Enter Street 1"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                  disabled={
                    formik.values.addressSource !== AddressSourceEnum.INPUT
                  }
                />
                <TextFieldComponent
                  sx={defaultFormStyles}
                  formik={formik}
                  fieldName={"street2"}
                  label={"Street 2"}
                  placeholder={"Enter Street 2"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                  disabled={
                    formik.values.addressSource !== AddressSourceEnum.INPUT
                  }
                />
                <TextFieldComponent
                  sx={defaultFormStyles}
                  formik={formik}
                  fieldName={"city"}
                  label={"City"}
                  placeholder={"Enter City"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                  disabled={
                    formik.values.addressSource !== AddressSourceEnum.INPUT
                  }
                />
                <TextFieldComponent
                  sx={defaultFormStyles}
                  formik={formik}
                  fieldName={"state"}
                  label={"State"}
                  select={true}
                  slotProps={{
                    select: {
                      variant: "outlined",
                      MenuProps: { PaperProps: { sx: { maxHeight: 200 } } }
                    }
                  }}
                  placeholder={"Enter State"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                  disabled={
                    formik.values.addressSource !== AddressSourceEnum.INPUT
                  }
                >
                  {stateDropdownOptions.map((state) => (
                    <MenuItem key={state.value} value={state.value}>
                      {state.label}
                    </MenuItem>
                  ))}
                </TextFieldComponent>

                <TextFieldComponent
                  sx={defaultFormStyles}
                  formik={formik}
                  fieldName={"country"}
                  label={"Country"}
                  placeholder={"Enter Country"}
                  fullWidth={true}
                  select={true}
                  setFieldValue={setFieldValue}
                  value={"US"}
                  disabled={
                    formik.values.addressSource !== AddressSourceEnum.INPUT
                  }
                >
                  <MenuItem key="US" value="US">
                    US
                  </MenuItem>
                </TextFieldComponent>
                <TextFieldComponent
                  sx={defaultFormStyles}
                  formik={formik}
                  fieldName={"postal_code"}
                  label={"Postal Code"}
                  placeholder={"Enter Postal Code"}
                  fullWidth={true}
                  setFieldValue={setFieldValue}
                  disabled={
                    formik.values.addressSource !== AddressSourceEnum.INPUT
                  }
                />
              </div>
            )}
          </RadioGroup>
          <br />
          {updateMemberAddressError && (
            <ErrorComponent error={updateMemberAddressError} />
          )}

          {validateAddressError && (
            <ErrorComponent
              error={"Address validation is not available at this time."}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons>
            <WhiteButton onClick={handleModalClose}>Cancel</WhiteButton>
            <TurqoiseButton
              loading={validateAddressIsLoading || updateMemberAddressLoading}
              disabled={!formik.dirty || !formik.isValid}
              type="submit"
            >
              {validateAddressError ? "Use Address Anyway" : "Use Address"}
            </TurqoiseButton>
          </ModalFooterButtons>
        </ModalFooter>
      </Form>
      <EditAddressValidationModal
        key={`edit-address-validate-address-modal${selectedPatientId}`}
        modalOpen={validateAddressModalOpen}
        setModalOpen={closeBothModals}
        selectedPatientId={selectedPatientId}
        addresses={validateAddressData}
        executeOnSuccess={() => {
          setAddressChanged(true);
        }}
      />
    </StyledModal>
  );
};

export { EditAddressModal };
