import { Button } from "@mui/material";
import {
  ModalBody,
  ModalFooter,
  ModalFooterButtons,
  ModalHeader
} from "../../styling/StyleModal";
import Modal from "./Modal";

import AddTaskForm, {
  AddTaskFormType
} from "../../pages/ArcOfDay/ui/AddTaskForm";
import { useFormik } from "formik";
import { useCreateTaskMutation } from "common/services/TaskingService";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import ErrorComponent from "../ErrorComponent";
import useGetAuthenticatedUser from "common/hooks/useGetAuthenticatedUser";

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const CreateNewTaskModal = ({ isOpen, onRequestClose }: IProps) => {
  const [createTaskMutation, { isLoading, isSuccess, error }] =
    useCreateTaskMutation();

  const { data: authenticatedUser } = useGetAuthenticatedUser();

  const validate = (values) => {
    const errors = {};
    if (values.member_id === "") {
      errors["member_id"] = "Required Field";
    }
    if (values.assigned_user_id === null) {
      errors["assigned_user_id"] = "Required Field";
    }
    if (values.task_category === null) {
      errors["task_category"] = "Required Field";
    }
    if (values.priority_score === null) {
      errors["priority_score"] = "Required Field";
    }
    if (values.notes.length < 2) {
      errors["notes"] = "Required Field";
    }
    if (values.due_date === null) {
      errors["due_date"] = "Required Field";
    }
    return errors;
  };

  const onSubmit = (values: AddTaskFormType) => {
    createTaskMutation({
      body: {
        patient_id: values.member_id,
        staff_id: values.assigned_user_id,
        task_category: values.task_category,
        priority_score: values.priority_score,
        notes: values.notes,
        type: "GENERAL",
        due_date: values.due_date.toISO()
      }
    });
  };

  const { setFieldValue, values, handleSubmit, isValid, dirty, resetForm } =
    useFormik<AddTaskFormType>({
      validate,
      initialValues: {
        task_category: null,
        member_id: null,
        assigned_user_id: authenticatedUser?.user?.user_id,
        due_date: null,
        priority_score: null,
        notes: ""
      },
      onSubmit
    });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onRequestClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="CreateNewTaskModal"
    >
      <ModalHeader>New Task</ModalHeader>
      <ModalBody>
        <AddTaskForm setFieldValue={setFieldValue} values={values} />
        <br />
        <ErrorComponent error={error} />
      </ModalBody>

      <ModalFooter>
        <ModalFooterButtons>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            onClick={() => handleSubmit()}
            disabled={!isValid || !dirty}
          >
            Create
          </LoadingButton>
          <Button variant="outlined" onClick={onRequestClose}>
            Cancel
          </Button>
        </ModalFooterButtons>
      </ModalFooter>
    </Modal>
  );
};

export default CreateNewTaskModal;
