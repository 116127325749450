import DeviceReadingType from "common/types/DeviceReadingType";
import Table from "../../../components/Table/Table";

interface IProps {
  glucoseData: DeviceReadingType[];
  patientTimezone: string;
}

const GlucoseTable = ({ glucoseData, patientTimezone }: IProps) => {
  return (
    <Table
      data={glucoseData}
      tableContainerStyles={{ margin: "0 2.5%", width: "95%" }}
      tableProps={{ patientTimezone }}
      tableColumns={[
        { name: "readingMeasureTimestamp" },
        { name: "glucoseReading" },
        { name: "glucoseTag" }
      ]}
    />
  );
};

export default GlucoseTable;
