import React from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, RootState } from "common/redux";

import { Store } from "@reduxjs/toolkit";

const ReduxWrapper = ({
  children,
  store
}: {
  children: React.ReactNode | React.ReactNode[];
  store: Store<RootState>;
}) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>{children}</PersistGate>
    </Provider>
  );
};

export default ReduxWrapper;
