import { useState, useEffect } from "react";

const useDelayedRender = (delay: number) => {
  const [delayed, setDelayed] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay);
    return () => clearTimeout(timeout);
  }, []);
  return (fn: any) => !delayed && fn();
};

/**
 * A wrapper for other components to delay their render by a delay ms)
 * Usage example: showing loading indicators when they are really needed
 * and skipping them when the interaction is so fast that the user won’t notice the loading time.
 * 
 * Can be combined with React.Suspense
 * 
 * const Component = lazy(() => import("./some-large-component"));

<Suspense fallback={<DelayedRender delay={500}>Loading...</DelayedRender>}>
  <Component />
</Suspense>

 * @param  {String} delay  base URL for a datasource
 * @param  {import("react").ReactNode} children   React component(s)
 * 
 * 
 */

interface IProps {
  delay: number;
  children: React.ReactNode;
}

export const DelayedRender = ({ delay, children }: IProps) =>
  useDelayedRender(delay)(() => children);
