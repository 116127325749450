import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import RolesEnum from "../enums/RolesEnum";
import { filterByRole } from "../helpers/EncounterHelper";
import { checkIdValid } from "../helpers/helpers";
import VisitReasonsEnum from "../enums/Calendaring/Visits/VisitReasonsEnum";
import { useGetEncountersQuery } from "../services/EncountersService";
// import { useGetEncounterVisitsQuery } from "../services/VisitsService";

const getMTDSum = (encounters?: any[]) => {
  if (encounters === undefined) return 0;

  const now = DateTime.now();
  const encountersByRole = filterByRole(encounters, [
    RolesEnum.TH_NURSE,
    RolesEnum.THN_MANAGER
  ]);

  const mtdEncounters = encountersByRole?.filter((item) => {
    return (
      (DateTime.fromISO(item?.visit?.encounter_started_on).get("month") ===
        now.get("month") &&
        DateTime.fromISO(item?.visit?.encounter_started_on)?.get("year") ===
          now.get("year") &&
        [
          VisitReasonsEnum.REMOTE_PATIENT_MONITORING,
          VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING
        ].includes(item?.visit?.visit_type)) ||
      (DateTime.fromISO(item?.encounter?.starts_on).get("month") ===
        now.get("month") &&
        DateTime.fromISO(item?.encounter?.starts_on)?.get("year") ===
          now.get("year") &&
        [
          VisitReasonsEnum.REMOTE_PATIENT_MONITORING,
          VisitReasonsEnum.ASYNC_REMOTE_PATIENT_MONITORING
        ].includes(item?.encounter?.reason))
    );
  });

  const mtdEncountersSum = mtdEncounters?.reduce((prev, curr) => {
    const time = curr?.visit?.total_time ?? curr?.encounter?.duration;
    return prev + time;
  }, 0);

  return mtdEncountersSum;
};

const useGetEncountersMTD = (
  patient_id: string,
  startDate?: DateTime,
  endDate?: DateTime
) => {
  const isValidId = checkIdValid(patient_id);

  const [isLoaded, setIsLoaded] = useState(false);

  const {
    data: encounters,
    isError,
    error
  } = useGetEncountersQuery(
    // } = useGetEncounterVisitsQuery(
    {
      patient_id,
      startsAfter: startDate,
      startsBefore: endDate
      // encounter_starts_on_after: startDate,
      // encounter_starts_on_before: endDate
    },
    { skip: isValidId === false }
  );

  const mtdEncountersSum = useMemo(() => {
    const sum = getMTDSum(encounters);
    setIsLoaded(true);
    return sum;
  }, [encounters]);

  useEffect(() => {
    if (isError) {
      setIsLoaded(true);
    }
  }, [isError]);

  return { mtdEncountersSum, isLoaded, error };
};

export { useGetEncountersMTD };
