import {
  useEffect,
  useMemo,
  useState,
  useRef,
  Fragment,
  useLayoutEffect,
  useImperativeHandle,
  forwardRef
} from "react";
import { DateTime, Duration } from "luxon";
import { css } from "@emotion/react";
import {
  flexRender,
  getSortedRowModel,
  SortingState,
  getCoreRowModel,
  useReactTable,
  RowSelectionState,
  ColumnFiltersState,
  getFilteredRowModel,
  getFacetedUniqueValues
} from "@tanstack/react-table";
import { useWindowSize } from "@uidotdev/usehooks";

import { TableProps } from "./TableTypes";
import { getColumns } from "./helpers/TableHelpers";
import {
  getFilterFns,
  fuzzyFilterFn,
  defaultFilterFn,
  getFilters,
  orderStatusFilterFn,
  orderTypeFilterFn
} from "./helpers/TableFilterHelpers";
import isEqual from "lodash.isequal";
import TableCount from "./TableCount";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAppDispatch } from "common/redux";
import { blue, gray } from "../../styling/colors";
import { ExpandLess, ExpandMore, ArrowForward } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  styled,
  Typography,
  IconButton
} from "@mui/material";
import AttritionReasonType from "common/types/AttritionReasonType";
import { Flexbox } from "../../styling/NewStyleComponents";

const FiltersContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const BORDER_WIDTH = 1;

const TableContainer = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "tableMaxHeight" && prop !== "columnResizeEnabled"
})<{
  tableMaxHeight: string;
  columnResizeEnabled: boolean;
  containerWidth: number;
}>`
  border: ${BORDER_WIDTH}px solid ${gray[300]};
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 4px;
  width: ${({ containerWidth, columnResizeEnabled }) =>
    columnResizeEnabled ? containerWidth - BORDER_WIDTH * 2 : "fit-content"};
  overflow: hidden;
  min-height: 50px;
  max-height: ${(props) => parseInt(props.tableMaxHeight, 10) + 50}px;
  overflow-y: scroll;
`;

const DefaultStyledTable = styled("table", {
  shouldForwardProp: (prop) =>
    prop !== "tableBackground" && prop !== "columnResizeEnabled"
})<{ tableBackground?: string; columnResizeEnabled: boolean }>`
  border-spacing: 0;
  display: ${({ columnResizeEnabled }) =>
    columnResizeEnabled ? undefined : "block"};
  background: ${({ tableBackground }) => tableBackground ?? "#ffffff"};
`;

const DefaultStyledTR = styled("tr")`
  overflow-wrap: anywhere;
  font-size: 14px;
  text-color: ${(props) => props.theme.palette.secondary.main};
`;

const TABLE_PADDING = 14;

const DefaultStyledTH = styled("th")`
  text-align: left;
  padding: ${TABLE_PADDING + "px"};
  border-bottom: 1px solid ${gray[300]};
  color: ${blue[700]};
`;

const DefaultStyledTD = styled("td")`
  margin: 0;
  padding: ${TABLE_PADDING + "px"};
  align-items: center;
  border-spacing: 0;
  border-bottom: 1px solid ${gray[300]};
  :first-of-type {
    border-top: 0;
  }
`;

const StyledTHead = styled("thead")`
  position: sticky;
  top: 0px;
  background-color: white;
  box-shadow:
    0 0px 4px 0 rgba(16, 24, 40, 0.06),
    0 0px 8px 0 rgba(24, 24, 40, 0.1);
  z-index: 1;
`;

function Rows({
  virtualRows,
  rows,
  tableClassName,
  totalSize,
  columnResizeEnabled,
  onRowClick
}) {
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return (
    <>
      {paddingTop > 0 && (
        <tr>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}

      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        if (row) {
          return (
            <DefaultStyledTR
              key={row.id}
              //style={{ cursor: onRowClick ? "pointer" : "default" }}
              //onClick={() => onRowClick?.(row)}
            >
              {row.getVisibleCells().map((cell) => (
                <DefaultStyledTD
                  className={`td ${tableClassName}`}
                  key={cell.id}
                  style={{
                    width: columnResizeEnabled
                      ? cell.column.getSize()
                      : undefined,
                    minWidth: cell.column.getSize()
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </DefaultStyledTD>
              ))}

              {onRowClick && (
                <DefaultStyledTD
                  style={{
                    width: columnResizeEnabled ? "40px" : undefined,
                    minWidth: "40px"
                  }}
                >
                  <IconButton onClick={() => onRowClick(row)}>
                    <ArrowForward color="primary" />
                  </IconButton>
                </DefaultStyledTD>
              )}
            </DefaultStyledTR>
          );
        }
      })}
      {paddingBottom > 0 && (
        <tr>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
    </>
  );
}

export interface TableHandleType {
  selectAll: () => void;
  deselectAll: () => void;
}

const Table = forwardRef<TableHandleType, TableProps>(
  (props: TableProps, ref) => {
    const {
      tableClassName = "defaultTableClassName",
      tableProps = {},
      data,
      noDataText = "No data found.",
      noDataButtonText = null,
      noDataButtonOnClick = () => {},
      tableColumns,
      enableMultiRowSelection = false,
      setSelectionCallback = (state: any) => {},
      tableMaxHeight = "500px",
      tableHeader,
      tableContainerStyles = {},
      estimateRowSize = () => 65,
      onRowClick,
      initialSortingState = [],
      disableColumnResize = false,
      showTableCount = true
    } = props;

    const dispatch = useAppDispatch();
    const windowSize = useWindowSize();

    //const rerender = useReducer(() => ({}), {})[1];

    const resizeTableContainer = useRef<HTMLDivElement>(null);
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [sorting, setSorting] = useState<SortingState>(initialSortingState);
    const [columnResizeEnabled, setColumnResizeEnabled] =
      useState<boolean>(false);

    // don't think we can combine these two functions to export an object with both fields
    // tried it and it doesn't work :I
    const finalColumns: Array<any> = useMemo(
      () => getColumns(tableColumns, { ...tableProps, dispatch }),
      [tableColumns, tableProps]
    );

    const columnWidth = useMemo(() => {
      let columnWidth = 0;
      finalColumns.forEach((column) => {
        columnWidth += column.size + 2 * TABLE_PADDING;
      });
      return columnWidth;
    }, [finalColumns]);

    useLayoutEffect(() => {
      if (disableColumnResize) return;

      const container = resizeTableContainer?.current;
      if (!container) return;
      const containerWidth = container.clientWidth;

      setColumnResizeEnabled(containerWidth >= columnWidth);
    }, [
      windowSize,
      columnWidth,
      resizeTableContainer?.current,
      disableColumnResize
    ]);

    useImperativeHandle(ref, () => {
      return {
        selectAll: () => {
          //@ts-ignore
          table.setRowSelection(data);
          setSelectionCallback(data);
        },
        deselectAll: () => {
          table.resetRowSelection(true);
          setSelectionCallback([]);
        }
      };
    });

    const finalFilterFns = getFilterFns(tableColumns);
    const filterKeys = useMemo(
      () => Object.keys(finalFilterFns),
      [finalFilterFns]
    );

    function handleSingleRowSelection(getRowSelection: any) {
      const tableRowSelection = getRowSelection();
      if (!isEqual(rowSelection, tableRowSelection)) {
        setRowSelection(tableRowSelection);
      }
    }

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    function dateTimeSortingMMDDYYYY(
      rowA: any,
      rowB: any,
      columnId: any
    ): number {
      const dateAString = rowA.getValue(columnId);
      const dateBString = rowB.getValue(columnId);

      const dateA = dateAString
        ? DateTime.fromFormat(dateAString, "MM/dd/yyyy")
        : 0;
      const dateB = dateBString
        ? DateTime.fromFormat(dateBString, "MM/dd/yyyy")
        : 0;

      if (dateA === dateB) return 0;
      return dateA < dateB ? 1 : -1;
    }

    function dateTimeSortingISO(rowA: any, rowB: any, columnId: any): number {
      const dateAString = rowA.getValue(columnId);
      const dateBString = rowB.getValue(columnId);

      const dateA = dateAString ? DateTime.fromISO(dateAString) : 0;
      const dateB = dateBString ? DateTime.fromISO(dateBString) : 0;

      if (dateA === dateB) return 0;
      return dateA < dateB ? 1 : -1;
    }

    function dateTimeSortingSQL(rowA: any, rowB: any, columnId: any): number {
      const dateAString = rowA.getValue(columnId);
      const dateBString = rowB.getValue(columnId);

      const dateA = dateAString ? DateTime.fromSQL(dateAString) : 0;
      const dateB = dateBString ? DateTime.fromSQL(dateBString) : 0;

      if (dateA === dateB) return 0;
      return dateA < dateB ? 1 : -1;
    }

    function numberSorting(rowA: any, rowB: any, columnId: any): number {
      const valueA = parseInt(rowA.getValue(columnId));
      const valueB = parseInt(rowB.getValue(columnId));

      if (valueA === valueB) return 0;
      return valueA > valueB ? 1 : -1;
    }

    function categorySorting(rowA: any, rowB: any, columnId: any): number {
      const reasonA = rowA.getValue(columnId) as AttritionReasonType[];
      const reasonB = rowB.getValue(columnId) as AttritionReasonType[];
      if (!reasonA || reasonA.length === 0) return -1;
      if (!reasonB || reasonB.length === 0) return 1;

      const filteredA = reasonA.filter(({ question }) => question !== "Notes");
      const valueA = filteredA.length > 0 ? filteredA[0].category : "N/A";

      const filteredB = reasonB.filter(({ question }) => question !== "Notes");
      const valueB = filteredB.length > 0 ? filteredB[0].category : "N/A";

      return valueA.localeCompare(valueB);
    }

    function answerSorting(rowA: any, rowB: any, columnId: any): number {
      const reasonA = rowA.getValue(columnId) as AttritionReasonType[];
      const reasonB = rowB.getValue(columnId) as AttritionReasonType[];
      if (!reasonA || reasonA.length === 0) return -1;
      if (!reasonB || reasonB.length === 0) return 1;

      const filteredA = reasonA.filter(({ question }) => question !== "Notes");
      const valueA = filteredA.length > 0 ? filteredA[0].answer : "N/A";

      const filteredB = reasonB.filter(({ question }) => question !== "Notes");
      const valueB = filteredB.length > 0 ? filteredB[0].answer : "N/A";

      return valueA.localeCompare(valueB);
    }

    function ptoAffectedAppointmentsSorting(
      rowA: any,
      rowB: any,
      columnId: any
    ): number {
      const valueA = rowA.getValue(columnId);
      const valueB = rowB.getValue(columnId);

      const affectedAppointmentsA = valueA?.[0]?.affected_appointments;
      const affectedAppointmentsB = valueB?.[0]?.affected_appointments;

      return affectedAppointmentsB - affectedAppointmentsA;
    }

    function ptoPolicySorting(rowA: any, rowB: any, columnId: any): number {
      const valueA = rowA.getValue(columnId);
      const valueB = rowB.getValue(columnId);

      const affectedAppointmentsA = valueA?.[0]?.appointment_type;
      const affectedAppointmentsB = valueB?.[0]?.appointment_type;

      return affectedAppointmentsB?.localeCompare(affectedAppointmentsA);
    }

    function ptoNursePeriodSorting(
      rowA: any,
      rowB: any,
      columnId: any
    ): number {
      const timezone = rowA?.original?.staff?.timezone;

      const valueA = rowA.getValue(columnId);
      const valueB = rowB.getValue(columnId);

      const startA = DateTime.fromISO(valueA?.[0]?.["start_time"]).setZone(
        timezone
      );
      const startB = DateTime.fromISO(valueB?.[0]?.["start_time"]).setZone(
        timezone
      );

      if (startB?.diff(startA)?.as("milliseconds") === 0) {
        const endA = DateTime.fromISO(valueA?.[0]?.["end_time"]).setZone(
          timezone
        );
        const endB = DateTime.fromISO(valueB?.[0]?.["end_time"]).setZone(
          timezone
        );

        return endB?.diff(endA)?.as("milliseconds");
      }

      return startB?.diff(startA)?.as("milliseconds");
    }

    function ptoDurationSorting(rowA: any, rowB: any, columnId: any): number {
      const valueA = rowA.getValue(columnId);
      const valueB = rowB.getValue(columnId);

      const durationA = Duration.fromISO(valueA?.[0]?.duration)?.as(
        "milliseconds"
      );
      const durationB = Duration.fromISO(valueB?.[0]?.duration)?.as(
        "milliseconds"
      );

      return durationB - durationA;
    }

    const table = useReactTable({
      data,
      columns: finalColumns,
      enableColumnResizing: columnResizeEnabled,
      columnResizeMode: "onChange",
      // Improvement opportunity: if we can use the finalFilterFns variable here, that would be better. It wasn't working and this works for now
      filterFns: {
        fuzzy: fuzzyFilterFn,
        username: fuzzyFilterFn,
        name: fuzzyFilterFn,
        roles: defaultFilterFn,
        orderStatus: orderStatusFilterFn,
        orderType: orderTypeFilterFn
      },
      sortingFns: {
        dateTimeSortingMMDDYYYY,
        dateTimeSortingISO,
        dateTimeSortingSQL,
        numberSorting,
        category: categorySorting,
        answer: answerSorting,
        ptoAffectedAppointmentsSorting,
        ptoPolicySorting,
        ptoNursePeriodSorting,
        ptoDurationSorting
      },
      onColumnFiltersChange: setColumnFilters,
      state: {
        columnFilters,
        sorting,
        rowSelection
      },
      onRowSelectionChange: enableMultiRowSelection
        ? setRowSelection
        : handleSingleRowSelection,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFacetedUniqueValues: getFacetedUniqueValues()
    });

    const { rows } = table.getRowModel();

    const rowVirtualizer = useVirtualizer({
      count: rows.length,
      getScrollElement: () => tableContainerRef.current,
      estimateSize: estimateRowSize,
      overscan: 10
    });

    const totalSize = rowVirtualizer.getTotalSize();
    const virtualRows = rowVirtualizer.getVirtualItems();

    useEffect(() => {
      // when row selection changes, grab the values from table and pass it to the callback
      const selectedRows = table
        .getSelectedRowModel()
        .flatRows.map((row) => row.original);
      setSelectionCallback(selectedRows);
    }, [rowSelection]);

    const tableHeaders = table.getHeaderGroups()?.[0]?.headers;
    const filterComponents = useMemo(() => {
      return getFilters(tableHeaders, filterKeys);
    }, [tableHeaders, filterKeys]);

    if (data && data.length === 0) {
      return (
        <Box
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          margin={"10px"}
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
              gap: "10px",
              width: "100%"
            }}
          >
            <Typography
              variant="h6"
              color="text.secondary"
              textAlign={"center"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              margin={"10px 0px"}
            >
              {noDataText}
            </Typography>

            {noDataButtonText && noDataButtonOnClick && (
              <Flexbox marginBottom={"20px"}>
                <Button variant="contained" onClick={noDataButtonOnClick}>
                  {noDataButtonText}
                </Button>
              </Flexbox>
            )}
          </Card>
        </Box>
      );
    }

    return (
      <>
        {/* Filters */}
        <FiltersContainer>
          {filterComponents.map((filterComponent) => {
            const key = filterComponent.key;
            return <Fragment key={key}>{filterComponent}</Fragment>;
          })}
        </FiltersContainer>
        {showTableCount && (
          <TableCount
            data={table.getRowModel().rows}
            tableHeader={tableHeader}
            tableHeaders={tableHeaders}
          />
        )}
        <div ref={resizeTableContainer}>
          <TableContainer
            tableMaxHeight={tableMaxHeight}
            ref={tableContainerRef}
            columnResizeEnabled={columnResizeEnabled}
            style={tableContainerStyles}
            containerWidth={resizeTableContainer?.current?.clientWidth}
          >
            <DefaultStyledTable
              tableBackground={tableProps?.tableBackground}
              columnResizeEnabled={columnResizeEnabled}
              className={tableClassName}
              style={{
                width: columnResizeEnabled ? "100%" : "auto"
              }}
            >
              <StyledTHead>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <DefaultStyledTR key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        const columnDef = header.column.columnDef;
                        // we are adding the below props to the columnDef
                        // react-table's columnDef does not have them
                        // TBD - override in the @types folder
                        const headerContentCss =
                          // @ts-ignore
                          columnDef.headerContentCss || "";

                        return (
                          <DefaultStyledTH
                            key={header.id}
                            colSpan={header.colSpan}
                            style={{
                              width: columnResizeEnabled
                                ? header.getSize()
                                : undefined,
                              minWidth: header.getSize()
                            }}
                          >
                            {header.isPlaceholder ? null : (
                              <div
                                css={css`
                                  ${headerContentCss}
                                `}
                                style={{
                                  cursor: header.column.getCanSort()
                                    ? "pointer"
                                    : "text",
                                  display: "flex",
                                  alignItems: "center"
                                }}
                                className={
                                  header.column.getCanSort()
                                    ? `cursor-pointer select-none`
                                    : ""
                                }
                                onClick={header.column.getToggleSortingHandler()}
                                onKeyDown={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {{
                                  desc: (
                                    <div>
                                      &nbsp; <ExpandLess fontSize={"small"} />
                                    </div>
                                  ),
                                  asc: (
                                    <div>
                                      &nbsp; <ExpandMore fontSize={"small"} />
                                    </div>
                                  )
                                }[header.column.getIsSorted() as string] ??
                                  null}
                              </div>
                            )}
                          </DefaultStyledTH>
                        );
                      })}

                      {onRowClick && (
                        <DefaultStyledTH
                          style={{
                            width: columnResizeEnabled ? "40px" : undefined,
                            minWidth: "40px"
                          }}
                        ></DefaultStyledTH>
                      )}
                    </DefaultStyledTR>
                  );
                })}
              </StyledTHead>
              <tbody
                data-testid="tableBody"
                style={{
                  display: columnResizeEnabled ? undefined : "block"
                  // if we decide to pass in more styles as a prop, we can destructure
                  // ...styles
                }}
              >
                <Rows
                  rows={rows}
                  virtualRows={virtualRows}
                  tableClassName={tableClassName}
                  totalSize={totalSize}
                  columnResizeEnabled={columnResizeEnabled}
                  onRowClick={onRowClick}
                />
              </tbody>
              {/* Commenting out the footer for now since we are not using it in any of our tables */}
              {/* <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <DefaultStyledTR key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <DefaultStyledTH key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </DefaultStyledTH>
              ))}
            </DefaultStyledTR>
          ))}
        </tfoot> */}
            </DefaultStyledTable>
          </TableContainer>
        </div>
      </>
    );
  }
);

export default Table;
