import { AppDispatch } from "common/redux";
import { addTime } from "common/redux/VisitsSlice";
import { CareFlowResponseType } from "common/types/Visits/CareFlowResponseType";
import GetVisitResponseType from "common/types/Visits/GetVisitResponseType";
import { DateTime } from "luxon";

const getField = async (careFlow: CareFlowResponseType, fieldId: string) => {
  // Loop through fields recursively to find the field
  for (const section of careFlow.sections) {
    for (const subsection of section?.subsections) {
      for (const field of subsection?.fields) {
        if (fieldId === field.field_id) return field;
        if (field.subfields) {
          for (const subfield of field?.subfields) {
            if (fieldId === subfield?.field_id) return subfield;
            if (subfield.subfields) {
              for (const subsubfield of subfield?.subfields) {
                if (fieldId === subsubfield?.field_id) return subsubfield;
                if (subsubfield.subfields) {
                  for (const subsubsubfield of subsubfield?.subfields) {
                    if (fieldId === subsubsubfield.field_id)
                      return subsubsubfield;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // If found, return the value of that field, else return null
  return null;
};

const getElapsedTime = (visit: GetVisitResponseType) => {
  let elapsedStartedTotalDateTime: DateTime = null;
  if (visit) {
    const now = DateTime.now();
    const visitCreatedAt = DateTime.fromISO(visit.created_at);
    const diffSeconds = now.diff(visitCreatedAt, "seconds");
    const delaySeconds = 1;
    elapsedStartedTotalDateTime = DateTime.fromSeconds(
      diffSeconds.seconds - delaySeconds < 0
        ? 0
        : diffSeconds.seconds - delaySeconds
    );
  }
  return elapsedStartedTotalDateTime;
};

const tickElapsedTime = (
  dispatch: AppDispatch,
  lastProcessedTime: DateTime
) => {
  lastProcessedTime = DateTime.now();
  return setInterval(() => {
    // We need to calculate previus vs new one because setInterval is not accurate on background tabs
    // This will cause that once the user returns to the tab, he will get processed all the seconds he lost.
    const now = DateTime.now();
    const diffsSeconds = now.diff(lastProcessedTime, "seconds").seconds;
    lastProcessedTime = now;
    dispatch(addTime({ actual_time_seconds: Math.round(diffsSeconds) }));
  }, 1000);
};

const safeRefetch = (refetch: () => void) => {
  try {
    refetch();
  } catch (error) {
    console.log("error during refetch: " + error);
  }
};

export { getField, getElapsedTime, tickElapsedTime, safeRefetch };
