import { mkConfig, generateCsv, download } from "export-to-csv";
import { MutableRefObject } from "react";
import ReportRefInterface from "../pages/Reports/report_types/ReportRefInterface";

export const CsvHelper_download = (
  ref: MutableRefObject<ReportRefInterface>
) => {
  const { filename, columns, data } = ref.current.getCSVReportData();

  const mappedData = data.map((item) => {
    const object = {};
    columns.forEach((column, index) => {
      object[column] = item[index] ?? "N/A";
    });

    return object;
  });

  const csvConfig = mkConfig({
    filename: filename,
    columnHeaders: columns
  });

  const csv = generateCsv(csvConfig)(mappedData);

  download(csvConfig)(csv);
};
