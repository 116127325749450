enum VisitReasonsEnum {
  INITIAL_VISIT = "INITIAL_VISIT",
  REMOTE_PATIENT_MONITORING = "REMOTE_PATIENT_MONITORING",
  CHRONIC_DISEASE_MANAGEMENT = "CHRONIC_DISEASE_MANAGEMENT",
  ASYNC_REMOTE_PATIENT_MONITORING = "ASYNC_REMOTE_PATIENT_MONITORING",
  REACTIVATING = "REACTIVATING",

  // new reasons
  PROVIDER_INTAKE = "PROVIDER_INTAKE",
  PROVIDER_FOLLOWUP = "PROVIDER_FOLLOWUP",
  NURSE_FOLLOWUP = "NURSE_FOLLOWUP",
  DEVICE_SETUP = "DEVICE_SETUP",
  ASYNC_DEVICE_SETUP = "ASYNC_DEVICE_SETUP",
  NURSE_INTAKE = "NURSE_INTAKE"
}

export default VisitReasonsEnum;
