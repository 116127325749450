import styled from "@emotion/styled";
import { DateTime } from "luxon";

import OrderMemberType from "common/types/OrderMemberType";
import { FormLabel } from "../../styling/StyleComponents";
import { getDeviceInfoBySku } from "../helpers";

const OrderItems = styled.li`
  color: ${(props) => props.theme.color.disabledInputFieldDark};
  padding: 5px 0;
`;

interface IProps {
  orders: OrderMemberType[];
  deviceType?: string;
  n?: number;
  showRecentOrdersHeading?: boolean;
  recentOrdersHeading?: string;
}

const RenderLastNOrders = ({
  orders,
  deviceType = "DEVICE",
  n = 2,
  showRecentOrdersHeading = false,
  recentOrdersHeading = "Recent Member Orders"
}: IProps) => {
  let lastNOrders = orders?.slice(0, n);

  return (
    <>
      {lastNOrders?.length > 0 && (
        <>
          {showRecentOrdersHeading && (
            <FormLabel as="div">{recentOrdersHeading}:</FormLabel>
          )}
          <ul data-testid="DevicesOrdered">
            {orders?.slice(0, n).map((order) => {
              const orderDate = DateTime.fromSQL(
                order?.order?.created_at
              )?.toLocaleString();

              let items = order?.order?.items;

              items = items?.filter((item) => {
                const deviceInfo = getDeviceInfoBySku(item?.sku);
                const { sku_type } = deviceInfo || {};
                return sku_type === deviceType;
              });

              let itemsString = "";
              items.map((item, index) => {
                const { quantity, sku } = item || {};
                const deviceInfo = getDeviceInfoBySku(sku);
                const { description } = deviceInfo || {};

                if (description && quantity) {
                  itemsString += `${description} x${quantity}`;
                } else {
                  itemsString += `${sku} x${quantity}`;
                }

                // add comma except for last item
                if (index !== items.length - 1) {
                  itemsString += ", ";
                }

                return item;
              });
              return (
                <OrderItems key={order.order.order_id}>
                  {orderDate}: {itemsString}
                </OrderItems>
              );
            })}
          </ul>
        </>
      )}
    </>
  );
};

export default RenderLastNOrders;
