import DeviceReadingType from "common/types/DeviceReadingType";
import Table from "../../../components/Table/Table";

interface IProps {
  bloodPressureData: DeviceReadingType[];
  patientTimezone: string;
}

const BloodPressureTable = ({ bloodPressureData, patientTimezone }: IProps) => {
  return (
    <Table
      data={bloodPressureData}
      tableProps={{ patientTimezone }}
      tableContainerStyles={{ margin: "0 2.5%", width: "95%" }}
      tableColumns={[
        { name: "readingMeasureTimestamp" },
        { name: "systolic" },
        { name: "diastolic" },
        { name: "pulse" }
      ]}
    />
  );
};

export default BloodPressureTable;
