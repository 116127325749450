import { Box, Checkbox, MenuItem, TextField, Typography } from "@mui/material";

import { Flexbox } from "../../../styling/NewStyleComponents";

import { formatName, isFalsy } from "common/helpers/helpers";
import useSanitizedParams from "../../../hooks/useSanitizedParams";
import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { useUpdateMemberMetadataMutation } from "common/services/MemberService";
import RecurrenceFrequencyEnum from "common/enums/Calendaring/Appointments/RecurrenceFrequencyEnum";
import { FormikProps, useFormik } from "formik";
import { gray } from "../../../styling/colors";
import ErrorComponent from "../../../components/ErrorComponent";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import { Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import {
  setRecurrence,
  setStep2IsLoading,
  setStep2SelectionsAreValid
} from "common/redux/StartIntakeSlice";
import StartIntakePreferencesFormType from "common/types/Calendaring/TimePreferences/StartIntakePreferencesFormType";
import {
  PreferencesForm,
  BorderedCheckboxContainer,
  FirstColumn,
  SecondColumn,
  RowContainer
} from "./StyledComponents";
import { WEEKDAYS } from "common/helpers/CalendarHelper";

const Step2 = forwardRef(
  (props: any, ref: Ref<FormikProps<StartIntakePreferencesFormType>>) => {
    const params = useSanitizedParams();
    const { memberId } = params;
    const dispatch = useAppDispatch();

    const {
      preferencesFormDefaults,
      appointmentPreferences,
      onNext,
      refetchPatientMetadata
    } = props;

    const [
      updateMemberMetadata,
      {
        error: memberMetadataError,
        isSuccess: isSuccessMemberMetadata,
        isError: isErrorMemberMetadata,
        reset: resetMemberMetadata
      }
    ] = useUpdateMemberMetadataMutation();

    useEffect(() => {
      if (isSuccessMemberMetadata) {
        // refetch patient metadata when the component unmounts to update the previous step.
        // We need this here to handle the case where the original request gave a 404 not found
        // in that case there is no cache invalidation triggered so we manually trigger
        refetchPatientMetadata();

        dispatch(setStep2IsLoading(false));

        onNext();
        resetMemberMetadata();
      }
    }, [isSuccessMemberMetadata]);

    useEffect(() => {
      if (isErrorMemberMetadata) {
        dispatch(setStep2IsLoading(false));
        Alert_show({
          dispatch,
          id: "validateAddressError",
          title: "Error",
          content: <ErrorComponent error={memberMetadataError} />,
          type: "warning",
          size: "small"
        });
        resetMemberMetadata();
      }
    });

    const validate = (values) => {
      const errors: { [key: string]: string } = {};

      if (isFalsy(values.frequency)) {
        errors["frequency"] = "Please select a cadence";
      }

      if (
        // if no boxes are checked
        !values.timePreferences.some(
          (item) => item.MORNING || item.MIDDAY || item.AFTERNOON
        )
      ) {
        errors["timePreferences"] =
          "Please select at least one time preference";
      }

      return errors;
    };

    const onSubmit = async (values) => {
      const timesOfDay = {};
      values.timePreferences.forEach((item) => {
        const arr = [];
        Object.keys(item).forEach((i) => {
          if (item[i] === true) {
            arr.push(i);
          }
        });
        if (arr.length > 0) {
          timesOfDay[item.day] = arr;
        }
      });

      const isFrequencyChanged =
        preferencesFormDefaults.frequency !== values.frequency;

      dispatch(setRecurrence(values.frequency));

      const isTimeChanged = !Object.keys(preferencesFormDefaults).every(
        (day) => {
          const isMorningTrue = preferencesFormDefaults[day].MORNING;
          const isMiddayTrue = preferencesFormDefaults[day].MIDDAY;
          const isAfternoonTrue = preferencesFormDefaults[day].AFTERNOON;

          return (
            (isMorningTrue
              ? timesOfDay[day]?.includes("MORNING")
              : !timesOfDay[day]?.includes("MORNING")) &&
            (isMiddayTrue
              ? timesOfDay[day]?.includes("MIDDAY")
              : !timesOfDay[day]?.includes("MIDDAY")) &&
            (isAfternoonTrue
              ? timesOfDay[day]?.includes("AFTERNOON")
              : !timesOfDay[day]?.includes("AFTERNOON"))
          );
        }
      );

      if (isFrequencyChanged || isTimeChanged) {
        dispatch(setStep2IsLoading(true));
        updateMemberMetadata({
          memberId: memberId,
          data: {
            preferences: {
              appointment: {
                ...(isTimeChanged && { times_of_day: timesOfDay }),
                ...(isFrequencyChanged && { frequency: values.frequency })
              }
            }
          }
        });
      } else {
        onNext();
      }
    };

    const formik = useFormik<StartIntakePreferencesFormType>({
      validate,
      initialValues: {
        frequency: preferencesFormDefaults.frequency,
        timePreferences: [
          {
            day: "MONDAY",
            ...preferencesFormDefaults.MONDAY
          },
          {
            day: "TUESDAY",
            ...preferencesFormDefaults.TUESDAY
          },
          {
            day: "WEDNESDAY",
            ...preferencesFormDefaults.WEDNESDAY
          },
          {
            day: "THURSDAY",
            ...preferencesFormDefaults.THURSDAY
          },
          {
            day: "FRIDAY",
            ...preferencesFormDefaults.FRIDAY
          }
        ]
      },
      onSubmit,
      innerRef: ref,
      enableReinitialize: true
    });

    useImperativeHandle(ref, () => ({
      ...formik
    }));

    useEffect(() => {
      if (formik?.isValid) {
        // have a small timeout to handle a redux race condition
        setTimeout(() => {
          dispatch(setStep2SelectionsAreValid(true));
        }, 50);
      }

      return () => {
        // have a small timeout to handle a redux race condition
        setTimeout(() => {
          dispatch(setStep2SelectionsAreValid(false));
        }, 50);
      };
    }, []);

    useEffect(() => {
      if (formik?.isValid) {
        // have a small timeout to handle a redux race condition
        setTimeout(() => {
          dispatch(setStep2SelectionsAreValid(true));
        }, 50);
      } else if (!formik?.isValid) {
        // have a small timeout to handle a redux race condition
        setTimeout(() => {
          dispatch(setStep2SelectionsAreValid(false));
        }, 50);
      }
    }, [formik?.isValid]);

    const [checkedArray, setCheckedArray] = useState([
      {
        day: "MONDAY",
        ...preferencesFormDefaults.MONDAY
      },
      {
        day: "TUESDAY",
        ...preferencesFormDefaults.TUESDAY
      },
      {
        day: "WEDNESDAY",
        ...preferencesFormDefaults.WEDNESDAY
      },
      {
        day: "THURSDAY",
        ...preferencesFormDefaults.THURSDAY
      },
      {
        day: "FRIDAY",
        ...preferencesFormDefaults.FRIDAY
      }
    ]);

    useEffect(() => {
      formik.setFieldValue("timePreferences", checkedArray);
    }, [checkedArray]);

    const isAllChecked = (arr) => {
      return arr.every((item) => {
        return item.MORNING && item.MIDDAY && item.AFTERNOON;
      });
    };

    const isAnyChecked = (arr) => {
      return arr.some((item) => {
        return item.MORNING || item.MIDDAY || item.AFTERNOON;
      });
    };

    const handleAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCheckedArray = [...checkedArray].map((item) => {
        return {
          day: item.day,
          MORNING: event.target.checked,
          MIDDAY: event.target.checked,
          AFTERNOON: event.target.checked
        };
      });
      setCheckedArray(newCheckedArray);
    };

    const isAllRowChecked = (arr, timeOfDay) => {
      return arr.every((item) => {
        return item[timeOfDay];
      });
    };

    const isAnyRowChecked = (arr, timeOfDay) => {
      return arr.some((item) => {
        return item[timeOfDay];
      });
    };

    const handleBoxChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      day,
      timeOfDay
    ) => {
      const newCheckedArray = [...checkedArray].map((item) => {
        if (item.day === day) {
          return {
            ...item,
            [timeOfDay]: event.target.checked
          };
        }

        return item;
      });

      setCheckedArray(newCheckedArray);
    };

    const isBoxChecked = (arr, day, timeOfDay) => {
      const item = arr.find((item) => item.day === day);

      return item?.[timeOfDay];
    };

    const handleAllRowChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      timeOfDay
    ) => {
      const newCheckedArray = [...checkedArray].map((item) => {
        return {
          ...item,
          [timeOfDay]: event.target.checked
        };
      });

      setCheckedArray(newCheckedArray);
    };

    return (
      <PreferencesForm>
        {(appointmentPreferences.isSuccess ||
          appointmentPreferences.isError) && (
          <>
            <Flexbox justifyContent="space-between" marginBottom="12px">
              <Flexbox alignItems="center">
                <Typography variant="body1" fontWeight={500}>
                  Set Time & Cadence Preferences
                </Typography>
              </Flexbox>

              <Flexbox gap="12px" alignItems="center">
                <Typography variant="body1" fontWeight={600}>
                  Cadence
                </Typography>
                <TextField
                  value={formik.values.frequency}
                  onChange={(event) => {
                    dispatch(setRecurrence(event.target.value));
                    formik.setFieldValue("frequency", event.target.value);
                  }}
                  select
                  sx={{ width: "356px", backgroundColor: "white" }}
                  label="Select an option"
                  placeholder="Select an option"
                  data-testid="Select an option"
                >
                  <MenuItem
                    data-testid={"weekly"}
                    key={"weekly"}
                    value={RecurrenceFrequencyEnum.WEEKLY}
                  >
                    Weekly
                  </MenuItem>
                  <MenuItem
                    data-testid={"biweekly"}
                    key={"biweekly"}
                    value={RecurrenceFrequencyEnum.BIWEEKLY}
                  >
                    Biweekly
                  </MenuItem>
                </TextField>
              </Flexbox>
            </Flexbox>
            <Box textAlign="right" marginBottom="12px" height="20px">
              {formik.errors?.frequency && formik.touched && (
                <ErrorComponent error={formik.errors?.frequency} />
              )}
            </Box>
            <Box
              bgcolor="white"
              padding="32px"
              sx={{
                display: "grid",
                justifyItems: "center",
                rowGap: "16px"
              }}
            >
              <RowContainer>
                <Flexbox marginRight={"24px"}>
                  <FirstColumn>
                    <BorderedCheckboxContainer>
                      <Checkbox
                        checked={isAllChecked(checkedArray)}
                        indeterminate={
                          isAnyChecked(checkedArray) &&
                          !isAllChecked(checkedArray)
                        }
                        onChange={handleAllChange}
                      />
                    </BorderedCheckboxContainer>
                  </FirstColumn>
                  <SecondColumn>
                    <div>&nbsp;</div>
                  </SecondColumn>
                </Flexbox>
                <Flexbox
                  justifyContent="space-between"
                  width="100%"
                  marginRight={"24px"}
                >
                  {WEEKDAYS.map((day) => (
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      lineHeight="24px"
                    >
                      {formatName(day)}
                    </Typography>
                  ))}
                </Flexbox>
              </RowContainer>

              <RowContainer
                border={`1px solid ${gray[300]}`}
                bgcolor={gray[50]}
                borderRadius="12px"
                padding="16px 0"
              >
                <Flexbox marginRight={"24px"}>
                  <FirstColumn>
                    <BorderedCheckboxContainer>
                      <Checkbox
                        data-testid="Morning"
                        checked={isAllRowChecked(checkedArray, "MORNING")}
                        indeterminate={
                          isAnyRowChecked(checkedArray, "MORNING") &&
                          !isAllRowChecked(checkedArray, "MORNING")
                        }
                        onChange={(e) => {
                          handleAllRowChange(e, "MORNING");
                        }}
                      />
                    </BorderedCheckboxContainer>
                  </FirstColumn>
                  <SecondColumn
                    borderLeft={`1px solid ${gray[300]}`}
                    borderRight={`1px solid ${gray[300]}`}
                  >
                    <Typography variant="body1">Morning</Typography>
                    <Typography variant="body1">8 - 11 a.m.</Typography>
                  </SecondColumn>
                </Flexbox>
                <Flexbox
                  justifyContent="space-between"
                  width="100%"
                  marginRight={"24px"}
                >
                  {WEEKDAYS.map((day) => (
                    <BorderedCheckboxContainer key={day}>
                      <Checkbox
                        checked={isBoxChecked(checkedArray, day, "MORNING")}
                        onChange={(e) => handleBoxChange(e, day, "MORNING")}
                      />
                    </BorderedCheckboxContainer>
                  ))}
                </Flexbox>
              </RowContainer>

              <RowContainer
                border={`1px solid ${gray[300]}`}
                bgcolor={gray[50]}
                borderRadius="12px"
                padding="16px 0"
              >
                <Flexbox marginRight={"24px"}>
                  <FirstColumn>
                    <BorderedCheckboxContainer>
                      <Checkbox
                        data-testid="Midday"
                        checked={isAllRowChecked(checkedArray, "MIDDAY")}
                        indeterminate={
                          isAnyRowChecked(checkedArray, "MIDDAY") &&
                          !isAllRowChecked(checkedArray, "MIDDAY")
                        }
                        onChange={(e) => {
                          handleAllRowChange(e, "MIDDAY");
                        }}
                      />
                    </BorderedCheckboxContainer>
                  </FirstColumn>
                  <SecondColumn
                    borderLeft={`1px solid ${gray[300]}`}
                    borderRight={`1px solid ${gray[300]}`}
                  >
                    <Typography variant="body1">Mid-day</Typography>
                    <Typography variant="body1">11 - 2 p.m.</Typography>
                  </SecondColumn>
                </Flexbox>
                <Flexbox
                  justifyContent="space-between"
                  width="100%"
                  marginRight={"24px"}
                >
                  {WEEKDAYS.map((day) => (
                    <BorderedCheckboxContainer key={day}>
                      <Checkbox
                        checked={isBoxChecked(checkedArray, day, "MIDDAY")}
                        onChange={(e) => handleBoxChange(e, day, "MIDDAY")}
                      />
                    </BorderedCheckboxContainer>
                  ))}
                </Flexbox>
              </RowContainer>

              <RowContainer
                border={`1px solid ${gray[300]}`}
                bgcolor={gray[50]}
                borderRadius="12px"
                padding="16px 0"
              >
                <Flexbox marginRight={"24px"}>
                  <FirstColumn>
                    <BorderedCheckboxContainer>
                      <Checkbox
                        data-testid="Afternoon"
                        checked={isAllRowChecked(checkedArray, "AFTERNOON")}
                        indeterminate={
                          isAnyRowChecked(checkedArray, "AFTERNOON") &&
                          !isAllRowChecked(checkedArray, "AFTERNOON")
                        }
                        onChange={(e) => {
                          handleAllRowChange(e, "AFTERNOON");
                        }}
                      />
                    </BorderedCheckboxContainer>
                  </FirstColumn>
                  <SecondColumn
                    borderLeft={`1px solid ${gray[300]}`}
                    borderRight={`1px solid ${gray[300]}`}
                  >
                    <Typography variant="body1">Afternoon</Typography>
                    <Typography variant="body1">2 - 5 p.m.</Typography>
                  </SecondColumn>
                </Flexbox>
                <Flexbox
                  justifyContent="space-between"
                  width="100%"
                  marginRight={"24px"}
                >
                  {WEEKDAYS.map((day) => (
                    <BorderedCheckboxContainer key={day}>
                      <Checkbox
                        checked={isBoxChecked(checkedArray, day, "AFTERNOON")}
                        onChange={(e) => handleBoxChange(e, day, "AFTERNOON")}
                      />
                    </BorderedCheckboxContainer>
                  ))}
                </Flexbox>
              </RowContainer>
            </Box>
            <Flexbox marginTop="12px">
              {formik.errors?.timePreferences && (
                <ErrorComponent
                  error={formik.errors?.timePreferences.toString()}
                />
              )}
            </Flexbox>
          </>
        )}
        {appointmentPreferences.isFetching && <LoadingFallback delay={50} />}
      </PreferencesForm>
    );
  }
);

export default Step2;
