import { useTransition, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { useGetOrdersByCreationDateQuery } from "common/services/OrdersService";

import Table from "../../components/Table/Table";
import {
  TableComponentContainer,
  ComponentHeader
} from "../../styling/StyleComponents";
import { TableColumn } from "../../components/Table/TableTypes";
import styled from "@emotion/styled";
import LoadingFallback from "common/helpers/components/LoadingFallback";
import ErrorComponent from "../../components/ErrorComponent";
import { useNavigate } from "react-router-dom";

const TableHeading = styled(ComponentHeader)`
  margin: 24px 0px;
`;

const DAYS = 10;

interface IProps {
  tableColumns: TableColumn[];
  externalLink: string;
}

const AllOrdersTable = ({ tableColumns, externalLink }: IProps) => {
  const navigate = useNavigate();

  const { since, until } = useMemo(() => {
    const since = DateTime.now().minus({ days: DAYS });
    const until = DateTime.now().plus({ days: 1 });
    return { since, until };
  }, []);

  const {
    data: orders,
    error,
    isSuccess,
    isError
  } = useGetOrdersByCreationDateQuery({
    since,
    until
  });

  const [ordersProcessed, setOrdersProcessed] = useState<boolean>(false);

  const [isPending, startTransition] = useTransition();

  const ordersListSorted = useMemo(() => {
    if (orders === undefined) {
      if (isError || isSuccess) {
        setOrdersProcessed(true);
      }
      return undefined;
    }

    const sortedOrders = [...orders].sort((a, b) => {
      const aDate = DateTime.fromSQL(a?.order?.created_at);
      const bDate = DateTime.fromSQL(b?.order?.created_at);
      return bDate.toMillis() - aDate.toMillis();
    });
    if (isSuccess && sortedOrders && sortedOrders.length) {
      startTransition(() => {
        setOrdersProcessed(true);
      });
    }
    return sortedOrders;
  }, [isError, isSuccess, orders]);

  return (
    <TableComponentContainer>
      <TableHeading>{`All Orders (last ${DAYS} days)`}</TableHeading>
      {!ordersProcessed && !ordersListSorted && <LoadingFallback />}
      {ordersListSorted && (
        <Table
          data={ordersListSorted}
          tableProps={{ externalLink, navigate }}
          tableColumns={tableColumns}
          tableHeader="{{COUNT}} orders"
          estimateRowSize={(index: number) => {
            const item = ordersListSorted[index];
            // bad test data was breaking the totalSize formula
            const itemsAmount = item?.order?.items?.length ?? 0;

            return Math.max(79, 55 + 12 * itemsAmount);
          }}
        />
      )}
      {isError && <ErrorComponent error={error} />}
    </TableComponentContainer>
  );
};

export default AllOrdersTable;
