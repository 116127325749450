import { useEffect } from "react";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import { TurqoiseButton, WhiteButton } from "../../styling";
import { ModalFooter, ModalFooterButtons } from "../../styling/StyleModal";
import { Typography } from "@mui/material";
import ErrorComponent from "../../components/ErrorComponent";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import { Flexbox } from "../../styling/NewStyleComponents";
import RecurrenceUpdateTypeEnum from "common/enums/Calendaring/Appointments/RecurrenceUpdateTypeEnum";
import { isFalsy } from "common/helpers/helpers";
import { RelationshipNoteType } from "common/types/RelationshipNoteType";
import { useDeleteRelationshipNoteMutation } from "common/services/MemberGoalsRelationshipService";
import Table from "../../components/Table/Table";

const StyledWhiteButton = styled(WhiteButton)`
  display: flex;
`;

const Form = styled.form`
  height: 100%;
`;
interface FormValues {
  updateType: RecurrenceUpdateTypeEnum;
}

interface IProps {
  relationshipNote: RelationshipNoteType;
}

const DeleteRelationshipNoteForm = ({ relationshipNote }: IProps) => {
  const dispatch = useAppDispatch();

  const [deleteMutation, { isLoading, error }] =
    useDeleteRelationshipNoteMutation();

  useEffect(() => {
    if (error) {
      Alert_close({ dispatch, id: "deleteEvent" });
      formik.resetForm();
      const id = "deleteEventError";
      Alert_show({
        dispatch,
        id,
        title: "Error",
        content: <ErrorComponent error={error} />,
        type: "error",
        size: "small",
        buttons: [
          {
            text: "Close",
            onPress: () => {
              Alert_close({ dispatch, id });
            }
          }
        ]
      });
    }
  }, [error]);

  const validate = () => {
    const errors = {};
    if (isFalsy(formik.values.updateType)) {
      errors["updateType"] = "Required";
    }
    return errors;
  };

  const onSubmit = async () => {
    await deleteMutation({
      relationship_note_id: relationshipNote.relationship_note_id
    });
    handleModalClose();
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      updateType: RecurrenceUpdateTypeEnum.ONCE
    },
    enableReinitialize: true,
    validate,
    onSubmit
  });

  const handleModalClose = () => {
    formik.resetForm();
    Alert_close({ dispatch, id: "deleteEvent" });
  };

  const columns = [
    { name: "relationshipNotesCategory", size: 100 },
    { name: "relationshipNotesNote", size: 300 },
    { name: "relationshipNotesLastModified", size: 100 }
  ];

  return (
    <Flexbox
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <Form onSubmit={formik.handleSubmit}>
        <Flexbox
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Flexbox gap="24px" flexDirection="column">
            <Typography variant="body1" color="text.primary">
              Are you sure you want to delete this relationship note?
            </Typography>
            <Table
              tableColumns={columns}
              data={[relationshipNote]}
              showTableCount={false}
            />
          </Flexbox>

          <ModalFooter>
            <ModalFooterButtons margin="0">
              <TurqoiseButton type="submit" loading={isLoading}>
                Confirm
              </TurqoiseButton>
              <StyledWhiteButton
                onClick={handleModalClose}
                disabled={isLoading}
              >
                Cancel
              </StyledWhiteButton>
            </ModalFooterButtons>
          </ModalFooter>
        </Flexbox>
      </Form>
    </Flexbox>
  );
};

export default DeleteRelationshipNoteForm;
