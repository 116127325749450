import { useSelector } from "react-redux";
import { DateTime } from "luxon";

import { RootState } from "common/redux";
import RolesEnum from "common/enums/RolesEnum";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import { useEffect, useMemo, useState } from "react";
import { useLazyGetReadingsByMemberQuery } from "common/services/ReadingsService";
import DeviceReadingType from "common/types/DeviceReadingType";
import { useLazyGetEncountersQuery } from "common/services/EncountersService";
import EncounterType from "common/types/EncounterType";
import DateFilterFieldEnum from "../../enums/DateFilterFieldEnum";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { useGetMembersWithQueryParamsQuery } from "common/services/MemberService";
import MemberType from "common/types/MemberType";

interface UserAdditionalData extends MemberType {
  lastReading?: DeviceReadingType;
  readings?: DeviceReadingType[];
  lastEncounter?: EncounterType[];
}

interface FilterByProps {
  startDate: DateTime;
  endDate: DateTime;
  field: DateFilterFieldEnum;
}

const useGetAllPatientList = (
  userListFilters: {
    status: MemberStatusEnum[];
    linked_entities?: MemberLinkedEntitiesEnum[];
    enrolled_after?: string;
    enrolled_before?: string;
    size?: number;
  },
  loadReadingsEncounters: boolean = false,
  filterBy?: FilterByProps
) => {
  const { currentRole, user } = useSelector((state: RootState) => state.auth);

  const [isEncountersReadingsLoading, setIsEncountersReadingLoading] =
    useState<boolean>(false);
  const [encountersReadingsData, setData] = useState<UserAdditionalData[]>();
  const [getReadingsByPatient] = useLazyGetReadingsByMemberQuery();
  const [getEncountersByPatient] = useLazyGetEncountersQuery();

  const getPatientsReadingsEncounters = async (patients: MemberType[]) => {
    const requests: any = [];
    //const errors: any = [];
    patients.forEach((patient: MemberType) => {
      requests.push(
        getReadingsByPatient({
          memberId: patient.patient.patient_id,
          paramsObject: {
            sort: "measure_timestamp,DESC",
            limit: 1
          }
        })
      );
      requests.push(
        getEncountersByPatient({
          patient_id: patient.patient.patient_id,
          sort: "starts_on,DESC",
          size: 1
        })
      );
    });

    function processResponse(result: any) {
      const { status, value } = result;
      const { isSuccess, data } = value;
      if (status === "fulfilled" && isSuccess) {
        return data;
      } else {
        //errors.push(error);
        return undefined;
      }
    }

    setIsEncountersReadingLoading(true);
    const results = await Promise.allSettled(requests);
    setIsEncountersReadingLoading(false);

    const userList = patients.map((item, index) => {
      const resultReadings = results[index * 2];
      const resultEncounters = results[index * 2 + 1];
      let readings = processResponse(resultReadings);
      let lastReading = readings?.[0];
      let lastEncounter = processResponse(resultEncounters);

      let data: UserAdditionalData = {
        ...item,
        lastReading,
        readings,
        lastEncounter
      };

      return data;
    });

    setData(userList);
  };

  const queryParams =
    RolesEnum.NPS === currentRole
      ? { ...userListFilters, created_by: user?.user_id }
      : { ...userListFilters };

  const {
    data: patientListPaged,
    isLoading: isPatientLoadingPaged,
    isFetching: isPatientFetchingPaged,
    isError: isPatientErrorPaged,
    error: patientErrorPaged,
    isSuccess: isPatientSuccessPaged
  } = useGetMembersWithQueryParamsQuery({
    ...queryParams,
    size: userListFilters?.size ?? 500
  });

  const {
    data: patientListFull,
    isLoading: isPatientLoadingFull,
    isFetching: isPatientFetchingFull,
    isError: isPatientErrorFull,
    error: patientErrorFull,
    isSuccess: isPatientSuccessFull
  } = useGetMembersWithQueryParamsQuery(queryParams);

  const patientList = patientListFull || patientListPaged;

  const result = useMemo(() => {
    if (patientList === undefined) return;

    const result = patientList.filter((patient) => {
      if (filterBy === undefined) return true;

      const { startDate, endDate, field } = filterBy;
      const dateString = patient.patient[field];
      const fieldDate = DateTime.fromSQL(dateString);

      return startDate < fieldDate && fieldDate < endDate;
    });

    return result;
  }, [patientList, filterBy]);

  useEffect(() => {
    if (loadReadingsEncounters && result !== undefined) {
      // @ts-ignore
      getPatientsReadingsEncounters(result);
    }
  }, [result, loadReadingsEncounters]);

  if (loadReadingsEncounters) {
    return {
      data: encountersReadingsData,
      isLoading:
        (isPatientLoadingPaged && isPatientLoadingFull) ||
        isEncountersReadingsLoading
    };
  }

  const isLoading =
    (isPatientLoadingPaged && isPatientLoadingFull) ||
    isEncountersReadingsLoading;

  const isFetching =
    (isPatientFetchingPaged && isPatientFetchingFull) ||
    isEncountersReadingsLoading;

  return {
    data: result,
    isDataSummarized:
      result !== undefined && isPatientSuccessPaged && !isPatientSuccessFull,
    isLoading,
    isFetching,
    isError: isPatientErrorPaged || isPatientErrorFull,
    error: patientErrorPaged || patientErrorFull,
    isSuccess: isPatientSuccessPaged || isPatientSuccessFull
  };
};

export { useGetAllPatientList };
