import { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import useSanitizedParams from "../../hooks/useSanitizedParams";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
  canOrderMemberDevices,
  canSeeSupportOrderForm,
  hasToHideRefillMemberSupplies
} from "common/enums/RolesEnum";
import { RootState } from "common/redux";

import OrderDeviceForm from "./OrderDeviceForm";

import RefillForm from "./RefillForm";

import OrderHistoryTable from "./OrderHistoryTable";
import { TurqoiseButton } from "../../styling";
import SearchPatientModal from "../../components/Modal/SearchPatientModal/SearchPatientModal";

import useGetOrderDevices from "../../hooks/data_loaders/useGetOrderDevices";

import { DelayedRender } from "common/helpers/components/DelayedRender";

import ErrorComponent from "../../components/ErrorComponent";
import AddressSourceEnum from "common/enums/AddressSourceEnum";
import SupportOrderForm from "./SupportOrderForm";
import { checkIdValid } from "common/helpers/helpers";
import ErrorType from "common/types/ErrorType";

const OrderDevicesOuterContainer = styled.div`
  margin: 0px 2.5%;
  width: 95%;
`;

const OrderDevicesContainer = styled.div`
  padding: 24px;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
  background: white;
  overflow-x: auto;
`;

const DeviceListContainer = styled.div``;

const OrderDevicesText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(24, 24, 25, 0.42);
`;

const SearchPatientModalTrigger = styled(TurqoiseButton)`
  width: 200px;
`;

enum TabsEnum {
  ORDER_DEVICES = "ORDER_DEVICES",
  ORDER_PATIENT_DEVICES = "ORDER_PATIENT_DEVICES",
  REFILL_PATIENT_SUPPLIES = "REFILL_PATIENT_SUPPLIES",
  PLACE_SUPPORT_ORDER = "PLACE_SUPPORT_ORDER"
}

interface DisplayErrorsIProps {
  error: {
    patient: ErrorType;
    athena: ErrorType;
    orders?: ErrorType;
  };
}

const DisplayErrors = ({ error }: DisplayErrorsIProps) => {
  const keys = Object.keys(error);

  return (
    <>
      {keys.map((key) => {
        const err = error[key];
        if (err === undefined) return;

        return (
          <div key={key}>
            <br />
            <ErrorComponent error={err} />
          </div>
        );
      })}
    </>
  );
};

const OrderDevices = () => {
  const { currentRole } = useSelector((state: RootState) => state.auth);

  const params = useSanitizedParams();
  const isValidId = checkIdValid(params?.memberId);

  const [memberId, setMemberId] = useState<string>(
    isValidId ? params?.memberId : undefined
  );
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.ORDER_DEVICES);
  const [searchPatientModalOpen, setSearchPatientModalOpen] =
    useState<boolean>(false);

  const handleSelectedUser = (user_id: string) => {
    setMemberId(user_id);
  };

  const handlePatientModalOpen = () => setSearchPatientModalOpen(true);
  const handlePatientModalClose = () => setSearchPatientModalOpen(false);

  const [addressChanged, setAddressChanged] = useState<boolean>(false);
  const [addressSource, setAddressSource] = useState<AddressSourceEnum>(
    AddressSourceEnum.ATHENA
  );

  // when we search a new user, reset address changed state
  useEffect(() => {
    if (addressChanged) {
      setAddressChanged(false);
    }
  }, [memberId]);

  const { data, error, isLoading, isSuccess } = useGetOrderDevices(memberId);
  const { patient } = data;

  const isOrderPatientDevicesEnabled = useMemo(() => {
    return canOrderMemberDevices(currentRole);
  }, [currentRole]);

  const TABS = useMemo(() => {
    const tabs: TabsEnum[] = [TabsEnum.ORDER_DEVICES];
    if (isOrderPatientDevicesEnabled) tabs.push(TabsEnum.ORDER_PATIENT_DEVICES);
    if (hasToHideRefillMemberSupplies(currentRole) === false) {
      tabs.push(TabsEnum.REFILL_PATIENT_SUPPLIES);
    }
    if (canSeeSupportOrderForm(currentRole))
      tabs.push(TabsEnum.PLACE_SUPPORT_ORDER);

    return tabs;
  }, [currentRole]);

  const getTabLabel = (tab: TabsEnum) => {
    switch (tab) {
      case TabsEnum.ORDER_DEVICES:
        return "Order History";
      case TabsEnum.ORDER_PATIENT_DEVICES:
        return "Order Member Devices";
      case TabsEnum.REFILL_PATIENT_SUPPLIES:
        return "Refill Member Supplies";
      case TabsEnum.PLACE_SUPPORT_ORDER:
        return "Place Support Order";
      default:
        return "";
    }
  };

  const tabIndex = useMemo(() => {
    return TABS.indexOf(tab);
  }, [TABS, tab]);

  const handleTabSelect = (event: React.SyntheticEvent, newValue: number) => {
    setTab(TABS[newValue]);
  };

  return (
    <OrderDevicesOuterContainer>
      <Tabs
        value={tabIndex}
        onChange={handleTabSelect}
        aria-label="Order Devices"
      >
        {TABS.map((item) => (
          <Tab
            key={item}
            label={getTabLabel(item)}
            style={{ fontWeight: "600" }}
          />
        ))}
      </Tabs>

      <br />

      {tab !== TabsEnum.ORDER_DEVICES && (
        <OrderDevicesText>
          Please fill out the following order form
        </OrderDevicesText>
      )}

      <br />

      {tab === TabsEnum.ORDER_DEVICES && (
        <DeviceListContainer>
          <OrderDevicesContainer>
            <SearchPatientModalTrigger
              aria-label="openModal"
              onClick={handlePatientModalOpen}
            >
              Search for Member
            </SearchPatientModalTrigger>
            {isLoading && (
              <DelayedRender delay={500}>
                <div>
                  <Skeleton count={10} />
                </div>
              </DelayedRender>
            )}
          </OrderDevicesContainer>

          {isSuccess && patient && <OrderHistoryTable memberId={memberId} />}
          <DisplayErrors error={error} />
        </DeviceListContainer>
      )}
      {isOrderPatientDevicesEnabled &&
        tab === TabsEnum.ORDER_PATIENT_DEVICES && (
          <OrderDevicesContainer>
            <SearchPatientModalTrigger
              aria-label="openModal"
              onClick={handlePatientModalOpen}
            >
              Search for Member
            </SearchPatientModalTrigger>
            <DisplayErrors error={error} />
            <OrderDeviceForm
              memberId={memberId}
              setAddressChanged={setAddressChanged}
              addressChanged={addressChanged}
              addressSource={addressSource}
              setAddressSource={setAddressSource}
            />
          </OrderDevicesContainer>
        )}
      {hasToHideRefillMemberSupplies(currentRole) === false &&
        tab === TabsEnum.REFILL_PATIENT_SUPPLIES && (
          <OrderDevicesContainer>
            <SearchPatientModalTrigger
              aria-label="openModal"
              onClick={handlePatientModalOpen}
            >
              Search for Member
            </SearchPatientModalTrigger>
            <DisplayErrors error={error} />
            <RefillForm
              memberId={memberId}
              setAddressChanged={setAddressChanged}
              addressChanged={addressChanged}
              addressSource={addressSource}
              setAddressSource={setAddressSource}
            />
          </OrderDevicesContainer>
        )}
      {canSeeSupportOrderForm && tab === TabsEnum.PLACE_SUPPORT_ORDER && (
        <OrderDevicesContainer>
          <SearchPatientModalTrigger
            aria-label="openModal"
            onClick={handlePatientModalOpen}
          >
            Search for Member
          </SearchPatientModalTrigger>
          <DisplayErrors error={error} />

          <SupportOrderForm patient={patient} />
        </OrderDevicesContainer>
      )}

      <SearchPatientModal
        isOpen={searchPatientModalOpen}
        handleClose={handlePatientModalClose}
        handleSelectedUser={handleSelectedUser}
      />
    </OrderDevicesOuterContainer>
  );
};

export default OrderDevices;
