import { useMemo } from "react";
import styled from "@emotion/styled";
import MemberStatusEnum from "common/enums/MemberStatusEnum";
import get from "lodash.get";
import { Header } from "@tanstack/react-table";
import OrderTypeEnum from "common/enums/OrderTypeEnum";
import OrderStatusEnum from "common/enums/OrderStatusEnum";
import { getDeviceInfoBySku } from "../../helpers/helpers";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";

const UserCount = styled.div<{ padding?: string }>`
  margin: 8px 0px 24px 0px;
  width: 95%;
  padding: ${(props) => props.padding ?? "0"};
  color: rgba(24, 24, 25, 0.42);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
`;

interface IProps {
  data: any[];
  tableHeader?: string;
  tableHeaders: Header<any, any>[];
  tableHeaderUserAccessor?: string;
  padding?: string;
}

const TableCount = ({
  data,
  tableHeader,
  tableHeaders,
  tableHeaderUserAccessor = "patient.status",
  padding
}: IProps) => {
  const tableHeaderString = useMemo(() => {
    if (tableHeader === undefined || data === undefined || data.length === 0) {
      return "";
    }

    const active = data.filter(
      (item: any) =>
        get(item.original, tableHeaderUserAccessor) === MemberStatusEnum.ACTIVE
    );
    const pending = data?.filter(
      (item: any) =>
        get(item.original, tableHeaderUserAccessor) === MemberStatusEnum.PENDING
    );

    const intakeSum = data.reduce((accumulator: number, row) => {
      const item = row.original;
      //This is for encounters only
      const duration = item?.encounter?.duration;

      if (duration) return accumulator + item?.encounter?.duration;
      else return 0;
    }, 0);

    const uncontacted = data.filter(
      (item: any) =>
        get(item.original, "patient_retention_actions")?.length === 0
    );

    let additionalText = getAdditionalText(data, tableHeaders);

    return (
      tableHeader
        .replace("{{COUNT}}", "" + data.length)
        .replace("{{MemberStatusEnum.ACTIVE}}", "" + active.length)
        .replace("{{MemberStatusEnum.PENDING}}", "" + pending.length)
        .replace("{{INTAKE_SUM}}", "" + intakeSum)
        .replace("{{UNCONTACTED_SUM}}", "" + uncontacted.length) +
      " " +
      additionalText
    );
  }, [tableHeader, data, tableHeaderUserAccessor, tableHeaders]);

  return (
    <UserCount padding={padding} data-testid="TableCount-usercount">
      {tableHeaderString}
    </UserCount>
  );
};

// Count orders filtering out based on ENG-1793
const getAdditionalText = (data, tableHeaders) => {
  const filteredOrders = data.filter((row) => {
    const order = row.original;
    const orderStatus = order?.order?.status?.sm_status;
    const patient = order?.patient;

    return (
      patient?.is_test !== true &&
      orderStatus !== OrderStatusEnum.deleted &&
      orderStatus !== OrderStatusEnum.exception &&
      orderStatus !== OrderStatusEnum.rejected &&
      orderStatus !== OrderStatusEnum.returned
    );
  });
  const orderTypeHeader = tableHeaders.find((item) => item.id === "orderType");

  const orderTypeFilterValue = orderTypeHeader?.column?.getFilterValue();
  if (orderTypeFilterValue === OrderTypeEnum.REFILL) {
    return getRefillAdditionalText(filteredOrders);
  } else if (orderTypeFilterValue === OrderTypeEnum.DEVICE) {
    return getDevicesAdditionalText(filteredOrders);
  }
  return "";
};

const getRefillAdditionalText = (filteredOrders) => {
  let testStripCount = 0;
  let lancetsCount = 0;
  let controlSolutionCount = 0;
  filteredOrders.forEach((row) => {
    const order = row.original;
    const items = order?.order?.items;
    items?.forEach((item) => {
      const deviceInfo = getDeviceInfoBySku(item?.sku);
      if (deviceInfo === undefined) return;

      if (deviceInfo.description === "Glucose Test Strips")
        testStripCount += item.quantity;
      else if (deviceInfo.description === "Glucose Lancets")
        lancetsCount += item.quantity;
      else if (deviceInfo.description === "Glucose Control Solution")
        controlSolutionCount += item.quantity;
    });
  });
  return `(${testStripCount} Test Strips, ${lancetsCount} Lancets, ${controlSolutionCount} Control Solution)`;
};

const getDevicesAdditionalText = (filteredOrders) => {
  let bloodPressureCount = 0;
  let glucoseCount = 0;
  filteredOrders.forEach((row) => {
    const order = row.original;
    const items = order?.order?.items;

    items?.forEach((item) => {
      const deviceInfo = getDeviceInfoBySku(item.sku);
      if (DeviceTypeEnum.BLOOD_PRESSURE === deviceInfo?.description)
        bloodPressureCount++;
      else if (DeviceTypeEnum.GLUCOSE_METER === deviceInfo?.description)
        glucoseCount++;
    });
  });
  return `(${bloodPressureCount} Blood Pressure Cuffs, ${glucoseCount} Glucose Meters)`;
};

export default TableCount;
