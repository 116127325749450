import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";

import DeviceTypeEnum from "../enums/DeviceTypeEnum";
import { useGetDevicesQuery } from "../services/DevicesService";
import { useGetReadingsByMemberQuery } from "../services/ReadingsService";
import DeviceReadingType from "../types/DeviceReadingType";
import DeviceStatusEnum from "../enums/DeviceStatusEnum";
import { useGetOrdersByMemberIdQuery } from "../services/OrdersService";
import { useGetMemberWithUsernameQuery } from "../services/MemberService";
import { checkIdValid, getNameOrUsername } from "../helpers/helpers";
import MemberStatusEnum from "../enums/MemberStatusEnum";
import OrderSKUEnum from "../enums/OrderSKUEnum";

const CAN_MAKE_REFILL_ORDER_AFTER_ENROLLED_DAYS = 15;

const useGetRefillDataSummary = (memberId: string, days: number) => {
  let isRefillAllowed;
  let refillNotAllowedMessage;

  const isValidId = checkIdValid(memberId);
  const {
    data: patient,
    isLoading: isMemberLoading,
    isError: isMemberError
  } = useGetMemberWithUsernameQuery(
    {
      username: memberId
    },
    { skip: memberId === undefined || isValidId === false }
  );

  let patientName = getNameOrUsername(patient?.patient);
  let patientStatus = patient?.patient?.status;
  let patientEnrolledDate = patient?.patient?.created_at ?? "";

  let enrolledDate = DateTime.fromISO(patientEnrolledDate);

  const daysSinceEnrolled = Math.abs(enrolledDate.diffNow("days").days);

  const startDate = DateTime.now()
    .minus({ days })
    .setZone(patient?.patient?.timezone)
    .startOf("day");
  const endDate = DateTime.now()
    .endOf("day")
    .setZone(patient?.patient?.timezone);

  const {
    data: lastGlucoseReading,
    isLoading: isLastGlucoseReadingLoading,
    isError: isLastGlucoseReadingError
  } = useGetReadingsByMemberQuery(
    {
      memberId,
      paramsObject: {
        sort: "measure_timestamp,DESC",
        limit: 1,
        device_type: DeviceTypeEnum.GLUCOSE_CATEGORY
      }
    },
    { skip: patient === undefined || isValidId === false }
  );

  const [gMax, setGMax] = useState<DeviceReadingType>();
  const [gMin, setGMin] = useState<DeviceReadingType>();

  const {
    data: orders,
    isLoading: isLoadingOrders,
    isError: isErrorOrders
  } = useGetOrdersByMemberIdQuery(
    {
      memberId
    },
    { skip: memberId === undefined || isValidId === false }
  );

  let lastOrderWithRefills = orders?.find(
    (order) =>
      order?.order?.items?.find(
        (item) => item?.sku === OrderSKUEnum.GLUCOSE_LANCETS
      ) ||
      // glucose test strips SKU
      order?.order?.items?.find(
        (item) => item?.sku === OrderSKUEnum.GLUCOSE_TEST_STRIPS
      )
  );

  // last delivered order with strips or lancets
  let lastDeliveredOrderWithRefills = orders?.find(
    (order) =>
      order?.order?.shipping?.delivered_date !== null &&
      // lancets SKU
      (order?.order?.items?.find(
        (item) => item?.sku === OrderSKUEnum.GLUCOSE_LANCETS
      ) ||
        // glucose test strips SKU
        order?.order?.items?.find(
          (item) => item?.sku === OrderSKUEnum.GLUCOSE_TEST_STRIPS
        ))
  );

  const lastDeliveredOrderWithRefillsDate = DateTime.fromSQL(
    lastDeliveredOrderWithRefills?.order?.shipping?.delivered_date
  );

  const hasGlucoseDeviceBeenDelivered =
    orders?.find(
      (order) =>
        (order?.order?.shipping?.delivered_date !== null ||
          // handle delivered override status
          order?.order?.status?.sm_status === "delivered_override") &&
        order?.order?.items?.find(
          (item) => item?.sku === OrderSKUEnum.GLUCOSE_METER
        ) !== undefined
    ) !== undefined;

  const {
    data: glucoseData,
    isLoading: glucoseLoading,
    error: glucoseError,
    isSuccess: glucoseIsSuccess
  } = useGetReadingsByMemberQuery(
    {
      memberId,
      paramsObject: {
        device_type: DeviceTypeEnum.GLUCOSE_CATEGORY,
        startdate: Math.round(startDate.toSeconds()),
        enddate: Math.round(endDate.toSeconds())
      }
    },
    {
      skip: patient === undefined || isValidId === false
    }
  );

  const [glucoseDevice, setGlucoseDevice] = useState<any>();

  useEffect(() => {
    if (glucoseData) {
      const GMaxMin = getMaxMin(glucoseData, "glucose");
      const { maxData: gMaxData, minData: gMinData } = GMaxMin;

      setGMax(gMaxData);
      setGMin(gMinData);
    }
  }, [glucoseData]);

  const { data: devices } = useGetDevicesQuery(
    { memberId },
    { skip: patient === undefined || isValidId === false }
  );

  const { hasGlucoseDevice } = useMemo(() => {
    if (devices === undefined) return {};

    const filteredDevices = devices.filter(
      (device) => device.status === DeviceStatusEnum.ACTIVE
    );

    const glucoseDevice = filteredDevices.find(
      (device) => device.extra.type === DeviceTypeEnum.GLUCOSE_CATEGORY
    );

    setGlucoseDevice(glucoseDevice);

    return {
      hasGlucoseDevice:
        glucoseDevice !== undefined ||
        (lastGlucoseReading && lastGlucoseReading?.length > 0)
    };
  }, [devices, lastGlucoseReading]);

  if (
    // patient is not active or pending
    (patientStatus !== MemberStatusEnum.ACTIVE &&
      patientStatus !== MemberStatusEnum.PENDING &&
      patientStatus !== MemberStatusEnum.REEVALUATING_PATIENT) ||
    daysSinceEnrolled < CAN_MAKE_REFILL_ORDER_AFTER_ENROLLED_DAYS ||
    // add this check here so we block refill if a glucose device has never been delivered
    !hasGlucoseDeviceBeenDelivered
  ) {
    // can't refill

    isRefillAllowed = false;
  } else {
    // can refill
    isRefillAllowed = true;
  }

  const isLoading =
    isMemberLoading ||
    glucoseLoading ||
    isLastGlucoseReadingLoading ||
    isLoadingOrders;

  const isError =
    isMemberError || glucoseError || isLastGlucoseReadingError || isErrorOrders;

  return {
    patientName,
    patientStatus,
    isRefillAllowed,
    refillNotAllowedMessage,
    patientEnrolledDate,
    isLoading,
    isError,
    lastOrderWithRefills,
    lastDeliveredOrderWithRefillsDate,
    glucose: {
      data: glucoseData,
      lastReading: lastGlucoseReading?.[0],
      max: gMax,
      min: gMin,
      loading: glucoseLoading,
      isSucess: glucoseIsSuccess,
      error: glucoseError,
      hasDevice: hasGlucoseDevice
    },
    orders,
    hasGlucoseDeviceBeenDelivered,
    hasGlucoseDevice:
      glucoseDevice !== undefined ||
      (lastGlucoseReading && lastGlucoseReading?.length > 0)
  };
};

const getMaxMin = (
  data: DeviceReadingType[],
  accessor: "systolic" | "glucose" | "spo2" | "weight"
) => {
  let maxData = undefined;
  let minData = undefined;

  [...data].forEach((item) => {
    const value = item[accessor];
    if (maxData === undefined || value > maxData[accessor]) maxData = item;
    if (minData === undefined || value < minData[accessor]) minData = item;
  });

  return { minData, maxData };
};

export default useGetRefillDataSummary;
