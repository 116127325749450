enum MemberStatusReasonEnum {
  NONE = "NONE",
  READINGS_ACTIVE = "READINGS_ACTIVE",
  ENCOUNTERS_ACTIVE = "ENCOUNTERS_ACTIVE",
  NURSE_ENCOUNTERS_ACTIVE = "NURSE_ENCOUNTERS_ACTIVE",
  PROVIDERS_ENCOUNTERS_ACTIVE = "PROVIDERS_ENCOUNTERS_ACTIVE",
  READINGS_ENCOUNTERS_ACTIVE = "READINGS_ENCOUNTERS_ACTIVE",
  DEVICE_DELIVERED = "DEVICE_DELIVERED",
  AUTO_CANCELED = "AUTO_CANCELED",
  PATIENT_CANCELED = "PATIENT_CANCELED",
  INACTIVE_NURSE = "INACTIVE_NURSE",
  INACTIVE_RETENTION = "INACTIVE_RETENTION",
  INACTIVE_LETTER = "INACTIVE_LETTER",
  ADMINISTRATIVE_ERROR = "ADMINISTRATIVE_ERROR",
  DECEASED = "DECEASED",
  NEVER_ENGAGED = "NEVER_ENGAGED",
  CANCELED_RETENTION = "CANCELED_RETENTION"
}

export default MemberStatusReasonEnum;
