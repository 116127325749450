import { apiPAB } from "./AxiosService";
import { DateTime } from "luxon";
import { QueryString_stringify } from "../helpers/QueryStringHelper";
import ReduxTagEnum from "../enums/ReduxTagEnum";
import EncounterSubmitterType from "../types/EncounterSubmitterType";
import { providesList } from "./ServicesCacheUtils";
import { getNameOrUsername } from "../helpers/helpers";
import MemberLinkedEntitiesEnum from "../enums/MemberLinkedEntitiesEnum";
import { visitsService } from "./VisitsService";
import CommunicationTypeEnum from "../enums/Calendaring/CommunicationTypeEnum";
import { getEncounterTypeForRole } from "../helpers/EncounterHelper";
import EncounterLinkedEntitiesEnum from "../enums/EncounterLinkedEntitiesEnum";

const DELAY_AFTER_ENCOUNTER_REQUEST_COMPLETED = 1000;
interface GetEncountersProps {
  patient_id?: string;
  submitted_by?: string;
  startsAfter?: DateTime;
  startsBefore?: DateTime;
  sort?: string;
  size?: number;
  submitterRoles?: string;
  linked_entities?: (EncounterLinkedEntitiesEnum | MemberLinkedEntitiesEnum)[];
}

interface CreateEncounterProps {
  duration: number;
  patient_id: string;
  submitted_by?: string;
  requested_by?: string;
  starts_on?: DateTime;
  reason?: string;
  modality?: CommunicationTypeEnum;
}

const providesTags = (result: EncounterSubmitterType[] | undefined) => {
  return providesList<EncounterSubmitterType, ReduxTagEnum.Encounter>(
    result,
    ReduxTagEnum.Encounter,
    (item: EncounterSubmitterType) => item.encounter.encounter_id
  );
};

const encountersService = apiPAB
  .enhanceEndpoints({
    addTagTypes: [ReduxTagEnum.Encounter]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      // Queries
      getEncounters: builder.query<
        EncounterSubmitterType[],
        GetEncountersProps
      >({
        query: ({
          patient_id,
          submitted_by,
          startsAfter = undefined,
          startsBefore = undefined,
          sort,
          size,
          submitterRoles,
          linked_entities
        }) => {
          const params = QueryString_stringify({
            sort,
            size,
            submitterRoles,
            patient_id,
            submitted_by,
            linked_entities,
            starts_after: startsAfter ? startsAfter.toISODate() : undefined,
            starts_before: startsBefore ? startsBefore.toISODate() : undefined
          });
          return { url: `/encounters?${params}`, method: "GET" };
        },
        providesTags,
        transformResponse: (response: EncounterSubmitterType[]) => {
          const finalResponse = response.map((item) => {
            const displayName = getNameOrUsername(item.patient);
            return {
              ...item,
              // match encounter-visit schema
              starts_on: item?.encounter?.starts_on,
              time: {
                duration: item?.encounter?.duration,
                talk_time: item?.encounter?.duration,
                total_time: item?.encounter?.duration
              },
              fullname: displayName
            };
          });
          const addType = finalResponse?.map((item) => {
            const type = getEncounterTypeForRole(
              item?.encounter?.reason,
              item?.encounter?.submitter_roles,
              item?.encounter?.starts_on
            );
            return {
              type,
              ...item
            };
          });
          const sort = addType?.sort((a, b) => {
            const dateA = DateTime.fromISO(a?.encounter?.starts_on);
            const dateB = DateTime.fromISO(b?.encounter?.starts_on);
            return dateB?.toMillis() - dateA?.toMillis();
          });
          return sort;
        }
      }),

      // Mutations
      createEncounter: builder.mutation<any, any>({
        query: ({ body }) => {
          return {
            url: "/encounters",
            method: "POST",
            data: body
          };
        },
        invalidatesTags: [ReduxTagEnum.Encounter],
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            setTimeout(() => {
              dispatch(
                visitsService.util.invalidateTags([
                  ReduxTagEnum.EncounterVisits
                ])
              );
            }, DELAY_AFTER_ENCOUNTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      updateEncounter: builder.mutation<
        EncounterSubmitterType,
        Omit<{ encounterId: string; data: any }, "id">
      >({
        query: ({ encounterId, data }) => {
          return {
            url: `/encounters/${encounterId}`,
            method: "PUT",
            data
          };
        },
        invalidatesTags: (result, error, arg) =>
          // don't invalidate tag if error
          result ? [{ type: ReduxTagEnum.Encounter, id: arg.encounterId }] : [],
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            setTimeout(() => {
              dispatch(
                visitsService.util.invalidateTags([
                  ReduxTagEnum.EncounterVisits
                ])
              );
            }, DELAY_AFTER_ENCOUNTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      }),
      deleteEncounter: builder.mutation<
        EncounterSubmitterType,
        Omit<{ encounterId: string }, "id">
      >({
        query: ({ encounterId }) => {
          return {
            url: `/encounters/${encounterId}`,
            method: "DELETE"
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: ReduxTagEnum.Encounter, id: arg.encounterId }
        ],
        async onQueryStarted(props, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;

            setTimeout(() => {
              dispatch(
                visitsService.util.invalidateTags([
                  ReduxTagEnum.EncounterVisits
                ])
              );
            }, DELAY_AFTER_ENCOUNTER_REQUEST_COMPLETED);
          } catch (error) {}
        }
      })
    })
  });

const {
  useGetEncountersQuery,
  useLazyGetEncountersQuery,
  useCreateEncounterMutation,
  useUpdateEncounterMutation,
  useDeleteEncounterMutation
} = encountersService;

export {
  useGetEncountersQuery,
  useLazyGetEncountersQuery,
  useCreateEncounterMutation,
  useUpdateEncounterMutation,
  useDeleteEncounterMutation,
  encountersService,
  DELAY_AFTER_ENCOUNTER_REQUEST_COMPLETED
};
