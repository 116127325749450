import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography, styled } from "@mui/material";

import LoadingFallback from "common/helpers/components/LoadingFallback";

import ErrorComponent from "../../../components/ErrorComponent";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Flexbox } from "../../../styling/NewStyleComponents";

import {
  useGetUpcomingCalendarEventsQuery,
  useReassignCalendarEventMutation
} from "common/services/CalendarService";
import Table from "../../../components/Table/Table";
import {
  checkIdValid,
  getNameOrUsername,
  isFalsy
} from "common/helpers/helpers";
import DebouncedInput from "../../../components/Input/DebouncedInput";
import { useGetUserWithUsernameQuery } from "common/services/UserService";
import { Spinner, TurqoiseButton } from "../../../styling";
import { DateTime } from "luxon";
import AppointmentTypeEnum from "common/enums/Calendaring/Appointments/AppointmentTypeEnum";
import { useGetStaffAvailabilityRescheduleEventQuery } from "common/services/PanelManagementService";
import { StyledModal } from "../../../styling/StyleModal";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";
import { blue } from "../../../styling/colors";
import { DELAY_AFTER_APPOINTMENTS_REQUEST_COMPLETED } from "common/services/AppointmentsService";

const Container = styled("div")`
  margin: 20px 2.5%;
  overflow: scroll;
`;

export const StyledInput = styled(DebouncedInput)`
  background: white;
  width: auto;
`;

const Row = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 10px;
`;

function ReassignEventAutomatically({
  staffId,
  startDate,
  endDate,
  oldStaffId,
  eventId,
  resetState,
  rescheduleEventData,
  oldStaffTimezone,
  staffTimezone,
  navigationState
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const previousNurseName = getNameOrUsername(
    rescheduleEventData?.original_event?.staff,
    false
  );
  const nextNurseName =
    getNameOrUsername(rescheduleEventData?.updated_event?.staff, false) ??
    "Name not found";

  const previousTimeStart = DateTime.fromISO(
    rescheduleEventData?.original_event?.start_time
  ).setZone(oldStaffTimezone);

  const previousTimeEnd = DateTime.fromISO(
    rescheduleEventData?.original_event?.end_time
  ).setZone(oldStaffTimezone);

  const nextTimeStart = DateTime.fromISO(
    rescheduleEventData?.updated_event?.start_time
  ).setZone(staffTimezone);

  const nextTimeEnd = DateTime.fromISO(
    rescheduleEventData?.updated_event?.end_time
  ).setZone(staffTimezone);

  const hasUpcomingAppointment = rescheduleEventData?.upcoming_event;
  const upcomingTimeStart = DateTime.fromISO(
    rescheduleEventData?.upcoming_event?.start_time
  ).setZone(oldStaffTimezone);

  const upcomingTimeEnd = DateTime.fromISO(
    rescheduleEventData?.upcoming_event?.end_time
  ).setZone(oldStaffTimezone);

  const upcomingTimeText = `${upcomingTimeStart.toFormat("ccc, LLL d hh:mm a")} - ${upcomingTimeEnd.toFormat("hh:mm a ZZZZ")}`;
  const upcomingNurseName =
    getNameOrUsername(rescheduleEventData?.upcoming_event?.staff, false) ??
    "Name not found";

  const previousTimeText = `${previousTimeStart.toFormat("ccc, LLL d hh:mm a")} - ${previousTimeEnd.toFormat("hh:mm a ZZZZ")}`;
  const nextTimeText = `${nextTimeStart.toFormat("ccc, LLL d hh:mm a")} - ${nextTimeEnd.toFormat("hh:mm a ZZZZ")}`;

  const [
    reassignCalendarEvent,
    { data: reassignCalendarEventData, error: reassignCalendarEventError }
  ] = useReassignCalendarEventMutation();

  useEffect(() => {
    if (reassignCalendarEventError) {
      Alert_show({
        dispatch,
        id: "reassignCalendarEventError",
        title: "Error",
        content: <ErrorComponent error={"There was an unexpected error."} />,
        type: "error",
        size: "small",
        hideCloseIcon: true,
        buttons: [
          {
            text: "Reassign Manually",
            style: "default",
            onPress: () => {
              Alert_close({ dispatch, id: "reassignCalendarEventError" });
              resetState();
              navigate(`/nurses-schedules/manual-reassignment/${eventId}`, {
                state: { staffId, ...navigationState }
              });
            }
          },
          {
            text: "Cancel",
            style: "cancel",
            onPress: () => {
              Alert_close({ dispatch, id: "reassignCalendarEventError" });
              resetState();
            }
          }
        ]
      });
    }
  }, [reassignCalendarEventError]);

  useEffect(() => {
    if (staffId && startDate && endDate && eventId) {
      // we need this if statement or things will break, it is a limitation of the Redux Modal
      if (hasUpcomingAppointment) {
        Alert_show({
          dispatch,
          id: "reassignResults",
          title: "Reassign Results",
          hideCloseIcon: true,
          content: (
            <div>
              <div>
                The system found an available target time for the appointment,
                please review and confirm.
              </div>
              <div
                style={{
                  borderRadius: "8px",
                  height: "64px",
                  border: "1px solid #d0d5dd",
                  margin: "8px 0"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center"
                  }}
                >
                  <div style={{ margin: "8px", width: "50px" }}>From:</div>
                  <div>
                    <b data-testid={previousNurseName} id="previousNurseName">
                      {previousNurseName}
                      <br />
                      {previousTimeText}
                    </b>
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderRadius: "8px",
                  height: "64px",
                  border: "1px solid #d0d5dd",
                  margin: "8px 0"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center"
                  }}
                >
                  <div style={{ margin: "8px", width: "50px" }}>To:</div>
                  <div>
                    <b data-testid={nextNurseName} id="nextNurseName">
                      {nextNurseName}
                      <br />
                      {nextTimeText}
                    </b>
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "8px",
                  height: "64px",
                  border: "1px solid #d0d5dd",
                  margin: "8px 0",
                  backgroundColor: blue[50]
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center"
                  }}
                >
                  <div style={{ margin: "8px" }}>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ fontSize: "14px", color: "#101828" }}>
                          Member's next appointment:
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#101828",
                          fontWeight: "700"
                        }}
                      >
                        {upcomingNurseName}:&nbsp;
                        {upcomingTimeText}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
          type: "default",
          size: { width: "640px", height: "500px" },
          buttons: [
            {
              text: "Confirm",
              style: "default",
              onPress: () => {
                reassignCalendarEvent({
                  staffId,
                  oldStaffId,
                  eventId,
                  body: {
                    staff_id: staffId,
                    start_date: startDate,
                    end_date: endDate
                  }
                });
                Alert_close({ dispatch, id: "reassignResults" });
              }
            },
            {
              text: "Select Manually",
              style: "cancel",
              onPress: () => {
                resetState();
                Alert_close({ dispatch, id: "reassignResults" });
                navigate(`/nurses-schedules/manual-reassignment/${eventId}`, {
                  state: { staffId, ...navigationState }
                });
              }
            }
          ]
        });
      } else {
        Alert_show({
          dispatch,
          id: "reassignResults",
          title: "Reassign Results",
          hideCloseIcon: true,
          content: (
            <div>
              <div>
                The system found an available target time for the appointment,
                please review and confirm.
              </div>
              <div
                style={{
                  borderRadius: "8px",
                  height: "64px",
                  border: "1px solid #d0d5dd",
                  margin: "8px 0"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center"
                  }}
                >
                  <div style={{ margin: "8px", width: "50px" }}>From:</div>
                  <div>
                    <b data-testid={previousNurseName} id="previousNurseName">
                      {previousNurseName}
                      <br />
                      {previousTimeText}
                    </b>
                  </div>
                </div>
              </div>
              <div
                style={{
                  borderRadius: "8px",
                  height: "64px",
                  border: "1px solid #d0d5dd",
                  margin: "8px 0"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center"
                  }}
                >
                  <div style={{ margin: "8px", width: "50px" }}>To:</div>
                  <div>
                    <b data-testid={nextNurseName} id="nextNurseName">
                      {nextNurseName}
                      <br />
                      {nextTimeText}
                    </b>
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: "8px",
                  height: "64px",
                  border: "1px solid #d0d5dd",
                  margin: "8px 0",
                  backgroundColor: blue[50]
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center"
                  }}
                >
                  <div style={{ margin: "8px" }}>
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ fontSize: "14px", color: "#101828" }}>
                          Member's next appointment:
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#101828",
                          fontWeight: "700"
                        }}
                      >
                        N/A
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
          type: "default",
          size: { width: "640px", height: "500px" },
          buttons: [
            {
              text: "Confirm",
              style: "default",
              onPress: () => {
                reassignCalendarEvent({
                  staffId,
                  oldStaffId,
                  eventId,
                  body: {
                    staff_id: staffId,
                    start_date: startDate,
                    end_date: endDate
                  }
                });
                Alert_close({ dispatch, id: "reassignResults" });
              }
            },
            {
              text: "Select Manually",
              style: "cancel",
              onPress: () => {
                resetState();
                Alert_close({ dispatch, id: "reassignResults" });
                navigate(`/nurses-schedules/manual-reassignment/${eventId}`, {
                  state: { staffId, ...navigationState }
                });
              }
            }
          ]
        });
      }
    }
  }, [staffId, startDate, endDate, eventId, hasUpcomingAppointment]);

  useEffect(() => {
    if (reassignCalendarEventData) {
      setTimeout(() => {
        Alert_show({
          dispatch,
          hideCloseIcon: true,
          id: "reassignCalendarEventSuccess",
          title: "Success",
          content: "The appointment has been reassigned successfully.",
          type: "default",
          size: "small",
          buttons: [
            {
              text: "Close",
              style: "default",
              onPress: () => {
                Alert_close({ dispatch, id: "reassignCalendarEventSuccess" });
                resetState();
              }
            }
          ]
        });
      }, DELAY_AFTER_APPOINTMENTS_REQUEST_COMPLETED);
    }
  }, [reassignCalendarEventData]);

  return <></>;
}

function GetRescheduleEventData({
  selectedEventId,
  setLoadingModalOpen,
  oldStaffId,
  resetRoot,
  navigationState
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [staffId, setStaffId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const resetState = useCallback(() => {
    resetRoot();
    setTimeout(() => {
      setStaffId("");
      setStartDate("");
      setEndDate("");
    }, 50);
  }, [setLoadingModalOpen]);

  const { data: rescheduleEventData, error: rescheduleEventError } =
    useGetStaffAvailabilityRescheduleEventQuery(
      { event_id: selectedEventId },
      { skip: !selectedEventId }
    );

  useEffect(() => {
    if (rescheduleEventError) {
      setLoadingModalOpen(false);
      Alert_show({
        dispatch,
        id: "rescheduleEventError",
        title: "Reassign Failed",
        content: <ErrorComponent error={"There was an unexpected error."} />,
        type: "error",
        size: "small",
        hideCloseIcon: true,
        buttons: [
          {
            text: "Reassign Manually",
            style: "default",
            onPress: () => {
              setLoadingModalOpen(false);
              Alert_close({ dispatch, id: "rescheduleEventError" });
              resetState();
              navigate(
                `/nurses-schedules/manual-reassignment/${selectedEventId}`,
                {
                  state: { staffId, ...navigationState }
                }
              );
            }
          },
          {
            text: "Cancel",
            style: "cancel",
            onPress: () => {
              setLoadingModalOpen(false);
              resetState();
              Alert_close({ dispatch, id: "rescheduleEventError" });
            }
          }
        ]
      });
    }
  }, [rescheduleEventError]);

  useEffect(() => {
    if (rescheduleEventData) {
      setStaffId(rescheduleEventData?.updated_event?.staff?.staff_id);
      setStartDate(rescheduleEventData?.updated_event?.start_time);
      setEndDate(rescheduleEventData?.updated_event?.end_time);
    }
  });

  return (
    <>
      {selectedEventId && rescheduleEventData && (
        <ReassignEventAutomatically
          oldStaffTimezone={
            rescheduleEventData?.original_event?.staff?.timezone
          }
          staffTimezone={rescheduleEventData?.updated_event?.staff?.timezone}
          staffId={staffId}
          startDate={startDate}
          endDate={endDate}
          oldStaffId={oldStaffId}
          eventId={selectedEventId}
          resetState={resetState}
          rescheduleEventData={rescheduleEventData}
          navigationState={navigationState}
        />
      )}
    </>
  );
}

const TimeOffRender = ({
  nurseName,
  dateRange,
  calendarEventsData,
  oldStaffId,
  navigationState
}) => {
  const navigate = useNavigate();
  const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false);
  const [selectedEventId, setSelectedEventId] = useState("");
  const resetRoot = useCallback(() => {
    setSelectedEventId("");
    setLoadingModalOpen(false);
  }, []);

  const filteredCalendarEventsData = useMemo(() => {
    return calendarEventsData?.filter((item) => {
      const isMemberAppointment =
        item?.appointment_type === AppointmentTypeEnum.TELEHEALTH_NURSE_SETUP ||
        item?.appointment_type === AppointmentTypeEnum.NURSE_FOLLOWUP ||
        item?.appointment_type === AppointmentTypeEnum.PROVIDER_FOLLOWUP;
      return isMemberAppointment;
    });
  }, [calendarEventsData]);

  useEffect(() => {
    if (selectedEventId) {
      setLoadingModalOpen(true);
    }
  }, [selectedEventId]);

  return (
    <>
      <Container>
        <Typography variant="h3" color="black">
          {nurseName}'s Appointments / {dateRange}
        </Typography>

        <br />
        <Flexbox justifyContent="space-between" alignItems="center">
          <Typography>
            Reassigning this nurse's appointments scheduled due to time off.
          </Typography>
          <TurqoiseButton
            sx={{ width: "auto" }}
            onClick={() => navigate("/nurses-schedules/time_off")}
          >
            Back
          </TurqoiseButton>
        </Flexbox>
      </Container>

      <Box margin="0 20px">
        {filteredCalendarEventsData?.length >= 0 && (
          <Table
            tableColumns={[
              {
                name: "calendarVisitsStartEnd",
                header: "Appointment Time",
                size: 80
              },
              {
                name: "appointmentMemberAttendee"
              },
              {
                name: "ptoReassign",
                size: 40
              }
            ]}
            tableProps={{
              setSelectedEventId
            }}
            noDataText="No data found."
            data={filteredCalendarEventsData}
          />
        )}
      </Box>

      <br />

      {selectedEventId && (
        <GetRescheduleEventData
          selectedEventId={selectedEventId}
          setLoadingModalOpen={setLoadingModalOpen}
          oldStaffId={oldStaffId}
          resetRoot={resetRoot}
          navigationState={navigationState}
        />
      )}

      <StyledModal
        isOpen={loadingModalOpen}
        modalHeight="72px"
        modalWidth="180px"
        minWidth="180px"
        contentLabel="Working"
      >
        <Flexbox height="100%" justifyContent="center">
          <Flexbox
            position="relative"
            width="60px"
            height="100%"
            alignItems="center"
          >
            <Spinner loading="true" />
          </Flexbox>
          <Flexbox height="100%" alignItems="center">
            Working
          </Flexbox>
        </Flexbox>
      </StyledModal>
    </>
  );
};

const TimeOff = () => {
  const { nurseId } = useParams();
  const { state } = useLocation();
  const start_time = state?.start_time;
  const end_time = state?.end_time;
  const invalidUserId = !isFalsy(nurseId) && !checkIdValid(nurseId);

  const now = useMemo(() => DateTime.now(), []);
  const start = useMemo(() => {
    if (start_time) {
      return DateTime.fromISO(start_time);
    }
    return now.startOf("day");
  }, [start_time]);
  const end = useMemo(() => {
    if (end_time) {
      return DateTime.fromISO(end_time);
    }
    now.endOf("day");
  }, [end_time]);
  const dateRange = useMemo(() => {
    if (start_time && end_time) {
      if (start.hasSame(end, "day")) {
        return start.toFormat("cccc, LLL d");
      } else {
        return `${start.toFormat("cccc, LLL d")} - ${end.toFormat("cccc, LLL d")}`;
      }
    }
    return now.toFormat("cccc, LLL d");
  }, [start_time, end_time]);

  const invalidDateRange = !start?.isValid || !end?.isValid;

  const {
    data: nurseData,
    isFetching: isNurseDataFetching,
    error: nurseDataError
  } = useGetUserWithUsernameQuery(
    {
      username: nurseId
    },
    { skip: invalidUserId || invalidDateRange }
  );

  const {
    data: calendarEventsData,
    isFetching: calendarEventsisFetching,
    error: calendarEventsError
  } = useGetUpcomingCalendarEventsQuery(
    {
      staff_id: nurseId,
      startdate: start,
      enddate: end
    },
    {
      skip: invalidUserId || invalidDateRange
    }
  );

  const isFetching = isNurseDataFetching || calendarEventsisFetching;

  return (
    <Container>
      {invalidUserId && (
        <Typography
          margin="20px"
          variant="body1"
          color="error"
        >{`Error: Invalid User ID ${nurseId}`}</Typography>
      )}
      {invalidDateRange && (
        <Typography
          margin="20px"
          variant="body1"
          color="error"
        >{`Error: Invalid date range. Please select a valid OOO slot from the Time Off screen.`}</Typography>
      )}
      {!invalidUserId &&
      !invalidDateRange &&
      calendarEventsData &&
      nurseData ? (
        <TimeOffRender
          nurseName={getNameOrUsername(nurseData?.user, false)}
          dateRange={dateRange}
          calendarEventsData={calendarEventsData}
          oldStaffId={nurseId}
          navigationState={state}
        />
      ) : (
        <>
          {isFetching && <LoadingFallback />}
          {nurseDataError && (
            <ErrorComponent
              error={nurseDataError}
              showErrorResponseMessage
              hideErrorCode
            />
          )}
          {calendarEventsError && (
            <ErrorComponent
              error={calendarEventsError}
              showErrorResponseMessage
              hideErrorCode
            />
          )}
        </>
      )}
    </Container>
  );
};

export default TimeOff;
