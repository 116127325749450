import { useEffect } from "react";

import { DateTime } from "luxon";
import DatePicker from "./DatePicker";

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  onStartDateChange: (startDate: DateTime) => void;
  onEndDateChange: (startDate: DateTime) => void;
  maxDate?: DateTime;
}

const DateRangePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  maxDate
}: IProps) => {
  useEffect(() => {
    if (startDate > endDate) {
      onEndDateChange(startDate);
    }
  }, [startDate, endDate]);

  // prevent user from typing in the date as this can lead to bugs
  // see ENG-3757
  const onKeyDown = (e) => {
    if (e.key === "Delete") {
      // TBD ENG-5032 fix this case when pressing fn + delete, these all don't work :(
      e.target = null;
      e.preventDefault();
      e.nativeEvent.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    e.preventDefault();
  };

  return (
    <>
      <DatePicker
        sx={{ marginTop: 0 }}
        label="Start date"
        value={startDate}
        onChange={(newDate: DateTime) => onStartDateChange(newDate)}
        slotProps={{
          field: {
            readOnly: true
          },
          textField: { onKeyDown }
        }}
        maxDate={endDate}
      />

      <DatePicker
        sx={{ marginTop: 0 }}
        label="End date"
        value={endDate}
        onChange={(newDate: DateTime) => onEndDateChange(newDate)}
        slotProps={{
          field: {
            readOnly: true
          },
          textField: { onKeyDown }
        }}
        minDate={startDate}
        maxDate={maxDate}
      />
    </>
  );
};

export default DateRangePicker;
