import { useEffect } from "react";
import useSanitizedParams from "../../hooks/useSanitizedParams";
import { getAthenaPatientUrl } from "common/helpers/EnvVarsHelper";

const AthenaRouter = () => {
  const params = useSanitizedParams();

  useEffect(() => {
    if (params?.athenaId) {
      location.replace(getAthenaPatientUrl(params.athenaId));
    }
  }, [params?.athenaId]);

  return <></>;
};

export default AthenaRouter;
