import RolesEnum from "./RolesEnum";

enum TeamTypeEnum {
  TH_NURSES = "TH_NURSES",
  NP_NURSES = "NP_NURSES",
  NURSE_MANAGERS = "NURSE_MANAGERS",
  NPS = "NPS",
  PROVIDERS = "PROVIDERS",
  MEMBER_CARE_SPECIALISTS = "MEMBER_CARE_SPECIALISTS"
}

interface TeamTypeInfo {
  allowedLeaderRoles: RolesEnum[];
  allowedMemberRoles: RolesEnum[];
}

const TeamTypeDropdownLabels = {
  TH_NURSES: { value: "TH_NURSES", label: "Telehealth Nurses" },
  NP_NURSES: { value: "NP_NURSES", label: "Intake Nurses" },
  NURSE_MANAGERS: { value: "NURSE_MANAGERS", label: "Nurse Managers" },
  NPS: { value: "NPS", label: "New Member Specialists" },
  PROVIDERS: { value: "PROVIDERS", label: "Providers" },
  MEMBER_CARE_SPECIALISTS: {
    value: "MEMBER_CARE_SPECIALISTS",
    label: "Member Care Specialists"
  }
};

const teamTypeData: Record<TeamTypeEnum, TeamTypeInfo> = {
  [TeamTypeEnum.TH_NURSES]: {
    allowedLeaderRoles: [
      RolesEnum.THN_MANAGER,
      RolesEnum.MD_PROVIDER,
      RolesEnum.NURSE_PROVIDER
    ],
    allowedMemberRoles: [RolesEnum.TH_NURSE]
  },
  [TeamTypeEnum.NP_NURSES]: {
    allowedLeaderRoles: [
      RolesEnum.NPN_MANAGER,
      RolesEnum.MD_PROVIDER,
      RolesEnum.NURSE_PROVIDER
    ],
    allowedMemberRoles: [RolesEnum.NP_NURSE]
  },
  [TeamTypeEnum.NURSE_MANAGERS]: {
    allowedLeaderRoles: [RolesEnum.NURSE_DIRECTOR],
    allowedMemberRoles: [
      RolesEnum.NPN_MANAGER,
      RolesEnum.THN_MANAGER,
      RolesEnum.NPS_MANAGER
    ]
  },
  [TeamTypeEnum.NPS]: {
    allowedLeaderRoles: [RolesEnum.NPS_MANAGER],
    allowedMemberRoles: [RolesEnum.NPS]
  },
  [TeamTypeEnum.PROVIDERS]: {
    allowedLeaderRoles: [RolesEnum.PROVIDER_MANAGER],
    allowedMemberRoles: [RolesEnum.MD_PROVIDER, RolesEnum.NURSE_PROVIDER]
  },
  [TeamTypeEnum.MEMBER_CARE_SPECIALISTS]: {
    allowedLeaderRoles: [RolesEnum.THN_MANAGER],
    allowedMemberRoles: [RolesEnum.MEMBER_CARE_SPECIALIST]
  }
};

function getAllowedLeaderRoles(teamType: TeamTypeEnum): RolesEnum[] {
  return teamTypeData[teamType].allowedLeaderRoles;
}

function getAllowedMemberRoles(teamType: TeamTypeEnum): RolesEnum[] {
  return teamTypeData[teamType].allowedMemberRoles;
}

function getAllowedTeamTypesForLeaderRole(leaderRole: RolesEnum): string[] {
  const teamTypes = Object.keys(teamTypeData).filter((key) =>
    teamTypeData[key]?.allowedLeaderRoles?.includes(leaderRole)
  );
  return teamTypes;
}
function getAllowedTeamTypesForMemberRole(memberRole: RolesEnum): string[] {
  const teamTypes = Object.keys(teamTypeData).filter((key) =>
    teamTypeData[key]?.allowedMemberRoles?.includes(memberRole)
  );
  return teamTypes;
}

function canEditTeam(userRole: RolesEnum, teamType: TeamTypeEnum): boolean {
  if (userRole === RolesEnum.ADMIN) return true;

  switch (teamType) {
    case TeamTypeEnum.TH_NURSES:
      return (
        userRole === RolesEnum.THN_MANAGER ||
        userRole === RolesEnum.MD_PROVIDER ||
        userRole === RolesEnum.NURSE_PROVIDER ||
        userRole === RolesEnum.TECHNICAL_SUPPORT
      );
    case TeamTypeEnum.NP_NURSES:
      return (
        userRole === RolesEnum.NPN_MANAGER ||
        userRole === RolesEnum.NURSE_DIRECTOR
      );
    case TeamTypeEnum.NURSE_MANAGERS:
      return userRole === RolesEnum.NURSE_DIRECTOR;
    case TeamTypeEnum.NPS:
      return userRole === RolesEnum.NPS_MANAGER;
    case TeamTypeEnum.PROVIDERS:
      return (
        userRole === RolesEnum.PROVIDER_MANAGER ||
        userRole === RolesEnum.THN_MANAGER ||
        userRole === RolesEnum.NURSE_DIRECTOR
      );
    case TeamTypeEnum.MEMBER_CARE_SPECIALISTS:
      return (
        userRole === RolesEnum.THN_MANAGER ||
        userRole === RolesEnum.NURSE_DIRECTOR
      );
    default:
      // if there is no existing team
      return true;
  }
}

export {
  TeamTypeEnum,
  TeamTypeDropdownLabels,
  getAllowedLeaderRoles,
  getAllowedMemberRoles,
  getAllowedTeamTypesForLeaderRole,
  getAllowedTeamTypesForMemberRole,
  canEditTeam
};
