import { Dispatch, SetStateAction } from "react";
import { useFormik } from "formik";
import { TurqoiseButton } from "../../styling";
import {
  isValidSalesforceId,
  isValidAthenaId
} from "../../helpers/components/Forms/FormHelpers";
import { Card, CardContent, TextField, Typography } from "@mui/material";

interface FormType {
  salesforceLead: string;
  athenaId: string;
}

interface IProps {
  salesforceInitialLead: string;
  getSalesforceLead: (sfLead: string) => Promise<void>;
  setSalesforceLead: Dispatch<SetStateAction<string>>;
  isFetching: boolean;
  isLoaded: boolean;
}

const RegisterPatientForm = ({
  isFetching,
  isLoaded,
  salesforceInitialLead,
  getSalesforceLead,
  setSalesforceLead
}: IProps) => {
  const validate = (values: FormType) => {
    const errors: { [key: string]: string } = {};

    if (!values.salesforceLead) {
      errors.salesforceLead = "Required";
    }

    if (values.salesforceLead && !isValidSalesforceId(values.salesforceLead)) {
      errors.salesforceLead = "Please enter a valid Salesforce Lead ID";
    }

    if (values.athenaId && !isValidAthenaId(values.athenaId)) {
      errors.athenaId = "The Athena ID provided is invalid";
    }

    return errors;
  };

  const onSubmit = async (values: FormType) => {
    const { salesforceLead } = values;

    if (isValidSalesforceId(salesforceLead)) {
      // isLoaded is true if the data has already been fetched
      // if they click on the "Register Member" button again, call fetchData again
      if (salesforceInitialLead === salesforceLead || isLoaded) {
        // call fetchData from the hook
        getSalesforceLead(salesforceLead);
      } else {
        // if value is different and data is not loaded yet, let useEffect in RegisterPatient handle submit
        setSalesforceLead(salesforceLead);
      }
    }
  };

  const formik = useFormik<FormType>({
    initialValues: {
      salesforceLead: salesforceInitialLead,
      athenaId: ""
    },
    onSubmit,
    validate
  });

  const { values, handleSubmit, setFieldValue, errors, isValid } = formik;
  return (
    <Card sx={{ minWidth: 500 }}>
      <CardContent>
        <Typography variant="h3">Create a New Member</Typography>

        <br />
        <form onSubmit={handleSubmit}>
          <TextField
            label="Salesforce Lead ID"
            value={values.salesforceLead}
            fullWidth
            onChange={(event) =>
              setFieldValue("salesforceLead", event.target.value)
            }
            error={errors["salesforceLead"] !== undefined}
            helperText={errors["salesforceLead"]}
          />

          <br />
          <br />
          <TurqoiseButton
            type="submit"
            loading={isFetching}
            disabled={
              !isValid ||
              !values.salesforceLead ||
              !isValidSalesforceId(values.salesforceLead) ||
              Object.keys(errors)?.length > 0
            }
          >
            Register Member
          </TurqoiseButton>
        </form>
      </CardContent>
    </Card>
  );
};

export default RegisterPatientForm;
